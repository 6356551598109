webix.i18n.locales["ru-RU"].sormui = {};

webix.i18n.locales["ru-RU"].sormui.AppView =
    {
        Title:"СКАТ",

        "Traffic analysis":"Анализ трафика",
        "Statistics":"Статистика",
        "Connections":"Соединения",
        "Traffic control":"Управление трафиком",
        "Traffic capture":"Захват трафика",
        "Traffic generation":"Генерация трафика",
        "Blocking and redirection":"Блокирование и перенаправление",
        "Cloning a website":"Клонирование  веб-сайта",
        "Network utilities":"Сетевые утилиты",
        "Network audit":"Аудит сети",
        "DPI control":"Управление DPI",
        "Performance":"Производительность",
        "Configuration":"Конфигурация",
        "Performance and statistics":"Производительность и статистика",
        "Logs":"Логи",
        "Send error":"Отправить ошибку",
        "Services":"Службы",
        "Health map":"Карта здоровья",

        "My profile":"Мой профиль",
        "Users":"Пользователи",
        "Roles":"Роли",
        "Managing users and roles":"Управление пользователями и ролями",

        "Log out":"Выход",

        "Edit profile":"Редактирование профиля",
        "Username":"Имя пользователя",
        "Enter user name":"Введите имя пользователя",
        "Full name":"ФИО",
        "Last name First name Middle name":"Фамилия Имя Отчество полностью",
        "Enter your full name":"Введите ФИО полностью",
        "Position":"Должность",
        "Enter your position":"Введите должность",
        "Organization":"Организация",
        "Name of the organization":"Название организации",
        "Enter the name of the organization":"Введите название организации",
        "Enter your e-mail":"Введите e-mail",
        "Phone":"Телефон",
        "Contact number":"Контактный телефон",
        "Enter phone number":"Введите номер телефона",
        "Change password":"Смена пароля",
        "Old password":"Старый пароль",
        "Enter password":"Введите пароль",
        "New password":"Новый пароль",
        "Confirm password":"Подтвердить пароль",
        "Passwords do not match":"Пароли не совпадают",
        "Traffic decode logs": "Логи разбора трафика"
    };

webix.i18n.locales["ru-RU"].sormui.LoginView =
    {
        "Sign in to control panel CKAT":"Войти в панель управления СКАТ",
        "Login":"Логин",
        "Enter login":"Введите логин",
        "Password":"Пароль",
        "Enter password":"Введите пароль",
        "Remember me":"Запомнить меня",
        "Sign in":"Войти"
    };

webix.i18n.locales["ru-RU"].sormui.All =
    {
        "Yes":"Да",
        "No":"Нет",

        "No connection":"Нет соединения",
        "In developing":"В разработке",

        "Russian":"Русский",
        "Rus.":"Рус.",
        "English":"Английский",
        "Eng.":"Англ.",

        "Refresh":"Обновить",
        "Cancel":"Отменить",
        "Delete":"Удалить",
        "Close":"Закрыть",
        "Save":"Сохранить",
        "Add value":"Добавить значение",
        "Upload from file":"Загрузить из файла",
        "Download from history":"Загрузить из истории",

        "Are you sure you want to delete the selected records?":"Вы действительно хотите удалить выбранные записи?",

        "Gb":"Гб",

        "The form":"Форма",
        "Editor":"Редактор",

        "Settings":"Настройки",

        "Total":"Всего",

        "byte":"байт",

        "Deleted":"Удалено",

        "Profile saved":"Профиль сохранен",
        "Task saved":"Задача сохранена",
        "Saved":"Сохранено",

        "Are you sure you want to delete the task?":"Вы действительно хотите удалить задачу?",
        "Are you sure you want to delete the profile?":"Вы действительно хотите удалить профиль?",
        "Are you sure you want to delete the file?":"Вы действительно хотите удалить файл?",
        "Are you sure you want to delete the selected files?":"Вы действительно хотите удалить выбранные файлы?",
        "Are you sure you want to delete this record?":"Вы действительно хотите удалить эту запись?",

        "On page":"На странице",

        "from":"от",

        "Export to PDF":"Экспортировать в PDF",
        "Export to Excel":"Экспортировать в Excel",

        "Tail":"Хвост",
        "tail":"хвост",

        "firts packets":"первые пакеты",

        "On":"Вкл.",
        "Off":"Выкл.",

        "Total":"Всего",
        "Done":"Выполнено",

        "Unhandled error":"Необработанная ошибка",
        "Send error":"Отправить ошибку",

        "Parser error":"Ошибка парсера",

        "Import":"Импортировать",
        "Imported successfully":"Импортировано успешно",

        "Communication operators":"Операторы связи",
        "Operators":"Операторы",
        "Operator":"Оператор",

        "Bytes":"Байт",
        "Kb":"Кб",
        "Mb":"Мб",
        "Gb":"Гб",
        "Tb":"Тб",

        "Size":"Размер"
    };

webix.i18n.locales["ru-RU"].sormui.ConnectionsStatistics =
    {
        "Period":"Период",
        "Interval":"Интервал",
        "1 minute":"1 минута",
        "1 hour":"1 час",
        "1 day":"1 день",
        "1 month":"1 месяц",

        "Total traffic":"Общий трафик",
        "Incoming traffic (byte)":"Входящий трафик (байт)",
        "Incoming traffic":"Входящий трафик",
        "Incoming locked traffic":"Входящий заблокированный трафик",
        "Outgoing traffic (byte)":"Исходящий трафик (байт)",
        "Outgoing traffic":"Исходящий трафик",
        "Outgoing locked traffic":"Исходящий трафик",
        "Protocol traffic":"Трафик по протоколам",

        "Object":"Объект",
        "Incoming":"Входящий",
        "Outgoing":"Исходящий",
        "Locked":"Заблокированный",

        "Filter":"Фильтр",
        "Number of displayed objects":"Количество отображаемых объектов",
        "Filter by objects: each with a new line":"Фильтр по объектам. Каждый с новой строки",
        "In a separate window":"В отдельном окне",

        "Top":"Топ",
        "Top traffic table":"Таблица топ трафика",
        "Top protocols":"Топ протоколы",
        "Top traffic":"Топ трафик",
        "Top services":"Топ сервисы",
        "Top subscribers":"Топ абоненты"
    };

webix.i18n.locales["ru-RU"].sormui.ConnectionsValidateData =
    {
        "Enable search":"Включить поиск",
        "Connections search request":"Запрос на поиск соединений",
        "Description":"Описание",
        "Period":"Период",
        "Subscriber":"Абонент",
        "Source IP":"IP источника",
        "Source port":"Порт источника",
        "IP address or IP address:port":"IP-адрес или IP-адрес:порт",
        "Destination IP":"IP получателя",
        "Destination port":"Порт получателя",
        "Max number of entries":"Макс. количество записей",
        "Max number of records in query results":"Макс. количество записей в результатах запроса",
        "Resources and services":"Ресурсы и сервисы",
        "Aaa subscribers sessions":"Aaa сессии абонентов",
        "Subscriber login":"Логин абонента",
        "Subscriber password":"Пароль абонента",
        "Url, for example somehost.ru/some/*":"Урл, например somehost.ru/some/*",
        "Url":"Урл",
        "Ftp-login":"Ftp-логин",
        "Ftp-password":"Ftp-пароль",
        "The sender, for example petya@gmail.com":"Отправитель, например petya@gmail.com",
        "Sender":"Отправитель",
        "The recipient of a copy of the email message":"Получатель копии почтового сообщения",
        "The message subject":"Тема сообщения",
        "The message text":"Текст сообщения",
        "Copy":"Копия",
        "The recipient, for example, vasya@gmail.com":"Получатель, например vasya@gmail.com",
        "Recipient":"Получатель",
        "Name of the server through which the message was sent":"Наименование сервера, через который отправлено письмо",
        "Attachements":"Вложения",
        "The presence of attachments in the letter":"Наличие прикрепленных файлов в письме",
        "Caller's phone number":"Телефонный номер вызывающего",
        "Phone number of called":"Телефонный номер вызываемого",
        "Caller's internal number":"Внутренний номер вызывающего",
        "Internal number of called":"Внутренний номер вызываемого",
        "Caller type":"Тип вызывающего абонента",
        "Type of called":"Тип вызываемого абонента",
        "Subscriber of this switch":"Абонент данного коммутатора",
        "Subscriber of communication network":"Абонент сети связи",
        "Rowmer":"Роумер",
        "Flows":"Потоки",
        "Protocol code":"Код протокола",
        "Protocol code in accordance with RFC1700 or port number for TCP / UDP":"Код протокола в соответствии с RFC1700, либо номер порта для TCP/UDP",
        "Terminal sessions":"Терминальные сессии",
        "Aaa sessions":"Ааа сессии",
        "User equipment":"Пользовательское оборудование",
        "Sender's User ID":"Пользовательский идентификатор отправителя",
        "Sender ID":"Идентификатор отправителя",
        "Receiver's User ID":"Пользовательский идентификатор получателя",
        "Receiver ID":"ИД получателя",
        "Public sender name":"Общедоступное имя отправителя",
        "Public receiver name":"Общедоступное имя получателя",

        "Are you sure you want to delete the request?":"Вы действительно хотите удалить запрос?",

        "Reports":"Отчеты",
        "Add request":"Добавить запрос",
        "Add subquery":"Добавить подзапрос",
        "Requests":"Запросы",
        "Date":"Дата",
        "User":"Пользователь",
        "Status":"Статус",
        "Data":"Данные",
        "On the page":"На странице",
        "Create subquery":"Создать подзапрос",
        "Clear filters":"Очистить фильтры",

        "Connection start":"Начало соединения",
        "Subject":"Тема",
        "Reply":"Ответить",
        "Message":"Сообщение",
        "Client IP":"IP клиента",
        "Client port":"Порт клиента",
        "Server IP":"IP сервера",
        "Server port":"Порт сервера",
        "Protocol":"Протокол",
        "Point":"Точка",
        "Connection point":"Точка подключения",
        "Connection point identifier to the data network":"Идентификатор точки подключения к сети передачи данных",
        "Event type":"Тип события",
        "Size":"Размер",
        "Files":"Size",
        "Email protocol":"Почтовый протокол",
        "Completion reason":"Причина завершения",
        "Connection completion reason":"Причина завершения соединения",
        "Connection end":"Конец соединения",

        "Username":"Имя пользователя",
        "User password":"Пароль пользователя",
        "Server name":"Название сервера",
        "Server mode":"Режим сервера",
        "Bytes recieved":"Принято байт",
        "Bytes sent":"Отправлено байт",
        "Caller's number":"Номер вызывающего абонента",
        "Name of the initiator of communication":"Имя инициатора связи",
        "Initiator name":"Имя инициатора",
        "Called number":"Номер вызываемого абонента",
        "Session ID":"ИД сессии",
        "Conference ID":"ИД конференции",
        "Duration of conversation":"Длительность разговора",
        "Duration of conversation, sec.":"Длительность разговора, сек.",
        "Sending a fax":"Передача факса",
        "Inbound bunch":"Входящий пучок",
        "ID of inbound bunch":"ИД входящего пучка",
        "for GSM network":"для GSM-Сети",
        "for W/CDMA, UMTS network":"для W/CDMA, UMTS-сети",
        "Outbound bunch":"Исходящий пучок",
        "ID of outbound bunch":"ИД исходящего пучка",
        "Gateway":"Шлюз",
        "Voip protocol":"Voip протокол",
        "Connection method":"Способ подключения",
        "Service ID":"ИД сервиса",

        "Url address":"Url адрес",
        "Http method":"Http метод",
        "The data size in bytes":"Объем данных в байтах",
        "Login":"Логин",
        "Authorization result":"Результат авторизации",

        "Sender name":"Имя отправителя",
        "Sender ID":"ИД отправителя",
        "Receiver name":"Имя получателя",
        "Receiving ID":"ИД получателя",
        "Subscriber ID":"ИД абонента",
        "Message size":"Размер сообщения",

        "Terminal protocol":"Терминальный протокол",
        "Extension number of the caller":"Доп. номер вызывающего абонента",
        "Extension number of the called subscriber":"Доп. номер вызываемого абонента",
        "Dialed number":"Набранный номер",
        "Connection duration":"Длительность соединения",
        "Call type":"Тип вызова",
        "Additional services for connection":"ДВО при соединении",
        "Type of called subscriber":"Тип вызываемого абонента",
        "Switch code":"Код коммутатора",
        "Border switch code":"Код пограничного коммутатора",
        "Phone card number":"Номер телефонной карты",
        "Forwarding number":"Номер переадресации",
        "Cause of connection termination":"Причина завершения соединения",

        "Connection date and time":"Дата и время соединения",
        "Login type":"Тип логина",
        "IP address":"IP-адрес",
        "Connection type":"Тип соединения",
        "Connecting to data network":"Подключение к сети передачи данных",
        "Disconnecting from data network":"Отключение от сети передачи данных",
        "Calling number":"Вызывающий номер",
        "Called number":"Вызываемый номер",
        "after conv.":"после преобраз.",
        "after conversion":"после преобразования",
        "in the E164 network":"в E164-сети",
        "IP address of the NAS server":"IP-адрес NAS-сервера",
        "NAS server port":"Порт NAS-сервера",
        "Received data":"Принято данных",
        "Sent data":"Отправлено данных",
        "Access point (APN)":"Точка доступа (APN)",
        "Service area code (SAC)":"Код зоны обслуживания (SAC)",
        "IMSI of mobile subscriber":"IMSI мобильного абонента",
        "IMEI of mobile subscriber":"IMEI мобильного абонента",
        "Mobile station ID of the subscriber":"Идентификатор мобильной станции абонента",
        "Mobile station ID":"ИД мобильной станции",
        "Modem pool number":"Номер модемного пула",
        "MAC address":"MAC-адрес",
        "ATM virtual path number (VPI)":"Номер виртуального пути сети ATM (VPI)",
        "ATM virtual channel number (VCI)":"Номер виртуального канала сети ATM (VCI)",
        "IP address of GPRS / EDGE SGSN":"IP-адрес GPRS/EDGE SGSN",
        "IP address of GPRS / EDGE GGSN":"IP-адрес GPRS/EDGE GGSN",
        "Base station (connection established)":"Базовая станция (соединение установлено)",
        "Base station zone code (connection established)":"Код зоны базовой станции (установлено соединение)",
        "Base station code (connection established)":"Код базовой станции (установлено соединение)",
        "Timing advance of the base station (connection established)":"Временная компенсация (Timing Advance) базовой станции (установлено соединение)",
        "Base station (connection completed)":"Базовая станция (завершено соединение)",
        "Base station zone code (connection completed)":"Код зоны базовой станции (завершено соединение)",
        "Base station code (connection completed)":"Код базовой станции (завершено соединение)",
        "Timing advance of the base station (connection completed)":"Временная компенсация (Timing Advance) базовой станции (завершено соединение)",

        "<br/><br/><br/>Synchronizing data":"<br/><br/><br/>Синхронизация данных",
        "Synchronizing data":"Синхронизация данных",
        "Data loading":"Загрузка данных",
        "Loaded":"Загружено",
        "of":"из",
        "Number of attempts":"Количество попыток",

        "Query saved":"Запрос сохранен",
        "Search terms in processing: ":"Поисковые запросы в обработке: ",
        "We were unable to process your searches: ":"Не удалось обработать поисковые запросы: ",
        "No search requests in processing":"Нет поисковых запросов в обработке",

        "Request":"Запрос",
        "from":"от",
        "Status":"Статус",
        "Total":"Всего",

        "Value, list separated by commas":"Значение, список через запятую",
        "Results":"Результаты",

        "Data is not ready":"Данные не готовы",
        "Data ready":"Данные готовы",
        "Data not found":"Данные не найдены",
        "Error":"Ошибка",
        "Data canceled":"Данные анулированы",
        "Data deleted":"Данные удалены",

        "You must enable at least one query parameter":"Необходимо включить хотя бы один параметр запроса",

        "Active":"Активный",
        "Passive":"Пассивный",

        "Try again":"Попробовать снова"
    };

webix.i18n.locales["ru-RU"].sormui.TrafficManagementCapture =
    {
        "Profiles":"Профили",
        "List of profiles for traffic capture":"Список профилей для захвата трафика",
        "Add profile":"Добавить профиль",
        "Add subprofile":"Добавить подпрофиль",

        "Start capturing":"Начать захват",
        "Stop capturing":"Остановить захват",

        "Profile":"Профиль",
        "Date":"Дата",
        "User":"Пользователь",
        "Status":"Статус",

        "Profile for traffic capture":"Профиль для захвата трафика",
        "Task title / description":"Название / описание задачи",
        "Description":"Описание",
        "IP address or CIDR":"IP-адрес или CIDR",
        "IP-address, range, CIDR":"IP-адрес, диапазон, CIDR",
        "Raw packages":"Cырые пакеты",
        "Send raw packages without analysis":"Посылать сырые пакеты без анализа",
        "Channel data":"Данные канала",
        "Send ethernet frames or IP packets (only if the option Raw packages enabled)":"Посылать ethernet frames или IP пакеты (Только если включена опция Сырые пакеты)",
        "Disable traffic control":"Выключить контроль трафика",
        "Enable buffer analyzer":"Включить анализатор буфера",

        "Enabled":"Включено",
        "Disabled":"Выключено",

        "Traffic capture enabled":"Захват трафика включен",
        "Could not enable traffic capture":"Не удалось включить захват трафика",

        "Traffic capture disabled":"Захват трафика отключен",
        "Could not disable traffic capture":"Не удалось отключить захват трафика",

        "Pcap files":"Pcap файлы",
        "File":"Файл",
        "Content":"Содержимое",
        "Download pcap file completely":"Скачать pcap файл полностью",

        "Time":"Время",
        "Source":"Источник",
        "Destination":"Получатель",
        "Info":"Информация",

        "Condition":"Условие",
        "IPv4 address":"IPv4 адрес",
        "Incorrect":"Неверный",

        "Subscriber login":"Логин абонента",

            "Task": "Задача",
            "Task name": "Имя задачи",
            "Field is required": "Поле обязательно к заполнению",
            "Decode files": "Разбор файлов",
            "New task": "Новая задача",
            "Tasks for pcap decoding successfully created!": "Задачи для разбора pcap-файлов успешно созданы!",
            "Do you want to open Traffic decode section?": "Вы хотите открыть раздел Разбора Трафика?",
            "Open": "Открыть"
    };

webix.i18n.locales["ru-RU"].sormui.TrafficManagementSiteClone =
    {
        "Sites":"Сайты",
        "Tasks for downloading sites":"Задачи на загрузку сайтов",

        "Add task to site download":"Добавить задачу на загрузку сайта",
        "Add a subtask to site download":"Добавить подзадачу на загрузку сайта",
        "Start scanning":"Запустить сканирование",
        "Stop scanning":"Остановить сканирование",
        "Data folder properties":"Свойства папки с данными",
        "Download zip-archive":"Скачать zip-архив",
        "Clear Folder":"Очистить папку",

        "Task":"Задача",
        "Date":"Дата",
        "User":"Пользователь",
        "Status":"Статус",
        "Http status":"Http статус",
        "Virt. host":"Вирт. хост",
        "Virtual host":"Вирт. хост",
        "Virtual port":"Вирт. порт",

        "Statistics":"Статистика",
        "Statistics log":"Лог статистики",
        "Files statistics":"Статистика по файлам",

        "Download the log file":"Скачать лог файл полностью",
        "Export to Excel":"Экспортировать в Excel",

        "Path":"Путь",
        "Bytes loaded":"Загружено байт",
        "Total bytes":"Всего байт",
        "Percent":"Процент",
        "Total (bytes)":"Всего (байт)",
        "Uploaded (bytes)":"Загружено (байт)",

        "The task for site downloading":"Задача на загрузку сайта",
        "Extra options":"Дополнительные параметры",
        "Task title / description":"Название / описание задачи",
        "Description":"Описание",
        "Main settings":"Основные параметры",

        "URLs":"URL-адрес(а)",
        "URLs list to download: each URL from a new line.":"Список URL-адресов для загрузки. Каждый URL с новой строки.",

        "Recursion":"Рекурсия",
        "Recursive download":"Рекурсивная загрузка",
        "Enable recursive download":"Включение рекурсивной загрузки",
        "Recursion depth":"Глубина рекурсии",
        "Recursion depth (inf and 0 - infinity)":"Глубина рекурсии (inf и 0 - бесконечность)",

        "Download requisites":"Загружать реквизиты",
        "Download all the images  necessary to display the HTML page and so on":"Загрузить все изображения и проч., необходимые для отображения HTML-страницы",
        "Convert references":"Конвертировать ссылки",
        "Make links in downloaded HTML or CSS point to local files":"Делать ссылки локальными в загруженном HTML или CSS",
        "Backup":"Делать резервную копию",
        "Before converting file X, back up as X.orig":"Перед преобразованием файла X делать резервную копию X.orig",
        "Create site mirror":"Создать зеркало сайта",
        "Shortcut for -N -r -l inf --no-remove-listing":"Короткий параметр, эквивалентный -N -r -l inf --no-remove-listing",
        "Don't ascend":"Не подниматься",
        "Don't ascend to the parent directory":"Не подниматься в родительский каталог",

        "Input&nbsp;data":"Входные&nbsp;данные",
        "Continue downloading":"Продолжить загрузку",
        "Resume getting a partially-downloaded file":"Продолжить загрузку частично сохраненных файлов",
        "Force HTML":"Форсировать HTML",
        "Treat input file as HTML":"Трактовать список входных URL-адресов как HTML",
        "URL prefix":"Префикс URL",
        "Resolves HTML input-file links relative to URL":"Добавить префикс к каждому  URL из списка",

        "Downloading":"Загрузка",
        "Pause":"Пауза",
        "Wait between retrievals in seconds":"Пауза в секундах между загрузками",
        "Random wait":"Произвольная пауза",
        "Wait from 0.5*WAIT...1.5*WAIT secs between retrievals":"Пауза в 0.5*WAIT...1.5*WAIT секунд между загрузками",
        "Speed (bps)":"Скорость (бит/сек.)",
        "Limit download rate":"Ограничить скорость загрузки",
        "Server response":"Ответ от сервера",
        "Print server response":"Вывод ответа сервера",
        "Skip downloads":"Пропустить загрузки",
        "Skip downloads that would download to existing files (overwriting them)":"Пропустить загрузки, которые будут загружаться в существующие файлы (перезаписывать их)",

        "Tries":"Попытки",
        "Set number of retries (0 unlimits)":"Установить число повторных попыток (0 без ограничения)",
        "Retry even if refused":"Повторять при отказе",
        "Retry even if connection is refused":"Повторять, даже если в подключении отказано",
        "Time-out":"Тайм-аут",
        "Set all timeout values in seconds":"Установка значений всех тайм-аутов в секундах",
        "Don't re-retrieve":"Не загружать повторно",
        "Don't re-retrieve files unless newer than local":"Не загружать повторно файлы, только если они не новее, чем локальные",
        "Directories":"Каталоги",
        "Don't create directories":"Не создавать каталоги",
        "Force creation of directories":"Принудительно создавать каталоги",
        "Don't create host directories":"Не создавать хостовые каталоги",
        "Ignore":"Игнорировать",
        "Ignore number remote directory components":"Игнорировать число компонентов удалённого каталога",

        "Http user":"Пользователь http",
        "Set http user":"Установить http-пользователя",
        "Proxy user":"Пользователь proxy",
        "Set proxy username":"Установить proxy-пользователя",
        "POST data":"POST-строка",
        "Use the POST method. Send STRING as the data":"Использовать метод POST; отправка строки в качестве данных",
        "Don't use cookies":"Не использовать кукисы",
        "Do not use keep alive":"Не использовать keep alive",
        "Disable HTTP keep-alive (persistent connections)":"Отключить поддержание активности HTTP (постоянные подключения)",
        "Do not use cache":"Не использовать кэш",
        "Disallow server-cached data":"Отвергать кэшированные сервером данные",
        "Http password":"Пароль http",
        "Set http password":"Установить http-пароль",
        "Proxy password":"Пароль proxy",
        "Set proxy password":"Установить proxy-пароль",
        "Referer header":"Заголовок Referer",
        "Include 'Referer: URL' header in HTTP request":"Включить в HTTP-запрос заголовок Referer: URL.",
        "Agent":"Агент",
        "Identify as Agent instead of Wget/version":"Идентифицировать себя как агент вместо Wget/версия",

        "Don't remove '.listing' files":"Не удалять файлы .listing",
        "Upload files by reference":"Загружать файлы по ссылкам",
        "When recursing, get linked-to files (not dir)":"При рекурсии загружать файлы по ссылкам (не каталоги)",
        "Turn off masks":"Выключить маски",
        "Turn off FTP file name globbing":"Выключить маски для имён файлов FTP",
        "Disable passive mode":"Отключить пассивный режим",
        "Disable the passive transfer mode":"Отключить пассивный режим передачи",

        "Are you sure you want to empty the folder?":"Вы действительно хотите очистить папку?",
        "Clear":"Очистить",

        "Folder":"Папка",
        "Size":"Размер",
        "Number of files":"Количество файлов",
        "Number of folders":"Количество папок",

        "File statistics":"Статистика по файлам",

        "Folder is cleared":"Папка очищена",

        "Task":"Задача",
        "Task saved":"Задача сохранена",

        "Scan started":"Сканирование запущено",
        "Scan not running":"Сканирование не запущено",
        "Scan stopped":"Сканирование остановлено",

        "NewFile":"Новый",
        "Scanning":"Сканирование",
        "Saved":"Сохранен",
        "Not saved":"Не сохранен",
        "Error":"Ошибка",

        "NewTask":"Новая",
        "Launched":"Запущена",
        "Stopped":"Остановлена",

        "Enabled":"Включено",
        "Disabled":"Выключено",

        "Enable virtual host":"Включить виртуальный хост",
        "Disable virtual host":"Выключить виртуальный хост",

        "Virtual host enabled":"Виртуальный хост включен",
        "Virtual host not enabled":"Виртуальный хост не включен",
        "Virtual host disabled":"Виртуальный хост выключен",
        "Virtual host not disabled":"Виртуальный хост не выключен",

        "Site spoofing":"Спуфинг сайта",
        "Facebook passwords collect":"Facebook - cбор паролей",
        "Template":"Шаблон",
        "Spoof":"Подменить",
        "File":"Файл",
        "Path to file":"Путь к файлу",
        "index.html by default":"index.html по умолчанию",
        "You must select a template":"Необходимо выбрать шаблон",
        "Site is spoofed":"Сайт подделан",
        "Spoofing data":"Данные спуфинга"
    };

webix.i18n.locales["ru-RU"].sormui.NetworkAudit =
    {
        "Profiles":"Профили",
        "List of nodes scan profiles":"Список профилей сканирования узлов",
        "Add profile":"Добавить профиль",
        "Add subprofile":"Добавить подпрофиль",

        "Start scanning":"Запустить сканирование",
        "Stop scanning":"Остановить сканирование",

        "Profile":"Профиль",
        "Date":"Дата",
        "User":"Пользователь",
        "Status":"Статус",

        "New":"Новый",
        "Launched":"Запущен",
        "Stopped":"Остановлен",
        "Error":"Ошибка",

        "Node scan profile":"Профиль сканирования узла",
        "Description":"Описание",
        "Task title / description":"Название / описание профиля",
        "Nodes":"Узлы",
        "Host names, IP-addresses, networks...":"Имена хостов, IP-адреса, сети... Каждый с новой строки.",

        "Scan started":"Сканирование запущено",
        "Scan not running":"Сканирование не запущено",
        "Scan stopped":"Сканирование остановлено",

        "Scan results":"Результаты сканирования",

        "Log":"Лог",
        "Report":"Отчет",

        "Main settings":"Основные параметры",
        "Extra options":"Дополнительные параметры",

        "Host discovery":"Обнаружение хостов",
        "Hosts":"Хосты",
        "List scan":"Сканирование списка",
        "Simply list targets to scan":"Сканирование с целью составления списка",
        "Ping scan":"Пинг сканирование",
        "Disable port scan":"Отключить сканирование портов",
        "Treat all hosts as online":"Все хосты онлайн",
        "Skip host discovery":"Пропустить обнаружение хоста",
        "TCP SYN Ping":"TCP SYN пинг",
        "This option sends an empty TCP packet with the SYN flag set":"Эта опция отправляет пустой TCP-пакет с установленным флагом SYN",
        "Ports list":"Список портов",
        "Ports list: 22-25,80,113,1050,35000":"Список портов: 22-25,80,113,1050,35000",
        "TCP ACK Ping":"TCP ACK пинг",
        "This option sends an empty TCP packet with the ACK  flag set":"Эта опция отправляет пустой TCP-пакет с установленным флагом ACK",
        "UDP Ping":"UDP пинг",
        "Sends a UDP packet to the given ports":"Отправляет UDP-пакет в указанные порты",
        "SCTP INIT Ping":"SCTP INIT пинг",
        "This option sends an SCTP packet containing a minimal INIT chunk":"Эта опция отправляет пакет SCTP, содержащий минимальный кусок INIT",
        "ICMP echo":"ICMP эхо",
        "Timestamp request":"Запрос метки времени",
        "Netmask request":"Запрос сетевой маски",
        "IP Protocol ping":"IP-протокол пинг",
        "Sends IP packets with the specified protocol number set in their IP header":"Отправляет IP-пакеты с указанным номером протокола, установленным в их IP-заголовке",
        "Protocols list":"Список протоколов",
        "No DNS resolution":"Не разрешать DNS",
        "Never do reverse DNS resolution on the active IP addresses it finds":"Не делать обратного DNS-разрешения на найденных активных IP-адресах",
        "DNS resolution for all targets":"Разрешение DNS для всех целей",
        "Always do reverse DNS resolution on the target IP addresses":"Всегда выполнять обратное DNS-разрешение на целевых IP-адресах",
        "DNS servers":"DNS сервера",
        "Specify custom DNS servers":"Задать собственные DNS сервера",
        "<serv1[,serv2],...>":"<сервер1[,сервер2],...>",
        "OS's DNS":"Системный DNS",
        "Use OS's DNS resolver":"Использовать системный DNS преобразователь",
        "Host path":"Путь к хосту",
        "Trace hop path to each host":"Отслеживать путь к хосту",

        "Port scanning":"Cканирование портов",
        "Ports":"Порты",
        "TCP SYN scan":"TCP SYN сканирование",
        "TCP connect scan":"TCP connect сканирование",
        "TCP connect scan is the default TCP scan type when SYN scan is not an option":"Это используемый по умолчанию тип TCP сканирования, когда недоступно SYN сканирование",
        "UDP scans":"UDP сканирование",
        "SCTP INIT scan":"SCTP INIT сканирование",
        "SCTP is a relatively new alternative to the TCP and UDP protocols":"SCTP является относительно новой альтернативой протоколам TCP и UDP, объединяя большинство характеристик TCP и UDP, а также добавляя новые функции, такие как многопоточность и многопоточность",
        "TCP Null scan":"TCP Null сканирование",
        "FIN scan":"FIN сканирование",
        "Xmas scan":"Xmas сканирование",
        "SCTP COOKIE ECHO scan":"SCTP COOKIE ECHO сканирование",
        "IP protocol scan":"Сканирование IP протокола",
        "Idle scan":"Idle сканирование",
        "This advanced scan method allows for a truly blind TCP port scan of the target (meaning no packets are sent to the target from your real IP address)":"Этот продвинутый метод сканирования позволяет осуществить действительно незаметное TCP сканирование портов цели (имеется ввиду, что никакие пакеты не отсылаются на целевую машину с вашего реального IP адерса)",
        "<zombie host>[:<probeport>]":"<зомби_хост>[:<порт>]",
        "FTP bounce scan":"FTP bounce сканирование",
        "<FTP relay host>":"<FTP хост> - <имя_пользователя>:<пароль>@<сервер>:<порт>",
        "Custom TCP scan":"Заказное TCP сканирование",
        "TCP flags":"TCP флаги",

        "Scan order":"Порядок сканирования",
        "Order":"Порядок",
        "Fast scan":"Быстрое сканирование",
        "Fast (limited port) scan":"Быстрое (ограниченные порты) сканирование",
        "No randomize ports":"Неслучайный порядок портов",
        "Don't randomize ports":"Не использовать случайный порядок портов",
        "Only specified ports":"Только определенные порты",
        "Only scan specified ports":"Сканирование только определенных портов",
        "Most common ports":"Наиболее распространенные порты",
        "Scan most common ports":"Сканировать наиболее распространенные порты",
        "<n> of ports":"<n> портов",
        "Scans ports with a ratio":"Сканирование портов с рейтингом",
        "Scans all ports with a ratio greater than the one given":"Сканирует все порты, чей рейтинг больше числа",
        "<ratio> must be between 0.0 and 1.0":"<рейтинг> - десятичное число между 0 и 1",

        "Service detection":"Обнаружение службы",
        "Service":"Служба",
        "Service determine":"Определение службы",
        "Probe open ports to determine service/version info":"Исследовать открытые порты для определения информации о службе/версии",
        "Intensity":"Интенсивность",
        "<level>: Set from 0 (light) to 9 (try all probes)":"<уровень>: Устанавливать от 0 (легкое) до 9 (пробовать все запросы)",
        "Likely probes":"Легкие запросы",
        "Limit to most likely probes (intensity 2)":"Ограничиться наиболее легкими запросами (интенсивность 2)",
        "Every probe":"Каждый запрос",
        "Try every single probe (intensity 9)":"Использовать каждый единичный запрос (интенсивность 9)",
        "Detailed info":"Подробная информация",
        "Show detailed version scan activity (for debugging)":"Выводить подробную информацию о процессе сканирования (для отладки)",

        "OS":"ОС",
        "OS detection":"Определение ОС",
        "Enable OS detection":"Активировать функцию определения ОС",
        "Promising targets":"Перспективные хосты",
        "Limit OS detection to promising targets":"Использовать функцию определения ОС только для 'перспективных' хостов",
        "Guess OS":"Угадывать OS",
        "Guess OS more aggressively":"Агрессивно угадывать результаты определения ОС",

        "Firewall":"Фаервол",
        "Fragment packets":"Фрагментировать пакеты",
        "MTU of fragment":"MTU фрагментации",
        "Fragment packets with given MTU":"Фрагментировать пакеты с заданным значением MTU",
        "MTU value":"Значение MTU",
        "Cloak a scan":"Маскировка сканирования",
        "Cloak a scan with decoys":"Маскировка сканирования с помощью фиктивных хостов",
        "<decoy1,decoy2[,ME],...>":"<фикт_хост1,фикт_хост2[,ME],...>",
        "Spoof address":"Изменить адрес",
        "Spoof source address":"Изменить исходный адрес",
        "<IP_Address>":"<IP_адрес>",
        "Use specified interface":"Изменить исходный адрес",
        "<iface>":"<интерфейс>",
        "Use port":"Использовать порт",
        "Use given port number":"Использовать заданный номер порта",
        "<portnum>":"<номер_порта>",
        "Random data":"Произвольные данные",
        "Append random data to sent packets":"Добавить произвольные данные к посылаемым пакетам",
        "<num>":"<число>",
        "Ip options":"IP опции",
        "Send packets with specified ip options":"Посылать пакет с заданным ip опциями",
        "<options>":"<опции>",
        "IP life time":"Время жизни IP",
        "Set IP time-to-live field":"Установить IP поле time-to-live (время жизни)",
        "Life time value":"Значение времени жизни",
        "MAC address":"MAC адрес",
        "Spoof your MAC address":"Задать собственный MAC адрес",
        "<mac address/prefix/vendor name>":"<MAC_адрес/префикс/название производителя>",
        "Bogus checksum":"Фиктивные контрольные суммы",
        "Send packets with a bogus TCP/UDP/SCTP checksum":"Посылать пакеты с фиктивными TCP/UDP контрольными суммами",

        "Misc":"Разное",
        "Enable IPv6 scanning":"Включить IPv6 сканирование",
        "Enable all":"Включить все",
        "Enable OS detection, version detection, script scanning, and traceroute":"Активировать функции определения ОС и версии, сканирование с использованием скриптов и трассировку",
        "Any params":"Любые параметры",

        "Time":"Время",
        "Timing template":"Временной шаблон",
        "<0-5>: Set timing template (higher is faster)":"<0-5>: Установить шаблон настроек управления временем (больше - быстрее)",
        "0 - paranoid":"0 - паранойдный",
        "1 - sneaky":"1 - хитрый",
        "2 - polite":"2 - вежливый",
        "3 - normal":"3 - обычный",
        "4 - aggressive":"4 - агрессивный",
        "5 - insane":"5 - безумный",
        "Group sizes":"Размер групп",
        "Parallel host scan group sizes":"Установить размер групп хостов для параллельного сканирования",
        "<hosts number>":"колличество хостов",
        "Probe parallelization":"Распараллеливание запросов",
        "Waiting time":"Время ожидания",
        "Specifies probe round trip time":"Регулирует время ожидания ответа на запрос",
        "<time>":"<время>",
        "Retries number":"Количество попыток",
        "Caps number of port scan probe retransmissions":"Задает максимальное количество повторных передач запроса",
        "<tries>":"<количество попыток>",
        "Host time-out":"Тайм-аут хоста",
        "Give up on target after this long":"Прекращает сканирование медленных целей",
        "Scan delay":"Задержка сканирования",
        "Adjust delay between probes":"Регулирует задержку между запросами",
        "Rate":"Скорость",
        "Send packets no slower than <number> per second":"Посылать запросы с интенсивностью не меньше чем <число> в секунду",
        "<number>":"<число>",

        "Intense scan":"Интенсивное",
        "Intense scan plus UDP":"Интенсивное плюс UDP",
        "Intense scan, all TCP ports":"Интенсивное, все TCP порты",
        "Intense scan, no ping":"Интенсивное, нет пинга",
        "Ping scan":"Пинг-сканирование",
        "Quick scan":"Быстрое сканирование",
        "Quick scan plus":"Быстрое сканирование плюс",
        "Quick traceroute":"Быстрый трейсер",
        "Regular scan":"Обычное сканирование",
        "Slow comprehensive scan":"Медленное всестороннее сканирование",

        "Template":"Шаблон",
        "Apply template":"Применить шаблон"
    };

webix.i18n.locales["ru-RU"].sormui.TrafficManagementGenerator =
    {
        "UDP flood generator":"UDP flood генератор",
        "HTTP flood generator":"HTTP flood генератор",
        "TCP SYN flood generator":"TCP SYN flood генератор",
        "Pcap sample generator":"Pcap sample генератор",

        "New":"Новый",
        "Launched":"Запущен",
        "Stopped":"Остановлен",
        "Error":"Ошибка",
        "Initialization":"Инициализация",
        "Generation":"Генерация",

        "Check":"Проверить",
        "Check and save":"Проверить и сохранить",
        "Save without checking":"Сохранить без проверки",

        "Profiles":"Профили",
        "Add profile":"Добавить профиль",
        "Add to current profile":"Добавить к текущему профилю",

        "Profile":"Профиль",
        "Date":"Дата",
        "User":"Пользователь",
        "Status":"Статус",

        "Profile settings":"Настройки профиля",
        "Start generation":"Запустить генерацию",
        "Stop generation":"Остановить генерацию",
        "General profile settings":"Общие настройки профиля",

        "Name":"Название",
        "Profile name":"Название профиля",

        "Source":"Источник",
        "IP address, range, CIDR":"IP-адрес, диапазон, CIDR",
        "IP port, range":"IP-порт, диапазон",
        "IP port, range, for example 2000-65000":"IP-порт, диапазон, например 2000-65000",
        "Receiver":"Получатель",
        "IP address, range, CIDR, for example, 192.168.1.1/16":"IP-адрес, диапазон, CIDR, например, 192.168.1.1/16",
        "PCI devices":"PCI-устройства",
        "PCI device (network port) that uses a generator to receive / send generated traffic. You can specify several":"PCI-устройство (сетевой порт), которое использует генератор для приема/отправки сгенерированного трафика. Можно указывать несколько",
        "Required parameter":"Обязательный параметр",
        "Run time":"Время работы",
        "The generator's running time in seconds is accurate to a tenth of a second, for example 1.5 - one and a half seconds. Unless indicated, the generator will work endlessly":"Время работы генератора в секундах с точностью до десятой доли секунды, например 1.5 – полторы секунды. Если не указано, генератор будет работать бесконечно",
        "for example 1.5":"например 1.5",
        "Number, optional":"Число, необязательное",
        "Cores":"Ядра",
        "The list of cores that the generator uses":"Список ядер, которые задействует генератор",
        "for example 0-2":"например 0-2",
        "Range, for example 0-2":"Диапазон, например 0-2",
        "Memory":"Память",
        "The memory size allocated for each socket (processor), in megabytes":"Размер памяти, аллоцируемый для каждого сокета (процессора), в мегабайтах",
        "Number":"Число",

        "Add a generator":"Добавить генератор",
        "Generators":"Генераторы",
        "Generator":"Генератор",

        "Generator settings":"Настройки генератора",
        "Common":"Общие",
        "Generator name":"Название генератора",
        "Type":"Тип",
        "Generator type":"Тип генератора",

        "Required":"Обязательные",
        "If the flag is not set, the general profile settings are used":"Если флаг не установлен, используется общие настройки профиля",

        "Optional":"Необязательные",
        "Payload size":"Размер payload",

        "Payload size in bytes, maximum 65500":"Размер payload в байтах, максимально 65500",
        "Arbitrary payload":"Произвольный размер payload",

        "Max data block":"Maк. блок данных",
        "Maximum size of a useful data block in bytes":"Максимальный размер полезного блока данных в байтах",

        "TCP window size":"Размер TCP окна",
        "TCP window size in bytes":"Размер TCP окна в байтах",
        "URL string":"Строка URL",

        "MAC source":"Источник MAC",
        "If not specified - random MAC address will be generated":"Если не задан – будет сгенерирован случайный MAC-адрес",
        "MAC-source MAC address, format 'xx: xx: xx: xx: xx: xx'. If not specified, a random MAC address will be generated":"MAC - MAC адрес источника, формат 'xx:xx:xx:xx:xx:xx'. Если не задан – будет сгенерирован случайный MAC-адрес",
        "MAC receiver":"Получатель MAC",
        "MAC-receiver MAC address, format 'xx: xx: xx: xx: xx: xx'. If not specified, a random MAC address will be generated":"MAC - MAC адрес получателя, формат 'xx:xx:xx:xx:xx:xx'. Если не задан – будет сгенерирован случайный MAC-адрес",

        "File":"Файл",
        "Attach a pcap file":"Прикрепить pcap-файл",

        "Generation statistics":"Статистика генерации",

        "You must specify a list of cores. The maximum number is ":"Необходимо задать список ядер. Максимальное количество - ",
        "It is necessary to specify a different range of cores for each generator":"Необходимо задать разный диапазон ядер для каждого генератора",
        "You must set the memory size in megabytes":"Необходимо задать размер памяти в мегабайтах",
        "You must specify network ports":"Необходимо задать сетевые порты",
        "You must specify a different set of PCI ports for each generator":"Необходимо задать разный набор PCI-портов для каждого генератора",
        "You need to set the time of work":"Необходимо задать время работы",
        "It is necessary to set the IP-range of the source":"Необходимо задать IP-дипазон источника",
        "You must specify a range of source ports":"Необходимо задать диапазон портов источника",
        "It is necessary to set the receiver's IP range":"Необходимо задать IP-дипазон получателя",
        "You must specify a range of destination ports":"Необходимо задать диапазон портов получателя",
        "You must specify a URL":"Необходимо задать URL",
        "You need to attach a file":"Необходимо прикрепить файл",
        "Profile is valid":"Профиль верный",
        "Profile saved":"Профиль сохранен",
        "No generator set":"Не задан ни один генератор",

        "No file":"Нет файла",

        "Generation started successfully":"Генерация запущена успешно",
        "Generation not running":"Генерация не запущена",
        "Generation is partially started":"Генерация запущена частично",
        "Generation is stopped":"Генерация остановлена",

        "Generation efficiency":"Производительность генерации",
        "Generation log":"Лог генерации",

        "from":"от",
        "Status":"Статус",
        "launched":"запущен",
        "not launched":" не запущен",

        "Gigabit/sec":"Гигабит/сек",
        "Total":"Всего",
        "Port ":"Порт "
    };

webix.i18n.locales["ru-RU"].sormui.TrafficManagementBlockAndRedirect =
    {
        "Profiles":"Профили",
        "List of nodes scan profiles":"Список профилей сканирования узлов",
        "Add profile":"Добавить профиль",
        "Add subprofile":"Добавить подпрофиль",

        "Profile":"Профиль",
        "Date":"Дата",
        "User":"Пользователь",
        "Status":"Статус",

        "Enable profile":"Включить профиль",
        "Disable profile":"Отключить профиль",

        "List of black and white profiles":"Список черных и белых профилей",
        "Black and white lists":"Чёрные и белые списки",

        "Black":"Чёрный",
        "White":"Белый",

        "Disabled":"Выключен",
        "Enabled":"Включен",

        "Type":"Тип",
        "Type: black or white":"Тип: чёрный или белый",

        "Black or white profile":"Чёрный или белый профиль",
        "Description":"Описание",
        "IP-addresses":"IP-адреса",
        "IP-addresses or CIDRs. Each with a new line":"IP-адреса или CIDR. Каждый с новой строки",

        "Resource":"Ресурс",
        "Redirect":"Переадресовывать",
        "URL to redirect":"URL для переадресации",

        "Add list item":"Добавить в список",
        "Import from file":"Импортировать из файла",

        "Profile enabled":"Профиль включен",
        "Profile not enabled":"Профиль не включен",
        "Profile disabled":"Профиль выключен",
        "Profile not disabled":"Профиль не выключен",

        "List item saved":"Запись сохранена",

        "You should set url to redirect":"Вы должны установить URL для перенаправления",
        "You should set ip addresses or CIDRs":"Вы должны установить IP-адреса или CIDR-ы",
        "You should set url to redirect when white list is enabled":"Вы должны установить URL-адрес для перенаправления, когда включен белый список"
    };

webix.i18n.locales["ru-RU"].sormui.Roles =
    {
        "Are you sure you want to delete the role?":"Вы действительно хотите удалить роль?",

        "Roles":"Роли",
        "Add role":"Добавить роль",

        "Role":"Роль",

        "Program sections":"Разделы программы",
        "Code":"Код",
        "Section":"Раздел",
        "Read":"Чтение",
        "Record":"Запись"
    };

webix.i18n.locales["ru-RU"].sormui.Users =
    {
        "Are you sure you want to delete the user?":"Вы действительно хотите удалить пользователя?",
        "User name":"Имя пользователя",
        "Enter user name":"Введите имя пользователя",
        "Full name":"ФИО",
        "Enter full name":"Введите ФИО полностью",
        "Enter e-mail":"Введите e-mail",
        "Phone":"Телефон",
        "Enter phone number":"Введите номер телефона",
        "Company":"Компания",
        "Enter the name of the organization":"Введите название организации",
        "Position":"Должность",
        "Enter the position":"Введите должность",
        "Role":"Роль",

        "Passwords do not match":"Пароли не совпадают",

        "New password":"Новый пароль",
        "Enter password":"Введите пароль",
        "Confirm password":"Подтвердить пароль",

        "Add user":"Добавить пользователя",
        "Users":"Пользователи"
    };

webix.i18n.locales["ru-RU"].sormui.Health =
    {
        "[INFO]":"[ИНФО]",
        "[ERROR]":"[ОШИБКА]",
        "success":"успех",

        "Verify services health":"Проверьте работоспособность сервисов"
    };

webix.i18n.locales["ru-RU"].sormui.CreateTicket =
    {
        "Error message":"Сообщение об ошибке",
        "Question":"Вопрос",
        "Suggestion":"Предложение",
        "Message":"Сообщение",

        "Send a message":"Отправить сообщение",
        "Message subject":"Предмет сообщения",
        "Message text":"Текст сообщения",
        "Enter here any text":"Введите сюда любой текст",

        "DPI messages log":"Лог сообщений DPI",
        "Log of information messages and errors":"Лог информационных сообщений и ошибок",
        "DPI statistics log":"Лог статистики DPI",
        "UI log":"UI лог",

        "Send":"Отправить",
        "Message sent successfully":"Сообщение успешно отправлено"
    };

webix.i18n.locales["ru-RU"].sormui.Dics =
    {
        "Enter the number":"Введите число",
        "Enter the IP address and port number":"Введите IP адрес и номер порта",
        "Enter the IP address":"Введите IP адрес",
            "Field is required": "Поле обязательно к заполнению"
    };

webix.i18n.locales["ru-RU"].sormui.TrafficDecode = {
        "Title": "Название",
        "Name": "Название",
        "Description": "Описание",
        "Tasks": "Задачи",
        "Add task": "Добавить задачу",
        "Refresh": "Обновить",
        "Task": "Задача",
        "User": "Пользователь",
        "On the page": "На странице",
        "Files": "Файлы",
        "Add file": "Добавить файл",
        "File": "Файл",
        "Status": "Статус",
        "Decode started": "Разбор начат",
        "Decode finished": "Разбор завершен",
        "Results": "Результаты",
        "Task saved": "Задача сохранена",
        "Task deleted": "Задача удалена",
        "New": "Новый",
        "Preparing": "Подготовка",
        "Decoding": "Разбор",
        "Finished": "Завершен",
        "Error": "Ошибка",
        "Pcap-file": "Pcap-файл",
        "File deleted": "Файл удален",
        "Requests": "Запросы",
        "Images": "Изображения",
        "Method": "Метод",
        "Decoded web items": "Декодированные веб-элементы",
        "Host": "Хост",
        "Address": "Адрес",
        "CName" : "Сертификат",
        "Server name": "Сервер",
        "Domain name": "Домен",
        "DNS info": "Информация о DNS",
        "Request info": "Информация о запросе",
        "Request date": "Дата запроса",
        "Response time": "Время ответа",
        "Decoded DNS items": "Декодированные данные DNS",

        "Src port": "Порт отправителя",
        "Dst port": "Порт получателя",
        "Ip proto": "Протокол Ip",
        "Ip version": "Версия Ip",
        "Src ip": "Ip отправителя",
        "Dst ip": "Ip получателя",
        "Eth type": "Тип Eth",
        "Src eth": "Eth отправителя",
        "Dst eth": "Eth получателя",
        "Row Id": "ИД записи",
        "File Id": "ИД файла",
        "File name": "Имя файла",


        "User agent": "Агент пользователя",
        "Url": "Урл",
        "Content type": "Тип содержимого",
        "Content encoding": "Кодировка контента",
        "Request method": "Метод запроса",
        "Response code": "Код ответа",
        "Chunk size": "Размер ответа",
        "Tcp time": "Время Tcp",
        "Response file": "Файл ответа",
        "Are you sure you want to reset decode queue for selected pcaps?": "Вы уверены, что хотите перезапустить очередь для разбора файла?",
        "Reset decode queue for selected files": "Перезапустить очередь для разбора файла",
        "Decode queue reset": "Очередь для разбора файла перезапущена",
        "Reset_": "Перезапустить",

        "Decoded Mail items": "Декодированные данные Email",
        "Sender": "Отправитель",
        "Receiver": "Получатель",
        "Subject": "Тема",
        "Message ID": "ИД Письма",
        "Mime version": "Версия MIME",
        "Language": "Язык",
        "IMF info": "Информация о IMF",
        "Multipart type": "Составной тип",
        "MIME info": "Информация о MIME",
        "Multipart content type": "Тип составного содержимого",
        "Multipart content encoding": "Кодировка составного содержимого",
        "Multipart content disposition": "Диспозиция составного содержимого",
        "Tcp stream": "Поток Tcp",

        "Requests logs": "Логи запросов",
        "Voip info": "Информация Voip",
        "From": "От",
        "To": "К",
        "Call ID": "ID звонка",
        "Rtp info": "Информация Rtp",
        "Ssrc from": "Ssrc от",
        "Ssrc to": "Ssrc к",
        "File from": "Файл от",
        "File to": "Файл к",
        "Decoded Voip items": "Декодированные данные Voip",
        "Duration": "Продолжительность",
        "File size": "Размер файла",
        "File size (bytes)": "Размер файла (байты)",
        "Ftp directory": "Директория Ftp",
        "Ftp info": "Информация Ftp",
        "Decoded Ftp items": "Декодированные данные Ftp",

        "Processing": "В процессе",
        "Success": "Успешно",
        "Skipped": "Пропущенно",
        "Log data": "Данные лога",
        "Are you sure you want to delete this row?": "Вы уверены, что хотите удалить эту строку?",
        "Traffic decode queue": "Очередь разбора трафика",
        "Row ID": "ИД Строки",
        "Type": "Тип",
        "Action": "Действие",
        "Decode type": "Тип разбора",
        "Row deleted": "Строка удалена",


        "File uploaded": "Файл загружен",
        "File uploading error": "Ошибка загрузки файла",
        "File saved": "Файл сохранен",
        "Caller": "Вызывающий",
        "Called": "Вызываемый",
        "Upload": "Загрузка",
        "Download": "Скачивание",
        "Direction": "Направление",
        "Records": "Записи",
        "Record": "Запись",
        "Combined record": "Комбинированная запись",
        "Key": "Ключ",
        "Value": "Значение",
        "Mail info": "Данные E-mail",
        "Decode types": "Типы разбора",
        "Do you want to reset decode queue for this pcap?": "Хотите перезапустить очередь для разбора файла?",
        "Downloading": "Загружается",
        "Client": "Клиент",
        "Server": "Сервер"
};
webix.i18n.locales["en-US"].sormui = {};

webix.i18n.locales["en-US"].sormui.AppView =
    {
        Title:"CKAT",

        "Traffic analysis":"Traffic analysis",
        "Statistics":"Statistics",
        "Connections":"Connections",
        "Traffic control":"Traffic control",
        "Traffic capture":"Traffic capture",
        "Traffic generation":"Traffic generation",
        "Blocking and redirection":"Blocking and redirection",
        "Cloning a website":"Cloning a website",
        "Network utilities":"Network utilities",
        "Network audit":"Network audit",
        "DPI control":"DPI control",
        "Performance":"Performance",
        "Configuration":"Configuration",
        "Performance and statistics":"Performance and statistics",
        "Logs":"Logs",
        "Send error":"Send error",
        "Services":"Services",
        "Health map":"Health map",
        "My profile":"My profile",
        "Users":"Users",
        "Roles":"Roles",
        "Managing users and roles":"Managing users and roles",

        "Log out":"Log out",

        "Edit profile":"Edit profile",
        "Username":"Username",
        "Enter user name":"Enter user name",
        "Full name":"Full name",
        "Last name First name Middle name":"Last name First name Middle name",
        "Enter your full name":"Enter your full name",
        "Position":"Position",
        "Enter your position":"Enter your position",
        "Organization":"Organization",
        "Name of the organization":"Name of the organization",
        "Enter the name of the organization":"Enter the name of the organization",
        "Enter your e-mail":"Enter your e-mail",
        "Phone":"Phone",
        "Contact number":"Contact number",
        "Enter phone number":"Enter phone number",
        "Change password":"Change password",
        "Old password":"Old password",
        "Enter password":"Enter password",
        "New password":"New password",
        "Confirm password":"Confirm password",
        "Passwords do not match":"Passwords do not match",
        "Traffic decode logs": "Traffic decode logs"
    };

webix.i18n.locales["en-US"].sormui.LoginView =
    {
        "Sign in to control panel CKAT":"Sign in to control panel CKAT",
        "Login":"Login",
        "Enter login":"Enter login",
        "Password":"Password",
        "Enter password":"Enter password",
        "Remember me":"Remember me",
        "Sign in":"Sign in"
    };

webix.i18n.locales["en-US"].sormui.All =
    {
        "Yes":"Yes",
        "No":"No",

        "No connection":"No connection",
        "In developing":"In developing",

        "Russian":"Russian",
        "Rus.":"Rus.",
        "English":"English",
        "Eng.":"Eng.",

        "Refresh":"Refresh",
        "Cancel":"Cancel",
        "Delete":"Delete",
        "Close":"Close",
        "Save":"Save",
        "Add value":"Add value",
        "Upload from file":"Upload from file",
        "Download from history":"Download from history",

        "Are you sure you want to delete the selected records?":"Are you sure you want to delete the selected records?",

        "Gb":"Gb",

        "The form":"The form",
        "Editor":"Editor",

        "Settings":"Settings",

        "Total": "Total",

        "byte":"byte",

        "Deleted":"Deleted",

        "Profile saved":"Profile saved",
        "Task saved":"Task saved",
        "Saved":"Saved",

        "Are you sure you want to delete the task?":"Are you sure you want to delete the task?",
        "Are you sure you want to delete the profile?":"Are you sure you want to delete the profile?",
        "Are you sure you want to delete the file?":"Are you sure you want to delete the file?",
        "Are you sure you want to delete the selected files?":"Are you sure you want to delete the selected files?",
        "Are you sure you want to delete this record?":"Are you sure you want to delete this record?",

        "On page":"On page",

        "from":"from",

        "Export to PDF":"Export to PDF",
        "Export to Excel":"Export to Excel",

        "Tail":"Tail",
        "tail":"tail",

        "firts packets":"firts packets",

        "On":"On",
        "Off":"Off",

        "Total":"Total",
        "Done":"Done",

        "Unhandled error":"Unhandled error",
        "Send error":"Send error",

        "Parser error":"Parser error",

        "Import":"Import",
        "Imported successfully":"Imported successfully",

        "Communication operators":"Communication operators",
        "Operators":"Operators",
        "Operator":"Operator",

        "Bytes":"Bytes",
        "Kb":"Kb",
        "Mb":"Mb",
        "Gb":"Gb",
        "Tb":"Tb",

        "Size":"Size"
    };

webix.i18n.locales["en-US"].sormui.ConnectionsStatistics =
    {
        "Period":"Period",
        "Interval":"Interval",
        "1 minute":"1 minute",
        "1 hour":"1 hour",
        "1 day":"1 day",
        "1 month":"1 month",

        "Total traffic":"Total traffic",
        "Incoming traffic (byte)":"Incoming traffic (byte)",
        "Incoming traffic":"Incoming traffic",
        "Incoming locked traffic":"Incoming locked traffic",
        "Outgoing traffic (byte)":"Outgoing traffic (byte)",
        "Outgoing traffic":"Outgoing traffic",
        "Outgoing locked traffic":"Outgoing locked traffic",
        "Protocol traffic":"Protocol traffic",

        "Object":"Object",
        "Incoming":"Incoming",
        "Outgoing":"Outgoing",
        "Locked":"Locked",

        "Filter":"Filter",
        "Number of displayed objects":"Number of displayed objects",
        "Filter by objects: each with a new line":"Filter by objects: each with a new line",
        "In a separate window":"In a separate window",

        "Top":"Top",
        "Top traffic table":"Top traffic table",
        "Top protocols":"Top protocols",
        "Top traffic":"Top traffic",
        "Top services":"Top services",
        "Top subscribers":"Top subscribers"
    };

webix.i18n.locales["en-US"].sormui.ConnectionsValidateData =
    {
        "Enable search":"Enable search",
        "Connections search request":"Connections search request",
        "Description":"Description",
        "Period":"Period",
        "Subscriber":"Subscriber",
        "Source IP":"Source IP",
        "Source port":"Source port",
        "IP address or IP address:port":"IP address or IP address:port",
        "Destination IP":"Destination IP",
        "Destination port":"Destination port",
        "Max number of entries":"Max number of entries",
        "Max number of records in query results":"Max number of records in query results",
        "Resources and services":"Resources and services",
        "Aaa subscribers sessions":"Aaa subscribers sessions",
        "Subscriber login":"Subscriber login",
        "Subscriber password":"Subscriber password",
        "Url, for example somehost.ru/some/*":"Url, for example somehost.ru/some/*",
        "Url":"Url",
        "Ftp-login":"Ftp-login",
        "Ftp-password":"Ftp-password",
        "The sender, for example petya@gmail.com":"The sender, for example petya@gmail.com",
        "Sender":"Sender",
        "The recipient of a copy of the email message":"The recipient of a copy of the email message",
        "The message subject":"The message subject",
        "The message text":"The message text",
        "Copy":"Copy",
        "The recipient, for example, vasya@gmail.com":"The recipient, for example, vasya@gmail.com",
        "Recipient":"Recipient",
        "Name of the server through which the message was sent":"Name of the server through which the message was sent",
        "Attachements":"Attachements",
        "The presence of attachments in the letter":"The presence of attachments in the letter",
        "Caller's phone number":"Caller's phone number",
        "Phone number of called":"Phone number of called",
        "Caller's internal number":"Caller's internal number",
        "Internal number of called":"Internal number of called",
        "Caller type":"Caller type",
        "Type of called":"Type of called",
        "Subscriber of this switch":"Subscriber of this switch",
        "Subscriber of communication network":"Subscriber of communication network",
        "Rowmer":"Rowmer",
        "Flows":"Flows",
        "Protocol code":"Protocol code",
        "Protocol code in accordance with RFC1700 or port number for TCP / UDP":"Protocol code in accordance with RFC1700 or port number for TCP / UDP",
        "Terminal sessions":"Terminal sessions",
        "Aaa sessions":"Aaa sessions",
        "User equipment":"User equipment",
        "Sender's User ID":"Sender's User ID",
        "Sender ID":"Sender ID",
        "Receiver's User ID":"Receiver's User ID",
        "Receiver ID":"Receiver ID",
        "Public sender name":"Public sender name",
        "Public receiver name":"Public receiver name",

        "Are you sure you want to delete the request?":"Are you sure you want to delete the request?",

        "Reports":"Reports",
        "Add request":"Add request",
        "Add subquery":"Add subquery",
        "Requests":"Requests",
        "Date":"Date",
        "User":"User",
        "Status":"Status",
        "Data":"Data",
        "On the page":"On the page",
        "Create subquery":"Create subquery",
        "Clear filters":"Clear filters",

        "Connection start":"Connection start",
        "Subject":"Subject",
        "Message":"Message",
        "Reply":"Reply",
        "Client IP":"Client IP",
        "Client port":"Client port",
        "Server IP":"Server IP",
        "Server port":"Server port",
        "Protocol":"Protocol",
        "Point":"Point",
        "Connection point":"Connection point",
        "Connection point identifier to the data network":"Connection point identifier to the data network",
        "Event type":"Event type",
        "Size":"Size",
        "Files":"Files",
        "Email protocol":"Email protocol",
        "Completion reason":"Completion reason",
        "Connection completion reason":"Connection completion reason",
        "Connection end":"Connection end",

        "Username":"Username",
        "User password":"User password",
        "Server name":"Server name",
        "Server mode":"Server mode",
        "Bytes recieved":"Bytes recieved",
        "Bytes sent":"Bytes sent",
        "Caller's number":"Caller's number",
        "Name of the initiator of communication":"Name of the initiator of communication",
        "Initiator name":"Initiator name",
        "Called number":"Called number",
        "Session ID":"Session ID",
        "Conference ID":"Conference ID",
        "Duration of conversation":"Duration of conversation",
        "Duration of conversation, sec.":"Duration of conversation, sec.",
        "Sending a fax":"Sending a fax",
        "Incoming bunch":"Incoming bunch",
        "ID of inbound bunch":"ID of inbound bunch",
        "for GSM network":"for GSM network",
        "for W/CDMA, UMTS network":"for W/CDMA, UMTS network",
        "Outbound bunch":"Outbound bunch",
        "ID of outbound bunch":"ID of outbound bunch",
        "Gateway":"Gateway",
        "Voip protocol":"Voip protocol",
        "Connection method":"Connection method",
        "Service ID":"Service ID",

        "Url address":"Url address",
        "Http method":"Http method",
        "The data size in bytes":"The data size in bytes",
        "Login":"Login",
        "Authorization result":"Authorization result",

        "Sender name":"Sender name",
        "Sender ID":"Sender ID",
        "Receiver name":"Receiver name",
        "Receiving ID":"Receiving ID",
        "Subscriber ID":"Subscriber ID",
        "Message size":"Message size",

        "Terminal protocol":"Terminal protocol",
        "Extension number of the caller":"Extension number of the caller",
        "Extension number of the called subscriber":"Extension number of the called subscriber",
        "Dialed number":"Dialed number",
        "Connection duration":"Connection duration",
        "Call type":"Call type",
        "Additional services for connection":"Additional services for connection",
        "Type of called subscriber":"Type of called subscriber",
        "Switch code":"Switch code",
        "Border switch code":"Border switch code",
        "Phone card number":"Phone card number",
        "Forwarding number":"Forwarding number",
        "Cause of connection termination":"Cause of connection termination",

        "Connection date and time":"Connection date and time",
        "Login type":"Login type",
        "IP address":"IP address",
        "Connection type":"Connection type",
        "Connecting to data network":"Connecting to data network",
        "Disconnecting from data network":"Disconnecting from data network",
        "Calling number":"Calling number",
        "Called number":"Called number",
        "after conv.":"after conv.",
        "after conversion":"after conversion",
        "in the E164 network":"in the E164 network",
        "IP address of the NAS server":"IP address of the NAS server",
        "NAS server port":"NAS server port",
        "Received data":"Received data",
        "Sent data":"Sent data",
        "Access point (APN)":"Access point (APN)",
        "Service area code (SAC)":"Service area code (SAC)",
        "IMSI of mobile subscriber":"IMSI of mobile subscriber",
        "IMEI of mobile subscriber":"IMEI of mobile subscriber",
        "Mobile station ID of the subscriber":"Mobile station ID of the subscriber",
        "Mobile station ID":"Mobile station ID",
        "Modem pool number":"Modem pool number",
        "MAC address":"MAC address",
        "ATM virtual path number (VPI)":"ATM virtual path number (VPI)",
        "ATM virtual channel number (VCI)":"ATM virtual channel number (VCI)",
        "IP address of GPRS / EDGE SGSN":"IP address of GPRS / EDGE SGSN",
        "IP address of GPRS / EDGE GGSN":"IP address of GPRS / EDGE GGSN",
        "Base station (connection established)":"Base station (connection established)",
        "Base station zone code (connection established)":"Base station zone code (connection established)",
        "Base station code (connection established)":"Base station code (connection established)",
        "Timing advance of the base station (connection established)":"Timing advance of the base station (connection established)",
        "Base station (connection completed)":"Base station (connection completed)",
        "Base station zone code (connection completed)":"Base station zone code (connection completed)",
        "Base station code (connection completed)":"Base station code (connection completed)",
        "Timing advance of the base station (connection completed)":"Timing advance of the base station (connection completed)",

        "<br/><br/><br/>Synchronizing data":"<br/><br/><br/>Synchronizing data",
        "Synchronizing data":"Synchronizing data",
        "Data loading":"Data loading",
        "Loaded":"Loaded",
        "of":"of",
        "Number of attempts":"Number of attempts",

        "Query saved":"Query saved",
        "Search terms in processing: ":"Search terms in processing: ",
        "We were unable to process your searches: ":"We were unable to process your searches: ",
        "No search requests in processing":"No search requests in processing",

        "Request":"Request",
        "from":"from",
        "Status":"Status",
        "Total":"Total",

        "Value, list separated by commas":"Value, list separated by commas",
        "Results":"Results",

        "Data is not ready":"Data is not ready",
        "Data ready":"Data ready",
        "Data not found":"Data not found",
        "Error":"Error",
        "Data canceled":"Data canceled",
        "Data deleted":"Data deleted",

        "You must enable at least one query parameter":"You must enable at least one query parameter",

        "Active":"Active",
        "Passive":"Passive",

        "Try again":"Try again"
    };

webix.i18n.locales["en-US"].sormui.TrafficManagementCapture =
    {
        "Profiles":"Profiles",
        "List of profiles for traffic capture":"List of profiles for traffic capture",
        "Add profile":"Add profile",
        "Add subprofile":"Add subprofile",

        "Start capturing":"Start capturing",
        "Stop capturing":"Stop capturing",

        "Profile":"Profile",
        "Date":"Date",
        "User":"User",
        "Status":"Status",

        "Profile for traffic capture":"Profile for traffic capture",
        "Task title / description":"Task title / description",
        "Description":"Description",
        "IP-address":"IP-address",
        "IP address or CIDR":"IP address or CIDR",
        "IP-address, range, CIDR":"IP-address, range, CIDR",
        "Raw packages":"Raw packages",
        "Send raw packages without analysis":"Send raw packages without analysis",
        "Channel data":"Channel data",
        "Send ethernet frames or IP packets (only if the option Raw packages enabled)":"Send ethernet frames or IP packets (only if the option Raw packages enabled)",
        "Disable traffic control":"Disable traffic control",
        "Enable buffer analyzer":"Enable buffer analyzer",

        "Enabled":"Enabled",
        "Disabled":"Disabled",

        "Traffic capture enabled":"Traffic capture enabled",
        "Could not enable traffic capture":"Could not enable traffic capture",

        "Traffic capture disabled":"Traffic capture disabled",
        "Could not disable traffic capture":"Could not disable traffic capture",

        "Pcap files":"Pcap files",
        "File":"File",
        "Content":"Content",
        "Download pcap file completely":"Download pcap file completely",

        "Time":"Time",
        "Source":"Source",
        "Destination":"Destination",
        "Info":"Info",

        "Condition":"Condition",
        "IPv4 address":"IPv4 address",
        "Incorrect":"Incorrect",

        "Subscriber login":"Subscriber login",

            "Task": "Task",
            "Task name": "Task name",
            "Field is required": "Field is required",
            "Decode files": "Decode files",
            "New task": "New task",
            "Tasks for pcap decoding successfully created!": "Tasks for pcap decoding successfully created!",
            "Do you want to open Traffic decode section?": "Do you want to open Traffic decode section?",
            "Open": "Open"
    };

webix.i18n.locales["en-US"].sormui.TrafficManagementSiteClone =
    {
        "Sites":"Sites",
        "Tasks for downloading sites": "Tasks for downloading sites",

        "Add task to site download":"Add task to site download",
        "Add a subtask to site download":"Add a subtask to site download",
        "Start scanning":"Start scanning",
        "Stop scanning":"Stop scanning",
        "Data folder properties":"Data folder properties",
        "Download zip-archive":"Download zip-archive",
        "Clear Folder":"Clear Folder",

        "Task":"Task",
        "Date":"Date",
        "User":"User",
        "Status":"Status",
        "Http status":"Http status",
        "Virt. host":"Virt. host",
        "Virtual host":"Virtual host",
        "Virtual port":"Virtual port",

        "Statistics":"Statistics",
        "Statistics log":"Statistics log",
        "Files statistics":"Files statistics",

        "Download the log file":"Download the log file",
        "Export to Excel":"Export to Excel",

        "Path":"Path",
        "Bytes loaded":"Bytes loaded",
        "Total bytes":"Total bytes",
        "Percent":"Percent",
        "Total (bytes)":"Total (bytes)",
        "Uploaded (bytes)":"Uploaded (bytes)",

        "The task for site downloading":"The task for site downloading",
        "Extra options":"Extra options",
        "Task title / description":"Task title / description",
        "Description":"Description",
        "Main settings":"Main settings",

        "URLs":"URLs",
        "URLs list to download: each URL from a new line.":"URLs list to download: each URL from a new line.",

        "Recursion":"Recursion",
        "Recursive download":"Recursive download",
        "Enable recursive download":"Enable recursive download",
        "Recursion depth":"Recursion depth",
        "Recursion depth (inf and 0 - infinity)":"Recursion depth (inf and 0 - infinity)",

        "Download requisites":"Download requisites",
        "Download all the images  necessary to display the HTML page and so on":"Download all the images  necessary to display the HTML page and so on",
        "Convert references":"Convert references",
        "Make links in downloaded HTML or CSS point to local files":"Make links in downloaded HTML or CSS point to local files",
        "Backup":"Backup",
        "Before converting file X, back up as X.orig":"Before converting file X, back up as X.orig",
        "Create site mirror":"Create site mirror",
        "Shortcut for -N -r -l inf --no-remove-listing":"Shortcut for -N -r -l inf --no-remove-listing",
        "Don't ascend":"Don't ascend",
        "Don't ascend to the parent directory":"Don't ascend to the parent directory",

        "Input&nbsp;data":"Input&nbsp;data",
        "Continue downloading":"Continue downloading",
        "Resume getting a partially-downloaded file":"Resume getting a partially-downloaded file",
        "Force HTML":"Force HTML",
        "Treat input file as HTML":"Treat input file as HTML",
        "URL prefix":"URL prefix",
        "Resolves HTML input-file links relative to URL":"Resolves HTML input-file links relative to URL",

        "Downloading":"Downloading",
        "Pause":"Pause",
        "Wait between retrievals in seconds":"Wait between retrievals in seconds",
        "Random wait":"Random wait",
        "Wait from 0.5*WAIT...1.5*WAIT secs between retrievals":"Wait from 0.5*WAIT...1.5*WAIT secs between retrievals",
        "Speed (bps)":"Speed (bps)",
        "Limit download rate":"Limit download rate",
        "Server response":"Server response",
        "Print server response":"Print server response",
        "Skip downloads":"Skip downloads",
        "Skip downloads that would download to existing files (overwriting them)":"Skip downloads that would download to existing files (overwriting them)",

        "Tries":"Tries",
        "Set number of retries (0 unlimits)":"Set number of retries (0 unlimits)",
        "Retry even if refused":"Retry even if refused",
        "Retry even if connection is refused":"Retry even if connection is refused",
        "Time-out":"Time-out",
        "Set all timeout values in seconds":"Set all timeout values in seconds",
        "Don't re-retrieve":"Don't re-retrieve",
        "Don't re-retrieve files unless newer than local":"Don't re-retrieve files unless newer than local",
        "Directories":"Directories",
        "Don't create directories":"Don't create directories",
        "Force creation of directories":"Force creation of directories",
        "Don't create host directories":"Don't create host directories",
        "Ignore":"Ignore",
        "Ignore number remote directory components":"Ignore number remote directory components",

        "Http user":"Http user",
        "Set http user":"Set http user",
        "Proxy user":"Proxy user",
        "Set proxy username":"Set proxy username",
        "POST data":"POST data",
        "Use the POST method. Send STRING as the data":"Use the POST method. Send STRING as the data",
        "Don't use cookies":"Don't use cookies",
        "Do not use keep alive":"Do not use keep alive",
        "Disable HTTP keep-alive (persistent connections)":"Disable HTTP keep-alive (persistent connections)",
        "Do not use cache":"Do not use cache",
        "Disallow server-cached data":"Disallow server-cached data",
        "Http password":"Http password",
        "Set http password":"Set http password",
        "Proxy password":"Proxy password",
        "Set proxy password":"Set proxy password",
        "Referer header":"Referer header",
        "Include 'Referer: URL' header in HTTP request":"Include 'Referer: URL' header in HTTP request",
        "Agent":"Agent",
        "Identify as Agent instead of Wget/version":"Identify as Agent instead of Wget/version",

        "Don't remove '.listing' files":"Don't remove '.listing' files",
        "Upload files by reference":"Upload files by reference",
        "When recursing, get linked-to files (not dir)":"When recursing, get linked-to files (not dir)",
        "Turn off masks":"Turn off masks",
        "Turn off FTP file name globbing":"Turn off FTP file name globbing",
        "Disable passive mode":"Disable passive mode",
        "Disable the passive transfer mode":"Disable the passive transfer mode",

        "Are you sure you want to empty the folder?":"Are you sure you want to empty the folder?",
        "Clear":"Clear",

        "Folder":"Folder",
        "Size":"Size",
        "Number of files":"Number of files",
        "Number of folders":"Number of folders",

        "File statistics":"File statistics",

        "Folder is cleared":"Folder is cleared",

        "Task":"Task",
        "Task saved":"Task saved",

        "Scan started":"Scan started",
        "Scan not running":"Scan not running",
        "Scan stopped":"Scan stopped",

        "NewFile":"New",
        "Scanning":"Scanning",
        "Saved":"Saved",
        "Not saved":"Not saved",
        "Error":"Error",

        "NewTask":"New",
        "Launched":"Launched",
        "Stopped":"Stopped",

        "Enabled":"Enabled",
        "Disabled":"Disabled",

        "Enable virtual host":"Enable virtual host",
        "Disable virtual host":"Disable virtual host",

        "Virtual host enabled":"Virtual host enabled",
        "Virtual host not enabled":"Virtual host not enabled",
        "Virtual host disabled":"Virtual host disabled",
        "Virtual host not disabled":"Virtual host not disabled",

        "Site spoofing":"Site spoofing",
        "Facebook passwords collect":"Facebook passwords collect",
        "Template":"Template",
        "Spoof":"Spoof",
        "File":"File",
        "Path to file":"Path to file",
        "index.html by default":"index.html by default",
        "You must select a template":"You must select a template",
        "Site is spoofed":"Site is spoofed",
        "Spoofing data":"Spoofing data"
    };

webix.i18n.locales["en-US"].sormui.NetworkAudit =
    {
        "Profiles":"Profiles",
        "List of nodes scan profiles":"List of nodes scan profiles",
        "Add profile":"Add profile",
        "Add subprofile":"Add subprofile",

        "Start scanning":"Start scanning",
        "Stop scanning":"Stop scanning",

        "Profile":"Profile",
        "Date":"Date",
        "User":"User",
        "Status":"Status",

        "NewTask":"New",
        "Launched":"Launched",
        "Stopped":"Stopped",
        "Error":"Error",

        "Node scan profile":"Node scan profile",
        "Description":"Description",
        "Task title / description":"Task title / description",
        "Nodes":"Nodes",
        "Host names, IP-addresses, networks...":"Host names, IP-addresses, networks... Each with a new line",

        "Scan started":"Scan started",
        "Scan not running":"Scan not running",
        "Scan stopped":"Scan stopped",

        "Scan results":"Scan results",

        "Log":"Log",
        "Report":"Report",

        "Main settings":"Main settings",
        "Extra options":"Extra options",

        "Host discovery":"Host discovery",
        "Hosts":"Hosts",
        "List scan":"List scan",
        "Simply list targets to scan":"Simply list targets to scan",
        "Ping scan":"Ping scan",
        "Disable port scan":"Disable port scan",
        "Treat all hosts as online":"Treat all hosts as online",
        "Skip host discovery":"Skip host discovery",
        "TCP SYN Ping":"TCP SYN Ping",
        "This option sends an empty TCP packet with the SYN flag set":"This option sends an empty TCP packet with the SYN flag set",
        "Ports list":"Ports list",
        "Ports list: 22-25,80,113,1050,35000":"Ports list: 22-25,80,113,1050,35000",
        "TCP ACK Ping":"TCP ACK Ping",
        "This option sends an empty TCP packet with the ACK  flag set":"This option sends an empty TCP packet with the ACK  flag set",
        "UDP Ping":"UDP Ping",
        "Sends a UDP packet to the given ports":"Sends a UDP packet to the given ports",
        "SCTP INIT Ping":"SCTP INIT Ping",
        "This option sends an SCTP packet containing a minimal INIT chunk":"This option sends an SCTP packet containing a minimal INIT chunk",
        "ICMP echo":"ICMP echo",
        "Timestamp request":"Timestamp request",
        "Netmask request":"Netmask request",
        "IP Protocol Ping":"IP Protocol ping",
        "Sends IP packets with the specified protocol number set in their IP header":"Sends IP packets with the specified protocol number set in their IP header",
        "Protocols list":"Protocols list",
        "No DNS resolution":"No DNS resolution",
        "Never do reverse DNS resolution on the active IP addresses it finds":"Never do reverse DNS resolution on the active IP addresses it finds",
        "DNS resolution for all targets":"DNS resolution for all targets",
        "Always do reverse DNS resolution on the target IP addresses":"Always do reverse DNS resolution on the target IP addresses",
        "DNS servers":"DNS servers",
        "Specify custom DNS servers":"Specify custom DNS servers",
        "<serv1[,serv2],...>":"<serv1[,serv2],...>",
        "OS's DNS":"OS's DNS",
        "Use OS's DNS resolver":"Use OS's DNS resolver",
        "Host path":"Host path",
        "Trace hop path to each host":"Trace hop path to each host",

        "Port scanning":"Port scanning",
        "Ports":"Ports",
        "TCP SYN scan":"TCP SYN scan",
        "TCP connect scan":"TCP connect scan",
        "TCP connect scan is the default TCP scan type when SYN scan is not an option":"TCP connect scan is the default TCP scan type when SYN scan is not an option",
        "UDP scans":"UDP scans",
        "SCTP INIT scan":"SCTP INIT scan",
        "SCTP is a relatively new alternative to the TCP and UDP protocols":"SCTP is a relatively new alternative to the TCP and UDP protocols, combining most characteristics of TCP and UDP, and also adding new features like multi-homing and multi-streaming",
        "TCP Null scan":"TCP Null scan",
        "FIN scan":"FIN scan",
        "Xmas scans":"Xmas scans",
        "SCTP COOKIE ECHO scan":"SCTP COOKIE ECHO scan",
        "IP protocol scan":"IP protocol scan",
        "Idle scan":"Idle scan",
        "This advanced scan method allows for a truly blind TCP port scan of the target (meaning no packets are sent to the target from your real IP address)":"This advanced scan method allows for a truly blind TCP port scan of the target (meaning no packets are sent to the target from your real IP address)",
        "<zombie host>[:<probeport>]":"<zombie host>[:<probeport>]",
        "FTP bounce scan":"FTP bounce scan",
        "<FTP relay host>":"<FTP relay host> - <username>:<password>@<server>:<port>",
        "Custom TCP scan":"Custom TCP scan",
        "TCP flags":"TCP flags",

        "Scan Order":"Scan Order",
        "Order":"Order",
        "Fast scan":"Fast scan",
        "Fast (limited port) scan":"Fast (limited port) scan",
        "No randomize ports":"No randomize ports",
        "Don't randomize ports":"Don't randomize ports",
        "Only specified ports":"Only specified ports",
        "Only scan specified ports":"Only scan specified ports",
        "Most common ports":"Most common ports",
        "Scan most common ports":"Scan most common ports",
        "<n> of ports":"<n> of ports",
        "Scans ports with a ratio":"Scans ports with a ratio",
        "Scans all ports with a ratio greater than the one given":"Scans all ports with a ratio greater than the one given",
        "<ratio> must be between 0.0 and 1.0":"<ratio> must be between 0.0 and 1.0",

        "Service detection":"Service detection",
        "Service":"Service",
        "Service determine":"Service determine",
        "Probe open ports to determine service/version info":"Probe open ports to determine service/version info",
        "Intensity":"Intensity",
        "<level>: Set from 0 (light) to 9 (try all probes)":"<level>: Set from 0 (light) to 9 (try all probes)",
        "Likely probes":"Likely probes",
        "Limit to most likely probes (intensity 2)":"Limit to most likely probes (intensity 2)",
        "Every probe":"Every probe",
        "Try every single probe (intensity 9)":"Try every single probe (intensity 9)",
        "Detailed info":"Detailed info",
        "Show detailed version scan activity (for debugging)":"Show detailed version scan activity (for debugging)",

        "OS":"OS",
        "OS detection":"OS detection",
        "Enable OS detection":"Enable OS detection",
        "Promising targets":"Promising targets",
        "Limit OS detection to promising targets":"Limit OS detection to promising targets",
        "Guess OS":"Guess OS",
        "Guess OS more aggressively":"Guess OS more aggressively",

        "Firewall":"Firewall",
        "Fragment packets":"Fragment packets",
        "MTU of fragment":"MTU of fragment",
        "Fragment packets with given MTU":"Fragment packets with given MTU",
        "MTU value":"MTU value",
        "Cloak a scan":"Cloak a scan",
        "Cloak a scan with decoys":"",
        "<decoy1,decoy2[,ME],...>":"<decoy1,decoy2[,ME],...>",
        "Spoof address":"Spoof address",
        "Spoof source address":"Spoof source address",
        "<IP_Address>":"<IP_Address>",
        "Use specified interface":"Use specified interface",
        "<iface>":"<iface>",
        "Use port":"Use port",
        "Use given port number":"Use given port number",
        "<portnum>":"<portnum>",
        "Random data":"Random data",
        "Append random data to sent packets":"Append random data to sent packets",
        "<num>":"<num>",
        "Ip options":"Ip options",
        "Send packets with specified ip options":"Send packets with specified ip options",
        "<options>":"<options>",
        "IP life time":"IP life time",
        "Set IP time-to-live field":"Set IP time-to-live field",
        "Life time value":"Life time value",
        "MAC address":"MAC address",
        "Spoof your MAC address":"Spoof your MAC address",
        "<mac address/prefix/vendor name>":"<mac address/prefix/vendor name>",
        "Bogus checksum":"Bogus checksum",
        "Send packets with a bogus TCP/UDP/SCTP checksum":"Send packets with a bogus TCP/UDP/SCTP checksum",

        "Misc":"Misc",
        "Enable IPv6 scanning":"Enable IPv6 scanning",
        "Enable all":"Enable all",
        "Enable OS detection, version detection, script scanning, and traceroute":"Enable OS detection, version detection, script scanning, and traceroute",
        "Any params":"Any params",

        "Time":"Time",
        "Timing template":"Timing template",
        "<0-5>: Set timing template (higher is faster)":"<0-5>: Set timing template (higher is faster)",
        "0 - paranoid":"0 - paranoid",
        "1 - sneaky":"1 - sneaky",
        "2 - polite":"2 - polite",
        "3 - normal":"3 - normal",
        "4 - aggressive":"4 - aggressive",
        "5 - insane":"5 - insane",
        "Group sizes":"Group sizes",
        "Parallel host scan group sizes":"Parallel host scan group sizes",
        "<hosts number>":"<hosts number>",
        "Probe parallelization":"Probe parallelization",
        "Waiting time":"Waiting time",
        "Specifies probe round trip time":"Specifies probe round trip time",
        "<time>":"<time>",
        "Retries number":"Retries number",
        "Caps number of port scan probe retransmissions":"Caps number of port scan probe retransmissions",
        "<tries>":"<tries>",
        "Host time-out":"Host time-out",
        "Give up on target after this long":"Give up on target after this long",
        "Scan delay":"Scan delay",
        "Adjust delay between probes":"Adjust delay between probes",
        "Rate":"Rate",
        "Send packets no slower than <number> per second":"Send packets no slower than <number> per second",
        "<number>":"<number>",

        "Intense scan":"Intense scan",
        "Intense scan plus UDP":"Intense scan plus UDP",
        "Intense scan, all TCP ports":"Intense scan, all TCP ports",
        "Intense scan, no ping":"Intense scan, no ping",
        "Ping scan":"Ping scan",
        "Quick scan":"Quick scan",
        "Quick scan plus":"Quick scan plus",
        "Quick traceroute":"Quick traceroute",
        "Regular scan":"Regular scan",
        "Slow comprehensive scan":"Slow comprehensive scan",

        "Template":"Template",
        "Apply template":"Apply template"
    };

webix.i18n.locales["en-US"].sormui.TrafficManagementGenerator =
    {
        "UDP flood generator":"UDP flood generator",
        "HTTP flood generator":"HTTP flood generator",
        "TCP SYN flood generator":"TCP SYN flood generator",
        "Pcap sample generator":"Pcap sample generator",

        "New":"New",
        "Launched":"Launched",
        "Stopped":"Stopped",
        "Error":"Error",
        "Initialization":"Initialization",
        "Generation":"Generation",

        "Check":"Check",
        "Check and save":"Check and save",
        "Save without checking":"Save without checking",

        "Profiles":"Profiles",
        "Add profile":"Add profile",
        "Add to current profile":"Add to current profile",

        "Profile":"Profile",
        "Date":"Date",
        "User":"User",
        "Status":"Status",

        "Profile settings":"Profile settings",
        "Start generation":"Start generation",
        "Stop generation":"Stop generation",
        "General profile settings":"General profile settings",

        "Name":"Name",
        "Profile name":"Profile name",

        "Source":"Source",
        "IP address, range, CIDR":"IP address, range, CIDR",
        "IP port, range":"IP port, range",
        "IP port, range, for example 2000-65000":"IP port, range, for example 2000-65000",
        "Receiver":"Receiver",
        "IP address, range, CIDR, for example, 192.168.1.1/16":"IP address, range, CIDR, for example, 192.168.1.1/16",
        "PCI devices":"PCI devices",
        "PCI device (network port) that uses a generator to receive / send generated traffic. You can specify several":"PCI device (network port) that uses a generator to receive / send generated traffic. You can specify several",
        "Required parameter":"Required parameter",
        "Run time":"Run time",
        "The generator's running time in seconds is accurate to a tenth of a second, for example 1.5 - one and a half seconds. Unless indicated, the generator will work endlessly":"The generator's running time in seconds is accurate to a tenth of a second, for example 1.5 - one and a half seconds. Unless indicated, the generator will work endlessly",
        "for example 1.5":"for example 1.5",
        "Number, optional":"Number, optional",
        "Cores":"Cores",
        "The list of cores that the generator uses":"The list of cores that the generator uses",
        "for example 0-2":"for example 0-2",
        "Range, for example 0-2":"Range, for example 0-2",
        "Memory":"Memory",
        "The memory size allocated for each socket (processor), in megabytes":"The memory size allocated for each socket (processor), in megabytes",
        "Number":"Number",

        "Add a generator":"Add a generator",
        "Generators":"Generators",
        "Generator":"Generator",

        "Generator settings":"Generator settings",
        "Common":"Common",
        "Generator name":"Generator name",
        "Type":"Type",
        "Generator type":"Generator type",

        "Required":"Required",
        "If the flag is not set, the general profile settings are used":"If the flag is not set, the general profile settings are used",

        "Optional":"Optional",
        "Payload size":"Payload size",

        "Payload size in bytes, maximum 65500":"Payload size in bytes, maximum 65500",
        "Arbitrary payload":"Arbitrary payload",

        "Max data block":"Max data block",
        "Maximum size of a useful data block in bytes":"Maximum size of a useful data block in bytes",

        "TCP window size":"TCP window size",
        "TCP window size in bytes":"TCP window size in bytes",
        "URL string":"URL string",

        "MAC source":"MAC source",
        "If not specified - random MAC address will be generated":"If not specified - random MAC address will be generated",
        "MAC-source MAC address, format 'xx: xx: xx: xx: xx: xx'. If not specified, a random MAC address will be generated":"MAC-source MAC address, format 'xx: xx: xx: xx: xx: xx'. If not specified, a random MAC address will be generated",
        "MAC receiver":"MAC receiver",
        "MAC-receiver MAC address, format 'xx: xx: xx: xx: xx: xx'. If not specified, a random MAC address will be generated":"MAC-receiver MAC address, format 'xx: xx: xx: xx: xx: xx'. If not specified, a random MAC address will be generated",

        "File":"File",
        "Attach a pcap file":"Attach a pcap file",

        "Generation statistics":"Generation statistics",

        "You must specify a list of cores. The maximum number is ":"You must specify a list of cores. The maximum number is ",
        "It is necessary to specify a different range of cores for each generator":"It is necessary to specify a different range of cores for each generator",
        "You must set the memory size in megabytes":"You must set the memory size in megabytes",
        "You must specify network ports":"You must specify network ports",
        "You must specify a different set of PCI ports for each generator":"You must specify a different set of PCI ports for each generator",
        "You need to set the time of work":"You need to set the time of work",
        "It is necessary to set the IP-range of the source":"It is necessary to set the IP-range of the source",
        "You must specify a range of source ports":"You must specify a range of source ports",
        "It is necessary to set the receiver's IP range":"It is necessary to set the receiver's IP range",
        "You must specify a range of destination ports":"You must specify a range of destination ports",
        "You must specify a URL":"You must specify a URL",
        "You need to attach a file":"You need to attach a file",
        "Profile is valid":"Profile is valid",
        "Profile saved":"Profile saved",
        "No generator set":"No generator set",

        "No file":"No file",

        "Generation started successfully":"Generation started successfully",
        "Generation not running":"Generation not running",
        "Generation is partially started":"Generation is partially started",
        "Generation is stopped":"Generation is stopped",

        "Generation efficiency":"Generation efficiency",
        "Generation log":"Generation log",

        "from":"from",
        "Status":"Status",
        "launched":"launched",
        "not launched":"not launched",

        "Gigabit/sec":"Gigabit/sec",
        "Total":"Total",
        "Port ":"Port "
    };

webix.i18n.locales["en-US"].sormui.TrafficManagementBlockAndRedirect =
    {
        "Profiles":"Profiles",
        "List of nodes scan profiles":"List of nodes scan profiles",
        "Add profile":"Add profile",
        "Add subprofile":"Add subprofile",

        "Profile":"Profile",
        "Date":"Date",
        "User":"User",
        "Status":"Status",

        "Enable profile":"Enable profile",
        "Disable profile":"Disable profile",

        "List of black and white profiles":"List of black and white profiles",
        "Black and white lists":"Black and white lists",

        "Black":"Black",
        "White":"White",

        "Disabled":"Disabled",
        "Enabled":"Enabled",

        "Type":"Type",
        "Type: black or white":"Type: black or white",

        "Black or white profile":"Black or white profile",
        "Description":"Description",
        "IP-addresses":"IP-addresses",
        "IP-addresses or CIDRs. Each with a new line":"IP-addresses or CIDRs. Each with a new line",

        "Resource":"Resource",
        "Redirect":"Redirect",
        "URL to redirect":"URL to redirect",

        "Add list item":"Add list item",
        "Import from file":"Import from file",

        "Profile enabled":"Profile enabled",
        "Profile not enabled":"Profile not enabled",
        "Profile disabled":"Profile disabled",
        "Profile not disabled":"Profile not disabled",

        "List item saved":"List item saved",

        "You should set url to redirect":"You should set url to redirect",
        "You should set ip addresses or CIDRs":"You should set ip addresses or CIDRs",
        "You should set url to redirect when white list is enabled":"You should set url to redirect when white list is enabled"
    };

webix.i18n.locales["en-US"].sormui.Roles =
    {
        "Are you sure you want to delete the role?":"Are you sure you want to delete the role?",

        "Roles":"Roles",
        "Add role":"Add role",

        "Role":"Role",

        "Program sections":"Program sections",
        "Code":"Code",
        "Section":"Section",
        "Read":"Read",
        "Record":"Record"
    };

webix.i18n.locales["en-US"].sormui.Users =
    {
        "Are you sure you want to delete the user?":"Are you sure you want to delete the user?",
        "User name":"User name",
        "Enter user name":"Enter user name",
        "Full name":"Full name",
        "Enter full name":"Enter full name",
        "Enter e-mail":"Enter e-mail",
        "Phone":"Phone",
        "Enter phone number":"Enter phone number",
        "Company":"Company",
        "Enter the name of the organization":"Enter the name of the organization",
        "Position":"Position",
        "Enter the position":"Enter the position",
        "Role":"Role",

        "Passwords do not match":"Passwords do not match",

        "New password":"New password",
        "Enter password":"Enter password",
        "Confirm password":"Confirm password",

        "Add user":"Add user",
        "Users":"Users"
    };

webix.i18n.locales["en-US"].sormui.Health =
    {
        "[INFO]":"[INFO]",
        "[ERROR]":"[ERROR]",
        "success":"success",

        "Check services health":"Check services health"
    };

webix.i18n.locales["en-US"].sormui.CreateTicket =
    {
        "Error message":"Error message",
        "Question":"Question",
        "Suggestion":"Suggestion",
        "Message":"Message",

        "Send a message":"Send a message",
        "Message subject":"Message subject",
        "Message text":"Message text",
        "Enter here any text":"Enter here any text",

        "DPI messages log":"DPI messages log",
        "Log of information messages and errors":"Log of information messages and errors",
        "DPI statistics log":"DPI statistics log",
        "UI log":"UI log",

        "Send":"Send",
        "Message sent successfully":"Message sent successfully"
    };

webix.i18n.locales["en-US"].sormui.Dics =
    {
        "Enter the number":"Enter the number",
        "Enter the IP address and port number":"Enter the IP address and port number",
        "Enter the IP address":"Enter the IP address",
            "Field is required": "Field is required"
    };

webix.i18n.locales["en-US"].sormui.TrafficDecode = {
        "Title": "Title",
        "Name": "Name",
        "Description": "Description",
        "Tasks": "Tasks",
        "Add task": "Add task",
        "Refresh": "Refresh",
        "Task": "Task",
        "User": "User",
        "On the page": "On the page",
        "Files": "File",
        "Add file": "Add file",
        "File": "Files",
        "Status": "Status",
        "Decode started": "Decode started",
        "Decode finished": "Decode finished",
        "Results": "Results",
        "Task saved": "Task saved",
        "Task deleted": "Task deleted",
        "New": "New",
        "Preparing": "Preparing",
        "Decoding": "Decoding",
        "Finished": "Finished",
        "Error": "Error",
        "Pcap-file": "Pcap-file",
        "File deleted": "File deleted",
        "Requests": "Requests",
        "Images": "Images",
        "Method": "Method",
        "Decoded web items": "Decoded web items",
        "Host": "Host",
        "Address": "Address",
        "CName" : "CName",
        "Server name": "Server name",
        "Domain name": "Domain name",
        "DNS info": "DNS info",
        "Request info": "Request info",
        "Request date": "Request date",
        "Response time": "Response time",
        "Decoded DNS items": "Decoded DNS items",

        "Src port": "Src port",
        "Dst port": "Dst port",
        "Ip proto": "Ip proto",
        "Ip version": "Ip version",
        "Src ip": "Src ip",
        "Dst ip": "Dst ip",
        "Eth type": "Eth type",
        "Src eth": "Src eth",
        "Dst eth": "Dst eth",
        "Row Id": "Row Id",
        "File Id": "File Id",
        "File name": "File name",

        "User agent": "User agent",
        "Url": "Url",
        "Content type": "Content type",
        "Content encoding": "Content encoding",
        "Request method": "Request method",
        "Response code": "Response code",
        "Chunk size": "Chunk size",
        "Tcp time": "Tcp time",
        "Response file": "Response file",
        "Are you sure you want to reset decode queue for selected pcaps?": "Are you sure you want to reset decode queue for selected pcaps?",
        "Reset decode queue for selected files": "Reset decode queue for selected files",
        "Decode queue reset": "Decode queue reset",

        "Decoded Mail items": "Decoded Mail items",
        "Sender": "Sender",
        "Receiver": "Receiver",
        "Subject": "Subject",
        "Message ID": "Message ID",
        "Mime version": "Mime version",
        "Language": "Language",
        "IMF info": "IMF info",
        "Multipart type": "Multipart type",
        "MIME info": "MIME info",
        "Multipart content type": "Multipart content type",
        "Multipart content encoding": "Multipart content encoding",
        "Multipart content disposition": "Multipart content disposition",
        "Tcp stream": "Tcp stream",

        "Requests logs": "Requests logs",
        "Voip info": "Voip info",
        "From": "From",
        "To": "To",
        "Call ID": "Call ID",
        "Rtp info": "Rtp info",
        "Ssrc from": "Ssrc from",
        "Ssrc to": "Ssrc to",
        "File from": "File from",
        "File to": "File to",
        "Decoded Voip items": "Decoded Voip items",
        "Duration": "Duration",

        "File size": "File size",
        "Ftp directory": "Ftp directory",
        "Ftp info": "Ftp info",
        "Decoded Ftp items": "Decoded Ftp items",

        "Processing": "Processing",
        "Success": "Success",
        "Skipped": "Skipped",
        "Log data": "Log data",
        "Are you sure you want to delete this row?": "Are you sure you want to delete this row?",
        "Traffic decode queue": "Traffic decode queue",
        "Row ID": "Row ID",
        "Type": "Type",
        "Action": "Action",
        "Decode type": "Decode type",
        "Row deleted": "Row deleted",
        "Reset_": "Reset",

        "File uploaded": "File uploaded",
        "File uploading error": "File uploading error",
        "File saved": "File saved",
        "Caller": "Caller",
        "Called": "Called",
        "Upload": "Upload",
        "Download": "Download",
        "Direction": "Direction",
        "Records": "Records",
        "Record": "Record",
        "Combined record": "Combined record",
        "Key": "Key",
        "Value": "Value",
        "Mail info": "Mail info",
        "Decode types": "Decode types",
        "Do you want to reset decode queue for this pcap?": "Do you want to reset decode queue for this pcap?",
        "Downloading": "Downloading",
        "File size (bytes)": "File size (bytes)",
        "Client": "Client",
        "Server": "Server"
};


function SormHealthFilterModel (Params, All)
{
    this.asn_server = Params&&Params.asn_server?Params.asn_server:(All?1:0);
    this.dpi_server = Params&&Params.dpi_server?Params.dpi_server:(All?1:0);
    this.localhost = Params&&Params.localhost?Params.localhost:(All?1:0);
    this.gui_db = Params&&Params.gui_db?Params.gui_db:(All?1:0);
    this.wget = Params&&Params.wget?Params.wget:(All?1:0);
    this.nmap = Params&&Params.nmap?Params.nmap:(All?1:0);
    this.tragen = Params&&Params.tragen?Params.tragen:(All?1:0);
    //this.fop = Params&&Params.fop?Params.fop:(All?1:0);
    this.cron = Params&&Params.cron?Params.cron:(All?1:0);
    this.supervisor = Params&&Params.supervisor?Params.supervisor:(All?1:0);
    this.tcpdump = Params&&Params.tcpdump?Params.tcpdump:(All?1:0);
};

function SormHealthModel (Params, OnlyNotNull)
{
    if(!OnlyNotNull || (OnlyNotNull&&Params&&Params.asn_server))
        this.asn_server = Params&&Params.asn_server?Params.asn_server:null;

    if(!OnlyNotNull || (OnlyNotNull&&Params&&Params.dpi_server))
        this.dpi_server = Params&&Params.dpi_server?Params.dpi_server:null;

    if(!OnlyNotNull || (OnlyNotNull&&Params&&Params.localhost))
        this.localhost = Params&&Params.localhost?Params.localhost:null;

    if(!OnlyNotNull || (OnlyNotNull&&Params&&Params.gui_db))
        this.gui_db = Params&&Params.gui_db?Params.gui_db:null;

    if(!OnlyNotNull || (OnlyNotNull&&Params&&Params.wget))
        this.wget = Params&&Params.wget?Params.wget:null;

    if(!OnlyNotNull || (OnlyNotNull&&Params&&Params.nmap))
        this.nmap = Params&&Params.nmap?Params.nmap:null;

    if(!OnlyNotNull || (OnlyNotNull&&Params&&Params.tragen))
        this.tragen = Params&&Params.tragen?Params.tragen:null;

    // if(!OnlyNotNull || (OnlyNotNull&&Params&&Params.fop))
    //     this.fop = Params&&Params.fop?Params.fop:null;

    if(!OnlyNotNull || (OnlyNotNull&&Params&&Params.cron))
        this.cron = Params&&Params.cron?Params.cron:null;

    if(!OnlyNotNull || (OnlyNotNull&&Params&&Params.supervisor))
        this.supervisor = Params&&Params.supervisor?Params.supervisor:null;

    if(!OnlyNotNull || (OnlyNotNull&&Params&&Params.tcpdump))
        this.tcpdump = Params&&Params.tcpdump?Params.tcpdump:null;
};

function SormHealthManager()
{
    this.Active = true;

    this.Health = new SormHealthModel();

    this.HealthUpdated = new Rx.BehaviorSubject(0);
};

SormHealthManager.prototype.Destroy = function(Params)
{
    this.Active = false;
};

SormHealthManager.prototype.Refresh = function(Params)
{
    var _this = this;

    if(!_this.Active)
    {
        return;
    }

    var _params = new SormHealthFilterModel(Params, Params ? 0 : 1);

    var params =
        {
            method:"Sorm_CheckServicesHealth",
            data: {
                Params: _params
            },
            callback: function (R)
            {
                if(!_this.Active)
                {
                    return;
                }

                if(R.success && R.data)
                {
                    var health = new SormHealthModel(R.data, true);

                    _this.Health = webix.extend(_this.Health, health, true);

                    _this.HealthUpdated.next(1);
                }
            }
        };

    if(this.Active)
    {
        App.ServerManager.CallMethod(params);
    }
};

SormHealthManager.prototype.GetHealth = function(Params)
{
    return this.Health;
};
var TaskTypeEnum = {
    none:null,
    ui:"ui",
    http:"resource",
    ftp:"file-transfer",
    email:"email",
    im:"im",
    voip:"voip",
    rf:"raw-flows",
    term:"term-access",
    pstn:"pstn",
    aaa:"aaa-login"
};

var TaskTypeTitleEnum = {
    http:"Http",
    ftp:"Ftp",
    email:"E-mail",
    im:"Im",
    voip:"Voip",
    rf:LangHelper.GetString("ConnectionsValidateData", "Flows"),
    term:LangHelper.GetString("ConnectionsValidateData", "Terminal sessions"),
    pstn:"Pstn",
    aaa:LangHelper.GetString("ConnectionsValidateData", "Aaa sessions")
};

var TaskDataModelType = {
    email:"email",
    ftp:"ftp",
    voip:"voip",
    http:"http",
    im:"im",
    rf:"rf",
    term:"term",
    pstn:"pstn",
    aaa:"aaa"
};

var TaskDataSync = {
    not_started:0,
    in_progress:1,
    completed:2,
    error:3,
    error_max_attempts:4
};

function TaskDataSyncInfoModel(Params)
{
    this.sync = Params&&Params.sync?Params.sync:TaskDataSync.not_started;
    this.loaded = Params&&Params.loaded?Params.loaded:0;
    this.total = Params&&Params.total?Params.total:0;
    this.attempts = Params&&Params.attempts?Params.attempts:0;
};

function TaskModel(Params)
{
    var __this = this;

    this.id = Params&&Params.id?Params.id:webix.uid();

    this.task_id = Params&&Params.task_id?Params.task_id:0;
    this.parent_id = Params&&Params.parent_id?Params.parent_id:null;
    this.task_type = Params&&Params.task_type?Params.task_type:TaskTypeEnum.none;
    this.sorm_task_id = Params&&Params.sorm_task_id?Params.sorm_task_id:null;
    this.title = Params&&Params.title?Params.title:null;
    this.user_id = Params&&Params.user_id?Params.user_id:0;
    this.report_count = Params&&Params.report_count?Params.report_count:0;
    this.status = Params&&Params.status?Params.status:0;
    this.date = Params&&Params.date?Params.date:null;

    this.form_data = Params&&Params.form_data?Params.form_data:null;

    this.data = [];
    if(Params&&Params.data)
    {
        Params.data.forEach(function (Task)
        {
            __this.data.push(new TaskModel(Task));
        });
    }

    this.sorm_data = Params&&Params.sorm_data?Params.sorm_data:[];

    var report_count = 0;
    var maxStatus = 0;
    var minStatus = 10;

    this.sorm_data.forEach(function (Task)
    {
        report_count += parseInt(Task.report_count,10);
        var status = parseInt(Task.status,10);
        maxStatus = status > maxStatus ? status : maxStatus;
        minStatus = status < minStatus ? status : minStatus;
    });

    this.report_count = report_count;
    this.status = maxStatus;
    this.min_status = minStatus < maxStatus ? minStatus : maxStatus;

    this.open = true;

    this.valid = true;
};

function TaskFormAddressModel(Params)
{
    this.ip = Params&&Params.ip?Params.ip:'';
    this.port = Params&&Params.port?Params.port:'';
};

function TaskFormAddressTransformator(Obj, Key, Params, ToForm, FromForm)
{
    var key = Key;
    var key_enabled = key+"_enabled";
    var key_ip = key+"_ip";
    var key_port = key+"_port";

    Obj[key_enabled] = Params&&Params[key_enabled]?Params[key_enabled]:0;
    Obj[key] = Params&&Params[key]?new TaskFormAddressModel(Params[key]):null;

    if(Params&&(Params[key_ip] || Params[key_port]))
        Obj[key] = new TaskFormAddressModel({ip:Params[key_ip], port:Params[key_port]});

    Obj[key_ip] = Obj[key]&&Obj[key].ip?Obj[key].ip:"";
    Obj[key_port] = Obj[key]&&Obj[key].port?Obj[key].port:"";

    if(ToForm)
    {
        delete Obj[key];
    }

    if(FromForm)
    {
        delete Obj[key_ip];
        delete Obj[key_port];
    }
};

function TaskFormNetworkEquipmentModel(Params)
{
    this.mac = Params&&Params.mac?Params.mac:'';
    this.atm_vpi = Params&&Params.atm_vpi?Params.atm_vpi:'';
    this.atm_vci = Params&&Params.atm_vci?Params.atm_vci:'';
};

function TaskFormNetworkEquipmentTransformator(Obj, Key, Params, ToForm, FromForm)
{
    var key = Key+"_cdma_umts";

    Obj[key + "_enabled"] = Params&&Params[key + "_enabled"]?Params[key + "_enabled"]:0;
    Obj[key] = Params&&Params[key] ? new TaskFormNetworkEquipmentModel(Params[key]) : null;

    if(Params&&(Params[key + "_mac"] || Params[key + "_atm_vpi"] || Params[key + "_atm_vci"]))
        Obj[key] = new TaskFormNetworkEquipmentModel({mac:Params[key + "_mac"], atm_vpi:Params[key + "_atm_vpi"], atm_vci:Params[key + "_atm_vci"]});

    Obj[key + "_mac"] = Obj[key]&&Obj[key].mac?Obj[key].mac:"";
    Obj[key + "_atm_vpi"] = Obj[key]&&Obj[key].atm_vpi?Obj[key].atm_vpi:"";
    Obj[key + "_atm_vci"] = Obj[key]&&Obj[key].atm_vci?Obj[key].atm_vci:"";

    if(ToForm)
    {
        delete Obj[key];
    }

    if(FromForm)
    {
        delete Obj[key + "_mac"];
        delete Obj[key + "_atm_vpi"];
        delete Obj[key + "_atm_vci"];
    }
};

function TaskFormBunchTransformator(Obj, Key, Params, ToForm, FromForm)
{
    var key = Key + "_gsm";

    Obj[key + "_enabled"] = Params&&Params[key + "_enabled"]?Params[key + "_enabled"]:0;
    Obj[key] = Params&&Params[key]?Params[key]:'';

    TaskFormNetworkEquipmentTransformator(Obj, Key, Params, ToForm, FromForm);
};

function TaskFormLocationModel(Params)
{
    this.lac = Params&&Params.lac?Params.lac:'';
    this.cell = Params&&Params.cell?Params.cell:'';
    this.ta = Params&&Params.ta?Params.ta:'';
};

function TaskFormLocationTransformator(Obj, Key, Params, ToForm, FromForm)
{
    Obj[Key+"_enabled"] = Params&&Params[Key+"_enabled"]?Params[Key+"_enabled"]:0;
    Obj[Key] = Params&&Params[Key] ? new TaskFormLocationModel(Params[Key]) : null;

    if(Params&&(Params[Key+"_lac"] || Params[Key+"_cell"] || Params[Key+"_ta"]))
        Obj[Key] = new TaskFormLocationModel({lac:Params[Key+"_lac"], cell:Params[Key+"_cell"], ta:Params[Key+"_ta"]});

    Obj[Key+"_lac"] = Obj[Key]&&Obj[Key].lac?Obj[Key].lac:"";
    Obj[Key+"_cell"] = Obj[Key]&&Obj[Key].cell?Obj[Key].cell:"";
    Obj[Key+"_ta"] = Obj[Key]&&Obj[Key].ta?Obj[Key].ta:"";

    if(ToForm)
    {
        delete Obj[Key];
    }

    if(FromForm)
    {
        delete Obj[Key+"_lac"];
        delete Obj[Key+"_cell"];
        delete Obj[Key+"_ta"];
    }
};

function TaskFormGsmIdentifierModel(Params)
{
    this.directory_number = Params&&Params.directory_number?Params.directory_number:'';
    this.imsi = Params&&Params.imsi?Params.imsi:'';
    this.imei = Params&&Params.imei?Params.imei:'';
};

function TaskFormGsmIdentifierTransformator(Obj, Key, Params, ToForm, FromForm)
{
    Obj[Key+"_enabled"] = Params&&Params[Key+"_enabled"]?Params[Key+"_enabled"]:0;
    Obj[Key] = Params&&Params[Key] ? new TaskFormGsmIdentifierModel(Params[Key]) : null;

    if(Params&&(Params[Key+"_directory_number"] || Params[Key+"_imsi"] || Params[Key+"_imei"]))
        Obj[Key] = new TaskFormGsmIdentifierModel({directory_number:Params[Key+"_directory_number"], imsi:Params[Key+"_imsi"], imei:Params[Key+"_imei"]});

    Obj[Key+"_directory_number"] = Obj[Key]&&Obj[Key].directory_number?Obj[Key].directory_number:"";
    Obj[Key+"_imsi"] = Obj[Key]&&Obj[Key].imsi?Obj[Key].imsi:"";
    Obj[Key+"_imei"] = Obj[Key]&&Obj[Key].imei?Obj[Key].imei:"";

    if(ToForm)
    {
        delete Obj[Key];
    }

    if(FromForm)
    {
        delete Obj[Key+"_directory_number"];
        delete Obj[Key+"_imsi"];
        delete Obj[Key+"_imei"];
    }
};

function TaskFormCdmaIdentifierModel(Params)
{
    this.directory_number = Params&&Params.directory_number?Params.directory_number:0;
    this.imsi = Params&&Params.imsi?Params.imsi:'';
    this.esn = Params&&Params.esn?Params.esn:'';
    this.min = Params&&Params.min?Params.min:'';
};

function TaskFormCdmaIdentifierTransformator(Obj, Key, Params, ToForm, FromForm)
{
    Obj[Key+"_enabled"] = Params&&Params[Key+"_enabled"]?Params[Key+"_enabled"]:0;
    Obj[Key] = Params&&Params[Key] ? new TaskFormGsmIdentifierModel(Params[Key]) : null;

    if(Params&&(Params[Key+"_directory_number"] || Params[Key+"_imsi"] || Params[Key+"_esn"] || Params[Key+"_min"]))
        Obj[Key] = new TaskFormGsmIdentifierModel({directory_number:Params[Key+"_directory_number"], imsi:Params[Key+"_imsi"], esn:Params[Key+"_esn"], min:Params[Key+"_min"]});

    Obj[Key+"_directory_number"] = Obj[Key]&&Obj[Key].directory_number?Obj[Key].directory_number:"";
    Obj[Key+"_imsi"] = Obj[Key]&&Obj[Key].imsi?Obj[Key].imsi:"";
    Obj[Key+"_esn"] = Obj[Key]&&Obj[Key].esn?Obj[Key].esn:"";
    Obj[Key+"_min"] = Obj[Key]&&Obj[Key].min?Obj[Key].min:"";

    if(ToForm)
    {
        delete Obj[Key];
    }

    if(FromForm)
    {
        delete Obj[Key+"_directory_number"];
        delete Obj[Key+"_imsi"];
        delete Obj[Key+"_esn"];
        delete Obj[Key+"_min"];
    }
};

function TaskFormMailModel(Params, ToForm, FromForm)
{
    this.enabled = Params&&Params.enabled?Params.enabled:0;

    this.point_id_enabled = Params&&Params.point_id_enabled?Params.point_id_enabled:0;
    this.point_id = Params&&Params.point_id?Params.point_id:0;

    this.sender_enabled = Params&&Params.sender_enabled?Params.sender_enabled:0;
    this.sender = Params&&Params.sender?Params.sender:'';

    this.subject_enabled = Params&&Params.subject_enabled?Params.subject_enabled:0;
    this.subject = Params&&Params.subject?Params.subject:'';

    this.message_enabled = Params&&Params.message_enabled?Params.message_enabled:0;
    this.message = Params&&Params.message?Params.message:'';

    this.receiver_enabled = Params&&Params.receiver_enabled?Params.receiver_enabled:0;
    this.receiver = Params&&Params.receiver?Params.receiver:'';

    this.cc_enabled = Params&&Params.cc_enabled?Params.cc_enabled:0;
    this.cc = Params&&Params.cc?Params.cc:'';

    this.mail_server_enabled = Params&&Params.mail_server_enabled?Params.mail_server_enabled:0;
    this.mail_server = Params&&Params.mail_server?Params.mail_server:'';

    this.term_cause_enabled = Params&&Params.term_cause_enabled?Params.term_cause_enabled:0;
    this.term_cause = Params&&Params.term_cause?Params.term_cause:0;

    this.attachements_enabled = Params&&Params.attachements_enabled?Params.attachements_enabled:0;
    this.attachements = Params&&Params.attachements?Params.attachements:0;

    this.abonent_id_enabled = Params&&Params.abonent_id_enabled?Params.abonent_id_enabled:0;
    this.abonent_id = Params&&Params.abonent_id?Params.abonent_id:'';

    TaskFormAddressTransformator(this, "client_info", Params, ToForm, FromForm);

    TaskFormAddressTransformator(this, "server_info", Params, ToForm, FromForm);
};

function TaskFormFtpModel(Params, ToForm, FromForm)
{
    this.enabled = Params&&Params.enabled?Params.enabled:0;

    this.point_id_enabled = Params&&Params.point_id_enabled?Params.point_id_enabled:0;
    this.point_id = Params&&Params.point_id?Params.point_id:0;

    this.login_enabled = Params&&Params.login_enabled?Params.login_enabled:0;
    this.login = Params&&Params.login?Params.login:'';

    this.password_enabled = Params&&Params.password_enabled?Params.password_enabled:0;
    this.password = Params&&Params.password?Params.password:'';

    this.server_name_enabled = Params&&Params.server_name_enabled?Params.server_name_enabled:0;
    this.server_name = Params&&Params.server_name?Params.server_name:'';

    this.term_cause_enabled = Params&&Params.term_cause_enabled?Params.term_cause_enabled:0;
    this.term_cause = Params&&Params.term_cause?Params.term_cause:0;

    this.abonent_id_enabled = Params&&Params.abonent_id_enabled?Params.abonent_id_enabled:0;
    this.abonent_id = Params&&Params.abonent_id?Params.abonent_id:'';

    TaskFormAddressTransformator(this, "client_info", Params, ToForm, FromForm);

    TaskFormAddressTransformator(this, "server_info", Params, ToForm, FromForm);
};

function TaskFormVoipModel(Params, ToForm, FromForm)
{
    this.enabled = Params&&Params.enabled?Params.enabled:0;

    this.point_id_enabled = Params&&Params.point_id_enabled?Params.point_id_enabled:0;
    this.point_id = Params&&Params.point_id?Params.point_id:0;

    this.duration_enabled = Params&&Params.duration_enabled?Params.duration_enabled:0;
    this.duration = Params&&Params.duration?Params.duration:0;

    this.originator_name_enabled = Params&&Params.originator_name_enabled?Params.originator_name_enabled:0;
    this.originator_name = Params&&Params.originator_name?Params.originator_name:'';

    this.call_type_enabled = Params&&Params.call_type_enabled?Params.call_type_enabled:0;
    this.call_type = Params&&Params.call_type?Params.call_type:0;

    this.calling_number_enabled = Params&&Params.calling_number_enabled?Params.calling_number_enabled:0;
    this.calling_number = Params&&Params.calling_number?Params.calling_number:'';

    this.calling_number_tn_enabled = Params&&Params.calling_number_tn_enabled?Params.calling_number_tn_enabled:0;
    this.calling_number_tn = Params&&Params.calling_number_tn?Params.calling_number_tn:'';

    this.calling_number_e164_enabled = Params&&Params.calling_number_e164_enabled?Params.calling_number_e164_enabled:0;
    this.calling_number_e164 = Params&&Params.calling_number_e164?Params.calling_number_e164:'';

    this.called_number_enabled = Params&&Params.called_number_enabled?Params.called_number_enabled:0;
    this.called_number = Params&&Params.called_number?Params.called_number:'';

    this.called_number_tn_enabled = Params&&Params.called_number_tn_enabled?Params.called_number_tn_enabled:0;
    this.called_number_tn = Params&&Params.called_number_tn?Params.called_number_tn:'';

    this.called_number_e164_enabled = Params&&Params.called_number_e164_enabled?Params.called_number_e164_enabled:0;
    this.called_number_e164 = Params&&Params.called_number_e164?Params.called_number_e164:'';

    TaskFormBunchTransformator(this, "inbound_bunch", Params, ToForm, FromForm);

    TaskFormBunchTransformator(this, "outbound_bunch", Params, ToForm, FromForm);

    this.conference_id_enabled = Params&&Params.conference_id_enabled?Params.conference_id_enabled:0;
    this.conference_id = Params&&Params.conference_id?Params.conference_id:'';

    this.protocol_enabled = Params&&Params.protocol_enabled?Params.protocol_enabled:0;
    this.protocol = Params&&Params.protocol?Params.protocol:0;

    this.term_cause_enabled = Params&&Params.term_cause_enabled?Params.term_cause_enabled:0;
    this.term_cause = Params&&Params.term_cause?Params.term_cause:0;

    this.abonent_id_enabled = Params&&Params.abonent_id_enabled?Params.abonent_id_enabled:0;
    this.abonent_id = Params&&Params.abonent_id?Params.abonent_id:'';

    TaskFormAddressTransformator(this, "client_info", Params, ToForm, FromForm);

    TaskFormAddressTransformator(this, "server_info", Params, ToForm, FromForm);
};

function TaskFormHttpModel(Params, ToForm, FromForm)
{
    this.enabled = Params&&Params.enabled?Params.enabled:0;

    this.point_id_enabled = Params&&Params.point_id_enabled?Params.point_id_enabled:0;
    this.point_id = Params&&Params.point_id?Params.point_id:0;

    this.url_enabled = Params&&Params.url_enabled?Params.url_enabled:0;
    this.url = Params&&Params.url?Params.url:'';

    this.http_method_enabled = Params&&Params.http_method_enabled?Params.http_method_enabled:0;
    this.http_method = Params&&Params.http_method?Params.http_method:0;

    this.term_cause_enabled = Params&&Params.term_cause_enabled?Params.term_cause_enabled:0;
    this.term_cause = Params&&Params.term_cause?Params.term_cause:0;

    this.abonent_id_enabled = Params&&Params.abonent_id_enabled?Params.abonent_id_enabled:0;
    this.abonent_id = Params&&Params.abonent_id?Params.abonent_id:'';

    TaskFormAddressTransformator(this, "client_info", Params, ToForm, FromForm);

    TaskFormAddressTransformator(this, "server_info", Params, ToForm, FromForm);
};

function TaskFormImModel(Params, ToForm, FromForm)
{
    this.enabled = Params&&Params.enabled?Params.enabled:0;

    this.point_id_enabled = Params&&Params.point_id_enabled?Params.point_id_enabled:0;
    this.point_id = Params&&Params.point_id?Params.point_id:0;

    this.user_login_enabled = Params&&Params.user_login_enabled?Params.user_login_enabled:0;
    this.user_login = Params&&Params.user_login?Params.user_login:'';

    this.user_password_enabled = Params&&Params.user_password_enabled?Params.user_password_enabled:0;
    this.user_password = Params&&Params.user_password?Params.user_password:"";

    this.sender_name_enabled = Params&&Params.sender_name_enabled?Params.sender_name_enabled:0;
    this.sender_name = Params&&Params.sender_name?Params.sender_name:"";

    this.sender_uin_enabled = Params&&Params.sender_uin_enabled?Params.sender_uin_enabled:0;
    this.sender_uin = Params&&Params.sender_uin?Params.sender_uin:"";

    this.receiver_name_enabled = Params&&Params.receiver_name_enabled?Params.receiver_name_enabled:0;
    this.receiver_name = Params&&Params.receiver_name?Params.receiver_name:"";

    this.receiver_uin_enabled = Params&&Params.receiver_uin_enabled?Params.receiver_uin_enabled:0;
    this.receiver_uin = Params&&Params.receiver_uin?Params.receiver_uin:"";

    this.protocol_enabled = Params&&Params.protocol_enabled?Params.protocol_enabled:0;
    this.protocol = Params&&Params.protocol?Params.protocol:0;

    this.term_cause_enabled = Params&&Params.term_cause_enabled?Params.term_cause_enabled:0;
    this.term_cause = Params&&Params.term_cause?Params.term_cause:0;

    this.abonent_id_enabled = Params&&Params.abonent_id_enabled?Params.abonent_id_enabled:0;
    this.abonent_id = Params&&Params.abonent_id?Params.abonent_id:'';

    this.message_enabled = Params&&Params.message_enabled?Params.message_enabled:0;
    this.message = Params&&Params.message?Params.message:'';

    TaskFormAddressTransformator(this, "client_info", Params, ToForm, FromForm);

    TaskFormAddressTransformator(this, "server_info", Params, ToForm, FromForm);
};

function TaskFormRfModel(Params, ToForm, FromForm)
{
    this.enabled = Params&&Params.enabled?Params.enabled:0;

    this.point_id_enabled = Params&&Params.point_id_enabled?Params.point_id_enabled:0;
    this.point_id = Params&&Params.point_id?Params.point_id:0;

    this.protocol_code_enabled = Params&&Params.protocol_code_enabled?Params.protocol_code_enabled:0;
    this.protocol_code = Params&&Params.protocol_code?Params.protocol_code:0;

    this.sni_enabled = Params&&Params.sni_enabled?Params.sni_enabled:0;
    this.sni = Params&&Params.sni?Params.sni:'';

    this.abonent_id_enabled = Params&&Params.abonent_id_enabled?Params.abonent_id_enabled:0;
    this.abonent_id = Params&&Params.abonent_id?Params.abonent_id:'';

    TaskFormAddressTransformator(this, "client_info", Params, ToForm, FromForm);

    TaskFormAddressTransformator(this, "server_info", Params, ToForm, FromForm);
};

function TaskFormTermModel(Params, ToForm, FromForm)
{
    this.enabled = Params&&Params.enabled?Params.enabled:0;

    this.point_id_enabled = Params&&Params.point_id_enabled?Params.point_id_enabled:0;
    this.point_id = Params&&Params.point_id?Params.point_id:0;

    this.abonent_id_enabled = Params&&Params.abonent_id_enabled?Params.abonent_id_enabled:0;
    this.abonent_id = Params&&Params.abonent_id?Params.abonent_id:'';

    TaskFormAddressTransformator(this, "client_info", Params, ToForm, FromForm);

    TaskFormAddressTransformator(this, "server_info", Params, ToForm, FromForm);
};

function TaskFormPstnModel(Params, ToForm, FromForm)
{
    this.enabled = Params&&Params.enabled?Params.enabled:0;

    this.duration_enabled = Params&&Params.duration_enabled?Params.duration_enabled:0;
    this.duration = Params&&Params.duration?Params.duration:0;

    this.call_type_enabled = Params&&Params.call_type_enabled?Params.call_type_enabled:0;
    this.call_type = Params&&Params.call_type?Params.call_type:0;

    this.in_abonent_type_enabled = Params&&Params.in_abonent_type_enabled?Params.in_abonent_type_enabled:0;
    this.in_abonent_type = Params&&Params.in_abonent_type?Params.in_abonent_type:0;

    this.out_abonent_type_enabled = Params&&Params.out_abonent_type_enabled?Params.out_abonent_type_enabled:0;
    this.out_abonent_type = Params&&Params.out_abonent_type?Params.out_abonent_type:0;

    this.switch_id_enabled = Params&&Params.switch_id_enabled?Params.switch_id_enabled:0;
    this.switch_id = Params&&Params.switch_id?Params.switch_id:'';

    this.border_switch_id_enabled = Params&&Params.border_switch_id_enabled?Params.border_switch_id_enabled:0;
    this.border_switch_id = Params&&Params.border_switch_id?Params.border_switch_id:'';

    this.supplement_service_id_enabled = Params&&Params.supplement_service_id_enabled?Params.supplement_service_id_enabled:0;
    this.supplement_service_id = Params&&Params.supplement_service_id?Params.supplement_service_id:0;

    this.forwarding_identifier_enabled = Params&&Params.forwarding_identifier_enabled?Params.forwarding_identifier_enabled:0;
    this.forwarding_identifier = Params&&Params.forwarding_identifier?Params.forwarding_identifier:'';

    this.inbound_bunch_enabled = Params&&Params.inbound_bunch_enabled?Params.inbound_bunch_enabled:0;
    this.inbound_bunch = Params&&Params.inbound_bunch?Params.inbound_bunch:0;

    this.outbound_bunch_enabled = Params&&Params.outbound_bunch_enabled?Params.outbound_bunch_enabled:0;
    this.outbound_bunch = Params&&Params.outbound_bunch?Params.outbound_bunch:0;

    this.term_cause_enabled = Params&&Params.term_cause_enabled?Params.term_cause_enabled:0;
    this.term_cause = Params&&Params.term_cause?Params.term_cause:0;

    this.phone_card_number_enabled = Params&&Params.phone_card_number_enabled?Params.phone_card_number_enabled:0;
    this.phone_card_number = Params&&Params.phone_card_number?Params.phone_card_number:'';

    this.in_info_directory_number_enabled = Params&&Params.in_info_directory_number_enabled?Params.in_info_directory_number_enabled:0;
    this.in_info_directory_number = Params&&Params.in_info_directory_number?Params.in_info_directory_number:'';

    this.in_info_internal_number_enabled = Params&&Params.in_info_internal_number_enabled?Params.in_info_internal_number_enabled:0;
    this.in_info_internal_number = Params&&Params.in_info_internal_number?Params.in_info_internal_number:'';

    this.out_info_directory_number_enabled = Params&&Params.out_info_directory_number_enabled?Params.out_info_directory_number_enabled:0;
    this.out_info_directory_number = Params&&Params.out_info_directory_number?Params.out_info_directory_number:'';

    this.out_info_internal_number_enabled = Params&&Params.out_info_internal_number_enabled?Params.out_info_internal_number_enabled:0;
    this.out_info_internal_number = Params&&Params.out_info_internal_number?Params.out_info_internal_number:'';
};

function TaskFormMobileModel(Params, ToForm, FromForm)
{
    this.enabled = Params&&Params.enabled?Params.enabled:0;

    this.duration_enabled = Params&&Params.duration_enabled?Params.duration_enabled:0;
    this.duration = Params&&Params.duration?Params.duration:0;

    this.call_type_enabled = Params&&Params.call_type_enabled?Params.call_type_enabled:0;
    this.call_type = Params&&Params.call_type?Params.call_type:0;

    this.in_abonent_type_enabled = Params&&Params.in_abonent_type_enabled?Params.in_abonent_type_enabled:0;
    this.in_abonent_type = Params&&Params.in_abonent_type?Params.in_abonent_type:0;

    this.out_abonent_type_enabled = Params&&Params.out_abonent_type_enabled?Params.out_abonent_type_enabled:0;
    this.out_abonent_type = Params&&Params.out_abonent_type?Params.out_abonent_type:0;

    this.switch_id_enabled = Params&&Params.switch_id_enabled?Params.switch_id_enabled:0;
    this.switch_id = Params&&Params.switch_id?Params.switch_id:0;

    this.border_switch_id_enabled = Params&&Params.border_switch_id_enabled?Params.border_switch_id_enabled:0;
    this.border_switch_id = Params&&Params.border_switch_id?Params.border_switch_id:'';

    this.supplement_service_id_enabled = Params&&Params.supplement_service_id_enabled?Params.supplement_service_id_enabled:0;
    this.supplement_service_id = Params&&Params.supplement_service_id?Params.supplement_service_id:0;

    TaskFormGsmIdentifierModel(this, "in_info_gsm", Params, ToForm, FromForm);

    TaskFormCdmaIdentifierModel(this, "in_info_cdma", Params, ToForm, FromForm);

    TaskFormGsmIdentifierModel(this, "out_info_gsm", Params, ToForm, FromForm);

    TaskFormCdmaIdentifierModel(this, "out_info_cdma", Params, ToForm, FromForm);

    this.forwarding_identifier_enabled = Params&&Params.forwarding_identifier_enabled?Params.forwarding_identifier_enabled:0;
    this.forwarding_identifier = Params&&Params.forwarding_identifier?Params.forwarding_identifier:'';

    TaskFormBunchTransformator(this, "inbound_bunch", Params, ToForm, FromForm);

    TaskFormBunchTransformator(this, "outbound_bunch", Params, ToForm, FromForm);

    this.roaming_partner_id_enabled = Params&&Params.roaming_partner_id_enabled?Params.roaming_partner_id_enabled:0;
    this.roaming_partner_id = Params&&Params.roaming_partner_id?Params.roaming_partner_id:0;

    this.term_cause_enabled = Params&&Params.term_cause_enabled?Params.term_cause_enabled:0;
    this.term_cause = Params&&Params.term_cause?Params.term_cause:0;

    this.dialed_digits_enabled = Params&&Params.dialed_digits_enabled?Params.dialed_digits_enabled:0;
    this.dialed_digits = Params&&Params.dialed_digits?Params.dialed_digits:'';

    TaskFormLocationTransformator(this, "in_begin_location", Params, ToForm, FromForm);

    TaskFormLocationTransformator(this, "in_end_location", Params, ToForm, FromForm);

    TaskFormLocationTransformator(this, "out_begin_location", Params, ToForm, FromForm);

    TaskFormLocationTransformator(this, "out_end_location", Params, ToForm, FromForm);
};

function TaskFormAaaModel(Params, ToForm, FromForm)
{
    this.enabled = Params&&Params.enabled?Params.enabled:0;

    this.point_id_enabled = Params&&Params.point_id_enabled?Params.point_id_enabled:0;
    this.point_id = Params&&Params.point_id?Params.point_id:0;

    this.login_type_enabled = Params&&Params.login_type_enabled?Params.login_type_enabled:0;
    this.login_type = Params&&Params.login_type?Params.login_type:0;

    this.user_equipment_enabled = Params&&Params.user_equipment_enabled?Params.user_equipment_enabled:0;
    this.user_equipment = Params&&Params.user_equipment ? new TaskFormNetworkEquipmentModel(Params.user_equipment) : null;

    if(Params&&(Params.user_equipment_mac || Params.user_equipment_atm_vpi || Params.user_equipment_atm_vci))
        this.user_equipment = new TaskFormNetworkEquipmentModel({mac:Params.user_equipment_mac, atm_vpi:Params.user_equipment_atm_vpi, atm_vci:Params.user_equipment_atm_vci});

    this.user_equipment_mac = this.user_equipment&&this.user_equipment.mac?this.user_equipment.mac:"";
    this.user_equipment_atm_vpi = this.user_equipment&&this.user_equipment.atm_vpi?this.user_equipment.atm_vpi:"";
    this.user_equipment_atm_vci = this.user_equipment&&this.user_equipment.atm_vci?this.user_equipment.atm_vci:"";

    this.allocated_ip_enabled = Params&&Params.allocated_ip_enabled?Params.allocated_ip_enabled:0;
    this.allocated_ip = Params&&Params.allocated_ip?Params.allocated_ip:"";

    this.user_name_enabled = Params&&Params.user_name_enabled?Params.user_name_enabled:0;//
    this.user_name = Params&&Params.user_name?Params.user_name:'';

    this.user_password_enabled = Params&&Params.user_password_enabled?Params.user_password_enabled:0;//
    this.user_password = Params&&Params.user_password?Params.user_password:'';

    this.connect_type_enabled = Params&&Params.connect_type_enabled?Params.connect_type_enabled:0;
    this.connect_type = Params&&Params.connect_type?Params.connect_type:0;

    this.calling_number_enabled = Params&&Params.calling_number_enabled?Params.calling_number_enabled:0;
    this.calling_number = Params&&Params.calling_number?Params.calling_number:'';

    this.called_number_enabled = Params&&Params.called_number_enabled?Params.called_number_enabled:0;
    this.called_number = Params&&Params.called_number?Params.called_number:'';

    this.nas_enabled = Params&&Params.nas_enabled?Params.nas_enabled:0;
    this.nas = Params&&Params.nas ? new TaskFormAddressModel(Params.nas) : null;

    if(Params&&(Params.nas_ip || Params.nas_port))
        this.nas = new TaskFormAddressModel({ip:Params.nas_ip, port:Params.nas_port});

    this.nas_ip = this.nas&&this.nas.ip?this.nas.ip:"";
    this.nas_port = this.nas&&this.nas.port?this.nas.port:"";

    this.apn_enabled = Params&&Params.apn_enabled?Params.apn_enabled:0;
    this.apn = Params&&Params.apn?Params.apn:'';

    this.sgsn_ip_enabled = Params&&Params.sgsn_ip_enabled?Params.sgsn_ip_enabled:0;
    this.sgsn_ip = Params&&Params.sgsn_ip?Params.sgsn_ip:'';

    this.ggsn_ip_enabled = Params&&Params.ggsn_ip_enabled?Params.ggsn_ip_enabled:0;
    this.ggsn_ip = Params&&Params.ggsn_ip?Params.ggsn_ip:'';

    this.service_area_code_enabled = Params&&Params.service_area_code_enabled?Params.service_area_code_enabled:0;
    this.service_area_code = Params&&Params.service_area_code?Params.service_area_code:0;

    this.location_start_enabled = Params&&Params.location_start_enabled?Params.location_start_enabled:0;
    this.location_start = Params&&Params.location_start ? new TaskFormLocationModel(Params.location_start) : null;

    if(Params&&(Params.location_start_lac || Params.location_start_cell || Params.location_start_ta))
        this.location_start = new TaskFormLocationModel({lac:Params.location_start_lac, cell:Params.location_start_cell, ta:Params.location_start_ta});

    this.location_start_lac = this.location_start&&this.location_start.lac?this.location_start.lac:"";
    this.location_start_cell = this.location_start&&this.location_start.cell?this.location_start.cell:"";
    this.location_start_ta = this.location_start&&this.location_start.ta?this.location_start.ta:"";

    this.location_end_enabled = Params&&Params.location_end_enabled?Params.location_end_enabled:0;
    this.location_end = Params&&Params.location_end ? new TaskFormLocationModel(Params.location_end) : null;

    if(Params&&(Params.location_end_lac || Params.location_end_cell || Params.location_end_ta))
        this.location_end = new TaskFormLocationModel({lac:Params.location_end_lac, cell:Params.location_end_cell, ta:Params.location_end_ta});

    this.location_end_lac = this.location_end&&this.location_end.lac?this.location_end.lac:"";
    this.location_end_cell = this.location_end&&this.location_end.cell?this.location_end.cell:"";
    this.location_end_ta = this.location_end&&this.location_end.ta?this.location_end.ta:"";

    this.phone_card_number_enabled = Params&&Params.phone_card_number_enabled?Params.phone_card_number_enabled:0;
    this.phone_card_number = Params&&Params.phone_card_number?Params.phone_card_number:'';

    this.imsi_enabled = Params&&Params.imsi_enabled?Params.imsi_enabled:0;
    this.imsi = Params&&Params.imsi?Params.imsi:'';

    this.imei_enabled = Params&&Params.imei_enabled?Params.imei_enabled:0;
    this.imei = Params&&Params.imei?Params.imei:'';

    this.esn_enabled = Params&&Params.esn_enabled?Params.esn_enabled:0;
    this.esn = Params&&Params.esn?Params.esn:'';

    this.pool_number_enabled = Params&&Params.pool_number_enabled?Params.pool_number_enabled:0;
    this.pool_number = Params&&Params.pool_number?Params.pool_number:'';

    if(ToForm)
    {
        delete this.user_equipment;

        delete this.nas;

        delete this.location_start;

        delete this.location_end;
    }

    if(FromForm)
    {
        delete this.user_equipment_mac;
        delete this.user_equipment_atm_vpi;
        delete this.user_equipment_atm_vci;

        delete this.nas_ip;
        delete this.nas_port;

        delete this.location_start_lac;
        delete this.location_start_cell;
        delete this.location_start_ta;

        delete this.location_end_lac;
        delete this.location_end_cell;
        delete this.location_end_ta;
    }
};

function TaskFormModel(Params, PartMain, PartAdd)
{
    if(typeof PartMain == "undefined" || PartMain === true)
    {
        this.title = Params&&Params.title?Params.title:null;
        this.user_id = Params&&Params.user_id?Params.user_id:0;
        this.telcos = Params&&Params.telcos?Params.telcos:"";
        this.abonent_list = Params&&Params.abonent_list?Params.abonent_list:'';
        this.date = Params&&Params.date?Params.date:null;

        var format =  webix.Date.dateToStr("%Y-%m-%d %H:%i:%s");

        if(this.date && this.date.start && typeof this.date.start.getMonth === 'function')
            this.date.start = format(this.date.start);

        if(this.date && this.date.end && typeof this.date.end.getMonth === 'function')
            this.date.end = format(this.date.end);

        this.total_limit = Params&&Params.total_limit?Params.total_limit:2000;

        this.resources = Params&&Params.resources?Params.resources:0;
        this.sessions = Params&&Params.sessions?Params.sessions:0;
        this.threads = Params&&Params.threads?Params.threads:0;
    }

    if(typeof PartAdd == "undefined" || PartAdd === true)
    {
        this.aaa = Params&&Params.aaa?Params.aaa:new TaskFormAaaModel();
        this.http = Params&&Params.http?Params.http:new TaskFormHttpModel();
        this.ftp = Params&&Params.ftp?Params.ftp:new TaskFormFtpModel();
        this.mail = Params&&Params.mail?Params.mail:new TaskFormMailModel();
        this.im = Params&&Params.im?Params.im:new TaskFormImModel();
        this.voip = Params&&Params.voip?Params.voip:new TaskFormVoipModel();
        this.pstn = Params&&Params.pstn?Params.pstn:new TaskFormPstnModel();
        this.term = Params&&Params.term?Params.term:new TaskFormTermModel();
        this.raw_flows = Params&&Params.raw_flows?Params.raw_flows:new TaskFormRfModel();
    }

};

function TaskFormToParamsModel(Params)
{
    this.title = Params&&Params.title?Params.title:null;
    this.user_id = Params&&Params.user_id?Params.user_id:0;

    this.telcos = Params&&Params.telcos?Params.telcos:"";

    if(this.telcos)
    {
        var telcos = this.telcos.split(",");

        var newTelcos = [];

        telcos.forEach(
            function (Telco) {
                if(App.DicsManager.GetDic(DicsEnum.TelcosDic)[Telco])
                    newTelcos.push(Telco);
            }
        );

        this.telcos = newTelcos.join(",");
    }

    this.abonent_list = Params&&Params.abonent_list?Params.abonent_list:'';

    this.date = Params&&Params.date?Params.date:null;
    if(this.date && this.date.start)
        this.date.start = new Date(this.date.start);
    if(this.date && this.date.end)
        this.date.end = new Date(this.date.end);

    this.total_limit = Params&&Params.total_limit?Params.total_limit:2000;

    this.resources = Params&&Params.resources?Params.resources:0;
    this.sessions = Params&&Params.sessions?Params.sessions:0;
    this.threads = Params&&Params.threads?Params.threads:0;
};

function TaskParamsToFormModel(Params)
{
    this.title = Params&&Params.title?Params.title:null;
    this.user_id = Params&&Params.user_id?Params.user_id:0;
    this.telcos = Params&&Params.telcos?Params.telcos:"";
    this.abonent_list = Params&&Params.abonent_list?Params.abonent_list:'';

    this.date = Params&&Params.date?Params.date:null;

    this.total_limit = Params&&Params.total_limit?Params.total_limit:2000;

    this.resources = Params&&Params.resources?Params.resources:0;
    this.sessions = Params&&Params.sessions?Params.sessions:0;
    this.threads = Params&&Params.threads?Params.threads:0;
};

function TaskDataModel(Params)
{
    this.id = Params&&Params.id?Params.id:webix.uid();
    this.task_id = Params&&Params.task_id?Params.task_id:0;
    this.data_type = Params&&Params.data_type?Params.data_type:TaskDataModelType.email;
    this.sync = Params&&Params.sync?new TaskDataSyncInfoModel(Params.sync):new TaskDataSyncInfoModel();
    this.data = Params&&Params.data?Params.data:[];
};

function TaskDataMailModel(Params)
{
    this.id = Params&&Params.id?Params.id:webix.uid();

    this.mc_id = Params&&Params.mc_id?Params.mc_id:0;
    this.task_id = Params&&Params.task_id?Params.task_id:0;
    this.begin_connect = Params&&Params.begin_connect?Params.begin_connect:null;
    this.end_connect = Params&&Params.end_connect?Params.end_connect:null;
    this.telco_code = Params&&Params.telco_code?Params.telco_code:0;
    this.telco = Params&&Params.telco?Params.telco:"";
    this.client_address = Params&&Params.client_address?Params.client_address:'';
    this.client_port = Params&&Params.client_port?Params.client_port:0;
    this.server_address = Params&&Params.server_address?Params.server_address:'';
    this.server_port = Params&&Params.server_port?Params.server_port:0;
    this.protocol_code = Params&&Params.protocol_code?Params.protocol_code:0;
    this.protocol_port = Params&&Params.protocol_port?Params.protocol_port:"";
    this.point_id = Params&&Params.point_id?Params.point_id:0;
    this.point = Params&&Params.point?Params.point:"";
    this.mlet_id = Params&&Params.mlet_id?Params.mlet_id:0;
    this.sender = Params&&Params.sender?Params.sender:'';
    this.subject = Params&&Params.subject?Params.subject:'';
    this.message = Params&&Params.message?Params.message:'';
    this.size = Params&&Params.size?Params.size:0;
    this.attachements = Params&&Params.attachements?Params.attachements:0;
    this.reply_to = Params&&Params.reply_to?Params.reply_to:0;
    this.emlp_id = Params&&Params.emlp_id?Params.emlp_id:0;
    this.term_cause = Params&&Params.term_cause?Params.term_cause:0;
    this.term = Params&&Params.term?Params.term:0;
    this.receiver = Params&&Params.receiver?Params.receiver:'';
    this.cc = Params&&Params.cc?Params.cc:'';
    this.mail_server = Params&&Params.mail_server?Params.mail_server:'';

    this.abonent_id = Params&&Params.abonent_id?Params.abonent_id:null;
};

function TaskDataFtpModel(Params)
{
    this.id = Params&&Params.id?Params.id:webix.uid();

    this.fc_id = Params&&Params.fc_id?Params.fc_id:0;
    this.task_id = Params&&Params.task_id?Params.task_id:0;
    this.begin_connect = Params&&Params.begin_connect?Params.begin_connect:null;
    this.end_connect = Params&&Params.end_connect?Params.end_connect:null;
    this.telco_code = Params&&Params.telco_code?Params.telco_code:0;
    this.telco = Params&&Params.telco?Params.telco:"";
    this.client_address = Params&&Params.client_address?Params.client_address:"";
    this.client_port = Params&&Params.client_port?Params.client_port:0;
    this.server_address = Params&&Params.server_address?Params.server_address:"";
    this.server_port = Params&&Params.server_port?Params.server_port:0;
    this.protocol_code = Params&&Params.protocol_code?Params.protocol_code:0;
    this.protocol_port = Params&&Params.protocol_port?Params.protocol_port:"";
    this.point_id = Params&&Params.point_id?Params.point_id:0;
    this.point = Params&&Params.point?Params.point:"";
    this.server_name = Params&&Params.server_name?Params.server_name:"";
    this.user_name = Params&&Params.user_name?Params.user_name:"";
    this.user_password = Params&&Params.user_password?Params.user_password:"";
    this.server_type = Params&&Params.server_type?Params.server_type:0;
    this.in_bytes = Params&&Params.in_bytes?Params.in_bytes:0;
    this.out_bytes = Params&&Params.out_bytes?Params.out_bytes:0;

    this.term_cause = Params&&Params.term_cause?Params.term_cause:0;
    this.term = Params&&Params.term?Params.term:0;

    this.abonent_id = Params&&Params.abonent_id?Params.abonent_id:null;
};

function TaskDataVoipModel(Params)
{
    this.id = Params&&Params.id?Params.id:webix.uid();

    this.fc_id = Params&&Params.fc_id?Params.fc_id:0;
    this.task_id = Params&&Params.task_id?Params.task_id:0;
    this.begin_connect = Params&&Params.begin_connect?Params.begin_connect:null;
    this.end_connect = Params&&Params.end_connect?Params.end_connect:null;
    this.telco_code = Params&&Params.telco_code?Params.telco_code:0;
    this.telco = Params&&Params.telco?Params.telco:"";
    this.client_address = Params&&Params.client_address?Params.client_address:"";
    this.client_port = Params&&Params.client_port?Params.client_port:0;
    this.server_address = Params&&Params.server_address?Params.server_address:"";
    this.server_port = Params&&Params.server_port?Params.server_port:0;
    this.protocol_code = Params&&Params.protocol_code?Params.protocol_code:0;
    this.protocol_port = Params&&Params.protocol_port?Params.protocol_port:"";
    this.point_id = Params&&Params.point_id?Params.point_id:0;
    this.point = Params&&Params.point?Params.point:"";

    this.session_id = Params&&Params.session_id?Params.session_id:"";
    this.conference_id = Params&&Params.conference_id?Params.conference_id:"";
    this.duration = Params&&Params.duration?Params.duration:0;
    this.originator_name = Params&&Params.originator_name?Params.originator_name:"";
    this.service_type = Params&&Params.service_type?Params.service_type:0;
    this.calling_number = Params&&Params.calling_number?Params.calling_number:"";
    this.called_number = Params&&Params.called_number?Params.called_number:"";
    this.fax = Params&&Params.fax?Params.fax:0;
    this.inbound_bunch = Params&&Params.inbound_bunch?Params.inbound_bunch:"";
    this.outbound_bunch = Params&&Params.outbound_bunch?Params.outbound_bunch:"";
    this.gateways = Params&&Params.gateways?Params.gateways:"";
    this.voip_protocol = Params&&Params.voip_protocol?Params.voip_protocol:0;
    this.service_id = Params&&Params.service_id?Params.service_id:"";
    this.in_bytes = Params&&Params.in_bytes?Params.in_bytes:0;
    this.out_bytes = Params&&Params.out_bytes?Params.out_bytes:0;

    this.term_cause = Params&&Params.term_cause?Params.term_cause:0;
    this.term = Params&&Params.term?Params.term:0;

    this.abonent_id = Params&&Params.abonent_id?Params.abonent_id:null;
};

function TaskDataHttpModel(Params) 
{
    this.id = Params && Params.id ? Params.id : webix.uid();

    this.h_id = Params&&Params.h_id?Params.h_id:0;
    this.task_id = Params&&Params.task_id?Params.task_id:0;
    this.begin_connect = Params&&Params.begin_connect?Params.begin_connect:null;
    this.end_connect = Params&&Params.end_connect?Params.end_connect:null;
    this.telco_code = Params&&Params.telco_code?Params.telco_code:0;
    this.telco = Params&&Params.telco?Params.telco:"";
    this.client_address = Params&&Params.client_address?Params.client_address:"";
    this.client_port = Params&&Params.client_port?Params.client_port:0;
    this.server_address = Params&&Params.server_address?Params.server_address:"";
    this.server_port = Params&&Params.server_port?Params.server_port:0;
    this.protocol_code = Params&&Params.protocol_code?Params.protocol_code:0;
    this.protocol_port = Params&&Params.protocol_port?Params.protocol_port:"";
    this.point_id = Params&&Params.point_id?Params.point_id:0;
    this.point = Params&&Params.point?Params.point:"";
    this.term_cause = Params&&Params.term_cause?Params.term_cause:0;
    this.term = Params&&Params.term?Params.term:0;

    this.url = Params&&Params.url?Params.url:'';
    this.bytes = Params&&Params.bytes?Params.bytes:0;
    this.http_method = Params&&Params.http_method?Params.http_method:0;

    this.aaa_username = Params&&Params.aaa_username?Params.aaa_username:"";
    this.aaa_result = Params&&Params.aaa_result?Params.aaa_result:"";

    this.abonent_id = Params&&Params.abonent_id?Params.abonent_id:null;
};

function TaskDataImModel(Params) 
{
    this.id = Params && Params.id ? Params.id : webix.uid();

    this.h_id = Params&&Params.h_id?Params.h_id:0;
    this.task_id = Params&&Params.task_id?Params.task_id:0;
    this.begin_connect = Params&&Params.begin_connect?Params.begin_connect:null;
    this.end_connect = Params&&Params.end_connect?Params.end_connect:null;
    this.telco_code = Params&&Params.telco_code?Params.telco_code:0;
    this.telco = Params&&Params.telco?Params.telco:"";
    this.client_address = Params&&Params.client_address?Params.client_address:"";
    this.client_port = Params&&Params.client_port?Params.client_port:0;
    this.server_address = Params&&Params.server_address?Params.server_address:"";
    this.server_port = Params&&Params.server_port?Params.server_port:0;
    this.protocol_code = Params&&Params.protocol_code?Params.protocol_code:0;
    this.protocol_port = Params&&Params.protocol_port?Params.protocol_port:"";
    this.point_id = Params&&Params.point_id?Params.point_id:0;
    this.point = Params&&Params.point?Params.point:"";
    this.term_cause = Params&&Params.term_cause?Params.term_cause:0;
    this.term = Params&&Params.term?Params.term:0;

    this.im_size = Params&&Params.im_size?Params.im_size:null;
    this.user_login = Params&&Params.user_login?Params.user_login:null;
    this.user_password = Params&&Params.user_password?Params.user_password:null;
    this.sender_name = Params&&Params.sender_name?Params.sender_name:null;
    this.sender_uin = Params&&Params.sender_uin?Params.sender_uin:null;
    this.receiver_name = Params&&Params.receiver_name?Params.receiver_name:null;
    this.receiver_uin = Params&&Params.receiver_uin?Params.receiver_uin:null;
    this.protocol = Params&&Params.protocol?Params.protocol:null;

    this.abonent_id = Params&&Params.abonent_id?Params.abonent_id:null;

    this.message = Params&&Params.message?Params.message:'';
};

function TaskDataRfModel(Params)
{
    this.id = Params && Params.id ? Params.id : webix.uid();

    this.r_id = Params&&Params.r_id?Params.r_id:0;
    this.task_id = Params&&Params.task_id?Params.task_id:0;
    this.begin_connect = Params&&Params.begin_connect?Params.begin_connect:null;
    this.end_connect = Params&&Params.end_connect?Params.end_connect:null;
    this.telco_code = Params&&Params.telco_code?Params.telco_code:0;
    this.telco = Params&&Params.telco?Params.telco:"";
    this.client_address = Params&&Params.client_address?Params.client_address:"";
    this.client_port = Params&&Params.client_port?Params.client_port:0;
    this.server_address = Params&&Params.server_address?Params.server_address:"";
    this.server_port = Params&&Params.server_port?Params.server_port:0;
    this.protocol_code = Params&&Params.protocol_code?Params.protocol_code:0;
    this.protocol_port = Params&&Params.protocol_port?Params.protocol_port:"";
    this.point_id = Params&&Params.point_id?Params.point_id:0;
    this.point = Params&&Params.point?Params.point:"";

    this.in_bytes = Params&&Params.in_bytes?Params.in_bytes:null;
    this.out_bytes = Params&&Params.out_bytes?Params.out_bytes:null;

    this.sni = Params&&Params.sni?Params.sni:"";

    this.abonent_id = Params&&Params.abonent_id?Params.abonent_id:null;
};

function TaskDataTermModel(Params)
{
    this.id = Params && Params.id ? Params.id : webix.uid();

    this.t_id = Params&&Params.t_id?Params.t_id:0;
    this.task_id = Params&&Params.task_id?Params.task_id:0;
    this.begin_connect = Params&&Params.begin_connect?Params.begin_connect:null;
    this.end_connect = Params&&Params.end_connect?Params.end_connect:null;
    this.telco_code = Params&&Params.telco_code?Params.telco_code:0;
    this.telco = Params&&Params.telco?Params.telco:"";
    this.client_address = Params&&Params.client_address?Params.client_address:"";
    this.client_port = Params&&Params.client_port?Params.client_port:0;
    this.server_address = Params&&Params.server_address?Params.server_address:"";
    this.server_port = Params&&Params.server_port?Params.server_port:0;
    this.protocol_code = Params&&Params.protocol_code?Params.protocol_code:0;
    this.protocol_port = Params&&Params.protocol_port?Params.protocol_port:"";
    this.point_id = Params&&Params.point_id?Params.point_id:0;
    this.point = Params&&Params.point?Params.point:"";

    this.in_bytes = Params&&Params.in_bytes?Params.in_bytes:null;
    this.out_bytes = Params&&Params.out_bytes?Params.out_bytes:null;
    this.term_protocol = Params&&Params.term_protocol?Params.term_protocol:null;

    this.abonent_id = Params&&Params.abonent_id?Params.abonent_id:null;
};

function TaskDataPstnModel(Params)
{
    this.id = Params && Params.id ? Params.id : webix.uid();

    this.p_id = Params&&Params.p_id?Params.p_id:0;
    this.task_id = Params&&Params.task_id?Params.task_id:0;
    this.telco_code = Params&&Params.telco_code?Params.telco_code:0;
    this.telco = Params&&Params.telco?Params.telco:"";
    this.begin_connect = Params&&Params.begin_connect?Params.begin_connect:null;
    this.duration  = Params&&Params.duration ?Params.duration:0;
    this.call_type = Params&&Params.call_type?Params.call_type:0;
    this.supplement_service_id = Params&&Params.supplement_service_id?Params.supplement_service_id:0;
    this.in_abonent_type = Params&&Params.in_abonent_type?Params.in_abonent_type:0;
    this.out_abonent_type = Params&&Params.out_abonent_type?Params.out_abonent_type:0;
    this.switch_id = Params&&Params.switch_id?Params.switch_id:0;
    this.switch = Params&&Params.switch?Params.switch:"";
    this.inbound_bunch = Params&&Params.inbound_bunch?Params.inbound_bunch:0;
    this.outbound_bunch = Params&&Params.outbound_bunch?Params.outbound_bunch:0;
    this.inbound_bunch_dic = Params&&Params.inbound_bunch_dic?Params.inbound_bunch_dic:0;
    this.outbound_bunch_dic = Params&&Params.outbound_bunch_dic?Params.outbound_bunch_dic:0;
    this.term_cause = Params&&Params.term_cause?Params.term_cause:0;
    this.term = Params&&Params.term?Params.term:"";
    this.dialed_digits = Params&&Params.dialed_digits?Params.dialed_digits:'';
    this.border_switch_id = Params&&Params.border_switch_id?Params.border_switch_id:0;
    this.border_switch = Params&&Params.border_switch?Params.border_switch:"";
    this.in_directory_number = Params&&Params.in_directory_number?Params.in_directory_number:'';
    this.in_internal_number = Params&&Params.in_internal_number?Params.in_internal_number:'';
    this.out_directory_number = Params&&Params.out_directory_number?Params.out_directory_number:'';
    this.out_internal_number = Params&&Params.out_internal_number?Params.out_internal_number:'';
    this.phone_card_number = Params&&Params.phone_card_number?Params.phone_card_number:'';
    this.forwarding_identifier = Params&&Params.forwarding_identifier?Params.forwarding_identifier:'';
};

function TaskDataAaaModel(Params)
{
    this.id = Params && Params.id ? Params.id : webix.uid();

    this.a_id = Params&&Params.a_id?Params.a_id:0;
    this.telco_code = Params&&Params.telco_code?Params.telco_code:0;
    this.telco = Params&&Params.telco?Params.telco:"";

    this.connection_time = Params&&Params.connection_time?Params.connection_time:null;

    this.login_type = Params&&Params.login_type?Params.login_type:0;
    this.session_id = Params&&Params.session_id?Params.session_id:"";
    this.allocated_ip = Params&&Params.allocated_ip?Params.allocated_ip:"";
    this.user_name = Params&&Params.user_name?Params.user_name:"";
    this.connect_type = Params&&Params.connect_type?Params.connect_type:0;
    this.protocol_port = Params&&Params.protocol_port?Params.protocol_port:"";
    this.calling_number = Params&&Params.calling_number?Params.calling_number:"";
    this.called_number = Params&&Params.called_number?Params.called_number:"";
    this.nas_ip = Params&&Params.nas_ip?Params.nas_ip:"";
    this.nas_port = Params&&Params.nas_port?Params.nas_port:0;
    this.in_bytes = Params&&Params.in_bytes?Params.in_bytes:0;
    this.out_bytes = Params&&Params.out_bytes?Params.out_bytes:0;
    this.user_password = Params&&Params.user_password?Params.user_password:"";
    this.apn = Params&&Params.apn?Params.apn:"";
    this.service_area_code = Params&&Params.service_area_code?Params.service_area_code:0;
    this.phone_card_number = Params&&Params.phone_card_number?Params.phone_card_number:"";
    this.imsi = Params&&Params.imsi?Params.imsi:"";
    this.imei = Params&&Params.imei?Params.imei:"";
    this.esn = Params&&Params.esn?Params.esn:"";
    this.pool_number = Params&&Params.pool_number?Params.pool_number:"";
    this.user_equipment_mac = Params&&Params.user_equipment_mac?Params.user_equipment_mac:"";
    this.user_equipment_atm_vpi = Params&&Params.user_equipment_atm_vpi?Params.user_equipment_atm_vpi:"";
    this.user_equipment_atm_vci = Params&&Params.user_equipment_atm_vci?Params.user_equipment_atm_vci:"";
    this.sgsn_ip = Params&&Params.sgsn_ip?Params.sgsn_ip:"";
    this.ggsn_ip = Params&&Params.ggsn_ip?Params.ggsn_ip:"";
    this.location_start_lac = Params&&Params.location_start_lac?Params.location_start_lac:0;
    this.location_start_cell = Params&&Params.location_start_cell?Params.location_start_cell:0;
    this.location_start_ta = Params&&Params.location_start_ta?Params.location_start_ta:0;
    this.location_end_lac = Params&&Params.location_end_lac?Params.location_end_lac:0;
    this.location_end_cell = Params&&Params.location_end_cell?Params.location_end_cell:0;
    this.location_end_ta = Params&&Params.location_end_ta?Params.location_end_ta:0;
};

function TasksFilterModel(Params)
{
    this.paging = Params&&Params.id?Params.paging:{size: 100, group: 5, count:0, page:0};
    this.total = Params&&Params.id?Params.total:{size: 100, group: 5, count:0, page:0};
    this.filter = Params&&Params.id?Params.filter:{};
    this.sort = Params&&Params.id?Params.filter:{order_by:"task_id", order_dir:"desc"};
};

function ConnectionsValidateDataManager(Params)
{
    this.Active = true;

    this.Tasks = [];

    this.TasksFilter = {};
    this.TasksFilter.Paging = {
        size: 100,
        group: 5,
        count:0,
        page:0
    };
    this.TasksFilter.Total = {
        size: 100,
        group: 5,
        count:0,
        page:0
    };
    this.TasksFilter.Filter = {
        title:null,
        date:null,
        report_count:0
    };
    this.TasksFilter.Sort = {
        order_by:"task_id",
        order_dir:"desc"
    };


    this.TaskData = {};

    this.TaskDataFilter = {};

    this.SignalTasksUpdated = new Rx.BehaviorSubject(0);

    this.SignalTaskUpdated = new Rx.BehaviorSubject(0);

    this.SignalTaskDataUpdated = new Rx.BehaviorSubject(0);

    this.SignalPortsDicUpdated = new Rx.BehaviorSubject(0);

    this.CurrentTasksRequestId = 0;

    this.CurrentTaskDataRequestId = 0;

    //this.ProtocolsDic = {};

    this.Init();
};

ConnectionsValidateDataManager.prototype.Init = function(Params)
{
    var _this = this;

    // webix.ajax().get("/app/data/ports.json?v=5", function(Text, xml, xhr)
    // {
    //     _this.ProtocolsDic = JSON.parse(Text);
    //
    //     _this.SignalPortsDicUpdated.next(1);
    // });
};

ConnectionsValidateDataManager.prototype.Destroy = function(Params)
{
    this.Active = false;
};

ConnectionsValidateDataManager.prototype.RefershTasks = function(Params)
{
    var _this = this;

    var partial = Params&&Params.tasks_ids ? true : false;

    if(!partial)
        this.CurrentTasksRequestId = webix.uid();

    if(!_this.Active)
    {
        return;
    }

    if(Params&&Params.page >= 0)
    {
        this.TasksFilter.Total.page = parseInt(Params.page,10);
    }

    if(Params&&Params.size&&Params.size >= 0)
    {
        this.TasksFilter.Total.size = parseInt(Params.size,10);
    }

    if(Params&&Params.filter)
    {
        this.TasksFilter.Filter = Params.filter;
    }

    if(Params&&Params.sort)
    {
        this.TasksFilter.Sort.order_by = Params.sort.id;
        this.TasksFilter.Sort.order_dir = Params.sort.dir;
    }

    var partial = Params&&Params.tasks_ids ? true : false;

    var _params = {
        page:this.TasksFilter.Total.page,
        size:this.TasksFilter.Total.size,
        title:this.TasksFilter.Filter.title,
        date:this.TasksFilter.Filter.date,
        user_id:this.TasksFilter.Filter.user_id,
        status:this.TasksFilter.Filter.status,
        report_count:this.TasksFilter.Filter.report_count,
        order_by:this.TasksFilter.Sort.order_by,
        order_dir:this.TasksFilter.Sort.order_dir,

        tasks_ids:partial?Params&&Params.tasks_ids:null
    };

    if(!partial)
    {
        _params.parent_id_is_null = true;
    }

    var params =
        {
            id:this.CurrentTasksRequestId,
            method:"Sorm_GetTasks",
            data:{
                Params: _params
            },
            callback:function (R)
            {
                if(!_this.Active)
                {
                    return;
                }

                if(!partial && _this.CurrentTasksRequestId != R.id)
                {
                    return;
                }

                if(R.success)
                {
                    if(partial)
                    {
                        if(R.data)
                        {
                            R.data.rows.forEach(function (Task)
                            {
                                _this.SignalTaskUpdated.next(new TaskModel(Task));
                            });
                        }
                    }
                    else
                    {
                        _this.Tasks = [];
                        _this.TasksFilter.Total.page = 0;
                        _this.TasksFilter.Total.count = 0;

                        if(R.data)
                        {
                            R.data.rows.forEach(function (Task)
                            {
                                _this.Tasks.push(new TaskModel(Task));
                            });

                            _this.TasksFilter.Total.page = parseInt(R.data.page,10);
                            _this.TasksFilter.Total.count = parseInt(R.data.count,10);
                        }

                        _this.SignalTasksUpdated.next(1);
                    }
                }

                if(Params && Params.callback && typeof Params.callback === "function")
                {
                    Params.callback(R);
                }
            }
        };

    if(this.Active)
    {
        App.ServerManager.CallMethod(params);
    }
};

ConnectionsValidateDataManager.prototype.GetTasks = function()
{
    return this.Tasks;
};

ConnectionsValidateDataManager.prototype.RefreshTaskData = function(TaskModel, Params)
{
    var _this = this;

    this.CurrentTaskDataRequestId = webix.uid();

    if(!_this.Active)
    {
        return;
    }

    var task = TaskModel;

    var sormTask, dataType, dataModel;

    if(Params&&Params.data_type)
    {
        dataType = TaskDataModelType[Params.data_type];
    }

    if(!dataType)
    {
        return;
    }

    switch (dataType)
    {
        case TaskDataModelType.email:
            dataModel = TaskDataMailModel;
            break;
        case TaskDataModelType.ftp:
            dataModel = TaskDataFtpModel;
            break;
        case TaskDataModelType.voip:
            dataModel = TaskDataVoipModel;
            break;
        case TaskDataModelType.http:
            dataModel = TaskDataHttpModel;
            break;
        case TaskDataModelType.im:
            dataModel = TaskDataImModel;
            break;
        case TaskDataModelType.rf:
            dataModel = TaskDataRfModel;
            break;
        case TaskDataModelType.term:
            dataModel = TaskDataTermModel;
            break;
        case TaskDataModelType.pstn:
            dataModel = TaskDataPstnModel;
            break;
        case TaskDataModelType.aaa:
            dataModel = TaskDataAaaModel;
            break;
        default:
            return;
    }

    task.sorm_data.every(function(Task)
    {
        if(Task.task_type == TaskTypeEnum[dataType])
        {
            sormTask = Task;

            return false;
        }

        return true;
    });

    if(!sormTask)
    {
        return;
    }

    if(!this.TaskDataFilter[sormTask.task_id])
    {
        this.TaskDataFilter[sormTask.task_id] = new TasksFilterModel();
    }

    if(Params&&Params.page >= 0)
    {
        this.TaskDataFilter[sormTask.task_id].total.page = parseInt(Params.page,10);
    }

    if(Params&&Params.size&&Params.size >= 0)
    {
        this.TaskDataFilter[sormTask.task_id].total.size = parseInt(Params.size,10);
    }

    if(Params&&Params.filter)
    {
        this.TaskDataFilter[sormTask.task_id].filter = Params.filter;
    }

    if(Params&&Params.sort)
    {
        this.TaskDataFilter[sormTask.task_id].sort.order_by = Params.sort.id;
        this.TaskDataFilter[sormTask.task_id].sort.order_dir = Params.sort.dir;
    }

    var filterParams = {
        page:this.TaskDataFilter[sormTask.task_id].total.page,
        size:this.TaskDataFilter[sormTask.task_id].total.size,

        order_by:this.TaskDataFilter[sormTask.task_id].sort.order_by,
        order_dir:this.TaskDataFilter[sormTask.task_id].sort.order_dir
    };

    Object.keys(this.TaskDataFilter[sormTask.task_id].filter).forEach(function (Key)
    {
        if(_this.TaskDataFilter[sormTask.task_id].filter[Key])
            filterParams[Key] = _this.TaskDataFilter[sormTask.task_id].filter[Key];
    });

    var params =
        {
            id:this.CurrentTaskDataRequestId,
            method:"Sorm_GetTaskData"+Helper.CapitalizeFirst(dataType),
            data:{
                Task:sormTask,
                Params:filterParams
            },
            callback:function (R)
            {
                if(!_this.Active)
                {
                    return;
                }

                if(_this.CurrentTaskDataRequestId != R.id)
                {
                    return;
                }

                if(R.success)
                {
                    _this.TaskDataFilter[sormTask.task_id].total.page = 0;
                    _this.TaskDataFilter[sormTask.task_id].total.count = 0;

                    _this.TaskData[sormTask.task_id] = new TaskDataModel({task_id:sormTask.task_id, data_type:dataType, sync:R.data?R.data.sync:0});

                    if(R.data)
                    {
                        R.data.rows.forEach(function (Data)
                        {
                            _this.TaskData[sormTask.task_id].data.push(new dataModel(Data));
                        });

                        _this.TaskDataFilter[sormTask.task_id].total.page = parseInt(R.data.page,10);
                        _this.TaskDataFilter[sormTask.task_id].total.count = parseInt(R.data.count,10);
                    }

                    _this.SignalTaskDataUpdated.next(sormTask.task_id);
                }

                if(Params && Params.callback && typeof Params.callback === "function")
                {
                    Params.callback(R);
                }
            }
        };

    if(this.Active)
    {
        App.ServerManager.CallMethod(params);
    }
};

ConnectionsValidateDataManager.prototype.GetTaskData = function(TaskId)
{
    return this.TaskData[TaskId];
};

ConnectionsValidateDataManager.prototype.SaveTask = function(Params)
{
    var _this = this;

    var task = new TaskModel(Params&&Params.task?Params.task:null);
    var taskForm = new TaskFormModel(Params&&Params.task_form?Params.task_form:null);

    var params =
        {
            id: task.id,
            method:"Sorm_SaveTask",
            data: {
                Task: task,
                Form: taskForm
            },
            callback:function (R)
            {
                if(Params && Params.callback && typeof Params.callback === "function")
                {
                    Params.callback(R);
                }
            }
        };

    App.ServerManager.CallMethod(params);
};

ConnectionsValidateDataManager.prototype.DeleteTask = function(Params)
{
    var _this = this;

    var task = new TaskModel(Params&&Params.task?Params.task:null);

    var params =
        {
            id: task.id,
            method:"Sorm_DeleteTask",
            data: {
                Task: task
            },
            callback:function (R)
            {
                if(Params && Params.callback && typeof Params.callback === "function")
                {
                    Params.callback(R);
                }
            }
        };

    App.ServerManager.CallMethod(params);
};

ConnectionsValidateDataManager.prototype.ResetTaskDataSyncAttempts = function(TaskModel, Params)
{
    var _this = this;

    var task = TaskModel;

    var sormTask, dataType;

    if(Params&&Params.data_type)
    {
        dataType = TaskDataModelType[Params.data_type];
    }

    if(!dataType)
    {
        return;
    }

    task.sorm_data.every(function(Task)
    {
        if(Task.task_type == TaskTypeEnum[dataType])
        {
            sormTask = Task;

            return false;
        }

        return true;
    });

    var params =
        {
            id: task.id,
            method:"Sorm_ResetTaskDataSyncAttempts",
            data: {
                Task: sormTask
            },
            callback:function (R)
            {
                if(Params && Params.callback && typeof Params.callback === "function")
                {
                    Params.callback(R);
                }
            }
        };

    App.ServerManager.CallMethod(params);
};

var TragenTaskTypeEnum = {
    ui:"ui",
    udp:"udp",
    tcpsyn:"tcp-syn",
    http2:"http2",
    pcapsample:"pcap-sample"
};

var TragenTaskStatusEnum = {
    new: 0,
    progress: 1,
    stop: 2,
    error: 3
};

var TragenTaskGenerationStatusesEnum = {
    new: 0,
    initialization: 1,
    generation: 2,
    stop: 3,
    error: 4
};

function TragenProfileModel(Params)
{
    var __this = this;

    this.id = Params&&Params.id?Params.id:webix.uid();

    this.tt_id = Params&&Params.tt_id?Params.tt_id:0;
    this.parent_id = Params&&Params.parent_id?Params.parent_id:null;

    this.title = Params&&Params.title?Params.title:null;
    this.date = Params&&Params.date?Params.date:null;
    this.user_id = Params&&Params.user_id?Params.user_id:0;

    this.form_data = Params&&Params.form_data?Params.form_data:null;

    this.tragen_data = [];
    if(Params&&Params.tragen_data)
    {
        Params.tragen_data.forEach(function (Profile)
        {
            __this.tragen_data.push(new TragenProfileModel(Profile));
        });
    }

    this.traffic_count = Params&&Params.traffic_count?Params.traffic_count:0;

    this.task_status = Params&&Params.task_status?parseInt(Params.task_status):TragenTaskStatusEnum.new;
    if(this.tragen_data.length > 0)
    {
        this.tragen_data.every(function (SubProfile)
        {
            var s = parseInt(SubProfile.task_status);

            if(s == TragenTaskStatusEnum.progress)
            {
                __this.task_status = s;
                return false;
            }

            if(s > __this.task_status)
                __this.task_status = s;

            return true;
        });
    };

    this.gen_type = Params&&Params.gen_type?Params.gen_type:TragenTaskTypeEnum.ui;
    this.pid = Params&&Params.pid?Params.pid:0;

    this.data = [];
    if(Params&&Params.data)
    {
        Params.data.forEach(function (Profile)
        {
            __this.data.push(new TragenProfileModel(Profile));
        });
    }


    this.file_content = Params&&Params.file_content?Params.file_content:null;

    this.open = true;
    this.valid = true;
};

function TragenFormProfileModel(Params, PartMain)
{
    this.title = Params&&Params.title?Params.title:"";
    this.cores = Params&&Params.cores?Params.cores:"";
    this.network_port_arr = Params&&Params.network_port_arr?Params.network_port_arr:"";
    this.socket_mem = Params&&Params.socket_mem?Params.socket_mem:"";

    this.src_ip = Params&&Params.src_ip?Params.src_ip:"";
    this.dst_ip = Params&&Params.dst_ip?Params.dst_ip:"";

    this.src_port = Params&&Params.src_port?Params.src_port:"";
    this.dst_port = Params&&Params.dst_port?Params.dst_port:"";

    this.time = Params&&Params.time?Params.time:null;

    if(typeof PartMain == "undefined" || PartMain === false)
    {
        this.tasks = Params&&Params.tasks?Params.tasks:[];
    }
}

function GetTragenFormSubProfileModel(Params, Type, PartMain, PartAdd)
{
    var model = null;
    var type = Type?Type:TragenTaskTypeEnum.udp;

    switch (type)
    {
        case TragenTaskTypeEnum.udp:
            model = new TragenFormUdpModel(Params, PartMain, PartAdd);
            break;
        case TragenTaskTypeEnum.tcpsyn:
            model = new TragenFormTcpModel(Params, PartMain, PartAdd);
            break;
        case TragenTaskTypeEnum.http2:
            model = new TragenFormHttpModel(Params, PartMain, PartAdd);
            break;
        case TragenTaskTypeEnum.pcapsample:
            model = new TragenFormPcapModel(Params, PartMain, PartAdd);
            break;

        default:
            break;
    }

    return model;
}

function TragenFormUdpModel(Params, PartMain, PartAdd)
{
    this.gen_type = TragenTaskTypeEnum.udp;

    if(typeof PartMain == "undefined" || PartMain === true)
    {
        this.title = Params&&Params.title?Params.title:null;
        this.time = Params&&Params.time?Params.time:null;
        this.cores = Params&&Params.cores?Params.cores:"";
        this.network_port_arr = Params&&Params.network_port_arr?Params.network_port_arr:"";
        this.socket_mem = Params&&Params.socket_mem?Params.socket_mem:"";

        this.src_ip = Params&&Params.src_ip?Params.src_ip:"";//required
        this.dst_ip = Params&&Params.dst_ip?Params.dst_ip:"";//required
        this.src_port = Params&&Params.src_port?Params.src_port:"";//required
        this.dst_port = Params&&Params.dst_port?Params.dst_port:"";//required

        this.time_enabled = Params&&Params.time_enabled?Params.time_enabled:0;
        this.cores_enabled = Params&&Params.cores_enabled?Params.cores_enabled:0;
        this.network_port_arr_enabled = Params&&Params.network_port_arr_enabled?Params.network_port_arr_enabled:0;
        this.socket_mem_enabled = Params&&Params.socket_mem_enabled?Params.socket_mem_enabled:0;
        this.src_enabled = Params&&Params.src_enabled?Params.src_enabled:0;
        this.dst_enabled = Params&&Params.dst_enabled?Params.dst_enabled:0;
    }

    if(typeof PartAdd == "undefined" || PartAdd === true)
    {
        this.payload_size = Params&&Params.payload_size?Params.payload_size:"";
        this.rand = Params&&Params.rand?Params.rand:0;
        this.payload_size_enabled = Params&&Params.payload_size_enabled?Params.payload_size_enabled:0;
    }
}

function TragenFormTcpModel(Params, PartMain, PartAdd)
{
    this.gen_type = TragenTaskTypeEnum.tcpsyn;

    if(typeof PartMain == "undefined" || PartMain === true)
    {
        this.title = Params&&Params.title?Params.title:null;
        this.time = Params&&Params.time?Params.time:null;
        this.cores = Params&&Params.cores?Params.cores:"";
        this.network_port_arr = Params&&Params.network_port_arr?Params.network_port_arr:"";
        this.socket_mem = Params&&Params.socket_mem?Params.socket_mem:"";

        this.src_ip = Params&&Params.src_ip?Params.src_ip:"";//required
        this.dst_ip = Params&&Params.dst_ip?Params.dst_ip:"";//required
        this.src_port = Params&&Params.src_port?Params.src_port:"";//required
        this.dst_port = Params&&Params.dst_port?Params.dst_port:"";//required

        this.time_enabled = Params&&Params.time_enabled?Params.time_enabled:0;
        this.cores_enabled = Params&&Params.cores_enabled?Params.cores_enabled:0;
        this.network_port_arr_enabled = Params&&Params.network_port_arr_enabled?Params.network_port_arr_enabled:0;
        this.socket_mem_enabled = Params&&Params.socket_mem_enabled?Params.socket_mem_enabled:0;
        this.src_enabled = Params&&Params.src_enabled?Params.src_enabled:0;
        this.dst_enabled = Params&&Params.dst_enabled?Params.dst_enabled:0;
    }

    if(typeof PartAdd == "undefined" || PartAdd === true)
    {
        this.mss = Params&&Params.mss?Params.mss:0;
        this.wsize = Params&&Params.wsize?Params.wsize:0;

        this.mss_enabled = Params&&Params.mss_enabled?Params.mss_enabled:0;
        this.wsize_enabled = Params&&Params.wsize_enabled?Params.wsize_enabled:0;
    }
}

function TragenFormHttpModel(Params, PartMain, PartAdd)
{
    this.gen_type = TragenTaskTypeEnum.http2;

    if(typeof PartMain == "undefined" || PartMain === true)
    {
        this.title = Params&&Params.title?Params.title:null;
        this.time = Params&&Params.time?Params.time:null;
        this.cores = Params&&Params.cores?Params.cores:"";
        this.network_port_arr = Params&&Params.network_port_arr?Params.network_port_arr:"";
        this.socket_mem = Params&&Params.socket_mem?Params.socket_mem:"";

        this.src_ip = Params&&Params.src_ip?Params.src_ip:"";//required
        this.dst_ip = Params&&Params.dst_ip?Params.dst_ip:"";//required
        this.src_port = Params&&Params.src_port?Params.src_port:"";//required
        this.dst_port = Params&&Params.dst_port?Params.dst_port:"";//required

        this.time_enabled = Params&&Params.time_enabled?Params.time_enabled:0;
        this.cores_enabled = Params&&Params.cores_enabled?Params.cores_enabled:0;
        this.network_port_arr_enabled = Params&&Params.network_port_arr_enabled?Params.network_port_arr_enabled:0;
        this.socket_mem_enabled = Params&&Params.socket_mem_enabled?Params.socket_mem_enabled:0;
        this.src_enabled = Params&&Params.src_enabled?Params.src_enabled:0;
        this.dst_enabled = Params&&Params.dst_enabled?Params.dst_enabled:0;
    }

    if(typeof PartAdd == "undefined" || PartAdd === true)
    {
        this.url = Params&&Params.url?Params.url:"";

        this.mss = Params&&Params.mss?Params.mss:0;
        this.wsize = Params&&Params.wsize?Params.wsize:0;
        this.src_mac = Params&&Params.src_mac?Params.src_mac:"";
        this.dst_mac = Params&&Params.dst_mac?Params.dst_mac:"";

        this.url_enabled = Params&&Params.url_enabled?Params.url_enabled:0;
        this.mss_enabled = Params&&Params.mss_enabled?Params.mss_enabled:0;
        this.wsize_enabled = Params&&Params.wsize_enabled?Params.wsize_enabled:0;
        this.src_mac_enabled = Params&&Params.src_mac_enabled?Params.src_mac_enabled:0;
        this.dst_mac_enabled = Params&&Params.dst_mac_enabled?Params.dst_mac_enabled:0;
    }
}

function TragenFormPcapModel(Params, PartMain, PartAdd)
{
    this.gen_type = TragenTaskTypeEnum.pcapsample;

    if(typeof PartMain == "undefined" || PartMain === true)
    {
        this.title = Params&&Params.title?Params.title:null;
        this.time = Params&&Params.time?Params.time:null;
        this.cores = Params&&Params.cores?Params.cores:"";
        this.network_port_arr = Params&&Params.network_port_arr?Params.network_port_arr:"";
        this.socket_mem = Params&&Params.socket_mem?Params.socket_mem:"";

        this.src_ip = Params&&Params.src_ip?Params.src_ip:"";//required
        this.dst_ip = Params&&Params.dst_ip?Params.dst_ip:"";//required
        this.src_port = Params&&Params.src_port?Params.src_port:"";//required
        this.dst_port = Params&&Params.dst_port?Params.dst_port:"";//required

        this.time_enabled = Params&&Params.time_enabled?Params.time_enabled:0;
        this.cores_enabled = Params&&Params.cores_enabled?Params.cores_enabled:0;
        this.network_port_arr_enabled = Params&&Params.network_port_arr_enabled?Params.network_port_arr_enabled:0;
        this.socket_mem_enabled = Params&&Params.socket_mem_enabled?Params.socket_mem_enabled:0;
        this.src_enabled = Params&&Params.src_enabled?Params.src_enabled:0;
        this.dst_enabled = Params&&Params.dst_enabled?Params.dst_enabled:0;
    }

    if(typeof PartAdd == "undefined" || PartAdd === true)
    {
        this.pcap_file_name = Params&&Params.pcap_file_name?Params.pcap_file_name:LangHelper.GetString("TrafficManagementGenerator","No file");
    }
}

function TragenProfilesFilterModel(Params)
{
    this.paging = Params&&Params.id?Params.paging:{size: 100, group: 5, count:0, page:0};
    this.total = Params&&Params.id?Params.total:{size: 100, group: 5, count:0, page:0};
    this.filter = Params&&Params.id?Params.filter:{};
    this.sort = Params&&Params.id?Params.filter:{order_by:"tt_id", order_dir:"desc"};
};

function TragenProfileStatDataLogModel(Params)
{
    this.task = Params&&Params.task?Params.task:null;
    this.content = Params&&Params.content?Params.content:"";
};

function TragenProfileLogsDataModel(Params)
{
    var __this = this;

    this.task = Params&&Params.task?Params.task:null;

    this.logs = [];
    if(Params&&Params.logs&&Params.logs.length)
    {
        Params.logs.forEach(function (Log)
        {
            if(Log.success && Log.data)
                __this.logs.push(new TragenProfileStatDataLogModel(Log.data));
        });
    }
};

function TragenProfileStatDataStatModel(Params)
{
    this.stat_id = Params&&Params.stat_id?Params.stat_id:0;
    this.task_id = Params&&Params.task_id?Params.task_id:0;
    this.date = Params&&Params.date?Params.date:null;

    this.type = Params&&Params.type?Params.type:null;

    this.pkts = Params&&Params.pkts?Params.pkts:0;
    this.bytes = Params&&Params.bytes?Params.bytes:0;
    this.error = Params&&Params.error?Params.error:0;
    this.speed = Params&&Params.speed?Params.speed:0;
    this.max_speed = Params&&Params.max_speed?Params.max_speed:0;
};

function TragenProfileStatDataStatArrModel(Params)
{
    var __this = this;

    if(Params)
    {
        Object.keys(Params).forEach(function (Key)
        {
            var ports = Params[Key];

            __this[Key] = {};

            Object.keys(ports).forEach(function (PortKey)
            {
                __this[Key][PortKey] = new TragenProfileStatDataStatModel(ports[PortKey]);
            });
        });
    }
};

function TragenProfileStatsDataModel(Params)
{
    var __this = this;

    this.task = Params&&Params.task?Params.task:null;

    this.stats = [];
    if(Params&&Params.stats&&Params.stats.length)
    {
        Params.stats.forEach(function (Stat)
        {
            if(Stat.success && Stat.data)
                __this.stats.push(new TragenProfileStatDataStatArrModel(Stat.data));
        });
    }
};

function TrafficGeneratorManager(Params)
{
    this.Active = true;

    this.Profiles = [];
    this.ProfilesFilter = new TragenProfilesFilterModel();

    this.ProfileLogsData = {};
    this.ProfileStatsData = {};

    this.SignalProfilesUpdated = new Rx.BehaviorSubject(0);

    this.SignalProfileUpdated = new Rx.BehaviorSubject(0);

    this.SignalProfileLogsUpdated = new Rx.BehaviorSubject(0);
    this.SignalProfileStatsUpdated = new Rx.BehaviorSubject(0);

    this.CurrentProfilesRequestId = 0;

    this.CurrentProfileLogsDataRequestId = 0;
    this.CurrentProfileStatDataRequestId = 0;
};

TrafficGeneratorManager.prototype.Destroy = function(Params)
{
    this.Active = false;
};

TrafficGeneratorManager.prototype.RefershProfiles = function(Params)
{
    var _this = this;

    var partial = Params&&Params.profiles_ids ? true : false;

    if(!partial)
        this.CurrentProfilesRequestId = webix.uid();

    if(!_this.Active)
    {
        return;
    }

    if(Params&&Params.page >= 0)
    {
        this.ProfilesFilter.total.page = parseInt(Params.page,10);
    }

    if(Params&&Params.size&&Params.size >= 0)
    {
        this.ProfilesFilter.total.size = parseInt(Params.size,10);
    }

    if(Params&&Params.filter)
    {
        this.ProfilesFilter.filter = Params.filter;
    }

    if(Params&&Params.sort)
    {
        this.ProfilesFilter.sort.order_by = Params.sort.id;
        this.ProfilesFilter.sort.order_dir = Params.sort.dir;
    }

    var partial = Params&&Params.profiles_ids ? true : false;

    var filterParams = {
        page:this.ProfilesFilter.total.page,
        size:this.ProfilesFilter.total.size,

        order_by:this.ProfilesFilter.sort.order_by,
        order_dir:this.ProfilesFilter.sort.order_dir
    };

    Object.keys(this.ProfilesFilter.filter).forEach(function (Key)
    {
        if(_this.ProfilesFilter.filter[Key])
            filterParams[Key] = _this.ProfilesFilter.filter[Key];
    });

    var _params = filterParams;
    _params.profiles_ids = partial?Params&&Params.profiles_ids:null;

    if(!partial)
    {
        _params.parent_id_is_null = true;
    }

    var params =
        {
            id:this.CurrentProfilesRequestId,
            method:"Sorm_GetTragenTasks",
            data:{
                Params: _params
            },
            callback:function (R)
            {
                if(!_this.Active)
                {
                    return;
                }

                if(!partial && _this.CurrentProfilesRequestId != R.id)
                {
                    return;
                }

                if(R.success)
                {
                    if(partial)
                    {
                        if(R.data)
                        {
                            R.data.rows.forEach(function (Profile)
                            {
                                _this.SignalProfileUpdated.next(new TragenProfileModel(Profile));
                            });
                        }
                    }
                    else
                    {
                        _this.Profiles = [];
                        _this.ProfilesFilter.total.page = 0;
                        _this.ProfilesFilter.total.count = 0;

                        if(R.data)
                        {
                            R.data.rows.forEach(function (Profile)
                            {
                                _this.Profiles.push(new TragenProfileModel(Profile));
                            });

                            _this.ProfilesFilter.total.page = parseInt(R.data.page,10);
                            _this.ProfilesFilter.total.count = parseInt(R.data.count,10);
                        }

                        _this.SignalProfilesUpdated.next(1);
                    }
                }

                if(Params && Params.callback && typeof Params.callback === "function")
                {
                    Params.callback(R);
                }
            }
        };

    if(this.Active)
    {
        App.ServerManager.CallMethod(params);
    }
};

TrafficGeneratorManager.prototype.GetProfiles = function()
{
    return this.Profiles;
};

TrafficGeneratorManager.prototype.DeleteProfile = function(Params)
{
    var _this = this;

    var profile = new TragenProfileModel(Params&&Params.profile?Params.profile:null);

    var params =
        {
            id: profile.id,
            method:"Sorm_DeleteTragenTask",
            data: {
                Task: profile
            },
            callback:function (R)
            {
                if(Params && Params.callback && typeof Params.callback === "function")
                {
                    Params.callback(R);
                }
            }
        };

    App.ServerManager.CallMethod(params);
};

TrafficGeneratorManager.prototype.SaveProfile = function(Params)
{
    var _this = this;

    var profile = new TragenProfileModel(Params&&Params.profile?Params.profile:null);
    var profileForm = new TragenFormProfileModel(Params&&Params.profile_form?Params.profile_form:null);

    profile.task_status = 0;

    var params =
        {
            id: profile.id,
            method:"Sorm_SaveTragenTask",
            data: {
                Task: profile,
                Form: profileForm
            },
            callback:function (R)
            {
                if(Params && Params.callback && typeof Params.callback === "function")
                {
                    Params.callback(R);
                }
            }
        };

    App.ServerManager.CallMethod(params);
};

TrafficGeneratorManager.prototype.RunTragenTask = function(Params)
{
    var _this = this;

    var profile = new TragenProfileModel(Params&&Params.profile?Params.profile:null);

    delete this.ProfileStatsData[profile.tt_id];

    delete this.ProfileLogsData[profile.tt_id];

    var params =
        {
            id: profile.id,
            method:"Sorm_RunTragenTask",
            data: {
                Task: profile
            },
            callback:function (R)
            {
                if(Params && Params.callback && typeof Params.callback === "function")
                {
                    Params.callback(R);
                }
            }
        };

    App.ServerManager.CallMethod(params);
};

TrafficGeneratorManager.prototype.StopTragenTask = function(Params)
{
    var _this = this;

    var profile = new TragenProfileModel(Params&&Params.profile?Params.profile:null);

    var params =
        {
            id: profile.id,
            method:"Sorm_StopTragenTask",
            data: {
                Task: profile
            },
            callback:function (R)
            {
                if(Params && Params.callback && typeof Params.callback === "function")
                {
                    Params.callback(R);
                }
            }
        };

    App.ServerManager.CallMethod(params);
};

TrafficGeneratorManager.prototype.RefreshProfileLogs = function(ProfileModel, Params)
{
    var _this = this;
    this.CurrentProfileLogsDataRequestId = webix.uid();

    if(!_this.Active)
    {
        return;
    }


    var params =
        {
            id:this.CurrentProfileLogsDataRequestId,
            method:"Sorm_GetTragenTaskLogs",
            data:{
                Task:ProfileModel
            },
            callback:function (R)
            {
                if(!_this.Active)
                {
                    return;
                }

                if(_this.CurrentProfileLogsDataRequestId != R.id)
                {
                    return;
                }

                if(R.success)
                {
                    if(R.data)
                    {
                        _this.ProfileLogsData[ProfileModel.tt_id] = new TragenProfileLogsDataModel(R.data);
                    }

                    _this.SignalProfileLogsUpdated.next(ProfileModel);
                }

                if(Params && Params.callback && typeof Params.callback === "function")
                {
                    Params.callback(R);
                }
            }
        };

    if(this.Active)
    {
        App.ServerManager.CallMethod(params);
    }
};

TrafficGeneratorManager.prototype.GetProfileLogs = function(ProfileModel)
{
    var _this = this;

    if(ProfileModel && ProfileModel.tt_id && _this.ProfileLogsData[ProfileModel.tt_id])
    {
        return _this.ProfileLogsData[ProfileModel.tt_id];
    }

    return null;
};

TrafficGeneratorManager.prototype.RefreshProfileStats = function(ProfileModel, Params)
{
    var _this = this;
    this.CurrentProfileStatDataRequestId = webix.uid();

    if(!_this.Active)
    {
        return;
    }


    var params =
        {
            id:this.CurrentProfileStatDataRequestId,
            method:"Sorm_GetTragenStats",
            data:{
                Task:ProfileModel
            },
            callback:function (R)
            {

                if(!_this.Active)
                {
                    return;
                }

                if(_this.CurrentProfileStatDataRequestId != R.id)
                {
                    return;
                }

                if(R.success)
                {
                    if(R.data)
                    {
                        _this.ProfileStatsData[ProfileModel.tt_id] = new TragenProfileStatsDataModel(R.data);
                    }

                    _this.SignalProfileStatsUpdated.next(ProfileModel);
                }

                if(Params && Params.callback && typeof Params.callback === "function")
                {
                    Params.callback(R);
                }
            }
        };

    if(this.Active)
    {
        App.ServerManager.CallMethod(params);
    }
};

TrafficGeneratorManager.prototype.GetProfileStats = function(ProfileModel)
{
    var _this = this;

    if(ProfileModel && ProfileModel.tt_id && _this.ProfileStatsData[ProfileModel.tt_id])
    {
        return _this.ProfileStatsData[ProfileModel.tt_id];
    }

    return null;
};
var ControlTaskStatusEnum = {
    off: 0,
    on: 1
};

var ControlTaskFormConditionTypeEnum = {
    ipv4: "ipv4",
    ipv6: "ipv6",
    ipv4_subnet: "ipv4_subnet",
    range: "range",
    login: "login"
};

function TrafficCaptureTaskModel(Params)
{
    var __this = this;

    this.control_id = Params&&Params.control_id?Params.control_id:0;
    this.parent_id = Params&&Params.parent_id?Params.parent_id:null;
    this.cocr_id = Params&&Params.cocr_id?Params.cocr_id:null;
    this.user_id = Params&&Params.user_id?Params.user_id:null;
    this.title = Params&&Params.title?Params.title:"";
    this.date = Params&&Params.date?Params.date:0;
    this.status = Params&&Params.status?Params.status:ControlTaskStatusEnum.off;

    this.form_data = Params&&Params.form_data?Params.form_data:"";

    this.data = [];
    if(Params&&Params.data)
    {
        Params.data.forEach(function (Task)
        {
            __this.data.push(new TrafficCaptureTaskModel(Task));
        });
    }

    this.open = true;
    this.valid = true;
};

function TrafficCaptureTaskFormModel(Params)
{
    this.title = Params&&Params.title?Params.title:"";

    this.condition_type = Params&&Params.condition_type?Params.condition_type:ControlTaskFormConditionTypeEnum.ipv4;
    this.condition = Params&&Params.condition?Params.condition:"";

    this.is_raw_data_sending = Params&&Params.is_raw_data_sending?Params.is_raw_data_sending:false;
    this.is_channel_layer_data_sending = Params&&Params.is_channel_layer_data_sending?Params.is_channel_layer_data_sending:false;
    this.is_traffic_control_disabled = Params&&Params.is_traffic_control_disabled?Params.is_traffic_control_disabled:false;
    this.is_buffer_analyzing = Params&&Params.is_buffer_analyzing?Params.is_buffer_analyzing:false;
};

function TrafficCaptureTasksFilterModel(Params)
{
    this.paging = Params&&Params.id?Params.paging:{size: 100, group: 5, count:0, page:0};
    this.total = Params&&Params.id?Params.total:{size: 100, group: 5, count:0, page:0};
    this.filter = Params&&Params.id?Params.filter:{};
    this.sort = Params&&Params.id?Params.filter:{order_by:"control_id", order_dir:"desc"};
};

function TrafficCapturePcapFileModel(Params)
{
    this.id = webix.uid();
    this.file_id = Params&&Params.file_id?Params.file_id:"";
    this.date = Params&&Params.date?Params.date:null;
    this.size = Params&&Params.size?Params.size:0;

    if(Params.atime)
    {
        this.date = new Date(Params.atime*1000);
    }

    // if(this.file_id)
    // {
    //     var fileIdArr = this.file_id.split(".");
    //     fileIdArr = fileIdArr.slice(0,5);
    //
    //     var parser = webix.Date.strToDate("%Y.%m.%d.%H.%i.%s");
    //     this.date = parser(fileIdArr.join("."));
    // }
};

function TrafficCapturePcapFileTableContentModel(Params)
{
    this.id = webix.uid();
    this.time = Params&&Params.time?Params.time:"";
    this.source = Params&&Params.source?Params.source:"";
    this.destination = Params&&Params.destination?Params.destination:"";
    this.info = Params&&Params.info?Params.info:"";
};

function TrafficCaptureManager()
{
    this.Active = true;

    this.Tasks = [];
    this.TasksFilter = new TrafficCaptureTasksFilterModel();

    this.PcapFilesList = [];

    this.SignalTasksUpdated = new Rx.BehaviorSubject(0);
    this.SignalTaskUpdated = new Rx.BehaviorSubject(0);
    this.SignalPcapFilesListUpdated = new Rx.BehaviorSubject(0);
    this.SignalStatLogTailUpdated = new Rx.BehaviorSubject(0);

    this.CurrentTasksRequestId = 0;

    this.CurrentPcapFilesRequestId = 0;
    this.CurrentPcapFileContentRequestId = 0;

    this.ConstPcapFileTail = 1000;
    this.PcapFileTailData = "";
    this.PcapFileTailTableData = "";
};

TrafficCaptureManager.prototype.Destroy = function(Params)
{
    this.Active = false;
};

TrafficCaptureManager.prototype.RefershTasks = function(Params)
{
    var _this = this;

    if(!partial)
        this.CurrentTasksRequestId = webix.uid();

    if(!_this.Active)
    {
        return;
    }

    if(Params&&Params.page >= 0)
    {
        this.TasksFilter.total.page = parseInt(Params.page,10);
    }

    if(Params&&Params.size&&Params.size >= 0)
    {
        this.TasksFilter.total.size = parseInt(Params.size,10);
    }

    if(Params&&Params.filter)
    {
        this.TasksFilter.filter = Params.filter;
    }

    if(Params&&Params.sort)
    {
        this.TasksFilter.sort.order_by = Params.sort.id;
        this.TasksFilter.sort.order_dir = Params.sort.dir;
    }

    var partial = Params&&Params.control_ids ? true : false;

    var filterParams = {
        page:this.TasksFilter.total.page,
        size:this.TasksFilter.total.size,

        order_by:this.TasksFilter.sort.order_by,
        order_dir:this.TasksFilter.sort.order_dir
    };

    Object.keys(this.TasksFilter.filter).forEach(function (Key)
    {
        if(_this.TasksFilter.filter[Key])
            filterParams[Key] = _this.TasksFilter.filter[Key];
    });

    var _params = filterParams;
    _params.control_ids = partial?Params&&Params.control_ids:null;

    if(!partial)
    {
        _params.parent_id_is_null = true;
    }

    var params =
        {
            id:this.CurrentTasksRequestId,
            method:"Sorm_GetControlTasks",
            data:{
                Params: _params
            },
            callback:function (R)
            {
                if(!_this.Active)
                {
                    return;
                }

                if(!partial && _this.CurrentTasksRequestId != R.id)
                {
                    return;
                }

                if(R.success)
                {
                    if(partial)
                    {
                        if(R.data)
                        {
                            R.data.rows.forEach(function (Task)
                            {
                                _this.SignalTaskUpdated.next(new TrafficCaptureTaskModel(Task));
                            });
                        }
                    }
                    else
                    {
                        _this.Tasks = [];
                        _this.TasksFilter.total.page = 0;
                        _this.TasksFilter.total.count = 0;

                        if(R.data)
                        {
                            R.data.rows.forEach(function (Task)
                            {
                                _this.Tasks.push(new TrafficCaptureTaskModel(Task));
                            });

                            _this.TasksFilter.total.page = parseInt(R.data.page,10);
                            _this.TasksFilter.total.count = parseInt(R.data.count,10);
                        }

                        _this.SignalTasksUpdated.next(1);
                    }
                }

                if(Params && Params.callback && typeof Params.callback === "function")
                {
                    Params.callback(R);
                }
            }
        };

    if(this.Active)
    {
        App.ServerManager.CallMethod(params);
    }
};

TrafficCaptureManager.prototype.GetTasks = function()
{
    return this.Tasks;
};

TrafficCaptureManager.prototype.SaveTask = function(Params)
{
    var _this = this;

    var task = new TrafficCaptureTaskModel(Params&&Params.task?Params.task:null);
    var taskForm = new TrafficCaptureTaskFormModel(Params&&Params.task?Params.task_form:null);

    var params =
        {
            id: task.id,
            method:"Sorm_SaveControlTask",
            data: {
                Task: task,
                Form: taskForm
            },
            callback:function (R)
            {
                if(Params && Params.callback && typeof Params.callback === "function")
                {
                    Params.callback(R);
                }
            }
        };

    App.ServerManager.CallMethod(params);
};

TrafficCaptureManager.prototype.DeleteTask = function(Params)
{
    var _this = this;

    var task = new TrafficCaptureTaskModel(Params&&Params.task?Params.task:null);

    var params =
        {
            id: task.id,
            method:"Sorm_DeleteControlTask",
            data: {
                Task: task
            },
            callback:function (R)
            {
                if(Params && Params.callback && typeof Params.callback === "function")
                {
                    Params.callback(R);
                }
            }
        };

    App.ServerManager.CallMethod(params);
};

TrafficCaptureManager.prototype.EnableTask = function(Params)
{
    var _this = this;

    var task = new TrafficCaptureTaskModel(Params&&Params.task?Params.task:null);

    var params =
        {
            id: task.id,
            method:"Sorm_EnableControlTask",
            data: {
                Task: task
            },
            callback:function (R)
            {
                if(Params && Params.callback && typeof Params.callback === "function")
                {
                    Params.callback(R);
                }
            }
        };

    App.ServerManager.CallMethod(params);
};

TrafficCaptureManager.prototype.DisableTask = function(Params)
{
    var _this = this;

    var task = new TrafficCaptureTaskModel(Params&&Params.task?Params.task:null);

    var params =
        {
            id: task.id,
            method:"Sorm_DisableControlTask",
            data: {
                Task: task
            },
            callback:function (R)
            {
                if(Params && Params.callback && typeof Params.callback === "function")
                {
                    Params.callback(R);
                }
            }
        };

    App.ServerManager.CallMethod(params);
};

TrafficCaptureManager.prototype.RefreshPcapFilesList = function(Params)
{
    var _this = this;

    if(!_this.Active)
    {
        return;
    }

    this.CurrentPcapFilesRequestId = webix.uid();

    var params =
        {
            id:this.CurrentPcapFilesRequestId,
            method:"Sorm_GetControlPcapFilesList",
            callback:function (R)
            {
                if(!_this.Active)
                {
                    return;
                }

                if(_this.CurrentPcapFilesRequestId != R.id)
                {
                    return;
                }

                if(R.success)
                {
                    var data = [];

                    if(R.data)
                    {
                        Object.keys(R.data).forEach(function (Key)
                        {
                            var obj = new TrafficCapturePcapFileModel(R.data[Key]);

                            data.push(obj);
                        });
                    }

                    _this.PcapFilesList = data;
                    _this.SignalPcapFilesListUpdated.next(1);
                }

                if(Params && Params.callback && typeof Params.callback === "function")
                {
                    Params.callback(R);
                }
                else
                {
                    if(!R.success)
                    {
                        webix.message({type:"error", text:R.error.msg});
                    }
                }
            }
        }

    if(this.Active)
    {
        App.ServerManager.CallMethod(params);
    }
};

TrafficCaptureManager.prototype.GetPcapFilesList = function()
{
    return this.PcapFilesList;
};

TrafficCaptureManager.prototype.RefreshPcapFileTail = function (Params)
{
    var _this = this;

    if(!_this.Active)
    {
        return;
    }

    this.CurrentPcapFileContentRequestId = webix.uid();

    var params =
        {
            id:this.CurrentPcapFileContentRequestId,
            method:"Sorm_GetControlPcapFileTail",
            data:{FileName: Params&&Params.file_id?Params.file_id:null, Tail:Params&&Params.tail?Params.tail:_this.ConstPcapFileTail},
            callback:function (R)
            {
                if(!_this.Active)
                {
                    return;
                }

                if(_this.CurrentPcapFileContentRequestId != R.id)
                {
                    return;
                }

                if(R.success)
                {
                    _this.PcapFileTailData = "";

                    if(R.data)
                    {
                        _this.PcapFileTailData = R.data;
                    }

                    _this.SignalStatLogTailUpdated.next(1);

                }

                if(Params && Params.callback && typeof Params.callback === "function")
                {
                    Params.callback(R);
                }
                else
                {
                    if(!R.success)
                    {
                        webix.message({type:"error", text:R.error.msg});
                    }
                }
            }
        };

    if(this.Active)
    {
        App.ServerManager.CallMethod(params);
    }
};

TrafficCaptureManager.prototype.GetPcapFileTail = function()
{
    return this.PcapFileTailData;
};

TrafficCaptureManager.prototype.ParsePcapFileTail = function()
{
    var _this = this;

    var lines = this.PcapFileTailData.split("\n");

    this.PcapFileTailTableData = [];

    lines.forEach(function (Line)
    {
        try
        {
            var infoSplit = Line.split(": ");
            var info = infoSplit[1];
            var cols = infoSplit[0].split(" ");

            var obj =
                {
                    time: cols[0],
                    source: cols[2],
                    destination: cols[4],
                    info: info
                };

            _this.PcapFileTailTableData.push(new TrafficCapturePcapFileTableContentModel(obj));
        }
        catch(e)
        {}

    });
};

TrafficCaptureManager.prototype.GetPcapFileTableTail = function()
{
    this.ParsePcapFileTail();
    return this.PcapFileTailTableData;
};

TrafficCaptureManager.prototype.DownloadPcapFile = function (FileName)
{
    var _this = this;

    if(!_this.Active)
    {
        return;
    }

    var params =
        {
            method:"Sorm_DownloadControlPcapFile",
            data:{FileName:FileName},
            callback:function (R)
            {
                if(!_this.Active)
                {
                    return;
                }

                if(R.success)
                {
                    App.ServerManager.RedirectToFileDownload(R.data);
                }
                else
                {
                    webix.message({type:"error", text:R.error.msg});
                }
            }
        };

    if(this.Active)
    {
        App.ServerManager.CallMethod(params);
    }
};

TrafficCaptureManager.prototype.DeleteFile = function(Params)
{
    var params =
        {
            method:"Sorm_DeleteControlPcapFile",
            data: {
                FileName: Params.file_id
            },
            callback:function (R)
            {
                if(Params && Params.callback && typeof Params.callback === "function")
                {
                    Params.callback(R);
                }
            }
        };

    App.ServerManager.CallMethod(params);
};

TrafficCaptureManager.prototype.DecodePcapFiles = function(Params)
{
    var _this = this;

    var task = new TrafficDecodeTaskModel(Params&&Params.task?Params.task:null);
    var files = Params && Params.files ? Params.files : [];
    var decode_types = Params && Params.decode_types ? Params.decode_types : null;

    var params =
        {
            id: task.id,
            method:"Sorm_DecodePcapFilesFromTrafficCapture",
            data: {
                Files: files,
                Task: task,
                DecodeTypes:decode_types
            },
            callback:function (R)
            {
                if(Params && Params.callback && typeof Params.callback === "function")
                {
                    Params.callback(R);
                }
            }
        };

    App.ServerManager.CallMethod(params);
};

var WgetTaskStatusEnum = {
    new: 0,
    progress: 1,
    stoped: 2,
    error: 3
};

var WgetTaskStatusColorsEnum = {};
WgetTaskStatusColorsEnum[WgetTaskStatusEnum.new] = "0";
WgetTaskStatusColorsEnum[WgetTaskStatusEnum.progress] = "green";
WgetTaskStatusColorsEnum[WgetTaskStatusEnum.stoped] = "orange";
WgetTaskStatusColorsEnum[WgetTaskStatusEnum.error] = "red";


var WgetTaskVhostStatusEnum = {
    disabled: 0,
    enabled: 1
};

var WgetTaskDataStatusEnum = {
    new: 0,
    progress: 1,
    saved: 2,
    not_saved: 3,
    error: 4
};

var WgetTaskDataStatusColorsEnum = {
    0: "orange",
    1: "orange",
    2: "green",
    3: "orange",
    4: "red"
};

var WgetTaskDataSync = {
    not_started:0,
    in_progress:1,
    completed:2
};

function WgetTaskModel(Params)
{
    var __this = this;

    this.wget_id = Params&&Params.wget_id?Params.wget_id:0;
    this.parent_id = Params&&Params.parent_id?Params.parent_id:null;
    this.user_id = Params&&Params.user_id?Params.user_id:null;
    this.title = Params&&Params.title?Params.title:"";
    this.date = Params&&Params.date?Params.date:0;
    this.url = Params&&Params.url?Params.url:"";
    this.status = Params&&Params.status?Params.status:WgetTaskStatusEnum.new;
    this.vhost = Params&&Params.vhost?Params.vhost:WgetTaskVhostStatusEnum.disabled;
    this.data_count = Params&&Params.data_count?Params.data_count:0;
    this.pid = Params&&Params.pid?Params.pid:0;

    this.form_data = Params&&Params.form_data?Params.form_data:"";

    this.data = [];
    if(Params&&Params.data)
    {
        Params.data.forEach(function (Task)
        {
            __this.data.push(new WgetTaskModel(Task));
        });
    }

    this.download = Params&&Params.download?Params.download:0;

    this.open = true;
    this.valid = true;
};

function WgetTaskDataModel(Params)
{
    this.data_id = Params&&Params.data_id?Params.data_id:null;
    this.wget_id = Params&&Params.wget_id?Params.wget_id:null;
    this.date = Params&&Params.date?Params.date:null;
    this.local_path = Params&&Params.local_path?Params.local_path:"";
    this.url = Params&&Params.url?Params.url:"";
    this.bytes = Params&&Params.bytes?Params.bytes:0;
    this.status = Params&&Params.status?Params.status:WgetTaskStatusEnum.new;
};

function WgetTasksFilterModel(Params)
{
    this.paging = Params&&Params.id?Params.paging:{size: 100, group: 5, count:0, page:0};
    this.total = Params&&Params.id?Params.total:{size: 100, group: 5, count:0, page:0};
    this.filter = Params&&Params.id?Params.filter:{};
    this.sort = Params&&Params.id?Params.filter:{order_by:"wget_id", order_dir:"desc"};
};

function WgetTaskDataModel(Params)
{
    this.id = Params&&Params.id?Params.id:webix.uid();
    this.wget_id = Params&&Params.wget_id?Params.wget_id:0;
    this.sync = Params&&Params.sync?Params.sync:WgetTaskDataSync.not_started;
    this.data = Params&&Params.data?Params.data:[];
};

function WgetTaskDataStatModel(Params)
{
    this.id = Params&&Params.id?Params.id:webix.uid();

    this.line_num = Params&&Params.line_num?Params.line_num:null;
    this.wget_id = Params&&Params.wget_id?Params.wget_id:null;
    this.date = Params&&Params.date?Params.date:null;
    this.local_path = Params&&Params.local_path?Params.local_path:"";
    this.url = Params&&Params.url?Params.url:"";
    this.bytes_loaded = Params&&Params.bytes_loaded?Params.bytes_loaded:0;
    this.bytes_total = Params&&Params.bytes_total?Params.bytes_total:0;
    this.status = Params&&Params.status?Params.status:WgetTaskStatusEnum.new;
    this.http_response = Params&&Params.http_response?Params.http_response:"";
};

function WgetTaskFormModel(Params, PartMain, PartAdd)
{
    if(typeof PartMain == "undefined" || PartMain === true)
    {
        this.title = Params&&Params.title?Params.title:"";
        this.url = Params&&Params.url?Params.url:"";

        this.v_host = Params&&Params.v_host?Params.v_host:"";
        this.v_port = Params&&Params.v_port?Params.v_port:"";
    }

    if(typeof PartAdd == "undefined" || PartAdd === true)
    {
        this.cmd = Params&&Params.cmd ? new WgetTaskFormCmdModel(Params.cmd) : null;
    }
};

function WgetSpoofFormModel(Params)
{
    this.template = Params&&Params.template?Params.template:"";
    this.file_name = Params&&Params.file_name?Params.file_name:"";
};

function WgetTaskFormCmdRecursiveModel(Params)
{
    this.recursive = Params&&Params.recursive?Params.recursive:0;

    this.level_enabled = Params&&Params.level_enabled?Params.level_enabled:0;
    this.level = Params&&Params.level?Params.level:0;

    this.page_requisites = Params&&Params.page_requisites?Params.page_requisites:0;
    this.convert_links = Params&&Params.convert_links?Params.convert_links:0;
    this.backup_converted = Params&&Params.backup_converted?Params.backup_converted:0;
    this.mirror = Params&&Params.mirror?Params.mirror:0;

    this.no_parent = Params&&Params.no_parent?Params.no_parent:0;

};

function WgetTaskFormCmdInputModel(Params)
{
    this.continue = Params&&Params.continue?Params.continue:0;
    this.force_html = Params&&Params.force_html?Params.force_html:0;

    this.base_enabled = Params&&Params.base_enabled?Params.base_enabled:0;
    this.base = Params&&Params.base?Params.base:"";

    this.add_params = Params&&Params.add_params?Params.add_params:"";

};

function WgetTaskFormCmdDownloadModel(Params)
{
    this.wait_enabled = Params&&Params.wait_enabled?Params.wait_enabled:0;
    this.wait = Params&&Params.wait?Params.wait:"";

    this.random_wait = Params&&Params.random_wait?Params.random_wait:0;

    this.limit_rate_enabled = Params&&Params.limit_rate_enabled?Params.limit_rate_enabled:0;
    this.limit_rate = Params&&Params.limit_rate?Params.limit_rate:"";

    this.server_response = Params&&Params.server_response?Params.server_response:0;

    this.no_clobber = Params&&Params.no_clobber?Params.no_clobber:0;

    this.tries_enabled = Params&&Params.tries_enabled?Params.tries_enabled:0;
    this.tries = Params&&Params.tries?Params.tries:"";

    this.retry_connrefused = Params&&Params.retry_connrefused?Params.retry_connrefused:0;

    this.timeout_enabled = Params&&Params.timeout_enabled?Params.timeout_enabled:0;
    this.timeout = Params&&Params.timeout?Params.timeout:"";

    this.timestamping = Params&&Params.timestamping?Params.timestamping:0;
};

function WgetTaskFormCmdDirectoriesModel(Params)
{
    this.no_directories = Params && Params.no_directories ? Params.no_directories : 0;

    this.force_directories = Params && Params.force_directories ? Params.force_directories : 0;

    this.no_host_directories = Params && Params.no_host_directories ? Params.no_host_directories : 0;

    this.cut_dirs_enabled = Params && Params.cut_dirs_enabled ? Params.cut_dirs_enabled : 0;
    this.cut_dirs = Params && Params.cut_dirs ? Params.cut_dirs : "";
};

function WgetTaskFormCmdHttpModel(Params)
{
    this.http_user_enabled = Params && Params.http_user_enabled ? Params.http_user_enabled : 0;
    this.http_user = Params && Params.http_user ? Params.http_user : "";

    this.http_password_enabled = Params && Params.http_password_enabled ? Params.http_password_enabled : 0;
    this.http_password = Params && Params.http_password ? Params.http_password : "";

    this.proxy_user_enabled = Params && Params.proxy_user_enabled ? Params.proxy_user_enabled : 0;
    this.proxy_user = Params && Params.proxy_user ? Params.proxy_user : "";

    this.proxy_password_enabled = Params && Params.proxy_password_enabled ? Params.proxy_password_enabled : 0;
    this.proxy_password = Params && Params.proxy_password ? Params.proxy_password : "";

    this.post_data_enabled = Params && Params.post_data_enabled ? Params.post_data_enabled : 0;
    this.post_data = Params && Params.post_data ? Params.post_data : "";

    this.no_cookies = Params && Params.no_cookies ? Params.no_cookies : 0;

    this.no_http_keep_alive = Params && Params.no_http_keep_alive ? Params.no_http_keep_alive : 0;

    this.no_cache = Params && Params.no_cache ? Params.no_cache : 0;

    this.referer_enabled = Params && Params.referer_enabled ? Params.referer_enabled : 0;
    this.referer = Params && Params.referer ? Params.referer : "";

    this.user_agent_enabled = Params && Params.user_agent_enabled ? Params.user_agent_enabled : 0;
    this.user_agent = Params && Params.user_agent ? Params.user_agent : "";
};

function WgetTaskFormCmdFtpModel(Params)
{
    this.no_remove_listing = Params && Params.no_remove_listing ? Params.no_remove_listing : 0;

    this.retr_symlinks = Params && Params.retr_symlinks ? Params.retr_symlinks : 0;

    this.no_glob = Params && Params.no_glob ? Params.no_glob : 0;

    this.no_passive_ftp = Params && Params.no_passive_ftp ? Params.no_passive_ftp : 0;
};

function WgetTaskFormCmdModel(Params)
{
    var _this = this;

    var model = new WgetTaskFormCmdRecursiveModel(Params);
    Object.keys(model).forEach(function (Key){_this[Key] = model[Key];});

    var model = new WgetTaskFormCmdInputModel(Params);
    Object.keys(model).forEach(function (Key){_this[Key] = model[Key];});

    var model = new WgetTaskFormCmdDownloadModel(Params);
    Object.keys(model).forEach(function (Key){_this[Key] = model[Key];});

    var model = new WgetTaskFormCmdDirectoriesModel(Params);
    Object.keys(model).forEach(function (Key){_this[Key] = model[Key];});

    var model = new WgetTaskFormCmdHttpModel(Params);
    Object.keys(model).forEach(function (Key){_this[Key] = model[Key];});

    var model = new WgetTaskFormCmdFtpModel(Params);
    Object.keys(model).forEach(function (Key){_this[Key] = model[Key];});
};

function WgetTasksFilterModel(Params)
{
    this.paging = Params&&Params.id?Params.paging:{size: 100, group: 5, count:0, page:0};
    this.total = Params&&Params.id?Params.total:{size: 100, group: 5, count:0, page:0};
    this.filter = Params&&Params.id?Params.filter:{};
    this.sort = Params&&Params.id?Params.filter:{order_by:"wget_id", order_dir:"desc"};
};

function WgetTaskDataFilterModel(Params)
{
    this.paging = Params&&Params.id?Params.paging:{size: 100, group: 5, count:0, page:0};
    this.total = Params&&Params.id?Params.total:{size: 100, group: 5, count:0, page:0};
    this.filter = Params&&Params.id?Params.filter:{};
    this.sort = Params&&Params.id?Params.filter:{order_by:"log_file_line_num", order_dir:"desc"};
};

function WgetTaskLogModel(Params)
{
    this.task = Params&&Params.task?Params.task:null;
    this.file = Params&&Params.file?Params.file:null;
    this.content = Params&&Params.content?Params.content:"";
};

function WgetManager ()
{
    this.Active = true;

    this.Tasks = [];
    this.TasksFilter = new WgetTasksFilterModel();
    
    this.TaskLogsData = {};
    this.TaskData = {};
    this.TaskSpoofingData = {};

    this.TaskDataFilter = {};

    this.SignalTasksUpdated = new Rx.BehaviorSubject(0);
    this.SignalTaskUpdated = new Rx.BehaviorSubject(0);

    this.SignalTaskLogsUpdated = new Rx.BehaviorSubject(0);
    this.SignalTaskDataUpdated = new Rx.BehaviorSubject(0);
    this.SignalTaskSpoofingDataUpdated = new Rx.BehaviorSubject(0);

    this.CurrentTasksRequestId = 0;

    this.CurrentTaskLogsDataRequestId = 0;
    this.CurrentTaskStatDataRequestId = 0;
    this.CurrentTaskSpoofingDataRequestId = 0;
};

WgetManager.prototype.Destroy = function(Params)
{
    this.Active = false;
};

WgetManager.prototype.RefershTasks = function(Params)
{
    var _this = this;

    if(!partial)
        this.CurrentTasksRequestId = webix.uid();

    if(!_this.Active)
    {
        return;
    }

    if(Params&&Params.page >= 0)
    {
        this.TasksFilter.total.page = parseInt(Params.page,10);
    }

    if(Params&&Params.size&&Params.size >= 0)
    {
        this.TasksFilter.total.size = parseInt(Params.size,10);
    }

    if(Params&&Params.filter)
    {
        this.TasksFilter.filter = Params.filter;
    }

    if(Params&&Params.sort)
    {
        this.TasksFilter.sort.order_by = Params.sort.id;
        this.TasksFilter.sort.order_dir = Params.sort.dir;
    }

    var partial = Params&&Params.wgets_ids ? true : false;

    var filterParams = {
        page:this.TasksFilter.total.page,
        size:this.TasksFilter.total.size,

        order_by:this.TasksFilter.sort.order_by,
        order_dir:this.TasksFilter.sort.order_dir
    };

    Object.keys(this.TasksFilter.filter).forEach(function (Key)
    {
        if(_this.TasksFilter.filter[Key])
            filterParams[Key] = _this.TasksFilter.filter[Key];
    });

    var _params = filterParams;
    _params.wgets_ids = partial?Params&&Params.wgets_ids:null;

    if(!partial)
    {
        _params.parent_id_is_null = true;
    }

    var params =
        {
            id:this.CurrentTasksRequestId,
            method:"Sorm_GetWgetTasks",
            data:{
                Params: _params
            },
            callback:function (R)
            {
                if(!_this.Active)
                {
                    return;
                }

                if(!partial && _this.CurrentTasksRequestId != R.id)
                {
                    return;
                }

                if(R.success)
                {
                    if(partial)
                    {
                        if(R.data)
                        {
                            R.data.rows.forEach(function (Task)
                            {
                                _this.SignalTaskUpdated.next(new WgetTaskModel(Task));
                            });
                        }
                    }
                    else
                    {
                        _this.Tasks = [];
                        _this.TasksFilter.total.page = 0;
                        _this.TasksFilter.total.count = 0;

                        if(R.data)
                        {
                            R.data.rows.forEach(function (Task)
                            {
                                _this.Tasks.push(new WgetTaskModel(Task));
                            });

                            _this.TasksFilter.total.page = parseInt(R.data.page,10);
                            _this.TasksFilter.total.count = parseInt(R.data.count,10);
                        }

                        _this.SignalTasksUpdated.next(1);
                    }
                }

                if(Params && Params.callback && typeof Params.callback === "function")
                {
                    Params.callback(R);
                }
            }
        };

    if(this.Active)
    {
        App.ServerManager.CallMethod(params);
    }
};

WgetManager.prototype.GetTasks = function()
{
    return this.Tasks;
};

WgetManager.prototype.SaveTask = function(Params)
{
    var _this = this;

    var task = new WgetTaskModel(Params&&Params.task?Params.task:null);
    var taskForm = new WgetTaskFormModel(Params&&Params.task?Params.task_form:null);

    var params =
        {
            id: task.id,
            method:"Sorm_SaveWgetTask",
            data: {
                Task: task,
                Form: taskForm
            },
            callback:function (R)
            {
                if(Params && Params.callback && typeof Params.callback === "function")
                {
                    Params.callback(R);
                }
            }
        };

    App.ServerManager.CallMethod(params);
};

WgetManager.prototype.DeleteTask = function(Params)
{
    var _this = this;

    var task = new WgetTaskModel(Params&&Params.task?Params.task:null);

    var params =
        {
            id: task.id,
            method:"Sorm_DeleteWgetTask",
            data: {
                Task: task
            },
            callback:function (R)
            {
                if(Params && Params.callback && typeof Params.callback === "function")
                {
                    Params.callback(R);
                }
            }
        };

    App.ServerManager.CallMethod(params);
};

WgetManager.prototype.RunTask = function(Params)
{
    var _this = this;

    var task = new WgetTaskModel(Params&&Params.task?Params.task:null);

    var params =
        {
            id: task.id,
            method:"Sorm_RunWgetTask",
            data: {
                Task: task
            },
            callback:function (R)
            {
                if(Params && Params.callback && typeof Params.callback === "function")
                {
                    Params.callback(R);
                }
            }
        };

    App.ServerManager.CallMethod(params);
};

WgetManager.prototype.StopTask = function(Params)
{
    var _this = this;

    var task = new WgetTaskModel(Params&&Params.task?Params.task:null);

    var params =
        {
            id: task.id,
            method:"Sorm_StopWgetTask",
            data: {
                Task: task
            },
            callback:function (R)
            {
                if(Params && Params.callback && typeof Params.callback === "function")
                {
                    Params.callback(R);
                }
            }
        };

    App.ServerManager.CallMethod(params);
};

WgetManager.prototype.RefreshTaskLogs = function(TaskModel, Params)
{
    var _this = this;

    this.CurrentTaskLogsDataRequestId = webix.uid();

    if(!_this.Active)
    {
        return;
    }

    var params =
        {
            id:this.CurrentTaskLogsDataRequestId,
            method:"Sorm_GetWgetTaskLogs",
            data:{
                Task:TaskModel
            },
            callback:function (R)
            {
                if(!_this.Active)
                {
                    return;
                }

                if(_this.CurrentTaskLogsDataRequestId != R.id)
                {
                    return;
                }

                if(R.success)
                {
                    if(R.data)
                    {
                        _this.TaskLogsData[TaskModel.wget_id] = new WgetTaskLogModel(R.data);
                    }
                }
                else
                {
                    _this.TaskLogsData[TaskModel.wget_id] = new WgetTaskLogModel();
                }

                _this.SignalTaskLogsUpdated.next(TaskModel);

                if(Params && Params.callback && typeof Params.callback === "function")
                {
                    Params.callback(R);
                }
            }
        };

    if(this.Active)
    {
        App.ServerManager.CallMethod(params);
    }
};

WgetManager.prototype.GetTaskLogs = function(TaskModel)
{
    var _this = this;

    if(TaskModel && TaskModel.wget_id && _this.TaskLogsData[TaskModel.wget_id])
    {
        return _this.TaskLogsData[TaskModel.wget_id];
    }

    return null;
};

WgetManager.prototype.RefreshTaskData = function(TaskModel, Params)
{
    var _this = this;

    this.CurrentTaskStatDataRequestId = webix.uid();

    if(!_this.Active)
    {
        return;
    }

    if(!this.TaskDataFilter[TaskModel.wget_id])
    {
        this.TaskDataFilter[TaskModel.wget_id] = new WgetTaskDataFilterModel();
    }

    if(Params&&Params.page >= 0)
    {
        this.TaskDataFilter[TaskModel.wget_id].total.page = parseInt(Params.page,10);
    }

    if(Params&&Params.size&&Params.size >= 0)
    {
        this.TaskDataFilter[TaskModel.wget_id].total.size = parseInt(Params.size,10);
    }

    if(Params&&Params.filter)
    {
        this.TaskDataFilter[TaskModel.wget_id].filter = Params.filter;
    }

    if(Params&&Params.sort)
    {
        this.TaskDataFilter[TaskModel.wget_id].sort.order_by = Params.sort.id;
        this.TaskDataFilter[TaskModel.wget_id].sort.order_dir = Params.sort.dir;
    }

    var filterParams = {
        page:this.TaskDataFilter[TaskModel.wget_id].total.page,
        size:this.TaskDataFilter[TaskModel.wget_id].total.size,

        order_by:this.TaskDataFilter[TaskModel.wget_id].sort.order_by,
        order_dir:this.TaskDataFilter[TaskModel.wget_id].sort.order_dir
    };

    Object.keys(this.TaskDataFilter[TaskModel.wget_id].filter).forEach(function (Key)
    {
        if(_this.TaskDataFilter[TaskModel.wget_id].filter[Key])
            filterParams[Key] = _this.TaskDataFilter[TaskModel.wget_id].filter[Key];
    });

    var params =
        {
            id:this.CurrentTaskStatDataRequestId,
            method:"Sorm_GetWgetTaskData",
            data:{
                Task:TaskModel,
                Params:filterParams
            },
            callback:function (R)
            {
                if(!_this.Active)
                {
                    return;
                }

                if(_this.CurrentTaskStatDataRequestId != R.id)
                {
                    return;
                }

                if(R.success)
                {
                    _this.TaskDataFilter[TaskModel.wget_id].total.page = 0;
                    _this.TaskDataFilter[TaskModel.wget_id].total.count = 0;

                    _this.TaskData[TaskModel.wget_id] = new WgetTaskDataModel({task_id:TaskModel.wget_id, sync:R.data?R.data.sync:WgetTaskDataSync.not_started});

                    if(R.data)
                    {
                        R.data.rows.forEach(function (Data)
                        {
                            _this.TaskData[TaskModel.wget_id].data.push(new WgetTaskDataStatModel(Data));
                        });

                        _this.TaskDataFilter[TaskModel.wget_id].total.page = parseInt(R.data.page,10);
                        _this.TaskDataFilter[TaskModel.wget_id].total.count = parseInt(R.data.count,10);
                    }
                }
                else
                {
                    _this.TaskData[TaskModel.wget_id] = new WgetTaskDataModel();
                }

                _this.SignalTaskDataUpdated.next(TaskModel.wget_id);

                if(Params && Params.callback && typeof Params.callback === "function")
                {
                    Params.callback(R);
                }
            }
        };

    if(this.Active)
    {
        App.ServerManager.CallMethod(params);
    }
};

WgetManager.prototype.GetTaskData = function(TaskId)
{
    return this.TaskData[TaskId];
};

WgetManager.prototype.DownloadLog = function (TaskModel)
{
    var _this = this;

    if(!_this.Active)
    {
        return;
    }

    var params =
        {
            method:"Sorm_DownloadWgetTaskDataLog",
            data:{Task:TaskModel},
            callback:function (R)
            {
                if(!_this.Active)
                {
                    return;
                }

                if(R.success)
                {
                    App.ServerManager.RedirectToFileDownload(R.data);
                }
                else
                {
                    webix.message({type:"error", text:R.error.msg});
                }
            }
        };

    if(this.Active)
    {
        App.ServerManager.CallMethod(params);
    }
};

WgetManager.prototype.DownloadSiteZip = function (Params)
{
    var _this = this;

    if(!_this.Active)
    {
        return;
    }

    var params =
        {
            method:"Sorm_DownloadWgetSiteZip",
            data:{Task:Params.task},
            callback:function (R)
            {
                if(!_this.Active)
                {
                    return;
                }

                if(R.success)
                {
                    App.ServerManager.RedirectToFileDownload(R.data);
                }
                else
                {
                    webix.message({type:"error", text:R.error.msg});
                }

                if(Params && Params.callback && typeof Params.callback === "function")
                {
                    Params.callback(R);
                }
            }
        };

    if(this.Active)
    {
        App.ServerManager.CallMethod(params);
    }
};

WgetManager.prototype.ClearSiteFolder = function (Params)
{
    var _this = this;

    if(!_this.Active)
    {
        return;
    }

    var params =
        {
            method:"Sorm_ClearWgetSiteFolder",
            data:{Task:Params.task},
            callback:function (R)
            {
                if(!_this.Active)
                {
                    return;
                }

                if(Params && Params.callback && typeof Params.callback === "function")
                {
                    Params.callback(R);
                }
            }
        };

    if(this.Active)
    {
        App.ServerManager.CallMethod(params);
    }
};

WgetManager.prototype.GetSiteFolderInfo = function (Params)
{
    var _this = this;

    if(!_this.Active)
    {
        return;
    }

    var params =
        {
            method:"Sorm_GetWgetSiteFolder",
            data:{Task:Params.task},
            callback:function (R)
            {
                if(!_this.Active)
                {
                    return;
                }

                if(Params && Params.callback && typeof Params.callback === "function")
                {
                    Params.callback(R);
                }
            }
        };

    if(this.Active)
    {
        App.ServerManager.CallMethod(params);
    }
};

WgetManager.prototype.EnableVirtualHost = function(Params)
{
    var _this = this;

    var task = new WgetTaskModel(Params&&Params.task?Params.task:null);

    var params =
        {
            id: task.id,
            method:"Sorm_RunWgetVirtualHost",
            data: {
                Task: task
            },
            callback:function (R)
            {
                if(Params && Params.callback && typeof Params.callback === "function")
                {
                    Params.callback(R);
                }
            }
        };

    App.ServerManager.CallMethod(params);
};

WgetManager.prototype.DisableVirtualHost = function(Params)
{
    var _this = this;

    var task = new WgetTaskModel(Params&&Params.task?Params.task:null);

    var params =
        {
            id: task.id,
            method:"Sorm_StopWgetVirtualHost",
            data: {
                Task: task
            },
            callback:function (R)
            {
                if(Params && Params.callback && typeof Params.callback === "function")
                {
                    Params.callback(R);
                }
            }
        };

    App.ServerManager.CallMethod(params);
};

WgetManager.prototype.SpoofSite = function(Params)
{
    var _this = this;

    var task = new WgetTaskModel(Params&&Params.task?Params.task:null);

    var form = new WgetSpoofFormModel(Params&&Params.form?Params.form:null);

    var params =
        {
            method:"Sorm_InjectSpoofingToVirtualHost",
            data: {
                Task: task,
                Params: form
            },
            callback:function (R)
            {
                if(Params && Params.callback && typeof Params.callback === "function")
                {
                    Params.callback(R);
                }
            }
        };

    App.ServerManager.CallMethod(params);
};

WgetManager.prototype.RefreshSpoofingData = function(TaskModel, Params)
{
    var _this = this;

    this.CurrentTaskSpoofingDataRequestId = webix.uid();

    if(!_this.Active)
    {
        return;
    }

    var params =
        {
            id:this.CurrentTaskSpoofingDataRequestId,
            method:"Sorm_GetWgetTaskSpoofingData",
            data:{
                Task:TaskModel
            },
            callback:function (R)
            {
                if(!_this.Active)
                {
                    return;
                }

                if(_this.CurrentTaskSpoofingDataRequestId != R.id)
                {
                    return;
                }

                if(R.success)
                {
                    if(R.data)
                    {
                        _this.TaskSpoofingData[TaskModel.wget_id] = new WgetTaskLogModel(R.data);
                    }
                }
                else
                {
                    _this.TaskSpoofingData[TaskModel.wget_id] = new WgetTaskLogModel();
                }

                _this.SignalTaskSpoofingDataUpdated.next(TaskModel);

                if(Params && Params.callback && typeof Params.callback === "function")
                {
                    Params.callback(R);
                }
            }
        };

    if(this.Active)
    {
        App.ServerManager.CallMethod(params);
    }
};

WgetManager.prototype.GetSpoofingData = function(TaskModel)
{
    var _this = this;

    if(TaskModel && TaskModel.wget_id && _this.TaskSpoofingData[TaskModel.wget_id])
    {
        return _this.TaskSpoofingData[TaskModel.wget_id];
    }

    return null;
};

WgetManager.prototype.DownloadSpoofingData = function (TaskModel)
{
    var _this = this;

    if(!_this.Active)
    {
        return;
    }

    var params =
        {
            method:"Sorm_DownloadWgetTaskSpoofingData",
            data:{Task:TaskModel},
            callback:function (R)
            {
                if(!_this.Active)
                {
                    return;
                }

                if(R.success)
                {
                    App.ServerManager.RedirectToFileDownload(R.data);
                }
                else
                {
                    webix.message({type:"error", text:R.error.msg});
                }
            }
        };

    if(this.Active)
    {
        App.ServerManager.CallMethod(params);
    }
};
var NetworkAuditTaskStatusEnum = {
    new: 0,
    progress: 1,
    stoped: 2,
    error: 3
};

var NetworkAuditTaskStatusMapEnum = {
    "0": "new",
    "1": "progress",
    "2": "stoped",
    "3": "error"
};

var NetworkAuditTaskStatusColorsEnum = {};
NetworkAuditTaskStatusColorsEnum[NetworkAuditTaskStatusEnum.new] = "0";
NetworkAuditTaskStatusColorsEnum[NetworkAuditTaskStatusEnum.progress] = "green";
NetworkAuditTaskStatusColorsEnum[NetworkAuditTaskStatusEnum.stoped] = "orange";
NetworkAuditTaskStatusColorsEnum[NetworkAuditTaskStatusEnum.error] = "red";

function NetworkAuditTaskModel(Params)
{
    var __this = this;

    this.task_id = Params&&Params.task_id?Params.task_id:0;
    this.parent_id = Params&&Params.parent_id?Params.parent_id:null;
    this.user_id = Params&&Params.user_id?Params.user_id:null;
    this.title = Params&&Params.title?Params.title:"";
    this.date = Params&&Params.date?Params.date:0;
    this.status = Params&&Params.status?Params.status:NetworkAuditTaskStatusEnum.new;

    this.target = Params&&Params.target?Params.target:"";

    this.form_data = Params&&Params.form_data?Params.form_data:"";

    this.data = [];
    if(Params&&Params.data)
    {
        Params.data.forEach(function (Task)
        {
            __this.data.push(new NetworkAuditTaskModel(Task));
        });
    }

    this.open = true;
    this.valid = true;
};

function NetworkAuditTasksFilterModel(Params)
{
    this.paging = Params&&Params.id?Params.paging:{size: 100, group: 5, count:0, page:0};
    this.total = Params&&Params.id?Params.total:{size: 100, group: 5, count:0, page:0};
    this.filter = Params&&Params.id?Params.filter:{};
    this.sort = Params&&Params.id?Params.filter:{order_by:"task_id", order_dir:"desc"};
};

function NetworkAuditTaskLogModel(Params)
{
    this.task = Params&&Params.task?Params.task:null;
    this.file = Params&&Params.file?Params.file:null;
    this.content = Params&&Params.content?Params.content:"";
};

function NetworkAuditTaskFormCmdHostDiscoveryModel(Params)
{
    this.sL = Params&&Params.sL?Params.sL:0; //List Scan
    this.sn = Params&&Params.sn?Params.sn:0; //No port scan
    this.Pn = Params&&Params.Pn?Params.Pn:0; //No ping

    this.PS_enabled = Params&&Params.PS_enabled?Params.PS_enabled:0;
    this.PS = Params&&Params.PS?Params.PS:""; //TCP SYN Ping - port list

    this.PA_enabled = Params&&Params.PA_enabled?Params.PA_enabled:0;
    this.PA = Params&&Params.PA?Params.PA:""; //TCP ACK Ping - port list

    this.PU_enabled = Params&&Params.PU_enabled?Params.PU_enabled:0;
    this.PU = Params&&Params.PU?Params.PU:""; //UDP Ping - port list

    this.PY_enabled = Params&&Params.PY_enabled?Params.PY_enabled:0;
    this.PY = Params&&Params.PY?Params.PY:""; //SCTP INIT Ping - port list

    this.PE = Params&&Params.PE?Params.PE:0; //ICMP echo request discovery probes
    this.PP = Params&&Params.PP?Params.PP:0; //Timestamp request discovery probes
    this.PM = Params&&Params.PM?Params.PM:0; //Netmask request discovery probes

    this.PO_enabled = Params&&Params.PO_enabled?Params.PO_enabled:0;
    this.PO = Params&&Params.PO?Params.PO:""; //IP Protocol Ping - protocol list

    this.n = Params&&Params.n?Params.n:0; //Never do DNS resolution
    this.R = Params&&Params.R?Params.R:0; //Always DNS resolve [default: sometimes]

    this.dns_servers_enabled = Params&&Params.dns_servers_enabled?Params.dns_servers_enabled:0;
    this.dns_servers = Params&&Params.dns_servers?Params.dns_servers:"";

    this.system_dns = Params&&Params.system_dns?Params.system_dns:0;

    this.traceroute = Params&&Params.traceroute?Params.traceroute:0;
};

function NetworkAuditTaskFormCmdScanTechniquesModel(Params)
{
    this.sS = Params&&Params.sS?Params.sS:0; //TCP SYN scan
    this.sT = Params&&Params.sT?Params.sT:0; //TCP connect scan
    this.sU = Params&&Params.sU?Params.sU:0; //UDP scans
    this.sY = Params&&Params.sY?Params.sY:0; //SCTP INIT scan

    this.sN = Params&&Params.sN?Params.sN:0; //TCP Null scan
    this.sF = Params&&Params.sF?Params.sF:0; //FIN scan
    this.sX = Params&&Params.sX?Params.sX:0; //Xmas scan

    this.scanflags_enabled = Params&&Params.scanflags_enabled?Params.scanflags_enabled:"";
    this.scanflags = Params&&Params.scanflags?Params.scanflags:""; //Customize TCP scan flags

    this.sI_enabled = Params&&Params.sI_enabled?Params.sI_enabled:"";
    this.sI = Params&&Params.sI?Params.sI:""; //<zombie host>[:<probeport>] (idle scan)

    this.sZ = Params&&Params.sZ?Params.sZ:0; //COOKIE-ECHO scan

    this.sO = Params&&Params.sO?Params.sO:0; //IP protocol scan

    this.b_enabled = Params&&Params.b_enabled?Params.b_enabled:0;
    this.b = Params&&Params.b?Params.b:""; //<FTP relay host>: FTP bounce scan
};

function NetworkAuditTaskFormCmdPortSpecificationModel(Params)
{
    this.p_enabled = Params&&Params.p_enabled?Params.p_enabled:0;
    this.p = Params&&Params.p?Params.p:""; //<port ranges>: Only scan specified ports

    this.F = Params&&Params.F?Params.F:0; //Fast mode - Scan fewer ports than the default scan
    this.r = Params&&Params.r?Params.r:0; //Scan ports consecutively - don't randomize

    this.top_ports_enabled = Params&&Params.top_ports_enabled?Params.top_ports_enabled:0;
    this.top_ports = Params&&Params.top_ports?Params.top_ports:""; //<number>: Scan <number> most common ports

    this.port_ratio_enabled = Params&&Params.port_ratio_enabled?Params.port_ratio_enabled:0;
    this.port_ratio = Params&&Params.port_ratio?Params.port_ratio:""; //<ratio>: Scan ports more common than <ratio>
};

function NetworkAuditTaskFormCmdServiceVersionModel(Params)
{
    this.sV = Params&&Params.sV?Params.sV:0; //Probe open ports

    this.version_intensity_enabled = Params&&Params.version_intensity_enabled?Params.version_intensity_enabled:0;
    this.version_intensity = Params&&Params.version_intensity?Params.version_intensity:"";

    this.version_light = Params&&Params.version_light?Params.version_light:0;
    this.version_all = Params&&Params.version_all?Params.version_all:0;
    this.version_trace = Params&&Params.version_trace?Params.version_trace:0;
};

function NetworkAuditTaskFormCmdOsModel(Params)
{
    this.O = Params&&Params.O?Params.O:0; //Enable OS detection

    this.osscan_limit = Params&&Params.osscan_limit?Params.osscan_limit:0;
    this.osscan_guess = Params&&Params.osscan_guess?Params.osscan_guess:0;
};

function NetworkAuditTaskFormCmdFirewallModel(Params)
{
    this.f = Params&&Params.f?Params.f:0; //Fragment packets
    this.mtu_enabled = Params&&Params.mtu_enabled?Params.mtu_enabled:0;
    this.mtu = Params&&Params.mtu?Params.mtu:"";

    this.D_enabled = Params&&Params.D_enabled?Params.D_enabled:0;
    this.D = Params&&Params.D?Params.D:"";

    this.S_enabled = Params&&Params.S_enabled?Params.S_enabled:0;
    this.S = Params&&Params.S?Params.S:"";

    this.e_enabled = Params&&Params.e_enabled?Params.e_enabled:0;
    this.e = Params&&Params.e?Params.e:"";

    this.g_enabled = Params&&Params.g_enabled?Params.g_enabled:0;
    this.g = Params&&Params.g?Params.g:"";

    this.data_length_enabled = Params&&Params.data_length_enabled?Params.data_length_enabled:0;
    this.data_length = Params&&Params.data_length?Params.data_length:"";

    this.ip_options_enabled = Params&&Params.ip_options_enabled?Params.ip_options_enabled:0;
    this.ip_options = Params&&Params.ip_options?Params.ip_options:"";

    this.ttl_enabled = Params&&Params.ttl_enabled?Params.ttl_enabled:0;
    this.ttl = Params&&Params.ttl?Params.ttl:"";

    this.spoof_mac_enabled = Params&&Params.spoof_mac_enabled?Params.spoof_mac_enabled:0;
    this.spoof_mac = Params&&Params.spoof_mac?Params.spoof_mac:"";

    this.badsum = Params&&Params.badsum?Params.badsum:0;
};

function NetworkAuditTaskFormMiscModel(Params)
{
    this["6"] = Params&&Params["6"]?Params["6"]:0;
    this.A = Params&&Params.A?Params.A:0;

    this.any_enabled = Params&&Params.any_enabled?Params.any_enabled:0;
    this.any = Params&&Params.any?Params.any:"";
};

function NetworkAuditTaskFormTimingModel(Params)
{
    this.T_enabled = Params&&Params.T_enabled?Params.T_enabled:0;
    this.T = Params&&Params.T?Params.T:"";

    this.hostgroup_enabled = Params&&Params.hostgroup_enabled?Params.hostgroup_enabled:0;
    this.min_hostgroup = Params&&Params.min_hostgroup?Params.min_hostgroup:"";
    this.max_hostgroup = Params&&Params.max_hostgroup?Params.max_hostgroup:"";

    this.parallelism_enabled = Params&&Params.parallelism_enabled?Params.parallelism_enabled:0;
    this.min_parallelism = Params&&Params.min_parallelism?Params.min_parallelism:"";
    this.max_parallelism = Params&&Params.max_parallelism?Params.max_parallelism:"";

    this.rtt_timeout_enabled = Params&&Params.rtt_timeout_enabled?Params.rtt_timeout_enabled:0;
    this.min_rtt_timeout = Params&&Params.min_rtt_timeout?Params.min_rtt_timeout:"";
    this.max_rtt_timeout = Params&&Params.max_rtt_timeout?Params.max_rtt_timeout:"";
    this.initial_rtt_timeout = Params&&Params.initial_rtt_timeout?Params.initial_rtt_timeout:"";

    this.max_retries_enabled = Params&&Params.max_retries_enabled?Params.max_retries_enabled:0;
    this.max_retries = Params&&Params.max_retries?Params.max_retries:"";

    this.host_timeout_enabled = Params&&Params.host_timeout_enabled?Params.host_timeout_enabled:0;
    this.host_timeout = Params&&Params.host_timeout?Params.host_timeout:"";

    this.scan_delay_enabled = Params&&Params.scan_delay_enabled?Params.scan_delay_enabled:0;
    this.scan_delay = Params&&Params.scan_delay?Params.scan_delay:"";
    this.max_scan_delay = Params&&Params.max_scan_delay?Params.max_scan_delay:"";

    this.rate_enabled = Params&&Params.rate_enabled?Params.rate_enabled:0;
    this.min_rate = Params&&Params.min_rate?Params.min_rate:"";
    this.max_rate = Params&&Params.max_rate?Params.max_rate:"";
};

function NetworkAuditTaskFormCmdModel(Params)
{
    var _this = this;

    var model = new NetworkAuditTaskFormCmdHostDiscoveryModel(Params);
    Object.keys(model).forEach(function (Key){_this[Key] = model[Key];});

    var model = new NetworkAuditTaskFormCmdScanTechniquesModel(Params);
    Object.keys(model).forEach(function (Key){_this[Key] = model[Key];});

    var model = new NetworkAuditTaskFormCmdPortSpecificationModel(Params);
    Object.keys(model).forEach(function (Key){_this[Key] = model[Key];});

    var model = new NetworkAuditTaskFormCmdServiceVersionModel(Params);
    Object.keys(model).forEach(function (Key){_this[Key] = model[Key];});

    var model = new NetworkAuditTaskFormCmdOsModel(Params);
    Object.keys(model).forEach(function (Key){_this[Key] = model[Key];});

    var model = new NetworkAuditTaskFormCmdFirewallModel(Params);
    Object.keys(model).forEach(function (Key){_this[Key] = model[Key];});

    var model = new NetworkAuditTaskFormMiscModel(Params);
    Object.keys(model).forEach(function (Key){_this[Key] = model[Key];});

    var model = new NetworkAuditTaskFormTimingModel(Params);
    Object.keys(model).forEach(function (Key){_this[Key] = model[Key];});
};

function NetworkAuditTaskFormModel(Params, PartMain, PartAdd)
{
    if(typeof PartMain == "undefined" || PartMain === true)
    {
        this.title = Params&&Params.title?Params.title:"";

        this.target = Params&&Params.target?Params.target:"";

        this.template = Params&&Params.template?Params.template:"0";
    }

    if(typeof PartAdd == "undefined" || PartAdd === true)
    {
        this.cmd = Params&&Params.cmd ? new NetworkAuditTaskFormCmdModel(Params.cmd) : new NetworkAuditTaskFormCmdModel();
    }
};

function NetworkAuditManager()
{
    this.Active = true;

    this.Tasks = [];
    this.TasksFilter = new NetworkAuditTasksFilterModel();

    this.TaskLogsData = {};
    this.TaskXmlData = {};

    this.SignalTasksUpdated = new Rx.BehaviorSubject(0);
    this.SignalTaskUpdated = new Rx.BehaviorSubject(0);
    this.SignalTaskLogsUpdated = new Rx.BehaviorSubject(0);
    this.SignalTaskXmlUpdated = new Rx.BehaviorSubject(0);

    this.CurrentTasksRequestId = 0;
    this.CurrentTaskLogsDataRequestId = 0;
    this.CurrentTaskXmlDataRequestId = 0;
};

NetworkAuditManager.prototype.Destroy = function(Params)
{
    this.Active = false;
};

NetworkAuditManager.prototype.RefershTasks = function(Params)
{
    var _this = this;

    if(!partial)
        this.CurrentTasksRequestId = webix.uid();

    if(!_this.Active)
    {
        return;
    }

    if(Params&&Params.page >= 0)
    {
        this.TasksFilter.total.page = parseInt(Params.page,10);
    }

    if(Params&&Params.size&&Params.size >= 0)
    {
        this.TasksFilter.total.size = parseInt(Params.size,10);
    }

    if(Params&&Params.filter)
    {
        this.TasksFilter.filter = Params.filter;
    }

    if(Params&&Params.sort)
    {
        this.TasksFilter.sort.order_by = Params.sort.id;
        this.TasksFilter.sort.order_dir = Params.sort.dir;
    }

    var partial = Params&&Params.tasks_ids ? true : false;

    var filterParams = {
        page:this.TasksFilter.total.page,
        size:this.TasksFilter.total.size,

        order_by:this.TasksFilter.sort.order_by,
        order_dir:this.TasksFilter.sort.order_dir
    };

    Object.keys(this.TasksFilter.filter).forEach(function (Key)
    {
        if(_this.TasksFilter.filter[Key])
            filterParams[Key] = _this.TasksFilter.filter[Key];
    });

    var _params = filterParams;
    _params.tasks_ids = partial?Params&&Params.tasks_ids:null;

    if(!partial)
    {
        _params.parent_id_is_null = true;
    }

    var params =
        {
            id:this.CurrentTasksRequestId,
            method:"Sorm_GetNmapTasks",
            data:{
                Params: _params
            },
            callback:function (R)
            {
                if(!_this.Active)
                {
                    return;
                }

                if(!partial && _this.CurrentTasksRequestId != R.id)
                {
                    return;
                }

                if(R.success)
                {
                    if(partial)
                    {
                        if(R.data)
                        {
                            R.data.rows.forEach(function (Task)
                            {
                                _this.SignalTaskUpdated.next(new NetworkAuditTaskModel(Task));
                            });
                        }
                    }
                    else
                    {
                        _this.Tasks = [];
                        _this.TasksFilter.total.page = 0;
                        _this.TasksFilter.total.count = 0;

                        if(R.data)
                        {
                            R.data.rows.forEach(function (Task)
                            {
                                _this.Tasks.push(new NetworkAuditTaskModel(Task));
                            });

                            _this.TasksFilter.total.page = parseInt(R.data.page,10);
                            _this.TasksFilter.total.count = parseInt(R.data.count,10);
                        }

                        _this.SignalTasksUpdated.next(1);
                    }
                }

                if(Params && Params.callback && typeof Params.callback === "function")
                {
                    Params.callback(R);
                }
            }
        };

    if(this.Active)
    {
        App.ServerManager.CallMethod(params);
    }
};

NetworkAuditManager.prototype.GetTasks = function()
{
    return this.Tasks;
};

NetworkAuditManager.prototype.SaveTask = function(Params)
{
    var _this = this;

    var task = new NetworkAuditTaskModel(Params&&Params.task?Params.task:null);
    var taskForm = new NetworkAuditTaskFormModel(Params&&Params.task?Params.task_form:null);

    var params =
        {
            id: task.id,
            method:"Sorm_SaveNmapTask",
            data: {
                Task: task,
                Form: taskForm
            },
            callback:function (R)
            {
                if(Params && Params.callback && typeof Params.callback === "function")
                {
                    Params.callback(R);
                }
            }
        };

    App.ServerManager.CallMethod(params);
};

NetworkAuditManager.prototype.DeleteTask = function(Params)
{
    var _this = this;

    var task = new NetworkAuditTaskModel(Params&&Params.task?Params.task:null);

    var params =
        {
            id: task.id,
            method:"Sorm_DeleteNmapTask",
            data: {
                Task: task
            },
            callback:function (R)
            {
                if(Params && Params.callback && typeof Params.callback === "function")
                {
                    Params.callback(R);
                }
            }
        };

    App.ServerManager.CallMethod(params);
};

NetworkAuditManager.prototype.RunTask = function(Params)
{
    var _this = this;

    var task = new NetworkAuditTaskModel(Params&&Params.task?Params.task:null);

    var params =
        {
            id: task.id,
            method:"Sorm_RunNmapTask",
            data: {
                Task: task
            },
            callback:function (R)
            {
                if(Params && Params.callback && typeof Params.callback === "function")
                {
                    Params.callback(R);
                }
            }
        };

    App.ServerManager.CallMethod(params);
};

NetworkAuditManager.prototype.StopTask = function(Params)
{
    var _this = this;

    var task = new NetworkAuditTaskModel(Params&&Params.task?Params.task:null);

    var params =
        {
            id: task.id,
            method:"Sorm_StopNmapTask",
            data: {
                Task: task
            },
            callback:function (R)
            {
                if(Params && Params.callback && typeof Params.callback === "function")
                {
                    Params.callback(R);
                }
            }
        };

    App.ServerManager.CallMethod(params);
};

NetworkAuditManager.prototype.RefreshTaskLogs = function(TaskModel, Params)
{
    var _this = this;

    this.CurrentTaskLogsDataRequestId = webix.uid();

    if(!_this.Active)
    {
        return;
    }

    var params =
        {
            id:this.CurrentTaskLogsDataRequestId,
            method:"Sorm_GetNmapTaskLogs",
            data:{
                Task:TaskModel
            },
            callback:function (R)
            {
                if(!_this.Active)
                {
                    return;
                }

                if(_this.CurrentTaskLogsDataRequestId != R.id)
                {
                    return;
                }

                if(R.success)
                {
                    if(R.data)
                    {
                        _this.TaskLogsData[TaskModel.task_id] = new NetworkAuditTaskLogModel(R.data);
                    }
                }
                else
                {
                    _this.TaskLogsData[TaskModel.task_id] = new NetworkAuditTaskLogModel();
                }

                _this.SignalTaskLogsUpdated.next(TaskModel);

                if(Params && Params.callback && typeof Params.callback === "function")
                {
                    Params.callback(R);
                }
            }
        };

    if(this.Active)
    {
        App.ServerManager.CallMethod(params);
    }
};

NetworkAuditManager.prototype.GetTaskLogs = function(TaskModel)
{
    var _this = this;

    if(TaskModel && TaskModel.task_id && _this.TaskLogsData[TaskModel.task_id])
    {
        return _this.TaskLogsData[TaskModel.task_id];
    }

    return null;
};

NetworkAuditManager.prototype.RefreshTaskXml = function(TaskModel, Params)
{
    var _this = this;

    this.CurrentTaskXmlDataRequestId = webix.uid();

    if(!_this.Active)
    {
        return;
    }

    var params =
        {
            id:this.CurrentTaskXmlDataRequestId,
            method:"Sorm_GetNmapTaskXml",
            data:{
                Task:TaskModel
            },
            callback:function (R)
            {
                if(!_this.Active)
                {
                    return;
                }

                if(_this.CurrentTaskXmlDataRequestId != R.id)
                {
                    return;
                }

                if(R.success)
                {
                    if(R.data)
                    {
                        _this.TaskXmlData[TaskModel.task_id] = new NetworkAuditTaskLogModel(R.data);
                    }
                }
                else
                {
                    _this.TaskXmlData[TaskModel.task_id] = new NetworkAuditTaskLogModel();
                }

                _this.SignalTaskXmlUpdated.next(TaskModel);

                if(Params && Params.callback && typeof Params.callback === "function")
                {
                    Params.callback(R);
                }
            }
        };

    if(this.Active)
    {
        App.ServerManager.CallMethod(params);
    }
};

NetworkAuditManager.prototype.GetTaskXml = function(TaskModel)
{
    var _this = this;

    if(TaskModel && TaskModel.task_id && _this.TaskXmlData[TaskModel.task_id])
    {
        return _this.TaskXmlData[TaskModel.task_id];
    }

    return null;
};

NetworkAuditManager.prototype.DownloadTaskPdfReport = function (TaskModel)
{
    var _this = this;

    if(!_this.Active)
    {
        return;
    }

    var params =
        {
            method:"Sorm_DownloadNmapTaskPdfReport",
            data:{Task:TaskModel},
            callback:function (R)
            {
                if(!_this.Active)
                {
                    return;
                }

                if(R.success)
                {
                    App.ServerManager.RedirectToFileDownload(R.data);
                }
                else
                {
                    webix.message({type:"error", text:R.error.msg});
                }
            }
        };

    if(this.Active)
    {
        App.ServerManager.CallMethod(params);
    }
};
function TrafficDecodeWebModel(Params)
{
    var __this = this;

    this.id = Params&&Params.id?Params.id:webix.uid();

    this.pcap_id = Params&&Params.pcap_id?Params.pcap_id:0;
    this.req_date = Params&&Params.req_date?Params.req_date:null;
    this.res_time = Params&&Params.res_time?Params.res_time:null;
    this.host = Params&&Params.host?Params.host:0;
    this.url = Params&&Params.url?Params.url:0;
    this.srcport = Params&&Params.srcport?Params.srcport:0;
    this.dstport = Params&&Params.dstport?Params.dstport:0;
    this.ip_proto = Params&&Params.ip_proto?Params.ip_proto:0;
    this.ip_version = Params&&Params.ip_version?Params.ip_version:0;
    this.ip_src = Params&&Params.ip_src?Params.ip_src:0;
    this.ip_dst = Params&&Params.ip_dst?Params.ip_dst:0;
    this.eth_src = Params&&Params.eth_src?Params.eth_src:0;
    this.eth_dst = Params&&Params.eth_dst?Params.eth_dst:0;
    this.eth_type = Params&&Params.eth_type?Params.eth_type:0;
    this.response_code = Params&&Params.response_code?Params.response_code:0;
    this.content_type = Params&&Params.content_type?Params.content_type:0;
    this.content_encoding = Params&&Params.content_encoding?Params.content_encoding:0;
    this.chunk_size = Params&&Params.chunk_size?Params.chunk_size:0;
    this.file_data = Params&&Params.file_data?Params.file_data:0;
    this.tcp_time = Params&&Params.tcp_time?Params.tcp_time:0;
    this.request_method = Params&&Params.request_method?Params.request_method:0;
    this.user_agent = Params&&Params.user_agent?Params.user_agent:0;
    this.available_file = Params&&Params.available_file?Params.available_file:null;

    this.valid = true;
};

function TrafficDecodeWebManager ()
{
    this.Active = true;

    this.Rows = [];
    this.RowsFilter = {};
    this.RowsFilter.Paging = {
        size: 100,
        group: 5,
        count:0,
        page:0
    };
    this.RowsFilter.Total = {
        size: 100,
        group: 5,
        count:0,
        page:0
    };
    this.RowsFilter.Filter = {
        pcap_ids:null,
        url:null,
        req_date: null,
        chunk_size: null,
        request_method: null
    };
    this.RowsFilter.Sort = {
        order_by:"req_date",
        order_dir:"desc"
    };

    this.SignalRowsUpdated = new Rx.BehaviorSubject(0);

    this.Images = [];
    this.ImagesFilter = {};
    this.ImagesFilter.Paging = {
        size: 100,
        group: 5,
        count:0,
        page:0
    };
    this.ImagesFilter.Total = {
        size: 100,
        group: 5,
        count:0,
        page:0
    };
    this.ImagesFilter.Filter = {
        pcap_ids:null,
        url:null,
        req_date: null,
        chunk_size: null,
        request_method: null,
        full_text_search: null
    };
    this.ImagesFilter.Sort = {
        order_by:"req_date",
        order_dir:"desc"
    };

    this.SignalImagesUpdated = new Rx.BehaviorSubject(0);
};

TrafficDecodeWebManager.prototype.Destroy = function(Params)
{
    this.Active = false;
};

TrafficDecodeWebManager.prototype.RefreshRows = function(Params)
{
    var _this = this;

    this.CurrentTasksRequestId = webix.uid();

    if(!_this.Active)
    {
        return;
    }

    if(Params&&Params.page >= 0)
    {
        this.RowsFilter.Total.page = parseInt(Params.page,10);
    }

    if(Params&&Params.size&&Params.size >= 0)
    {
        this.RowsFilter.Total.size = parseInt(Params.size,10);
    }

    if(Params&&Params.filter)
    {
        this.RowsFilter.Filter = webix.extend(this.RowsFilter,Params.filter, true);
    }

    if(Params&&Params.sort)
    {
        this.RowsFilter.Sort.order_by = Params.sort.id;
        this.RowsFilter.Sort.order_dir = Params.sort.dir;
    }

    var _params = {
        page:this.RowsFilter.Total.page,
        size:this.RowsFilter.Total.size,
        url:this.RowsFilter.Filter.url,
        req_date:this.RowsFilter.Filter.req_date,
        chunk_size:this.RowsFilter.Filter.chunk_size,
        request_method:this.RowsFilter.Filter.request_method,
        order_by:this.RowsFilter.Sort.order_by,
        order_dir:this.RowsFilter.Sort.order_dir,

        pcap_ids:this.RowsFilter.pcap_ids
    };

    var params =
        {
            id:this.CurrentTasksRequestId,
            method:"Sorm_GetPcapDecodeWebRows",
            data:{
                Params: _params
            },
            callback:function (R)
            {
                if(!_this.Active)
                {
                    return;
                }

                if(_this.CurrentTasksRequestId != R.id)
                {
                    return;
                }

                if(R.success)
                {
                    _this.Rows = [];
                    _this.RowsFilter.Total.page = 0;
                    _this.RowsFilter.Total.count = 0;

                    if(R.data)
                    {
                        R.data.rows.forEach(function (Row)
                        {
                            _this.Rows.push(new TrafficDecodeWebModel(Row));
                        });

                        _this.RowsFilter.Total.page = parseInt(R.data.page,10);
                        _this.RowsFilter.Total.count = parseInt(R.data.count,10);
                    }

                    _this.SignalRowsUpdated.next(1);
                }

                if(Params && Params.callback && typeof Params.callback === "function")
                {
                    Params.callback(R);
                }
            }
        };

    if(this.Active)
    {
        App.ServerManager.CallMethod(params);
    }
};

TrafficDecodeWebManager.prototype.GetRows = function()
{
    return this.Rows;
};

TrafficDecodeWebManager.prototype.RefreshImages = function(Params)
{
    var _this = this;

    this.CurrentTasksRequestId = webix.uid();

    if(!_this.Active)
    {
        return;
    }

    if(Params&&Params.page >= 0)
    {
        this.ImagesFilter.Total.page = parseInt(Params.page,10);
    }

    if(Params&&Params.size&&Params.size >= 0)
    {
        this.ImagesFilter.Total.size = parseInt(Params.size,10);
    }

    if(Params&&Params.filter)
    {
        this.ImagesFilter.Filter = webix.extend(this.ImagesFilter,Params.filter, true);
    }

    if(Params&&Params.sort)
    {
        this.ImagesFilter.Sort.order_by = Params.sort.id;
        this.ImagesFilter.Sort.order_dir = Params.sort.dir;
    }

    var _params = {
        page:this.ImagesFilter.Total.page,
        size:this.ImagesFilter.Total.size,
        url:this.ImagesFilter.Filter.url,
        req_date:this.ImagesFilter.Filter.req_date,
        chunk_size:this.ImagesFilter.Filter.chunk_size,
        request_method:this.ImagesFilter.Filter.request_method,
        full_text_search:this.ImagesFilter.Filter.full_text_search,
        order_by:this.ImagesFilter.Sort.order_by,
        order_dir:this.ImagesFilter.Sort.order_dir,

        pcap_ids:this.ImagesFilter.Filter.pcap_ids
    };

    var params =
        {
            id:this.CurrentTasksRequestId,
            method:"Sorm_GetPcapDecodeWebImages",
            data:{
                Params: _params
            },
            callback:function (R)
            {
                if(!_this.Active)
                {
                    return;
                }

                if(_this.CurrentTasksRequestId != R.id)
                {
                    return;
                }

                if(R.success)
                {
                    _this.Images = [];
                    _this.ImagesFilter.Total.page = 0;
                    _this.ImagesFilter.Total.count = 0;

                    if(R.data)
                    {
                        R.data.rows.forEach(function (Row)
                        {
                            _this.Images.push(new TrafficDecodeWebModel(Row));
                        });

                        _this.ImagesFilter.Total.page = parseInt(R.data.page,10);
                        _this.ImagesFilter.Total.count = parseInt(R.data.count,10);
                    }

                    _this.SignalImagesUpdated.next(1);
                }

                if(Params && Params.callback && typeof Params.callback === "function")
                {
                    Params.callback(R);
                }
            }
        };

    if(this.Active)
    {
        App.ServerManager.CallMethod(params);
    }
};

TrafficDecodeWebManager.prototype.GetImages = function()
{
    return this.Images;
};

function TrafficDecodeDnsModel(Params)
{
    var __this = this;

    this.id = Params&&Params.id?Params.id:webix.uid();

    this.row_id = Params&&Params.row_id?Params.row_id:0;
    this.pcap_id = Params&&Params.pcap_id?Params.pcap_id:0;
    this.req_date = Params&&Params.req_date?Params.req_date:null;
    this.res_time = Params&&Params.res_time?Params.res_time:null;
    this.host = Params&&Params.host?Params.host:0;
    this.srcport = Params&&Params.srcport?Params.srcport:0;
    this.dstport = Params&&Params.dstport?Params.dstport:0;
    this.ip_proto = Params&&Params.ip_proto?Params.ip_proto:0;
    this.ip_version = Params&&Params.ip_version?Params.ip_version:0;
    this.ip_src = Params&&Params.ip_src?Params.ip_src:0;
    this.ip_dst = Params&&Params.ip_dst?Params.ip_dst:0;
    this.eth_src = Params&&Params.eth_src?Params.eth_src:0;
    this.eth_dst = Params&&Params.eth_dst?Params.eth_dst:0;
    this.eth_type = Params&&Params.eth_type?Params.eth_type:0;
    this.address = Params&&Params.address?Params.address:"";
    this.cname = Params&&Params.cname?Params.cname:"";
    this.srv_name = Params&&Params.srv_name?Params.srv_name:"";
    this.domain_name = Params&&Params.domain_name?Params.domain_name:"";

    this.valid = true;
};

function TrafficDecodeDnsManager ()
{
    this.Active = true;

    this.Rows = [];
    this.RowsFilter = {};
    this.RowsFilter.Paging = {
        size: 100,
        group: 5,
        count:0,
        page:0
    };
    this.RowsFilter.Total = {
        size: 100,
        group: 5,
        count:0,
        page:0
    };
    this.RowsFilter.Filter = {
        pcap_ids:null,
        full_text_search: null,
        req_date: null
    };
    this.RowsFilter.Sort = {
        order_by:"req_date",
        order_dir:"desc"
    };

    this.SignalRowsUpdated = new Rx.BehaviorSubject(0);
};

TrafficDecodeDnsManager.prototype.Destroy = function(Params)
{
    this.Active = false;
};

TrafficDecodeDnsManager.prototype.RefreshRows = function(Params)
{
    var _this = this;

    this.CurrentTasksRequestId = webix.uid();

    if(!_this.Active)
    {
        return;
    }

    if(Params&&Params.page >= 0)
    {
        this.RowsFilter.Total.page = parseInt(Params.page,10);
    }

    if(Params&&Params.size&&Params.size >= 0)
    {
        this.RowsFilter.Total.size = parseInt(Params.size,10);
    }

    if(Params&&Params.filter)
    {
        this.RowsFilter.Filter = webix.extend(this.RowsFilter,Params.filter, true);
    }

    if(Params&&Params.sort)
    {
        this.RowsFilter.Sort.order_by = Params.sort.id;
        this.RowsFilter.Sort.order_dir = Params.sort.dir;
    }

    var _params = {
        page:this.RowsFilter.Total.page,
        size:this.RowsFilter.Total.size,
        full_text_search:this.RowsFilter.Filter.full_text_search,
        req_date:this.RowsFilter.Filter.req_date,
        order_by:this.RowsFilter.Sort.order_by,
        order_dir:this.RowsFilter.Sort.order_dir,

        pcap_ids:this.RowsFilter.pcap_ids
    };

    var params =
        {
            id:this.CurrentTasksRequestId,
            method:"Sorm_GetPcapDecodeDnsRows",
            data:{
                Params: _params
            },
            callback:function (R)
            {
                if(!_this.Active)
                {
                    return;
                }

                if(_this.CurrentTasksRequestId != R.id)
                {
                    return;
                }

                if(R.success)
                {
                    _this.Rows = [];
                    _this.RowsFilter.Total.page = 0;
                    _this.RowsFilter.Total.count = 0;

                    if(R.data)
                    {
                        R.data.rows.forEach(function (Row)
                        {
                            _this.Rows.push(new TrafficDecodeDnsModel(Row));
                        });

                        _this.RowsFilter.Total.page = parseInt(R.data.page,10);
                        _this.RowsFilter.Total.count = parseInt(R.data.count,10);
                    }

                    _this.SignalRowsUpdated.next(1);
                }

                if(Params && Params.callback && typeof Params.callback === "function")
                {
                    Params.callback(R);
                }
            }
        };

    if(this.Active)
    {
        App.ServerManager.CallMethod(params);
    }
};

TrafficDecodeDnsManager.prototype.GetRows = function()
{
    return this.Rows;
};

function TrafficDecodeVoipModel(Params)
{
    var __this = this;

    this.id = Params&&Params.id?Params.id:webix.uid();

    this.pcap_id = Params&&Params.pcap_id?Params.pcap_id:0;
    this.req_date = Params&&Params.req_date?Params.req_date:null;
    this.duration = Params&&Params.duration?Params.duration:null;

    this.eth_src = Params&&Params.eth_src?Params.eth_src:"";
    this.eth_dst = Params&&Params.eth_dst?Params.eth_dst:"";
    this.eth_type = Params&&Params.eth_type?Params.eth_type:"";


    this.ip_proto = Params&&Params.ip_proto?Params.ip_proto:"";
    this.ip_version = Params&&Params.ip_version?Params.ip_version:"";
    this.ip_src = Params&&Params.ip_src?Params.ip_src:"";
    this.ip_dst = Params&&Params.ip_dst?Params.ip_dst:"";

    this.srcport = Params&&Params.srcport?Params.srcport:"";
    this.dstport = Params&&Params.dstport?Params.dstport:"";

    this.sip_from = Params&&Params.sip_from?Params.sip_from:"";
    this.sip_to = Params&&Params.sip_to?Params.sip_to:"";
    this.msg_headers = Params&&Params.msg_headers?Params.msg_headers:"";
    this.sip_call_id = Params&&Params.sip_call_id?Params.sip_call_id:"";
    this.ssrc_from = Params&&Params.ssrc_from?Params.ssrc_from:"";
    this.ssrc_to = Params&&Params.ssrc_to?Params.ssrc_to:"";
    this.file_1 = Params&&Params.file_1?Params.file_1:"";
    this.file_2 = Params&&Params.file_2?Params.file_2:"";
    this.file_combined = Params&&Params.file_combined?Params.file_combined:"";

    this.valid = true;
};

function TrafficDecodeVoipManager ()
{
    this.Active = true;

    this.Rows = [];
    this.RowsFilter = {};
    this.RowsFilter.Paging = {
        size: 100,
        group: 5,
        count:0,
        page:0
    };
    this.RowsFilter.Total = {
        size: 100,
        group: 5,
        count:0,
        page:0
    };
    this.RowsFilter.Filter = {
        pcap_ids:null,
        full_text_search: null,
        req_date: null
    };
    this.RowsFilter.Sort = {
        order_by:"req_date",
        order_dir:"desc"
    };

    this.SignalRowsUpdated = new Rx.BehaviorSubject(0);
};

TrafficDecodeVoipManager.prototype.Destroy = function(Params)
{
    this.Active = false;
};

TrafficDecodeVoipManager.prototype.RefreshRows = function(Params)
{
    var _this = this;

    this.CurrentTasksRequestId = webix.uid();

    if(!_this.Active)
    {
        return;
    }

    if(Params&&Params.page >= 0)
    {
        this.RowsFilter.Total.page = parseInt(Params.page,10);
    }

    if(Params&&Params.size&&Params.size >= 0)
    {
        this.RowsFilter.Total.size = parseInt(Params.size,10);
    }

    if(Params&&Params.filter)
    {
        this.RowsFilter.Filter = webix.extend(this.RowsFilter,Params.filter, true);
    }

    if(Params&&Params.sort)
    {
        this.RowsFilter.Sort.order_by = Params.sort.id;
        this.RowsFilter.Sort.order_dir = Params.sort.dir;
    }

    var _params = {
        page:this.RowsFilter.Total.page,
        size:this.RowsFilter.Total.size,
        sip_from:this.RowsFilter.Filter.sip_from,
        sip_to:this.RowsFilter.Filter.sip_to,
        req_date:this.RowsFilter.Filter.req_date,
        duration:this.RowsFilter.Filter.duration,
        order_by:this.RowsFilter.Sort.order_by,
        order_dir:this.RowsFilter.Sort.order_dir,

        pcap_ids:this.RowsFilter.pcap_ids
    };

    var params =
        {
            id:this.CurrentTasksRequestId,
            method:"Sorm_GetPcapDecodeVoipRows",
            data:{
                Params: _params
            },
            callback:function (R)
            {
                if(!_this.Active)
                {
                    return;
                }

                if(_this.CurrentTasksRequestId != R.id)
                {
                    return;
                }

                if(R.success)
                {
                    _this.Rows = [];
                    _this.RowsFilter.Total.page = 0;
                    _this.RowsFilter.Total.count = 0;

                    if(R.data)
                    {
                        R.data.rows.forEach(function (Row)
                        {
                            _this.Rows.push(new TrafficDecodeVoipModel(Row));
                        });

                        _this.RowsFilter.Total.page = parseInt(R.data.page,10);
                        _this.RowsFilter.Total.count = parseInt(R.data.count,10);
                    }

                    _this.SignalRowsUpdated.next(1);
                }

                if(Params && Params.callback && typeof Params.callback === "function")
                {
                    Params.callback(R);
                }
            }
        };

    if(this.Active)
    {
        App.ServerManager.CallMethod(params);
    }
};

TrafficDecodeVoipManager.prototype.GetRows = function()
{
    return this.Rows;
};
var TrafficDecodeFtpDirectionsDic = {
    "up": {id: "up", value: LangHelper.GetString("TrafficDecode", "Upload")},
    "down": {id: "down", value: LangHelper.GetString("TrafficDecode", "Download")},
};

function TrafficDecodeFtpModel(Params)
{
    var __this = this;

    this.id = Params&&Params.id?Params.id:webix.uid();

    this.pcap_id = Params&&Params.pcap_id?Params.pcap_id:0;
    this.req_date = Params&&Params.req_date?Params.req_date:null;

    this.eth_src = Params&&Params.eth_src?Params.eth_src:"";
    this.eth_dst = Params&&Params.eth_dst?Params.eth_dst:"";
    this.eth_type = Params&&Params.eth_type?Params.eth_type:"";


    this.ip_proto = Params&&Params.ip_proto?Params.ip_proto:"";
    this.ip_version = Params&&Params.ip_version?Params.ip_version:"";
    this.ip_src = Params&&Params.ip_src?Params.ip_src:"";
    this.ip_dst = Params&&Params.ip_dst?Params.ip_dst:"";

    this.srcport = Params&&Params.srcport?Params.srcport:"";
    this.dstport = Params&&Params.dstport?Params.dstport:"";

    this.file_name = Params&&Params.file_name?Params.file_name:"";
    this.direction = Params&&Params.direction?Params.direction:"";
    this.ftp_directory = Params&&Params.ftp_directory?Params.ftp_directory:TrafficDecodeFtpDirectionsDic.down.id;
    this.file_size = Params&&Params.file_size?Params.file_size:0;
    this.available_file = Params&&Params.available_file?Params.available_file:"";

    this.valid = true;
};

function TrafficDecodeFtpManager ()
{
    this.Active = true;

    this.Rows = [];
    this.RowsFilter = {};
    this.RowsFilter.Paging = {
        size: 100,
        group: 5,
        count:0,
        page:0
    };
    this.RowsFilter.Total = {
        size: 100,
        group: 5,
        count:0,
        page:0
    };
    this.RowsFilter.Filter = {
        pcap_ids:null,
        ip_src: null,
        req_date: null,
        ip_dst: null,
        file_name: null,
        file_size: null,
        direction: null
    };
    this.RowsFilter.Sort = {
        order_by:"req_date",
        order_dir:"desc"
    };

    this.SignalRowsUpdated = new Rx.BehaviorSubject(0);
};

TrafficDecodeFtpManager.prototype.Destroy = function(Params)
{
    this.Active = false;
};

TrafficDecodeFtpManager.prototype.RefreshRows = function(Params)
{
    var _this = this;

    this.CurrentTasksRequestId = webix.uid();

    if(!_this.Active)
    {
        return;
    }

    if(Params&&Params.page >= 0)
    {
        this.RowsFilter.Total.page = parseInt(Params.page,10);
    }

    if(Params&&Params.size&&Params.size >= 0)
    {
        this.RowsFilter.Total.size = parseInt(Params.size,10);
    }

    if(Params&&Params.filter)
    {
        this.RowsFilter.Filter = webix.extend(this.RowsFilter,Params.filter, true);
    }

    if(Params&&Params.sort)
    {
        this.RowsFilter.Sort.order_by = Params.sort.id;
        this.RowsFilter.Sort.order_dir = Params.sort.dir;
    }

    var _params = {
        page:this.RowsFilter.Total.page,
        size:this.RowsFilter.Total.size,
        ip_src:this.RowsFilter.Filter.ip_src,
        ip_dst:this.RowsFilter.Filter.ip_dst,
        req_date:this.RowsFilter.Filter.req_date,
        file_name:this.RowsFilter.Filter.file_name,
        ftp_directory:this.RowsFilter.Filter.ftp_directory,
        file_size:this.RowsFilter.Filter.file_size,
        order_by:this.RowsFilter.Sort.order_by,
        order_dir:this.RowsFilter.Sort.order_dir,
        direction: this.RowsFilter.Filter.direction,
        pcap_ids:this.RowsFilter.pcap_ids
    };

    var params =
        {
            id:this.CurrentTasksRequestId,
            method:"Sorm_GetPcapDecodeFtpRows",
            data:{
                Params: _params
            },
            callback:function (R)
            {
                if(!_this.Active)
                {
                    return;
                }

                if(_this.CurrentTasksRequestId != R.id)
                {
                    return;
                }

                if(R.success)
                {
                    _this.Rows = [];
                    _this.RowsFilter.Total.page = 0;
                    _this.RowsFilter.Total.count = 0;

                    if(R.data)
                    {
                        R.data.rows.forEach(function (Row)
                        {
                            _this.Rows.push(new TrafficDecodeFtpModel(Row));
                        });

                        _this.RowsFilter.Total.page = parseInt(R.data.page,10);
                        _this.RowsFilter.Total.count = parseInt(R.data.count,10);
                    }

                    _this.SignalRowsUpdated.next(1);
                }

                if(Params && Params.callback && typeof Params.callback === "function")
                {
                    Params.callback(R);
                }
            }
        };

    if(this.Active)
    {
        App.ServerManager.CallMethod(params);
    }
};

TrafficDecodeFtpManager.prototype.GetRows = function()
{
    return this.Rows;
};
function TrafficDecodeMailModel(Params)
{
    var __this = this;

    this.id = Params&&Params.id?Params.id:webix.uid();

    this.row_id = Params&&Params.row_id?Params.row_id:0;
    this.pcap_id = Params&&Params.pcap_id?Params.pcap_id:0;
    this.req_date = Params&&Params.req_date?Params.req_date:null;
    this.res_time = Params&&Params.res_time?Params.res_time:null;
    this.eth_src = Params&&Params.eth_src?Params.eth_src:"";
    this.eth_dst = Params&&Params.eth_dst?Params.eth_dst:"";
    this.eth_type = Params&&Params.eth_type?Params.eth_type:"";


    this.ip_proto = Params&&Params.ip_proto?Params.ip_proto:"";
    this.ip_version = Params&&Params.ip_version?Params.ip_version:"";
    this.ip_src = Params&&Params.ip_src?Params.ip_src:"";
    this.ip_dst = Params&&Params.ip_dst?Params.ip_dst:"";

    this.src_port = Params&&Params.src_port?Params.src_port:"";
    this.dst_port = Params&&Params.dst_port?Params.dst_port:"";
    this.tcp_stream = Params&&Params.tcp_stream?Params.tcp_stream:"";

    this.imf_from = Params&&Params.imf_from?Params.imf_from:"";
    this.imf_to = Params&&Params.imf_to?Params.imf_to:"";
    this.imf_subject = Params&&Params.imf_subject?Params.imf_subject:"";
    this.imf_message_id = Params&&Params.imf_message_id?Params.imf_message_id:"";
    this.imf_user_agent = Params&&Params.imf_user_agent?Params.imf_user_agent:"";
    this.imf_mime_version = Params&&Params.imf_mime_version?Params.imf_mime_version:"";
    this.imf_content_type = Params&&Params.imf_content_type?Params.imf_content_type:"";
    this.imf_lang = Params&&Params.imf_lang?Params.imf_lang:"";
    this.imf_message = Params&&Params.imf_message?Params.imf_message:"";
    this.imf_attachments = Params&&Params.imf_attachments?Params.imf_attachments:"[]";

    this.mime_multipart_type = Params&&Params.mime_multipart_type?Params.mime_multipart_type:"";
    this.mime_multipart_content_type = Params&&Params.mime_multipart_content_type?Params.mime_multipart_content_type:"";
    this.mime_multipart_content_encoding = Params&&Params.mime_multipart_content_encoding?Params.mime_multipart_content_encoding:"";
    this.mime_multipart_content_disposition = Params&&Params.mime_multipart_content_disposition?Params.mime_multipart_content_disposition:"";

    this.available_file = Params&&Params.available_file?Params.available_file:"";

    this.valid = true;
};

function TrafficDecodeMailManager ()
{
    this.Active = true;

    this.Rows = [];
    this.RowsFilter = {};
    this.RowsFilter.Paging = {
        size: 100,
        group: 5,
        count:0,
        page:0
    };
    this.RowsFilter.Total = {
        size: 100,
        group: 5,
        count:0,
        page:0
    };
    this.RowsFilter.Filter = {
        pcap_ids:null,
        full_text_search: null,
        req_date: null
    };
    this.RowsFilter.Sort = {
        order_by:"req_date",
        order_dir:"desc"
    };

    this.SignalRowsUpdated = new Rx.BehaviorSubject(0);
};

TrafficDecodeMailManager.prototype.Destroy = function(Params)
{
    this.Active = false;
};

TrafficDecodeMailManager.prototype.RefreshRows = function(Params)
{
    var _this = this;

    this.CurrentTasksRequestId = webix.uid();

    if(!_this.Active)
    {
        return;
    }

    if(Params&&Params.page >= 0)
    {
        this.RowsFilter.Total.page = parseInt(Params.page,10);
    }

    if(Params&&Params.size&&Params.size >= 0)
    {
        this.RowsFilter.Total.size = parseInt(Params.size,10);
    }

    if(Params&&Params.filter)
    {
        this.RowsFilter.Filter = webix.extend(this.RowsFilter,Params.filter, true);
    }

    if(Params&&Params.sort)
    {
        this.RowsFilter.Sort.order_by = Params.sort.id;
        this.RowsFilter.Sort.order_dir = Params.sort.dir;
    }

    var _params = {
        page:this.RowsFilter.Total.page,
        size:this.RowsFilter.Total.size,
        full_text_search:this.RowsFilter.Filter.full_text_search,
        req_date:this.RowsFilter.Filter.req_date,
        order_by:this.RowsFilter.Sort.order_by,
        order_dir:this.RowsFilter.Sort.order_dir,

        pcap_ids:this.RowsFilter.pcap_ids
    };

    var params =
        {
            id:this.CurrentTasksRequestId,
            method:"Sorm_GetPcapDecodeMailRows",
            data:{
                Params: _params
            },
            callback:function (R)
            {
                if(!_this.Active)
                {
                    return;
                }

                if(_this.CurrentTasksRequestId != R.id)
                {
                    return;
                }

                if(R.success)
                {
                    _this.Rows = [];
                    _this.RowsFilter.Total.page = 0;
                    _this.RowsFilter.Total.count = 0;

                    if(R.data)
                    {
                        R.data.rows.forEach(function (Row)
                        {
                            _this.Rows.push(new TrafficDecodeMailModel(Row));
                        });

                        _this.RowsFilter.Total.page = parseInt(R.data.page,10);
                        _this.RowsFilter.Total.count = parseInt(R.data.count,10);
                    }

                    _this.SignalRowsUpdated.next(1);
                }

                if(Params && Params.callback && typeof Params.callback === "function")
                {
                    Params.callback(R);
                }
            }
        };

    if(this.Active)
    {
        App.ServerManager.CallMethod(params);
    }
};

TrafficDecodeMailManager.prototype.GetRows = function()
{
    return this.Rows;
};

TrafficDecodeMailManager.prototype.PrepareMailDownloadLink = function(Params)
{
    var _this = this;

    this.CurrentTasksRequestId = webix.uid();

    var params =
        {
            id:this.CurrentTasksRequestId,
            method:"Sorm_PrepareMailFileLink",
            data:{
                Model: Params && Params.model ? Params.model : null
            },
            callback:function (R)
            {
                if(!_this.Active)
                {
                    return;
                }

                if(_this.CurrentTasksRequestId != R.id)
                {
                    return;
                }

                if(Params && Params.callback && typeof Params.callback === "function")
                {
                    Params.callback(R);
                }
            }
        };

    if(this.Active)
    {
        App.ServerManager.CallMethod(params);
    }
};

TrafficDecodeMailManager.prototype.DownloadAttachment = function (File)
{
    var _this = this;

    if(!_this.Active)
    {
        return;
    }

    var params =
        {
            method:"Sorm_DownloadPcapDecodeEmailAttachmentFile",
            data:{File:File},
            callback:function (R)
            {
                if(!_this.Active)
                {
                    return;
                }

                if(R.success)
                {
                    App.ServerManager.RedirectToFileDownload(R.data);
                }
                else
                {
                    webix.message({type:"error", text:R.error.msg});
                }
            }
        };

    if(this.Active)
    {
        App.ServerManager.CallMethod(params);
    }
};
function TrafficDecodeTaskModel(Params)
{
    var __this = this;

    this.id = Params&&Params.id?Params.id:webix.uid();

    this.task_id = Params&&Params.task_id?Params.task_id:0;
    this.title = Params&&Params.title?Params.title:null;
    this.description = Params&&Params.description?Params.description:null;
    this.user_id = Params&&Params.user_id?Params.user_id:0;
    this.deleted = Params&&Params.deleted?Params.deleted:0;

    this.valid = true;
};

function TrafficDecodeFileModel(Params)
{
    var __this = this;

    this.id = Params&&Params.id?Params.id:webix.uid();

    this.task_id = Params&&Params.task_id?Params.task_id:0;
    this.pcap_id = Params&&Params.pcap_id?Params.pcap_id:0;
    this.title = Params&&Params.title?Params.title:null;
    this.description = Params&&Params.description?Params.description:null;
    this.deleted = Params&&Params.deleted?Params.deleted:0;
    this.file_name = Params&&Params.file_name?Params.file_name:null;
    this.status = Params&&Params.status?Params.status:"new";
    this.error_description = Params&&Params.error_description?Params.error_description:"";
    this.decode_start_at = Params&&Params.decode_start_at?Params.decode_start_at:null;
    this.decode_finish_at = Params&&Params.decode_finish_at?Params.decode_finish_at:null;
    this.decode_types = Params&&Params.decode_types?Params.decode_types:null;

    this.valid = true;
};

function TrafficDecodeTaskFormModel(Params)
{
    this.title = Params&&Params.title?Params.title:null;
    this.description = Params&&Params.description?Params.description:null;
};

function TrafficDecodeFileFormModel(Params)
{
    this.title = Params&&Params.title?Params.title:null;
    this.description = Params&&Params.description?Params.description:null;
    this.file_name = Params && Params.file_name ? Params.file_name : null;
    this.decode_types = Params&&Params.decode_types?Params.decode_types:null;

    if(!this.decode_types)
    {
        var arr = [];
        Object.keys(TrafficDecodeTypesDic).forEach(function(Key){
            arr.push(Key);
        });

        this.decode_types = arr.join(",");
    }
};



function TrafficDecodeManager ()
{
    this.Active = true;

    this.Tasks = [];
    this.TasksFilter = {};
    this.TasksFilter.Paging = {
        size: 100,
        group: 5,
        count:0,
        page:0
    };
    this.TasksFilter.Total = {
        size: 100,
        group: 5,
        count:0,
        page:0
    };
    this.TasksFilter.Filter = {
        title:null,
        user_id:null,
        deleted: 0,
        task_id: null
    };
    this.TasksFilter.Sort = {
        order_by:"task_id",
        order_dir:"desc"
    };

    this.SignalTasksUpdated = new Rx.BehaviorSubject(0);
    this.SignalTaskUpdated = new Rx.BehaviorSubject(0);

    this.CurrentTasksRequestId = 0;

    this.Files = [];
    this.FilesFilter = {};
    this.FilesFilter.Paging = {
        size: 100,
        group: 5,
        count:0,
        page:0
    };
    this.FilesFilter.Total = {
        size: 100,
        group: 5,
        count:0,
        page:0
    };
    this.FilesFilter.Filter = {
        title:null,
        user_id:null,
        deleted: 0,
        status: null,
        decode_start_at: null,
        decode_finish_at: null,
        pcap_id: null
    };
    this.FilesFilter.Sort = {
        order_by:"pcap_id",
        order_dir:"desc"
    };

    this.SignalFilesUpdated = new Rx.BehaviorSubject(0);
    this.SignalFileUpdated = new Rx.BehaviorSubject(0);

    this.CurrentFileRequestId = 0;

};

TrafficDecodeManager.prototype.Destroy = function(Params)
{
    this.Active = false;
};

TrafficDecodeManager.prototype.RefershTasks = function(Params)
{
    var _this = this;

    var partial = Params&&Params.tasks_ids ? true : false;

    if(!partial)
        this.CurrentTasksRequestId = webix.uid();

    if(!_this.Active)
    {
        return;
    }

    if(Params&&Params.page >= 0)
    {
        this.TasksFilter.Total.page = parseInt(Params.page,10);
    }

    if(Params&&Params.size&&Params.size >= 0)
    {
        this.TasksFilter.Total.size = parseInt(Params.size,10);
    }

    if(Params&&Params.filter)
    {
        this.TasksFilter.Filter = webix.extend(this.TasksFilter,Params.filter, true);
    }

    if(Params&&Params.sort)
    {
        this.TasksFilter.Sort.order_by = Params.sort.id;
        this.TasksFilter.Sort.order_dir = Params.sort.dir;
    }

    var partial = Params&&Params.tasks_ids ? true : false;

    var _params = {
        page:this.TasksFilter.Total.page,
        size:this.TasksFilter.Total.size,
        title:this.TasksFilter.Filter.title,
        deleted:this.TasksFilter.Filter.deleted,
        user_id:this.TasksFilter.Filter.user_id,
        task_id_like: this.TasksFilter.Filter.task_id,
        order_by:this.TasksFilter.Sort.order_by,
        order_dir:this.TasksFilter.Sort.order_dir,

        tasks_ids:partial?Params&&Params.tasks_ids:null
    };


    var params =
        {
            id:this.CurrentTasksRequestId,
            method:"Sorm_GetPcapDecodeTasks",
            data:{
                Params: _params
            },
            callback:function (R)
            {
                if(!_this.Active)
                {
                    return;
                }

                if(!partial && _this.CurrentTasksRequestId != R.id)
                {
                    return;
                }

                if(R.success)
                {
                    if(partial)
                    {
                        if(R.data)
                        {
                            R.data.rows.forEach(function (Task)
                            {
                                _this.SignalTaskUpdated.next(new TrafficDecodeTaskModel(Task));
                            });
                        }
                    }
                    else
                    {
                        _this.Tasks = [];
                        _this.TasksFilter.Total.page = 0;
                        _this.TasksFilter.Total.count = 0;

                        if(R.data)
                        {
                            R.data.rows.forEach(function (Task)
                            {
                                _this.Tasks.push(new TrafficDecodeTaskModel(Task));
                            });

                            _this.TasksFilter.Total.page = parseInt(R.data.page,10);
                            _this.TasksFilter.Total.count = parseInt(R.data.count,10);
                        }

                        _this.SignalTasksUpdated.next(1);
                    }
                }

                if(Params && Params.callback && typeof Params.callback === "function")
                {
                    Params.callback(R);
                }
            }
        };

    if(this.Active)
    {
        App.ServerManager.CallMethod(params);
    }
};

TrafficDecodeManager.prototype.GetTasks = function()
{
    return this.Tasks;
};

TrafficDecodeManager.prototype.SaveTask = function(Params)
{
    var _this = this;

    var task = new TrafficDecodeTaskModel(Params&&Params.task?Params.task:null);
    var taskForm = new TrafficDecodeTaskFormModel(Params&&Params.task_form?Params.task_form:null);

    task = webix.extend(task, taskForm, true);

    var params =
        {
            id: task.id,
            method:"Sorm_SavePcapDecodeTask",
            data: {
                Task: task,
                Form: taskForm
            },
            callback:function (R)
            {
                if(Params && Params.callback && typeof Params.callback === "function")
                {
                    Params.callback(R);
                }
            }
        };

    App.ServerManager.CallMethod(params);
};

TrafficDecodeManager.prototype.DeleteTask = function(Params)
{
    var _this = this;

    var task = new TrafficDecodeTaskModel(Params&&Params.task?Params.task:null);

    var params =
        {
            id: task.id,
            method:"Sorm_DeletePcapDecodeTask",
            data: {
                Task: task
            },
            callback:function (R)
            {
                if(Params && Params.callback && typeof Params.callback === "function")
                {
                    Params.callback(R);
                }
            }
        };

    App.ServerManager.CallMethod(params);
};

TrafficDecodeManager.prototype.RefreshFiles = function(Params)
{
    var _this = this;

    var partial = Params&&Params.file_id ? true : false;

    if(!partial)
        this.CurrentFileRequestId = webix.uid();

    if(!_this.Active)
    {
        return;
    }

    if(Params&&Params.page >= 0)
    {
        this.FilesFilter.Total.page = parseInt(Params.page,10);
    }

    if(Params&&Params.size&&Params.size >= 0)
    {
        this.FilesFilter.Total.size = parseInt(Params.size,10);
    }

    if(Params&&Params.filter)
    {
        this.FilesFilter.Filter = webix.extend(this.FilesFilter,Params.filter, true);
    }

    if(Params&&Params.sort)
    {
        this.FilesFilter.Sort.order_by = Params.sort.id;
        this.FilesFilter.Sort.order_dir = Params.sort.dir;
    }

    var _params = {
        page:this.FilesFilter.Total.page,
        size:this.FilesFilter.Total.size,
        title:this.FilesFilter.Filter.title,
        deleted:this.FilesFilter.Filter.deleted,
        decode_start_at:this.FilesFilter.Filter.decode_start_at,
        decode_finish_at:this.FilesFilter.Filter.decode_finish_at,
        status:this.FilesFilter.Filter.status,
        task_id:this.FilesFilter.Filter.task_id,
        pcap_id_like: this.FilesFilter.Filter.pcap_id,
        order_by:this.FilesFilter.Sort.order_by,
        order_dir:this.FilesFilter.Sort.order_dir,

        pcap_ids:partial?Params&&Params.pcap_ids:null
    };


    var params =
        {
            id:this.CurrentFileRequestId,
            method:"Sorm_GetPcapDecodeFiles",
            data:{
                Params: _params
            },
            callback:function (R)
            {
                if(!_this.Active)
                {
                    return;
                }

                if(!partial && _this.CurrentFileRequestId !== R.id)
                {
                    return;
                }

                if(R.success)
                {
                    if(partial)
                    {
                        if(R.data)
                        {
                            R.data.rows.forEach(function (Task)
                            {
                                _this.SignalFileUpdated.next(new TrafficDecodeFileModel(Task));
                            });
                        }
                    }
                    else
                    {
                        _this.Files = [];
                        _this.FilesFilter.Total.page = 0;
                        _this.FilesFilter.Total.count = 0;

                        if(R.data)
                        {
                            R.data.rows.forEach(function (Task)
                            {
                                _this.Files.push(new TrafficDecodeFileModel(Task));
                            });

                            _this.FilesFilter.Total.page = parseInt(R.data.page,10);
                            _this.FilesFilter.Total.count = parseInt(R.data.count,10);
                        }

                        _this.SignalFilesUpdated.next(1);
                    }
                }

                if(Params && Params.callback && typeof Params.callback === "function")
                {
                    Params.callback(R);
                }
            }
        };

    if(this.Active)
    {
        App.ServerManager.CallMethod(params);
    }
};

TrafficDecodeManager.prototype.GetFiles = function()
{
    return this.Files;
};

TrafficDecodeManager.prototype.SaveFile = function(Params)
{
    var _this = this;

    var file = new TrafficDecodeFileModel(Params&&Params.file?Params.file:null);
    var fileForm = new TrafficDecodeFileFormModel(Params&&Params.file_form?Params.file_form:null);

    var params =
        {
            id: file.id,
            method:"Sorm_SavePcapDecodeFile",
            data: {
                File: file,
                Form: fileForm
            },
            callback:function (R)
            {
                if(Params && Params.callback && typeof Params.callback === "function")
                {
                    Params.callback(R);
                }
            }
        };

    App.ServerManager.CallMethod(params);
};

TrafficDecodeManager.prototype.DeleteFile = function(Params)
{
    var _this = this;

    var file = new TrafficDecodeFileModel(Params&&Params.file?Params.file:null);

    var params =
        {
            id: file.id,
            method:"Sorm_DeletePcapDecodeFile",
            data: {
                File: file
            },
            callback:function (R)
            {
                if(Params && Params.callback && typeof Params.callback === "function")
                {
                    Params.callback(R);
                }
            }
        };

    App.ServerManager.CallMethod(params);
};

TrafficDecodeManager.prototype.GeneratePcapFileUploadId = function(Params)
{
    var _this = this;

    var fileName = Params && Params.fileName ? Params.fileName : null;
    var path = Params && Params.path ? Params.path : null;

    var params =
        {
            id: webix.uid(),
            method:"Sorm_GeneratePcapFileUploadId",
            data: {
                File: fileName,
                Path: path
            },
            callback:function (R)
            {
                if(Params && Params.callback && typeof Params.callback === "function")
                {
                    Params.callback(R);
                }
            }
        };

    App.ServerManager.CallMethod(params);
};

TrafficDecodeManager.prototype.DeleteLocalFile = function(Params)
{
    var _this = this;

    var fileModel = new TrafficDecodeFileFormModel(Params && Params.file ? Params.file : null);

    var taskId = parseInt(Params && Params.taskId ? Params.taskId : 0);

    var params =
        {
            id: webix.uid(),
            method:"Sorm_DeleteLocalPcapFile",
            data: {
                File: fileModel,
                TaskId: taskId
            },
            callback:function (R)
            {
                if(Params && Params.callback && typeof Params.callback === "function")
                {
                    Params.callback(R);
                }
            }
        };

    App.ServerManager.CallMethod(params);
};

TrafficDecodeManager.prototype.CheckPcapDecodeHardwreAvailability = function(Params)
{
    var _this = this;

    var fileModel = new TrafficDecodeFileFormModel(Params && Params.file ? Params.file : null);

    var taskId = parseInt(Params && Params.taskId ? Params.taskId : 0);

    var params =
        {
            id: webix.uid(),
            method:"Sorm_CheckPcapDecodeHardwreAvailability",
            callback:function (R)
            {
                if(R && !R.success)
                {
                    webix.alert({
                        title: LangHelper.GetString("All", "Error"),
                        text: R.error.msg,
                        type:"alert-error",
                        width:600
                    });
                }

                if(Params && Params.callback && typeof Params.callback === "function")
                {
                    Params.callback(R);
                }
            }
        };

    App.ServerManager.CallMethod(params);
};

TrafficDecodeManager.prototype.ResetPcapDecodeQueue = function(Params)
{
    var _this = this;

    var file = new TrafficDecodeFileModel(Params&&Params.file?Params.file:null);

    var params =
        {
            id: file.id,
            method:"Sorm_ResetPcapDecodeQueue",
            data: {
                File: file
            },
            callback:function (R)
            {
                if(Params && Params.callback && typeof Params.callback === "function")
                {
                    Params.callback(R);
                }
            }
        };

    App.ServerManager.CallMethod(params);
};

TrafficDecodeManager.prototype.DownloadPcapFile = function (File)
{
    var _this = this;

    if(!_this.Active)
    {
        return;
    }

    var params =
        {
            method:"Sorm_DownloadPcapDecodePcapFile",
            data:{Pcap:File},
            callback:function (R)
            {
                if(!_this.Active)
                {
                    return;
                }

                if(R.success)
                {
                    App.ServerManager.RedirectToFileDownload(R.data);
                }
                else
                {
                    webix.message({type:"error", text:R.error.msg});
                }
            }
        };

    if(this.Active)
    {
        App.ServerManager.CallMethod(params);
    }
};

var TrafficDecodeLogsItemTypeDic = {
    "Task": {id: "Task", value: LangHelper.GetString("TrafficDecode", "Task")},
    "Pcap": {id: "Pcap", value: LangHelper.GetString("TrafficDecode", "Pcap")},
};

var TrafficDecodeLogStatusesDic = {
    "new": {id: "new", value: LangHelper.GetString("TrafficDecode", "New")},
    "processing": {id: "processing", value: LangHelper.GetString("TrafficDecode", "Processing")},
    "success": {id: "success", value: LangHelper.GetString("TrafficDecode", "Success")},
    "error": {id: "error", value: LangHelper.GetString("TrafficDecode", "Error")},
    "skipped": {id: "skipped", value: LangHelper.GetString("TrafficDecode", "Skipped")}
};

var TrafficDecodeTypesDic = {
    "Web": {id: "Web", value: LangHelper.GetString("TrafficDecode", "Web")},
    "Dns": {id: "Dns", value: LangHelper.GetString("TrafficDecode", "Dns")},
    "Mail": {id: "Mail", value: LangHelper.GetString("TrafficDecode", "Mail")},
    "Voip": {id: "Voip", value: LangHelper.GetString("TrafficDecode", "Voip")},
    "Ftp": {id: "Ftp", value: LangHelper.GetString("TrafficDecode", "Ftp")},
};

function TrafficDecodeLogModel(Params)
{
    var __this = this;

    this.id = Params&&Params.id?Params.id:webix.uid();

    this._id = Params&&Params._id?Params._id:0;
    this.row_id = Params&&Params.row_id?Params.row_id:0;
    this.item_type = Params&&Params.item_type?Params.item_type:TrafficDecodeLogsItemTypeDic.Task.id;
    this.item_action = Params&&Params.item_action?Params.item_action:null;
    this.status = Params&&Params.status?Params.status:TrafficDecodeLogStatusesDic.new.id;
    this.status_description = Params&&Params.status_description?Params.status_description:"";
    this.attempts = Params&&Params.attempts?Params.attempts:0;
    this.data = Params&&Params.data?Params.data:"{}";
    this.update_time = Params&&Params.update_time?Params.update_time:null;
    this.decode_type = Params&&Params.decode_type?Params.decode_type:null;

    this.valid = true;
};

function TrafficDecodeLogsManager ()
{
    this.Active = true;

    this.Rows = [];
    this.RowsFilter = {};
    this.RowsFilter.Paging = {
        size: 100,
        group: 5,
        count:0,
        page:0
    };
    this.RowsFilter.Total = {
        size: 100,
        group: 5,
        count:0,
        page:0
    };
    this.RowsFilter.Filter = {
        row_id:null,
        update_time: null,
        item_type: null,
        item_action: null,
        status: null,
        status_description: null
    };
    this.RowsFilter.Sort = {
        order_by:"update_time",
        order_dir:"desc"
    };

    this.SignalRowsUpdated = new Rx.BehaviorSubject(0);
};

TrafficDecodeLogsManager.prototype.Destroy = function(Params)
{
    this.Active = false;
};

TrafficDecodeLogsManager.prototype.RefreshRows = function(Params)
{
    var _this = this;

    this.CurrentTasksRequestId = webix.uid();

    if(!_this.Active)
    {
        return;
    }

    if(Params&&Params.page >= 0)
    {
        this.RowsFilter.Total.page = parseInt(Params.page,10);
    }

    if(Params&&Params.size&&Params.size >= 0)
    {
        this.RowsFilter.Total.size = parseInt(Params.size,10);
    }

    if(Params&&Params.sort)
    {
        this.RowsFilter.Sort.order_by = Params.sort.id;
        this.RowsFilter.Sort.order_dir = Params.sort.dir;
    }

    var _params = {
        page:this.RowsFilter.Total.page,
        size:this.RowsFilter.Total.size,
        order_by:this.RowsFilter.Sort.order_by,
        order_dir:this.RowsFilter.Sort.order_dir,
        filter: {}
    };

    Object.keys(Params.filter).forEach(function(Key){
        if(Key && Key != "id" && Params.filter[Key])
            _params.filter[Key] = Params.filter[Key];
    });

    var params =
        {
            id:this.CurrentTasksRequestId,
            method:"Sorm_GetPcapDecodeLogs",
            data:{
                Params: _params
            },
            callback:function (R)
            {
                if(!_this.Active)
                {
                    return;
                }

                if(_this.CurrentTasksRequestId != R.id)
                {
                    return;
                }

                if(R.success)
                {
                    _this.Rows = [];
                    _this.RowsFilter.Total.page = 0;
                    _this.RowsFilter.Total.count = 0;

                    if(R.data)
                    {
                        R.data.rows.forEach(function (Row)
                        {
                            _this.Rows.push(new TrafficDecodeLogModel(Row));
                        });

                        _this.RowsFilter.Total.page = parseInt(R.data.page,10);
                        _this.RowsFilter.Total.count = parseInt(R.data.count,10);
                    }

                    _this.SignalRowsUpdated.next(1);
                }

                if(Params && Params.callback && typeof Params.callback === "function")
                {
                    Params.callback(R);
                }
            }
        };

    if(this.Active)
    {
        App.ServerManager.CallMethod(params);
    }
};

TrafficDecodeLogsManager.prototype.GetRows = function()
{
    return this.Rows;
};

TrafficDecodeLogsManager.prototype.DeleteRow = function(Params)
{
    var _this = this;

    var row = new TrafficDecodeLogModel(Params&&Params.row?Params.row:null);

    var params =
        {
            id: row.id,
            method:"Sorm_DeletePcapDecodeQueueRow",
            data: {
                Row: row
            },
            callback:function (R)
            {
                if(Params && Params.callback && typeof Params.callback === "function")
                {
                    Params.callback(R);
                }
            }
        };

    App.ServerManager.CallMethod(params);
};

//https://docs.webix.com/samples/60_pro/11_treemap/02_colors.html
//view-source:https://docs.webix.com/samples/60_pro/11_treemap/02_colors.html
//https://docs.webix.com/samples/60_pro/11_treemap/data/data_colors.json
function SormHealthViewController(Params)
{
    var _this = this;

    this.Id = "SormHealth";

    this.ViewModel = new SormHealthManager();

    this.ViewConfig = [
        {
            cols:[
                SormSideBarView({id: this.Id}),
                {
                    type: "space",
                    rows: [
                        {
                            type: "clear",
                            borderless: true,
                            rows: [
                                PageHeaderTemplate({root_title:LangHelper.GetString("AppView", "Lawful interception"), title: LangHelper.GetString("AppView","Health map")}),
                                {
                                    view: "toolbar",
                                    height: 32,
                                    padding: 0,
                                    margin: 0,
                                    elements: [
                                        {},
                                        {
                                            view: "button",
                                            id:this.Id+"RefreshBtn",
                                            type: "icon",
                                            icon: "refresh",
                                            css: "toolbar_round_btn",
                                            width: 32,
                                            tooltip: LangHelper.GetString("All","Refresh"),
                                            click: function (id, e, node)
                                            {
                                                _this.Refresh();
                                            }
                                        }
                                    ]
                                },
                                {
                                    view: "treemap",
                                    id:this.Id + "Treemap",
                                    //select: true,
                                    type:{
                                        cssClass: function(item)
                                        {
                                            return item.css || "";
                                        },
                                        template: function (item)
                                        {
                                            return (item.label || "") + "<br/>" + (item.info || "");
                                        }
                                    },
                                    value: "#value#",
                                    data: []
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    ];

    this.Views = {
        Treemap:null
    };

    this.HealthMap = {
        asn_server:null,
        dpi_server:null,
        localhost:null,
        gui_db:null,
        wget:null,
        nmap:null,
        tragen:null,
        //fop:null,
        cron:null,
        supervisor:null,
        tcpdump:null
    };

    this.Subscriptions = {};
};

SormHealthViewController.prototype.Destroy = function ()
{
    var _this = this;

    Object.keys(this.Subscriptions).forEach(function (key)
    {
        _this.Subscriptions[key].unsubscribe();
    });

    this.ViewModel.Destroy();
    delete this.ViewModel;
};

SormHealthViewController.prototype.ViewDidLoad = function (Params)
{
    var _this = this;

    webix.ready(function () {
        $$(_this.Id + "LeftSideBar").select(_this.Id);
    });

    this.BindAll();
};

SormHealthViewController.prototype.BindAll = function (Params)
{
    var _this = this;

    this.Views.Treemap = $$(this.Id + "Treemap");

    this.Subscriptions.SignalHealthUpdatedSubscription = this.ViewModel.HealthUpdated.subscribe(function (Updated)
    {
        if (Updated)
        {
            _this.UpdateHealthMap();
        }
    });

    this.UpdateHealthMap();

    this.Refresh();
};

SormHealthViewController.prototype.Refresh = function (Params)
{
    this.RefreshGui();

    this.RefreshServers();

    this.RefreshUtils();
};

SormHealthViewController.prototype.RefreshGui = function (Params)
{
    this.ViewModel.Refresh({localhost:1, gui_db: 1, cron: 1, supervisor: 1});
};

SormHealthViewController.prototype.RefreshServers = function (Params)
{
    this.ViewModel.Refresh({asn_server:1, dpi_server: 1, tragen: 1});
};

SormHealthViewController.prototype.RefreshUtils = function (Params)
{
    //this.ViewModel.Refresh({wget:1, nmap: 1, fop: 1, tcpdump: 1});
    this.ViewModel.Refresh({wget:1, nmap: 1, tcpdump: 1});
};

SormHealthViewController.prototype.UpdateHealthMap = function (Params)
{
    var _this = this;

    var health = this.ViewModel.GetHealth();

    var _getCss = function (Key)
    {
        return health[Key] ? (health[Key].success ? "health_ok" : "health_fail") : "";
    };

    var _getInfo = function (Key)
    {
        if(!health[Key])
            return "";

        var res = "";

        if(health[Key].success)
        {
            res += LangHelper.GetString(_this.Id, "[INFO]") + ": ";

            if(health[Key].data)
            {
                if(health[Key].data.status)
                {
                    res += health[Key].data.status;
                }
                else
                {
                    res += health[Key].data;
                }
            }
            else
            {
                res += LangHelper.GetString(_this.Id, "success");
            }
        }
        else
        {
            res += LangHelper.GetString(_this.Id, "[ERROR]") + "["+health[Key].error.code+"]: "+health[Key].error.msg;
        }

        return res;
    };

    var gui =
        {
            id: "1", data: [
                {
                    id: "1.1",
                    css:_getCss("localhost"),
                    label: LangHelper.GetString(this.Id, "Localhost"),
                    info:_getInfo("localhost"),
                    value: "40"
                },
                {
                    id: "1.2",
                    css:_getCss("gui_db"),
                    label: LangHelper.GetString(this.Id, "GUI Db"),
                    info:_getInfo("gui_db"),
                    value: "20"
                },
                {
                    id: "1.3",
                    css:_getCss("supervisor"),
                    label: LangHelper.GetString(this.Id, "Supervisor"),
                    info:_getInfo("supervisor"),
                    value: "20"
                },
                {
                    id: "1.4",
                    css:_getCss("cron"),
                    label: LangHelper.GetString(this.Id, "Cronie"),
                    info:_getInfo("cron"),
                    value: "20"
                }
            ]
        };

    var servers =
        {
            id: "2", data: [
            {
                id: "2.1",
                css:_getCss("asn_server"),
                label: LangHelper.GetString(this.Id, "ASN API"),
                info:_getInfo("asn_server"),
                value: "35"
            },
            {
                id: "2.2",
                css:_getCss("dpi_server"),
                label: LangHelper.GetString(this.Id, "CKAT DPI"),
                info:_getInfo("dpi_server"),
                value: "35"
            },
            {
                id: "2.3",
                css:_getCss("tragen"),
                label: LangHelper.GetString(this.Id, "Tragen"),
                info:_getInfo("tragen"),
                value: "30"
            }
        ]
        };

    var utils =
        {
            id: "3", data: [
            {
                id: "3.1",
                css:_getCss("wget"),
                label: LangHelper.GetString(this.Id, "Site clone (wget)"),
                info:_getInfo("wget"),
                value: "25"
            },
            {
                id: "3.2",
                css:_getCss("nmap"),
                label: LangHelper.GetString(this.Id, "Network audit (nmap)"),
                info:_getInfo("nmap"),
                value: "25"
            },
            // {
            //     id: "3.3",
            //     css:_getCss("fop"),
            //     label: LangHelper.GetString(this.Id, "Pdf maker (fop)"),
            //     info:_getInfo("fop"),
            //     value: "25"
            // },
            {
                id: "3.4",
                css:_getCss("tcpdump"),
                label: LangHelper.GetString(this.Id, "Tcpdump"),
                info:_getInfo("tcpdump"),
                value: "25"
            }
        ]
        };

    this.Views.Treemap.clearAll();
    this.Views.Treemap.parse([utils, servers, gui]);
};
function ConnectionsValidateDataViewController(Params)
{

    var _this = this;

    this.Id = "ConnectionsValidateData";

    this.ViewModel = new ConnectionsValidateDataManager();

    this.Health = new SormHealthManager();

    this.CreatTaskContext = null;
    this.CreateTaskContextFormConfig = {
        view:"popup",
        id:this.Id + "CreatTaskContext",
        width:800,
        height:720,
        modal:true,
        body:{
            rows:[
                {
                    id:this.Id + "CreateTaskContextForm",
                    view: "form",
                    margin: 0,
                    padding: 0,
                    elements: [
                        BlockHeaderTemplate({title:LangHelper.GetString(this.Id, "Connections search request"), icon:"file"}),
                        {
                            cols: [
                                {
                                    view: "text",
                                    name: "title",
                                    label: LangHelper.GetString(this.Id, "Description"),
                                    labelPosition: "left",
                                    labelWidth:200
                                }
                            ]
                        },
                        {
                            cols: [
                                {
                                    view:"daterangepicker",
                                    name:"date",
                                    label:LangHelper.GetString(this.Id, "Period"),
                                    labelPosition: "left",
                                    labelWidth:200,
                                    timepicker:true
                                }
                            ]
                        },
                        {
                            cols:[
                                {
                                    view:"multiselect",
                                    name:"telcos",
                                    label:LangHelper.GetString(_this.Id,"Operators"),
                                    tooltip:LangHelper.GetString(_this.Id,"Communication operators"),
                                    options:App.DicsManager.GetDicAsArray(DicsEnum.TelcosDic),
                                    labelWidth:200
                                }
                            ]
                        },
                        {
                            cols: [
                                {
                                    view: "richselect",
                                    name: "total_limit",
                                    label: LangHelper.GetString(this.Id, "Max number of entries"),
                                    labelPosition: "left",
                                    labelWidth:200,
                                    options:[
                                        { "id":2000, "value":"2 000"},
                                        { "id":10000, "value":"10 000"},
                                        { "id":20000, "value":"20 000"},
                                        { "id":50000, "value":"50 000"},
                                        { "id":100000, "value":"100 000"},
                                        { "id":200000, "value":"200 000"},
                                        { "id":500000, "value":"500 000"}
                                    ],
                                    value:2000,
                                    placeholder:LangHelper.GetString(this.Id, "Max number of records in query results")
                                }
                            ]
                        }
                    ]
                },
                {
                    cols:[
                        {
                            rows:[
                                BlockHeaderTemplate({title:LangHelper.GetString(this.Id, "Resources and services"), icon:"file"}),
                                {
                                    id: this.Id + "CreateTaskContextForm" + "Tabbar",
                                    view: "datatable",
                                    header:false,
                                    select: "row",
                                    checkboxRefresh:true,
                                    width:200,
                                    on: {
                                        onCheck:function(rowId, colId, state)
                                        {
                                            var form = _this.Views.CreateTaskContextForms[rowId];

                                            if(form)
                                            {
                                                form.elements.enabled.setValue(state);
                                            }
                                        },
                                        onAfterSelect: function (data, preserve)
                                        {
                                            var tabId = _this.Id + "CreateTaskContextForm" + "_" + data.id + "_" + "tab";

                                            if($$(tabId))
                                            {
                                                $$(tabId).show();
                                            }
                                        }
                                    },
                                    columns: [
                                        {
                                            id: "enabled",
                                            template:CellCheckBoxTemplate,
                                            width:32
                                        },
                                        {
                                            id: "value",
                                            fillspace: true
                                        }
                                    ],
                                    data: [
                                        {id:"aaa", value:LangHelper.GetString(this.Id, "Aaa subscribers sessions"), enabled:0, valid:1},
                                        {id:"http", value:"Http", enabled:0, valid:1},
                                        {id:"ftp", value:"Ftp", enabled:0, valid:1},
                                        {id:"mail", value:"E-mail", enabled:0, valid:1},
                                        {id:"im", value:"Im", enabled:0, valid:1},
                                        {id:"voip", value:"Voip", enabled:0, valid:1},
                                        {id:"pstn", value:"Pstn", enabled:0, valid:1},
                                        {id:"term", value:LangHelper.GetString(this.Id, LangHelper.GetString(this.Id, "Terminal sessions")), enabled:0, valid:1},
                                        {id:"raw_flows", value:LangHelper.GetString(this.Id, "Flows"), enabled:0, valid:1}
                                    ],
                                    rules:{
                                        "valid":function (value)
                                        {
                                            return value;
                                        }
                                    }
                                }
                            ]
                        },
                        {
                            view:"multiview",
                            id: this.Id + "CreateTaskContextForm" + "Tabs",
                            animate:true,
                            cells:[
                                {
                                    id:this.Id + "CreateTaskContextForm" + "_" + "aaa" + "_" + "tab",
                                    rows:[
                                        BlockHeaderTemplate({title:LangHelper.GetString(this.Id, "Aaa subscribers sessions"), icon:"file"}),
                                        {
                                            id:this.Id + "CreateTaskContextForm" + "_" + "aaa",
                                            view: "form",
                                            margin: 0,
                                            padding: 8,
                                            scroll:"y",
                                            elements:[
                                                {
                                                    view: "checkbox",
                                                    name: "enabled",
                                                    labelRight: LangHelper.GetString(this.Id, "Enable search"),
                                                    value:0,
                                                    labelWidth:0,
                                                    on: {
                                                        onChange:function()
                                                        {
                                                            _this.SetCreateTaskContextFormTabbarCheckboxes();
                                                        }
                                                    },
                                                    validate:function(Val)
                                                    {
                                                        if(!Val)
                                                            return true;

                                                        return _this.ValidateFormOneParameterChecked(this);
                                                    },
                                                    invalidMessage: LangHelper.GetString(this.Id,"You must enable at least one query parameter")
                                                },
                                                {
                                                    rows:[
                                                        {
                                                            view: "checkbox",
                                                            name: "point_id_enabled",
                                                            labelRight:LangHelper.GetString(this.Id, "Connection point"),
                                                            tooltip:LangHelper.GetString(this.Id, "Connection point identifier to the data network"),
                                                            value:0,
                                                            labelWidth:0
                                                        },
                                                        {
                                                            view: "text",
                                                            name: "point_id",
                                                            placeholder:LangHelper.GetString(this.Id, "Connection point"),
                                                            tooltip:LangHelper.GetString(this.Id, "Connection point identifier to the data network"),
                                                            validate:function(Val)
                                                            {
                                                                if(!this.elements.enabled.getValue())
                                                                    return true;

                                                                if(!this.elements.point_id_enabled.getValue())
                                                                    return true;

                                                                return !isNaN(Val*1);
                                                            },
                                                            invalidMessage: LangHelper.GetString("Dics","Enter the number"),
                                                            suggest: {
                                                                data:App.DicsManager.GetDicAsArray(DicsEnum.IpDataPointsDic),
                                                                template:"#id#",
                                                                filter:function(obj, value)
                                                                {
                                                                    return obj.value.indexOf(value) >= 0;
                                                                }
                                                            },
                                                            keyPressTimeout:500,
                                                            on:{
                                                                onTimedKeyPress: function()
                                                                {
                                                                    App.DicsManager.Refresh({data:[DicsEnum.IpDataPointsDic], filter:this.getValue()});
                                                                }
                                                            }
                                                        }
                                                    ]
                                                },
                                                {
                                                    rows:[
                                                        {
                                                            view: "checkbox",
                                                            name: "login_type_enabled",
                                                            labelRight:LangHelper.GetString(this.Id, "Connection type"),
                                                            tooltip:LangHelper.GetString(this.Id, "Connection type"),
                                                            value:0,
                                                            labelWidth:0
                                                        },
                                                        {
                                                            view: "combo",
                                                            name: "login_type",
                                                            placeholder:LangHelper.GetString(this.Id, "Connection type"),
                                                            tooltip:LangHelper.GetString(this.Id, "Connection type"),
                                                            validate:function(Val)
                                                            {
                                                                if(!this.elements.enabled.getValue())
                                                                    return true;

                                                                if(!this.elements.login_type_enabled.getValue())
                                                                    return true;

                                                                return !isNaN(Val*1);
                                                            },
                                                            invalidMessage: LangHelper.GetString("Dics","Enter the number"),
                                                            suggest:Helper.DictToArr(SormRequestConnectionTypeDic)
                                                        }
                                                    ]
                                                },
                                                {
                                                    cols:[
                                                        {
                                                            rows:[
                                                                {
                                                                    view: "checkbox",
                                                                    name: "user_name_enabled",
                                                                    labelRight:LangHelper.GetString(this.Id, "Subscriber login"),
                                                                    tooltip:LangHelper.GetString(this.Id, "Subscriber login"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "text",
                                                                    name: "user_name",
                                                                    placeholder:LangHelper.GetString(this.Id, "Subscriber login"),
                                                                    tooltip:LangHelper.GetString(this.Id, "Subscriber login")
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            rows:[
                                                                {
                                                                    view: "checkbox",
                                                                    name: "user_password_enabled",
                                                                    labelRight:LangHelper.GetString(this.Id, "Subscriber password"),
                                                                    tooltip:LangHelper.GetString(this.Id, "Subscriber password"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "text",
                                                                    name: "user_password",
                                                                    placeholder:LangHelper.GetString(this.Id, "Subscriber password"),
                                                                    tooltip:LangHelper.GetString(this.Id, "Subscriber password")
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    rows:[
                                                        {
                                                            view: "checkbox",
                                                            name: "allocated_ip_enabled",
                                                            labelRight:LangHelper.GetString(this.Id, "IP address"),
                                                            tooltip:LangHelper.GetString(this.Id, "IP address"),
                                                            value:0,
                                                            labelWidth:0
                                                        },
                                                        {
                                                            view: "text",
                                                            name: "allocated_ip",
                                                            placeholder:LangHelper.GetString(this.Id, "IP address"),
                                                            tooltip:LangHelper.GetString(this.Id, "IP address"),
                                                            validate:function(Val)
                                                            {
                                                                if(!this.elements.enabled.getValue())
                                                                    return true;

                                                                if(!this.elements.allocated_ip_enabled.getValue())
                                                                    return true;

                                                                if(Val)
                                                                    return Helper.ValidateIPaddress(Val);
                                                                else
                                                                    return true;
                                                            },
                                                            invalidMessage: LangHelper.GetString("Dics", "Enter the IP address")
                                                        }
                                                    ]
                                                },
                                                {
                                                    rows:[
                                                        {
                                                            view: "checkbox",
                                                            name: "connect_type_enabled",
                                                            labelRight:LangHelper.GetString(this.Id, "Protocol code"),
                                                            tooltip:LangHelper.GetString(this.Id, "Protocol code in accordance with RFC1700 or port number for TCP / UDP"),
                                                            value:0,
                                                            labelWidth:0
                                                        },
                                                        {
                                                            view: "text",
                                                            name: "connect_type",
                                                            placeholder:LangHelper.GetString(this.Id, "Protocol code"),
                                                            tooltip:LangHelper.GetString(this.Id, "Protocol code in accordance with RFC1700 or port number for TCP / UDP"),
                                                            validate:function(Val)
                                                            {
                                                                if(!this.elements.enabled.getValue())
                                                                    return true;

                                                                if(!this.elements.connect_type_enabled.getValue())
                                                                    return true;

                                                                return !isNaN(Val*1);
                                                            },
                                                            invalidMessage: LangHelper.GetString("Dics","Enter the number"),
                                                            suggest: {
                                                                data:App.DicsManager.GetDicAsArray(DicsEnum.PortsDic),
                                                                template:"#id#",
                                                                filter:function(obj, value)
                                                                {
                                                                    return obj.value.indexOf(value) >= 0;
                                                                }
                                                            },
                                                            keyPressTimeout:500,
                                                            on:{
                                                                onTimedKeyPress: function()
                                                                {
                                                                    App.DicsManager.Refresh({data:[DicsEnum.PortsDic], filter:this.getValue()});
                                                                }
                                                            }
                                                        }
                                                    ]
                                                },
                                                {
                                                    rows:[
                                                        {
                                                            view: "checkbox",
                                                            name: "phone_card_number_enabled",
                                                            labelRight:LangHelper.GetString(this.Id, "Phone card number"),
                                                            tooltip:LangHelper.GetString(this.Id, "Phone card number"),
                                                            value:0,
                                                            labelWidth:0
                                                        },
                                                        {
                                                            view: "text",
                                                            name: "phone_card_number",
                                                            placeholder:LangHelper.GetString(this.Id, "Phone card number"),
                                                            tooltip:LangHelper.GetString(this.Id, "Phone card number")
                                                        }
                                                    ]
                                                },
                                                {
                                                    cols:[
                                                        {
                                                            rows:[
                                                                {
                                                                    view: "checkbox",
                                                                    name: "calling_number_enabled",
                                                                    labelRight:LangHelper.GetString(this.Id, "Calling number"),
                                                                    tooltip:LangHelper.GetString(this.Id, "Calling number"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "text",
                                                                    name: "calling_number",
                                                                    placeholder:LangHelper.GetString(this.Id, "Calling number"),
                                                                    tooltip:LangHelper.GetString(this.Id, "Calling number")
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            rows:[
                                                                {
                                                                    view: "checkbox",
                                                                    name: "called_number_enabled",
                                                                    labelRight:LangHelper.GetString(this.Id, "Called number"),
                                                                    tooltip:LangHelper.GetString(this.Id, "Called number"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "text",
                                                                    name: "called_number",
                                                                    placeholder:LangHelper.GetString(this.Id, "Called number"),
                                                                    tooltip:LangHelper.GetString(this.Id, "Called number")
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    rows:[
                                                        {
                                                            view: "checkbox",
                                                            name: "service_area_code_enabled",
                                                            labelRight:LangHelper.GetString(this.Id, "Service area code (SAC)"),
                                                            tooltip:LangHelper.GetString(this.Id, "Service area code (SAC)"),
                                                            value:0,
                                                            labelWidth:0
                                                        },
                                                        {
                                                            view: "text",
                                                            name: "service_area_code",
                                                            placeholder:LangHelper.GetString(this.Id, "Service area code (SAC)"),
                                                            tooltip:LangHelper.GetString(this.Id, "Service area code (SAC)"),
                                                            validate:function(Val)
                                                            {
                                                                if(!this.elements.enabled.getValue())
                                                                    return true;

                                                                if(!this.elements.service_area_code_enabled.getValue())
                                                                    return true;

                                                                return !isNaN(Val*1);
                                                            },
                                                            invalidMessage: LangHelper.GetString("Dics","Enter the number")
                                                        }
                                                    ]
                                                },
                                                {
                                                    rows:[
                                                        {
                                                            view: "checkbox",
                                                            name: "esn_enabled",
                                                            labelRight:LangHelper.GetString(this.Id, "Mobile station ID"),
                                                            tooltip:LangHelper.GetString(this.Id, "Mobile station ID"),
                                                            value:0,
                                                            labelWidth:0
                                                        },
                                                        {
                                                            view: "text",
                                                            name: "esn",
                                                            placeholder:LangHelper.GetString(this.Id, "Mobile station ID of the subscriber"),
                                                            tooltip:LangHelper.GetString(this.Id, "Mobile station ID of the subscriber"),
                                                            validate:function(Val)
                                                            {
                                                                if(!this.elements.enabled.getValue())
                                                                    return true;

                                                                if(!this.elements.esn_enabled.getValue())
                                                                    return true;

                                                                return !isNaN(Val*1);
                                                            },
                                                            invalidMessage: LangHelper.GetString("Dics","Enter the number")
                                                        }
                                                    ]
                                                },
                                                {
                                                    rows:[
                                                        {
                                                            view: "checkbox",
                                                            name: "apn_enabled",
                                                            labelRight:LangHelper.GetString(this.Id, "Access point (APN)"),
                                                            tooltip:LangHelper.GetString(this.Id, "Access point (APN)"),
                                                            value:0,
                                                            labelWidth:0
                                                        },
                                                        {
                                                            view: "text",
                                                            name: "apn",
                                                            placeholder:LangHelper.GetString(this.Id, "Access point (APN)"),
                                                            tooltip:LangHelper.GetString(this.Id, "Access point (APN)")
                                                        }
                                                    ]
                                                },
                                                {
                                                    rows:[
                                                        {
                                                            view: "checkbox",
                                                            name: "nas_enabled",
                                                            labelRight:LangHelper.GetString(this.Id, "NAS server"),
                                                            tooltip:LangHelper.GetString(this.Id, "NAS server"),
                                                            value:0,
                                                            labelWidth:0
                                                        },
                                                        {
                                                            cols:[
                                                                {
                                                                    view: "text",
                                                                    name: "nas_ip",
                                                                    placeholder:LangHelper.GetString(this.Id, "IP address of the NAS server"),
                                                                    tooltip:LangHelper.GetString(this.Id, "IP address of the NAS server"),
                                                                    validate:function(Val)
                                                                    {
                                                                        if(!this.elements.enabled.getValue())
                                                                            return true;

                                                                        if(!this.elements.nas_enabled.getValue())
                                                                            return true;

                                                                        if(!this.elements.nas_ip.getValue() && !this.elements.nas_port.getValue())
                                                                            return false;

                                                                        if(Val)
                                                                            return Helper.ValidateIPaddress(Val);
                                                                        else
                                                                            return true;
                                                                    },
                                                                    invalidMessage: LangHelper.GetString("Dics", "Enter the IP address")
                                                                },
                                                                {
                                                                    view: "text",
                                                                    name: "nas_port",
                                                                    placeholder:LangHelper.GetString(this.Id, "NAS server port"),
                                                                    tooltip:LangHelper.GetString(this.Id, "NAS server port"),
                                                                    validate:function(Val)
                                                                    {
                                                                        if(!this.elements.enabled.getValue())
                                                                            return true;

                                                                        if(!this.elements.nas_enabled.getValue())
                                                                            return true;

                                                                        if(!this.elements.nas_ip.getValue() && !this.elements.nas_port.getValue())
                                                                            return false;

                                                                        return !isNaN(Val*1);
                                                                    },
                                                                    invalidMessage: LangHelper.GetString("Dics","Enter the number")
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    rows:[
                                                        {
                                                            view: "checkbox",
                                                            name: "sgsn_ip_enabled",
                                                            labelRight:LangHelper.GetString(this.Id, "IP address of GPRS / EDGE SGSN"),
                                                            tooltip:LangHelper.GetString(this.Id, "IP address of GPRS / EDGE SGSN"),
                                                            value:0,
                                                            labelWidth:0
                                                        },
                                                        {
                                                            view: "text",
                                                            name: "sgsn_ip",
                                                            placeholder:LangHelper.GetString(this.Id, "IP address of GPRS / EDGE SGSN"),
                                                            tooltip:LangHelper.GetString(this.Id, "IP address of GPRS / EDGE SGSN"),
                                                            validate:function(Val)
                                                            {
                                                                if(!this.elements.enabled.getValue())
                                                                    return true;

                                                                if(!this.elements.sgsn_ip_enabled.getValue())
                                                                    return true;

                                                                if(Val)
                                                                    return Helper.ValidateIPaddress(Val);
                                                                else
                                                                    return true;
                                                            },
                                                            invalidMessage: LangHelper.GetString("Dics", "Enter the IP address")
                                                        }
                                                    ]
                                                },
                                                {
                                                    rows:[
                                                        {
                                                            view: "checkbox",
                                                            name: "ggsn_ip_enabled",
                                                            labelRight:LangHelper.GetString(this.Id, "IP address of GPRS / EDGE GGSN"),
                                                            tooltip:LangHelper.GetString(this.Id, "IP address of GPRS / EDGE GGSN"),
                                                            value:0,
                                                            labelWidth:0
                                                        },
                                                        {
                                                            view: "text",
                                                            name: "ggsn_ip",
                                                            placeholder:LangHelper.GetString(this.Id, "IP address of GPRS / EDGE GGSN"),
                                                            tooltip:LangHelper.GetString(this.Id, "IP address of GPRS / EDGE GGSN"),
                                                            validate:function(Val)
                                                            {
                                                                if(!this.elements.enabled.getValue())
                                                                    return true;

                                                                if(!this.elements.ggsn_ip_enabled.getValue())
                                                                    return true;

                                                                if(Val)
                                                                    return Helper.ValidateIPaddress(Val);
                                                                else
                                                                    return true;
                                                            },
                                                            invalidMessage: LangHelper.GetString("Dics", "Enter the IP address")
                                                        }
                                                    ]
                                                },
                                                {
                                                    rows:[
                                                        {
                                                            view: "checkbox",
                                                            name: "imei_enabled",
                                                            labelRight:LangHelper.GetString(this.Id, "IMEI of mobile subscriber"),
                                                            tooltip:LangHelper.GetString(this.Id, "IMEI of mobile subscriber"),
                                                            value:0,
                                                            labelWidth:0
                                                        },
                                                        {
                                                            view: "text",
                                                            name: "imei",
                                                            placeholder:LangHelper.GetString(this.Id, "IMEI of mobile subscriber"),
                                                            tooltip:LangHelper.GetString(this.Id, "IMEI of mobile subscriber")
                                                        }
                                                    ]
                                                },
                                                {
                                                    rows:[
                                                        {
                                                            view: "checkbox",
                                                            name: "imsi_enabled",
                                                            labelRight:LangHelper.GetString(this.Id, "IMSI of mobile subscriber"),
                                                            tooltip:LangHelper.GetString(this.Id, "IMSI of mobile subscriber"),
                                                            value:0,
                                                            labelWidth:0
                                                        },
                                                        {
                                                            view: "text",
                                                            name: "imsi",
                                                            placeholder:LangHelper.GetString(this.Id, "IMSI of mobile subscriber"),
                                                            tooltip:LangHelper.GetString(this.Id, "IMSI of mobile subscriber")
                                                        }
                                                    ]
                                                },
                                                {
                                                    rows:[
                                                        {
                                                            view: "checkbox",
                                                            name: "pool_number_enabled",
                                                            labelRight:LangHelper.GetString(this.Id, "Modem pool number"),
                                                            tooltip:LangHelper.GetString(this.Id, "Modem pool number"),
                                                            value:0,
                                                            labelWidth:0
                                                        },
                                                        {
                                                            view: "text",
                                                            name: "pool_number",
                                                            placeholder:LangHelper.GetString(this.Id, "Modem pool number"),
                                                            tooltip:LangHelper.GetString(this.Id, "Modem pool number")
                                                        }
                                                    ]
                                                },
                                                {
                                                    rows:[
                                                        {
                                                            view: "checkbox",
                                                            name: "user_equipment_enabled",
                                                            labelRight:LangHelper.GetString(this.Id, "User equipment"),
                                                            tooltip:LangHelper.GetString(this.Id, "User equipment"),
                                                            value:0,
                                                            labelWidth:0
                                                        },
                                                        {
                                                            cols:[
                                                                {
                                                                    view: "text",
                                                                    name: "user_equipment_mac",
                                                                    placeholder:LangHelper.GetString(this.Id, "MAC address"),
                                                                    tooltip:LangHelper.GetString(this.Id, "MAC address")
                                                                },
                                                                {
                                                                    view: "text",
                                                                    name: "user_equipment_atm_vpi",
                                                                    placeholder:LangHelper.GetString(this.Id, "ATM virtual path number (VPI)"),
                                                                    tooltip:LangHelper.GetString(this.Id, "ATM virtual path number (VPI)")
                                                                },
                                                                {
                                                                    view: "text",
                                                                    name: "user_equipment_atm_vci",
                                                                    placeholder:LangHelper.GetString(this.Id, "ATM virtual channel number (VCI)"),
                                                                    tooltip:LangHelper.GetString(this.Id, "ATM virtual channel number (VCI)")
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    rows:[
                                                        {
                                                            view: "checkbox",
                                                            name: "location_start_enabled",
                                                            labelRight:LangHelper.GetString(this.Id, "Base station (connection established)"),
                                                            tooltip:LangHelper.GetString(this.Id, "Base station (connection established)"),
                                                            value:0,
                                                            labelWidth:0
                                                        },
                                                        {
                                                            cols:[
                                                                {
                                                                    view: "text",
                                                                    name: "location_start_lac",
                                                                    placeholder:LangHelper.GetString(this.Id, "Base station zone code (connection established)"),
                                                                    tooltip:LangHelper.GetString(this.Id, "Base station zone code (connection established)")
                                                                },
                                                                {
                                                                    view: "text",
                                                                    name: "location_start_cell",
                                                                    placeholder:LangHelper.GetString(this.Id, "Base station code (connection established)"),
                                                                    tooltip:LangHelper.GetString(this.Id, "Base station code (connection established)")
                                                                },
                                                                {
                                                                    view: "text",
                                                                    name: "location_start_ta",
                                                                    placeholder:LangHelper.GetString(this.Id, "Timing advance of the base station (connection established)"),
                                                                    tooltip:LangHelper.GetString(this.Id, "Timing advance of the base station (connection established)")
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    rows:[
                                                        {
                                                            view: "checkbox",
                                                            name: "location_end_enabled",
                                                            labelRight:LangHelper.GetString(this.Id, "Base station (connection completed)"),
                                                            tooltip:LangHelper.GetString(this.Id, "Base station (connection completed)"),
                                                            value:0,
                                                            labelWidth:0
                                                        },
                                                        {
                                                            cols:[
                                                                {
                                                                    view: "text",
                                                                    name: "location_end_lac",
                                                                    placeholder:LangHelper.GetString(this.Id, "Base station zone code (connection completed)"),
                                                                    tooltip:LangHelper.GetString(this.Id, "Base station zone code (connection completed)")
                                                                },
                                                                {
                                                                    view: "text",
                                                                    name: "location_end_cell",
                                                                    placeholder:LangHelper.GetString(this.Id, "Base station code (connection completed)"),
                                                                    tooltip:LangHelper.GetString(this.Id, "Base station code (connection completed)")
                                                                },
                                                                {
                                                                    view: "text",
                                                                    name: "location_end_ta",
                                                                    placeholder:LangHelper.GetString(this.Id, "Timing advance of the base station (connection completed)"),
                                                                    tooltip:LangHelper.GetString(this.Id, "Timing advance of the base station (connection completed)")
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    id:this.Id + "CreateTaskContextForm" + "_" + "http" + "_" + "tab",
                                    rows:[
                                        BlockHeaderTemplate({title:LangHelper.GetString(this.Id, "Http"), icon:"file"}),
                                        {
                                            id:this.Id + "CreateTaskContextForm" + "_" + "http",
                                            view: "form",
                                            margin: 0,
                                            padding: 8,
                                            scroll:"y",
                                            elements:[
                                                {
                                                    view: "checkbox",
                                                    name: "enabled",
                                                    labelRight: LangHelper.GetString(this.Id, "Enable search"),
                                                    value:0,
                                                    labelWidth:0,
                                                    on: {
                                                        onChange:function()
                                                        {
                                                            _this.SetCreateTaskContextFormTabbarCheckboxes();
                                                        }
                                                    },
                                                    validate:function(Val)
                                                    {
                                                        if(!Val)
                                                            return true;

                                                        return _this.ValidateFormOneParameterChecked(this);
                                                    },
                                                    invalidMessage: LangHelper.GetString(this.Id,"You must enable at least one query parameter")
                                                },
                                                {
                                                    rows:[
                                                        {
                                                            view: "checkbox",
                                                            name: "point_id_enabled",
                                                            labelRight:LangHelper.GetString(this.Id, "Connection point"),
                                                            tooltip:LangHelper.GetString(this.Id, "Connection point identifier to the data network"),
                                                            value:0,
                                                            labelWidth:0
                                                        },
                                                        {
                                                            view: "text",
                                                            name: "point_id",
                                                            placeholder:LangHelper.GetString(this.Id, "Connection point"),
                                                            tooltip:LangHelper.GetString(this.Id, "Connection point identifier to the data network"),
                                                            validate:function(Val)
                                                            {
                                                                if(!this.elements.enabled.getValue())
                                                                    return true;

                                                                if(!this.elements.point_id_enabled.getValue())
                                                                    return true;

                                                                return !isNaN(Val*1);
                                                            },
                                                            invalidMessage: LangHelper.GetString("Dics","Enter the number"),
                                                            suggest: {
                                                                data:App.DicsManager.GetDicAsArray(DicsEnum.IpDataPointsDic),
                                                                template:"#id#",
                                                                filter:function(obj, value)
                                                                {
                                                                    return obj.value.indexOf(value) >= 0;
                                                                }
                                                            },
                                                            keyPressTimeout:500,
                                                            on:{
                                                                onTimedKeyPress: function()
                                                                {
                                                                    App.DicsManager.Refresh({data:[DicsEnum.IpDataPointsDic], filter:this.getValue()});
                                                                }
                                                            }
                                                        }
                                                    ]
                                                },
                                                {
                                                    rows:[
                                                        {
                                                            view: "checkbox",
                                                            name: "url_enabled",
                                                            labelRight:LangHelper.GetString(this.Id, "Url"),
                                                            tooltip:LangHelper.GetString(this.Id, "Url, for example somehost.ru/some/*"),
                                                            value:0,
                                                            labelWidth:0
                                                        },
                                                        {
                                                            view: "text",
                                                            name: "url",
                                                            placeholder:LangHelper.GetString(this.Id, "Url, for example somehost.ru/some/*"),
                                                            tooltip:LangHelper.GetString(this.Id, "Url, for example somehost.ru/some/*")
                                                        }
                                                    ]
                                                },
                                                {
                                                    rows:[
                                                        {
                                                            view: "checkbox",
                                                            name: "http_method_enabled",
                                                            labelRight:LangHelper.GetString(this.Id, "Http method"),
                                                            tooltip:LangHelper.GetString(this.Id, "Http method"),
                                                            value:0,
                                                            labelWidth:0
                                                        },
                                                        {
                                                            view: "richselect",
                                                            name: "http_method",
                                                            placeholder:LangHelper.GetString(this.Id, "Http method"),
                                                            tooltip:LangHelper.GetString(this.Id, "Http method"),
                                                            options:Helper.DictToArr(SormRequestHttpMethodDic)
                                                        }
                                                    ]
                                                },
                                                {
                                                    rows:[
                                                        {
                                                            view: "checkbox",
                                                            name: "client_info_enabled",
                                                            labelRight:LangHelper.GetString(this.Id, "Source IP"),
                                                            tooltip:LangHelper.GetString(this.Id, "Source IP"),
                                                            value:0,
                                                            labelWidth:0
                                                        },
                                                        {
                                                            cols:[
                                                                {
                                                                    view: "text",
                                                                    name: "client_info_ip",
                                                                    placeholder:LangHelper.GetString(this.Id, "Source IP"),
                                                                    tooltip:LangHelper.GetString(this.Id, "Source IP"),
                                                                    validate:function(Val)
                                                                    {
                                                                        if(!this.elements.enabled.getValue())
                                                                            return true;

                                                                        if(!this.elements.client_info_enabled.getValue())
                                                                            return true;

                                                                        if(!this.elements.client_info_ip.getValue() && !this.elements.client_info_port.getValue())
                                                                            return false;

                                                                        if(Val)
                                                                            return Helper.ValidateIPaddress(Val);
                                                                        else
                                                                            return true;
                                                                    },
                                                                    invalidMessage: LangHelper.GetString("Dics", "Enter the IP address")
                                                                },
                                                                {
                                                                    view: "text",
                                                                    name: "client_info_port",
                                                                    placeholder:LangHelper.GetString(this.Id, "Source port"),
                                                                    tooltip:LangHelper.GetString(this.Id, "Source port"),
                                                                    validate:function(Val)
                                                                    {
                                                                        if(!this.elements.enabled.getValue())
                                                                            return true;

                                                                        if(!this.elements.client_info_enabled.getValue())
                                                                            return true;

                                                                        if(!this.elements.client_info_ip.getValue() && !this.elements.client_info_port.getValue())
                                                                            return false;

                                                                        return !isNaN(Val*1);
                                                                    },
                                                                    invalidMessage: LangHelper.GetString("Dics","Enter the number")
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    rows:[
                                                        {
                                                            view: "checkbox",
                                                            name: "server_info_enabled",
                                                            labelRight:LangHelper.GetString(this.Id, "Destination IP"),
                                                            tooltip:LangHelper.GetString(this.Id, "Destination IP"),
                                                            value:0,
                                                            labelWidth:0
                                                        },
                                                        {
                                                            cols:[
                                                                {
                                                                    view: "text",
                                                                    name: "server_info_ip",
                                                                    placeholder:LangHelper.GetString(this.Id, "Destination IP"),
                                                                    tooltip:LangHelper.GetString(this.Id, "Destination IP"),
                                                                    validate:function(Val)
                                                                    {
                                                                        if(!this.elements.enabled.getValue())
                                                                            return true;

                                                                        if(!this.elements.server_info_enabled.getValue())
                                                                            return true;

                                                                        if(!this.elements.server_info_ip.getValue() && !this.elements.server_info_port.getValue())
                                                                            return false;

                                                                        if(Val)
                                                                            return Helper.ValidateIPaddress(Val);
                                                                        else
                                                                            return true;
                                                                    },
                                                                    invalidMessage: LangHelper.GetString("Dics", "Enter the IP address")
                                                                },
                                                                {
                                                                    view: "text",
                                                                    name: "server_info_port",
                                                                    placeholder:LangHelper.GetString(this.Id, "Destination port"),
                                                                    tooltip:LangHelper.GetString(this.Id, "Destination port"),
                                                                    validate:function(Val)
                                                                    {
                                                                        if(!this.elements.enabled.getValue())
                                                                            return true;

                                                                        if(!this.elements.server_info_enabled.getValue())
                                                                            return true;

                                                                        if(!this.elements.server_info_ip.getValue() && !this.elements.server_info_port.getValue())
                                                                            return false;

                                                                        return !isNaN(Val*1);
                                                                    },
                                                                    invalidMessage: LangHelper.GetString("Dics","Enter the number")
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    rows:[
                                                        {
                                                            view: "checkbox",
                                                            name: "term_cause_enabled",
                                                            labelRight:LangHelper.GetString(this.Id, "Completion reason"),
                                                            tooltip:LangHelper.GetString(this.Id, "Connection completion reason"),
                                                            value:0,
                                                            labelWidth:0
                                                        },
                                                        {
                                                            view: "text",
                                                            name: "term_cause",
                                                            placeholder:LangHelper.GetString(this.Id, "Completion reason"),
                                                            tooltip:LangHelper.GetString(this.Id, "Connection completion reason"),
                                                            validate:function(Val)
                                                            {
                                                                if(!this.elements.enabled.getValue())
                                                                    return true;

                                                                if(!this.elements.term_cause_enabled.getValue())
                                                                    return true;

                                                                return !isNaN(Val*1);
                                                            },
                                                            invalidMessage: LangHelper.GetString("Dics","Enter the number"),
                                                            suggest: {
                                                                data:App.DicsManager.GetDicAsArray(DicsEnum.TermCausesDic),
                                                                template:"#id#",
                                                                filter:function(obj, value)
                                                                {
                                                                    return obj.value.indexOf(value) >= 0;
                                                                }
                                                            },
                                                            keyPressTimeout:500,
                                                            on:{
                                                                onTimedKeyPress: function()
                                                                {
                                                                    App.DicsManager.Refresh({data:[DicsEnum.TermCausesDic], filter:this.getValue()});
                                                                }
                                                            }
                                                        }
                                                    ]
                                                },
                                                {
                                                    rows:[
                                                        {
                                                            view: "checkbox",
                                                            name: "abonent_id_enabled",
                                                            labelRight:LangHelper.GetString(this.Id, "Subscriber ID"),
                                                            tooltip:LangHelper.GetString(this.Id, "Subscriber ID"),
                                                            value:0,
                                                            labelWidth:0
                                                        },
                                                        {
                                                            view: "text",
                                                            name: "abonent_id",
                                                            placeholder:LangHelper.GetString(this.Id, "Subscriber ID"),
                                                            tooltip:LangHelper.GetString(this.Id, "Subscriber ID")
                                                        }
                                                    ]
                                                },
                                                {}
                                            ]

                                        }
                                    ]
                                },
                                {
                                    id:this.Id + "CreateTaskContextForm" + "_" + "ftp" + "_" + "tab",
                                    rows:[
                                        BlockHeaderTemplate({title:LangHelper.GetString(this.Id, "Ftp"), icon:"file"}),
                                        {
                                            id:this.Id + "CreateTaskContextForm" + "_" + "ftp",
                                            view: "form",
                                            margin: 0,
                                            padding: 8,
                                            scroll:"y",
                                            elements:[
                                                {
                                                    view: "checkbox",
                                                    name: "enabled",
                                                    labelRight: LangHelper.GetString(this.Id, "Enable search"),
                                                    value:0,
                                                    labelWidth:0,
                                                    on: {
                                                        onChange:function()
                                                        {
                                                            _this.SetCreateTaskContextFormTabbarCheckboxes();
                                                        }
                                                    },
                                                    validate:function(Val)
                                                    {
                                                        if(!Val)
                                                            return true;

                                                        return _this.ValidateFormOneParameterChecked(this);
                                                    },
                                                    invalidMessage: LangHelper.GetString(this.Id,"You must enable at least one query parameter")
                                                },
                                                {
                                                    rows:[
                                                        {
                                                            view: "checkbox",
                                                            name: "point_id_enabled",
                                                            labelRight:LangHelper.GetString(this.Id, "Connection point"),
                                                            tooltip:LangHelper.GetString(this.Id, "Connection point identifier to the data network"),
                                                            value:0,
                                                            labelWidth:0
                                                        },
                                                        {
                                                            view: "text",
                                                            name: "point_id",
                                                            placeholder:LangHelper.GetString(this.Id, "Connection point"),
                                                            tooltip:LangHelper.GetString(this.Id, "Connection point identifier to the data network"),
                                                            validate:function(Val)
                                                            {
                                                                if(!this.elements.enabled.getValue())
                                                                    return true;

                                                                if(!this.elements.point_id_enabled.getValue())
                                                                    return true;

                                                                return !isNaN(Val*1);
                                                            },
                                                            invalidMessage: LangHelper.GetString("Dics","Enter the number"),
                                                            suggest: {
                                                                data:App.DicsManager.GetDicAsArray(DicsEnum.IpDataPointsDic),
                                                                template:"#id#",
                                                                filter:function(obj, value)
                                                                {
                                                                    return obj.value.indexOf(value) >= 0;
                                                                }
                                                            },
                                                            keyPressTimeout:500,
                                                            on:{
                                                                onTimedKeyPress: function()
                                                                {
                                                                    App.DicsManager.Refresh({data:[DicsEnum.IpDataPointsDic], filter:this.getValue()});
                                                                }
                                                            }
                                                        }
                                                    ]
                                                },
                                                {
                                                    cols:[
                                                        {
                                                            rows:[
                                                                {
                                                                    view: "checkbox",
                                                                    name: "login_enabled",
                                                                    labelRight:LangHelper.GetString(this.Id, "Ftp-login"),
                                                                    tooltip:LangHelper.GetString(this.Id, "Ftp-login"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "text",
                                                                    name: "login",
                                                                    placeholder:LangHelper.GetString(this.Id, "Ftp-login"),
                                                                    tooltip:LangHelper.GetString(this.Id, "Ftp-login")
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            rows:[
                                                                {
                                                                    view: "checkbox",
                                                                    name: "password_enabled",
                                                                    labelRight:LangHelper.GetString(this.Id, "Ftp-password"),
                                                                    tooltip:LangHelper.GetString(this.Id, "Ftp-password"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "text",
                                                                    name: "password",
                                                                    placeholder:LangHelper.GetString(this.Id, "Ftp-password"),
                                                                    tooltip:LangHelper.GetString(this.Id, "Ftp-password")
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    rows:[
                                                        {
                                                            view: "checkbox",
                                                            name: "server_name_enabled",
                                                            labelRight:LangHelper.GetString(this.Id, LangHelper.GetString(this.Id, "Server name")),
                                                            tooltip:LangHelper.GetString(this.Id, LangHelper.GetString(this.Id, "Server name")),
                                                            value:0,
                                                            labelWidth:0
                                                        },
                                                        {
                                                            view: "text",
                                                            name: "server_name",
                                                            placeholder:LangHelper.GetString(this.Id, LangHelper.GetString(this.Id, "Server name")),
                                                            tooltip:LangHelper.GetString(this.Id, LangHelper.GetString(this.Id, "Server name"))
                                                        }
                                                    ]
                                                },
                                                {
                                                    rows:[
                                                        {
                                                            view: "checkbox",
                                                            name: "client_info_enabled",
                                                            labelRight:LangHelper.GetString(this.Id, "Source IP"),
                                                            tooltip:LangHelper.GetString(this.Id, "Source IP"),
                                                            value:0,
                                                            labelWidth:0
                                                        },
                                                        {
                                                            cols:[
                                                                {
                                                                    view: "text",
                                                                    name: "client_info_ip",
                                                                    placeholder:LangHelper.GetString(this.Id, "Source IP"),
                                                                    tooltip:LangHelper.GetString(this.Id, "Source IP"),
                                                                    validate:function(Val)
                                                                    {
                                                                        if(!this.elements.enabled.getValue())
                                                                            return true;

                                                                        if(!this.elements.client_info_enabled.getValue())
                                                                            return true;

                                                                        if(!this.elements.client_info_ip.getValue() && !this.elements.client_info_port.getValue())
                                                                            return false;

                                                                        if(Val)
                                                                            return Helper.ValidateIPaddress(Val);
                                                                        else
                                                                            return true;
                                                                    },
                                                                    invalidMessage: LangHelper.GetString("Dics", "Enter the IP address")
                                                                },
                                                                {
                                                                    view: "text",
                                                                    name: "client_info_port",
                                                                    placeholder:LangHelper.GetString(this.Id, "Source port"),
                                                                    tooltip:LangHelper.GetString(this.Id, "Source port"),
                                                                    validate:function(Val)
                                                                    {
                                                                        if(!this.elements.enabled.getValue())
                                                                            return true;

                                                                        if(!this.elements.client_info_enabled.getValue())
                                                                            return true;

                                                                        if(!this.elements.client_info_ip.getValue() && !this.elements.client_info_port.getValue())
                                                                            return false;

                                                                        return !isNaN(Val*1);
                                                                    },
                                                                    invalidMessage: LangHelper.GetString("Dics","Enter the number")
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    rows:[
                                                        {
                                                            view: "checkbox",
                                                            name: "server_info_enabled",
                                                            labelRight:LangHelper.GetString(this.Id, "Destination IP"),
                                                            tooltip:LangHelper.GetString(this.Id, "Destination IP"),
                                                            value:0,
                                                            labelWidth:0
                                                        },
                                                        {
                                                            cols:[
                                                                {
                                                                    view: "text",
                                                                    name: "server_info_ip",
                                                                    placeholder:LangHelper.GetString(this.Id, "Destination IP"),
                                                                    tooltip:LangHelper.GetString(this.Id, "Destination IP"),
                                                                    validate:function(Val)
                                                                    {
                                                                        if(!this.elements.enabled.getValue())
                                                                            return true;

                                                                        if(!this.elements.server_info_enabled.getValue())
                                                                            return true;

                                                                        if(!this.elements.server_info_ip.getValue() && !this.elements.server_info_port.getValue())
                                                                            return false;

                                                                        if(Val)
                                                                            return Helper.ValidateIPaddress(Val);
                                                                        else
                                                                            return true;
                                                                    },
                                                                    invalidMessage: LangHelper.GetString("Dics", "Enter the IP address")
                                                                },
                                                                {
                                                                    view: "text",
                                                                    name: "server_info_port",
                                                                    placeholder:LangHelper.GetString(this.Id, "Destination port"),
                                                                    tooltip:LangHelper.GetString(this.Id, "Destination port"),
                                                                    validate:function(Val)
                                                                    {
                                                                        if(!this.elements.enabled.getValue())
                                                                            return true;

                                                                        if(!this.elements.server_info_enabled.getValue())
                                                                            return true;

                                                                        if(!this.elements.server_info_ip.getValue() && !this.elements.server_info_port.getValue())
                                                                            return false;

                                                                        return !isNaN(Val*1);
                                                                    },
                                                                    invalidMessage: LangHelper.GetString("Dics","Enter the number")
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    rows:[
                                                        {
                                                            view: "checkbox",
                                                            name: "term_cause_enabled",
                                                            labelRight:LangHelper.GetString(this.Id, "Completion reason"),
                                                            tooltip:LangHelper.GetString(this.Id, "Connection completion reason"),
                                                            value:0,
                                                            labelWidth:0
                                                        },
                                                        {
                                                            view: "text",
                                                            name: "term_cause",
                                                            placeholder:LangHelper.GetString(this.Id, "Completion reason"),
                                                            tooltip:LangHelper.GetString(this.Id, "Connection completion reason"),
                                                            validate:function(Val)
                                                            {
                                                                if(!this.elements.enabled.getValue())
                                                                    return true;

                                                                if(!this.elements.term_cause_enabled.getValue())
                                                                    return true;

                                                                return !isNaN(Val*1);
                                                            },
                                                            invalidMessage: LangHelper.GetString("Dics","Enter the number"),
                                                            suggest: {
                                                                data:App.DicsManager.GetDicAsArray(DicsEnum.TermCausesDic),
                                                                template:"#id#",
                                                                filter:function(obj, value)
                                                                {
                                                                    return obj.value.indexOf(value) >= 0;
                                                                }
                                                            },
                                                            keyPressTimeout:500,
                                                            on:{
                                                                onTimedKeyPress: function()
                                                                {
                                                                    App.DicsManager.Refresh({data:[DicsEnum.TermCausesDic], filter:this.getValue()});
                                                                }
                                                            }
                                                        }
                                                    ]
                                                },
                                                {
                                                    rows:[
                                                        {
                                                            view: "checkbox",
                                                            name: "abonent_id_enabled",
                                                            labelRight:LangHelper.GetString(this.Id, "Subscriber ID"),
                                                            tooltip:LangHelper.GetString(this.Id, "Subscriber ID"),
                                                            value:0,
                                                            labelWidth:0
                                                        },
                                                        {
                                                            view: "text",
                                                            name: "abonent_id",
                                                            placeholder:LangHelper.GetString(this.Id, "Subscriber ID"),
                                                            tooltip:LangHelper.GetString(this.Id, "Subscriber ID")
                                                        }
                                                    ]
                                                },
                                                {}
                                            ]

                                        }
                                    ]
                                },
                                {
                                    id:this.Id + "CreateTaskContextForm" + "_" + "mail" + "_" + "tab",
                                    rows:[
                                        BlockHeaderTemplate({title:LangHelper.GetString(this.Id, "E-mail"), icon:"file"}),
                                        {
                                            id:this.Id + "CreateTaskContextForm" + "_" + "mail",
                                            view: "form",
                                            margin: 0,
                                            padding: 8,
                                            scroll:"y",
                                            elements:[
                                                {
                                                    view: "checkbox",
                                                    name: "enabled",
                                                    labelRight: LangHelper.GetString(this.Id, "Enable search"),
                                                    value:0,
                                                    labelWidth:0,
                                                    on: {
                                                        onChange:function()
                                                        {
                                                            _this.SetCreateTaskContextFormTabbarCheckboxes();
                                                        }
                                                    },
                                                    validate:function(Val)
                                                    {
                                                        if(!Val)
                                                            return true;

                                                        return _this.ValidateFormOneParameterChecked(this);
                                                    },
                                                    invalidMessage: LangHelper.GetString(this.Id,"You must enable at least one query parameter")
                                                },
                                                {
                                                    rows:[
                                                        {
                                                            view: "checkbox",
                                                            name: "point_id_enabled",
                                                            labelRight:LangHelper.GetString(this.Id, "Connection point"),
                                                            tooltip:LangHelper.GetString(this.Id, "Connection point identifier to the data network"),
                                                            value:0,
                                                            labelWidth:0
                                                        },
                                                        {
                                                            view: "text",
                                                            name: "point_id",
                                                            placeholder:LangHelper.GetString(this.Id, "Connection point"),
                                                            tooltip:LangHelper.GetString(this.Id, "Connection point identifier to the data network"),
                                                            validate:function(Val)
                                                            {
                                                                if(!this.elements.enabled.getValue())
                                                                    return true;

                                                                if(!this.elements.point_id_enabled.getValue())
                                                                    return true;

                                                                return !isNaN(Val*1);
                                                            },
                                                            invalidMessage: LangHelper.GetString("Dics","Enter the number"),
                                                            suggest: {
                                                                data:App.DicsManager.GetDicAsArray(DicsEnum.IpDataPointsDic),
                                                                template:"#id#",
                                                                filter:function(obj, value)
                                                                {
                                                                    return obj.value.indexOf(value) >= 0;
                                                                }
                                                            },
                                                            keyPressTimeout:500,
                                                            on:{
                                                                onTimedKeyPress: function()
                                                                {
                                                                    App.DicsManager.Refresh({data:[DicsEnum.IpDataPointsDic], filter:this.getValue()});
                                                                }
                                                            }
                                                        }
                                                    ]
                                                },
                                                {
                                                    cols: [
                                                        {
                                                            rows:[
                                                                {
                                                                    view: "checkbox",
                                                                    name: "sender_enabled",
                                                                    labelRight:LangHelper.GetString(this.Id, "Sender"),
                                                                    tooltip:LangHelper.GetString(this.Id, "The sender, for example petya@gmail.com"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "text",
                                                                    name: "sender",
                                                                    value: "",
                                                                    placeholder:LangHelper.GetString(this.Id, "The sender, for example petya@gmail.com"),
                                                                    tooltip:LangHelper.GetString(this.Id, "The sender, for example petya@gmail.com")
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            rows:[
                                                                {},
                                                                {
                                                                    view:"button",
                                                                    type:"icon",
                                                                    icon: "exchange",
                                                                    width:28,
                                                                    click:function ()
                                                                    {
                                                                        var left = _this.Views.CreateTaskContextForms.mail.elements.sender;
                                                                        var left_val = _this.Views.CreateTaskContextForms.mail.elements.sender.getValue();
                                                                        var right = _this.Views.CreateTaskContextForms.mail.elements.receiver;
                                                                        var right_val = _this.Views.CreateTaskContextForms.mail.elements.receiver.getValue();

                                                                        left.setValue(right_val);
                                                                        right.setValue(left_val);
                                                                    }
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            rows:[
                                                                {
                                                                    view: "checkbox",
                                                                    name: "receiver_enabled",
                                                                    labelRight:LangHelper.GetString(this.Id, "Recipient"),
                                                                    tooltip:LangHelper.GetString(this.Id, "The recipient, for example, vasya@gmail.com"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "text",
                                                                    name: "receiver",
                                                                    value: "",
                                                                    placeholder:LangHelper.GetString(this.Id, "The recipient, for example, vasya@gmail.com"),
                                                                    tooltip:LangHelper.GetString(this.Id, "The recipient, for example, vasya@gmail.com")
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    rows:[
                                                        {
                                                            view: "checkbox",
                                                            name: "cc_enabled",
                                                            labelRight:LangHelper.GetString(this.Id, "Copy"),
                                                            tooltip:LangHelper.GetString(this.Id, "The recipient of a copy of the email message"),
                                                            value:0,
                                                            labelWidth:0
                                                        },
                                                        {
                                                            view: "text",
                                                            name: "cc",
                                                            value: "",
                                                            placeholder:LangHelper.GetString(this.Id, "The recipient of a copy of the email message"),
                                                            tooltip:LangHelper.GetString(this.Id, "The recipient of a copy of the email message")
                                                        }
                                                    ]
                                                },
                                                {
                                                    rows:[
                                                        {
                                                            view: "checkbox",
                                                            name: "subject_enabled",
                                                            labelRight:LangHelper.GetString(this.Id, "Subject"),
                                                            tooltip:LangHelper.GetString(this.Id, "The message subject"),
                                                            value:0,
                                                            labelWidth:0
                                                        },
                                                        {
                                                            view: "text",
                                                            name: "subject",
                                                            value: "",
                                                            placeholder:LangHelper.GetString(this.Id, "The message subject"),
                                                            tooltip:LangHelper.GetString(this.Id, "The message subject")
                                                        }
                                                    ]
                                                },
                                                {
                                                    rows:[
                                                        {
                                                            view: "checkbox",
                                                            name: "message_enabled",
                                                            labelRight:LangHelper.GetString(this.Id, "Message"),
                                                            tooltip:LangHelper.GetString(this.Id, "The message text"),
                                                            value:0,
                                                            labelWidth:0
                                                        },
                                                        {
                                                            view: "text",
                                                            name: "message",
                                                            value: "",
                                                            placeholder:LangHelper.GetString(this.Id, "The message text"),
                                                            tooltip:LangHelper.GetString(this.Id, "The message text")
                                                        }
                                                    ]
                                                },
                                                {
                                                    rows:[
                                                        {
                                                            view: "checkbox",
                                                            name: "mail_server_enabled",
                                                            labelRight:LangHelper.GetString(this.Id, "Server name"),
                                                            tooltip:LangHelper.GetString(this.Id, "Name of the server through which the message was sent"),
                                                            value:0,
                                                            labelWidth:0
                                                        },
                                                        {
                                                            view: "text",
                                                            name: "mail_server",
                                                            value: "",
                                                            placeholder:LangHelper.GetString(this.Id, "Name of the server through which the message was sent"),
                                                            tooltip:LangHelper.GetString(this.Id, "Name of the server through which the message was sent")
                                                        }
                                                    ]
                                                },
                                                {
                                                    rows:[
                                                        {
                                                            view: "checkbox",
                                                            name: "attachements_enabled",
                                                            labelRight:LangHelper.GetString(this.Id, "Attachements"),
                                                            tooltip:LangHelper.GetString(this.Id, "The presence of attachements in the letter"),
                                                            value:0,
                                                            labelWidth:0
                                                        },
                                                        {
                                                            view: "richselect",
                                                            name: "attachements",
                                                            placeholder:LangHelper.GetString(this.Id, "The presence of attachements in the letter"),
                                                            tooltip:LangHelper.GetString(this.Id, "The presence of attachements in the letter"),
                                                            options:Helper.DictToArr(YesNoDic)
                                                        }
                                                    ]
                                                },
                                                {
                                                    rows:[
                                                        {
                                                            view: "checkbox",
                                                            name: "client_info_enabled",
                                                            labelRight:LangHelper.GetString(this.Id, "Source IP"),
                                                            tooltip:LangHelper.GetString(this.Id, "Source IP"),
                                                            value:0,
                                                            labelWidth:0
                                                        },
                                                        {
                                                            cols:[
                                                                {
                                                                    view: "text",
                                                                    name: "client_info_ip",
                                                                    placeholder:LangHelper.GetString(this.Id, "Source IP"),
                                                                    tooltip:LangHelper.GetString(this.Id, "Source IP"),
                                                                    validate:function(Val)
                                                                    {
                                                                        if(!this.elements.enabled.getValue())
                                                                            return true;

                                                                        if(!this.elements.client_info_enabled.getValue())
                                                                            return true;

                                                                        if(!this.elements.client_info_ip.getValue() && !this.elements.client_info_port.getValue())
                                                                            return false;

                                                                        if(Val)
                                                                            return Helper.ValidateIPaddress(Val);
                                                                        else
                                                                            return true;
                                                                    },
                                                                    invalidMessage: LangHelper.GetString("Dics", "Enter the IP address")
                                                                },
                                                                {
                                                                    view: "text",
                                                                    name: "client_info_port",
                                                                    placeholder:LangHelper.GetString(this.Id, "Source port"),
                                                                    tooltip:LangHelper.GetString(this.Id, "Source port"),
                                                                    validate:function(Val)
                                                                    {
                                                                        if(!this.elements.enabled.getValue())
                                                                            return true;

                                                                        if(!this.elements.client_info_enabled.getValue())
                                                                            return true;

                                                                        if(!this.elements.client_info_ip.getValue() && !this.elements.client_info_port.getValue())
                                                                            return false;

                                                                        return !isNaN(Val*1);
                                                                    },
                                                                    invalidMessage: LangHelper.GetString("Dics","Enter the number")
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    rows:[
                                                        {
                                                            view: "checkbox",
                                                            name: "server_info_enabled",
                                                            labelRight:LangHelper.GetString(this.Id, "Destination IP"),
                                                            tooltip:LangHelper.GetString(this.Id, "Destination IP"),
                                                            value:0,
                                                            labelWidth:0
                                                        },
                                                        {
                                                            cols:[
                                                                {
                                                                    view: "text",
                                                                    name: "server_info_ip",
                                                                    placeholder:LangHelper.GetString(this.Id, "Destination IP"),
                                                                    tooltip:LangHelper.GetString(this.Id, "Destination IP"),
                                                                    validate:function(Val)
                                                                    {
                                                                        if(!this.elements.enabled.getValue())
                                                                            return true;

                                                                        if(!this.elements.server_info_enabled.getValue())
                                                                            return true;

                                                                        if(!this.elements.server_info_ip.getValue() && !this.elements.server_info_port.getValue())
                                                                            return false;

                                                                        if(Val)
                                                                            return Helper.ValidateIPaddress(Val);
                                                                        else
                                                                            return true;
                                                                    },
                                                                    invalidMessage: LangHelper.GetString("Dics", "Enter the IP address")
                                                                },
                                                                {
                                                                    view: "text",
                                                                    name: "server_info_port",
                                                                    placeholder:LangHelper.GetString(this.Id, "Destination port"),
                                                                    tooltip:LangHelper.GetString(this.Id, "Destination port"),
                                                                    validate:function(Val)
                                                                    {
                                                                        if(!this.elements.enabled.getValue())
                                                                            return true;

                                                                        if(!this.elements.server_info_enabled.getValue())
                                                                            return true;

                                                                        if(!this.elements.server_info_ip.getValue() && !this.elements.server_info_port.getValue())
                                                                            return false;

                                                                        return !isNaN(Val*1);
                                                                    },
                                                                    invalidMessage: LangHelper.GetString("Dics","Enter the number")
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    rows:[
                                                        {
                                                            view: "checkbox",
                                                            name: "term_cause_enabled",
                                                            labelRight:LangHelper.GetString(this.Id, "Completion reason"),
                                                            tooltip:LangHelper.GetString(this.Id, "Connection completion reason"),
                                                            value:0,
                                                            labelWidth:0
                                                        },
                                                        {
                                                            view: "text",
                                                            name: "term_cause",
                                                            placeholder:LangHelper.GetString(this.Id, "Completion reason"),
                                                            tooltip:LangHelper.GetString(this.Id, "Connection completion reason"),
                                                            validate:function(Val)
                                                            {
                                                                if(!this.elements.enabled.getValue())
                                                                    return true;

                                                                if(!this.elements.term_cause_enabled.getValue())
                                                                    return true;

                                                                return !isNaN(Val*1);
                                                            },
                                                            invalidMessage: LangHelper.GetString("Dics","Enter the number"),
                                                            suggest: {
                                                                data:App.DicsManager.GetDicAsArray(DicsEnum.TermCausesDic),
                                                                template:"#id#",
                                                                filter:function(obj, value)
                                                                {
                                                                    return obj.value.indexOf(value) >= 0;
                                                                }
                                                            },
                                                            keyPressTimeout:500,
                                                            on:{
                                                                onTimedKeyPress: function()
                                                                {
                                                                    App.DicsManager.Refresh({data:[DicsEnum.TermCausesDic], filter:this.getValue()});
                                                                }
                                                            }
                                                        }
                                                    ]
                                                },
                                                {
                                                    rows:[
                                                        {
                                                            view: "checkbox",
                                                            name: "abonent_id_enabled",
                                                            labelRight:LangHelper.GetString(this.Id, "Subscriber ID"),
                                                            tooltip:LangHelper.GetString(this.Id, "Subscriber ID"),
                                                            value:0,
                                                            labelWidth:0
                                                        },
                                                        {
                                                            view: "text",
                                                            name: "abonent_id",
                                                            placeholder:LangHelper.GetString(this.Id, "Subscriber ID"),
                                                            tooltip:LangHelper.GetString(this.Id, "Subscriber ID")
                                                        }
                                                    ]
                                                },
                                                {}
                                            ]

                                        }
                                    ]
                                },
                                {
                                    id:this.Id + "CreateTaskContextForm" + "_" + "im" + "_" + "tab",
                                    rows:[
                                        BlockHeaderTemplate({title:LangHelper.GetString(this.Id, "Im"), icon:"file"}),
                                        {
                                            id:this.Id + "CreateTaskContextForm" + "_" + "im",
                                            view: "form",
                                            margin: 0,
                                            padding: 8,
                                            scroll:"y",
                                            elements:[
                                                {
                                                    view: "checkbox",
                                                    name: "enabled",
                                                    labelRight: LangHelper.GetString(this.Id, "Enable search"),
                                                    value:0,
                                                    labelWidth:0,
                                                    on: {
                                                        onChange:function()
                                                        {
                                                            _this.SetCreateTaskContextFormTabbarCheckboxes();
                                                        }
                                                    },
                                                    validate:function(Val)
                                                    {
                                                        if(!Val)
                                                            return true;

                                                        return _this.ValidateFormOneParameterChecked(this);
                                                    },
                                                    invalidMessage: LangHelper.GetString(this.Id,"You must enable at least one query parameter")
                                                },
                                                {
                                                    rows:[
                                                        {
                                                            view: "checkbox",
                                                            name: "point_id_enabled",
                                                            labelRight:LangHelper.GetString(this.Id, "Connection point"),
                                                            tooltip:LangHelper.GetString(this.Id, "Connection point identifier to the data network"),
                                                            value:0,
                                                            labelWidth:0
                                                        },
                                                        {
                                                            view: "text",
                                                            name: "point_id",
                                                            placeholder:LangHelper.GetString(this.Id, "Connection point"),
                                                            tooltip:LangHelper.GetString(this.Id, "Connection point identifier to the data network"),
                                                            validate:function(Val)
                                                            {
                                                                if(!this.elements.enabled.getValue())
                                                                    return true;

                                                                if(!this.elements.point_id_enabled.getValue())
                                                                    return true;

                                                                return !isNaN(Val*1);
                                                            },
                                                            invalidMessage: LangHelper.GetString("Dics","Enter the number"),
                                                            suggest: {
                                                                data:App.DicsManager.GetDicAsArray(DicsEnum.IpDataPointsDic),
                                                                template:"#id#",
                                                                filter:function(obj, value)
                                                                {
                                                                    return obj.value.indexOf(value) >= 0;
                                                                }
                                                            },
                                                            keyPressTimeout:500,
                                                            on:{
                                                                onTimedKeyPress: function()
                                                                {
                                                                    App.DicsManager.Refresh({data:[DicsEnum.IpDataPointsDic], filter:this.getValue()});
                                                                }
                                                            }
                                                        }
                                                    ]
                                                },
                                                {
                                                    cols: [
                                                        {
                                                            rows:[
                                                                {
                                                                    view: "checkbox",
                                                                    name: "sender_uin_enabled",
                                                                    labelRight:LangHelper.GetString(this.Id, "Sender ID"),
                                                                    tooltip:LangHelper.GetString(this.Id, "Sender's User ID"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "text",
                                                                    name: "sender_uin",
                                                                    value: "",
                                                                    placeholder:"petya_nickname",
                                                                    tooltip:LangHelper.GetString(this.Id, "Sender's User ID")
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            rows:[
                                                                {},
                                                                {
                                                                    view:"button",
                                                                    type:"icon",
                                                                    icon: "exchange",
                                                                    width:28,
                                                                    click:function ()
                                                                    {
                                                                        var left = _this.Views.CreateTaskContextForms.im.elements.sender_uin;
                                                                        var left_val = _this.Views.CreateTaskContextForms.im.elements.sender_uin.getValue();
                                                                        var right = _this.Views.CreateTaskContextForms.im.elements.receiver_uin;
                                                                        var right_val = _this.Views.CreateTaskContextForms.im.elements.receiver_uin.getValue();

                                                                        left.setValue(right_val);
                                                                        right.setValue(left_val);
                                                                    }
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            rows:[
                                                                {
                                                                    view: "checkbox",
                                                                    name: "receiver_uin_enabled",
                                                                    labelRight:LangHelper.GetString(this.Id, "Receiver ID"),
                                                                    tooltip:LangHelper.GetString(this.Id, "Receiver's User ID"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "text",
                                                                    name: "receiver_uin",
                                                                    value: "",
                                                                    placeholder:"vasya_nickname",
                                                                    tooltip:LangHelper.GetString(this.Id, "Receiver's User ID")
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    cols: [
                                                        {
                                                            rows:[
                                                                {
                                                                    view: "checkbox",
                                                                    name: "sender_name_enabled",
                                                                    labelRight:LangHelper.GetString(this.Id, "Sender name"),
                                                                    tooltip:LangHelper.GetString(this.Id, "Public sender name"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "text",
                                                                    name: "sender_name",
                                                                    value: "",
                                                                    placeholder:"petya_nickname",
                                                                    tooltip:LangHelper.GetString(this.Id, "Public sender name")
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            rows:[
                                                                {},
                                                                {
                                                                    view:"button",
                                                                    type:"icon",
                                                                    icon: "exchange",
                                                                    width:28,
                                                                    click:function ()
                                                                    {
                                                                        var left = _this.Views.CreateTaskContextForms.im.elements.sender_name;
                                                                        var left_val = _this.Views.CreateTaskContextForms.im.elements.sender_name.getValue();
                                                                        var right = _this.Views.CreateTaskContextForms.im.elements.receiver_name;
                                                                        var right_val = _this.Views.CreateTaskContextForms.im.elements.receiver_name.getValue();

                                                                        left.setValue(right_val);
                                                                        right.setValue(left_val);
                                                                    }
                                                                },
                                                            ]
                                                        },
                                                        {
                                                            rows:[
                                                                {
                                                                    view: "checkbox",
                                                                    name: "receiver_name_enabled",
                                                                    labelRight:LangHelper.GetString(this.Id, "Receiver name"),
                                                                    tooltip:LangHelper.GetString(this.Id, "Public receiver name"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "text",
                                                                    name: "receiver_name",
                                                                    value: "",
                                                                    placeholder:"vasya_nickname",
                                                                    tooltip:LangHelper.GetString(this.Id, "Public receiver name")
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    rows:[
                                                        {
                                                            view: "checkbox",
                                                            name: "message_enabled",
                                                            labelRight:LangHelper.GetString(this.Id, "Message"),
                                                            tooltip:LangHelper.GetString(this.Id, "The message text"),
                                                            value:0,
                                                            labelWidth:0
                                                        },
                                                        {
                                                            view: "text",
                                                            name: "message",
                                                            value: "",
                                                            placeholder:LangHelper.GetString(this.Id, "The message text"),
                                                            tooltip:LangHelper.GetString(this.Id, "The message text")
                                                        }
                                                    ]
                                                },
                                                {
                                                    rows:[
                                                        {
                                                            view: "checkbox",
                                                            name: "user_login_enabled",
                                                            labelRight:LangHelper.GetString(this.Id, "Username"),
                                                            tooltip:LangHelper.GetString(this.Id, "Username"),
                                                            value:0,
                                                            labelWidth:0
                                                        },
                                                        {
                                                            view: "text",
                                                            name: "user_login",
                                                            placeholder:LangHelper.GetString(this.Id, "Username"),
                                                            tooltip:LangHelper.GetString(this.Id, "Username")
                                                        }
                                                    ]
                                                },
                                                {
                                                    rows:[
                                                        {
                                                            view: "checkbox",
                                                            name: "user_password_enabled",
                                                            labelRight:LangHelper.GetString(this.Id, "User password"),
                                                            tooltip:LangHelper.GetString(this.Id, "User password"),
                                                            value:0,
                                                            labelWidth:0
                                                        },
                                                        {
                                                            view: "text",
                                                            name: "user_password",
                                                            placeholder:LangHelper.GetString(this.Id, "User password"),
                                                            tooltip:LangHelper.GetString(this.Id, "User password")
                                                        }
                                                    ]
                                                },
                                                {
                                                    rows:[
                                                        {
                                                            view: "checkbox",
                                                            name: "protocol_enabled",
                                                            labelRight:LangHelper.GetString(this.Id, "Protocol"),
                                                            tooltip:LangHelper.GetString(this.Id, "Protocol"),
                                                            value:0,
                                                            labelWidth:0
                                                        },
                                                        {
                                                            view: "richselect",
                                                            name: "protocol",
                                                            placeholder:LangHelper.GetString(this.Id, "Protocol"),
                                                            tooltip:LangHelper.GetString(this.Id, "Protocol"),
                                                            options:Helper.DictToArr(ImProtocolDic)
                                                        }
                                                    ]
                                                },
                                                {
                                                    rows:[
                                                        {
                                                            view: "checkbox",
                                                            name: "client_info_enabled",
                                                            labelRight:LangHelper.GetString(this.Id, "Source IP"),
                                                            tooltip:LangHelper.GetString(this.Id, "Source IP"),
                                                            value:0,
                                                            labelWidth:0
                                                        },
                                                        {
                                                            cols:[
                                                                {
                                                                    view: "text",
                                                                    name: "client_info_ip",
                                                                    placeholder:LangHelper.GetString(this.Id, "Source IP"),
                                                                    tooltip:LangHelper.GetString(this.Id, "Source IP"),
                                                                    validate:function(Val)
                                                                    {
                                                                        if(!this.elements.enabled.getValue())
                                                                            return true;

                                                                        if(!this.elements.client_info_enabled.getValue())
                                                                            return true;

                                                                        if(!this.elements.client_info_ip.getValue() && !this.elements.client_info_port.getValue())
                                                                            return false;

                                                                        if(Val)
                                                                            return Helper.ValidateIPaddress(Val);
                                                                        else
                                                                            return true;
                                                                    },
                                                                    invalidMessage: LangHelper.GetString("Dics", "Enter the IP address")
                                                                },
                                                                {
                                                                    view: "text",
                                                                    name: "client_info_port",
                                                                    placeholder:LangHelper.GetString(this.Id, "Source port"),
                                                                    tooltip:LangHelper.GetString(this.Id, "Source port"),
                                                                    validate:function(Val)
                                                                    {
                                                                        if(!this.elements.enabled.getValue())
                                                                            return true;

                                                                        if(!this.elements.client_info_enabled.getValue())
                                                                            return true;

                                                                        if(!this.elements.client_info_ip.getValue() && !this.elements.client_info_port.getValue())
                                                                            return false;

                                                                        return !isNaN(Val*1);
                                                                    },
                                                                    invalidMessage: LangHelper.GetString("Dics","Enter the number")
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    rows:[
                                                        {
                                                            view: "checkbox",
                                                            name: "server_info_enabled",
                                                            labelRight:LangHelper.GetString(this.Id, "Destination IP"),
                                                            tooltip:LangHelper.GetString(this.Id, "Destination IP"),
                                                            value:0,
                                                            labelWidth:0
                                                        },
                                                        {
                                                            cols:[
                                                                {
                                                                    view: "text",
                                                                    name: "server_info_ip",
                                                                    placeholder:LangHelper.GetString(this.Id, "Destination IP"),
                                                                    tooltip:LangHelper.GetString(this.Id, "Destination IP"),
                                                                    validate:function(Val)
                                                                    {
                                                                        if(!this.elements.enabled.getValue())
                                                                            return true;

                                                                        if(!this.elements.server_info_enabled.getValue())
                                                                            return true;

                                                                        if(!this.elements.server_info_ip.getValue() && !this.elements.server_info_port.getValue())
                                                                            return false;

                                                                        if(Val)
                                                                            return Helper.ValidateIPaddress(Val);
                                                                        else
                                                                            return true;
                                                                    },
                                                                    invalidMessage: LangHelper.GetString("Dics", "Enter the IP address")
                                                                },
                                                                {
                                                                    view: "text",
                                                                    name: "server_info_port",
                                                                    placeholder:LangHelper.GetString(this.Id, "Destination port"),
                                                                    tooltip:LangHelper.GetString(this.Id, "Destination port"),
                                                                    validate:function(Val)
                                                                    {
                                                                        if(!this.elements.enabled.getValue())
                                                                            return true;

                                                                        if(!this.elements.server_info_enabled.getValue())
                                                                            return true;

                                                                        if(!this.elements.server_info_ip.getValue() && !this.elements.server_info_port.getValue())
                                                                            return false;

                                                                        return !isNaN(Val*1);
                                                                    },
                                                                    invalidMessage: LangHelper.GetString("Dics","Enter the number")
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    rows:[
                                                        {
                                                            view: "checkbox",
                                                            name: "term_cause_enabled",
                                                            labelRight:LangHelper.GetString(this.Id, "Completion reason"),
                                                            tooltip:LangHelper.GetString(this.Id, "Connection completion reason"),
                                                            value:0,
                                                            labelWidth:0
                                                        },
                                                        {
                                                            view: "text",
                                                            name: "term_cause",
                                                            placeholder:LangHelper.GetString(this.Id, "Completion reason"),
                                                            tooltip:LangHelper.GetString(this.Id, "Connection completion reason"),
                                                            validate:function(Val)
                                                            {
                                                                if(!this.elements.enabled.getValue())
                                                                    return true;

                                                                if(!this.elements.term_cause_enabled.getValue())
                                                                    return true;

                                                                return !isNaN(Val*1);
                                                            },
                                                            invalidMessage: LangHelper.GetString("Dics","Enter the number"),
                                                            suggest: {
                                                                data:App.DicsManager.GetDicAsArray(DicsEnum.TermCausesDic),
                                                                template:"#id#",
                                                                filter:function(obj, value)
                                                                {
                                                                    return obj.value.indexOf(value) >= 0;
                                                                }
                                                            },
                                                            keyPressTimeout:500,
                                                            on:{
                                                                onTimedKeyPress: function()
                                                                {
                                                                    App.DicsManager.Refresh({data:[DicsEnum.TermCausesDic], filter:this.getValue()});
                                                                }
                                                            }
                                                        }
                                                    ]
                                                },
                                                {
                                                    rows:[
                                                        {
                                                            view: "checkbox",
                                                            name: "abonent_id_enabled",
                                                            labelRight:LangHelper.GetString(this.Id, "Subscriber ID"),
                                                            tooltip:LangHelper.GetString(this.Id, "Subscriber ID"),
                                                            value:0,
                                                            labelWidth:0
                                                        },
                                                        {
                                                            view: "text",
                                                            name: "abonent_id",
                                                            placeholder:LangHelper.GetString(this.Id, "Subscriber ID"),
                                                            tooltip:LangHelper.GetString(this.Id, "Subscriber ID")
                                                        }
                                                    ]
                                                },
                                                {}
                                            ]

                                        }
                                    ]
                                },
                                {
                                    id:this.Id + "CreateTaskContextForm" + "_" + "voip" + "_" + "tab",
                                    rows:[
                                        BlockHeaderTemplate({title:LangHelper.GetString(this.Id, "Voip"), icon:"file"}),
                                        {
                                            id:this.Id + "CreateTaskContextForm" + "_" + "voip",
                                            view: "form",
                                            margin: 0,
                                            padding: 8,
                                            scroll:"y",
                                            elements:[
                                                {
                                                    view: "checkbox",
                                                    name: "enabled",
                                                    labelRight: LangHelper.GetString(this.Id, "Enable search"),
                                                    value:0,
                                                    labelWidth:0,
                                                    on: {
                                                        onChange:function()
                                                        {
                                                            _this.SetCreateTaskContextFormTabbarCheckboxes();
                                                        }
                                                    },
                                                    validate:function(Val)
                                                    {
                                                        if(!Val)
                                                            return true;

                                                        return _this.ValidateFormOneParameterChecked(this);
                                                    },
                                                    invalidMessage: LangHelper.GetString(this.Id,"You must enable at least one query parameter")
                                                },
                                                {
                                                    rows:[
                                                        {
                                                            view: "checkbox",
                                                            name: "point_id_enabled",
                                                            labelRight:LangHelper.GetString(this.Id, "Connection point"),
                                                            tooltip:LangHelper.GetString(this.Id, "Connection point identifier to the data network"),
                                                            value:0,
                                                            labelWidth:0
                                                        },
                                                        {
                                                            view: "text",
                                                            name: "point_id",
                                                            placeholder:LangHelper.GetString(this.Id, "Connection point"),
                                                            tooltip:LangHelper.GetString(this.Id, "Connection point identifier to the data network"),
                                                            validate:function(Val)
                                                            {
                                                                if(!this.elements.enabled.getValue())
                                                                    return true;

                                                                if(!this.elements.point_id_enabled.getValue())
                                                                    return true;

                                                                return !isNaN(Val*1);
                                                            },
                                                            invalidMessage: LangHelper.GetString("Dics","Enter the number"),
                                                            suggest: {
                                                                data:App.DicsManager.GetDicAsArray(DicsEnum.IpDataPointsDic),
                                                                template:"#id#",
                                                                filter:function(obj, value)
                                                                {
                                                                    return obj.value.indexOf(value) >= 0;
                                                                }
                                                            },
                                                            keyPressTimeout:500,
                                                            on:{
                                                                onTimedKeyPress: function()
                                                                {
                                                                    App.DicsManager.Refresh({data:[DicsEnum.IpDataPointsDic], filter:this.getValue()});
                                                                }
                                                            }
                                                        }
                                                    ]
                                                },
                                                {
                                                    cols: [
                                                        {
                                                            rows:[
                                                                {
                                                                    view: "checkbox",
                                                                    name: "calling_number_enabled",
                                                                    labelRight:LangHelper.GetString(this.Id, "Calling number"),
                                                                    tooltip:LangHelper.GetString(this.Id, "Calling number"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "text",
                                                                    name: "calling_number",
                                                                    value: "",
                                                                    placeholder:LangHelper.GetString(this.Id, "Calling number"),
                                                                    tooltip:LangHelper.GetString(this.Id, "Calling number")
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            rows:[
                                                                {},
                                                                {
                                                                    view:"button",
                                                                    type:"icon",
                                                                    icon: "exchange",
                                                                    width:28,
                                                                    click:function ()
                                                                    {
                                                                        var left = _this.Views.CreateTaskContextForms.voip.elements.calling_number;
                                                                        var left_val = _this.Views.CreateTaskContextForms.voip.elements.calling_number.getValue();
                                                                        var right = _this.Views.CreateTaskContextForms.voip.elements.called_number;
                                                                        var right_val = _this.Views.CreateTaskContextForms.voip.elements.called_number.getValue();

                                                                        left.setValue(right_val);
                                                                        right.setValue(left_val);
                                                                    }
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            rows:[
                                                                {
                                                                    view: "checkbox",
                                                                    name: "called_number_enabled",
                                                                    labelRight:LangHelper.GetString(this.Id, "Called number"),
                                                                    tooltip:LangHelper.GetString(this.Id, "Called number"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "text",
                                                                    name: "called_number",
                                                                    value: "",
                                                                    placeholder:LangHelper.GetString(this.Id, "Called number"),
                                                                    tooltip:LangHelper.GetString(this.Id, "Called number")
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    cols: [
                                                        {
                                                            rows:[
                                                                {
                                                                    view: "checkbox",
                                                                    name: "calling_number_tn_enabled",
                                                                    labelRight:LangHelper.GetString(this.Id, "Calling number") + " " + LangHelper.GetString(this.Id, "after conv."),
                                                                    tooltip:LangHelper.GetString(this.Id, "Calling number") + " " + LangHelper.GetString(this.Id, "after conversion"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "text",
                                                                    name: "calling_number_tn",
                                                                    value: "",
                                                                    placeholder:LangHelper.GetString(this.Id, "Calling number") + " " + LangHelper.GetString(this.Id, "after conversion"),
                                                                    tooltip:LangHelper.GetString(this.Id, "Calling number") + " " + LangHelper.GetString(this.Id, "after conversion")
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            rows:[
                                                                {},
                                                                {
                                                                    view:"button",
                                                                    type:"icon",
                                                                    icon: "exchange",
                                                                    width:28,
                                                                    click:function ()
                                                                    {
                                                                        var left = _this.Views.CreateTaskContextForms.voip.elements.calling_number_tn;
                                                                        var left_val = _this.Views.CreateTaskContextForms.voip.elements.calling_number_tn.getValue();
                                                                        var right = _this.Views.CreateTaskContextForms.voip.elements.called_number_tn;
                                                                        var right_val = _this.Views.CreateTaskContextForms.voip.elements.called_number_tn.getValue();

                                                                        left.setValue(right_val);
                                                                        right.setValue(left_val);
                                                                    }
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            rows:[
                                                                {
                                                                    view: "checkbox",
                                                                    name: "called_number_tn_enabled",
                                                                    labelRight:LangHelper.GetString(this.Id, "Called number") + " " + LangHelper.GetString(this.Id, "after conv."),
                                                                    tooltip:LangHelper.GetString(this.Id, "Called number") + " " + LangHelper.GetString(this.Id, "after conversion"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "text",
                                                                    name: "called_number_tn",
                                                                    value: "",
                                                                    placeholder:LangHelper.GetString(this.Id, "Called number") + " " + LangHelper.GetString(this.Id, "after conversion"),
                                                                    tooltip:LangHelper.GetString(this.Id, "Called number") + " " + LangHelper.GetString(this.Id, "after conversion")
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    cols: [
                                                        {
                                                            rows:[
                                                                {
                                                                    view: "checkbox",
                                                                    name: "calling_number_e164_enabled",
                                                                    labelRight:LangHelper.GetString(this.Id, "Calling number") + " " + LangHelper.GetString(this.Id, "in the E164 network"),
                                                                    tooltip:LangHelper.GetString(this.Id, "Calling number") + " " + LangHelper.GetString(this.Id, "in the E164 network"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "text",
                                                                    name: "calling_number_e164",
                                                                    value: "",
                                                                    placeholder:LangHelper.GetString(this.Id, "Calling number") + " " + LangHelper.GetString(this.Id, "in the E164 network"),
                                                                    tooltip:LangHelper.GetString(this.Id, "Calling number") + " " + LangHelper.GetString(this.Id, "in the E164 network")
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            rows:[
                                                                {},
                                                                {
                                                                    view:"button",
                                                                    type:"icon",
                                                                    icon: "exchange",
                                                                    width:28,
                                                                    click:function ()
                                                                    {
                                                                        var left = _this.Views.CreateTaskContextForms.voip.elements.calling_number_e164;
                                                                        var left_val = _this.Views.CreateTaskContextForms.voip.elements.calling_number_e164.getValue();
                                                                        var right = _this.Views.CreateTaskContextForms.voip.elements.called_number_e164;
                                                                        var right_val = _this.Views.CreateTaskContextForms.voip.elements.called_number_e164.getValue();

                                                                        left.setValue(right_val);
                                                                        right.setValue(left_val);
                                                                    }
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            rows:[
                                                                {
                                                                    view: "checkbox",
                                                                    name: "called_number_e164_enabled",
                                                                    labelRight:LangHelper.GetString(this.Id, "Called number") + " " + LangHelper.GetString(this.Id, "in the E164 network"),
                                                                    tooltip:LangHelper.GetString(this.Id, "Called number") + " " + LangHelper.GetString(this.Id, "in the E164 network"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "text",
                                                                    name: "called_number_e164",
                                                                    value: "",
                                                                    placeholder:LangHelper.GetString(this.Id, "Called number") + " " + LangHelper.GetString(this.Id, "in the E164 network"),
                                                                    tooltip:LangHelper.GetString(this.Id, "Called number") + " " + LangHelper.GetString(this.Id, "in the E164 network")
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    rows:[
                                                        {
                                                            view: "checkbox",
                                                            name: "duration_enabled",
                                                            labelRight:LangHelper.GetString(this.Id, "Duration of conversation"),
                                                            tooltip:LangHelper.GetString(this.Id, "Duration of conversation, sec."),
                                                            value:0,
                                                            labelWidth:0
                                                        },
                                                        {
                                                            view: "text",
                                                            name: "duration",
                                                            placeholder:LangHelper.GetString(this.Id, "Duration of conversation"),
                                                            tooltip:LangHelper.GetString(this.Id, "Duration of conversation, sec."),
                                                            validate:function(Val)
                                                            {
                                                                if(!this.elements.enabled.getValue())
                                                                    return true;

                                                                if(!this.elements.duration_enabled.getValue())
                                                                    return true;

                                                                return !isNaN(Val*1);
                                                            },
                                                            invalidMessage: LangHelper.GetString("Dics","Enter the number")
                                                        }
                                                    ]
                                                },
                                                {
                                                    rows:[
                                                        {
                                                            view: "checkbox",
                                                            name: "call_type_enabled",
                                                            labelRight:LangHelper.GetString(this.Id, "Connection method"),
                                                            tooltip:LangHelper.GetString(this.Id, "Connection method"),
                                                            value:0,
                                                            labelWidth:0
                                                        },
                                                        {
                                                            view: "text",
                                                            name: "call_type",
                                                            placeholder:LangHelper.GetString(this.Id, "Connection method"),
                                                            tooltip:LangHelper.GetString(this.Id, "Connection method"),
                                                            validate:function(Val)
                                                            {
                                                                if(!this.elements.enabled.getValue())
                                                                    return true;

                                                                if(!this.elements.call_type_enabled.getValue())
                                                                    return true;

                                                                return !isNaN(Val*1);
                                                            },
                                                            invalidMessage: LangHelper.GetString("Dics","Enter the number")
                                                        }
                                                    ]
                                                },
                                                {
                                                    rows:[
                                                        {
                                                            view: "checkbox",
                                                            name: "originator_name_enabled",
                                                            labelRight:LangHelper.GetString(this.Id, "Initiator name"),
                                                            tooltip:LangHelper.GetString(this.Id, "Name of the initiator of communication"),
                                                            value:0,
                                                            labelWidth:0
                                                        },
                                                        {
                                                            view: "text",
                                                            name: "originator_name",
                                                            value: "",
                                                            placeholder:LangHelper.GetString(this.Id, "Initiator name"),
                                                            tooltip:LangHelper.GetString(this.Id, "Name of the initiator of communication")
                                                        }
                                                    ]
                                                },
                                                {
                                                    rows:[
                                                        {
                                                            view: "checkbox",
                                                            name: "conference_id_enabled",
                                                            labelRight:LangHelper.GetString(this.Id, "Conference ID"),
                                                            tooltip:LangHelper.GetString(this.Id, "Conference ID"),
                                                            value:0,
                                                            labelWidth:0
                                                        },
                                                        {
                                                            view: "text",
                                                            name: "conference_id",
                                                            value: "",
                                                            placeholder:LangHelper.GetString(this.Id, "Conference ID"),
                                                            tooltip:LangHelper.GetString(this.Id, "Conference ID")
                                                        }
                                                    ]
                                                },
                                                {
                                                    rows:[
                                                        {
                                                            view: "checkbox",
                                                            name: "inbound_bunch_gsm_enabled",
                                                            labelRight:LangHelper.GetString(this.Id, "Inbound bunch") + " " + LangHelper.GetString(this.Id, "for GSM network"),
                                                            tooltip:LangHelper.GetString(this.Id, "Inbound bunch") + " " + LangHelper.GetString(this.Id, "for GSM network"),
                                                            value:0,
                                                            labelWidth:0
                                                        },
                                                        {
                                                            view: "text",
                                                            name: "inbound_bunch_gsm",
                                                            placeholder:LangHelper.GetString(this.Id, "ID of inbound bunch") + " " + LangHelper.GetString(this.Id, "for GSM network"),
                                                            tooltip:LangHelper.GetString(this.Id, "ID of inbound bunch") + " " + LangHelper.GetString(this.Id, "for GSM network"),
                                                            validate:function(Val)
                                                            {
                                                                if(!this.elements.enabled.getValue())
                                                                    return true;

                                                                if(!this.elements.inbound_bunch_gsm_enabled.getValue())
                                                                    return true;

                                                                return !isNaN(Val*1);
                                                            },
                                                            invalidMessage: LangHelper.GetString("Dics","Enter the number"),
                                                            suggest: {
                                                                data:App.DicsManager.GetDicAsArrayWithFilter(DicsEnum.BunchesDic, "dic_id_type", "0"),
                                                                template:"#id#",
                                                                filter:function(obj, value)
                                                                {
                                                                    return (obj.id + obj.value).indexOf(value) >= 0;
                                                                }
                                                            },
                                                            keyPressTimeout:500,
                                                            on:{
                                                                onTimedKeyPress: function()
                                                                {
                                                                    App.DicsManager.Refresh({data:[DicsEnum.BunchesDic], filter:this.getValue()});
                                                                }
                                                            }
                                                        }
                                                    ]
                                                },
                                                {
                                                    rows:[
                                                        {
                                                            view: "checkbox",
                                                            name: "inbound_bunch_cdma_umts_enabled",
                                                            labelRight:LangHelper.GetString(this.Id, "Inbound bunch") + " " + LangHelper.GetString(this.Id, "for W/CDMA, UMTS network"),
                                                            tooltip:LangHelper.GetString(this.Id, "Inbound bunch") + " " + LangHelper.GetString(this.Id, "for W/CDMA, UMTS network"),
                                                            value:0,
                                                            labelWidth:0
                                                        },
                                                        {
                                                            cols:[
                                                                {
                                                                    view: "text",
                                                                    name: "inbound_bunch_cdma_umts_mac",
                                                                    placeholder:LangHelper.GetString(this.Id, "MAC address"),
                                                                    tooltip:LangHelper.GetString(this.Id, "MAC address"),
                                                                    suggest: {
                                                                        data:App.DicsManager.GetDicAsArrayWithFilter(DicsEnum.BunchesDic, "dic_id_type", "1"),
                                                                        template:"#id#",
                                                                        filter:function(obj, value)
                                                                        {
                                                                            return (obj.id + obj.value).indexOf(value) >= 0;
                                                                        }
                                                                    },
                                                                    keyPressTimeout:500,
                                                                    on:{
                                                                        onTimedKeyPress: function()
                                                                        {
                                                                            App.DicsManager.Refresh({data:[DicsEnum.BunchesDic], filter:this.getValue()});
                                                                        }
                                                                    }
                                                                },
                                                                {
                                                                    view: "text",
                                                                    name: "inbound_bunch_cdma_umts_atm_vpi",
                                                                    placeholder:LangHelper.GetString(this.Id, "ATM virtual path number (VPI)"),
                                                                    tooltip:LangHelper.GetString(this.Id, "ATM virtual path number (VPI)"),
                                                                    suggest: {
                                                                        data:App.DicsManager.GetDicAsArrayWithFilter(DicsEnum.BunchesDic, "dic_id_type", "2"),
                                                                        template:"#bunch_id_cdma_umts_atm_vpi#",
                                                                        filter:function(obj, value)
                                                                        {
                                                                            return (obj.id + obj.value).indexOf(value) >= 0;
                                                                        }
                                                                    },
                                                                    keyPressTimeout:500,
                                                                    on:{
                                                                        onTimedKeyPress: function()
                                                                        {
                                                                            App.DicsManager.Refresh({data:[DicsEnum.BunchesDic], filter:this.getValue()});
                                                                        }
                                                                    }
                                                                },
                                                                {
                                                                    view: "text",
                                                                    name: "inbound_bunch_cdma_umts_atm_vci",
                                                                    placeholder:LangHelper.GetString(this.Id, "ATM virtual channel number (VCI)"),
                                                                    tooltip:LangHelper.GetString(this.Id, "ATM virtual channel number (VCI)"),
                                                                    suggest: {
                                                                        data:App.DicsManager.GetDicAsArrayWithFilter(DicsEnum.BunchesDic, "dic_id_type", "3"),
                                                                        template:"#bunch_id_cdma_umts_atm_vci#",
                                                                        filter:function(obj, value)
                                                                        {
                                                                            return (obj.id + obj.value).indexOf(value) >= 0;
                                                                        }
                                                                    },
                                                                    keyPressTimeout:500,
                                                                    on:{
                                                                        onTimedKeyPress: function()
                                                                        {
                                                                            App.DicsManager.Refresh({data:[DicsEnum.BunchesDic], filter:this.getValue()});
                                                                        }
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    rows:[
                                                        {
                                                            view: "checkbox",
                                                            name: "outbound_bunch_gsm_enabled",
                                                            labelRight:LangHelper.GetString(this.Id, "Outbound bunch") + " " + LangHelper.GetString(this.Id, "for GSM network"),
                                                            tooltip:LangHelper.GetString(this.Id, "Outbound bunch") + " " + LangHelper.GetString(this.Id, "for GSM network"),
                                                            value:0,
                                                            labelWidth:0
                                                        },
                                                        {
                                                            view: "text",
                                                            name: "outbound_bunch_gsm",
                                                            placeholder:LangHelper.GetString(this.Id, "ID of outbound bunch") + " " + LangHelper.GetString(this.Id, "for GSM network"),
                                                            tooltip:LangHelper.GetString(this.Id, "ID of outbound bunch") + " " + LangHelper.GetString(this.Id, "for GSM network"),
                                                            validate:function(Val)
                                                            {
                                                                if(!this.elements.enabled.getValue())
                                                                    return true;

                                                                if(!this.elements.outbound_bunch_gsm_enabled.getValue())
                                                                    return true;

                                                                return !isNaN(Val*1);
                                                            },
                                                            invalidMessage: LangHelper.GetString("Dics","Enter the number"),
                                                            suggest: {
                                                                data:App.DicsManager.GetDicAsArrayWithFilter(DicsEnum.BunchesDic, "dic_id_type", "0"),
                                                                template:"#id#",
                                                                filter:function(obj, value)
                                                                {
                                                                    return (obj.id + obj.value).indexOf(value) >= 0;
                                                                }
                                                            },
                                                            keyPressTimeout:500,
                                                            on:{
                                                                onTimedKeyPress: function()
                                                                {
                                                                    App.DicsManager.Refresh({data:[DicsEnum.BunchesDic], filter:this.getValue()});
                                                                }
                                                            }
                                                        }
                                                    ]
                                                },
                                                {
                                                    rows:[
                                                        {
                                                            view: "checkbox",
                                                            name: "outbound_bunch_cdma_umts_enabled",
                                                            labelRight:LangHelper.GetString(this.Id, "Outbound bunch") + " " + LangHelper.GetString(this.Id, "for W/CDMA, UMTS network"),
                                                            tooltip:LangHelper.GetString(this.Id, "Outbound bunch") + " " + LangHelper.GetString(this.Id, "for W/CDMA, UMTS network"),
                                                            value:0,
                                                            labelWidth:0
                                                        },
                                                        {
                                                            cols:[
                                                                {
                                                                    view: "text",
                                                                    name: "outbound_bunch_cdma_umts_mac",
                                                                    placeholder:LangHelper.GetString(this.Id, "MAC address"),
                                                                    tooltip:LangHelper.GetString(this.Id, "MAC address"),
                                                                    suggest: {
                                                                        data:App.DicsManager.GetDicAsArrayWithFilter(DicsEnum.BunchesDic, "dic_id_type", "1"),
                                                                        template:"#id#",
                                                                        filter:function(obj, value)
                                                                        {
                                                                            return (obj.id + obj.value).indexOf(value) >= 0;
                                                                        }
                                                                    },
                                                                    keyPressTimeout:500,
                                                                    on:{
                                                                        onTimedKeyPress: function()
                                                                        {
                                                                            App.DicsManager.Refresh({data:[DicsEnum.BunchesDic], filter:this.getValue()});
                                                                        }
                                                                    }
                                                                },
                                                                {
                                                                    view: "text",
                                                                    name: "outbound_bunch_cdma_umts_atm_vpi",
                                                                    placeholder:LangHelper.GetString(this.Id, "ATM virtual path number (VPI)"),
                                                                    tooltip:LangHelper.GetString(this.Id, "ATM virtual path number (VPI)"),
                                                                    suggest: {
                                                                        data:App.DicsManager.GetDicAsArrayWithFilter(DicsEnum.BunchesDic, "dic_id_type", "2"),
                                                                        template:"#bunch_id_cdma_umts_atm_vpi#",
                                                                        filter:function(obj, value)
                                                                        {
                                                                            return (obj.id + obj.value).indexOf(value) >= 0;
                                                                        }
                                                                    },
                                                                    keyPressTimeout:500,
                                                                    on:{
                                                                        onTimedKeyPress: function()
                                                                        {
                                                                            App.DicsManager.Refresh({data:[DicsEnum.BunchesDic], filter:this.getValue()});
                                                                        }
                                                                    }
                                                                },
                                                                {
                                                                    view: "text",
                                                                    name: "outbound_bunch_cdma_umts_atm_vci",
                                                                    placeholder:LangHelper.GetString(this.Id, "ATM virtual channel number (VCI)"),
                                                                    tooltip:LangHelper.GetString(this.Id, "ATM virtual channel number (VCI)"),
                                                                    suggest: {
                                                                        data:App.DicsManager.GetDicAsArrayWithFilter(DicsEnum.BunchesDic, "dic_id_type", "3"),
                                                                        template:"#bunch_id_cdma_umts_atm_vci#",
                                                                        filter:function(obj, value)
                                                                        {
                                                                            return (obj.id + obj.value).indexOf(value) >= 0;
                                                                        }
                                                                    },
                                                                    keyPressTimeout:500,
                                                                    on:{
                                                                        onTimedKeyPress: function()
                                                                        {
                                                                            App.DicsManager.Refresh({data:[DicsEnum.BunchesDic], filter:this.getValue()});
                                                                        }
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    rows:[
                                                        {
                                                            view: "checkbox",
                                                            name: "protocol_enabled",
                                                            labelRight:LangHelper.GetString(this.Id, "Protocol"),
                                                            tooltip:LangHelper.GetString(this.Id, "Protocol"),
                                                            value:0,
                                                            labelWidth:0
                                                        },
                                                        {
                                                            view: "richselect",
                                                            name: "protocol",
                                                            placeholder:LangHelper.GetString(this.Id, "Protocol"),
                                                            tooltip:LangHelper.GetString(this.Id, "Protocol"),
                                                            options:Helper.DictToArr(VoipProtocolDic)
                                                        }
                                                    ]
                                                },
                                                {
                                                    rows:[
                                                        {
                                                            view: "checkbox",
                                                            name: "client_info_enabled",
                                                            labelRight:LangHelper.GetString(this.Id, "Source IP"),
                                                            tooltip:LangHelper.GetString(this.Id, "Source IP"),
                                                            value:0,
                                                            labelWidth:0
                                                        },
                                                        {
                                                            cols:[
                                                                {
                                                                    view: "text",
                                                                    name: "client_info_ip",
                                                                    placeholder:LangHelper.GetString(this.Id, "Source IP"),
                                                                    tooltip:LangHelper.GetString(this.Id, "Source IP"),
                                                                    validate:function(Val)
                                                                    {
                                                                        if(!this.elements.enabled.getValue())
                                                                            return true;

                                                                        if(!this.elements.client_info_enabled.getValue())
                                                                            return true;

                                                                        if(!this.elements.client_info_ip.getValue() && !this.elements.client_info_port.getValue())
                                                                            return false;

                                                                        if(Val)
                                                                            return Helper.ValidateIPaddress(Val);
                                                                        else
                                                                            return true;
                                                                    },
                                                                    invalidMessage: LangHelper.GetString("Dics", "Enter the IP address")
                                                                },
                                                                {
                                                                    view: "text",
                                                                    name: "client_info_port",
                                                                    placeholder:LangHelper.GetString(this.Id, "Source port"),
                                                                    tooltip:LangHelper.GetString(this.Id, "Source port"),
                                                                    validate:function(Val)
                                                                    {
                                                                        if(!this.elements.enabled.getValue())
                                                                            return true;

                                                                        if(!this.elements.client_info_enabled.getValue())
                                                                            return true;

                                                                        if(!this.elements.client_info_ip.getValue() && !this.elements.client_info_port.getValue())
                                                                            return false;

                                                                        return !isNaN(Val*1);
                                                                    },
                                                                    invalidMessage: LangHelper.GetString("Dics","Enter the number")
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    rows:[
                                                        {
                                                            view: "checkbox",
                                                            name: "server_info_enabled",
                                                            labelRight:LangHelper.GetString(this.Id, "Destination IP"),
                                                            tooltip:LangHelper.GetString(this.Id, "Destination IP"),
                                                            value:0,
                                                            labelWidth:0
                                                        },
                                                        {
                                                            cols:[
                                                                {
                                                                    view: "text",
                                                                    name: "server_info_ip",
                                                                    placeholder:LangHelper.GetString(this.Id, "Destination IP"),
                                                                    tooltip:LangHelper.GetString(this.Id, "Destination IP"),
                                                                    validate:function(Val)
                                                                    {
                                                                        if(!this.elements.enabled.getValue())
                                                                            return true;

                                                                        if(!this.elements.server_info_enabled.getValue())
                                                                            return true;

                                                                        if(!this.elements.server_info_ip.getValue() && !this.elements.server_info_port.getValue())
                                                                            return false;

                                                                        if(Val)
                                                                            return Helper.ValidateIPaddress(Val);
                                                                        else
                                                                            return true;
                                                                    },
                                                                    invalidMessage: LangHelper.GetString("Dics", "Enter the IP address")
                                                                },
                                                                {
                                                                    view: "text",
                                                                    name: "server_info_port",
                                                                    placeholder:LangHelper.GetString(this.Id, "Destination port"),
                                                                    tooltip:LangHelper.GetString(this.Id, "Destination port"),
                                                                    validate:function(Val)
                                                                    {
                                                                        if(!this.elements.enabled.getValue())
                                                                            return true;

                                                                        if(!this.elements.server_info_enabled.getValue())
                                                                            return true;

                                                                        if(!this.elements.server_info_ip.getValue() && !this.elements.server_info_port.getValue())
                                                                            return false;

                                                                        return !isNaN(Val*1);
                                                                    },
                                                                    invalidMessage: LangHelper.GetString("Dics","Enter the number")
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    rows:[
                                                        {
                                                            view: "checkbox",
                                                            name: "term_cause_enabled",
                                                            labelRight:LangHelper.GetString(this.Id, "Completion reason"),
                                                            tooltip:LangHelper.GetString(this.Id, "Connection completion reason"),
                                                            value:0,
                                                            labelWidth:0
                                                        },
                                                        {
                                                            view: "text",
                                                            name: "term_cause",
                                                            placeholder:LangHelper.GetString(this.Id, "Completion reason"),
                                                            tooltip:LangHelper.GetString(this.Id, "Connection completion reason"),
                                                            validate:function(Val)
                                                            {
                                                                if(!this.elements.enabled.getValue())
                                                                    return true;

                                                                if(!this.elements.term_cause_enabled.getValue())
                                                                    return true;

                                                                return !isNaN(Val*1);
                                                            },
                                                            invalidMessage: LangHelper.GetString("Dics","Enter the number"),
                                                            suggest: {
                                                                data:App.DicsManager.GetDicAsArray(DicsEnum.TermCausesDic),
                                                                template:"#id#",
                                                                filter:function(obj, value)
                                                                {
                                                                    return obj.value.indexOf(value) >= 0;
                                                                }
                                                            },
                                                            keyPressTimeout:500,
                                                            on:{
                                                                onTimedKeyPress: function()
                                                                {
                                                                    App.DicsManager.Refresh({data:[DicsEnum.TermCausesDic], filter:this.getValue()});
                                                                }
                                                            }
                                                        }
                                                    ]
                                                },
                                                {
                                                    rows:[
                                                        {
                                                            view: "checkbox",
                                                            name: "abonent_id_enabled",
                                                            labelRight:LangHelper.GetString(this.Id, "Subscriber ID"),
                                                            tooltip:LangHelper.GetString(this.Id, "Subscriber ID"),
                                                            value:0,
                                                            labelWidth:0
                                                        },
                                                        {
                                                            view: "text",
                                                            name: "abonent_id",
                                                            placeholder:LangHelper.GetString(this.Id, "Subscriber ID"),
                                                            tooltip:LangHelper.GetString(this.Id, "Subscriber ID")
                                                        }
                                                    ]
                                                },
                                                {}
                                            ]

                                        }
                                    ]
                                },
                                {
                                    id:this.Id + "CreateTaskContextForm" + "_" + "pstn" + "_" + "tab",
                                    rows:[
                                        BlockHeaderTemplate({title:LangHelper.GetString(this.Id, "Pstn"), icon:"file"}),
                                        {
                                            id:this.Id + "CreateTaskContextForm" + "_" + "pstn",
                                            view: "form",
                                            margin: 0,
                                            padding: 8,
                                            scroll:"y",
                                            elements:[
                                                {
                                                    view: "checkbox",
                                                    name: "enabled",
                                                    labelRight: LangHelper.GetString(this.Id, "Enable search"),
                                                    value:0,
                                                    labelWidth:0,
                                                    on: {
                                                        onChange:function()
                                                        {
                                                            _this.SetCreateTaskContextFormTabbarCheckboxes();
                                                        }
                                                    },
                                                    validate:function(Val)
                                                    {
                                                        if(!Val)
                                                            return true;

                                                        return _this.ValidateFormOneParameterChecked(this);
                                                    },
                                                    invalidMessage: LangHelper.GetString(this.Id,"You must enable at least one query parameter")
                                                },
                                                {
                                                    cols: [
                                                        {
                                                            rows:[
                                                                {
                                                                    view: "checkbox",
                                                                    name: "in_info_directory_number_enabled",
                                                                    labelRight:LangHelper.GetString(this.Id, "Caller's phone number"),
                                                                    tooltip:LangHelper.GetString(this.Id, "Caller's phone number"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "text",
                                                                    name: "in_info_directory_number",
                                                                    placeholder:LangHelper.GetString(this.Id, "Caller's phone number"),
                                                                    tooltip:LangHelper.GetString(this.Id, "Caller's phone number")
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            rows:[
                                                                {},
                                                                {
                                                                    view:"button",
                                                                    type:"icon",
                                                                    icon: "exchange",
                                                                    width:28,
                                                                    click:function ()
                                                                    {
                                                                        var left = _this.Views.CreateTaskContextForms.pstn.elements.in_info_directory_number;
                                                                        var left_val = _this.Views.CreateTaskContextForms.pstn.elements.in_info_directory_number.getValue();
                                                                        var right = _this.Views.CreateTaskContextForms.pstn.elements.out_info_directory_number;
                                                                        var right_val = _this.Views.CreateTaskContextForms.pstn.elements.out_info_directory_number.getValue();

                                                                        left.setValue(right_val);
                                                                        right.setValue(left_val);
                                                                    }
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            rows:[
                                                                {
                                                                    view: "checkbox",
                                                                    name: "out_info_directory_number_enabled",
                                                                    labelRight:LangHelper.GetString(this.Id, "Phone number of called"),
                                                                    tooltip:LangHelper.GetString(this.Id, "Phone number of called"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "text",
                                                                    name: "out_info_directory_number",
                                                                    value: "",
                                                                    placeholder:LangHelper.GetString(this.Id, "Phone number of called"),
                                                                    tooltip:LangHelper.GetString(this.Id, "Phone number of called")
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    cols: [
                                                        {
                                                            rows:[
                                                                {
                                                                    view: "checkbox",
                                                                    name: "in_info_internal_number_enabled",
                                                                    labelRight:LangHelper.GetString(this.Id, "Caller's internal number"),
                                                                    tooltip:LangHelper.GetString(this.Id, "Caller's internal number"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "text",
                                                                    name: "in_info_internal_number",
                                                                    placeholder:LangHelper.GetString(this.Id, "Caller's internal number"),
                                                                    tooltip:LangHelper.GetString(this.Id, "Caller's internal number")
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            rows:[
                                                                {},
                                                                {
                                                                    view:"button",
                                                                    type:"icon",
                                                                    icon: "exchange",
                                                                    width:28,
                                                                    click:function ()
                                                                    {
                                                                        var left = _this.Views.CreateTaskContextForms.pstn.elements.in_info_internal_number;
                                                                        var left_val = _this.Views.CreateTaskContextForms.pstn.elements.in_info_internal_number.getValue();
                                                                        var right = _this.Views.CreateTaskContextForms.pstn.elements.out_info_internal_number;
                                                                        var right_val = _this.Views.CreateTaskContextForms.pstn.elements.out_info_internal_number.getValue();

                                                                        left.setValue(right_val);
                                                                        right.setValue(left_val);
                                                                    }
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            rows:[
                                                                {
                                                                    view: "checkbox",
                                                                    name: "out_info_internal_number_enabled",
                                                                    labelRight:LangHelper.GetString(this.Id, "Internal number of called"),
                                                                    tooltip:LangHelper.GetString(this.Id, "Internal number of called"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "text",
                                                                    name: "out_info_internal_number",
                                                                    value: "",
                                                                    placeholder:LangHelper.GetString(this.Id, "Internal number of called"),
                                                                    tooltip:LangHelper.GetString(this.Id, "Internal number of called")
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    rows:[
                                                        {
                                                            view: "checkbox",
                                                            name: "duration_enabled",
                                                            labelRight:LangHelper.GetString(this.Id, "Duration of conversation"),
                                                            tooltip:LangHelper.GetString(this.Id, "Duration of conversation, sec."),
                                                            value:0,
                                                            labelWidth:0
                                                        },
                                                        {
                                                            view: "text",
                                                            name: "duration",
                                                            placeholder:LangHelper.GetString(this.Id, "Duration of conversation"),
                                                            tooltip:LangHelper.GetString(this.Id, "Duration of conversation, sec."),
                                                            validate:function(Val)
                                                            {
                                                                if(!this.elements.enabled.getValue())
                                                                    return true;

                                                                if(!this.elements.duration_enabled.getValue())
                                                                    return true;

                                                                return !isNaN(Val*1);
                                                            },
                                                            invalidMessage: LangHelper.GetString("Dics","Enter the number")
                                                        }
                                                    ]
                                                },
                                                {
                                                    rows:[
                                                        {
                                                            view: "checkbox",
                                                            name: "call_type_enabled",
                                                            labelRight:LangHelper.GetString(this.Id, "Connection method"),
                                                            tooltip:LangHelper.GetString(this.Id, "Connection method"),
                                                            value:0,
                                                            labelWidth:0
                                                        },
                                                        {
                                                            view: "text",
                                                            name: "call_type",
                                                            placeholder:LangHelper.GetString(this.Id, "Connection method"),
                                                            tooltip:LangHelper.GetString(this.Id, "Connection method"),
                                                            validate:function(Val)
                                                            {
                                                                if(!this.elements.enabled.getValue())
                                                                    return true;

                                                                if(!this.elements.call_type_enabled.getValue())
                                                                    return true;

                                                                return !isNaN(Val*1);
                                                            },
                                                            invalidMessage: LangHelper.GetString("Dics","Enter the number")
                                                        }
                                                    ]
                                                },
                                                {
                                                    cols:[
                                                        {
                                                            rows:[
                                                                {
                                                                    view: "checkbox",
                                                                    name: "in_abonent_type_enabled",
                                                                    labelRight:LangHelper.GetString(this.Id, "Caller type"),
                                                                    tooltip:LangHelper.GetString(this.Id, "Caller type"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "richselect",
                                                                    name: "in_abonent_type",
                                                                    placeholder:LangHelper.GetString(this.Id, "Caller type"),
                                                                    tooltip:LangHelper.GetString(this.Id, "Caller type"),
                                                                    validate:function(Val)
                                                                    {
                                                                        if(!this.elements.enabled.getValue())
                                                                            return true;

                                                                        if(!this.elements.in_abonent_type_enabled.getValue())
                                                                            return true;

                                                                        return !isNaN(Val*1);
                                                                    },
                                                                    invalidMessage: LangHelper.GetString("Dics","Enter the number"),
                                                                    options:Helper.DictToArr(PstnPhoneAbonentTypeDic)
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            rows:[
                                                                {
                                                                    view: "checkbox",
                                                                    name: "out_abonent_type_enabled",
                                                                    labelRight:LangHelper.GetString(this.Id, "Type of called"),
                                                                    tooltip:LangHelper.GetString(this.Id, "Type of called"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "richselect",
                                                                    name: "out_abonent_type",
                                                                    placeholder:LangHelper.GetString(this.Id, "Type of called"),
                                                                    tooltip:LangHelper.GetString(this.Id, "Type of called"),
                                                                    validate:function(Val)
                                                                    {
                                                                        if(!this.elements.enabled.getValue())
                                                                            return true;

                                                                        if(!this.elements.out_abonent_type.getValue())
                                                                            return true;

                                                                        return !isNaN(Val*1);
                                                                    },
                                                                    invalidMessage: LangHelper.GetString("Dics","Enter the number"),
                                                                    options:Helper.DictToArr(PstnPhoneAbonentTypeDic)
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    rows:[
                                                        {
                                                            view: "checkbox",
                                                            name: "switch_id_enabled",
                                                            labelRight:LangHelper.GetString(this.Id, "Switch code"),
                                                            tooltip:LangHelper.GetString(this.Id, "Switch code"),
                                                            value:0,
                                                            labelWidth:0
                                                        },
                                                        {
                                                            view: "text",
                                                            name: "switch_id",
                                                            value: "",
                                                            placeholder:LangHelper.GetString(this.Id, "Switch code"),
                                                            tooltip:LangHelper.GetString(this.Id, "Switch code"),
                                                            suggest: {
                                                                data:App.DicsManager.GetDicAsArray(DicsEnum.SwitchesDic),
                                                                template:"#id#",
                                                                filter:function(obj, value)
                                                                {
                                                                    return obj.value.indexOf(value) >= 0;
                                                                }
                                                            },
                                                            keyPressTimeout:500,
                                                            on:{
                                                                onTimedKeyPress: function()
                                                                {
                                                                    App.DicsManager.Refresh({data:[DicsEnum.SwitchesDic], filter:this.getValue()});
                                                                }
                                                            }
                                                        }
                                                    ]
                                                },
                                                {
                                                    rows:[
                                                        {
                                                            view: "checkbox",
                                                            name: "border_switch_id_enabled",
                                                            labelRight:LangHelper.GetString(this.Id, "Border switch code"),
                                                            tooltip:LangHelper.GetString(this.Id, "Border switch code"),
                                                            value:0,
                                                            labelWidth:0
                                                        },
                                                        {
                                                            view: "text",
                                                            name: "border_switch_id",
                                                            value: "",
                                                            placeholder:LangHelper.GetString(this.Id, "Border switch code"),
                                                            tooltip:LangHelper.GetString(this.Id, "Border switch code"),
                                                            suggest: {
                                                                data:App.DicsManager.GetDicAsArray(DicsEnum.SwitchesDic),
                                                                template:"#id#",
                                                                filter:function(obj, value)
                                                                {
                                                                    return obj.value.indexOf(value) >= 0;
                                                                }
                                                            },
                                                            keyPressTimeout:500,
                                                            on:{
                                                                onTimedKeyPress: function()
                                                                {
                                                                    App.DicsManager.Refresh({data:[DicsEnum.SwitchesDic], filter:this.getValue()});
                                                                }
                                                            }
                                                        }
                                                    ]
                                                },
                                                {
                                                    rows:[
                                                        {
                                                            view: "checkbox",
                                                            name: "supplement_service_id_enabled",
                                                            labelRight:LangHelper.GetString(this.Id, "Additional services for connection"),
                                                            tooltip:LangHelper.GetString(this.Id, "Additional services for connection"),
                                                            value:0,
                                                            labelWidth:0
                                                        },
                                                        {
                                                            view: "text",
                                                            name: "supplement_service_id",
                                                            placeholder:LangHelper.GetString(this.Id, "Additional services for connection"),
                                                            tooltip:LangHelper.GetString(this.Id, "Additional services for connection"),
                                                            validate:function(Val)
                                                            {
                                                                if(!this.elements.enabled.getValue())
                                                                    return true;

                                                                if(!this.elements.supplement_service_id_enabled.getValue())
                                                                    return true;

                                                                return !isNaN(Val*1);
                                                            },
                                                            invalidMessage: LangHelper.GetString("Dics","Enter the number")
                                                        }
                                                    ]
                                                },
                                                {
                                                    rows:[
                                                        {
                                                            view: "checkbox",
                                                            name: "forwarding_identifier_enabled",
                                                            labelRight:LangHelper.GetString(this.Id, "Forwarding number"),
                                                            tooltip:LangHelper.GetString(this.Id, "Forwarding number"),
                                                            value:0,
                                                            labelWidth:0
                                                        },
                                                        {
                                                            view: "text",
                                                            name: "forwarding_identifier",
                                                            value: "",
                                                            placeholder:LangHelper.GetString(this.Id, "Forwarding number"),
                                                            tooltip:LangHelper.GetString(this.Id, "Forwarding number")
                                                        }
                                                    ]
                                                },
                                                {
                                                    rows:[
                                                        {
                                                            view: "checkbox",
                                                            name: "phone_card_number_enabled",
                                                            labelRight:LangHelper.GetString(this.Id, "Phone card number"),
                                                            tooltip:LangHelper.GetString(this.Id, "Phone card number"),
                                                            value:0,
                                                            labelWidth:0
                                                        },
                                                        {
                                                            view: "text",
                                                            name: "phone_card_number",
                                                            placeholder:LangHelper.GetString(this.Id, "Phone card number"),
                                                            tooltip:LangHelper.GetString(this.Id, "Phone card number"),
                                                            validate:function(Val)
                                                            {
                                                                if(!this.elements.enabled.getValue())
                                                                    return true;

                                                                if(!this.elements.phone_card_number_enabled.getValue())
                                                                    return true;

                                                                return !isNaN(Val*1);
                                                            },
                                                            invalidMessage: LangHelper.GetString("Dics","Enter the number")
                                                        }
                                                    ]
                                                },
                                                {
                                                    cols:[
                                                        {
                                                            rows:[
                                                                {
                                                                    view: "checkbox",
                                                                    name: "inbound_bunch_enabled",
                                                                    labelRight:LangHelper.GetString(this.Id, "Inbound bunch"),
                                                                    tooltip:LangHelper.GetString(this.Id, "Inbound bunch"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "text",
                                                                    name: "inbound_bunch",
                                                                    placeholder:LangHelper.GetString(this.Id, "ID of inbound bunch"),
                                                                    tooltip:LangHelper.GetString(this.Id, "ID of inbound bunch"),
                                                                    validate:function(Val)
                                                                    {
                                                                        if(!this.elements.enabled.getValue())
                                                                            return true;

                                                                        if(!this.elements.inbound_bunch_enabled.getValue())
                                                                            return true;

                                                                        return !isNaN(Val*1);
                                                                    },
                                                                    invalidMessage: LangHelper.GetString("Dics","Enter the number"),
                                                                    suggest: {
                                                                        data:App.DicsManager.GetDicAsArrayWithFilter(DicsEnum.BunchesDic, "dic_id_type", "0"),
                                                                        template:"#id#",
                                                                        filter:function(obj, value)
                                                                        {
                                                                            return (obj.id + obj.value).indexOf(value) >= 0;
                                                                        }
                                                                    },
                                                                    keyPressTimeout:500,
                                                                    on:{
                                                                        onTimedKeyPress: function()
                                                                        {
                                                                            App.DicsManager.Refresh({data:[DicsEnum.BunchesDic], filter:this.getValue()});
                                                                        }
                                                                    }
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            rows:[
                                                                {
                                                                    view: "checkbox",
                                                                    name: "outbound_bunch_enabled",
                                                                    labelRight:LangHelper.GetString(this.Id, "Outbound bunch"),
                                                                    tooltip:LangHelper.GetString(this.Id, "Outbound bunch"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "text",
                                                                    name: "outbound_bunch",
                                                                    placeholder:LangHelper.GetString(this.Id, "ID of outbound bunch"),
                                                                    tooltip:LangHelper.GetString(this.Id, "ID of outbound bunch"),
                                                                    validate:function(Val)
                                                                    {
                                                                        if(!this.elements.enabled.getValue())
                                                                            return true;

                                                                        if(!this.elements.outbound_bunch_enabled.getValue())
                                                                            return true;

                                                                        return !isNaN(Val*1);
                                                                    },
                                                                    invalidMessage: LangHelper.GetString("Dics","Enter the number"),
                                                                    suggest: {
                                                                        data:App.DicsManager.GetDicAsArrayWithFilter(DicsEnum.BunchesDic, "dic_id_type", "0"),
                                                                        template:"#id#",
                                                                        filter:function(obj, value)
                                                                        {
                                                                            return (obj.id + obj.value).indexOf(value) >= 0;
                                                                        }
                                                                    },
                                                                    keyPressTimeout:500,
                                                                    on:{
                                                                        onTimedKeyPress: function()
                                                                        {
                                                                            App.DicsManager.Refresh({data:[DicsEnum.BunchesDic], filter:this.getValue()});
                                                                        }
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    rows:[
                                                        {
                                                            view: "checkbox",
                                                            name: "term_cause_enabled",
                                                            labelRight:LangHelper.GetString(this.Id, "Completion reason"),
                                                            tooltip:LangHelper.GetString(this.Id, "Connection completion reason"),
                                                            value:0,
                                                            labelWidth:0
                                                        },
                                                        {
                                                            view: "text",
                                                            name: "term_cause",
                                                            placeholder:LangHelper.GetString(this.Id, "Completion reason"),
                                                            tooltip:LangHelper.GetString(this.Id, "Connection completion reason"),
                                                            validate:function(Val)
                                                            {
                                                                if(!this.elements.enabled.getValue())
                                                                    return true;

                                                                if(!this.elements.term_cause_enabled.getValue())
                                                                    return true;

                                                                return !isNaN(Val*1);
                                                            },
                                                            invalidMessage: LangHelper.GetString("Dics","Enter the number"),
                                                            suggest: {
                                                                data:App.DicsManager.GetDicAsArray(DicsEnum.TermCausesDic),
                                                                template:"#id#",
                                                                filter:function(obj, value)
                                                                {
                                                                    return obj.value.indexOf(value) >= 0;
                                                                }
                                                            },
                                                            keyPressTimeout:500,
                                                            on:{
                                                                onTimedKeyPress: function()
                                                                {
                                                                    App.DicsManager.Refresh({data:[DicsEnum.TermCausesDic], filter:this.getValue()});
                                                                }
                                                            }
                                                        }
                                                    ]
                                                },
                                                {}
                                            ]

                                        }
                                    ]
                                },
                                {
                                    id:this.Id + "CreateTaskContextForm" + "_" + "term" + "_" + "tab",
                                    rows:[
                                        BlockHeaderTemplate({title:LangHelper.GetString(this.Id, "Terminal sessions"), icon:"file"}),
                                        {
                                            id:this.Id + "CreateTaskContextForm" + "_" + "term",
                                            view: "form",
                                            margin: 0,
                                            padding: 8,
                                            scroll:"y",
                                            elements:[
                                                {
                                                    view: "checkbox",
                                                    name: "enabled",
                                                    labelRight: LangHelper.GetString(this.Id, "Enable search"),
                                                    value:0,
                                                    labelWidth:0,
                                                    on: {
                                                        onChange:function()
                                                        {
                                                            _this.SetCreateTaskContextFormTabbarCheckboxes();
                                                        }
                                                    },
                                                    validate:function(Val)
                                                    {
                                                        if(!Val)
                                                            return true;

                                                        return _this.ValidateFormOneParameterChecked(this);
                                                    },
                                                    invalidMessage: LangHelper.GetString(this.Id,"You must enable at least one query parameter")
                                                },
                                                {
                                                    rows:[
                                                        {
                                                            view: "checkbox",
                                                            name: "point_id_enabled",
                                                            labelRight:LangHelper.GetString(this.Id, "Connection point"),
                                                            tooltip:LangHelper.GetString(this.Id, "Connection point identifier to the data network"),
                                                            value:0,
                                                            labelWidth:0
                                                        },
                                                        {
                                                            view: "text",
                                                            name: "point_id",
                                                            placeholder:LangHelper.GetString(this.Id, "Connection point"),
                                                            tooltip:LangHelper.GetString(this.Id, "Connection point identifier to the data network"),
                                                            validate:function(Val)
                                                            {
                                                                if(!this.elements.enabled.getValue())
                                                                    return true;

                                                                if(!this.elements.point_id_enabled.getValue())
                                                                    return true;

                                                                return !isNaN(Val*1);
                                                            },
                                                            invalidMessage: LangHelper.GetString("Dics","Enter the number"),
                                                            suggest: {
                                                                data:App.DicsManager.GetDicAsArray(DicsEnum.IpDataPointsDic),
                                                                template:"#id#",
                                                                filter:function(obj, value)
                                                                {
                                                                    return obj.value.indexOf(value) >= 0;
                                                                }
                                                            },
                                                            keyPressTimeout:500,
                                                            on:{
                                                                onTimedKeyPress: function()
                                                                {
                                                                    App.DicsManager.Refresh({data:[DicsEnum.IpDataPointsDic], filter:this.getValue()});
                                                                }
                                                            }
                                                        }
                                                    ]
                                                },
                                                {
                                                    rows:[
                                                        {
                                                            view: "checkbox",
                                                            name: "client_info_enabled",
                                                            labelRight:LangHelper.GetString(this.Id, "Source IP"),
                                                            tooltip:LangHelper.GetString(this.Id, "Source IP"),
                                                            value:0,
                                                            labelWidth:0
                                                        },
                                                        {
                                                            cols:[
                                                                {
                                                                    view: "text",
                                                                    name: "client_info_ip",
                                                                    placeholder:LangHelper.GetString(this.Id, "Source IP"),
                                                                    tooltip:LangHelper.GetString(this.Id, "Source IP"),
                                                                    validate:function(Val)
                                                                    {
                                                                        if(!this.elements.enabled.getValue())
                                                                            return true;

                                                                        if(!this.elements.client_info_enabled.getValue())
                                                                            return true;

                                                                        if(!this.elements.client_info_ip.getValue() && !this.elements.client_info_port.getValue())
                                                                            return false;

                                                                        if(Val)
                                                                            return Helper.ValidateIPaddress(Val);
                                                                        else
                                                                            return true;
                                                                    },
                                                                    invalidMessage: LangHelper.GetString("Dics", "Enter the IP address")
                                                                },
                                                                {
                                                                    view: "text",
                                                                    name: "client_info_port",
                                                                    placeholder:LangHelper.GetString(this.Id, "Source port"),
                                                                    tooltip:LangHelper.GetString(this.Id, "Source port"),
                                                                    validate:function(Val)
                                                                    {
                                                                        if(!this.elements.enabled.getValue())
                                                                            return true;

                                                                        if(!this.elements.client_info_enabled.getValue())
                                                                            return true;

                                                                        if(!this.elements.client_info_ip.getValue() && !this.elements.client_info_port.getValue())
                                                                            return false;

                                                                        return !isNaN(Val*1);
                                                                    },
                                                                    invalidMessage: LangHelper.GetString("Dics","Enter the number")
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    rows:[
                                                        {
                                                            view: "checkbox",
                                                            name: "server_info_enabled",
                                                            labelRight:LangHelper.GetString(this.Id, "Destination IP"),
                                                            tooltip:LangHelper.GetString(this.Id, "Destination IP"),
                                                            value:0,
                                                            labelWidth:0
                                                        },
                                                        {
                                                            cols:[
                                                                {
                                                                    view: "text",
                                                                    name: "server_info_ip",
                                                                    placeholder:LangHelper.GetString(this.Id, "Destination IP"),
                                                                    tooltip:LangHelper.GetString(this.Id, "Destination IP"),
                                                                    validate:function(Val)
                                                                    {
                                                                        if(!this.elements.enabled.getValue())
                                                                            return true;

                                                                        if(!this.elements.server_info_enabled.getValue())
                                                                            return true;

                                                                        if(!this.elements.server_info_ip.getValue() && !this.elements.server_info_port.getValue())
                                                                            return false;

                                                                        if(Val)
                                                                            return Helper.ValidateIPaddress(Val);
                                                                        else
                                                                            return true;
                                                                    },
                                                                    invalidMessage: LangHelper.GetString("Dics", "Enter the IP address")
                                                                },
                                                                {
                                                                    view: "text",
                                                                    name: "server_info_port",
                                                                    placeholder:LangHelper.GetString(this.Id, "Destination port"),
                                                                    tooltip:LangHelper.GetString(this.Id, "Destination port"),
                                                                    validate:function(Val)
                                                                    {
                                                                        if(!this.elements.enabled.getValue())
                                                                            return true;

                                                                        if(!this.elements.server_info_enabled.getValue())
                                                                            return true;

                                                                        if(!this.elements.server_info_ip.getValue() && !this.elements.server_info_port.getValue())
                                                                            return false;

                                                                        return !isNaN(Val*1);
                                                                    },
                                                                    invalidMessage: LangHelper.GetString("Dics","Enter the number")
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    rows:[
                                                        {
                                                            view: "checkbox",
                                                            name: "abonent_id_enabled",
                                                            labelRight:LangHelper.GetString(this.Id, "Subscriber ID"),
                                                            tooltip:LangHelper.GetString(this.Id, "Subscriber ID"),
                                                            value:0,
                                                            labelWidth:0
                                                        },
                                                        {
                                                            view: "text",
                                                            name: "abonent_id",
                                                            placeholder:LangHelper.GetString(this.Id, "Subscriber ID"),
                                                            tooltip:LangHelper.GetString(this.Id, "Subscriber ID")
                                                        }
                                                    ]
                                                },
                                                {}
                                            ]
                                        }
                                    ]
                                },
                                {
                                    id:this.Id + "CreateTaskContextForm" + "_" + "raw_flows" + "_" + "tab",
                                    rows:[
                                        BlockHeaderTemplate({title:LangHelper.GetString(this.Id, "Flows"), icon:"file"}),
                                        {
                                            id:this.Id + "CreateTaskContextForm" + "_" + "raw_flows",
                                            view: "form",
                                            margin: 0,
                                            padding: 8,
                                            scroll:"y",
                                            elements:[
                                                {
                                                    view: "checkbox",
                                                    name: "enabled",
                                                    labelRight: LangHelper.GetString(this.Id, "Enable search"),
                                                    value:0,
                                                    labelWidth:0,
                                                    on: {
                                                        onChange:function()
                                                        {
                                                            _this.SetCreateTaskContextFormTabbarCheckboxes();
                                                        }
                                                    },
                                                    validate:function(Val)
                                                    {
                                                        if(!Val)
                                                            return true;

                                                        return _this.ValidateFormOneParameterChecked(this);
                                                    },
                                                    invalidMessage: LangHelper.GetString(this.Id,"You must enable at least one query parameter")
                                                },
                                                {
                                                    rows:[
                                                        {
                                                            view: "checkbox",
                                                            name: "point_id_enabled",
                                                            labelRight:LangHelper.GetString(this.Id, "Connection point"),
                                                            tooltip:LangHelper.GetString(this.Id, "Connection point identifier to the data network"),
                                                            value:0,
                                                            labelWidth:0
                                                        },
                                                        {
                                                            view: "text",
                                                            name: "point_id",
                                                            placeholder:LangHelper.GetString(this.Id, "Connection point"),
                                                            tooltip:LangHelper.GetString(this.Id, "Connection point identifier to the data network"),
                                                            validate:function(Val)
                                                            {
                                                                if(!this.elements.enabled.getValue())
                                                                    return true;

                                                                if(!this.elements.point_id_enabled.getValue())
                                                                    return true;

                                                                return !isNaN(Val*1);
                                                            },
                                                            invalidMessage: LangHelper.GetString("Dics","Enter the number"),
                                                            suggest: {
                                                                data:App.DicsManager.GetDicAsArray(DicsEnum.IpDataPointsDic),
                                                                template:"#id#",
                                                                filter:function(obj, value)
                                                                {
                                                                    return obj.value.indexOf(value) >= 0;
                                                                }
                                                            },
                                                            keyPressTimeout:500,
                                                            on:{
                                                                onTimedKeyPress: function()
                                                                {
                                                                    App.DicsManager.Refresh({data:[DicsEnum.IpDataPointsDic], filter:this.getValue()});
                                                                }
                                                            }
                                                        }
                                                    ]
                                                },
                                                {
                                                    rows:[
                                                        {
                                                            view: "checkbox",
                                                            name: "protocol_code_enabled",
                                                            labelRight:LangHelper.GetString(this.Id, "Protocol code"),
                                                            tooltip:LangHelper.GetString(this.Id, "Protocol code in accordance with RFC1700 or port number for TCP / UDP"),
                                                            value:0,
                                                            labelWidth:0
                                                        },
                                                        {
                                                            view: "text",
                                                            name: "protocol_code",
                                                            placeholder:LangHelper.GetString(this.Id, "Protocol code"),
                                                            tooltip:LangHelper.GetString(this.Id, "Protocol code in accordance with RFC1700 or port number for TCP / UDP"),
                                                            validate:function(Val)
                                                            {
                                                                if(!this.elements.enabled.getValue())
                                                                    return true;

                                                                if(!this.elements.protocol_code_enabled.getValue())
                                                                    return true;

                                                                return !isNaN(Val*1);
                                                            },
                                                            invalidMessage: LangHelper.GetString("Dics","Enter the number"),
                                                            suggest: {
                                                                data:App.DicsManager.GetDicAsArray(DicsEnum.PortsDic),
                                                                template:"#id#",
                                                                filter:function(obj, value)
                                                                {
                                                                    return obj.value.indexOf(value) >= 0;
                                                                }
                                                            },
                                                            keyPressTimeout:500,
                                                            on:{
                                                                onTimedKeyPress: function()
                                                                {
                                                                    App.DicsManager.Refresh({data:[DicsEnum.PortsDic], filter:this.getValue()});
                                                                }
                                                            }
                                                        }
                                                    ]
                                                },
                                                {
                                                    rows:[
                                                        {
                                                            view: "checkbox",
                                                            name: "client_info_enabled",
                                                            labelRight:LangHelper.GetString(this.Id, "Source IP"),
                                                            tooltip:LangHelper.GetString(this.Id, "Source IP"),
                                                            value:0,
                                                            labelWidth:0
                                                        },
                                                        {
                                                            cols:[
                                                                {
                                                                    view: "text",
                                                                    name: "client_info_ip",
                                                                    placeholder:LangHelper.GetString(this.Id, "Source IP"),
                                                                    tooltip:LangHelper.GetString(this.Id, "Source IP"),
                                                                    validate:function(Val)
                                                                    {
                                                                        if(!this.elements.enabled.getValue())
                                                                            return true;

                                                                        if(!this.elements.client_info_enabled.getValue())
                                                                            return true;

                                                                        if(!this.elements.client_info_ip.getValue() && !this.elements.client_info_port.getValue())
                                                                            return false;

                                                                        if(Val)
                                                                            return Helper.ValidateIPaddress(Val);
                                                                        else
                                                                            return true;
                                                                    },
                                                                    invalidMessage: LangHelper.GetString("Dics", "Enter the IP address")
                                                                },
                                                                {
                                                                    view: "text",
                                                                    name: "client_info_port",
                                                                    placeholder:LangHelper.GetString(this.Id, "Source port"),
                                                                    tooltip:LangHelper.GetString(this.Id, "Source port"),
                                                                    validate:function(Val)
                                                                    {
                                                                        if(!this.elements.enabled.getValue())
                                                                            return true;

                                                                        if(!this.elements.client_info_enabled.getValue())
                                                                            return true;

                                                                        if(!this.elements.client_info_ip.getValue() && !this.elements.client_info_port.getValue())
                                                                            return false;

                                                                        return !isNaN(Val*1);
                                                                    },
                                                                    invalidMessage: LangHelper.GetString("Dics","Enter the number")
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    rows:[
                                                        {
                                                            view: "checkbox",
                                                            name: "server_info_enabled",
                                                            labelRight:LangHelper.GetString(this.Id, "Destination IP"),
                                                            tooltip:LangHelper.GetString(this.Id, "Destination IP"),
                                                            value:0,
                                                            labelWidth:0
                                                        },
                                                        {
                                                            cols:[
                                                                {
                                                                    view: "text",
                                                                    name: "server_info_ip",
                                                                    placeholder:LangHelper.GetString(this.Id, "Destination IP"),
                                                                    tooltip:LangHelper.GetString(this.Id, "Destination IP"),
                                                                    validate:function(Val)
                                                                    {
                                                                        if(!this.elements.enabled.getValue())
                                                                            return true;

                                                                        if(!this.elements.server_info_enabled.getValue())
                                                                            return true;

                                                                        if(!this.elements.server_info_ip.getValue() && !this.elements.server_info_port.getValue())
                                                                            return false;

                                                                        if(Val)
                                                                            return Helper.ValidateIPaddress(Val);
                                                                        else
                                                                            return true;
                                                                    },
                                                                    invalidMessage: LangHelper.GetString("Dics", "Enter the IP address")
                                                                },
                                                                {
                                                                    view: "text",
                                                                    name: "server_info_port",
                                                                    placeholder:LangHelper.GetString(this.Id, "Destination port"),
                                                                    tooltip:LangHelper.GetString(this.Id, "Destination port"),
                                                                    validate:function(Val)
                                                                    {
                                                                        if(!this.elements.enabled.getValue())
                                                                            return true;

                                                                        if(!this.elements.server_info_enabled.getValue())
                                                                            return true;

                                                                        if(!this.elements.server_info_ip.getValue() && !this.elements.server_info_port.getValue())
                                                                            return false;

                                                                        return !isNaN(Val*1);
                                                                    },
                                                                    invalidMessage: LangHelper.GetString("Dics","Enter the number")
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    rows:[
                                                        {
                                                            view: "checkbox",
                                                            name: "sni_enabled",
                                                            labelRight:LangHelper.GetString(this.Id, "SNI, CN"),
                                                            tooltip:LangHelper.GetString(this.Id, "SNI, CN"),
                                                            value:0,
                                                            labelWidth:0
                                                        },
                                                        {
                                                            view: "text",
                                                            name: "sni",
                                                            value: "",
                                                            placeholder:LangHelper.GetString(this.Id, "SNI, CN"),
                                                            tooltip:LangHelper.GetString(this.Id, "SNI, CN")
                                                        }
                                                    ]
                                                },
                                                {
                                                    rows:[
                                                        {
                                                            view: "checkbox",
                                                            name: "abonent_id_enabled",
                                                            labelRight:LangHelper.GetString(this.Id, "Subscriber ID"),
                                                            tooltip:LangHelper.GetString(this.Id, "Subscriber ID"),
                                                            value:0,
                                                            labelWidth:0
                                                        },
                                                        {
                                                            view: "text",
                                                            name: "abonent_id",
                                                            placeholder:LangHelper.GetString(this.Id, "Subscriber ID"),
                                                            tooltip:LangHelper.GetString(this.Id, "Subscriber ID")
                                                        }
                                                    ]
                                                },
                                                {}
                                            ]

                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    view: "form",
                    margin: 0,
                    padding: 0,
                    borderless:true,
                    elements:[
                        {
                            cols:[
                                {},
                                {
                                    view:"button",
                                    label:LangHelper.GetString(this.Id, "Cancel"),
                                    click:function ()
                                    {
                                        _this.CreatTaskContext.hide();
                                        _this.ClearForms();
                                    }
                                },
                                {
                                    view:"button",
                                    type:"form",
                                    label:LangHelper.GetString(this.Id, "Save"),
                                    click:function ()
                                    {
                                        _this.SaveTask();
                                    }
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    };

    this.TaskDeleteContext = webix.ui({
        view:"popup",
        width:300,
        height:180,
        body:{
            view:"form",
            borderless:true,
            elements:[
                {
                    template:LangHelper.GetString(this.Id, "Are you sure you want to delete the request?")
                },
                {
                    cols:[
                        {},
                        {
                            view:"button",
                            label:LangHelper.GetString(this.Id, "Cancel"),
                            click:function () {
                                _this.TaskDeleteContext.hide();
                            }
                        },
                        {
                            view:"button",
                            type:"form",
                            label:LangHelper.GetString(this.Id, "Delete"),
                            click:function () {
                                _this.TaskDeleteContext.hide();

                                var selected = _this.GetTasksTableSelectedData();

                                if(selected)
                                    _this.DeleteTask(selected.id);
                            }
                        }
                    ]
                }
            ]
        }
    });

    this.TabbarOptionsDic = {
        "ConnectionsAaaTab": {
            valueTpl: "Aaa",
            valueCount:0,
            value: "Aaa",
            id: this.Id + "ConnectionsAaaTab"
        },
        "ConnectionsHttpTab": {
            valueTpl: "Http",
            valueCount:0,
            value: "Http",
            id: this.Id + "ConnectionsHttpTab"
        },
        "ConnectionsFtpTab": {
            valueTpl:"Ftp",
            valueCount:0,
            value: "Ftp",
            id: this.Id + "ConnectionsFtpTab"
        },
        "ConnectionsMailTab": {
            valueTpl:"Mail",
            valueCount:0,
            value: "Mail",
            id: this.Id + "ConnectionsMailTab"
        },
        "ConnectionsImTab": {
            valueTpl: "Im",
            valueCount:0,
            value: "Im",
            id: this.Id + "ConnectionsImTab"
        },
        "ConnectionsVoipTab": {
            valueTpl: "Voip",
            valueCount:0,
            value: "Voip",
            id: this.Id + "ConnectionsVoipTab"
        },
        "ConnectionsPstnTab": {
            valueTpl: "Pstn",
            valueCount:0,
            value: "Pstn",
            id: this.Id + "ConnectionsPstnTab"
        },
        "ConnectionsTermTab": {
            valueTpl: LangHelper.GetString(this.Id, "Terminal sessions"),
            valueCount:0,
            value: LangHelper.GetString(this.Id, "Flows"),
            id: this.Id + "ConnectionsTermTab"
        },
        "ConnectionsRfTab": {
            valueTpl: LangHelper.GetString(this.Id, "Flows"),
            valueCount:0,
            value: LangHelper.GetString(this.Id, "Flows"),
            id: this.Id + "ConnectionsRfTab"
        }
    };

    this.PagingSizeOptions = [
        {id:10, value:"10"},
        {id:50, value:"50"},
        {id:100, value:"100"},
        {id:200, value:"200"},
        {id:500, value:"500"},
        {id:1000, value:"1 000"},
        {id:10000, value:"10 000"}
    ];

    this.ViewConfig = [
        {
            cols:[
                SormSideBarView({id: this.Id}),
                {
                    type: "space",
                    rows: [
                        {
                            type: "clear",
                            borderless: true,
                            rows: [
                                PageHeaderTemplate({root_title: LangHelper.GetString("AppView", "Lawful interception"), title: LangHelper.GetString("AppView", "Connections")}),
                                {
                                    view:"accordion",
                                    multi:"mixed",
                                    cols:[
                                        {
                                            header:LangHelper.GetString(this.Id, "Reports"),
                                            body:{
                                                rows: [
                                                    {
                                                        view: "toolbar",
                                                        height: 32,
                                                        padding: 0,
                                                        margin: 0,
                                                        elements: [
                                                            {
                                                                view: "button",
                                                                id:this.Id + "AddTaskBtn",
                                                                type: "icon",
                                                                icon: "plus",
                                                                align: "right",
                                                                width: 32,
                                                                css:"toolbar_round_btn",
                                                                tooltip:LangHelper.GetString(this.Id, "Add request"),
                                                                click: function (id, e, node)
                                                                {
                                                                    _this.AddTaskToTasksTable();
                                                                }
                                                            },
                                                            {
                                                                view: "button",
                                                                id:this.Id + "AddSubTaskBtn",
                                                                type: "icon",
                                                                icon: "plus-square",
                                                                align: "right",
                                                                width: 32,
                                                                css:"toolbar_round_btn",
                                                                tooltip:LangHelper.GetString(this.Id, "Add subquery"),
                                                                click: function (id, e, node)
                                                                {
                                                                    _this.AddSubTaskToTasksTable();
                                                                }
                                                            },
                                                            {},
                                                            {
                                                                view: "button",
                                                                id:this.Id+"TasksRefreshBtn",
                                                                type: "icon",
                                                                icon: "refresh",
                                                                css: "toolbar_round_btn",
                                                                width: 32,
                                                                tooltip: LangHelper.GetString(this.Id, "Refresh"),
                                                                click: function (id, e, node)
                                                                {
                                                                    _this.RefreshTasksTable(0,1);
                                                                }
                                                            }
                                                        ]
                                                    },
                                                    BlockHeaderTemplate({title:LangHelper.GetString(this.Id, "Requests"), icon:"file"}),
                                                    {
                                                        view: "treetable",
                                                        id: this.Id + "TasksTable",
                                                        select: "row",
                                                        editable: true,
                                                        editaction:"custom",
                                                        resizeColumn: true,
                                                        tooltip:true,
                                                        form:this.Id + "CreateTaskContextForm",
                                                        on: {
                                                            onItemClick: function (id,e,node)
                                                            {
                                                                var rowData = this.getItem(id);

                                                                if (id.column === 'edit')
                                                                {
                                                                    _this.ShowTasksEditForm(rowData, this.getItemNode(id), "right");
                                                                }
                                                                else if(id.column === 'trash')
                                                                {
                                                                    var __this = this;

                                                                    var data = _this.Views.TasksTable.getItem(id);

                                                                    if(!data.task_id)
                                                                    {
                                                                        _this.DeleteTask(id);
                                                                        return;
                                                                    }

                                                                    setTimeout(function () {
                                                                        var node = __this.getItemNode(id);
                                                                        _this.TaskDeleteContext.show(node, {pos:"bottom"});
                                                                    }, 10);
                                                                }
                                                            },
                                                            onBeforeFilter:function()
                                                            {
                                                                return false;
                                                            },
                                                            onAfterFilter:function()
                                                            {
                                                                _this.RefreshTasksTable();
                                                            },
                                                            onBeforeSort:function()
                                                            {
                                                                _this.RefreshTasksTable();
                                                                return false;
                                                            },
                                                            onAfterSelect:function ()
                                                            {
                                                                _this.OnTasksTableRowSelect();
                                                            },
                                                            onAfterUnSelect:function ()
                                                            {
                                                                _this.OnTasksTableRowUnSelect();
                                                            }
                                                        },
                                                        columns: [
                                                            {
                                                                id: "edit",
                                                                header: "",
                                                                width: 32,
                                                                template: CellEditBtnTemplate,
                                                                headermenu: false
                                                            },
                                                            {
                                                                id: "title",
                                                                header: [LangHelper.GetString(this.Id, "Request"), {content: "textFilter"}],
                                                                template:function(obj, common, value, config)
                                                                {
                                                                    return common.treetable(obj, common, value, config) + (obj.title?obj.title:LangHelper.GetString(_this.Id, "Request")+" "+obj.task_id);
                                                                },
                                                                fillspace: 2,
                                                                sort: "string"
                                                            },
                                                            {
                                                                id: "date",
                                                                header: [LangHelper.GetString(this.Id, "Date"), {content: "dateRangeFilter"}],
                                                                //format: webix.Date.dateToStr("%d.%m.%Y"),
                                                                template:function(obj, common){
                                                                    if(obj.date)
                                                                    {
                                                                        var format =  webix.Date.dateToStr("%d.%m.%Y %H:%i");
                                                                        return format(obj.date);
                                                                    }

                                                                    return "";
                                                                },
                                                                fillspace: true,
                                                                sort: "date"
                                                            },
                                                            {
                                                                id: "user_id",
                                                                header: [LangHelper.GetString(this.Id, "User"), {
                                                                    content: "multiSelectFilter",
                                                                    options: App.DicsManager.GetDicAsArray(DicsEnum.UsersDic),
                                                                    inputConfig: {suggest: {fitMaster: false}}
                                                                }],
                                                                fillspace: true,
                                                                template: function (obj)
                                                                {
                                                                    if(App.DicsManager.GetDic("UsersDic")[obj.user_id])
                                                                        return App.DicsManager.GetDic("UsersDic")[obj.user_id].value;
                                                                    else
                                                                        return "";
                                                                },
                                                                sort: "string"
                                                            },
                                                            {
                                                                id: "status",
                                                                header: [LangHelper.GetString(this.Id, LangHelper.GetString(this.Id, "Status")), {
                                                                    content: "richSelectFilter",
                                                                    options: Helper.DictToArr(TaskStatusesDic),
                                                                    inputConfig: {suggest: {fitMaster: false}}
                                                                }],
                                                                template: function (obj, common)
                                                                {
                                                                    var statusStr = "";

                                                                    if(TaskStatusesDic[obj.min_status])
                                                                    {
                                                                        if(obj.min_status == 0 && obj.task_id && obj.sorm_data && obj.sorm_data.length)
                                                                        {
                                                                            statusStr += '<div class="spinner"><div class="bounce1"></div><div class="bounce2"></div> <div class="bounce3"></div> </div>';
                                                                        }

                                                                        statusStr += "<span class=\"status status_" + obj.min_status + "\">" + TaskStatusesDic[obj.min_status].value + "</span>";
                                                                    }

                                                                    return statusStr;
                                                                },
                                                                fillspace: true,
                                                                sort: "string"
                                                            },
                                                            {
                                                                id: "report_count",
                                                                header: [LangHelper.GetString(this.Id, "Data"), {content: "textFilter"}],
                                                                fillspace: true,
                                                                sort: "string"
                                                            },
                                                            {
                                                                id: "trash",
                                                                header: "",
                                                                width: 32,
                                                                template: CellTrashBtnTemplate,
                                                                headermenu: false
                                                            }
                                                        ],
                                                        data:[],
                                                        rules:{
                                                            "valid":function (value)
                                                            {
                                                                return value;
                                                            }
                                                        }
                                                    },
                                                    {
                                                        view: "toolbar",
                                                        padding: 0,
                                                        margin: 0,
                                                        height:32,
                                                        elements: [
                                                            {
                                                                view: "pager",
                                                                gravity:3,
                                                                id: this.Id + "TasksTable" + "Paging",
                                                                template: "{common.first()} {common.prev()} {common.pages()} {common.next()} {common.last()}",
                                                                master:false,
                                                                on: {
                                                                    onAfterPageChange:function(Page)
                                                                    {
                                                                        _this.RefreshTasksTable(Page);
                                                                    }
                                                                }
                                                            },
                                                            {
                                                                view:"richselect",
                                                                id: this.Id + "TasksTable" + "Paging" + "Size",
                                                                label:LangHelper.GetString(this.Id, "On the page"),
                                                                labelWidth:120,
                                                                minWidth:220,
                                                                value:100,
                                                                options:this.PagingSizeOptions,
                                                                on: {
                                                                    onChange:function()
                                                                    {
                                                                        _this.RefreshTasksTable();
                                                                    }
                                                                }
                                                            }
                                                        ]
                                                    }
                                                ]
                                            }
                                        },
                                        {
                                            view: "resizer"
                                        },
                                        {
                                            gravity:2,
                                            header:LangHelper.GetString(this.Id, "Results"),
                                            body:{
                                                id:this.Id + "ReportsPanel",
                                                rows:[
                                                    {
                                                        view: "toolbar",
                                                        height: 32,
                                                        padding: 0,
                                                        margin: 0,
                                                        elements: [
                                                            {
                                                                view:"label",
                                                                id:this.Id+"TaskDataInfoLabel"
                                                            },
                                                            {},
                                                            {
                                                                view: "button",
                                                                type: "icon",
                                                                icon: "plus-square",
                                                                label: LangHelper.GetString(this.Id, "Create subquery"),
                                                                width:149,
                                                                click: function (id, e, node)
                                                                {
                                                                    _this.AddSubTaskToTasksTable();
                                                                }

                                                            },
                                                            {
                                                                width:16
                                                            },
                                                            {
                                                                view: "button",
                                                                id:this.Id+"TaskDataExportToExcelBtn",
                                                                type: "icon",
                                                                icon: "file-excel",
                                                                css: "toolbar_round_btn",
                                                                width: 32,
                                                                tooltip: LangHelper.GetString(this.Id, "Export to Excel"),
                                                                click: function (id, e, node)
                                                                {
                                                                    _this.ExportTaskDataToExcel();
                                                                }
                                                            },
                                                            {
                                                                view: "button",
                                                                id:this.Id+"TaskDataExportToPdfBtn",
                                                                type: "icon",
                                                                icon: "file-pdf",
                                                                css: "toolbar_round_btn",
                                                                width: 32,
                                                                tooltip: LangHelper.GetString(this.Id, "Export to PDF"),
                                                                click: function (id, e, node)
                                                                {
                                                                    _this.ExportTaskDataToPdf();
                                                                }
                                                            },
                                                            {
                                                              width:16
                                                            },
                                                            {
                                                                view: "button",
                                                                id:this.Id+"TaskDataResetFiltersBtn",
                                                                type: "icon",
                                                                icon: "bolt",
                                                                css: "toolbar_round_btn",
                                                                width: 32,
                                                                tooltip: LangHelper.GetString(this.Id, "Clear filters"),
                                                                click: function (id, e, node)
                                                                {
                                                                    _this.ClearTaskDataFilters();
                                                                }
                                                            },
                                                            {
                                                                view: "button",
                                                                id:this.Id+"TaskDataRefreshBtn",
                                                                type: "icon",
                                                                icon: "refresh",
                                                                css: "toolbar_round_btn",
                                                                width: 32,
                                                                tooltip: LangHelper.GetString(this.Id, "Refresh"),
                                                                click: function (id, e, node)
                                                                {
                                                                    _this.RefreshTaskData(0);
                                                                }
                                                            }
                                                        ]
                                                    },
                                                    {
                                                        view: "tabbar",
                                                        id: this.Id + "ConnectionsTabs",
                                                        multiview: true,
                                                        height:webix.skin.$active.tabbarSmallHeight,
                                                        borderless:false,
                                                        options: Helper.DictToArr(this.TabbarOptionsDic)
                                                    },
                                                    {
                                                        cells: [
                                                            {
                                                                id:this.Id + "ConnectionsAaaTab",
                                                                rows:[
                                                                    {
                                                                        id:this.Id + "ConnectionsAaaTabTable",
                                                                        view: "datatable",
                                                                        select: "row",
                                                                        resizeColumn:true,
                                                                        tooltip:true,
                                                                        scroll:"xy",
                                                                        dragColumn:true,
                                                                        headermenu: {
                                                                            width: webix.skin.$active.tableHeaderMenuWidth,
                                                                            autoheight: false,
                                                                            scroll: true
                                                                        },
                                                                        leftSplit:4,
                                                                        rightSplit:1,
                                                                        columns: [
                                                                            {
                                                                                id: "telco",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Operator")), {content: "textFilter"}],
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "connection_time",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Connection date and time")), {content: "dateRangeFilter"}],
                                                                                template:function(obj, common){
                                                                                    if(obj.connection_time)
                                                                                    {
                                                                                        var format =  webix.Date.dateToStr("%d.%m.%Y %H:%i");
                                                                                        return format(obj.connection_time);
                                                                                    }

                                                                                    return "";
                                                                                },
                                                                                //fillspace: true,
                                                                                sort: "date"
                                                                            },
                                                                            {
                                                                                id: "allocated_ip",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "IP address")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "user_name",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Username")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "calling_number",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Calling number")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "called_number",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Called number")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "login_type",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Connection type")), {content: "richSelectFilter", options:Helper.DictToArr(SormRequestConnectionTypeDic)}],
                                                                                //fillspace: true,
                                                                                sort:"string",
                                                                                template:function (Obj)
                                                                                {
                                                                                    if(Obj&&Obj.login_type>=0&&SormRequestConnectionTypeDic[Obj.login_type])
                                                                                        return SormRequestConnectionTypeDic[Obj.login_type].value;

                                                                                    return Obj.login_type;
                                                                                }
                                                                            },
                                                                            {
                                                                                id: "session_id",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Session ID")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "user_password",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "User password")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "protocol_port",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Protocol code")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                                // ,
                                                                                // template:function(Obj)
                                                                                // {
                                                                                //     if(Obj&&Obj.connect_type&&_this.ViewModel.ProtocolsDic[Obj.connect_type])
                                                                                //         return _this.ViewModel.ProtocolsDic[Obj.connect_type].value;
                                                                                //
                                                                                //     return Obj.connect_type;
                                                                                // }
                                                                            },
                                                                            {
                                                                                id: "nas_ip",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "IP address of the NAS server")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "nas_port",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "NAS server port")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "in_bytes",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Received data")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "out_bytes",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Sent data")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "apn",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Access point (APN)")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "service_area_code",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Service area code (SAC)")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "phone_card_number",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Phone card number")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "imsi",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "IMSI of mobile subscriber")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "imei",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "IMEI of mobile subscriber")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "esn",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Mobile station ID of the subscriber")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "pool_number",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Modem pool number")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "user_equipment_mac",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "MAC address")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "user_equipment_atm_vpi",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "ATM virtual path number (VPI)")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "user_equipment_atm_vci",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "ATM virtual channel number (VCI)")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "sgsn_ip",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "IP address of GPRS / EDGE SGSN")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "ggsn_ip",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "IP address of GPRS / EDGE GGSN")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "location_start_lac",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Base station zone code (connection established)")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "location_start_cell",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Base station code (connection established)")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "location_start_ta",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Timing advance of the base station (connection established)")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "location_end_lac",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Base station zone code (connection completed)")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "location_end_cell",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Base station code (connection completed)")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "location_end_ta",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Timing advance of the base station (connection completed)")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                header: {content: "headerMenu"},
                                                                                headermenu: false,
                                                                                width: 32
                                                                            }
                                                                        ],
                                                                        on: {
                                                                            onBeforeFilter:function()
                                                                            {
                                                                                return false;
                                                                            },
                                                                            onAfterFilter:function()
                                                                            {
                                                                                _this.RefreshTaskData();
                                                                            },
                                                                            onBeforeSort:function()
                                                                            {
                                                                                _this.RefreshTaskData();

                                                                                return false;
                                                                            },
                                                                            onAfterColumnHide:function()
                                                                            {
                                                                                _this.SaveTableState(this);
                                                                            },
                                                                            onAfterColumnShow:function()
                                                                            {
                                                                                _this.SaveTableState(this);
                                                                            },
                                                                            onAfterColumnDrop:function()
                                                                            {
                                                                                _this.SaveTableState(this);
                                                                            }
                                                                        },
                                                                        data: [],
                                                                        version:"8"
                                                                    },
                                                                    this.GetTaskDataPagingConfig("ConnectionsAaaTabTable")
                                                                ]
                                                            },
                                                            {
                                                                id:this.Id + "ConnectionsHttpTab",
                                                                rows:[
                                                                    {
                                                                        id:this.Id + "ConnectionsHttpTabTable",
                                                                        view: "datatable",
                                                                        select: "row",
                                                                        resizeColumn:true,
                                                                        tooltip:true,
                                                                        scroll:"xy",
                                                                        dragColumn:true,
                                                                        headermenu: {
                                                                            width: webix.skin.$active.tableHeaderMenuWidth,
                                                                            autoheight: false,
                                                                            scroll: true
                                                                        },
                                                                        leftSplit:3,
                                                                        rightSplit:1,
                                                                        columns: [
                                                                            {
                                                                                id: "telco",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Operator")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "begin_connect",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Connection start")), {content: "dateRangeFilter"}],
                                                                                template:function(obj, common){
                                                                                    if(obj.begin_connect)
                                                                                    {
                                                                                        var format =  webix.Date.dateToStr("%d.%m.%Y %H:%i");
                                                                                        return format(obj.begin_connect);
                                                                                    }

                                                                                    return "";
                                                                                },
                                                                                //fillspace: true,
                                                                                sort: "date"
                                                                            },
                                                                            {
                                                                                id: "url",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Url address")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "http_method",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Http method")), {content: "richSelectFilter", options:Helper.DictToArr(HttpMethodDic)}],
                                                                                //fillspace: true,
                                                                                sort:"string",
                                                                                template:function (Obj)
                                                                                {
                                                                                    if(Obj&&Obj.http_method>=0&&HttpMethodDic[Obj.http_method])
                                                                                        return HttpMethodDic[Obj.http_method].value;

                                                                                    return Obj.http_method;
                                                                                }
                                                                            },
                                                                            {
                                                                                id: "bytes",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "The data size in bytes")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "aaa_username",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Login")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "aaa_result",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Authorization result")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "client_address",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Client IP")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "client_port",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Client port")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "server_address",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Server IP")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "server_port",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Server port")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "protocol_port",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Protocol code")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                                // ,
                                                                                // template:function(Obj)
                                                                                // {
                                                                                //     if(Obj&&Obj.protocol_code&&_this.ViewModel.ProtocolsDic[Obj.protocol_code])
                                                                                //         return _this.ViewModel.ProtocolsDic[Obj.protocol_code].value;
                                                                                //
                                                                                //     return Obj.protocol_code;
                                                                                // }
                                                                            },
                                                                            {
                                                                                id: "point",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Connection point")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "term",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Completion reason")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "end_connect",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Connection end")), {content: "dateRangeFilter"}],
                                                                                template:function(obj, common){
                                                                                    if(obj.end_connect)
                                                                                    {
                                                                                        var format =  webix.Date.dateToStr("%d.%m.%Y %H:%i");
                                                                                        return format(obj.end_connect);
                                                                                    }

                                                                                    return "";
                                                                                },
                                                                                //fillspace: true,
                                                                                sort: "date"
                                                                            },
                                                                            {
                                                                                id: "abonent_id",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Subscriber ID")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                header: {content: "headerMenu"},
                                                                                headermenu: false,
                                                                                minWidth: 32,
                                                                                fillspace: true
                                                                            }
                                                                        ],
                                                                        on: {
                                                                            onBeforeFilter:function()
                                                                            {
                                                                                return false;
                                                                            },
                                                                            onAfterFilter:function()
                                                                            {
                                                                                _this.RefreshTaskData();
                                                                            },
                                                                            onBeforeSort:function()
                                                                            {
                                                                                _this.RefreshTaskData();

                                                                                return false;
                                                                            },
                                                                            onAfterColumnHide:function()
                                                                            {
                                                                                _this.SaveTableState(this);
                                                                            },
                                                                            onAfterColumnShow:function()
                                                                            {
                                                                                _this.SaveTableState(this);
                                                                            },
                                                                            onAfterColumnDrop:function()
                                                                            {
                                                                                _this.SaveTableState(this);
                                                                            }
                                                                        },
                                                                        data: [],
                                                                        version:2
                                                                    },
                                                                    this.GetTaskDataPagingConfig("ConnectionsHttpTabTable")
                                                                ]
                                                            },
                                                            {
                                                                id:this.Id + "ConnectionsFtpTab",
                                                                rows:[
                                                                    {
                                                                        id:this.Id + "ConnectionsFtpTabTable",
                                                                        view: "datatable",
                                                                        select: "row",
                                                                        resizeColumn:true,
                                                                        tooltip:true,
                                                                        scroll:"xy",
                                                                        dragColumn:true,
                                                                        headermenu: {
                                                                            width: webix.skin.$active.tableHeaderMenuWidth,
                                                                            autoheight: false,
                                                                            scroll: true
                                                                        },
                                                                        leftSplit:4,
                                                                        rightSplit:1,
                                                                        columns: [
                                                                            {
                                                                                id: "telco",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Operator")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "begin_connect",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Connection start")), {content: "dateRangeFilter"}],
                                                                                template:function(obj, common){
                                                                                    if(obj.begin_connect)
                                                                                    {
                                                                                        var format =  webix.Date.dateToStr("%d.%m.%Y %H:%i");
                                                                                        return format(obj.begin_connect);
                                                                                    }

                                                                                    return "";
                                                                                },
                                                                                //fillspace: true,
                                                                                sort: "date"
                                                                            },
                                                                            {
                                                                                id: "user_name",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Username")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "server_name",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Server name")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "user_password",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "User password")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "client_address",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Client IP")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "client_port",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Client port")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "server_address",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Server IP")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "server_port",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Server port")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "server_type",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Server mode")), {content: "richSelectFilter", options:Helper.DictToArr(FtpServerModelDic)}],
                                                                                //fillspace: true,
                                                                                sort:"string",
                                                                                template:function (Obj)
                                                                                {
                                                                                    if(Obj&&Obj.server_type>=0&&FtpServerModelDic[Obj.server_type])
                                                                                        return FtpServerModelDic[Obj.server_type].value;

                                                                                    return Obj.server_type;

                                                                                }
                                                                            },
                                                                            {
                                                                                id: "protocol_port",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Protocol code")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                                // ,
                                                                                // template:function(Obj)
                                                                                // {
                                                                                //     if(Obj&&Obj.protocol_code&&_this.ViewModel.ProtocolsDic[Obj.protocol_code])
                                                                                //         return _this.ViewModel.ProtocolsDic[Obj.protocol_code].value;
                                                                                //
                                                                                //     return Obj.protocol_code;
                                                                                // }
                                                                            },
                                                                            {
                                                                                id: "point",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Connection point")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "in_bytes",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Bytes recieved")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "out_bytes",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Bytes sent")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "term",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Completion reason")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "end_connect",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Connection end")), {content: "dateRangeFilter"}],
                                                                                template:function(obj, common){
                                                                                    if(obj.end_connect)
                                                                                    {
                                                                                        var format =  webix.Date.dateToStr("%d.%m.%Y %H:%i");
                                                                                        return format(obj.end_connect);
                                                                                    }

                                                                                    return "";
                                                                                },
                                                                                //fillspace: true,
                                                                                sort: "date"
                                                                            },
                                                                            {
                                                                                id: "abonent_id",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Subscriber ID")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                header: {content: "headerMenu"},
                                                                                headermenu: false,
                                                                                width: 32
                                                                            }
                                                                        ],
                                                                        on: {
                                                                            onBeforeFilter:function()
                                                                            {
                                                                                return false;
                                                                            },
                                                                            onAfterFilter:function()
                                                                            {
                                                                                _this.RefreshTaskData();
                                                                            },
                                                                            onBeforeSort:function()
                                                                            {
                                                                                _this.RefreshTaskData();

                                                                                return false;
                                                                            },
                                                                            onAfterColumnHide:function()
                                                                            {
                                                                                _this.SaveTableState(this);
                                                                            },
                                                                            onAfterColumnShow:function()
                                                                            {
                                                                                _this.SaveTableState(this);
                                                                            },
                                                                            onAfterColumnDrop:function()
                                                                            {
                                                                                _this.SaveTableState(this);
                                                                            }
                                                                        },
                                                                        data: [],
                                                                        version:"2"
                                                                    },
                                                                    this.GetTaskDataPagingConfig("ConnectionsFtpTabTable")
                                                                ]
                                                            },
                                                            {
                                                                id:this.Id + "ConnectionsMailTab",
                                                                rows:[
                                                                    {
                                                                        id:this.Id + "ConnectionsMailTabTable",
                                                                        view: "datatable",
                                                                        select: "row",
                                                                        resizeColumn:true,
                                                                        tooltip:true,
                                                                        scroll:"xy",
                                                                        dragColumn:true,
                                                                        headermenu: {
                                                                            width: webix.skin.$active.tableHeaderMenuWidth,
                                                                            autoheight: false,
                                                                            scroll: true
                                                                        },
                                                                        leftSplit:5,
                                                                        rightSplit:1,
                                                                        gravity:2,
                                                                        columns: [
                                                                            {
                                                                                id: "telco",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Operator")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "begin_connect",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Connection start")), {content: "dateRangeFilter"}],
                                                                                template:function(obj, common){
                                                                                    if(obj.begin_connect)
                                                                                    {
                                                                                        var format =  webix.Date.dateToStr("%d.%m.%Y %H:%i");
                                                                                        return format(obj.begin_connect);
                                                                                    }

                                                                                    return "";
                                                                                },
                                                                                //fillspace: true,
                                                                                sort: "date"
                                                                            },
                                                                            {
                                                                                id: "subject",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Subject")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "sender",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Sender")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "receiver",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Recipient")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "cc",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Copy")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "message",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Message")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "reply_to",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Reply")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "client_address",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Client IP")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "client_port",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Client port")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "server_address",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Server IP")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "server_port",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Server port")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "mail_server",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Server name")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "protocol_port",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Protocol code")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                                // ,
                                                                                // template:function(Obj)
                                                                                // {
                                                                                //     if(Obj&&Obj.protocol_code&&_this.ViewModel.ProtocolsDic[Obj.protocol_code])
                                                                                //         return _this.ViewModel.ProtocolsDic[Obj.protocol_code].value;
                                                                                //
                                                                                //     return Obj.protocol_code;
                                                                                // }
                                                                            },
                                                                            {
                                                                                id: "attachements",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Attachements")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string",
                                                                                template: function (obj, common)
                                                                                {
                                                                                    return YesNoDic[obj.attachements] ? YesNoDic[obj.attachements].value : obj.attachements;
                                                                                }
                                                                            },
                                                                            {
                                                                                id: "point",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Connection point")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "mlet_id",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Event type")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "mail_size",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Size")), {content: "textFilter"}],
                                                                                template:"#size#",
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "emlp_id",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Email protocol")), {content: "richSelectFilter", options:Helper.DictToArr(EmailProtocolDic)}],
                                                                                //fillspace: true,
                                                                                sort:"string",
                                                                                template:function (Obj)
                                                                                {
                                                                                    if(Obj&&Obj.emlp_id >=0&&EmailProtocolDic[Obj.emlp_id])
                                                                                        return EmailProtocolDic[Obj.emlp_id].value;

                                                                                    return Obj.emlp_id;
                                                                                }
                                                                            },
                                                                            {
                                                                                id: "term",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Completion reason")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "end_connect",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Connection end")), {content: "dateRangeFilter"}],
                                                                                template:function(obj, common){
                                                                                    if(obj.end_connect)
                                                                                    {
                                                                                        var format =  webix.Date.dateToStr("%d.%m.%Y %H:%i");
                                                                                        return format(obj.end_connect);
                                                                                    }

                                                                                    return "";
                                                                                },
                                                                                //fillspace: true,
                                                                                sort: "date"
                                                                            },
                                                                            {
                                                                                id: "abonent_id",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Subscriber ID")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                header: {content: "headerMenu"},
                                                                                headermenu: false,
                                                                                width: 32
                                                                            }
                                                                        ],
                                                                        on: {
                                                                            onBeforeFilter:function()
                                                                            {
                                                                                return false;
                                                                            },
                                                                            onAfterFilter:function()
                                                                            {
                                                                                _this.RefreshTaskData();
                                                                            },
                                                                            onBeforeSort:function()
                                                                            {
                                                                                _this.RefreshTaskData();

                                                                                return false;
                                                                            },
                                                                            onAfterColumnHide:function()
                                                                            {
                                                                                _this.SaveTableState(this);
                                                                            },
                                                                            onAfterColumnShow:function()
                                                                            {
                                                                                _this.SaveTableState(this);
                                                                            },
                                                                            onAfterColumnDrop:function()
                                                                            {
                                                                                _this.SaveTableState(this);
                                                                            },
                                                                            onAfterSelect:function(Selection)
                                                                            {
                                                                                var data = this.getItem(Selection);

                                                                                var message = data && data.message ? data.message : "";

                                                                                var view = _this.Views.ConnectionsMailTabMessage;

                                                                                view.define("rows", [{template:message}]);

                                                                                view.reconstruct();
                                                                            }

                                                                        },
                                                                        data: [],
                                                                        version:"9"
                                                                    },
                                                                    {view:"resizer"},
                                                                    {
                                                                        id:this.Id + "ConnectionsMailTabMessage",
                                                                        rows:[
                                                                            {template:""}
                                                                        ]
                                                                    },
                                                                    this.GetTaskDataPagingConfig("ConnectionsMailTabTable")
                                                                ]
                                                            },
                                                            {
                                                                id:this.Id + "ConnectionsImTab",
                                                                rows:[
                                                                    {
                                                                        id:this.Id + "ConnectionsImTabTable",
                                                                        view: "datatable",
                                                                        select: "row",
                                                                        resizeColumn:true,
                                                                        tooltip:true,
                                                                        scroll:"xy",
                                                                        dragColumn:true,
                                                                        headermenu: {
                                                                            width: webix.skin.$active.tableHeaderMenuWidth,
                                                                            autoheight: false,
                                                                            scroll: true
                                                                        },
                                                                        leftSplit:6,
                                                                        rightSplit:1,
                                                                        columns: [
                                                                            {
                                                                                id: "telco",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Operator")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "begin_connect",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Connection start")), {content: "dateRangeFilter"}],
                                                                                template:function(obj, common){
                                                                                    if(obj.begin_connect)
                                                                                    {
                                                                                        var format =  webix.Date.dateToStr("%d.%m.%Y %H:%i");
                                                                                        return format(obj.begin_connect);
                                                                                    }

                                                                                    return "";
                                                                                },
                                                                                //fillspace: true,
                                                                                sort: "date"
                                                                            },
                                                                            {
                                                                                id: "sender_uin",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Sender ID")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "sender_name",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Sender name")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "receiver_uin",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Receiving ID")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "receiver_name",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Receiver name")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "message",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Message")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "user_login",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Login")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "user_password",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "User password")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "im_size",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Message size")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "client_address",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Client IP")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "client_port",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Client port")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "server_address",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Server IP")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "server_port",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Server port")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "protocol",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Protocol")), {content: "richSelectFilter", options:Helper.DictToArr(ImProtocolDic)}],
                                                                                //fillspace: true,
                                                                                sort:"string",
                                                                                template:function(Obj)
                                                                                {
                                                                                    if(Obj&&Obj.protocol&&ImProtocolDic[Obj.protocol])
                                                                                        return ImProtocolDic[Obj.protocol].value;

                                                                                    return Obj.protocol;
                                                                                }
                                                                            },
                                                                            {
                                                                                id: "protocol_port",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Protocol code")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                                // ,
                                                                                // template:function(Obj)
                                                                                // {
                                                                                //     if(Obj&&Obj.protocol_code&&_this.ViewModel.ProtocolsDic[Obj.protocol_code])
                                                                                //         return _this.ViewModel.ProtocolsDic[Obj.protocol_code].value;
                                                                                //
                                                                                //     return Obj.protocol_code;
                                                                                // }
                                                                            },
                                                                            {
                                                                                id: "point",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Connection point")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "term",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Completion reason")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "end_connect",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Connection end")), {content: "dateRangeFilter"}],
                                                                                template:function(obj, common){
                                                                                    if(obj.end_connect)
                                                                                    {
                                                                                        var format =  webix.Date.dateToStr("%d.%m.%Y %H:%i");
                                                                                        return format(obj.end_connect);
                                                                                    }

                                                                                    return "";
                                                                                },
                                                                                //fillspace: true,
                                                                                sort: "date"
                                                                            },
                                                                            {
                                                                                id: "abonent_id",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Subscriber ID")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                header: {content: "headerMenu"},
                                                                                headermenu: false,
                                                                                width: 32
                                                                            }
                                                                        ],
                                                                        on: {
                                                                            onBeforeFilter:function()
                                                                            {
                                                                                return false;
                                                                            },
                                                                            onAfterFilter:function()
                                                                            {
                                                                                _this.RefreshTaskData();
                                                                            },
                                                                            onBeforeSort:function()
                                                                            {
                                                                                _this.RefreshTaskData();

                                                                                return false;
                                                                            },
                                                                            onAfterColumnHide:function()
                                                                            {
                                                                                _this.SaveTableState(this);
                                                                            },
                                                                            onAfterColumnShow:function()
                                                                            {
                                                                                _this.SaveTableState(this);
                                                                            },
                                                                            onAfterColumnDrop:function()
                                                                            {
                                                                                _this.SaveTableState(this);
                                                                            }
                                                                        },
                                                                        data: [],
                                                                        version:"5"
                                                                    },
                                                                    this.GetTaskDataPagingConfig("ConnectionsImTabTable")
                                                                ]
                                                            },
                                                            {
                                                                id:this.Id + "ConnectionsVoipTab",
                                                                rows:[
                                                                    {
                                                                        id:this.Id + "ConnectionsVoipTabTable",
                                                                        view: "datatable",
                                                                        select: "row",
                                                                        resizeColumn:true,
                                                                        tooltip:true,
                                                                        scroll:"xy",
                                                                        dragColumn:true,
                                                                        headermenu: {
                                                                            width: webix.skin.$active.tableHeaderMenuWidth,
                                                                            autoheight: false,
                                                                            scroll: true
                                                                        },
                                                                        leftSplit:5,
                                                                        rightSplit:1,
                                                                        columns: [
                                                                            {
                                                                                id: "telco",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Operator")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "begin_connect",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Connection start")), {content: "dateRangeFilter"}],
                                                                                template:function(obj, common){
                                                                                    if(obj.begin_connect)
                                                                                    {
                                                                                        var format =  webix.Date.dateToStr("%d.%m.%Y %H:%i");
                                                                                        return format(obj.begin_connect);
                                                                                    }

                                                                                    return "";
                                                                                },
                                                                                //fillspace: true,
                                                                                sort: "date"
                                                                            },
                                                                            {
                                                                                id: "calling_number",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Calling number")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "originator_name",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Name of the initiator of communication")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "called_number",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Called number")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "client_address",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Client IP")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "client_port",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Client port")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "server_address",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Server IP")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "server_port",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Server port")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "session_id",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Session ID")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "conference_id",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Conference ID")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "duration",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Duration of conversation")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "fax",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Sending a fax")), {content: "richSelectFilter", options:Helper.DictToArr(YesNoDic)}],
                                                                                //fillspace: true,
                                                                                sort:"string",
                                                                                template:function (Obj)
                                                                                {
                                                                                    if(Obj&&Obj.fax>=0&&YesNoDic[Obj.fax])
                                                                                        return YesNoDic[Obj.fax].value;

                                                                                    return Obj.fax;
                                                                                }
                                                                            },
                                                                            {
                                                                                id: "inbound_bunch",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Inbound bunch")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "outbound_bunch",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Outbound bunch")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "gateways",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Gateway")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "voip_protocol",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Voip protocol")), {content: "richSelectFilter", options:Helper.DictToArr(VoipProtocolDic)}],
                                                                                //fillspace: true,
                                                                                sort:"string",
                                                                                template:function(Obj)
                                                                                {
                                                                                    if(Obj&&Obj.voip_protocol>=0&&VoipProtocolDic[Obj.voip_protocol])
                                                                                        return VoipProtocolDic[Obj.voip_protocol].value;

                                                                                    return Obj.voip_protocol;
                                                                                }
                                                                            },
                                                                            {
                                                                                id: "service_type",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Connection method")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "service_id",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Service ID")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "protocol_port",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Protocol code")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                                // ,
                                                                                // template:function(Obj)
                                                                                // {
                                                                                //     if(Obj&&Obj.protocol_code&&_this.ViewModel.ProtocolsDic[Obj.protocol_code])
                                                                                //         return _this.ViewModel.ProtocolsDic[Obj.protocol_code].value;
                                                                                //
                                                                                //     return Obj.protocol_code;
                                                                                // }
                                                                            },
                                                                            {
                                                                                id: "point",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Connection point")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "in_bytes",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Bytes recieved")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "out_bytes",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Bytes sent")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "term",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Completion reason")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "end_connect",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Connection end")), {content: "dateRangeFilter"}],
                                                                                template:function(obj, common){
                                                                                    if(obj.end_connect)
                                                                                    {
                                                                                        var format =  webix.Date.dateToStr("%d.%m.%Y %H:%i");
                                                                                        return format(obj.end_connect);
                                                                                    }

                                                                                    return "";
                                                                                },
                                                                                //fillspace: true,
                                                                                sort: "date"
                                                                            },
                                                                            {
                                                                                id: "abonent_id",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Subscriber ID")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                header: {content: "headerMenu"},
                                                                                headermenu: false,
                                                                                width: 32
                                                                            }
                                                                        ],
                                                                        on: {
                                                                            onBeforeFilter:function()
                                                                            {
                                                                                return false;
                                                                            },
                                                                            onAfterFilter:function()
                                                                            {
                                                                                _this.RefreshTaskData();
                                                                            },
                                                                            onBeforeSort:function()
                                                                            {
                                                                                _this.RefreshTaskData();

                                                                                return false;
                                                                            },
                                                                            onAfterColumnHide:function()
                                                                            {
                                                                                _this.SaveTableState(this);
                                                                            },
                                                                            onAfterColumnShow:function()
                                                                            {
                                                                                _this.SaveTableState(this);
                                                                            },
                                                                            onAfterColumnDrop:function()
                                                                            {
                                                                                _this.SaveTableState(this);
                                                                            }
                                                                        },
                                                                        data: [],
                                                                        version:"2"
                                                                    },
                                                                    this.GetTaskDataPagingConfig("ConnectionsVoipTabTable")
                                                                ]
                                                            },
                                                            {
                                                                id:this.Id + "ConnectionsPstnTab",
                                                                rows:[
                                                                    {
                                                                        id:this.Id + "ConnectionsPstnTabTable",
                                                                        view: "datatable",
                                                                        select: "row",
                                                                        resizeColumn:true,
                                                                        tooltip:true,
                                                                        scroll:"xy",
                                                                        dragColumn:true,
                                                                        headermenu: {
                                                                            width: webix.skin.$active.tableHeaderMenuWidth,
                                                                            autoheight: false,
                                                                            scroll: true
                                                                        },
                                                                        leftSplit:7,
                                                                        rightSplit:1,
                                                                        columns: [
                                                                            {
                                                                                id: "telco",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Operator")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "begin_connect",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Connection start")), {content: "dateRangeFilter"}],
                                                                                template:function(obj, common){
                                                                                    if(obj.begin_connect)
                                                                                    {
                                                                                        var format =  webix.Date.dateToStr("%d.%m.%Y %H:%i");
                                                                                        return format(obj.begin_connect);
                                                                                    }

                                                                                    return "";
                                                                                },
                                                                                //fillspace: true,
                                                                                sort: "date"
                                                                            },
                                                                            {
                                                                                id: "in_directory_number",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Caller's number")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "in_internal_number",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Extension number of the caller")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "out_directory_number",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Called number")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "out_internal_number",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Extension number of the called subscriber")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "dialed_digits",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Dialed number")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "duration",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Connection duration")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "call_type",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Call type")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "supplement_service_id",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Additional services for connection")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "in_abonent_type",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Caller type")), {content: "richSelectFilter", options:Helper.DictToArr(PstnPhoneAbonentTypeDic)}],
                                                                                //fillspace: true,
                                                                                sort:"string",
                                                                                template:function(Obj)
                                                                                {
                                                                                    if(Obj&&Obj.in_abonent_type&&PstnPhoneAbonentTypeDic[Obj.in_abonent_type])
                                                                                        return PstnPhoneAbonentTypeDic[Obj.in_abonent_type].value;

                                                                                    return PstnPhoneAbonentTypeDic[Obj.in_abonent_type];

                                                                                }
                                                                            },
                                                                            {
                                                                                id: "out_abonent_type",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Type of called subscriber")), {content: "richSelectFilter", options:Helper.DictToArr(PstnPhoneAbonentTypeDic)}],
                                                                                //fillspace: true,
                                                                                sort:"string",
                                                                                template:function(Obj)
                                                                                {
                                                                                    if(Obj&&Obj.out_abonent_type&&PstnPhoneAbonentTypeDic[Obj.out_abonent_type])
                                                                                        return PstnPhoneAbonentTypeDic[Obj.out_abonent_type].value;

                                                                                    return PstnPhoneAbonentTypeDic[Obj.out_abonent_type];

                                                                                }
                                                                            },
                                                                            {
                                                                                id: "switch",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Switch code")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "border_switch",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Border switch code")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "inbound_bunch_dic",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Inbound bunch")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "outbound_bunch_dic",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Outbound bunch")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "phone_card_number",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Phone card number")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "forwarding_identifier",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Forwarding number")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "term",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Cause of connection termination")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "end_connect",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Connection end")), {content: "dateRangeFilter"}],
                                                                                template:function(obj, common){
                                                                                    if(obj.end_connect)
                                                                                    {
                                                                                        var format =  webix.Date.dateToStr("%d.%m.%Y %H:%i");
                                                                                        return format(obj.end_connect);
                                                                                    }

                                                                                    return "";
                                                                                },
                                                                                //fillspace: true,
                                                                                sort: "date"
                                                                            },
                                                                            {
                                                                                header: {content: "headerMenu"},
                                                                                headermenu: false,
                                                                                width: 32
                                                                            }
                                                                        ],
                                                                        on: {
                                                                            onBeforeFilter:function()
                                                                            {
                                                                                return false;
                                                                            },
                                                                            onAfterFilter:function()
                                                                            {
                                                                                _this.RefreshTaskData();
                                                                            },
                                                                            onBeforeSort:function()
                                                                            {
                                                                                _this.RefreshTaskData();

                                                                                return false;
                                                                            },
                                                                            onAfterColumnHide:function()
                                                                            {
                                                                                _this.SaveTableState(this);
                                                                            },
                                                                            onAfterColumnShow:function()
                                                                            {
                                                                                _this.SaveTableState(this);
                                                                            },
                                                                            onAfterColumnDrop:function()
                                                                            {
                                                                                _this.SaveTableState(this);
                                                                            }
                                                                        },
                                                                        data: [],
                                                                        version:"3"
                                                                    },
                                                                    this.GetTaskDataPagingConfig("ConnectionsPstnTabTable")
                                                                ]
                                                            },
                                                            {
                                                                id:this.Id + "ConnectionsTermTab",
                                                                rows:[
                                                                    {
                                                                        id:this.Id + "ConnectionsTermTabTable",
                                                                        view: "datatable",
                                                                        select: "row",
                                                                        resizeColumn:true,
                                                                        tooltip:true,
                                                                        scroll:"xy",
                                                                        dragColumn:true,
                                                                        headermenu: {
                                                                            width: webix.skin.$active.tableHeaderMenuWidth,
                                                                            autoheight: false,
                                                                            scroll: true
                                                                        },
                                                                        columns: [
                                                                            {
                                                                                id: "telco",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Operator")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "begin_connect",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Connection start")), {content: "dateRangeFilter"}],
                                                                                template:function(obj, common){
                                                                                    if(obj.begin_connect)
                                                                                    {
                                                                                        var format =  webix.Date.dateToStr("%d.%m.%Y %H:%i");
                                                                                        return format(obj.begin_connect);
                                                                                    }

                                                                                    return "";
                                                                                },
                                                                                //fillspace: true,
                                                                                sort: "date"
                                                                            },
                                                                            {
                                                                                id: "client_address",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Client IP")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "client_port",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Client port")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "server_address",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Server IP")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "server_port",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Server port")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "protocol_port",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Protocol code")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                                // ,
                                                                                // template:function(Obj)
                                                                                // {
                                                                                //     if(Obj&&Obj.protocol_code&&_this.ViewModel.ProtocolsDic[Obj.protocol_code])
                                                                                //         return _this.ViewModel.ProtocolsDic[Obj.protocol_code].value;
                                                                                //
                                                                                //     return Obj.protocol_code;
                                                                                // }
                                                                            },
                                                                            {
                                                                                id: "point",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Connection point")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "term_protocol",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Terminal protocol")), {content: "richSelectFilter", options:Helper.DictToArr(TermProtocolDic)}],
                                                                                //fillspace: true,
                                                                                sort:"string",
                                                                                template:function (Obj)
                                                                                {
                                                                                    if(Obj&&Obj.term_protocol&&TermProtocolDic[Obj.term_protocol])
                                                                                        return TermProtocolDic[Obj.term_protocol].value;

                                                                                    return Obj.term_protocol;
                                                                                }
                                                                            },
                                                                            {
                                                                                id: "in_bytes",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Bytes recieved")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "out_bytes",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Bytes sent")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "end_connect",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Connection end")), {content: "dateRangeFilter"}],
                                                                                template:function(obj, common){
                                                                                    if(obj.end_connect)
                                                                                    {
                                                                                        var format =  webix.Date.dateToStr("%d.%m.%Y %H:%i");
                                                                                        return format(obj.end_connect);
                                                                                    }

                                                                                    return "";
                                                                                },
                                                                                //fillspace: true,
                                                                                sort: "date"
                                                                            },
                                                                            {
                                                                                id: "abonent_id",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Subscriber ID")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                header: {content: "headerMenu"},
                                                                                headermenu: false,
                                                                                minWidth: 32,
                                                                                fillspace: true
                                                                            }
                                                                        ],
                                                                        on: {
                                                                            onBeforeFilter:function()
                                                                            {
                                                                                return false;
                                                                            },
                                                                            onAfterFilter:function()
                                                                            {
                                                                                _this.RefreshTaskData();
                                                                            },
                                                                            onBeforeSort:function()
                                                                            {
                                                                                _this.RefreshTaskData();

                                                                                return false;
                                                                            },
                                                                            onAfterColumnHide:function()
                                                                            {
                                                                                _this.SaveTableState(this);
                                                                            },
                                                                            onAfterColumnShow:function()
                                                                            {
                                                                                _this.SaveTableState(this);
                                                                            },
                                                                            onAfterColumnDrop:function()
                                                                            {
                                                                                _this.SaveTableState(this);
                                                                            }
                                                                        },
                                                                        data: [],
                                                                        version:2
                                                                    },
                                                                    this.GetTaskDataPagingConfig("ConnectionsTermTabTable")
                                                                ]
                                                            },
                                                            {
                                                                id:this.Id + "ConnectionsRfTab",
                                                                rows:[
                                                                    {
                                                                        id:this.Id + "ConnectionsRfTabTable",
                                                                        view: "datatable",
                                                                        select: "row",
                                                                        resizeColumn:true,
                                                                        tooltip:true,
                                                                        scroll:"xy",
                                                                        dragColumn:true,
                                                                        headermenu: {
                                                                            width: webix.skin.$active.tableHeaderMenuWidth,
                                                                            autoheight: false,
                                                                            scroll: true
                                                                        },
                                                                        columns: [
                                                                            {
                                                                                id: "telco",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Operator")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "begin_connect",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Connection start")), {content: "dateRangeFilter"}],
                                                                                template:function(obj, common){
                                                                                    if(obj.begin_connect)
                                                                                    {
                                                                                        var format =  webix.Date.dateToStr("%d.%m.%Y %H:%i");
                                                                                        return format(obj.begin_connect);
                                                                                    }

                                                                                    return "";
                                                                                },
                                                                                //fillspace: true,
                                                                                sort: "date"
                                                                            },
                                                                            {
                                                                                id: "client_address",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Client IP")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "client_port",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Client port")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "server_address",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Server IP")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "server_port",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Server port")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "sni",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "SNI, CN")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "protocol_port",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Protocol code")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                                // ,
                                                                                // template:function(Obj)
                                                                                // {
                                                                                //     if(Obj&&Obj.protocol_code&&_this.ViewModel.ProtocolsDic[Obj.protocol_code])
                                                                                //         return _this.ViewModel.ProtocolsDic[Obj.protocol_code].value;
                                                                                //
                                                                                //     return Obj.protocol_code;
                                                                                // }
                                                                            },
                                                                            {
                                                                                id: "point",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Connection point")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "in_bytes",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Bytes recieved")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "out_bytes",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Bytes sent")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                id: "end_connect",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Connection end")), {content: "dateRangeFilter"}],
                                                                                template:function(obj, common){
                                                                                    if(obj.end_connect)
                                                                                    {
                                                                                        var format =  webix.Date.dateToStr("%d.%m.%Y %H:%i");
                                                                                        return format(obj.end_connect);
                                                                                    }

                                                                                    return "";
                                                                                },
                                                                                //fillspace: true,
                                                                                sort: "date"
                                                                            },
                                                                            {
                                                                                id: "abonent_id",
                                                                                header: [TooltipTemplate(LangHelper.GetString(this.Id, "Subscriber ID")), {content: "textFilter"}],
                                                                                //fillspace: true,
                                                                                sort:"string"
                                                                            },
                                                                            {
                                                                                header: {content: "headerMenu"},
                                                                                headermenu: false,
                                                                                minWidth: 32,
                                                                                fillspace: true
                                                                            }
                                                                        ],
                                                                        on: {
                                                                            onBeforeFilter:function()
                                                                            {
                                                                                return false;
                                                                            },
                                                                            onAfterFilter:function()
                                                                            {
                                                                                _this.RefreshTaskData();
                                                                            },
                                                                            onBeforeSort:function()
                                                                            {
                                                                                _this.RefreshTaskData();

                                                                                return false;
                                                                            },
                                                                            onAfterColumnHide:function()
                                                                            {
                                                                                _this.SaveTableState(this);
                                                                            },
                                                                            onAfterColumnShow:function()
                                                                            {
                                                                                _this.SaveTableState(this);
                                                                            },
                                                                            onAfterColumnDrop:function()
                                                                            {
                                                                                _this.SaveTableState(this);
                                                                            }
                                                                        },
                                                                        data: [],
                                                                        version:"4"
                                                                    },
                                                                    this.GetTaskDataPagingConfig("ConnectionsRfTabTable")
                                                                ]
                                                            }
                                                        ]
                                                    }
                                                ]
                                            }
                                        },
                                        {
                                            gravity:0
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    ];

    this.Views = {
        AddTaskBtn:null,
        AddSubTaskBtn:null,

        TasksTable:null,
        TasksTablePaging:null,
        TasksTablePagingSize:null,

        ReportsPanel:null,

        ConnectionsTabs:null,
        TaskDataInfoLabel:null,

        CreateTaskContextForms:{
            CreateTaskContextForm:null,
            aaa:null,
            http:null,
            ftp:null,
            mail:null,
            im:null,
            voip:null,
            pstn:null,
            raw_flows:null,
            term:null
        },
        CreateTaskContextFormTabbar:null,

        ConnectionsMailTabTable:null,
        ConnectionsMailTabTablePaging:null,
        ConnectionsMailTabTablePagingSize:null,
        ConnectionsMailTabMessage:null,

        ConnectionsFtpTabTable:null,
        ConnectionsFtpTabTablePaging:null,
        ConnectionsFtpTabTablePagingSize:null,

        ConnectionsVoipTabTable:null,
        ConnectionsVoipTabTablePaging:null,
        ConnectionsVoipTabTablePagingSize:null,

        ConnectionsHttpTabTable:null,
        ConnectionsHttpTabTablePaging:null,
        ConnectionsHttpTabTablePagingSize:null,

        ConnectionsImTabTable:null,
        ConnectionsImTabTablePaging:null,
        ConnectionsImTabTablePagingSize:null,

        ConnectionsRfTabTable:null,
        ConnectionsRfTabTablePaging:null,
        ConnectionsRfTabTablePagingSize:null,

        ConnectionsTermTabTable:null,
        ConnectionsTermTabTablePaging:null,
        ConnectionsTermTabTablePagingSize:null,

        ConnectionsPstnTabTable:null,
        ConnectionsPstnTabTablePaging:null,
        ConnectionsPstnTabTablePagingSize:null,

        ConnectionsAaaTabTable:null,
        ConnectionsAaaTabTablePaging:null,
        ConnectionsAaaTabTablePagingSize:null
    };

    this.Subscriptions = {};

    this.TasksTableRowsSelectedCount = new Rx.BehaviorSubject(0);

    this.MonitorTimer = null;

    this.TaskDataMonitorTimer = null;

    return this;
}

ConnectionsValidateDataViewController.prototype.Destroy = function ()
{
    var _this = this;

    clearTimeout(this.MonitorTimer);
    clearTimeout(this.TaskDataMonitorTimer);

    this.SaveAllTablesState();

    Object.keys(this.Subscriptions).forEach(function (key)
    {
        _this.Subscriptions[key].unsubscribe();
    });

    this.ViewModel.Destroy();
    delete this.ViewModel;

    this.Health.Destroy();
    delete this.Health;
};

ConnectionsValidateDataViewController.prototype.ViewDidLoad = function (Params)
{
    var _this = this;

    webix.ready(function () {
        $$(_this.Id + "LeftSideBar").select(_this.Id);
    });

    this.BindAll();

    this.Refresh();

    this.RestoreAllTablesState();

    this.CheckHealth();
};

ConnectionsValidateDataViewController.prototype.BindAll = function (Params)
{
    var _this = this;

    this.Views.AddTaskBtn = $$(this.Id + "AddTaskBtn");
    this.Views.AddSubTaskBtn = $$(this.Id + "AddSubTaskBtn");

    this.Views.TasksTable = $$(this.Id + "TasksTable");
    this.Views.TasksTablePaging = $$(this.Id + "TasksTable" + "Paging");
    this.Views.TasksTablePagingSize = $$(this.Id + "TasksTable" + "Paging" + "Size");

    this.Views.ConnectionsMailTabTable = $$(this.Id + "ConnectionsMailTabTable");
    this.Views.ConnectionsMailTabTablePaging = $$(this.Id + "ConnectionsMailTabTable"+ "Paging");
    this.Views.ConnectionsMailTabTablePagingSize = $$(this.Id + "ConnectionsMailTabTable"+ "Paging" + "Size");
    webix.extend(this.Views.ConnectionsMailTabTable, webix.ProgressBar);
    webix.extend(this.Views.ConnectionsMailTabTable, webix.OverlayBox);
    this.Views.ConnectionsMailTabMessage = $$(this.Id + "ConnectionsMailTabMessage");

    this.Views.ConnectionsFtpTabTable = $$(this.Id + "ConnectionsFtpTabTable");
    this.Views.ConnectionsFtpTabTablePaging = $$(this.Id + "ConnectionsFtpTabTable"+ "Paging");
    this.Views.ConnectionsFtpTabTablePagingSize = $$(this.Id + "ConnectionsFtpTabTable"+ "Paging" + "Size");
    webix.extend(this.Views.ConnectionsFtpTabTable, webix.ProgressBar);
    webix.extend(this.Views.ConnectionsFtpTabTable, webix.OverlayBox);

    this.Views.ConnectionsVoipTabTable = $$(this.Id + "ConnectionsVoipTabTable");
    this.Views.ConnectionsVoipTabTablePaging = $$(this.Id + "ConnectionsVoipTabTable"+ "Paging");
    this.Views.ConnectionsVoipTabTablePagingSize = $$(this.Id + "ConnectionsVoipTabTable"+ "Paging" + "Size");
    webix.extend(this.Views.ConnectionsVoipTabTable, webix.ProgressBar);
    webix.extend(this.Views.ConnectionsVoipTabTable, webix.OverlayBox);

    this.Views.ConnectionsHttpTabTable = $$(this.Id + "ConnectionsHttpTabTable");
    this.Views.ConnectionsHttpTabTablePaging = $$(this.Id + "ConnectionsHttpTabTable"+ "Paging");
    this.Views.ConnectionsHttpTabTablePagingSize = $$(this.Id + "ConnectionsHttpTabTable"+ "Paging" + "Size");
    webix.extend(this.Views.ConnectionsHttpTabTable, webix.ProgressBar);
    webix.extend(this.Views.ConnectionsHttpTabTable, webix.OverlayBox);

    this.Views.ConnectionsImTabTable = $$(this.Id + "ConnectionsImTabTable");
    this.Views.ConnectionsImTabTablePaging = $$(this.Id + "ConnectionsImTabTable"+ "Paging");
    this.Views.ConnectionsImTabTablePagingSize = $$(this.Id + "ConnectionsImTabTable"+ "Paging" + "Size");
    webix.extend(this.Views.ConnectionsImTabTable, webix.ProgressBar);
    webix.extend(this.Views.ConnectionsImTabTable, webix.OverlayBox);

    this.Views.ConnectionsRfTabTable = $$(this.Id + "ConnectionsRfTabTable");
    this.Views.ConnectionsRfTabTablePaging = $$(this.Id + "ConnectionsRfTabTable"+ "Paging");
    this.Views.ConnectionsRfTabTablePagingSize = $$(this.Id + "ConnectionsRfTabTable"+ "Paging" + "Size");
    webix.extend(this.Views.ConnectionsRfTabTable, webix.ProgressBar);
    webix.extend(this.Views.ConnectionsRfTabTable, webix.OverlayBox);

    this.Views.ConnectionsTermTabTable = $$(this.Id + "ConnectionsTermTabTable");
    this.Views.ConnectionsTermTabTablePaging = $$(this.Id + "ConnectionsTermTabTable"+ "Paging");
    this.Views.ConnectionsTermTabTablePagingSize = $$(this.Id + "ConnectionsTermTabTable"+ "Paging" + "Size");
    webix.extend(this.Views.ConnectionsTermTabTable, webix.ProgressBar);
    webix.extend(this.Views.ConnectionsTermTabTable, webix.OverlayBox);

    this.Views.ConnectionsPstnTabTable = $$(this.Id + "ConnectionsPstnTabTable");
    this.Views.ConnectionsPstnTabTablePaging = $$(this.Id + "ConnectionsPstnTabTable"+ "Paging");
    this.Views.ConnectionsPstnTabTablePagingSize = $$(this.Id + "ConnectionsPstnTabTable"+ "Paging" + "Size");
    webix.extend(this.Views.ConnectionsPstnTabTable, webix.ProgressBar);
    webix.extend(this.Views.ConnectionsPstnTabTable, webix.OverlayBox);

    this.Views.ConnectionsAaaTabTable = $$(this.Id + "ConnectionsAaaTabTable");
    this.Views.ConnectionsAaaTabTablePaging = $$(this.Id + "ConnectionsAaaTabTable"+ "Paging");
    this.Views.ConnectionsAaaTabTablePagingSize = $$(this.Id + "ConnectionsAaaTabTable"+ "Paging" + "Size");
    webix.extend(this.Views.ConnectionsAaaTabTable, webix.ProgressBar);
    webix.extend(this.Views.ConnectionsAaaTabTable, webix.OverlayBox);


    this.Views.ReportsPanel = $$(this.Id + "ReportsPanel");
    webix.extend(this.Views.ReportsPanel, webix.OverlayBox);

    this.Views.ConnectionsTabs = $$(this.Id + "ConnectionsTabs");

    this.CreatTaskContext = webix.ui(this.CreateTaskContextFormConfig);
    this.Views.CreateTaskContextForms.CreateTaskContextForm = $$(this.Id+"CreateTaskContextForm");
    this.Views.CreateTaskContextForms.aaa = $$(this.Id+"CreateTaskContextForm_aaa");
    this.Views.CreateTaskContextForms.http = $$(this.Id+"CreateTaskContextForm_http");
    this.Views.CreateTaskContextForms.ftp = $$(this.Id+"CreateTaskContextForm_ftp");
    this.Views.CreateTaskContextForms.mail = $$(this.Id+"CreateTaskContextForm_mail");
    this.Views.CreateTaskContextForms.im = $$(this.Id+"CreateTaskContextForm_im");
    this.Views.CreateTaskContextForms.voip = $$(this.Id+"CreateTaskContextForm_voip");
    this.Views.CreateTaskContextForms.pstn = $$(this.Id+"CreateTaskContextForm_pstn");
    this.Views.CreateTaskContextForms.raw_flows = $$(this.Id+"CreateTaskContextForm_raw_flows");
    this.Views.CreateTaskContextForms.term = $$(this.Id+"CreateTaskContextForm_term");


    this.Views.CreateTaskContextFormTabbar = $$(this.Id+"CreateTaskContextForm" + "Tabbar");

    this.Views.TaskDataInfoLabel = $$(this.Id + "TaskDataInfoLabel");

    this.Views.ConnectionsTabs.attachEvent("onChange", function(New, Old)
    {
        _this.RefreshTaskData();
    });

    this.Subscriptions.SignalDicUpdatedSubscription = App.DicsManager.Signal[DicsEnum.TelcosDic].subscribe(function (Updated)
    {
        if (Updated)
        {
            var telcos = _this.Views.CreateTaskContextForms.CreateTaskContextForm.elements.telcos.getList();

            telcos.clearAll();
            telcos.parse(App.DicsManager.GetDicAsArray(DicsEnum.TelcosDic));

            _this.RefreshTasksTable(0,1);
        }
    });

    this.Subscriptions.SignalTasksUpdatedSubscription = this.ViewModel.SignalTasksUpdated.subscribe(function (Updated)
    {
        if (Updated)
        {
            _this.UpdateTasksTable();

            _this.MonitorTasksTable();
        }
    });

    this.Subscriptions.SignalTaskDataUpdatedSubscription = this.ViewModel.SignalTaskDataUpdated.subscribe(function (TaskId)
    {
        if (TaskId)
        {
            _this.UpdateTaskData(TaskId);
        }
    });

    this.Subscriptions.TasksTableRowsSelectedCountSubscription = this.TasksTableRowsSelectedCount.subscribe(function (Value)
    {
        if(Value == 1)
        {
            var selectedData = _this.GetTasksTableSelectedData();

            if(selectedData.status || selectedData.report_count)
            {
                _this.Views.ReportsPanel.enable();

                _this.RefreshTaskData();
            }
            else
            {
                _this.Views.ReportsPanel.disable("");
            }

            _this.Views.AddSubTaskBtn.enable();
        }
        else
        {
            _this.Views.ReportsPanel.disable();
            _this.Views.AddSubTaskBtn.disable();
        }
    });

    this.Subscriptions.SignalTaskUpdatedSubscription = this.ViewModel.SignalTaskUpdated.subscribe(function (Task)
    {
        if(Task)
        {
            var selectedData = _this.GetTasksTableSelectedData();

            var rows = _this.Views.TasksTable.find(function(obj){
                return obj.task_id == Task.task_id;
            });

            if(rows && rows.length == 1)
            {
                _this.Views.TasksTable.updateItem(rows[0].id,  Task);

                if(selectedData && selectedData.task_id == Task.task_id && Task.min_status != 0)
                {
                    _this.OnTasksTableRowSelect();
                }
            }

        }
    });

    this.Subscriptions.SignalHealthUpdatedSubscription = this.Health.HealthUpdated.subscribe(function (Updated)
    {
        if (Updated)
        {
            _this.HealthResult();
        }
    });

    this.Subscriptions.SignalDicPortsUpdatedSubscription = App.DicsManager.Signal[DicsEnum.PortsDic].subscribe(function (Updated)
    {
        if (Updated)
        {
            var opt = App.DicsManager.GetDicAsArray(DicsEnum.PortsDic);

            var list1 = $$(_this.Views.CreateTaskContextForms.aaa.elements.connect_type.config.suggest).getList();
            list1.clearAll();
            list1.parse(opt);


            var list2 = $$(_this.Views.CreateTaskContextForms.raw_flows.elements.protocol_code.config.suggest).getList();
            list2.clearAll();
            list2.parse(opt);
        }
    });

    this.Subscriptions.SignalDicTermCausesUpdatedSubscription = App.DicsManager.Signal[DicsEnum.TermCausesDic].subscribe(function (Updated)
    {
        if (Updated)
        {
            var opt = App.DicsManager.GetDicAsArray(DicsEnum.TermCausesDic);

            var lists = [];

            lists.push($$(_this.Views.CreateTaskContextForms.http.elements.term_cause.config.suggest).getList());
            lists.push($$(_this.Views.CreateTaskContextForms.ftp.elements.term_cause.config.suggest).getList());
            lists.push($$(_this.Views.CreateTaskContextForms.mail.elements.term_cause.config.suggest).getList());
            lists.push($$(_this.Views.CreateTaskContextForms.im.elements.term_cause.config.suggest).getList());
            lists.push($$(_this.Views.CreateTaskContextForms.voip.elements.term_cause.config.suggest).getList());
            lists.push($$(_this.Views.CreateTaskContextForms.pstn.elements.term_cause.config.suggest).getList());

            lists.forEach(function (Item)
            {
                Item.clearAll();
                Item.parse(opt);
            });
        }
    });

    this.Subscriptions.SignalDicIpDataPointsUpdatedSubscription = App.DicsManager.Signal[DicsEnum.IpDataPointsDic].subscribe(function (Updated)
    {
        if (Updated)
        {
            var opt = App.DicsManager.GetDicAsArray(DicsEnum.IpDataPointsDic);

            var lists = [];

            lists.push($$(_this.Views.CreateTaskContextForms.aaa.elements.point_id.config.suggest).getList());
            lists.push($$(_this.Views.CreateTaskContextForms.http.elements.point_id.config.suggest).getList());
            lists.push($$(_this.Views.CreateTaskContextForms.ftp.elements.point_id.config.suggest).getList());
            lists.push($$(_this.Views.CreateTaskContextForms.mail.elements.point_id.config.suggest).getList());
            lists.push($$(_this.Views.CreateTaskContextForms.im.elements.point_id.config.suggest).getList());
            lists.push($$(_this.Views.CreateTaskContextForms.term.elements.point_id.config.suggest).getList());
            lists.push($$(_this.Views.CreateTaskContextForms.raw_flows.elements.point_id.config.suggest).getList());
            lists.push($$(_this.Views.CreateTaskContextForms.voip.elements.point_id.config.suggest).getList());

            lists.forEach(function (Item)
            {
                Item.clearAll();
                Item.parse(opt);
            });
        }
    });

    this.Subscriptions.SignalDicSwitchesDicUpdatedSubscription = App.DicsManager.Signal[DicsEnum.SwitchesDic].subscribe(function (Updated)
    {
        if (Updated)
        {
            var opt = App.DicsManager.GetDicAsArray(DicsEnum.SwitchesDic);

            var lists = [];

            lists.push($$(_this.Views.CreateTaskContextForms.pstn.elements.switch_id.config.suggest).getList());
            lists.push($$(_this.Views.CreateTaskContextForms.pstn.elements.border_switch_id.config.suggest).getList());

            lists.forEach(function (Item)
            {
                Item.clearAll();
                Item.parse(opt);
            });
        }
    });

    this.Subscriptions.SignalDicBunchesDicUpdatedSubscription = App.DicsManager.Signal[DicsEnum.BunchesDic].subscribe(function (Updated)
    {
        if (Updated)
        {
            var opt_0 = App.DicsManager.GetDicAsArrayWithFilter(DicsEnum.BunchesDic, "dic_id_type", "0");
            var opt_1 = App.DicsManager.GetDicAsArrayWithFilter(DicsEnum.BunchesDic, "dic_id_type", "1");
            var opt_2 = App.DicsManager.GetDicAsArrayWithFilter(DicsEnum.BunchesDic, "dic_id_type", "2");
            var opt_3 = App.DicsManager.GetDicAsArrayWithFilter(DicsEnum.BunchesDic, "dic_id_type", "3");
            var opt_2_3 = opt_2.concat(opt_3);

            var lists = [];

            lists.push($$(_this.Views.CreateTaskContextForms.pstn.elements.inbound_bunch.config.suggest).getList());
            lists.push($$(_this.Views.CreateTaskContextForms.pstn.elements.outbound_bunch.config.suggest).getList());
            lists.push($$(_this.Views.CreateTaskContextForms.voip.elements.inbound_bunch_gsm.config.suggest).getList());
            lists.push($$(_this.Views.CreateTaskContextForms.voip.elements.outbound_bunch_gsm.config.suggest).getList());

            lists.forEach(function (Item)
            {
                Item.clearAll();
                Item.parse(opt_0);
            });

            var lists = [];

            lists.push($$(_this.Views.CreateTaskContextForms.voip.elements.inbound_bunch_cdma_umts_mac.config.suggest).getList());
            lists.push($$(_this.Views.CreateTaskContextForms.voip.elements.outbound_bunch_cdma_umts_mac.config.suggest).getList());

            lists.forEach(function (Item)
            {
                Item.clearAll();
                Item.parse(opt_1);
            });

            var lists = [];

            lists.push($$(_this.Views.CreateTaskContextForms.voip.elements.inbound_bunch_cdma_umts_atm_vpi.config.suggest).getList());
            lists.push($$(_this.Views.CreateTaskContextForms.voip.elements.outbound_bunch_cdma_umts_atm_vpi.config.suggest).getList());

            lists.forEach(function (Item)
            {
                Item.clearAll();
                Item.parse(opt_2_3);
            });

            var lists = [];

            lists.push($$(_this.Views.CreateTaskContextForms.voip.elements.inbound_bunch_cdma_umts_atm_vci.config.suggest).getList());
            lists.push($$(_this.Views.CreateTaskContextForms.voip.elements.outbound_bunch_cdma_umts_atm_vci.config.suggest).getList());

            lists.forEach(function (Item)
            {
                Item.clearAll();
                Item.parse(opt_3);
            });
        }
    });
};

ConnectionsValidateDataViewController.prototype.Refresh = function ()
{
    if(!App.DicsManager.GetDic(DicsEnum.TelcosDic))
        App.DicsManager.Refresh({data:[DicsEnum.TelcosDic]});
};

ConnectionsValidateDataViewController.prototype.UpdateTasksTable = function (Params) 
{
    var _this = this;

    var dic = App.DicsManager.GetDicAsArray(DicsEnum.UsersDic);
    
    this.Views.TasksTable.getColumnConfig("user_id").header[1].options = App.DicsManager.GetDicAsArray(DicsEnum.UsersDic);

    this.Views.TasksTable.clearAll();
    this.Views.TasksTable.parse(_this.ViewModel.GetTasks());

    this.Views.TasksTablePagingSize.setValue(this.ViewModel.TasksFilter.Total.size);

    this.Views.TasksTablePaging.define(this.ViewModel.TasksFilter.Total);
    this.Views.TasksTablePaging.refresh();
};

ConnectionsValidateDataViewController.prototype.RefreshTasksTable = function(Page, Sync)
{
    var _this = this;

    var state = this.Views.TasksTable.getState();

    var size = this.Views.TasksTablePagingSize.getValue();

    this.ViewModel.RefershTasks({page:Page, sync:Sync, filter:state.filter, size: size, sort:state.sort ? state.sort:null, callback:function ()
    {

    }});
};

ConnectionsValidateDataViewController.prototype.MonitorTasksTable = function()
{
    var _this = this;

    clearTimeout(this.MonitorTimer);

    var tasks = this.Views.TasksTable.serialize();

    var tasksIdsToMonitor = [];

    var addToMonitor = function (Tasks)
    {
        Tasks.forEach(function (Task)
        {
            if(Task.task_id && Task.min_status == 0 && Task.sorm_data && Task.sorm_data.length)
            {
                tasksIdsToMonitor.push(Task.task_id)
            }

            if(Task.data && Task.data.length)
            {
                addToMonitor(Task.data);
            }
        });
    };

    addToMonitor(tasks);

    if(tasksIdsToMonitor.length)
    {
        this.ViewModel.RefershTasks({tasks_ids:tasksIdsToMonitor, callback:function ()
        {
            _this.MonitorTimer = setTimeout(function ()
            {
                _this.MonitorTasksTable();
            }, 10000);
        }});
    }
};

ConnectionsValidateDataViewController.prototype.GetTasksTableSelectedData = function ()
{
    return this.Views.TasksTable.getSelectedItem();
};

ConnectionsValidateDataViewController.prototype.OnTasksTableRowSelect = function ()
{
    var data = this.GetTasksTableSelectedData();

    this.TasksTableRowsSelectedCount.next(data?(Array.isArray(data)?data.length:1):0);
};

ConnectionsValidateDataViewController.prototype.OnTasksTableRowUnSelect = function ()
{
    var data = this.GetTasksTableSelectedData();
    this.TasksTableRowsSelectedCount.next(data?(Array.isArray(data)?data.length:1):0);
};

ConnectionsValidateDataViewController.prototype.PrepareTaskDataTab = function()
{
    var selectedTaskData = this.GetTasksTableSelectedData();

    if(!selectedTaskData)
        return null;

    this.SetTaskDataInfoLabel();

    var tabIdOld = this.Views.ConnectionsTabs.getValue();

    this.SetupTabs();

    var tabId = this.Views.ConnectionsTabs.getValue();

    if(tabIdOld != tabId)
    {
        this.Views.ConnectionsTabs.setValue(tabId);
        $$(tabId).show();
    }

    var dataType, table, sizeSelect;

    switch (tabId)
    {
        case this.Id + "ConnectionsMailTab":
            dataType = TaskDataModelType.email;
            table = this.Views.ConnectionsMailTabTable;
            sizeSelect = this.Views.ConnectionsMailTabTablePagingSize;
            break;
        case this.Id + "ConnectionsFtpTab":
            dataType = TaskDataModelType.ftp;
            table = this.Views.ConnectionsFtpTabTable;
            sizeSelect = this.Views.ConnectionsFtpTabTablePagingSize;
            break;
        case this.Id + "ConnectionsVoipTab":
            dataType = TaskDataModelType.voip;
            table = this.Views.ConnectionsVoipTabTable;
            sizeSelect = this.Views.ConnectionsVoipTabTablePagingSize;
            break;
        case this.Id + "ConnectionsHttpTab":
            dataType = TaskDataModelType.http;
            table = this.Views.ConnectionsHttpTabTable;
            sizeSelect = this.Views.ConnectionsHttpTabTablePagingSize;
            break;
        case this.Id + "ConnectionsImTab":
            dataType = TaskDataModelType.im;
            table = this.Views.ConnectionsImTabTable;
            sizeSelect = this.Views.ConnectionsImTabTablePagingSize;
            break;
        case this.Id + "ConnectionsRfTab":
            dataType = TaskDataModelType.rf;
            table = this.Views.ConnectionsRfTabTable;
            sizeSelect = this.Views.ConnectionsRfTabTablePagingSize;
            break;
        case this.Id + "ConnectionsTermTab":
            dataType = TaskDataModelType.term;
            table = this.Views.ConnectionsTermTabTable;
            sizeSelect = this.Views.ConnectionsTermTabTablePagingSize;
            break;
        case this.Id + "ConnectionsPstnTab":
            dataType = TaskDataModelType.pstn;
            table = this.Views.ConnectionsPstnTabTable;
            sizeSelect = this.Views.ConnectionsPstnTabTablePagingSize;
            break;
        case this.Id + "ConnectionsAaaTab":
            dataType = TaskDataModelType.aaa;
            table = this.Views.ConnectionsAaaTabTable;
            sizeSelect = this.Views.ConnectionsAaaTabTablePagingSize;
            break;
        default:
            return null;
            break;
    }

    return {dataType:dataType, table:table, state:table.getState(), size:sizeSelect, selectedTaskData:selectedTaskData};
};

ConnectionsValidateDataViewController.prototype.RefreshTaskData = function(Page)
{
    clearTimeout(this.TaskDataMonitorTimer);

    var resultObj = this.PrepareTaskDataTab();

    if(!resultObj)
        return;

    var dataType = resultObj.dataType;
    var selectedTaskData = resultObj.selectedTaskData;
    var table = resultObj.table;
    var state = resultObj.state;
    var size = resultObj.size ? resultObj.size.getValue() : null;

    if(dataType)
    {
        if(dataType == "email")
        {
            var view = this.Views.ConnectionsMailTabMessage;
            view.define("rows",[{template:""}]);
            view.reconstruct();
        }

        var state = table.getState();
        this.ViewModel.RefreshTaskData(selectedTaskData, {page:Page, data_type:dataType, filter:state.filter, size: size, sort:state.sort ? state.sort:null, callback:function ()
        {

        }});
    }
};

ConnectionsValidateDataViewController.prototype.ResetTaskDataSyncAttempts = function()
{
    var _this = this;

    clearTimeout(this.TaskDataMonitorTimer);

    var resultObj = this.PrepareTaskDataTab();

    if(!resultObj)
        return;

    var dataType = resultObj.dataType;
    var selectedTaskData = resultObj.selectedTaskData;

    if(dataType)
    {
        this.ViewModel.ResetTaskDataSyncAttempts(selectedTaskData, {data_type:dataType, callback:function ()
        {
            _this.RefreshTaskData(0);
        }});
    }
};

ConnectionsValidateDataViewController.prototype.UpdateTaskData = function (TaskId)
{
    var _this = this;

    var data = _this.ViewModel.GetTaskData(TaskId);

    var table, paging, sizeSelect;

    switch (data.data_type)
    {
        case TaskDataModelType.email:
            table = this.Views.ConnectionsMailTabTable;
            paging = this.Views.ConnectionsMailTabTablePaging;
            sizeSelect = this.Views.ConnectionsMailTabTablePagingSize;
            break;

        case TaskDataModelType.ftp:
            table = this.Views.ConnectionsFtpTabTable;
            paging = this.Views.ConnectionsFtpTabTablePaging;
            sizeSelect = this.Views.ConnectionsFtpTabTablePagingSize;
            break;

        case TaskDataModelType.voip:
            table = this.Views.ConnectionsVoipTabTable;
            paging = this.Views.ConnectionsVoipTabTablePaging;
            sizeSelect = this.Views.ConnectionsVoipTabTablePagingSize;
            break;

        case TaskDataModelType.http:
            table = this.Views.ConnectionsHttpTabTable;
            paging = this.Views.ConnectionsHttpTabTablePaging;
            sizeSelect = this.Views.ConnectionsHttpTabTablePagingSize;
            break;

        case TaskDataModelType.im:
            table = this.Views.ConnectionsImTabTable;
            paging = this.Views.ConnectionsImTabTablePaging;
            sizeSelect = this.Views.ConnectionsImTabTablePagingSize;
            break;

        case TaskDataModelType.rf:
            table = this.Views.ConnectionsRfTabTable;
            paging = this.Views.ConnectionsRfTabTablePaging;
            sizeSelect = this.Views.ConnectionsRfTabTablePagingSize;
            break;

        case TaskDataModelType.term:
            table = this.Views.ConnectionsTermTabTable;
            paging = this.Views.ConnectionsTermTabTablePaging;
            sizeSelect = this.Views.ConnectionsTermTabTablePagingSize;
            break;

        case TaskDataModelType.pstn:
            table = this.Views.ConnectionsPstnTabTable;
            paging = this.Views.ConnectionsPstnTabTablePaging;
            sizeSelect = this.Views.ConnectionsPstnTabTablePagingSize;
            break;

        case TaskDataModelType.aaa:
            table = this.Views.ConnectionsAaaTabTable;
            paging = this.Views.ConnectionsAaaTabTablePaging;
            sizeSelect = this.Views.ConnectionsAaaTabTablePagingSize;
            break;

        default:
            return;
    }

    table.clearAll(true);
    table.parse(data.data);

    paging.define(this.ViewModel.TaskDataFilter[TaskId].total);
    paging.refresh();

    if(sizeSelect)
    {
        sizeSelect.setValue(this.ViewModel.TaskDataFilter[TaskId].total.size);
    }

    if(data&&data.sync.sync >= TaskDataSync.in_progress)
    {
        this.TaskDataMonitorTimer = setTimeout(function ()
        {
            _this.RefreshTaskData();
        }, 5000);

        str = "<br/><br/><br/>";
        str += LangHelper.GetString(this.Id, "Data loading") + "...";

        if(data.sync.sync == TaskDataSync.error_max_attempts)
        {
            clearTimeout(this.TaskDataMonitorTimer);

            str += LangHelper.GetString(this.Id, "Error") + ".";
            str += "<br/><br/><br/>";

            //str += "<a href='#' class='try_again_link_btn'>" + LangHelper.GetString(this.Id, "Try again") + "</a>"
            //str += '<div data-view="button" data-width="150">Button</div>';
            str += '<div style="margin: 0 auto; width:160px;" id="'+this.Id+"DataTableTryAgain"+data.data_type+'"></div>';
        }


        str += "<br/><br/><br/>";

        str += LangHelper.GetString(this.Id, "Loaded") + ": " + data.sync.loaded + " " + LangHelper.GetString(this.Id, "of") + " " + data.sync.total + "<br/>";
        str += LangHelper.GetString(this.Id, "Number of attempts") + ": " + data.sync.attempts;

        table.showOverlay(str);

        if(data.sync.sync == TaskDataSync.error_max_attempts)
        {
            webix.ui({
                view:"button",
                label:LangHelper.GetString(this.Id, "Try again"),
                container:this.Id+"DataTableTryAgain"+data.data_type,
                click:function ()
                {
                    _this.ResetTaskDataSyncAttempts();
                }
            });
        }

        if(data.sync.sync < TaskDataSync.error_max_attempts)
            table.showProgress();
    }
    else
    {
        table.hideOverlay();
        table.hideProgress();
    }
};

ConnectionsValidateDataViewController.prototype.ExportTaskDataToExcel = function ()
{
    var resultObj = this.PrepareTaskDataTab();

    if(!resultObj)
        return;

    var tabId = resultObj.table.config.id;
    tabId = tabId.replace(this.Id, "");
    tabId = tabId.replace("Table", "");

    var tabTitle = this.TabbarOptionsDic[tabId].value;

    webix.toExcel(resultObj.table, {
        filename: LangHelper.GetString("AppView","Title") + " " + tabTitle,
        name: LangHelper.GetString("AppView","Title") + " " + tabTitle,
        filterHTML:true
    });
};

ConnectionsValidateDataViewController.prototype.ExportTaskDataToPdf = function ()
{
    var resultObj = this.PrepareTaskDataTab();

    if(!resultObj)
        return;

    var tabId = resultObj.table.config.id;
    tabId = tabId.replace(this.Id, "");
    tabId = tabId.replace("Table", "");

    var tabTitle = this.TabbarOptionsDic[tabId].value;

    webix.toPDF(resultObj.table, {
        filename: LangHelper.GetString("AppView","Title") + " " + tabTitle,
        orientation:"landscape",
        filterHTML:true
    });
};

ConnectionsValidateDataViewController.prototype.ClearTaskDataFilters = function ()
{
    var resultObj = this.PrepareTaskDataTab();

    if(!resultObj)
        return;

    var table = resultObj.table;

    table.eachColumn(function(id, col)
    {
        var filter = this.getFilter(id);

        if (filter)
        {
            if (filter.setValue) filter.setValue("");
            else  filter.value = "";
        }
    });
    table.filterByAll();
};

ConnectionsValidateDataViewController.prototype.SetCreateTaskContextFormTabbarCheckboxes = function ()
{
    var _this = this;

    var tabbar = this.Views.CreateTaskContextFormTabbar;

    Object.keys(this.Views.CreateTaskContextForms).every(function (Key)
    {
        var item = tabbar.getItem(Key);

        if(item)
        {
            var form = _this.Views.CreateTaskContextForms[Key];

            item.enabled = form.elements.enabled.getValue();

            tabbar.updateItem(Key,  item);
        }

        return true;
    });
};

ConnectionsValidateDataViewController.prototype.SetCreateTaskContextFormTabbarValid = function (Valid, ItemKey)
{
    var _this = this;

    var tabbar = this.Views.CreateTaskContextFormTabbar;

    var valid = Valid;

    Object.keys(this.Views.CreateTaskContextForms).every(function (Key)
    {
        if(ItemKey && ItemKey != Key)
            return true;

        var item = tabbar.getItem(Key);

        if(item)
        {
            item.valid = valid;

            tabbar.updateItem(Key,  item);

            tabbar.validate(Key);
        }

        return true;
    });
};

ConnectionsValidateDataViewController.prototype.ClearForms = function ()
{
    var _this = this;

    Object.keys(this.Views.CreateTaskContextForms).forEach(function (Key)
    {
        _this.Views.CreateTaskContextForms[Key].clear();
        _this.Views.CreateTaskContextForms[Key].clearValidation();
    });

    this.SetCreateTaskContextFormTabbarValid(1);
};

ConnectionsValidateDataViewController.prototype.SetTasksEditFormValues = function (FormData)
{
    if(FormData)
    {
        this.Views.CreateTaskContextForms.CreateTaskContextForm.setValues(new TaskFormToParamsModel(FormData));
        this.Views.CreateTaskContextForms.aaa.setValues(new TaskFormAaaModel(FormData.aaa, true, false));
        this.Views.CreateTaskContextForms.ftp.setValues(new TaskFormFtpModel(FormData.ftp, true, false));
        this.Views.CreateTaskContextForms.http.setValues(new TaskFormHttpModel(FormData.http, true, false));
        this.Views.CreateTaskContextForms.im.setValues(new TaskFormImModel(FormData.im, true, false));
        this.Views.CreateTaskContextForms.mail.setValues(new TaskFormMailModel(FormData.mail, true, false));
        this.Views.CreateTaskContextForms.pstn.setValues(new TaskFormPstnModel(FormData.pstn, true, false));
        this.Views.CreateTaskContextForms.raw_flows.setValues(new TaskFormRfModel(FormData.raw_flows, true, false));
        this.Views.CreateTaskContextForms.term.setValues(new TaskFormTermModel(FormData.term, true, false));
        this.Views.CreateTaskContextForms.voip.setValues(new TaskFormVoipModel(FormData.voip, true, false));
    }
};

ConnectionsValidateDataViewController.prototype.ShowTasksEditForm = function (Data, Node, Pos)
{
    this.ClearForms();

    if(Data && Data.form_data)
    {
        var fData = new TaskFormModel(JSON.parse(Data.form_data));
        this.SetTasksEditFormValues(fData);
    }

    this.SetCreateTaskContextFormTabbarCheckboxes();

    var selectedTab = this.Views.CreateTaskContextFormTabbar.getSelectedItem();
    if(!selectedTab)
    {
        this.Views.CreateTaskContextFormTabbar.select("aaa");
    }

    this.CreatTaskContext.show(Node, {pos:Pos});
};

ConnectionsValidateDataViewController.prototype.ValidateFormOneParameterChecked = function (Form)
{
    var els = Form.elements;

    var valid = false;

    Object.keys(els).every(function (Key)
    {
        if(Key.indexOf('_enabled') >= 0)
        {
            valid = els[Key].getValue();
        }

        return !valid;
    });

    return valid;
};

ConnectionsValidateDataViewController.prototype.ValidateForm = function (Key)
{
    var form = this.Views.CreateTaskContextForms[Key];

    var valid = form.validate();

    this.SetCreateTaskContextFormTabbarValid(valid, Key);

    return valid;
};

ConnectionsValidateDataViewController.prototype.ValidateForms = function ()
{
    var _this = this;

    var valid = true;

    Object.keys(this.Views.CreateTaskContextForms).every(function (Key)
    {
        valid = _this.ValidateForm(Key);

        return valid;
    });

    return valid;
};

ConnectionsValidateDataViewController.prototype.SaveTask = function ()
{
    var _this = this;

    var taskData = this.GetTasksTableSelectedData();

    if(this.ValidateForms())
    {
        taskData.valid = true;

        this.CreatTaskContext.hide();

        var formData = new TaskFormModel(new TaskParamsToFormModel(this.Views.CreateTaskContextForms.CreateTaskContextForm.getValues()));

        formData.aaa = new TaskFormAaaModel(this.Views.CreateTaskContextForms.aaa.getValues(), false, true);
        formData.ftp = new TaskFormFtpModel(this.Views.CreateTaskContextForms.ftp.getValues(), false, true);
        formData.http = new TaskFormHttpModel(this.Views.CreateTaskContextForms.http.getValues(), false, true);
        formData.im = new TaskFormImModel(this.Views.CreateTaskContextForms.im.getValues(), false, true);
        formData.mail = new TaskFormMailModel(this.Views.CreateTaskContextForms.mail.getValues(), false, true);
        formData.pstn = new TaskFormPstnModel(this.Views.CreateTaskContextForms.pstn.getValues(), false, true);
        formData.raw_flows = new TaskFormRfModel(this.Views.CreateTaskContextForms.raw_flows.getValues(), false, true);
        formData.term = new TaskFormTermModel(this.Views.CreateTaskContextForms.term.getValues(), false, true);
        formData.voip = new TaskFormVoipModel(this.Views.CreateTaskContextForms.voip.getValues(), false, true);

        this.ViewModel.SaveTask({task:taskData, task_form:formData, callback:function (R)
        {
            if(R.success && R.data && R.data.task)
            {

                //taskData.task_id = R.data.task_id;
                //taskData.title = R.data.title;

                _this.Views.TasksTable.updateItem(taskData.id,  new TaskModel(R.data.task));

                _this.MonitorTasksTable();

                //taskData.valid = true;
                //_this.Views.TasksTable.validate(taskData.id);

                webix.message(LangHelper.GetString(_this.Id, "Query saved"));

                var tasksInPerform = [];
                var failedTasks = [];

                if(R.data.tasks)
                {
                    Object.keys(R.data.tasks).forEach(function (Key)
                    {
                        if(R.data.tasks[Key] && R.data.tasks[Key].success)
                        {
                            tasksInPerform.push(TaskTypeTitleEnum[Key]?TaskTypeTitleEnum[Key]:Key);
                        }
                        else
                        {
                            failedTasks.push(TaskTypeTitleEnum[Key]?TaskTypeTitleEnum[Key]:Key);

                            if(R.data.tasks[Key]&&R.data.tasks[Key].error)
                                webix.message({text:R.data.tasks[Key].error.msg,type:"error"});
                        }
                    });
                }

                if(tasksInPerform.length)
                {
                    webix.message(LangHelper.GetString(_this.Id, "Search terms in processing: ") + tasksInPerform.join(", "));
                }

                if(failedTasks.length)
                {
                    webix.message({text:LangHelper.GetString(_this.Id, "We were unable to process your searches: ") + failedTasks.join(", "),type:"error"});
                }

                if(!tasksInPerform.length && !failedTasks.length)
                {
                    webix.message({text:LangHelper.GetString(_this.Id, "We were unable to process your searches: "), type:"debug"});
                }
            }
            else
            {
                taskData.valid = false;
                _this.Views.TasksTable.validate(taskData.id);

                if(R.error)
                    webix.message({text:R.error.msg,type:"error"});
            }

            _this.OnTasksTableRowSelect();
        }});

        this.ClearForms();
    }
    else
    {
        taskData.valid = false;
    }

    this.Views.TasksTable.validate(taskData.id);
};

ConnectionsValidateDataViewController.prototype.DeleteTask = function (RowId)
{
    var _this = this;

    var taskData = this.Views.TasksTable.getItem(RowId);
    taskData.valid = true;

    if(taskData.task_id > 0)
    {
        this.ViewModel.DeleteTask({task:taskData, callback:function (R)
        {
            if(R.success)
            {
                _this.Views.TasksTable.remove(RowId);

                webix.message(LangHelper.GetString(_this.Id, "Deleted"));
            }
            else
            {
                taskData.valid = false;
                _this.Views.TasksTable.validate(RowId);
                if(R.error)
                    webix.message({text:R.error.msg,type:"error"});
            }
        }});
    }
    else
    {
        this.Views.TasksTable.remove(RowId);
    }
};

ConnectionsValidateDataViewController.prototype.SetTaskDataInfoLabel = function()
{
    var taskData = this.GetTasksTableSelectedData();

    if(!taskData)
        return;

    var format =  webix.Date.dateToStr("%d.%m.%Y %H:%i");
    var date =  format(taskData.date);

    var report_count = 0;
    var maxStatus = 0;

    var status = "";

    taskData.sorm_data.forEach(function (Task)
    {
        report_count += parseInt(Task.report_count,10);

        var status = parseInt(Task.status,10);
        maxStatus = status > maxStatus ? status : maxStatus;
    });

    if(TaskStatusesDic[maxStatus])
        status = "<span class=\"status status_" + maxStatus + "\">" + TaskStatusesDic[maxStatus].value + "</span>";

    var title = taskData.title?taskData.title:LangHelper.GetString(this.Id, "Request") + " " + taskData.task_id;

    var text = title + " " + LangHelper.GetString(this.Id, "from") + " " + date + ". " + LangHelper.GetString(this.Id, "Status") + ": " + status + ". " + LangHelper.GetString(this.Id, "Total") + ": " + report_count;

    this.Views.TaskDataInfoLabel.setValue(text);
};

ConnectionsValidateDataViewController.prototype.SetupTabs = function()
{
    var _this = this;

    var taskData = this.GetTasksTableSelectedData();

    Object.keys(this.TabbarOptionsDic).forEach(function (Key)
    {
        _this.TabbarOptionsDic[Key].valueCount = 0;
    });

    taskData.sorm_data.forEach(function (Task)
    {
        switch (Task.task_type)
        {
            case TaskTypeEnum.email:
                _this.TabbarOptionsDic.ConnectionsMailTab.valueCount = Task.report_count.toString();
                break;
            case TaskTypeEnum.ftp:
                _this.TabbarOptionsDic.ConnectionsFtpTab.valueCount = Task.report_count.toString();
                break;
            case TaskTypeEnum.voip:
                _this.TabbarOptionsDic.ConnectionsVoipTab.valueCount = Task.report_count.toString();
                break;
            case TaskTypeEnum.http:
                _this.TabbarOptionsDic.ConnectionsHttpTab.valueCount = Task.report_count.toString();
                break;
            case TaskTypeEnum.im:
                _this.TabbarOptionsDic.ConnectionsImTab.valueCount = Task.report_count.toString();
                break;
            case TaskTypeEnum.rf:
                _this.TabbarOptionsDic.ConnectionsRfTab.valueCount = Task.report_count.toString();
                break;
            case TaskTypeEnum.term:
                _this.TabbarOptionsDic.ConnectionsTermTab.valueCount = Task.report_count.toString();
                break;
            case TaskTypeEnum.pstn:
                _this.TabbarOptionsDic.ConnectionsPstnTab.valueCount = Task.report_count.toString();
                break;
            case TaskTypeEnum.aaa:
                _this.TabbarOptionsDic.ConnectionsAaaTab.valueCount = Task.report_count.toString();
                break;
            default:
                return;
                break;
        }
    });

    Object.keys(this.TabbarOptionsDic).forEach(function (Key)
    {
        _this.TabbarOptionsDic[Key].value = (_this.TabbarOptionsDic[Key].valueTpl + " " + _this.TabbarOptionsDic[Key].valueCount).replace(/\s/g, "&nbsp;");
    });

    var tabbar = this.Views.ConnectionsTabs;

    tabbar.config.options = Helper.DictToArr(this.TabbarOptionsDic);

    tabbar.blockEvent();
    tabbar.config.options.forEach(function (Opt)
    {
        if(Opt.valueCount)
            tabbar.showOption(Opt.id);
        else
            tabbar.hideOption(Opt.id);
    });
    tabbar.unblockEvent();

    tabbar.render();
};

ConnectionsValidateDataViewController.prototype.SaveTableState = function(Table)
{
    var tableState = Table.getState();
    var config = Table.config;
    var tableId = Table.config.id;
    if(config.version)
        tableId += "_" + config.version;

    var tableColunsToSave = {
        hidden:tableState.hidden,
        ids:tableState.ids,
        order:tableState.order
    };

    webix.storage.local.put(tableId+"Columns", tableColunsToSave);
};

ConnectionsValidateDataViewController.prototype.RestoreTableState = function(Table)
{

    var tableState = Table.getState();
    var config = Table.config;
    var tableId = Table.config.id;
    if(config.version)
        tableId += "_" + config.version;

    var columnsRestoredState = webix.storage.local.get(tableId+"Columns");

    if(columnsRestoredState)
    {
        tableState.hidden = columnsRestoredState.hidden;
        tableState.ids = columnsRestoredState.ids;
        tableState.order = columnsRestoredState.order;

        Table.setState(tableState);
    }
};

ConnectionsValidateDataViewController.prototype.SaveAllTablesState = function()
{
    this.SaveTableState(this.Views.ConnectionsMailTabTable);
    this.SaveTableState(this.Views.ConnectionsFtpTabTable);
    this.SaveTableState(this.Views.ConnectionsHttpTabTable);
    this.SaveTableState(this.Views.ConnectionsImTabTable);
    this.SaveTableState(this.Views.ConnectionsPstnTabTable);
    this.SaveTableState(this.Views.ConnectionsRfTabTable);
    this.SaveTableState(this.Views.ConnectionsTermTabTable);
    this.SaveTableState(this.Views.ConnectionsVoipTabTable);
    this.SaveTableState(this.Views.ConnectionsAaaTabTable);
};

ConnectionsValidateDataViewController.prototype.RestoreAllTablesState = function()
{
    this.RestoreTableState(this.Views.ConnectionsMailTabTable);
    this.RestoreTableState(this.Views.ConnectionsFtpTabTable);
    this.RestoreTableState(this.Views.ConnectionsHttpTabTable);
    this.RestoreTableState(this.Views.ConnectionsImTabTable);
    this.RestoreTableState(this.Views.ConnectionsPstnTabTable);
    this.RestoreTableState(this.Views.ConnectionsRfTabTable);
    this.RestoreTableState(this.Views.ConnectionsTermTabTable);
    this.RestoreTableState(this.Views.ConnectionsVoipTabTable);
    this.RestoreTableState(this.Views.ConnectionsAaaTabTable);
};

ConnectionsValidateDataViewController.prototype.AddTaskToTasksTable = function()
{
    var _this = this;

    var model = new TaskModel();

    _this.Views.TasksTable.add(model,0);

    _this.Views.TasksTable.select(model.id, false);
    _this.Views.TasksTable.showItem(model.id);

    _this.ShowTasksEditForm(model, _this.Views.TasksTable.getItemNode(model.id), "right");
};

ConnectionsValidateDataViewController.prototype.AddSubTaskToTasksTable = function()
{
    var _this = this;

    var selectedModel = _this.GetTasksTableSelectedData();

    if(!selectedModel || !selectedModel.task_id)
        return;

    var model = new TaskModel({parent_id:selectedModel.task_id});

    _this.Views.TasksTable.add(model,0,selectedModel.id);
    _this.Views.TasksTable.open(selectedModel.id);

    _this.Views.TasksTable.select(model.id, false);
    _this.Views.TasksTable.showItem(model.id);

    _this.ShowTasksEditForm(model, _this.Views.TasksTable.getItemNode(model.id), "right");
};

ConnectionsValidateDataViewController.prototype.GetTaskDataPagingConfig = function(TableId)
{
    var _this = this;

    return {
        view: "toolbar",
        padding: 0,
        margin: 0,
        height:32,
        elements: [
            {
                view: "pager",
                gravity:3,
                id: this.Id + TableId + "Paging",
                template: "{common.first()} {common.prev()} {common.pages()} {common.next()} {common.last()}",
                master:false,
                on: {
                    onAfterPageChange:function(Page)
                    {
                        _this.RefreshTaskData(Page);
                    }
                }
            },
            {
                view:"richselect",
                id: this.Id + TableId + "Paging" + "Size",
                label:LangHelper.GetString(this.Id, "On the page"),
                labelWidth:120,
                minWidth:220,
                value:100,
                options:this.PagingSizeOptions,
                on: {
                    onChange:function()
                    {
                        _this.RefreshTaskData();
                    }
                }
            }
        ]
    };
};

ConnectionsValidateDataViewController.prototype.CheckHealth = function()
{
    this.Health.Refresh({asn_server: 1});
};

ConnectionsValidateDataViewController.prototype.HealthResult = function()
{
    var health = this.Health.GetHealth();

    if(health.asn_server)
    {
        if(!health.asn_server.success)
            webix.message({text:LangHelper.GetString("AppView","Connections") + "<br/><br/>" + health.asn_server.error.msg + "<br/><br/>" + LangHelper.GetString("Health","Verify services health"), type:"error", expire: 300000});
    }
};
function TrafficManagementGeneratorViewController(Params)
{
    var _this = this;

    this.Id = "TrafficManagementGenerator";

    this.ViewModel = new TrafficGeneratorManager();

    this.Health = new SormHealthManager();

    this.ProfileDeleteContext = webix.ui({
        view:"popup",
        width:300,
        height:180,
        body:{
            view:"form",
            borderless:true,
            elements:[
                {
                    template:LangHelper.GetString("All","Are you sure you want to delete the profile?")
                },
                {
                    cols:[
                        {},
                        {
                            view:"button",
                            label:LangHelper.GetString(_this.Id,"Cancel"),
                            click:function () {
                                _this.ProfileDeleteContext.hide();
                            }
                        },
                        {
                            view:"button",
                            type:"form",
                            label:LangHelper.GetString(_this.Id,"Delete"),
                            click:function () {
                                _this.ProfileDeleteContext.hide();

                                var selected = _this.GetProfilesTableSelectedData();

                                if(selected)
                                    _this.DeleteProfile(selected.id);
                            }
                        }
                    ]
                }
            ]
        }
    });

    this.SaveContextId = this.Id + "SaveContext";
    this.SaveContext = webix.ui({
        view:"submenu",
        id:this.SaveContextId,
        width:320,
        autowidth: true,
        padding: 0,
        data: [
            {id: this.SaveContextId+"Validate", icon: "check-square", value: LangHelper.GetString(_this.Id,"Check")},
            {id: this.SaveContextId+"ValidateAndSave", icon: "floppy-o", value: LangHelper.GetString(_this.Id,"Check and save")},
            {id: this.SaveContextId+"JustSave", icon: "floppy-o", value: LangHelper.GetString(_this.Id,"Save without checking")}
        ],
        type: {
            template: function (obj) {
                if (obj.type) {
                    return "<div class='separator'></div>";
                }

                return "<span class='webix_icon alerts fa-" + obj.icon + "'></span><span>" + obj.value + "</span>";
            }
        },
        on: {
            onMenuItemClick: function (id)
            {
                this.hide();
                if(id === _this.SaveContextId+"Validate")
                {
                    _this.SaveProfile(false, true);
                }
                else if(id === _this.SaveContextId+"ValidateAndSave")
                {
                    _this.SaveProfile(true, true);
                }
                else if(id === _this.SaveContextId+"JustSave")
                {
                    _this.SaveProfile(true, false);
                }
            }
        }
    });

    this.ViewConfig = [
        {
            cols:[
                SormSideBarView({id: this.Id}),
                {
                    type: "space",
                    rows: [
                        {
                            type: "clear",
                            borderless: true,
                            rows: [
                                PageHeaderTemplate({root_title: LangHelper.GetString("AppView","Lawful interception"), title: LangHelper.GetString("AppView","Traffic generation")}),
                                {
                                    view:"accordion",
                                    multi:"mixed",
                                    cols:[
                                        {
                                            header:LangHelper.GetString(_this.Id,"Profiles"),
                                            body:{
                                                rows: [
                                                    {
                                                        view: "toolbar",
                                                        height: 32,
                                                        padding: 0,
                                                        margin: 0,
                                                        elements: [
                                                            {
                                                                view: "button",
                                                                id:this.Id + "AddProfileBtn",
                                                                type: "icon",
                                                                icon: "plus",
                                                                align: "right",
                                                                width: 32,
                                                                css:"toolbar_round_btn",
                                                                tooltip:LangHelper.GetString(_this.Id,"Add profile"),
                                                                click: function (id, e, node)
                                                                {
                                                                    _this.AddProfileToProfilesTable();
                                                                }
                                                            },
                                                            {
                                                                view: "button",
                                                                id:this.Id + "AddSubProfileBtn",
                                                                type: "icon",
                                                                icon: "plus-square",
                                                                align: "right",
                                                                width: 32,
                                                                css:"toolbar_round_btn",
                                                                tooltip:LangHelper.GetString(_this.Id,"Add to current profile"),
                                                                click: function (id, e, node)
                                                                {
                                                                    _this.AddSubProfileToProfilesTable();
                                                                }
                                                            },
                                                            {},
                                                            {
                                                                view: "button",
                                                                id:this.Id+"ProfilesRefreshBtn",
                                                                type: "icon",
                                                                icon: "refresh",
                                                                css: "toolbar_round_btn",
                                                                width: 32,
                                                                tooltip: LangHelper.GetString(_this.Id,"Refresh"),
                                                                click: function (id, e, node)
                                                                {
                                                                    _this.RefreshProfilesTable(0);
                                                                }
                                                            }
                                                        ]
                                                    },
                                                    BlockHeaderTemplate({title:LangHelper.GetString(_this.Id,"Profiles"), icon:"file"}),
                                                    {
                                                        view: "treetable",
                                                        id: this.Id + "ProfilesTable",
                                                        select: "row",
                                                        resizeColumn: true,
                                                        tooltip:true,
                                                        on: {
                                                            onItemClick: function (id,e,node)
                                                            {
                                                                if(id.column === 'run')
                                                                {
                                                                    var data = _this.Views.ProfilesTable.getItem(id);

                                                                    if(!data.tt_id)
                                                                    {
                                                                        return;
                                                                    }

                                                                    if(data.task_status == TragenTaskStatusEnum.progress)
                                                                    {
                                                                        setTimeout(function ()
                                                                        {
                                                                            _this.StopProfile();
                                                                        }, 10);
                                                                    }
                                                                    else
                                                                    {
                                                                        setTimeout(function ()
                                                                        {
                                                                            _this.RunProfile();
                                                                        }, 10);
                                                                    }
                                                                }

                                                                if(id.column === 'trash')
                                                                {
                                                                    var __this = this;

                                                                    var data = _this.Views.ProfilesTable.getItem(id);

                                                                    if(!data.tt_id)
                                                                    {
                                                                        _this.DeleteProfile(id);
                                                                        return;
                                                                    }

                                                                    setTimeout(function ()
                                                                    {
                                                                        var node = __this.getItemNode(id);
                                                                        _this.ProfileDeleteContext.show(node, {pos:"bottom"});
                                                                    }, 10);
                                                                }
                                                            },
                                                            onBeforeFilter:function()
                                                            {
                                                                return false;
                                                            },
                                                            onAfterFilter:function()
                                                            {
                                                                _this.RefreshProfilesTable()
                                                            },
                                                            onBeforeSort:function()
                                                            {
                                                                _this.RefreshProfilesTable()
                                                                return false;
                                                            },
                                                            onAfterSelect:function ()
                                                            {
                                                                _this.OnProfilesTableRowSelect();
                                                            },
                                                            onAfterUnSelect:function ()
                                                            {
                                                                _this.OnProfilesTableRowUnSelect();
                                                            }
                                                        },
                                                        columns: [
                                                            {
                                                                id: "run",
                                                                header: "",
                                                                width: 32,
                                                                //template: CellTrashBtnTemplate,
                                                                template: function (obj, common)
                                                                {
                                                                    if(obj.task_status == TragenTaskStatusEnum.progress && obj.tt_id)
                                                                    {
                                                                        return CellStopBtnTemplate();
                                                                    }

                                                                    if(obj.tt_id)
                                                                    {
                                                                        return CellPlayBtnTemplate();
                                                                    }

                                                                    return "";
                                                                },
                                                                headermenu: false
                                                            },
                                                            {
                                                                id: "title",
                                                                header: [LangHelper.GetString(_this.Id,"Profile"), {content: "textFilter"}],
                                                                template:function(obj, common, value, config)
                                                                {
                                                                    return common.treetable(obj, common, value, config) + (obj.title?obj.title:LangHelper.GetString(_this.Id,"Profile") + " "+obj.tt_id);
                                                                },
                                                                sort: "string",
                                                                fillspace: true,
                                                                minWidth:150
                                                            },
                                                            {
                                                                id: "date",
                                                                header: [LangHelper.GetString(_this.Id,"Date"), {content: "dateRangeFilter"}],
                                                                template:function(obj, common){
                                                                    if(obj.date)
                                                                    {
                                                                        var format =  webix.Date.dateToStr("%d.%m.%Y %H:%i");
                                                                        return format(obj.date);
                                                                    }

                                                                    return "";
                                                                },
                                                                fillspace: true,
                                                                sort: "date"
                                                            },
                                                            {
                                                                id: "user_id",
                                                                header: [LangHelper.GetString(_this.Id,"User"), {
                                                                    content: "multiSelectFilter",
                                                                    options: App.DicsManager.GetDicAsArray(DicsEnum.UsersDic),
                                                                    inputConfig: {suggest: {fitMaster: false}}
                                                                }],
                                                                fillspace: true,
                                                                template: function (obj)
                                                                {
                                                                    if(App.DicsManager.GetDic("UsersDic")[obj.user_id])
                                                                        return App.DicsManager.GetDic("UsersDic")[obj.user_id].value;
                                                                    else
                                                                        return "";
                                                                },
                                                                sort: "string"
                                                            },
                                                            {
                                                                id: "task_status",
                                                                header: [LangHelper.GetString(_this.Id,"Status"), {
                                                                    content: "richSelectFilter",
                                                                    options: Helper.DictToArr(TragenTaskStatusesDic),
                                                                    inputConfig: {suggest: {fitMaster: false}}
                                                                }],
                                                                template: function (obj, common)
                                                                {
                                                                    var statusStr = "";

                                                                    if(TragenTaskStatusesDic[obj.task_status])
                                                                    {
                                                                        if(obj.task_status == TragenTaskStatusEnum.progress && obj.tt_id && obj.tragen_data && obj.tragen_data.length)
                                                                        {
                                                                            statusStr += '<div class="spinner green"><div class="bounce1"></div><div class="bounce2"></div> <div class="bounce3"></div> </div>';
                                                                        }

                                                                        statusStr += "<span class=\"status status_" + obj.task_status + "\">" + TragenTaskStatusesDic[obj.task_status].value + "</span>";
                                                                    }

                                                                    return statusStr;
                                                                },
                                                                fillspace: true,
                                                                sort: "string"
                                                            },
                                                            {
                                                                id: "trash",
                                                                header: "",
                                                                width: 32,
                                                                template: CellTrashBtnTemplate,
                                                                headermenu: false
                                                            }
                                                        ],
                                                        data:[]
                                                    },
                                                    {
                                                        view: "toolbar",
                                                        padding: 0,
                                                        margin: 0,
                                                        height: 32,
                                                        elements: [
                                                            {
                                                                view: "pager",
                                                                id: this.Id + "ProfilesTable" + "Paging",
                                                                template: "{common.first()} {common.prev()} {common.pages()} {common.next()} {common.last()}",
                                                                master:false,
                                                                on: {
                                                                    onAfterPageChange:function(Page)
                                                                    {
                                                                        _this.RefreshProfilesTable(Page);
                                                                    }
                                                                }
                                                            },
                                                            {
                                                                view:"richselect",
                                                                id: this.Id + "ProfilesTable" + "Paging" + "Size",
                                                                label:LangHelper.GetString(_this.Id,"On page"),
                                                                labelWidth:120,
                                                                minWidth:220,
                                                                value:100,
                                                                options:PagingSizeOptions,
                                                                on: {
                                                                    onChange:function()
                                                                    {
                                                                        _this.RefreshProfilesTable();
                                                                    }
                                                                }
                                                            }
                                                        ]
                                                    }

                                                ]
                                            }
                                        },
                                        {
                                            view:"resizer"
                                        },
                                        {
                                            header:LangHelper.GetString(_this.Id,"Profile settings"),
                                            gravity:2,
                                            body:{
                                                id:this.Id + "ProfileEditPanel",
                                                rows:[
                                                    {
                                                        view: "toolbar",
                                                        height: 32,
                                                        padding: 0,
                                                        margin: 0,
                                                        elements: [
                                                            {
                                                                view: "button",
                                                                id:this.Id + "ProfileSaveBtn",
                                                                type: "icon",
                                                                icon: "floppy-o",
                                                                width: 32,
                                                                css:"toolbar_round_btn",
                                                                tooltip:LangHelper.GetString(_this.Id,"Save"),
                                                                click: function (id, e, node)
                                                                {
                                                                    var __this = this;

                                                                    setTimeout(function () {
                                                                        var node = __this.getNode(id);
                                                                        _this.SaveContext.show(node, {pos: "bottom"});
                                                                    }, 1);
                                                                }
                                                            },
                                                            {
                                                                width:16
                                                            },
                                                            {
                                                                view: "button",
                                                                id:this.Id + "PlayBtn",
                                                                type: "icon",
                                                                icon: "play-circle",
                                                                width: 32,
                                                                css:"toolbar_round_btn",
                                                                tooltip:LangHelper.GetString(_this.Id,"Start generation"),
                                                                click: function (id, e, node)
                                                                {
                                                                    _this.RunProfile();
                                                                }
                                                            },
                                                            {
                                                                view: "button",
                                                                id:this.Id + "StopBtn",
                                                                type: "icon",
                                                                icon: "stop-circle",
                                                                width: 32,
                                                                css:"toolbar_round_btn",
                                                                tooltip:LangHelper.GetString(_this.Id,"Stop generation"),
                                                                click: function (id, e, node)
                                                                {
                                                                    _this.StopProfile();
                                                                }
                                                            }
                                                        ]
                                                    },
                                                    BlockHeaderTemplate({title:LangHelper.GetString(_this.Id,"General profile settings"), icon:"file"}),
                                                    {
                                                        rows:[
                                                            {
                                                                id:this.Id + "ProfileCommonForm",
                                                                view: "form",
                                                                margin: 0,
                                                                padding: 8,
                                                                elements:[
                                                                    {
                                                                        cols:[
                                                                            {
                                                                                view: "label",
                                                                                label: LangHelper.GetString(_this.Id,"Name"),
                                                                                width:150
                                                                            },
                                                                            {
                                                                                view: "text",
                                                                                name: "title",
                                                                                placeholder:LangHelper.GetString(_this.Id,"Profile name"),
                                                                                tooltip:LangHelper.GetString(_this.Id,"Profile name")
                                                                            }
                                                                        ]
                                                                    },
                                                                    {
                                                                        cols:[
                                                                            {
                                                                                view: "label",
                                                                                label: LangHelper.GetString(_this.Id,"Source"),
                                                                                width:150,
                                                                                required:true,
                                                                                css:"webix_required"
                                                                            },
                                                                            {
                                                                                view: "text",
                                                                                name: "src_ip",
                                                                                label: LangHelper.GetString(_this.Id,"IP address, range, CIDR"),
                                                                                labelPosition: "top",
                                                                                placeholder:"192.168.1.1/16",
                                                                                tooltip:LangHelper.GetString(_this.Id,"IP address, range, CIDR, for example, 192.168.1.1/16"),
                                                                                validate: function(Val){
                                                                                    return (Val ? (Helper.ValidateIPaddressOrCidr(Val) || Helper.IsIpRange(Val)) : false)
                                                                                },
                                                                                invalidMessage: LangHelper.GetString(_this.Id,"IP address, range, CIDR"),
                                                                                gravity:2,
                                                                                required:true
                                                                            },
                                                                            {
                                                                                view: "text",
                                                                                name: "src_port",
                                                                                label: LangHelper.GetString(_this.Id,"IP port, range"),
                                                                                labelPosition: "top",
                                                                                placeholder:"2000-65000",
                                                                                tooltip:LangHelper.GetString(_this.Id,"IP port, range, for example 2000-65000"),
                                                                                validate: function(Val){return Val ? Helper.ValidatePortRange(Val) : false},
                                                                                invalidMessage: LangHelper.GetString(_this.Id,"IP port, range, for example 2000-65000"),
                                                                                required:true
                                                                            }
                                                                        ]
                                                                    },
                                                                    {
                                                                        cols:[
                                                                            {
                                                                                view: "label",
                                                                                label: LangHelper.GetString(_this.Id,"Receiver"),
                                                                                width:150,
                                                                                required:true,
                                                                                css:"webix_required"
                                                                            },
                                                                            {
                                                                                view: "text",
                                                                                name: "dst_ip",
                                                                                label: LangHelper.GetString(_this.Id,"IP address, range, CIDR"),
                                                                                labelPosition: "top",
                                                                                placeholder:"192.168.1.1/16",
                                                                                tooltip:LangHelper.GetString(_this.Id,"IP address, range, CIDR, for example, 192.168.1.1/16"),
                                                                                validate: function(Val){
                                                                                    return (Val ? (Helper.ValidateIPaddressOrCidr(Val) || Helper.IsIpRange(Val)) : false)
                                                                                },
                                                                                invalidMessage: LangHelper.GetString(_this.Id,"IP address, range, CIDR"),
                                                                                gravity:2,
                                                                                required:true
                                                                            },
                                                                            {
                                                                                view: "text",
                                                                                name: "dst_port",
                                                                                label: LangHelper.GetString(_this.Id,"IP port, range"),
                                                                                labelPosition: "top",
                                                                                placeholder:"2000-65000",
                                                                                tooltip:LangHelper.GetString(_this.Id,"IP port, range, for example 2000-65000"),
                                                                                validate: function(Val){return Val ? Helper.ValidatePortRange(Val) : false},
                                                                                invalidMessage: LangHelper.GetString(_this.Id,"IP port, range, for example 2000-65000"),
                                                                                required:true
                                                                            }
                                                                        ]
                                                                    },
                                                                    {
                                                                        cols:[
                                                                            {
                                                                                view: "label",
                                                                                label: LangHelper.GetString(_this.Id,"PCI devices"),
                                                                                tooltip:LangHelper.GetString(_this.Id,"PCI device (network port) that uses a generator to receive / send generated traffic. You can specify several"),
                                                                                width:150,
                                                                                required:true,
                                                                                css:"webix_required"
                                                                            },
                                                                            {
                                                                                view:"multiselect",
                                                                                name:"network_port_arr",
                                                                                tooltip:LangHelper.GetString(_this.Id,"PCI device (network port) that uses a generator to receive / send generated traffic. You can specify several"),
                                                                                options:App.DicsManager.GetDicAsArray(DicsEnum.CardsDic),
                                                                                validate: function(Val){return Val && Val.length;},
                                                                                invalidMessage: LangHelper.GetString(_this.Id,"Required parameter"),
                                                                                required:true
                                                                            }
                                                                        ]
                                                                    },
                                                                    {
                                                                        cols:[
                                                                            {
                                                                                view: "label",
                                                                                label: LangHelper.GetString(_this.Id,"Run time"),
                                                                                tooltip:LangHelper.GetString(_this.Id,"The generator's running time in seconds is accurate to a tenth of a second, for example 1.5 - one and a half seconds. Unless indicated, the generator will work endlessly"),
                                                                                width:150
                                                                            },
                                                                            {
                                                                                view: "text",
                                                                                name: "time",
                                                                                placeholder:LangHelper.GetString(_this.Id,"for example 1.5"),
                                                                                tooltip:LangHelper.GetString(_this.Id,"The generator's running time in seconds is accurate to a tenth of a second, for example 1.5 - one and a half seconds. Unless indicated, the generator will work endlessly"),
                                                                                validate: function(Val){return Val ? !isNaN(Val) : true;},
                                                                                invalidMessage: LangHelper.GetString(_this.Id,"Number, optional")
                                                                            },
                                                                            {
                                                                                width:16
                                                                            },
                                                                            {
                                                                                view: "label",
                                                                                label: LangHelper.GetString(_this.Id,"Cores"),
                                                                                tooltip:LangHelper.GetString(_this.Id,"The list of cores that the generator uses"),
                                                                                required:true,
                                                                                css:"webix_required"
                                                                            },
                                                                            {
                                                                                view: "text",
                                                                                name: "cores",
                                                                                placeholder:LangHelper.GetString(_this.Id,"for example 0-2"),
                                                                                tooltip:LangHelper.GetString(_this.Id,"The list of cores that the generator uses"),
                                                                                validate: function(Val){
                                                                                    var max = 16;

                                                                                    var dic = App.DicsManager.GetDic(DicsEnum.CpuDic);
                                                                                    if(dic && dic["CPU(s)"])
                                                                                        max = dic["CPU(s)"].value;

                                                                                    return Val ? Helper.ValidatePortRange(Val, max) : false
                                                                                },
                                                                                invalidMessage: LangHelper.GetString(_this.Id,"Range, for example 0-2")
                                                                            },
                                                                            {
                                                                                width:16
                                                                            },
                                                                            {
                                                                                view: "label",
                                                                                label: LangHelper.GetString(_this.Id,"Memory"),
                                                                                tooltip:LangHelper.GetString(_this.Id,"The memory size allocated for each socket (processor), in megabytes"),
                                                                                required:true,
                                                                                css:"webix_required"
                                                                            },
                                                                            {
                                                                                view: "text",
                                                                                name: "socket_mem",
                                                                                placeholder:"4096",
                                                                                tooltip:LangHelper.GetString(_this.Id,"The memory size allocated for each socket (processor), in megabytes"),
                                                                                validate: function(Val){return Val && !isNaN(Val);},
                                                                                invalidMessage: LangHelper.GetString(_this.Id,"Number")
                                                                            }
                                                                        ]
                                                                    }
                                                                ],
                                                                on:{
                                                                    onChange:function(NVal, OVal)
                                                                    {
                                                                        _this.ApplySubProfileFormToSelectedSubProfile();
                                                                    }
                                                                }
                                                            },
                                                            {
                                                                cols:[
                                                                    {
                                                                        gravity:2,
                                                                        rows: [
                                                                            {
                                                                                view: "toolbar",
                                                                                height: 32,
                                                                                padding: 0,
                                                                                margin: 0,
                                                                                elements: [
                                                                                    {
                                                                                        view: "button",
                                                                                        id:this.Id + "AddGeneratorBtn",
                                                                                        type: "icon",
                                                                                        icon: "plus",
                                                                                        align: "right",
                                                                                        width: 32,
                                                                                        css:"toolbar_round_btn",
                                                                                        tooltip:LangHelper.GetString(_this.Id,"Add a generator"),
                                                                                        click: function (id, e, node)
                                                                                        {
                                                                                            _this.AddSubProfileToSubProfilesTable();
                                                                                        }
                                                                                    }
                                                                                ]
                                                                            },
                                                                            BlockHeaderTemplate({title:LangHelper.GetString(_this.Id,"Generators"), icon:"file"}),
                                                                            {
                                                                                view: "treetable",
                                                                                id: this.Id + "SubProfilesTable",
                                                                                select: "row",
                                                                                editable: true,
                                                                                editaction:"custom",
                                                                                resizeColumn: true,
                                                                                tooltip:true,
                                                                                columns: [
                                                                                    {
                                                                                        id: "title",
                                                                                        header: LangHelper.GetString(_this.Id,"Generator"),
                                                                                        template:function(obj, common, value, config)
                                                                                        {
                                                                                            return common.treetable(obj, common, value, config) + (obj.title?obj.title:LangHelper.GetString(_this.Id,"Generator")+" "+obj.tt_id);
                                                                                        },
                                                                                        fillspace: true,
                                                                                        sort: "string"
                                                                                    },
                                                                                    {
                                                                                        id: "gen_type",
                                                                                        header: LangHelper.GetString(_this.Id,"Type"),
                                                                                        template:function(obj, common, value, config)
                                                                                        {
                                                                                            var str = obj.gen_type;

                                                                                            if(TragenTypesDic[obj.gen_type])
                                                                                            {
                                                                                                str = TragenTypesDic[obj.gen_type].value;
                                                                                            }

                                                                                            return str;
                                                                                        },
                                                                                        fillspace: false,
                                                                                        sort: "string"
                                                                                    },
                                                                                    {
                                                                                        id: "trash",
                                                                                        header: "",
                                                                                        width: 32,
                                                                                        template: CellTrashBtnTemplate,
                                                                                        headermenu: false
                                                                                    }
                                                                                ],
                                                                                data:[],
                                                                                on: {
                                                                                    onItemClick: function (id, e, node) {

                                                                                        if (id.column === 'trash')
                                                                                        {
                                                                                            _this.DeleteSubProfile(id);
                                                                                        }
                                                                                    },
                                                                                    onAfterSelect:function ()
                                                                                    {
                                                                                        _this.OnSubProfilesTableRowSelect();
                                                                                    },
                                                                                    onAfterUnSelect:function ()
                                                                                    {
                                                                                        _this.OnSubProfilesTableRowUnSelect();
                                                                                    }
                                                                                }
                                                                            }
                                                                        ]
                                                                    },
                                                                    {
                                                                        id:this.Id + "ProfileGeneratorEditPanel",
                                                                        gravity:3,
                                                                        scroll:"y",
                                                                        rows:[
                                                                            BlockHeaderTemplate({title:LangHelper.GetString(_this.Id,"Generator settings"), icon:"file"}),
                                                                            {
                                                                                view:"scrollview",
                                                                                scroll:"y",
                                                                                body:{
                                                                                    rows:[
                                                                                        {
                                                                                            id:this.Id + "ProfileGeneratorForm",
                                                                                            view: "form",
                                                                                            margin: 0,
                                                                                            padding: 0,
                                                                                            elements:[
                                                                                                {
                                                                                                    view: "fieldset",
                                                                                                    label: LangHelper.GetString(_this.Id,"Common"),
                                                                                                    body: {
                                                                                                        rows:[
                                                                                                            {
                                                                                                                cols:[
                                                                                                                    {
                                                                                                                        view: "label",
                                                                                                                        label: LangHelper.GetString(_this.Id,"Name"),
                                                                                                                        width:100
                                                                                                                    },
                                                                                                                    {
                                                                                                                        view:"text",
                                                                                                                        name:"title",
                                                                                                                        placeholder:LangHelper.GetString(_this.Id,"Generator name"),
                                                                                                                        tooltip:LangHelper.GetString(_this.Id,"Generator name")
                                                                                                                    }
                                                                                                                ]
                                                                                                            },
                                                                                                            {
                                                                                                                cols:[
                                                                                                                    {
                                                                                                                        view: "label",
                                                                                                                        label: LangHelper.GetString(_this.Id,"Type"),
                                                                                                                        width:100,
                                                                                                                        required:true,
                                                                                                                        css:"webix_required"
                                                                                                                    },
                                                                                                                    {
                                                                                                                        view:"richselect",
                                                                                                                        name:"gen_type",
                                                                                                                        placeholder:LangHelper.GetString(_this.Id,"Generator type"),
                                                                                                                        tooltip:LangHelper.GetString(_this.Id,"Generator type"),
                                                                                                                        options:Helper.DictToArr(TragenTypesDic)
                                                                                                                    }
                                                                                                                ]
                                                                                                            }
                                                                                                        ]
                                                                                                    }
                                                                                                },
                                                                                                {
                                                                                                    id:this.Id + "ProfileGeneratorFormCommonFieldset",
                                                                                                    view: "fieldset",
                                                                                                    label: LangHelper.GetString(_this.Id,"Required"),
                                                                                                    body: {
                                                                                                        rows:[
                                                                                                            {
                                                                                                                cols:[
                                                                                                                    {
                                                                                                                        view: "checkbox",
                                                                                                                        name: "src_enabled",
                                                                                                                        labelRight: LangHelper.GetString(_this.Id,"Source"),
                                                                                                                        tooltip: LangHelper.GetString(_this.Id,"If the flag is not set, the general profile settings are used"),
                                                                                                                        value:0,
                                                                                                                        labelWidth:0,
                                                                                                                        width:150,
                                                                                                                        required:true,
                                                                                                                        css:"webix_required"
                                                                                                                    },
                                                                                                                    {
                                                                                                                        view: "text",
                                                                                                                        name: "src_ip",
                                                                                                                        label: LangHelper.GetString(_this.Id,"IP address, range, CIDR"),
                                                                                                                        labelPosition: "top",
                                                                                                                        placeholder:"192.168.1.1/16",
                                                                                                                        tooltip:LangHelper.GetString(_this.Id,"IP address, range, CIDR, for example, 192.168.1.1/16"),
                                                                                                                        validate: function(Val){
                                                                                                                            return (Val ? (Helper.ValidateIPaddressOrCidr(Val) || Helper.Helper.IsIpRange(Val)) : false)
                                                                                                                        },
                                                                                                                        invalidMessage: LangHelper.GetString(_this.Id,"IP address, range, CIDR"),
                                                                                                                        gravity:2,
                                                                                                                        required:true
                                                                                                                    },
                                                                                                                    {
                                                                                                                        view: "text",
                                                                                                                        name: "src_port",
                                                                                                                        label: LangHelper.GetString(_this.Id,"IP port, range"),
                                                                                                                        labelPosition: "top",
                                                                                                                        placeholder:"2000-65000",
                                                                                                                        tooltip:LangHelper.GetString(_this.Id,"IP port, range, for example 2000-65000"),
                                                                                                                        validate: function(Val){return Val ? Helper.ValidatePortRange(Val) : false},
                                                                                                                        invalidMessage: LangHelper.GetString(_this.Id,"IP port, range, for example 2000-65000"),
                                                                                                                        required:true
                                                                                                                    }
                                                                                                                ]
                                                                                                            },
                                                                                                            {
                                                                                                                cols:[
                                                                                                                    {
                                                                                                                        view: "checkbox",
                                                                                                                        name: "dst_enabled",
                                                                                                                        labelRight: LangHelper.GetString(_this.Id,"Receiver"),
                                                                                                                        tooltip: LangHelper.GetString(_this.Id,"If the flag is not set, the general profile settings are used"),
                                                                                                                        value:0,
                                                                                                                        labelWidth:0,
                                                                                                                        width:150,
                                                                                                                        required:true,
                                                                                                                        css:"webix_required"
                                                                                                                    },
                                                                                                                    {
                                                                                                                        view: "text",
                                                                                                                        name: "dst_ip",
                                                                                                                        label: LangHelper.GetString(_this.Id,"IP address, range, CIDR"),
                                                                                                                        labelPosition: "top",
                                                                                                                        placeholder:"192.168.1.1/16",
                                                                                                                        tooltip:LangHelper.GetString(_this.Id,"IP address, range, CIDR, for example, 192.168.1.1/16"),
                                                                                                                        validate: function(Val){
                                                                                                                            return (Val ? (Helper.ValidateIPaddressOrCidr(Val) || Helper.IsIpRange(Val)) : false)
                                                                                                                        },
                                                                                                                        invalidMessage: LangHelper.GetString(_this.Id,"IP address, range, CIDR"),
                                                                                                                        gravity:2,
                                                                                                                        required:true
                                                                                                                    },
                                                                                                                    {
                                                                                                                        view: "text",
                                                                                                                        name: "dst_port",
                                                                                                                        label: LangHelper.GetString(_this.Id,"IP port, range"),
                                                                                                                        labelPosition: "top",
                                                                                                                        placeholder:"2000-65000",
                                                                                                                        tooltip:LangHelper.GetString(_this.Id,"IP port, range, for example 2000-65000"),
                                                                                                                        validate: function(Val){return Val ? Helper.ValidatePortRange(Val) : false},
                                                                                                                        invalidMessage: LangHelper.GetString(_this.Id,"IP port, range, for example 2000-65000"),
                                                                                                                        required:true
                                                                                                                    }
                                                                                                                ]
                                                                                                            },
                                                                                                            {
                                                                                                                cols:[
                                                                                                                    {
                                                                                                                        view: "checkbox",
                                                                                                                        name: "network_port_arr_enabled",
                                                                                                                        labelRight: LangHelper.GetString(_this.Id,"PCI devices"),
                                                                                                                        tooltip: LangHelper.GetString(_this.Id,"If the flag is not set, the general profile settings are used"),
                                                                                                                        value:0,
                                                                                                                        labelWidth:0,
                                                                                                                        width:150,
                                                                                                                        required:true,
                                                                                                                        css:"webix_required"
                                                                                                                    },
                                                                                                                    {
                                                                                                                        view:"multiselect",
                                                                                                                        name:"network_port_arr",
                                                                                                                        tooltip:LangHelper.GetString(_this.Id,"PCI device (network port) that uses a generator to receive / send generated traffic. You can specify several"),
                                                                                                                        options:App.DicsManager.GetDicAsArray(DicsEnum.CardsDic),
                                                                                                                        validate: function(Val){return Val && Val.length;},
                                                                                                                        invalidMessage: LangHelper.GetString(_this.Id,"Required parameter")
                                                                                                                    }
                                                                                                                ]
                                                                                                            },
                                                                                                            {
                                                                                                                cols:[
                                                                                                                    {
                                                                                                                        view: "checkbox",
                                                                                                                        name: "time_enabled",
                                                                                                                        labelRight: LangHelper.GetString(_this.Id,"Run time"),
                                                                                                                        tooltip: LangHelper.GetString(_this.Id,"If the flag is not set, the general profile settings are used"),
                                                                                                                        value:0,
                                                                                                                        labelWidth:0,
                                                                                                                        width:150
                                                                                                                    },
                                                                                                                    {
                                                                                                                        view: "text",
                                                                                                                        name: "time",
                                                                                                                        placeholder:LangHelper.GetString(_this.Id,"for example 1.5"),
                                                                                                                        tooltip:LangHelper.GetString(_this.Id,"The generator's running time in seconds is accurate to a tenth of a second, for example 1.5 - one and a half seconds. Unless indicated, the generator will work endlessly"),
                                                                                                                        validate: function(Val){return Val ? !isNaN(Val) : true;},
                                                                                                                        invalidMessage: LangHelper.GetString(_this.Id,"Number, optional")
                                                                                                                    }
                                                                                                                ]
                                                                                                            },
                                                                                                            {
                                                                                                                cols:[
                                                                                                                    {
                                                                                                                        view: "checkbox",
                                                                                                                        name: "cores_enabled",
                                                                                                                        labelRight: LangHelper.GetString(_this.Id,"Cores"),
                                                                                                                        tooltip: LangHelper.GetString(_this.Id,"If the flag is not set, the general profile settings are used"),
                                                                                                                        value:0,
                                                                                                                        labelWidth:0,
                                                                                                                        width:150,
                                                                                                                        required:true,
                                                                                                                        css:"webix_required"
                                                                                                                    },
                                                                                                                    {
                                                                                                                        view: "text",
                                                                                                                        name: "cores",
                                                                                                                        placeholder:LangHelper.GetString(_this.Id,"for example 0-2"),
                                                                                                                        tooltip:LangHelper.GetString(_this.Id,"The list of cores that the generator uses"),
                                                                                                                        validate: function(Val){
                                                                                                                            var max = 16;

                                                                                                                            var dic = App.DicsManager.GetDic(DicsEnum.CpuDic);
                                                                                                                            if(dic && dic["CPU(s)"])
                                                                                                                                max = dic["CPU(s)"].value;

                                                                                                                            return Val ? Helper.ValidatePortRange(Val, max) : false
                                                                                                                        },
                                                                                                                        invalidMessage: LangHelper.GetString(_this.Id,"Range, for example 0-2")
                                                                                                                    }
                                                                                                                ]
                                                                                                            },
                                                                                                            {
                                                                                                                cols:[
                                                                                                                    {
                                                                                                                        view: "checkbox",
                                                                                                                        name: "socket_mem_enabled",
                                                                                                                        labelRight: LangHelper.GetString(_this.Id,"Memory"),
                                                                                                                        tooltip:LangHelper.GetString(_this.Id,"The memory size allocated for each socket (processor), in megabytes"),
                                                                                                                        value:0,
                                                                                                                        labelWidth:0,
                                                                                                                        width:150,
                                                                                                                        required:true,
                                                                                                                        css:"webix_required"
                                                                                                                    },
                                                                                                                    {
                                                                                                                        view: "text",
                                                                                                                        name: "socket_mem",
                                                                                                                        placeholder:"100",
                                                                                                                        tooltip:LangHelper.GetString(_this.Id,"The memory size allocated for each socket (processor), in megabytes"),
                                                                                                                        validate: function(Val){return Val && !isNaN(Val);},
                                                                                                                        invalidMessage: LangHelper.GetString(_this.Id,"Number")
                                                                                                                    }
                                                                                                                ]
                                                                                                            }
                                                                                                        ]
                                                                                                    }
                                                                                                }
                                                                                            ],
                                                                                            on:{
                                                                                                onChange:function(NVal, OVal)
                                                                                                {
                                                                                                    _this.ApplySubProfileFormToSelectedSubProfile();
                                                                                                }
                                                                                            }
                                                                                        },
                                                                                        {
                                                                                            id:this.Id + "ProfileGeneratorFormFieldset" + TragenTaskTypeEnum.udp,
                                                                                            hidden:true,
                                                                                            view: "form",
                                                                                            margin: 0,
                                                                                            padding: 0,
                                                                                            elements:[
                                                                                                {
                                                                                                    view:"fieldset",
                                                                                                    label:LangHelper.GetString(_this.Id,"Optional"),
                                                                                                    body:{
                                                                                                        rows:[
                                                                                                            {
                                                                                                                cols:[
                                                                                                                    {
                                                                                                                        view: "checkbox",
                                                                                                                        name: "payload_size_enabled",
                                                                                                                        labelRight: LangHelper.GetString(_this.Id,"Payload size"),
                                                                                                                        value:0,
                                                                                                                        labelWidth:0,
                                                                                                                        width:150
                                                                                                                    },
                                                                                                                    {
                                                                                                                        view: "text",
                                                                                                                        name: "payload_size",
                                                                                                                        placeholder:LangHelper.GetString(_this.Id,"Payload size in bytes, maximum 65500"),
                                                                                                                        tooltip:LangHelper.GetString(_this.Id,"Payload size in bytes, maximum 65500")
                                                                                                                    }
                                                                                                                ]
                                                                                                            },
                                                                                                            {
                                                                                                                view: "checkbox",
                                                                                                                name: "rand",
                                                                                                                labelRight: LangHelper.GetString(_this.Id,"Arbitrary payload"),
                                                                                                                value:0,
                                                                                                                labelWidth:0
                                                                                                            }
                                                                                                        ]
                                                                                                    }
                                                                                                }
                                                                                            ],
                                                                                            on:{
                                                                                                onChange:function(NVal, OVal)
                                                                                                {
                                                                                                    _this.ApplySubProfileFormToSelectedSubProfile();
                                                                                                }
                                                                                            }

                                                                                        },
                                                                                        {
                                                                                            id:this.Id + "ProfileGeneratorFormFieldset" + TragenTaskTypeEnum.tcpsyn,
                                                                                            hidden: true,
                                                                                            view: "form",
                                                                                            margin: 0,
                                                                                            padding: 0,
                                                                                            elements: [
                                                                                                {
                                                                                                    view:"fieldset",
                                                                                                    label:LangHelper.GetString(_this.Id,"Optional"),
                                                                                                    body:{
                                                                                                        rows:[
                                                                                                            {
                                                                                                                cols:[
                                                                                                                    {
                                                                                                                        view: "checkbox",
                                                                                                                        name: "mss_enabled",
                                                                                                                        labelRight: LangHelper.GetString(_this.Id,"Max data block"),
                                                                                                                        value:0,
                                                                                                                        labelWidth:0,
                                                                                                                        width:150
                                                                                                                    },
                                                                                                                    {
                                                                                                                        view: "text",
                                                                                                                        name: "mss",
                                                                                                                        placeholder: "1460",
                                                                                                                        tooltip: LangHelper.GetString(_this.Id,"Maximum size of a useful data block in bytes")
                                                                                                                    }
                                                                                                                ]
                                                                                                            },
                                                                                                            {
                                                                                                                cols:[
                                                                                                                    {
                                                                                                                        view: "checkbox",
                                                                                                                        name: "wsize_enabled",
                                                                                                                        labelRight: LangHelper.GetString(_this.Id,"TCP window size"),
                                                                                                                        value:0,
                                                                                                                        labelWidth:0,
                                                                                                                        width:150
                                                                                                                    },
                                                                                                                    {
                                                                                                                        view: "text",
                                                                                                                        name: "wsize",
                                                                                                                        placeholder: "4128",
                                                                                                                        tooltip: LangHelper.GetString(_this.Id,"TCP window size in bytes")
                                                                                                                    }
                                                                                                                ]
                                                                                                            }
                                                                                                        ]
                                                                                                    }
                                                                                                }
                                                                                            ],
                                                                                            on:{
                                                                                                onChange:function(NVal, OVal)
                                                                                                {
                                                                                                    _this.ApplySubProfileFormToSelectedSubProfile();
                                                                                                }
                                                                                            }
                                                                                        },
                                                                                        {
                                                                                            id:this.Id + "ProfileGeneratorFormFieldset" + TragenTaskTypeEnum.http2,
                                                                                            hidden: true,
                                                                                            view: "form",
                                                                                            margin: 0,
                                                                                            padding: 0,
                                                                                            elements: [
                                                                                                {
                                                                                                    view:"fieldset",
                                                                                                    label:LangHelper.GetString(_this.Id,"Required"),
                                                                                                    body:{
                                                                                                        rows:[
                                                                                                            {
                                                                                                                cols:[
                                                                                                                    {
                                                                                                                        view: "checkbox",
                                                                                                                        name: "url_enabled",
                                                                                                                        labelRight: LangHelper.GetString(_this.Id,"URL string"),
                                                                                                                        value:0,
                                                                                                                        labelWidth:0,
                                                                                                                        width:150,
                                                                                                                        required:true,
                                                                                                                        css:"webix_required"
                                                                                                                    },
                                                                                                                    {
                                                                                                                        view: "text",
                                                                                                                        name: "url",
                                                                                                                        placeholder: "http://wwww.yandex.ru",
                                                                                                                        tooltip: LangHelper.GetString(_this.Id,"URL string"),
                                                                                                                        validate: function(Val){return Val && Helper.IsUrlValid(Val);},
                                                                                                                        invalidMessage: LangHelper.GetString(_this.Id,"URL string")
                                                                                                                    }
                                                                                                                ]
                                                                                                            }
                                                                                                        ]
                                                                                                    }
                                                                                                },
                                                                                                {
                                                                                                    view:"fieldset",
                                                                                                    label:LangHelper.GetString(_this.Id,"Optional"),
                                                                                                    body:{
                                                                                                        rows:[
                                                                                                            {
                                                                                                                cols:[
                                                                                                                    {
                                                                                                                        view: "checkbox",
                                                                                                                        name: "mss_enabled",
                                                                                                                        labelRight: LangHelper.GetString(_this.Id,"Max data block"),
                                                                                                                        value:0,
                                                                                                                        labelWidth:0,
                                                                                                                        width:150
                                                                                                                    },
                                                                                                                    {
                                                                                                                        view: "text",
                                                                                                                        name: "mss",
                                                                                                                        placeholder: "1460",
                                                                                                                        tooltip: LangHelper.GetString(_this.Id,"Maximum size of a useful data block in bytes")
                                                                                                                    }
                                                                                                                ]
                                                                                                            },
                                                                                                            {
                                                                                                                cols:[
                                                                                                                    {
                                                                                                                        view: "checkbox",
                                                                                                                        name: "wsize_enabled",
                                                                                                                        labelRight: LangHelper.GetString(_this.Id,"TCP window size"),
                                                                                                                        value:0,
                                                                                                                        labelWidth:0,
                                                                                                                        width:150
                                                                                                                    },
                                                                                                                    {
                                                                                                                        view: "text",
                                                                                                                        name: "wsize",
                                                                                                                        placeholder: "4128",
                                                                                                                        tooltip: LangHelper.GetString(_this.Id,"TCP window size in bytes")
                                                                                                                    }
                                                                                                                ]
                                                                                                            },
                                                                                                            {
                                                                                                                cols:[
                                                                                                                    {
                                                                                                                        view: "checkbox",
                                                                                                                        name: "src_mac_enabled",
                                                                                                                        labelRight: LangHelper.GetString(_this.Id,"MAC source"),
                                                                                                                        tooltip: LangHelper.GetString(_this.Id,"If not specified - random MAC address will be generated"),
                                                                                                                        value:0,
                                                                                                                        labelWidth:0,
                                                                                                                        width:150
                                                                                                                    },
                                                                                                                    {
                                                                                                                        view: "text",
                                                                                                                        name: "src_mac",
                                                                                                                        placeholder: "xx:xx:xx:xx:xx:xx",
                                                                                                                        tooltip: LangHelper.GetString(_this.Id,"MAC-source MAC address, format 'xx: xx: xx: xx: xx: xx'. If not specified, a random MAC address will be generated")
                                                                                                                    }
                                                                                                                ]
                                                                                                            },
                                                                                                            {
                                                                                                                cols:[
                                                                                                                    {
                                                                                                                        view: "checkbox",
                                                                                                                        name: "dst_mac_enabled",
                                                                                                                        labelRight: LangHelper.GetString(_this.Id,"MAC receiver"),
                                                                                                                        tooltip: LangHelper.GetString(_this.Id,"If not specified - random MAC address will be generated"),
                                                                                                                        value:0,
                                                                                                                        labelWidth:0,
                                                                                                                        width:150
                                                                                                                    },
                                                                                                                    {
                                                                                                                        view: "text",
                                                                                                                        name: "dst_mac",
                                                                                                                        placeholder: "xx:xx:xx:xx:xx:xx",
                                                                                                                        tooltip: LangHelper.GetString(_this.Id,"MAC-receiver MAC address, format 'xx: xx: xx: xx: xx: xx'. If not specified, a random MAC address will be generated")
                                                                                                                    }
                                                                                                                ]
                                                                                                            }
                                                                                                        ]
                                                                                                    }
                                                                                                }
                                                                                            ],
                                                                                            on:{
                                                                                                onChange:function(NVal, OVal)
                                                                                                {
                                                                                                    _this.ApplySubProfileFormToSelectedSubProfile();
                                                                                                }
                                                                                            }
                                                                                        },
                                                                                        {
                                                                                            id:this.Id + "ProfileGeneratorFormFieldset" + TragenTaskTypeEnum.pcapsample,
                                                                                            hidden: true,
                                                                                            view: "form",
                                                                                            margin: 0,
                                                                                            padding: 0,
                                                                                            elements: [
                                                                                                {
                                                                                                    view:"fieldset",
                                                                                                    label:LangHelper.GetString(_this.Id,"File"),
                                                                                                    body:{
                                                                                                        rows:[
                                                                                                            {
                                                                                                                view:"label",
                                                                                                                name:"pcap_file_name"
                                                                                                            },
                                                                                                            {
                                                                                                                name: "pcap_file",
                                                                                                                id:this.Id + "ProfileGeneratorFormFieldset" + TragenTaskTypeEnum.pcapsample + "Uploader",
                                                                                                                view: "uploader",
                                                                                                                label: LangHelper.GetString(_this.Id,"Attach a pcap file"),
                                                                                                                autosend:false,
                                                                                                                on:{
                                                                                                                    onAfterFileAdd:function (FileObj)
                                                                                                                    {
                                                                                                                        _this.ApplySubProfileFormToSelectedSubProfile(FileObj);

                                                                                                                        return false;
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                        ]
                                                                                                    }
                                                                                                }
                                                                                            ],
                                                                                            on:{
                                                                                                onChange:function(NVal, OVal)
                                                                                                {
                                                                                                    _this.ApplySubProfileFormToSelectedSubProfile();
                                                                                                }
                                                                                            }
                                                                                        },
                                                                                        {
                                                                                            view: "form",
                                                                                            margin: 0,
                                                                                            padding: 0,
                                                                                            elements:[{}]
                                                                                        }
                                                                                    ]
                                                                                }
                                                                            }
                                                                        ]
                                                                    }
                                                                ]
                                                            }
                                                        ]
                                                    }
                                                ]
                                            }
                                        },
                                        {
                                            view:"resizer"
                                        },
                                        {
                                            header:LangHelper.GetString(_this.Id,"Generation statistics"),
                                            body:{
                                                id:this.Id + "ProfileStatDataPanel",
                                                rows:[
                                                    {
                                                        view: "toolbar",
                                                        height: 32,
                                                        padding: 0,
                                                        margin: 0,
                                                        elements: [
                                                            {
                                                                view:"label",
                                                                id:this.Id+"ProfileDataInfoLabel"
                                                            }
                                                        ]
                                                    },
                                                    {
                                                        id:this.Id + "ProfileStatDataLogTabs",
                                                        rows:[{}]
                                                    }
                                                ]
                                            }
                                        },
                                        {
                                            gravity:0
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    ];

    this.Views = {
        AddProfileBtn:null,
        AddSubProfileBtn:null,
        ProfilesRefreshBtn:null,

        ProfileSaveBtn:null,
        PlayBtn:null,
        StopBtn:null,
        WireSharkBtn:null,

        AddGeneratorBtn:null,

        ProfilesTable:null,
        ProfilesTablePaging:null,
        ProfilesTablePagingSize:null,

        SubProfilesTable:null,
        SubProfilesTablePaging:null,

        ProfileEditPanel:null,
        ProfileCommonForm: null,

        ProfileGeneratorEditPanel:null,
        ProfileGeneratorForm: null,

        ProfileGeneratorFormFieldSets:{},
        ProfileGeneratorFormFieldSetsPcapFileUploader:null,

        ProfileStatDataPanel: null,
        ProfileStatDataLogTabs : null,
        ProfileDataInfoLabel: null
    };

    this.Subscriptions = {};

    this.ProfilesTableRowsSelectedCount = new Rx.BehaviorSubject(0);
    this.SubProfilesTableRowsSelectedCount = new Rx.BehaviorSubject(0);

    this.MonitorTimer = null;
    this.MonitorTimerSpeedNormalInSec = 10;
    this.MonitorTimerSpeedFastInSec = 3;
    this.MonitorTimerSpeedInSec = this.MonitorTimerSpeedNormalInSec;
    this.MonitorTimerFastTries = 0;
    this.MonitorTimerFastTriesMax = 10;

    this.CurrentStatPanelConfigHash = "";
};

TrafficManagementGeneratorViewController.prototype.Destroy = function ()
{
    var _this = this;

    clearTimeout(this.MonitorTimer);

    Object.keys(this.Subscriptions).forEach(function (key)
    {
        _this.Subscriptions[key].unsubscribe();
    });

    this.ViewModel.Destroy();
    delete this.ViewModel;

    this.Health.Destroy();
    delete this.Health;
};

TrafficManagementGeneratorViewController.prototype.ViewDidLoad = function (Params)
{
    var _this = this;

    webix.ready(function () {
        $$(_this.Id + "LeftSideBar").select(_this.Id);
    });

    this.BindAll();

    App.DicsManager.Refresh({data:[DicsEnum.CardsDic, DicsEnum.CpuDic]});

    this.CheckHealth();
};

TrafficManagementGeneratorViewController.prototype.BindAll = function (Params)
{
    var _this = this;

    this.Views.AddProfileBtn = $$(this.Id + "AddProfileBtn");
    this.Views.AddSubProfileBtn = $$(this.Id + "AddSubProfileBtn");
    this.Views.ProfilesRefreshBtn = $$(this.Id + "ProfilesRefreshBtn");

    this.Views.ProfileSaveBtn = $$(this.Id + "ProfileSaveBtn");
    this.Views.PlayBtn = $$(this.Id + "PlayBtn");
    this.Views.StopBtn = $$(this.Id + "StopBtn");
    this.Views.WireSharkBtn = $$(this.Id + "WireSharkBtn");

    this.Views.WireSharkBtn = $$(this.Id + "AddGeneratorBtn");

    this.Views.ProfilesTable = $$(this.Id + "ProfilesTable");
    this.Views.ProfilesTablePaging = $$(this.Id + "ProfilesTable" + "Paging");
    this.Views.ProfilesTablePagingSize = $$(this.Id + "ProfilesTablePagingSize");

    this.Views.ProfileEditPanel = $$(this.Id + "ProfileEditPanel");
    webix.extend(this.Views.ProfileEditPanel, webix.OverlayBox);

    this.Views.ProfileCommonForm = $$(this.Id + "ProfileCommonForm");

    this.Views.SubProfilesTable = $$(this.Id + "SubProfilesTable");
    this.Views.SubProfilesTablePaging = $$(this.Id + "SubProfilesTablePaging");

    this.Views.ProfileGeneratorEditPanel = $$(this.Id + "ProfileGeneratorEditPanel");
    webix.extend(this.Views.ProfileGeneratorEditPanel, webix.OverlayBox);

    this.Views.ProfileGeneratorForm = $$(this.Id + "ProfileGeneratorForm");

    this.Views.ProfileGeneratorFormFieldSets[TragenTaskTypeEnum.udp] = $$(this.Id + "ProfileGeneratorFormFieldset" + TragenTaskTypeEnum.udp);
    this.Views.ProfileGeneratorFormFieldSets[TragenTaskTypeEnum.tcpsyn] = $$(this.Id + "ProfileGeneratorFormFieldset" + TragenTaskTypeEnum.tcpsyn);
    this.Views.ProfileGeneratorFormFieldSets[TragenTaskTypeEnum.http2] = $$(this.Id + "ProfileGeneratorFormFieldset" + TragenTaskTypeEnum.http2);
    this.Views.ProfileGeneratorFormFieldSets[TragenTaskTypeEnum.pcapsample] = $$(this.Id + "ProfileGeneratorFormFieldset" + TragenTaskTypeEnum.pcapsample);

    this.Views.ProfileGeneratorFormFieldSetsPcapFileUploader = $$(this.Id + "ProfileGeneratorFormFieldset" + TragenTaskTypeEnum.pcapsample + "Uploader");

    this.Views.ProfileStatDataPanel = $$(this.Id + "ProfileStatDataPanel");
    this.Views.ProfileStatDataLogTabs = $$(this.Id + "ProfileStatDataLogTabs");

    this.Views.ProfileDataInfoLabel = $$(this.Id + "ProfileDataInfoLabel");

    this.Subscriptions.ProfilesTableRowsSelectedCountSubscription = this.ProfilesTableRowsSelectedCount.subscribe(function (Value)
    {
        if(Value == 1)
        {
            _this.Views.ProfileEditPanel.enable();

            _this.Views.AddSubProfileBtn.enable();

            _this.SetProfileFormSelectedProfile();

            _this.SetProfileStatPanelsSelectedProfile();

            _this.SetProfileDataInfoLabel();
        }
        else
        {
            _this.Views.ProfileEditPanel.disable();
            _this.Views.AddSubProfileBtn.disable();
            _this.Views.ProfileStatDataPanel.disable();
        }
    });

    this.Subscriptions.SignalDicUpdatedSubscription = App.DicsManager.Signal[DicsEnum.CardsDic].subscribe(function (Updated)
    {
        if (Updated)
        {
            _this.Views.ProfileCommonForm.elements.network_port_arr.getPopup().getList().parse(App.DicsManager.GetDicAsArray(DicsEnum.CardsDic));

            _this.Views.ProfileGeneratorForm.elements.network_port_arr.getPopup().getList().parse(App.DicsManager.GetDicAsArray(DicsEnum.CardsDic));

            _this.RefreshProfilesTable(0);
        }
    });

    this.Subscriptions.SignalProfilesUpdatedSubscription = this.ViewModel.SignalProfilesUpdated.subscribe(function (Updated)
    {
        if (Updated)
        {
            _this.UpdateProfilesTable();

            _this.MonitorProfilesTable();
        }
    });

    this.Subscriptions.SubProfilesTableRowsSelectedCountSubscription = this.SubProfilesTableRowsSelectedCount.subscribe(function (Value)
    {
        if(Value == 1)
        {
            _this.Views.ProfileGeneratorEditPanel.enable();

            _this.SetSubProfileFormSelectedSubProfile();
        }
        else
        {
            _this.Views.ProfileGeneratorEditPanel.disable();
        }
    });

    this.Subscriptions.SignalProfileUpdatedSubscription = this.ViewModel.SignalProfileUpdated.subscribe(function (Profile)
    {
        if(Profile)
        {
            var selectedData = _this.GetProfilesTableSelectedData();

            var rows = _this.Views.ProfilesTable.find(function(obj)
            {
                return obj.tt_id == Profile.tt_id;
            });

            if(rows && rows.length == 1)
            {
                _this.Views.ProfilesTable.updateItem(rows[0].id,  Profile);

                if(selectedData && selectedData.tt_id == Profile.tt_id && Profile.task_status != TragenTaskStatusEnum.new)
                {
                    _this.OnProfilesTableRowSelect();
                }
            }
        }
    });

    this.Subscriptions.SignalProfileLogsUpdatedSubscription = this.ViewModel.SignalProfileLogsUpdated.subscribe(function (Profile)
    {
        if(Profile)
        {
            _this.SetProfileLogsSelectedProfile();
        }
    });

    this.Subscriptions.SignalProfileStatsUpdatedSubscription = this.ViewModel.SignalProfileStatsUpdated.subscribe(function (Profile)
    {
        if(Profile)
        {
            _this.SetProfileStatsSelectedProfile();
        }
    });

    this.Subscriptions.SignalHealthUpdatedSubscription = this.Health.HealthUpdated.subscribe(function (Updated)
    {
        if (Updated)
        {
            _this.HealthResult();
        }
    });
};

TrafficManagementGeneratorViewController.prototype.GetProfilesTableSelectedData = function ()
{
    return this.Views.ProfilesTable.getSelectedItem();
};

TrafficManagementGeneratorViewController.prototype.OnProfilesTableRowSelect = function ()
{
    var data = this.GetProfilesTableSelectedData();

    this.ProfilesTableRowsSelectedCount.next(data?(Array.isArray(data)?data.length:1):0);
};

TrafficManagementGeneratorViewController.prototype.OnProfilesTableRowUnSelect = function ()
{
    var data = this.GetProfilesTableSelectedData();
    this.ProfilesTableRowsSelectedCount.next(data?(Array.isArray(data)?data.length:1):0);
};

TrafficManagementGeneratorViewController.prototype.RefreshProfilesTable = function(Page)
{
    var _this = this;

    var state = this.Views.ProfilesTable.getState();

    var size = this.Views.ProfilesTablePagingSize.getValue();

    this.ViewModel.RefershProfiles({page:Page, filter:state.filter, size:size, sort:state.sort ? state.sort:null, callback:function ()
    {

    }});
};

TrafficManagementGeneratorViewController.prototype.AddProfileToProfilesTable = function()
{
    var _this = this;

    var model = new TragenProfileModel();

    _this.Views.ProfilesTable.add(model,0);

    _this.Views.ProfilesTable.select(model.id, false);
    _this.Views.ProfilesTable.showItem(model.id);

};

TrafficManagementGeneratorViewController.prototype.AddSubProfileToProfilesTable = function()
{
    var _this = this;

    var selectedModel = _this.GetProfilesTableSelectedData();

    if(!selectedModel || !selectedModel.tt_id)
        return;

    var model = new TragenProfileModel({parent_id:selectedModel.tt_id});

    _this.Views.ProfilesTable.add(model,0,selectedModel.id);
    _this.Views.ProfilesTable.open(selectedModel.id);

    _this.Views.ProfilesTable.select(model.id, false);
    _this.Views.ProfilesTable.showItem(model.id);
};

TrafficManagementGeneratorViewController.prototype.DeleteProfile = function (RowId)
{
    var _this = this;

    var profileData = this.Views.ProfilesTable.getItem(RowId);
    profileData.valid = true;

    if(profileData.tt_id > 0)
    {
        this.ViewModel.DeleteProfile({profile:profileData, callback:function (R)
        {
            if(R.success)
            {
                _this.Views.ProfilesTable.remove(RowId);

                webix.message(LangHelper.GetString(_this.Id,"Deleted"));
            }
            else
            {
                profileData.valid = false;
                _this.Views.ProfilesTable.validate(RowId);
                if(R.error)
                    webix.message({text:R.error.msg,type:"error"});
            }
        }});
    }
    else
    {
        this.Views.ProfilesTable.remove(RowId);
    }
};

TrafficManagementGeneratorViewController.prototype.UpdateProfilesTable = function (Params)
{
    var _this = this;

    var dic = App.DicsManager.GetDicAsArray(DicsEnum.UsersDic);

    this.Views.ProfilesTable.getColumnConfig("user_id").header[1].options = App.DicsManager.GetDicAsArray(DicsEnum.UsersDic);

    this.Views.ProfilesTable.clearAll();
    this.Views.ProfilesTable.parse(_this.ViewModel.GetProfiles());

    this.Views.ProfilesTablePagingSize.setValue(this.ViewModel.ProfilesFilter.total.size);

    this.Views.ProfilesTablePaging.define(this.ViewModel.ProfilesFilter.total);
    this.Views.ProfilesTablePaging.refresh();
};

TrafficManagementGeneratorViewController.prototype.SaveProfile = function (Save, Validate)
{
    var _this = this;

    var profileData = this.GetProfilesTableSelectedData();

    var formData = this.ParamsToProfileFormModel(this.Views.ProfileCommonForm.getValues());

    var tasks = this.SerializeSubProfilesTable();

    tasks.forEach(function (Task)
    {
        var form_data = JSON.parse(Task.form_data);

        if(form_data)
        {
            form_data.default = new TragenFormProfileModel(formData, true);

            Task.form_data = JSON.stringify(form_data);
        }
    });

    formData.tasks = tasks;

    var _save = function ()
    {
        _this.ViewModel.SaveProfile({profile:profileData, profile_form:formData, callback:function (R)
        {
            if(R.success && R.data && R.data.task)
            {
                _this.Views.ProfilesTable.updateItem(profileData.id,  new TragenProfileModel(R.data.task));

                _this.MonitorProfilesTable();

                webix.message(LangHelper.GetString(_this.Id,"Profile saved"));
            }
            else
            {
                profileData.valid = false;

                _this.Views.ProfilesTable.validate(profileData.id);

                if(R.error)
                    webix.message({text:R.error.msg,type:"error"});
            }

            _this.OnProfilesTableRowSelect();

            _this.ClearFormsValidations();
        }});
    };

    if(Validate)
    {
        profileData.valid = this.ValidateProfile(profileData, formData);
        this.Views.ProfilesTable.validate(profileData.id);

        if(profileData.valid && Save)
        {
            _save();
        }
    }
    else
    {
        profileData.valid = true;
        this.Views.ProfilesTable.validate(profileData.id);

        this.ClearFormsValidations();

        if(Save)
        {
            _save();
        }
    }
};

TrafficManagementGeneratorViewController.prototype.ValidateProfile = function (ProfileData, FormData)
{
    var _this = this;

    var valid = true;

    var selected = this.GetSubProfilesTableSelectedData();

    var cores = {};

    var ports = {};

    FormData.tasks.every(function (Task)
    {
        var formData = JSON.parse(Task.form_data);

        _this.Views.SubProfilesTable.select(Task.id, false);
        _this.Views.SubProfilesTable.showItem(Task.id);

        if(formData.cores_enabled)
        {
            valid = _this.Views.ProfileGeneratorForm.elements.cores.validate();
        }
        else
        {
            valid = _this.Views.ProfileCommonForm.elements.cores.validate();
        }

        if(!valid)
        {
            var max = 16;

            var dic = App.DicsManager.GetDic(DicsEnum.CpuDic);
            if(dic && dic["CPU(s)"])
                max = dic["CPU(s)"].value;

            var text = LangHelper.GetString(_this.Id,"You must specify a list of cores. The maximum number is ") + max;
            webix.message({text:text, type:"debug"});
            valid = false;
            return false;
        }

        var _cores = formData.cores_enabled ? formData.cores : FormData.cores;
        var _coresArr = Helper.RangeToArray(_cores);
        _coresArr.every(function (Val) {
            if(cores[Val])
            {
                valid = false;
                return false;
            }

            cores[Val] = 1;
            return true;
        });

        if(!valid)
        {
            var text = LangHelper.GetString(_this.Id,"It is necessary to specify a different range of cores for each generator");
            webix.message({text:text, type:"debug"});
            valid = false;
            return false;
        }

        if(formData.socket_mem_enabled)
        {
            valid = _this.Views.ProfileGeneratorForm.elements.socket_mem.validate();
        }
        else
        {
            valid = _this.Views.ProfileCommonForm.elements.socket_mem.validate();
        }

        if(!valid)
        {
            webix.message({text:LangHelper.GetString(_this.Id,"You must set the memory size in megabytes"), type:"debug"});
            valid = false;
            return false;
        }

        if(formData.network_port_arr_enabled)
        {
            valid = _this.Views.ProfileGeneratorForm.elements.network_port_arr.validate();
        }
        else
        {
            valid = _this.Views.ProfileCommonForm.elements.network_port_arr.validate();
        }

        if(!valid)
        {
            webix.message({text:LangHelper.GetString(_this.Id,"You must specify network ports"), type:"debug"});
            valid = false;
            return false;
        }

        var _ports = formData.network_port_arr_enabled ? formData.network_port_arr : FormData.network_port_arr;
        var _portsArr = Helper.RangeToArray(_ports);
        _portsArr.every(function (Val) {
            if(ports[Val])
            {
                valid = false;
                return false;
            }

            ports[Val] = 1;
            return true;
        });

        if(!valid)
        {
            var text = LangHelper.GetString(_this.Id,"You must specify a different set of PCI ports for each generator");
            webix.message({text:text, type:"debug"});
            valid = false;
            return false;
        }

        if(formData.time_enabled)
        {
            valid = _this.Views.ProfileGeneratorForm.elements.time.validate();
        }
        else
        {
            valid = _this.Views.ProfileCommonForm.elements.time.validate();
        }

        if(!valid)
        {
            webix.message({text:LangHelper.GetString(_this.Id,"You need to set the time of work"), type:"debug"});
            valid = false;
            return false;
        }

        if(formData.src_enabled)
        {
            valid = _this.Views.ProfileGeneratorForm.elements.src_ip.validate();
        }
        else
        {
            valid = _this.Views.ProfileCommonForm.elements.src_ip.validate();
        }

        if(!valid)
        {
            webix.message({text:LangHelper.GetString(_this.Id,"It is necessary to set the IP-range of the source"), type:"debug"});
            valid = false;
            return false;
        }

        if(formData.gen_type != TragenTaskTypeEnum.pcapsample || (formData.gen_type == TragenTaskTypeEnum.pcapsample && (formData.src_port || FormData.src_port)))
        {
            if(formData.src_enabled)
            {
                valid = _this.Views.ProfileGeneratorForm.elements.src_port.validate();
            }
            else
            {
                valid = _this.Views.ProfileCommonForm.elements.src_port.validate();
            }
        }

        if(!valid)
        {
            webix.message({text:LangHelper.GetString(_this.Id,"You must specify a range of source ports"), type:"debug"});
            valid = false;
            return false;
        }

        if(formData.dst_enabled)
        {
            valid = _this.Views.ProfileGeneratorForm.elements.dst_ip.validate();
        }
        else
        {
            valid = _this.Views.ProfileCommonForm.elements.dst_ip.validate();
        }

        if(!valid)
        {
            webix.message({text:LangHelper.GetString(_this.Id,"It is necessary to set the receiver's IP range"), type:"debug"});
            valid = false;
            return false;
        }

        if(formData.gen_type != TragenTaskTypeEnum.pcapsample || (formData.gen_type == TragenTaskTypeEnum.pcapsample && (formData.dst_port || FormData.dst_port)))
        {
            if(formData.dst_enabled)
            {
                valid = _this.Views.ProfileGeneratorForm.elements.dst_port.validate();
            }
            else
            {
                valid = _this.Views.ProfileCommonForm.elements.dst_port.validate();
            }
        }

        if(!valid)
        {
            webix.message({text:LangHelper.GetString(_this.Id,"You must specify a range of destination ports"), type:"debug"});
            valid = false;
            return false;
        }

        if(Task.gen_type == TragenTaskTypeEnum.http2)
        {
            if(formData.url_enabled)
            {
                valid = _this.Views.ProfileGeneratorFormFieldSets[TragenTaskTypeEnum.http2].elements.url.validate();
            }
            else
            {
                valid = true;
            }

            if(!valid)
            {
                webix.message({text:LangHelper.GetString(_this.Id,"You must specify a URL"), type:"debug"});
                valid = false;
                return false;
            }
        }

        if(Task.gen_type == TragenTaskTypeEnum.pcapsample)
        {
            if(formData.pcap_file_name && formData.pcap_file_name != LangHelper.GetString(_this.Id,"No file"))
            {
                valid = true;
            }
            else
            {
                valid = false;
            }

            if(!valid)
            {
                webix.message({text:LangHelper.GetString(_this.Id,"You need to attach a file"), type:"debug"});
                valid = false;
                return false;
            }
        }

        if(selected && selected.id)
        {
            _this.Views.SubProfilesTable.select(selected.id, false);
            _this.Views.SubProfilesTable.showItem(selected.id);
        }

        webix.message(LangHelper.GetString(_this.Id,"Profile is valid"));

        return true;
    });

    return valid;
};

TrafficManagementGeneratorViewController.prototype.ParamsToProfileFormModel = function(Params)
{
    var model = new TragenFormProfileModel(Params);

    return model;
};

TrafficManagementGeneratorViewController.prototype.ProfileFormModelToParams = function(ProfileForm)
{
    var taskFormModel = new TragenFormProfileModel(ProfileForm);

    return taskFormModel;
};

TrafficManagementGeneratorViewController.prototype.SetProfileFormSelectedProfile = function()
{
    var profileData = this.GetProfilesTableSelectedData();

    if(profileData)
    {
        var formParams = this.ProfileFormModelToParams(JSON.parse(profileData.form_data));
        this.Views.ProfileCommonForm.setValues(formParams);

        this.Views.SubProfilesTable.clearAll();
        this.Views.SubProfilesTable.parse(profileData.tragen_data);

        this.SetProfileFormButtonsState(profileData);
        this.ClearFormsValidations();
    }
};

TrafficManagementGeneratorViewController.prototype.SetProfileFormButtonsState = function (ProfileData)
{
    switch (parseInt(ProfileData.task_status))
    {
        case TragenTaskStatusEnum.new:
        case TragenTaskStatusEnum.stop:
        case TragenTaskStatusEnum.error:

            if(ProfileData.tt_id && ProfileData.tragen_data.length)
                this.Views.PlayBtn.enable();
            else
                this.Views.PlayBtn.disable();

            this.Views.ProfileSaveBtn.enable();

            this.Views.StopBtn.disable();

            break;

        case TragenTaskStatusEnum.progress:
            this.Views.ProfileSaveBtn.disable();
            this.Views.PlayBtn.disable();
            this.Views.StopBtn.enable();
            break;
        default:
            break;
    }
};

TrafficManagementGeneratorViewController.prototype.AddSubProfileToSubProfilesTable = function()
{
    var _this = this;

    var model = new TragenProfileModel({gen_type:TragenTaskTypeEnum.udp, form_data:'{"gen_type":"'+TragenTaskTypeEnum.udp+'"}'});

    _this.Views.SubProfilesTable.add(model,0);

    _this.Views.SubProfilesTable.select(model.id, false);
    _this.Views.SubProfilesTable.showItem(model.id);

};

TrafficManagementGeneratorViewController.prototype.DeleteSubProfile = function (RowId)
{
    var _this = this;

    this.Views.SubProfilesTable.remove(RowId);
};

TrafficManagementGeneratorViewController.prototype.GetSubProfilesTableSelectedData = function ()
{
    return this.Views.SubProfilesTable.getSelectedItem();
};

TrafficManagementGeneratorViewController.prototype.OnSubProfilesTableRowSelect = function ()
{
    var data = this.GetSubProfilesTableSelectedData();
    this.SubProfilesTableRowsSelectedCount.next(data?(Array.isArray(data)?data.length:1):0);
};

TrafficManagementGeneratorViewController.prototype.OnSubProfilesTableRowUnSelect = function ()
{
    var data = this.GetSubProfilesTableSelectedData();
    this.SubProfilesTableRowsSelectedCount.next(data?(Array.isArray(data)?data.length:1):0);
};

TrafficManagementGeneratorViewController.prototype.SubProfileFormModelToParams = function(ProfileForm, Type)
{
    var taskFormModel = GetTragenFormSubProfileModel(ProfileForm, Type);

    return taskFormModel;
};

TrafficManagementGeneratorViewController.prototype.SetSubProfileFormSelectedSubProfile = function()
{
    var _this = this;

    var subProfileData = this.GetSubProfilesTableSelectedData();

    if(subProfileData)
    {
        var formParams = this.SubProfileFormModelToParams(JSON.parse(subProfileData.form_data), subProfileData.gen_type);

        if(formParams)
        {
            formParams.title = subProfileData.title;
            formParams.gen_type = subProfileData.gen_type;

            this.Views.ProfileGeneratorForm.clear();

            Object.keys(this.Views.ProfileGeneratorFormFieldSets).forEach(function (Key)
            {
                _this.Views.ProfileGeneratorFormFieldSets[Key].clear();

                if(Key == TragenTaskTypeEnum.pcapsample)
                {
                    _this.Views.ProfileGeneratorFormFieldSets[Key].elements.pcap_file_name.setValue("");
                }
            });

            var temp = GetTragenFormSubProfileModel(formParams, subProfileData.gen_type, true, false);

            this.Views.ProfileGeneratorForm.setValues(temp);

            this.Views.ProfileGeneratorFormFieldSets[subProfileData.gen_type].setValues(GetTragenFormSubProfileModel(formParams, subProfileData.gen_type, false, true));
        }

        this.SetProfileGeneratorFormFieldSetsVisibility(subProfileData.gen_type);
    }

    _this.ClearFormsValidations();
};

TrafficManagementGeneratorViewController.prototype.ApplySubProfileFormToSelectedSubProfile = function(FileObj)
{
    var _this = this;

    var mainFormData = this.Views.ProfileGeneratorForm.getValues();

    if(!mainFormData.gen_type)
        return;

    var subProfileData = this.GetSubProfilesTableSelectedData();

    if(!subProfileData)
        return;

    subProfileData.title = mainFormData.title;
    subProfileData.gen_type = mainFormData.gen_type;

    var subFormData = this.Views.ProfileGeneratorFormFieldSets[subProfileData.gen_type].getValues();

    var _applyForm = function()
    {
        var formData = webix.extend(mainFormData, subFormData);

        subProfileData.form_data = JSON.stringify(formData);

        _this.Views.SubProfilesTable.updateItem(subProfileData.id,  new TragenProfileModel(subProfileData));
        _this.SetSubProfileFormSelectedSubProfile();
    };

    if(FileObj && subProfileData.gen_type == TragenTaskTypeEnum.pcapsample)
    {
        var reader = new FileReader();

        reader.onload = function(e)
        {
            subFormData.pcap_file_name = FileObj.name;
            subProfileData.file_content = reader.result;

            _applyForm();
        }

        reader.readAsDataURL(FileObj.file);
    }
    else
    {
        _applyForm();
    }
};

TrafficManagementGeneratorViewController.prototype.SerializeSubProfilesTable = function()
{
    return this.Views.SubProfilesTable.serialize();
};

TrafficManagementGeneratorViewController.prototype.SetProfileGeneratorFormFieldSetsVisibility = function(Type)
{
    var _this = this;

    Object.keys(this.Views.ProfileGeneratorFormFieldSets).forEach(function (Key)
    {
        var form = _this.Views.ProfileGeneratorFormFieldSets[Key];

        if(Key == Type)
        {
            form.show();
        }
        else
        {
            form.hide();
            form.clear();
        }
    });
};

TrafficManagementGeneratorViewController.prototype.RunProfile = function ()
{
    var _this = this;

    var profileData = this.GetProfilesTableSelectedData();

    var subProfiles = profileData.tragen_data;

    if(!subProfiles || !Array.isArray(subProfiles) || subProfiles.length == 0)
    {
        webix.message({text:LangHelper.GetString(_this.Id,"No generator set"), type:"debug"});
        return;
    }

    profileData.valid = true;

    this.ViewModel.RunTragenTask({profile:profileData, callback:function (R)
    {
        if(R.success && R.data && R.data.task)
        {
            _this.Views.ProfilesTable.updateItem(profileData.id,  new TragenProfileModel(R.data.task));

            _this.MonitorProfilesTable(true);

            if(R.data.run_res && R.data.task.tragen_data)
            {
                var i = 0;
                var success = 0;
                var error = 0;

                R.data.run_res.data.forEach(function (Res)
                {
                    if(Res.success && R.data.task.tragen_data[i] && R.data.task.tragen_data[i].task_status == TragenTaskStatusEnum.progress)
                    {
                        var title = R.data.task.tragen_data[i].title;
                        var tt_id = R.data.task.tragen_data[i].tt_id;

                        webix.message((title?title:LangHelper.GetString(_this.Id,"Generator") + " " + tt_id) + " " + LangHelper.GetString(_this.Id,"launched"));

                        success++;
                    }

                    if(!Res.success && R.data.task.tragen_data[i] && R.data.task.tragen_data[i].task_status != TragenTaskStatusEnum.progress)
                    {
                        var title = R.data.task.tragen_data[i].title;
                        var tt_id = R.data.task.tragen_data[i].tt_id;

                        webix.message({text:(title?title:LangHelper.GetString(_this.Id,"Generator") + " " + tt_id) + " " + LangHelper.GetString(_this.Id,"not launched") + " - " + Res.error.msg, type:"error"});

                        error++;
                    }
                    i++;
                });
            }

            if(success > 0 && error == 0)
                webix.message(LangHelper.GetString(_this.Id,"Generation started successfully"));

            else if(success == 0 && error > 0)
                webix.message({text:LangHelper.GetString(_this.Id,"Generation not running"), type:"error"});

            else if(success > 0 && error > 0)
                webix.message({text:LangHelper.GetString(_this.Id,"Generation is partially started"), type:"debug"});
        }
        else
        {
            profileData.valid = false;

            _this.Views.ProfilesTable.validate(profileData.id);

            if(R.error)
                webix.message({text:R.error.msg,type:"error"});
        }

        _this.OnProfilesTableRowSelect();
    }});
};

TrafficManagementGeneratorViewController.prototype.StopProfile = function ()
{
    var _this = this;

    var profileData = this.GetProfilesTableSelectedData();

    var subProfiles = profileData.tragen_data;

    profileData.valid = true;

    this.ViewModel.StopTragenTask({profile:profileData, callback:function (R)
    {
        if(R.success && R.data && R.data.task)
        {
            _this.Views.ProfilesTable.updateItem(profileData.id,  new TragenProfileModel(R.data.task));

            webix.message(LangHelper.GetString(_this.Id,"Generation is stopped"));
        }
        else
        {
            profileData.valid = false;

            _this.Views.ProfilesTable.validate(profileData.id);

            if(R.error)
                webix.message({text:R.error.msg,type:"error"});
        }

        _this.OnProfilesTableRowSelect();
    }});
};

TrafficManagementGeneratorViewController.prototype.MonitorProfilesTable = function(FastSpeed)
{
    var _this = this;

    clearTimeout(this.MonitorTimer);

    _this.MonitorTimerFastTries++;

    if(FastSpeed)
    {
        _this.MonitorTimerFastTries = 0;

        _this.MonitorTimerSpeedInSec = _this.MonitorTimerSpeedFastInSec;
    }

    if(_this.MonitorTimerFastTries >= this.MonitorTimerFastTriesMax)
        _this.MonitorTimerSpeedInSec = _this.MonitorTimerSpeedNormalInSec;

    var profiles = this.Views.ProfilesTable.serialize();

    var profilesIdsToMonitor = [];

    var addToMonitor = function (Profiles)
    {
        Profiles.forEach(function (Profile)
        {
            if(Profile.tt_id && Profile.task_status == TragenTaskStatusEnum.progress && Profile.tragen_data && Profile.tragen_data.length)
            {
                profilesIdsToMonitor.push(Profile.task_id)
            }

            if(Profile.data && Profile.data.length)
            {
                addToMonitor(Profile.data);
            }
        });
    };

    addToMonitor(profiles);

    if(profilesIdsToMonitor.length)
    {
        this.ViewModel.RefershProfiles({profiles_ids:profilesIdsToMonitor, callback:function ()
        {
            _this.MonitorTimer = setTimeout(function ()
            {
                _this.MonitorProfilesTable();
            }, _this.MonitorTimerSpeedInSec*1000);
        }});
    }
};

TrafficManagementGeneratorViewController.prototype.SetProfileStatPanelsSelectedProfile = function()
{
    var profileData = this.GetProfilesTableSelectedData();

    if(profileData)
    {
        this.ConfigProfileLogsPanelSelectedProfile(profileData);

        this.ViewModel.RefreshProfileLogs(profileData);
        this.ViewModel.RefreshProfileStats(profileData);
    }

    if(!profileData || !profileData.tt_id || !profileData.tragen_data || !profileData.tragen_data.length)
    {
        this.Views.ProfileStatDataPanel.disable();
    }
    else
    {
        this.Views.ProfileStatDataPanel.enable();
    }

    this.SetProfileGeneratorsStatusesInfo();
};

TrafficManagementGeneratorViewController.prototype.ConfigProfileLogsPanelSelectedProfile = function(ProfileData)
{
    var _this = this;

    var cellsConfig = [];

    var tabbarOptionsConfig = [];

    var tabsConfig = [{}];

    var exists = [];

    var portsExists = [];

    var ports = [];

    if(ProfileData.tragen_data && ProfileData.tragen_data.length)
    {
        var profileFormData = JSON.parse(ProfileData.form_data);

        ProfileData.tragen_data.forEach(function (SubProfile)
        {
            if(SubProfile.tt_id)
            {
                ports = [];

                if(profileFormData && profileFormData.network_port_arr)
                {
                    ports = Array.isArray(profileFormData.network_port_arr) ? profileFormData.network_port_arr.sort() : profileFormData.network_port_arr.split(",").sort();
                }

                var subProfileFormData = JSON.parse(SubProfile.form_data);

                if(subProfileFormData && subProfileFormData.network_port_arr)
                {
                    if(subProfileFormData.network_port_arr_enabled)
                    {
                        ports = Array.isArray(subProfileFormData.network_port_arr) ? subProfileFormData.network_port_arr.sort() : subProfileFormData.network_port_arr.split(",").sort();
                    }
                }

                if($$(_this.Id+"ProfileLogsStatsDataTabs_" + SubProfile.tt_id + "_Editor"))
                {
                    exists.push(1);
                }

                var portsGageConfig = [];
                var portIndex = 0;

                ports.forEach(function (Port)
                {
                    portsGageConfig.push({
                        id:_this.Id + "ProfileLogsStatsDataTabs_" + SubProfile.tt_id + "_" + Port,
                        width: 150,
                        view: "gage",
                        value: 0,
                        minRange: 0,
                        maxRange: 100,
                        label: LangHelper.GetString(_this.Id,"Port ") + Port,
                        placeholder: LangHelper.GetString(_this.Id,"Gigabit/sec")
                    });

                    if($$(_this.Id + "ProfileLogsStatsDataTabs_" + SubProfile.tt_id + "_" + Port))
                    {
                        portsExists.push(1);
                    }

                    portIndex++;
                });

                portsGageConfig.push({
                    id:_this.Id + "ProfileLogsStatsDataTabs_" + SubProfile.tt_id + "_" + "total",
                    width: 150,
                    view: "gage",
                    value: 0,
                    minRange: 0,
                    maxRange: 100,
                    label: LangHelper.GetString(_this.Id,"Total"),
                    placeholder: LangHelper.GetString(_this.Id,"Gigabit/sec")
                });

                portsGageConfig.push({});

                var cellConfig = {
                    id:_this.Id + "ProfileLogsStatsDataTabs_" + SubProfile.tt_id,
                    rows:[
                        {
                            view: "toolbar",
                            height: 32,
                            padding: 0,
                            margin: 0,
                            elements: [
                                {
                                    view:"label",
                                    id:_this.Id+"ProfileLogsStatsDataTabs_" + SubProfile.tt_id + "_InfoLabel"
                                }
                            ]
                        },
                        BlockHeaderTemplate({title:LangHelper.GetString(_this.Id,"Generation efficiency"), icon:"file", no_id: true}),
                        {
                            height:150,
                            cols:portsGageConfig
                        },
                        BlockHeaderTemplate({title:LangHelper.GetString(_this.Id,"Generation log"), icon:"file", no_id: true}),
                        {
                            view:"codemirror-editor",
                            id:_this.Id+"ProfileLogsStatsDataTabs_" + SubProfile.tt_id + "_Editor",
                            value:""
                        }
                    ]
                };

                var tabbarOptionValue = SubProfile.title?SubProfile.title:LangHelper.GetString(_this.Id,"Generator") + " " + SubProfile.tt_id;

                var tabbarOption = {
                    id:cellConfig.id,
                    value:tabbarOptionValue,
                    title:tabbarOptionValue

                };

                cellsConfig.push(cellConfig);
                tabbarOptionsConfig.push(tabbarOption);
            }
        });
    }

    var hash = JSON.stringify(cellsConfig);

    if(this.CurrentStatPanelConfigHash === hash)
        return;

    this.CurrentStatPanelConfigHash = hash;

    if(cellsConfig.length)
    {
        var tabsConfig = [
            {
                view: "tabbar",
                id: this.Id + "ProfileLogsStatsDataTabs",
                value: tabbarOptionsConfig[0].id,
                multiview: true,
                height:webix.skin.$active.tabbarSmallHeight,
                borderless:false,
                options: tabbarOptionsConfig,
                on:{
                    onChange:function(New, Old)
                    {
                        _this.SetProfileStatPanelsSelectedProfile();
                    }
                }
            },
            {
                cells: cellsConfig
            }
        ];
    }

    this.Views.ProfileStatDataLogTabs.define("rows",tabsConfig);
    this.Views.ProfileStatDataLogTabs.reconstruct();
};

TrafficManagementGeneratorViewController.prototype.SetProfileLogsSelectedProfile = function()
{
    var _this = this;

    var profileData = this.GetProfilesTableSelectedData();

    if(profileData)
    {
        var LogsModel = this.ViewModel.GetProfileLogs(profileData);

        if(LogsModel && LogsModel.logs && LogsModel.logs.length && LogsModel.task && LogsModel.task.tt_id == profileData.tt_id)
        {
            LogsModel.logs.forEach(function (Log)
            {
                var editor = $$(_this.Id+"ProfileLogsStatsDataTabs_" + Log.task.tt_id + "_Editor");

                if(editor)
                {
                    editor.setValue(Log.content);
                }
            });
        }
    }
};

TrafficManagementGeneratorViewController.prototype.SetProfileStatsSelectedProfile = function()
{
    var _this = this;

    var profileData = this.GetProfilesTableSelectedData();

    if(profileData)
    {
        var StatsModel = this.ViewModel.GetProfileStats(profileData);

        if(StatsModel && StatsModel.stats && StatsModel.stats.length && StatsModel.task && StatsModel.task.tt_id == profileData.tt_id)
        {
            StatsModel.stats.forEach(function (Stat)
            {
                var ticks = Object.keys(Stat).sort();

                if(Stat && ticks.length > 0)
                {
                    var l = ticks.length;

                    var lastTick = Stat[ticks[l-1]];

                    var portIndex = 0;

                    Object.keys(lastTick).sort().forEach(function (PortKey)
                    {
                        var port = lastTick[PortKey];

                        var viewId = _this.Id + "ProfileLogsStatsDataTabs_" + port.task_id + "_" + PortKey;

                        if(port && $$(viewId))
                        {
                            var speed = profileData.task_status == TragenTaskStatusEnum.progress ? (Math.round(port.speed * 10) / 10) : 0;

                            var maxSpeed = (Math.round(port.max_speed * 10) / 10)

                            var view = $$(viewId);

                            view.define("maxRange", maxSpeed);

                            view.refresh();

                            view.setValue(speed);
                        }

                        portIndex++;
                    });
                }
            });
        }
    }

    this.SetProfileGeneratorsStatusesInfo();
};

TrafficManagementGeneratorViewController.prototype.ClearFormsValidations = function()
{
    this.Views.ProfileCommonForm.clearValidation();
    this.Views.ProfileGeneratorForm.clearValidation();
};

TrafficManagementGeneratorViewController.prototype.SetProfileDataInfoLabel = function()
{
    var _this = this;

    var profileData = this.GetProfilesTableSelectedData();

    if(!profileData)
        return;

    var format =  webix.Date.dateToStr("%d.%m.%Y %H:%i");
    var date =  format(profileData.date);

    var status = "";

    if(TragenTaskStatusesDic[profileData.task_status])
        status = "<span class=\"status status_" + profileData.task_status + "\">" + TragenTaskStatusesDic[profileData.task_status].value + "</span>";

    var title = profileData.title?profileData.title:LangHelper.GetString(_this.Id,"Profile") + " "+profileData.task_id;

    var text = title + " " + LangHelper.GetString(_this.Id,"from") + " " + date + ". " + LangHelper.GetString(_this.Id,"Status") + ": " + status + ".";

    this.Views.ProfileDataInfoLabel.setValue(text);
};

TrafficManagementGeneratorViewController.prototype.SetProfileGeneratorsStatusesInfo = function()
{
    var _this = this;

    var profileData = this.GetProfilesTableSelectedData();

    if(profileData)
    {
        var profileStatus = profileData.task_status;

        var tabbar = $$(this.Id + "ProfileLogsStatsDataTabs");

        var index = 0;

        profileData.tragen_data.forEach(function (Tragen)
        {
            var tragenStatus = Tragen.task_status;

            var viewId = _this.Id + "ProfileLogsStatsDataTabs_" + Tragen.tt_id + "_InfoLabel";

            var generationStatus = TragenTaskGenerationStatusesEnum.new;

            generationStatus = tragenStatus == TragenTaskStatusEnum.progress ? TragenTaskGenerationStatusesEnum.initialization : generationStatus;

            generationStatus = tragenStatus == TragenTaskStatusEnum.stop ? TragenTaskGenerationStatusesEnum.stop : generationStatus;

            generationStatus = tragenStatus == TragenTaskStatusEnum.error ? TragenTaskGenerationStatusesEnum.error : generationStatus;

            generationStatus = tragenStatus == TragenTaskStatusEnum.stop && profileStatus == TragenTaskStatusEnum.progress ? TragenTaskGenerationStatusesEnum.error : generationStatus;

            var stats = _this.ViewModel.GetProfileStats(profileData);

            generationStatus = tragenStatus == TragenTaskStatusEnum.progress && stats && stats.stats && stats.stats[index] ? TragenTaskGenerationStatusesEnum.generation : generationStatus;

            var color = "orange";

            color = generationStatus == TragenTaskGenerationStatusesEnum.generation ? "green" : color;

            color = generationStatus == TragenTaskGenerationStatusesEnum.error ? "red" : color;

            var progress = generationStatus == TragenTaskGenerationStatusesEnum.initialization || generationStatus == TragenTaskGenerationStatusesEnum.generation;

            var text = "";

            if(progress)
                text += '<div class="spinner '+color+'"><div class="bounce1"></div><div class="bounce2"></div> <div class="bounce3"></div> </div>';

            text += "<span class=\"status status_" + color + "\">" + TragenTaskGenerationStatusesDic[generationStatus].value + "</span>";

            var logs = _this.ViewModel.GetProfileLogs(profileData);

            if(logs && logs.logs && logs.logs[index] && logs.logs[index].content)
            {
                var lines = logs.logs[index].content.split("\n");

                var last = lines.length - 2;

                if(lines[last].replace(/\[.*\]/, '') == " ")
                {
                    last = lines.length - 3;
                }

                if(last >= 0)
                    text += lines[last].replace(/\[.*\]/, '');
            }

            $$(viewId).setValue(text);

            if(tabbar&&tabbar.config.options[index])
            {
                if(generationStatus == TragenTaskGenerationStatusesEnum.error)
                {
                    tabbar.config.options[index].value = "<span class=\"status status_" + color + "\">" + tabbar.config.options[index].title + "</span>";
                }
                else
                {
                    tabbar.config.options[index].value = tabbar.config.options[index].title;
                }

            }

            index++;
        });

        if(tabbar)
        {
            tabbar.render();
        }
    }
};

TrafficManagementGeneratorViewController.prototype.CheckHealth = function()
{
    this.Health.Refresh({tragen: 1});
};

TrafficManagementGeneratorViewController.prototype.HealthResult = function()
{
    var health = this.Health.GetHealth();

    if(health.tragen)
    {
        if(!health.tragen.success)
            webix.message({text:LangHelper.GetString("AppView","Traffic generation") + "<br/><br/>" + health.tragen.error.msg + "<br/><br/>" + LangHelper.GetString("Health","Verify services health"), type:"error", expire: 300000});
    }
};
function TrafficManagementCaptureViewController(Params)
{
    var _this = this;

    this.Id = "TrafficManagementCapture";

    this.ViewModel = new TrafficCaptureManager();

    this.Health = new SormHealthManager();

    this.TaskDeleteContext = webix.ui({
        view:"popup",
        width:300,
        height:180,
        body:{
            view:"form",
            borderless:true,
            elements:[
                {
                    template:LangHelper.GetString(this.Id,"Are you sure you want to delete the profile?")
                },
                {
                    cols:[
                        {},
                        {
                            view:"button",
                            label:LangHelper.GetString(this.Id,"Cancel"),
                            click:function () {
                                _this.TaskDeleteContext.hide();
                            }
                        },
                        {
                            view:"button",
                            type:"form",
                            label:LangHelper.GetString(this.Id,"Delete"),
                            click:function () {
                                _this.TaskDeleteContext.hide();

                                var selected = _this.GetTasksTableSelectedData();

                                if(selected)
                                    _this.DeleteTask(selected.id);
                            }
                        }
                    ]
                }
            ]
        }
    });

    this.FileDeleteContext = webix.ui({
        view:"popup",
        width:300,
        height:180,
        body:{
            view:"form",
            borderless:true,
            elements:[
                {
                    template:LangHelper.GetString(this.Id,"Are you sure you want to delete the file?")
                },
                {
                    cols:[
                        {},
                        {
                            view:"button",
                            label:LangHelper.GetString(this.Id,"Cancel"),
                            click:function () {
                                _this.FileDeleteContext.hide();
                            }
                        },
                        {
                            view:"button",
                            type:"form",
                            label:LangHelper.GetString(this.Id,"Delete"),
                            click:function () {
                                _this.FileDeleteContext.hide();

                                _this.DeleteFile();

                            }
                        }
                    ]
                }
            ]
        }
    });

    this.FilesDeleteContext = webix.ui({
        view:"popup",
        width:300,
        height:180,
        body:{
            view:"form",
            borderless:true,
            elements:[
                {
                    template:LangHelper.GetString(this.Id,"Are you sure you want to delete the selected files?")
                },
                {
                    cols:[
                        {},
                        {
                            view:"button",
                            label:LangHelper.GetString(this.Id,"Cancel"),
                            click:function () {
                                _this.FilesDeleteContext.hide();
                            }
                        },
                        {
                            view:"button",
                            type:"form",
                            label:LangHelper.GetString(this.Id,"Delete"),
                            click:function () {
                                _this.FilesDeleteContext.hide();

                                _this.DeleteSelectedFiles();
                            }
                        }
                    ]
                }
            ]
        }
    });

    this.FilesDecodeContext = webix.ui({
        view: "popup",
        width: 400,
        height: 320,
        body:{
            borderless: true,
            rows:[
                {
                    view: "toolbar",
                    height: 40,
                    elements:[
                        {},
                        {
                            view: "button",
                            type: "icon",
                            icon: "times",
                            width: 32,
                            click: function()
                            {
                                _this.FilesDecodeContext.hide();
                            }
                        }
                    ]
                },
                {
                    view: "form",
                    id: this.Id + "FilesDecodeContextForm",
                    borderless:true,
                    elements:[
                        {
                            id: this.Id + "FilesDecodeContextFormSelect",
                            view: "richselect",
                            name: "task_id",
                            value: "-1",
                            labelWidth: 120,
                            label: LangHelper.GetString(_this.Id, "Task"),
                            options:[]
                        },
                        {
                            view: "text",
                            name: "task_name",
                            label: LangHelper.GetString(_this.Id, "Task name"),
                            invalidMessage: LangHelper.GetString(_this.Id, "Field is required"),
                            labelWidth: 120,
                            validate: function(Val)
                            {
                                var isNew = _this.Views.FilesDecodeContextFormSelect.getValue() == "-1";

                                if(isNew)
                                {
                                    return Val;
                                }
                                return true;
                            }
                        },
                        {
                            view: "multiselect",
                            name: "decode_types",
                            label: LangHelper.GetString("TrafficDecode", "Decode types"),
                            labelPosition: "left",
                            options: Helper.DictToArr(TrafficDecodeTypesDic),
                            labelWidth:120,
                            invalidMessage: LangHelper.GetString("Dics",  "Field is required"),
                            value: function(){
                                var arr = [];

                                Object.keys(TrafficDecodeTypesDic).forEach(function(Key){
                                    arr.push(Key);
                                });

                                return arr.join(",");
                            }(),
                            validate: function(Val)
                            {
                                return !!Val;
                            }
                        },
                        {
                            cols:[
                                {},
                                {
                                    view:"button",
                                    label:LangHelper.GetString(this.Id,"Cancel"),
                                    click:function () {
                                        _this.FilesDecodeContext.hide();
                                    }
                                },
                                {
                                    view:"button",
                                    type:"form",
                                    label:LangHelper.GetString(this.Id,"Apply"),
                                    click:function () {
                                        if(_this.DecodeSelectedFiles())
                                        {
                                            _this.FilesDecodeContext.hide();
                                        }
                                    }
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    });

    this.CreateTaskContextFormConfig = {
        view:"popup",
        id:this.Id + "CreateTaskContext",
        width:480,
        height:480,
        modal:true,
        body:{
            id:this.Id + "CreateTaskContextForm",
            view: "form",
            borderless: true,
            margin: 0,
            padding: 8,
            elements: [
                BlockHeaderTemplate({title:LangHelper.GetString(this.Id,"Profile for traffic capture"), icon:"file"}),
                {
                    view: "text",
                    name: "title",
                    labelWidth:120,
                    label: LangHelper.GetString(this.Id,"Description"),
                    tooltip:LangHelper.GetString(this.Id,"Task title / description")
                },
                {
                    cols:[
                        {
                            view:"richselect",
                            name:"condition_type",
                            label: LangHelper.GetString(this.Id,"Condition"),
                            labelWidth:120,
                            value:"ipv4",
                            options:[
                                {id:ControlTaskFormConditionTypeEnum.ipv4, value:LangHelper.GetString(this.Id,"IPv4 address")},
                                //{id:ControlTaskFormConditionTypeEnum.ipv6, value:LangHelper.GetString(this.Id,"IPv6 address")},
                                {id:ControlTaskFormConditionTypeEnum.ipv4_subnet, value:LangHelper.GetString(this.Id,"IPv4 CIDR")},
                                // {id:ControlTaskFormConditionTypeEnum.range, value:LangHelper.GetString(this.Id,"Range")},
                                {id:ControlTaskFormConditionTypeEnum.login, value:LangHelper.GetString(this.Id,"Subscriber login")}
                            ],
                            gravity:2
                        },
                        {
                            view: "text",
                            name: "condition",
                            labelWidth:0,
                            invalidMessage: LangHelper.GetString(this.Id, "Incorrect"),
                            validate: function(Val)
                            {
                                var type = _this.Views.CreateTaskContextForm.elements.condition_type.getValue();

                                switch(type)
                                {
                                    case ControlTaskFormConditionTypeEnum.ipv4:
                                        return Val && Helper.ValidateIPv4(Val) ? true : false;
                                        break;

                                    case ControlTaskFormConditionTypeEnum.ipv6:
                                        return Val && Helper.ValidateIPv6(Val) ? true : false;
                                        break;

                                    case ControlTaskFormConditionTypeEnum.ipv4_subnet:
                                        return Val && Helper.ValidateIPv4OrCidr(Val) ? true : false;
                                        break;

                                    case ControlTaskFormConditionTypeEnum.range:
                                        return Val && Helper.IsIpRange(Val) ? true : false;
                                        break;

                                    case ControlTaskFormConditionTypeEnum.login:
                                        return Val ? true : false;
                                        break;
                                }

                                return false;
                            }
                        }
                    ]
                },
                // {
                //     view: "checkbox",
                //     name: "is_raw_data_sending",
                //     labelRight: LangHelper.GetString(this.Id,"Raw packages"),
                //     tooltip:LangHelper.GetString(this.Id,"Send raw packages without analysis"),
                //     value:0,
                //     labelWidth:160
                // },
                // {
                //     view: "checkbox",
                //     name: "is_channel_layer_data_sending",
                //     labelRight: LangHelper.GetString(this.Id,"Channel data"),
                //     tooltip:LangHelper.GetString(this.Id,"Send ethernet frames or IP packets (only if the option Raw packages enabled)"),
                //     value:0,
                //     labelWidth:160
                // },
                // {
                //     view: "checkbox",
                //     name: "is_traffic_control_disabled",
                //     labelRight: LangHelper.GetString(this.Id,"Disable traffic control"),
                //     tooltip:LangHelper.GetString(this.Id,"Disable traffic control"),
                //     value:0,
                //     labelWidth:160
                // },
                // {
                //     view: "checkbox",
                //     name: "is_buffer_analyzing",
                //     labelRight: LangHelper.GetString(this.Id,"Enable buffer analyzer"),
                //     tooltip:LangHelper.GetString(this.Id,"Enable buffer analyzer"),
                //     value:0,
                //     labelWidth:160
                // },
                {
                    height:16
                },
                {
                    cols:[
                        {},
                        {
                            view:"button",
                            label:LangHelper.GetString(this.Id,"Cancel"),
                            click:function ()
                            {
                                _this.Views.CreateTaskContext.hide();
                            }
                        },
                        {
                            view:"button",
                            type:"form",
                            label:LangHelper.GetString(this.Id,"Save"),
                            click:function ()
                            {
                                _this.SaveTask();
                            }
                        }
                    ]
                }
            ]
        }
    };

    this.OnFileDecodeSuccessContext = webix.ui({
        view:"popup",
        width:480,
        height:180,
        modal: true,
        left: (window.innerWidth / 2 ) - 240,
        top: (window.innerHeight / 2) - 90,
        body:{
            view:"form",
            borderless:true,
            elements:[
                {
                    borderless:true,
                    template:LangHelper.GetString(_this.Id,"Tasks for pcap decoding successfully created!")
                },
                {
                    borderless:true,
                    template:LangHelper.GetString(_this.Id,"Do you want to open Traffic decode section?")
                },
                {
                    cols:[
                        {},
                        {
                            view:"button",
                            label:LangHelper.GetString(this.Id,"Cancel"),
                            click:function () {
                                _this.OnFileDecodeSuccessContext.hide();
                            }
                        },
                        {
                            view:"button",
                            type:"form",
                            label:LangHelper.GetString(this.Id,"Open"),
                            click:function () {
                                _this.OnFileDecodeSuccessContext.hide();

                                App.NavRouter.Navigate("TrafficDecode");
                            }
                        }
                    ]
                }
            ]
        }
    });

    this.ViewConfig = [
        {
            cols:[
                SormSideBarView({id: this.Id}),
                {
                    type: "space",
                    rows: [
                        {
                            type: "clear",
                            borderless: true,
                            rows: [
                                PageHeaderTemplate({root_title: LangHelper.GetString("AppView","Lawful interception"), title: LangHelper.GetString("AppView","Traffic capture")}),
                                {
                                    view:"accordion",
                                    multi:"mixed",
                                    cols:[
                                        {
                                            header:LangHelper.GetString(this.Id,"Profiles"),
                                            body:{
                                                rows:[
                                                    {
                                                        view: "toolbar",
                                                        height: 32,
                                                        padding: 0,
                                                        margin: 0,
                                                        elements: [
                                                            {
                                                                view: "button",
                                                                id:this.Id + "AddTaskBtn",
                                                                type: "icon",
                                                                icon: "plus",
                                                                align: "right",
                                                                width: 32,
                                                                css:"toolbar_round_btn",
                                                                tooltip:LangHelper.GetString(this.Id,"Add profile"),
                                                                click: function (id, e, node)
                                                                {
                                                                    _this.AddTaskToTasksTable();
                                                                }
                                                            },
                                                            {
                                                                view: "button",
                                                                id:this.Id + "AddSubTaskBtn",
                                                                type: "icon",
                                                                icon: "plus-square",
                                                                align: "right",
                                                                width: 32,
                                                                css:"toolbar_round_btn",
                                                                tooltip:LangHelper.GetString(this.Id,"Add subprofile"),
                                                                click: function (id, e, node)
                                                                {
                                                                    _this.AddSubTaskToTasksTable();
                                                                }
                                                            },
                                                            {
                                                                width:16
                                                            },
                                                            {
                                                                view: "button",
                                                                id:this.Id + "PlayBtn",
                                                                type: "icon",
                                                                icon: "play-circle",
                                                                width: 32,
                                                                css:"toolbar_round_btn",
                                                                tooltip:LangHelper.GetString(this.Id,"Start capturing"),
                                                                click: function (id, e, node)
                                                                {
                                                                    _this.EnableTask();
                                                                }
                                                            },
                                                            {
                                                                view: "button",
                                                                id:this.Id + "StopBtn",
                                                                type: "icon",
                                                                icon: "stop-circle",
                                                                width: 32,
                                                                css:"toolbar_round_btn",
                                                                tooltip:LangHelper.GetString(this.Id,"Stop capturing"),
                                                                click: function (id, e, node)
                                                                {
                                                                    _this.DisableTask();
                                                                }
                                                            },
                                                            {},
                                                            {
                                                                view: "button",
                                                                id:this.Id+"TasksRefreshBtn",
                                                                type: "icon",
                                                                icon: "refresh",
                                                                css: "toolbar_round_btn",
                                                                width: 32,
                                                                tooltip: LangHelper.GetString("All","Refresh"),
                                                                click: function (id, e, node)
                                                                {
                                                                    _this.RefreshTasksTable(0);
                                                                }
                                                            }
                                                        ]
                                                    },
                                                     BlockHeaderTemplate({title:LangHelper.GetString(this.Id,"List of profiles for traffic capture"), icon:"file"}),
                                                    {
                                                        view: "treetable",
                                                        id: this.Id + "TasksTable",
                                                        select: "row",
                                                        editable: true,
                                                        editaction:"custom",
                                                        resizeColumn: true,
                                                        tooltip:true,
                                                        form:this.Id + "CreateTaskContextForm",
                                                        on: {
                                                            onItemClick: function (id,e,node)
                                                            {
                                                                var rowData = this.getItem(id);

                                                                if(id.column === 'run')
                                                                {
                                                                    var data = _this.Views.TasksTable.getItem(id);

                                                                    if(!data.control_id)
                                                                    {
                                                                        return;
                                                                    }

                                                                    if(data.status != ControlTaskStatusEnum.on && data.download)
                                                                    {
                                                                        return;
                                                                    }

                                                                    if(data.status == ControlTaskStatusEnum.on)
                                                                    {
                                                                        setTimeout(function ()
                                                                        {
                                                                            _this.DisableTask();
                                                                        }, 10);
                                                                    }
                                                                    else
                                                                    {
                                                                        setTimeout(function ()
                                                                        {
                                                                            _this.EnableTask();
                                                                        }, 10);
                                                                    }
                                                                }

                                                                else if (id.column === 'edit')
                                                                {
                                                                    _this.ShowTasksEditForm(rowData, this.getItemNode(id), "right");
                                                                }

                                                                else if(id.column === 'trash')
                                                                {
                                                                    var __this = this;

                                                                    var data = _this.Views.TasksTable.getItem(id);

                                                                    if(!data.control_id)
                                                                    {
                                                                        _this.DeleteTask(id);
                                                                        return;
                                                                    }

                                                                    setTimeout(function () {
                                                                        var node = __this.getItemNode(id);
                                                                        _this.TaskDeleteContext.show(node, {pos:"bottom"});
                                                                    }, 10);
                                                                }
                                                            },
                                                            onBeforeFilter:function()
                                                            {
                                                                return false;
                                                            },
                                                            onAfterFilter:function()
                                                            {
                                                                _this.RefreshTasksTable();
                                                            },
                                                            onBeforeSort:function()
                                                            {
                                                                _this.RefreshTasksTable();
                                                                return false;
                                                            },
                                                            onAfterSelect:function (data)
                                                            {
                                                                var item = this.getItem(data.id);
                                                                if(item)
                                                                {
                                                                    if(!item.checked)
                                                                    {
                                                                        item.checked = 1;
                                                                    }
                                                                    _this.Views.TasksTable.updateItem(data.id, item);
                                                                }
                                                                _this.OnTasksTableRowSelect();
                                                            },
                                                            onAfterUnSelect:function (data)
                                                            {
                                                                var item = this.getItem(data.id);
                                                                if(item && item.checked)
                                                                {
                                                                    if(item.checked)
                                                                    {
                                                                        item.checked = 0;
                                                                    }
                                                                    _this.Views.TasksTable.updateItem(data.id, item);
                                                                }
                                                                _this.OnTasksTableRowUnSelect();
                                                            },
                                                            onHeaderClick:function(id, e, target)
                                                            {
                                                                if(id.column == "checked")
                                                                {
                                                                    var cb = _this.Views.TasksTable.getHeaderContent("mc");
                                                                    if(cb.isChecked())
                                                                    {
                                                                        _this.Views.TasksTable.selectAll();
                                                                    }
                                                                    else{
                                                                        _this.Views.TasksTable.unselectAll();
                                                                    }
                                                                }
                                                                return false;
                                                            }
                                                        },
                                                        columns: [
                                                            {
                                                                id:"checked",
                                                                header:[{content:"masterCheckbox", css:"hcell_ckeckbox", contentId:"mc"},""],
                                                                template: CellColoredCheckBoxTemplate,
                                                                width:32
                                                            },
                                                            {
                                                                id: "run",
                                                                header: "",
                                                                width: 32,
                                                                template: function (obj, common)
                                                                {
                                                                    if(obj.status == ControlTaskStatusEnum.on && obj.control_id)
                                                                    {
                                                                        return CellStopBtnTemplate();
                                                                    }

                                                                    if(obj.control_id)
                                                                    {
                                                                        return CellPlayBtnTemplate();
                                                                    }

                                                                    return "";
                                                                },
                                                                headermenu: false
                                                            },
                                                            {
                                                                id: "edit",
                                                                header: "",
                                                                width: 32,
                                                                template: CellEditBtnTemplate,
                                                                headermenu: false
                                                            },
                                                            {
                                                                id: "title",
                                                                header: [LangHelper.GetString(this.Id,"Profile"), {content: "textFilter"}],
                                                                template:function(obj, common, value, config)
                                                                {
                                                                    return common.treetable(obj, common, value, config) + (obj.title?obj.title:LangHelper.GetString(_this.Id, "Profile") + " "+obj.control_id);
                                                                },
                                                                fillspace: true,
                                                                sort: "string"
                                                            },
                                                            {
                                                                id: "date",
                                                                header: [LangHelper.GetString(this.Id,"Date"), {content: "dateRangeFilter"}],
                                                                //format: webix.Date.dateToStr("%d.%m.%Y"),
                                                                template:function(obj, common){
                                                                    if(obj.date)
                                                                    {
                                                                        var format =  webix.Date.dateToStr("%d.%m.%Y %H:%i");
                                                                        return format(obj.date);
                                                                    }

                                                                    return "";
                                                                },
                                                                fillspace: true,
                                                                sort: "date"
                                                            },
                                                            {
                                                                id: "user_id",
                                                                header: [LangHelper.GetString(this.Id,"User"), {
                                                                    content: "multiSelectFilter",
                                                                    options: App.DicsManager.GetDicAsArray(DicsEnum.UsersDic),
                                                                    inputConfig: {suggest: {fitMaster: false}}
                                                                }],
                                                                fillspace: true,
                                                                template: function (obj)
                                                                {
                                                                    if(App.DicsManager.GetDic("UsersDic")[obj.user_id])
                                                                        return App.DicsManager.GetDic("UsersDic")[obj.user_id].value;
                                                                    else
                                                                        return "";
                                                                },
                                                                sort: "string"
                                                            },
                                                            {
                                                                id: "status",
                                                                header: [LangHelper.GetString(this.Id,"Status"), {
                                                                    content: "richSelectFilter",
                                                                    options: Helper.DictToArr(ControlTaskStatusesDic),
                                                                    inputConfig: {suggest: {fitMaster: false}}
                                                                }],
                                                                template: function (obj, common)
                                                                {
                                                                    var statusStr = "";

                                                                    if(ControlTaskStatusesDic[obj.status])
                                                                    {
                                                                        statusStr += "<span class=\"status status_" + obj.status + "\">" + ControlTaskStatusesDic[obj.status].value + "</span>";
                                                                    }

                                                                    return statusStr;
                                                                },
                                                                fillspace: true,
                                                                sort: "string"
                                                            },
                                                            {
                                                                id: "trash",
                                                                header: "",
                                                                width: 32,
                                                                template: CellTrashBtnTemplate,
                                                                headermenu: false
                                                            }
                                                        ],
                                                        data:[]
                                                    },
                                                    {
                                                        view: "toolbar",
                                                        padding: 0,
                                                        margin: 0,
                                                        height:32,
                                                        elements: [
                                                            {
                                                                view: "pager",
                                                                gravity:3,
                                                                id: this.Id + "TasksTable" + "Paging",
                                                                template: "{common.first()} {common.prev()} {common.pages()} {common.next()} {common.last()}",
                                                                master:false,
                                                                on: {
                                                                    onAfterPageChange:function(Page)
                                                                    {
                                                                        _this.RefreshTasksTable(Page);
                                                                    }
                                                                }
                                                            },
                                                            {
                                                                view:"richselect",
                                                                id: this.Id + "TasksTable" + "Paging" + "Size",
                                                                label:LangHelper.GetString("All", "On page"),
                                                                labelWidth:120,
                                                                minWidth:220,
                                                                value:100,
                                                                options:PagingSizeOptions,
                                                                on: {
                                                                    onChange:function()
                                                                    {
                                                                        _this.RefreshTasksTable();
                                                                    }
                                                                }
                                                            }
                                                        ]
                                                    }
                                                ]
                                            }
                                        },
                                        {view:"resizer"},
                                        {
                                            header:LangHelper.GetString(this.Id,"Pcap files"),
                                            gravity:2,
                                            body:{
                                                cols:[
                                                    {
                                                        rows:[
                                                            {
                                                                view: "toolbar",
                                                                height: 32,
                                                                padding: 0,
                                                                margin: 0,
                                                                elements: [
                                                                    {
                                                                        view: "button",
                                                                        id:this.Id+"DecodeFilesBtn",
                                                                        type: "icon",
                                                                        icon: "file-code",
                                                                        css:"toolbar_round_btn",
                                                                        width:32,
                                                                        tooltip:LangHelper.GetString(this.Id, "Decode files"),
                                                                        disabled:true,
                                                                        click:function(id, e, node)
                                                                        {
                                                                            var __this = this;
                                                                            setTimeout(function ()
                                                                            {
                                                                                var node = __this.getNode(id);
                                                                                _this.FilesDecodeContext.show(node, {pos:"bottom"});
                                                                            }, 10);
                                                                        }
                                                                    },
                                                                    {
                                                                        view: "button",
                                                                        id:this.Id+"DelFilesBtn",
                                                                        type: "icon",
                                                                        icon: "trash",
                                                                        css:"toolbar_round_btn",
                                                                        width:32,
                                                                        tooltip:LangHelper.GetString(this.Id, "Delete"),
                                                                        disabled:true,
                                                                        click:function (id,e,node)
                                                                        {
                                                                            var __this = this;
                                                                            setTimeout(function ()
                                                                            {
                                                                                var node = __this.getNode(id);
                                                                                _this.FilesDeleteContext.show(node, {pos:"bottom"});
                                                                            }, 10);
                                                                        }
                                                                    },
                                                                    {},
                                                                    {
                                                                        view: "button",
                                                                        id:this.Id+"PcapFilesRefreshBtn",
                                                                        type: "icon",
                                                                        icon: "refresh",
                                                                        css: "toolbar_round_btn",
                                                                        width: 32,
                                                                        tooltip: LangHelper.GetString("All","Refresh"),
                                                                        click: function (id, e, node)
                                                                        {
                                                                            _this.RefreshPcapFilesTable();
                                                                        }
                                                                    }
                                                                ]
                                                            },
                                                            BlockHeaderTemplate({title: LangHelper.GetString(this.Id,"Pcap files"), icon: "files-o"}),
                                                            {
                                                                view:"datatable",
                                                                id:this.Id + "PcapFilesPanel" + "FilesTable",
                                                                select: "row",
                                                                multiselect:true,
                                                                columns: [
                                                                    {
                                                                        id:"checked",
                                                                        header:[{content:"masterCheckbox", css:"hcell_ckeckbox", contentId:"mc"},""],
                                                                        template: CellColoredCheckBoxTemplate,
                                                                        width:32
                                                                    },
                                                                    {
                                                                        id: "download",
                                                                        header: "",
                                                                        width: 32,
                                                                        template: CellDownloadBtnTemplate,
                                                                        headermenu: false
                                                                    },
                                                                    {
                                                                        id: "decode",
                                                                        header: "",
                                                                        width: 32,
                                                                        template: CellFileCodeBtnTemplate,
                                                                        headermenu: false
                                                                    },
                                                                    {
                                                                        id: "file_id",
                                                                        header: [LangHelper.GetString(this.Id,"File"), {content: "textFilter"}],
                                                                        fillspace: 3,
                                                                        sort: "string"
                                                                    },
                                                                    {
                                                                        id: "size",
                                                                        header: [LangHelper.GetString(this.Id,"Size"), {content: "textFilter"}],
                                                                        fillspace: true,
                                                                        sort: "string",
                                                                        template:function(obj, common)
                                                                        {
                                                                            return Helper.BytesToSize(obj.size);
                                                                        }
                                                                    },
                                                                    {
                                                                        id: "date",
                                                                        header: [TooltipTemplate(LangHelper.GetString(this.Id, "Date")), {content: "dateRangeFilter"}],
                                                                        template:function(obj, common)
                                                                        {
                                                                            if(obj.date)
                                                                            {
                                                                                var format =  webix.Date.dateToStr("%d.%m.%Y %H:%i");
                                                                                return format(obj.date);
                                                                            }

                                                                            return "";
                                                                        },
                                                                        fillspace: true,
                                                                        sort: "date"
                                                                    },
                                                                    {
                                                                        id: "trash",
                                                                        header: "",
                                                                        width: 32,
                                                                        template: CellTrashBtnTemplate,
                                                                        headermenu: false
                                                                    }
                                                                ],
                                                                on:{
                                                                    onItemClick: function (id,e,node)
                                                                    {
                                                                        var rowData = this.getItem(id);

                                                                        if (id.column === 'download')
                                                                        {
                                                                            this.select(id); //reset if multiple rows selected

                                                                            _this.DownloadPcapFile(rowData);
                                                                        }
                                                                        else if(id.column === 'trash')
                                                                        {
                                                                            var __this = this;

                                                                            this.select(id); //reset if multiple rows selected

                                                                            setTimeout(function () {
                                                                                var node = __this.getItemNode(id);
                                                                                _this.FileDeleteContext.show(node, {pos:"bottom"});
                                                                            }, 10);
                                                                        }
                                                                        else if(id.column === 'decode')
                                                                        {
                                                                            var __this = this;

                                                                            this.select(id); //reset if multiple rows selected

                                                                            setTimeout(function () {
                                                                                var node = __this.getItemNode(id);
                                                                                _this.FilesDecodeContext.show(node, {pos:"bottom"});
                                                                            }, 10);
                                                                        }
                                                                    },
                                                                    onAfterLoad:function()
                                                                    {
                                                                        this.sort("date", "desc", "date");
                                                                        this.markSorting("date", "desc");
                                                                    },
                                                                    onAfterSelect:function (data)
                                                                    {
                                                                        var item = this.getItem(data.id);
                                                                        if(item)
                                                                        {
                                                                            if(!item.checked)
                                                                            {
                                                                                item.checked = 1;
                                                                            }
                                                                            _this.Views.FilesTable.updateItem(data.id, item);
                                                                        }
                                                                        _this.OnFilesTableRowSelect();
                                                                    },
                                                                    onAfterUnSelect:function (data)
                                                                    {
                                                                        var item = this.getItem(data.id);
                                                                        if(item && item.checked)
                                                                        {
                                                                            if(item.checked)
                                                                            {
                                                                                item.checked = 0;
                                                                            }
                                                                            _this.Views.FilesTable.updateItem(data.id, item);
                                                                        }
                                                                        _this.OnFilesTableRowUnSelect();
                                                                    },
                                                                    onHeaderClick:function(id, e, target)
                                                                    {
                                                                        if(id.column == "checked")
                                                                        {
                                                                            var cb = _this.Views.FilesTable.getHeaderContent("mc");
                                                                            if(cb.isChecked())
                                                                            {
                                                                                _this.Views.FilesTable.selectAll();
                                                                            }
                                                                            else{
                                                                                _this.Views.FilesTable.unselectAll();
                                                                            }
                                                                        }
                                                                        return false;
                                                                    }

                                                                },
                                                                data:[]
                                                            }
                                                        ]
                                                    },
                                                    {view:"resizer"},
                                                    {
                                                        //gravity:2,
                                                        id:this.Id + "PcapFilesPanel" + "FileContentPanel",
                                                        rows:[
                                                            {
                                                                view: "toolbar",
                                                                height: 32,
                                                                padding: 0,
                                                                margin: 0,
                                                                elements: [
                                                                    {
                                                                        view: "button",
                                                                        id:this.Id+"PcapFileDownloadBtn",
                                                                        type: "icon",
                                                                        icon: "download",
                                                                        css: "toolbar_round_btn",
                                                                        width: 32,
                                                                        tooltip: LangHelper.GetString(this.Id,"Download pcap file completely"),
                                                                        click: function (id, e, node)
                                                                        {
                                                                            _this.DownloadPcapFile();
                                                                        }
                                                                    },
                                                                    {},
                                                                    {
                                                                        view: "button",
                                                                        id:this.Id+"PcapFileContentRefreshBtn",
                                                                        type: "icon",
                                                                        icon: "refresh",
                                                                        css: "toolbar_round_btn",
                                                                        width: 32,
                                                                        tooltip: LangHelper.GetString("All","Refresh"),
                                                                        click: function (id, e, node)
                                                                        {
                                                                            _this.RefreshSelectedFileContent();
                                                                        }
                                                                    }
                                                                ]
                                                            },
                                                            BlockHeaderTemplate({title: LangHelper.GetString(this.Id,"Content") +" ("+LangHelper.GetString(this.Id,"firts packets")+")", icon: "file-text"}),
                                                            // {
                                                            //     view:"codemirror-editor",
                                                            //     id:this.Id + "PcapFilesPanel" + "FileContent",
                                                            //     value:""
                                                            // },
                                                            {
                                                                view:"datatable",
                                                                id:this.Id + "PcapFilesPanel" + "FileContentTable",
                                                                select: "row",
                                                                columns: [
                                                                    {
                                                                        id: "time",
                                                                        header: [LangHelper.GetString(this.Id,"Time"), {content: "textFilter"}],
                                                                        fillspace: 1,
                                                                        sort: "string"
                                                                    },
                                                                    {
                                                                        id: "source",
                                                                        header: [LangHelper.GetString(this.Id,"Source"), {content: "textFilter"}],
                                                                        fillspace: 1,
                                                                        sort: "string"
                                                                    },
                                                                    {
                                                                        id: "destination",
                                                                        header: [LangHelper.GetString(this.Id,"Destination"), {content: "textFilter"}],
                                                                        fillspace: 1,
                                                                        sort: "string"
                                                                    },
                                                                    {
                                                                        id: "info",
                                                                        header: [LangHelper.GetString(this.Id,"Info"), {content: "textFilter"}],
                                                                        fillspace: 3,
                                                                        sort: "string"
                                                                    }
                                                                ],
                                                                on:{
                                                                    onAfterLoad:function()
                                                                    {
                                                                        this.sort("time", "desc", "string");
                                                                        this.markSorting("time", "desc");
                                                                    }
                                                                },
                                                                data:[]
                                                            }
                                                        ]
                                                    }
                                                ]
                                            }
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    ];

    this.Views = {
        AddTaskBtn: null,
        AddSubTaskBtn: null,

        PlayBtn: null,
        StopBtn: null,

        TasksTable:null,
        TasksTablePaging:null,
        TasksTablePagingSize:null,

        CreateTaskContext:null,
        CreateTaskContextForm:null,

        FilesTable:null,
        //FileContent:null,
        FileContentTable:null,
        FileContentPanel:null,
        DelFilesBtn:null,
        DecodeFilesBtn: null,
        FilesDecodeContextFormSelect: null,
        FilesDecodeContextForm: null
    };

    this.Subscriptions = {};

    this.TasksTableRowsSelectedCount = new Rx.BehaviorSubject(0);
    this.FilesTableRowsSelectedCount = new Rx.BehaviorSubject(0);
};

TrafficManagementCaptureViewController.prototype.Destroy = function ()
{
    var _this = this;

    Object.keys(this.Subscriptions).forEach(function (key)
    {
        _this.Subscriptions[key].unsubscribe();
    });

    this.ViewModel.Destroy();
    delete this.ViewModel;

    this.Health.Destroy();
    delete this.Health;
};

TrafficManagementCaptureViewController.prototype.ViewDidLoad = function (Params)
{
    var _this = this;

    webix.ready(function () {
        $$(_this.Id + "LeftSideBar").select(_this.Id);
    });

    this.BindAll();

    this.CheckHealth();
};

TrafficManagementCaptureViewController.prototype.BindAll = function (Params)
{
    var _this = this;

    this.Views.AddTaskBtn = $$(this.Id + "AddTaskBtn");
    this.Views.AddSubTaskBtn = $$(this.Id + "AddSubTaskBtn");

    this.Views.PlayBtn = $$(this.Id + "PlayBtn");
    this.Views.StopBtn = $$(this.Id + "StopBtn");

    this.Views.TasksTable = $$(this.Id + "TasksTable");
    this.Views.TasksTablePaging = $$(this.Id + "TasksTable" + "Paging");
    this.Views.TasksTablePagingSize = $$(this.Id + "TasksTable" + "Paging" + "Size");

    this.Views.CreateTaskContext = webix.ui(this.CreateTaskContextFormConfig);
    this.Views.CreateTaskContextForm = $$(this.Id+"CreateTaskContextForm");

    this.Views.FilesTable = $$(this.Id + "PcapFilesPanel" + "FilesTable");
    //this.Views.FileContent = $$(this.Id + "PcapFilesPanel" + "FileContent");
    this.Views.FileContentTable = $$(this.Id + "PcapFilesPanel" + "FileContentTable");
    this.Views.FileContentPanel = $$(this.Id + "PcapFilesPanel" + "FileContentPanel");

    this.Views.DelFilesBtn = $$(this.Id + "DelFilesBtn");
    this.Views.DecodeFilesBtn = $$(this.Id + "DecodeFilesBtn");
    this.Views.FilesDecodeContextFormSelect = $$(this.Id + "FilesDecodeContextFormSelect");
    this.Views.FilesDecodeContextForm = $$(this.Id + "FilesDecodeContextForm");

    this.Subscriptions.SignalDicUpdatedSubscription = App.DicsManager.Signal[DicsEnum.UsersDic].subscribe(function (Updated)
    {
        if (Updated)
        {
            _this.RefreshTasksTable(0);
            _this.RefreshPcapFilesTable();
        }
    });

    this.Subscriptions.SignalTasksUpdatedSubscription = this.ViewModel.SignalTasksUpdated.subscribe(function (Updated)
    {
        if (Updated)
        {
            _this.UpdateTasksTable();
        }
    });

    this.Subscriptions.TasksTableRowsSelectedCountSubscription = this.TasksTableRowsSelectedCount.subscribe(function (Value)
    {
        _this.TasksTableRowsSelectedCountSubscriptionHandler(Value);
    });

    this.Subscriptions.SignalTaskUpdatedSubscription = this.ViewModel.SignalTaskUpdated.subscribe(function (Task)
    {
        if(Task)
        {
            var selectedData = _this.GetTasksTableSelectedData();

            var rows = _this.Views.TasksTable.find(function(obj)
            {
                return obj.control_id == Task.control_id;
            });

            if(rows && rows.length == 1)
            {
                _this.Views.TasksTable.updateItem(rows[0].id,  Task);

                if(selectedData && selectedData.control_id == Task.control_id && Task.status != 0)
                {
                    _this.OnTasksTableRowSelect();
                }
            }

        }
    });

    this.Subscriptions.SignalPcapFilesListUpdatedSubscription = this.ViewModel.SignalPcapFilesListUpdated.subscribe(function (Updated)
    {
        if (Updated)
        {
            _this.UpdatePcapFilesTable();
        }
    });

    this.Subscriptions.SignalStatLogTailUpdatedSubscription = this.ViewModel.SignalStatLogTailUpdated.subscribe(function (Updated)
    {
        if (Updated)
        {
            //_this.UpdatePcapFileContent();
            _this.UpdatePcapFileTableContent();
        }
    });

    this.Subscriptions.FilesTableRowsSelectedCountSubscription = this.FilesTableRowsSelectedCount.subscribe(function (Value)
    {
        _this.FilesTableRowsSelectedCountSubscriptionHandler(Value);
    });

    this.Subscriptions.SignalHealthUpdatedSubscription = this.Health.HealthUpdated.subscribe(function (Updated)
    {
        if (Updated)
        {
            _this.HealthResult();
        }
    });

    this.Subscriptions.SignalTrafficDecodeTasksDicSubscription = App.DicsManager.Signal[DicsEnum.SormTrafficDecodeTasksDic].subscribe(function(Val){
       if(Val)
       {
           _this.Views.FilesDecodeContextFormSelect.getList().clearAll();
           var arr = [
               {id: "-1", value: LangHelper.GetString(_this.Id, "New task")}
           ];

           arr = arr.concat(App.DicsManager.GetDicAsArray(DicsEnum.SormTrafficDecodeTasksDic));
           _this.Views.FilesDecodeContextFormSelect.getList().parse(arr);
       }
    });

    _this.Views.TasksTable.attachEvent("onCheck", function(id, column, state)
    {
        if(column == "checked")
        {
            if(state)
                _this.Views.TasksTable.select(id, true);
            else
                _this.Views.TasksTable.unselect(id, true);

        }
    });

    _this.Views.FilesTable.attachEvent("onCheck", function(id, column, state)
    {
        if(column == "checked")
        {
            if(state)
                _this.Views.FilesTable.select(id, true);
            else
                _this.Views.FilesTable.unselect(id, true);

        }
    });

    _this.Views.FilesDecodeContextFormSelect.attachEvent("onChange", function(newV, oldV){
        if(newV == "-1")
        {
            _this.Views.FilesDecodeContextForm.elements.task_name.show();
        }
        else{
            _this.Views.FilesDecodeContextForm.elements.task_name.hide();
        }
    });

    App.DicsManager.Refresh({data: [DicsEnum.SormTrafficDecodeTasksDic]});


};

TrafficManagementCaptureViewController.prototype.UpdateTasksTable = function (Params)
{
    var _this = this;

    var dic = App.DicsManager.GetDicAsArray(DicsEnum.UsersDic);

    this.Views.TasksTable.getColumnConfig("user_id").header[1].options = App.DicsManager.GetDicAsArray(DicsEnum.UsersDic);

    this.Views.TasksTable.clearAll();
    this.Views.TasksTable.parse(_this.ViewModel.GetTasks());

    this.Views.TasksTablePagingSize.setValue(this.ViewModel.TasksFilter.total.size);

    this.Views.TasksTablePaging.define(this.ViewModel.TasksFilter.total);
    this.Views.TasksTablePaging.refresh();
};

TrafficManagementCaptureViewController.prototype.RefreshTasksTable = function (Page)
{
    var _this = this;

    var state = this.Views.TasksTable.getState();

    var size = this.Views.TasksTablePagingSize.getValue();

    this.ViewModel.RefershTasks({page:Page, filter:state.filter, size:size, sort:state.sort ? state.sort:null, callback:function () {}})
};

TrafficManagementCaptureViewController.prototype.AddTaskToTasksTable = function ()
{
    var _this = this;

    var model = new TrafficCaptureTaskModel();

    _this.Views.TasksTable.add(model,0);

    _this.Views.TasksTable.select(model.id, false);
    _this.Views.TasksTable.showItem(model.id);

    _this.ShowTasksEditForm(model, _this.Views.TasksTable.getItemNode(model.id), "right");
};

TrafficManagementCaptureViewController.prototype.AddSubTaskToTasksTable = function ()
{
    var _this = this;

    var selectedModel = _this.GetTasksTableSelectedData();

    if(!selectedModel || !selectedModel.control_id)
        return;

    var model = new TrafficCaptureTaskModel({parent_id:selectedModel.control_id});

    _this.Views.TasksTable.add(model,0,selectedModel.id);
    _this.Views.TasksTable.open(selectedModel.id);

    _this.Views.TasksTable.select(model.id, false);
    _this.Views.TasksTable.showItem(model.id);

    _this.ShowTasksEditForm(model, _this.Views.TasksTable.getItemNode(model.id), "right");
};

TrafficManagementCaptureViewController.prototype.ClearForms = function ()
{
    this.Views.CreateTaskContextForm.clear();
    this.Views.CreateTaskContextForm.clearValidation();
};

TrafficManagementCaptureViewController.prototype.ShowTasksEditForm = function (Data, Node, Pos)
{
    var _this = this;

    this.ClearForms();

    if(Data && Data.form_data)
    {
        var formParams = new TrafficCaptureTaskFormModel(JSON.parse(Data.form_data));

        if(formParams)
        {
            this.Views.CreateTaskContextForm.setValues(new TrafficCaptureTaskFormModel(formParams));
        }
    }

    this.Views.CreateTaskContext.show(Node, {pos:Pos});
};

TrafficManagementCaptureViewController.prototype.GetTasksTableSelectedData = function ()
{
    return this.Views.TasksTable.getSelectedItem();
};

TrafficManagementCaptureViewController.prototype.OnTasksTableRowSelect = function ()
{
    var data = this.GetTasksTableSelectedData();

    this.TasksTableRowsSelectedCount.next(data?(Array.isArray(data)?data.length:1):0);
};

TrafficManagementCaptureViewController.prototype.OnTasksTableRowUnSelect = function ()
{
    var data = this.GetTasksTableSelectedData();
    this.TasksTableRowsSelectedCount.next(data?(Array.isArray(data)?data.length:1):0);
};

TrafficManagementCaptureViewController.prototype.ValidateForms = function ()
{
    return this.Views.CreateTaskContextForm.validate();
};

TrafficManagementCaptureViewController.prototype.SaveTask = function ()
{
    var _this = this;

    var taskData = this.GetTasksTableSelectedData();

    if(this.ValidateForms())
    {
        taskData.valid = true;

        this.Views.CreateTaskContext.hide();

        var formData = new TrafficCaptureTaskFormModel(this.Views.CreateTaskContextForm.getValues());

        this.ViewModel.SaveTask({task:taskData, task_form:formData, callback:function (R)
        {
            if(R.success && R.data && R.data.task)
            {
                _this.Views.TasksTable.updateItem(taskData.id,  new TrafficCaptureTaskModel(R.data.task));

                webix.message(LangHelper.GetString(this.Id,"Profile saved"));
            }
            else
            {
                taskData.valid = false;

                _this.Views.TasksTable.validate(taskData.id);

                if(R.error)
                    webix.message({text:R.error.msg,type:"error"});
            }

            _this.OnTasksTableRowSelect();
        }});

        this.ClearForms();
    }
    else
    {
        taskData.valid = false;
    }

    this.Views.TasksTable.validate(taskData.id);
};

TrafficManagementCaptureViewController.prototype.DeleteTask = function (RowId)
{
    var _this = this;

    var taskData = this.Views.TasksTable.getItem(RowId);
    taskData.valid = true;

    if(taskData.control_id > 0)
    {
        this.ViewModel.DeleteTask({task:taskData, callback:function (R)
        {
            if(R.success)
            {
                _this.Views.TasksTable.remove(RowId);

                webix.message(LangHelper.GetString("All","Deleted"));
            }
            else
            {
                taskData.valid = false;
                _this.Views.TasksTable.validate(RowId);
                if(R.error)
                    webix.message({text:R.error.msg,type:"error"});
            }
        }});
    }
    else
    {
        this.Views.TasksTable.remove(RowId);
    }
};

TrafficManagementCaptureViewController.prototype.TasksTableRowsSelectedCountSubscriptionHandler = function (Value, NoRefresh)
{
    var _this = this;

    var _disableAll = function ()
    {
        _this.Views.AddSubTaskBtn.disable();
        _this.Views.PlayBtn.disable();
        _this.Views.StopBtn.disable();
    };

    if(Value != 1)
    {
        _disableAll();
        return;
    }

    var selectedData = _this.GetTasksTableSelectedData();

    if(!selectedData)
    {
        _disableAll();
        return;
    }

    _this.Views.AddSubTaskBtn.enable();

    switch (parseInt(selectedData.status))
    {
        case ControlTaskStatusEnum.off:

            if(selectedData.control_id)
            {
                !selectedData.download ? _this.Views.PlayBtn.enable() : _this.Views.PlayBtn.disable();
            }
            else
            {
                _this.Views.PlayBtn.disable();
            }

            _this.Views.StopBtn.disable();

            break;

        case ControlTaskStatusEnum.on:
            _this.Views.PlayBtn.disable();
            _this.Views.StopBtn.enable();
            break;
        default:
            break;
    }
};

TrafficManagementCaptureViewController.prototype.EnableTask = function ()
{
    var _this = this;

    var taskData = this.GetTasksTableSelectedData();

    taskData.valid = true;

    this.ViewModel.EnableTask({task:taskData, callback:function (R)
    {
        if(R.success && R.data)
        {
            _this.Views.TasksTable.updateItem(taskData.id,  new TrafficCaptureTaskModel(R.data));

            if(R.data.status == ControlTaskStatusEnum.on)
            {
                webix.message(LangHelper.GetString(_this.Id,"Traffic capture enabled"));
            }
            else
            {
                webix.message({text:LangHelper.GetString(_this.Id,"Could not enable traffic capture"), type:"debug"});
            }
        }
        else
        {
            taskData.valid = false;

            _this.Views.TasksTable.validate(taskData.id);

            if(R.error)
                webix.message({text:R.error.msg,type:"error"});
        }

        _this.OnTasksTableRowSelect();
    }});
};

TrafficManagementCaptureViewController.prototype.DisableTask = function ()
{
    var _this = this;

    var taskData = this.GetTasksTableSelectedData();

    taskData.valid = true;

    this.ViewModel.DisableTask({task:taskData, callback:function (R)
    {
        if(R.success)
        {
            if(R.data)
            {
                _this.Views.TasksTable.updateItem(taskData.id,  new TrafficCaptureTaskModel(R.data));

                webix.message(LangHelper.GetString(_this.Id,"Traffic capture disabled"));
            }
            else
            {
                webix.message({text:LangHelper.GetString(_this.Id,"Could not disable traffic capture"), type:"debug"});
            }

        }
        else
        {
            taskData.valid = false;

            _this.Views.TasksTable.validate(taskData.id);

            if(R.error)
                webix.message({text:R.error.msg,type:"error"});
        }

        _this.OnTasksTableRowSelect();
    }});
};

TrafficManagementCaptureViewController.prototype.RefreshPcapFilesTable = function ()
{
    this.ViewModel.RefreshPcapFilesList();
};

TrafficManagementCaptureViewController.prototype.UpdatePcapFilesTable = function ()
{
    this.Views.FilesTable.clearAll();
    this.Views.FilesTable.parse(this.ViewModel.GetPcapFilesList());
};

TrafficManagementCaptureViewController.prototype.UpdatePcapFileContent = function ()
{
    var content = this.ViewModel.GetPcapFileTail();

    this.Views.FileContent.setValue(content);
};

TrafficManagementCaptureViewController.prototype.UpdatePcapFileTableContent = function ()
{
    var data = this.ViewModel.GetPcapFileTableTail();

    this.Views.FileContentTable.clearAll();
    this.Views.FileContentTable.parse(data);
};

TrafficManagementCaptureViewController.prototype.GetFilesTableSelectedData = function ()
{
    return this.Views.FilesTable.getSelectedItem();
};

TrafficManagementCaptureViewController.prototype.OnFilesTableRowSelect = function ()
{
    var data = this.GetFilesTableSelectedData();

    this.FilesTableRowsSelectedCount.next(data?(Array.isArray(data)?data.length:1):0);
};

TrafficManagementCaptureViewController.prototype.OnFilesTableRowUnSelect = function ()
{
    var data = this.GetFilesTableSelectedData();

    this.FilesTableRowsSelectedCount.next(data?(Array.isArray(data)?data.length:1):0);
};

TrafficManagementCaptureViewController.prototype.RefreshSelectedFileContent = function ()
{
    var selectedData = this.GetFilesTableSelectedData();

    if(!selectedData)
    {
        return;
    }

    this.ViewModel.RefreshPcapFileTail({file_id:selectedData.file_id});
};

TrafficManagementCaptureViewController.prototype.FilesTableRowsSelectedCountSubscriptionHandler = function (Value)
{
    var _this = this;

    if(Value)
    {
        _this.Views.DecodeFilesBtn.enable();
        if(Value > 1)
        {
            _this.Views.DelFilesBtn.enable();
        }
        else{
            _this.Views.DelFilesBtn.disable();
        }
    }else{
        _this.Views.DelFilesBtn.disable();
        _this.Views.DecodeFilesBtn.disable();
    }

    var _disableAll = function ()
    {
        _this.Views.FileContentPanel.disable();
    };

    if(Value != 1)
    {
        _disableAll();
        return;
    }

    this.RefreshSelectedFileContent();

    _this.Views.FileContentPanel.enable();
};

TrafficManagementCaptureViewController.prototype.DownloadPcapFile = function (Data)
{
    var selectedData = Data ? Data : this.GetFilesTableSelectedData();

    if(selectedData)
    {
        this.ViewModel.DownloadPcapFile(selectedData.file_id);
    }
};

TrafficManagementCaptureViewController.prototype.DeleteFile = function ()
{
    var _this = this;

    var selectedData = this.GetFilesTableSelectedData();

    if(!selectedData)
        return;

    this.ViewModel.DeleteFile({file_id:selectedData.file_id, callback:function (R)
    {
        if(R.success)
        {
            _this.Views.FilesTable.remove(selectedData.id);

            webix.message(LangHelper.GetString("All","Deleted"));
        }
        else
        {
            if(R.error)
                webix.message({text:R.error.msg,type:"error"});
        }
    }});
};

TrafficManagementCaptureViewController.prototype.CheckHealth = function()
{
    this.Health.Refresh({dpi_server: 1});
};

TrafficManagementCaptureViewController.prototype.HealthResult = function()
{
    var health = this.Health.GetHealth();

    if(health.dpi_server)
    {
        if(!health.dpi_server.success)
            webix.message({text:LangHelper.GetString("AppView","Traffic capture") + "<br/><br/>" + health.dpi_server.error.msg + "<br/><br/>" + LangHelper.GetString("Health","Verify services health"), type:"error", expire: 300000});
    }
};

TrafficManagementCaptureViewController.prototype.DeleteSelectedFiles = function (RowId)
{
    var _this = this;

    var selectedRows = this.GetFilesTableSelectedData();

    if(!selectedRows)
        return;

    selectedRows = Array.isArray(selectedRows)?selectedRows:[selectedRows];

    selectedRows.forEach(function (Row)
    {
        _this.ViewModel.DeleteFile({file_id:Row.file_id, callback:function (R)
        {
            if(R.success)
            {
                _this.Views.FilesTable.remove(Row.id);

                webix.message(LangHelper.GetString("All","Deleted"));
            }
            else
            {
                if(R.error)
                    webix.message({text:R.error.msg,type:"error"});
            }
        }});
    });
};

TrafficManagementCaptureViewController.prototype.DecodeSelectedFiles = function()
{
  var _this = this;

  if(this.Views.FilesDecodeContextForm.validate())
  {
      var data = this.Views.FilesDecodeContextForm.getValues();

      if(data.task_id == "-1" && !data.task_name)
      {
          return false;
      }

      var task = new TrafficDecodeTaskModel();
      if(data.task_id == "-1")
      {
          task.title = data.task_name;
          task.description = data.task_name;
      }
      else{
          task.task_id = data.task_id;
      }

      var files = [];
      var selected = this.GetFilesTableSelectedData();
      if(!selected)
      {
          return false;
      }
      else{
          if(Array.isArray(selected))
          {
              selected.forEach(function(V){
                  files.push(V.file_id);
              });
          }
          else{
              files.push(selected.file_id);
          }
      }

      this.ViewModel.DecodePcapFiles({
          task: task,
          files: files,
          decode_types: data.decode_types,
          callback: function(R)
          {
              if(R.success)
              {
                  _this.OnFileDecodeSuccessContext.show();
                  // webix.message(LangHelper.GetString(_this.Id,"Tasks for pcap decoding successfully created!"));
              }
              else if(R.error){
                  webix.message({text:R.error.msg,type:"error"});
              }
          }
      });

      return true;
  }
  else{
      return false;
  }
};

function TrafficManagementSiteCloneViewController(Params)
{
    var _this = this;

    this.Id = "TrafficManagementSiteClone";

    this.ViewModel = new WgetManager();

    this.Health = new SormHealthManager();

    this.CreateTaskContextFormConfig = {
        view:"popup",
        id:this.Id + "CreateTaskContext",
        width:880,
        maxHeight:560,
        modal:true,
        body:{
            rows:[
                BlockHeaderTemplate({title:LangHelper.GetString(this.Id,"The task for site downloading"), icon:"file"}),
                {
                    view:"accordion",
                    multi:"mixed",
                    cols:[
                        {
                            header:LangHelper.GetString(this.Id,"Main settings"),
                            gravity:2,
                            body:{
                                rows:[
                                    {
                                        id:this.Id + "CreateTaskContextForm",
                                        view: "form",
                                        borderless: true,
                                        margin: 0,
                                        padding: 8,
                                        elements: [
                                            {
                                                view: "text",
                                                name: "title",
                                                label: LangHelper.GetString(this.Id,"Description"),
                                                labelPosition: "top",
                                                tooltip:LangHelper.GetString(this.Id,"Task title / description")
                                            },
                                            {
                                                view: "textarea",
                                                name: "url",
                                                label: LangHelper.GetString(this.Id,"URLs"),
                                                labelPosition: "top",
                                                tooltip:LangHelper.GetString(this.Id,"URLs list to download: each URL from a new line.")
                                            },
                                            {
                                                cols:[
                                                    {
                                                        view: "text",
                                                        name: "v_host",
                                                        label: LangHelper.GetString(this.Id,"Virtual host"),
                                                        labelPosition: "top",
                                                        tooltip:LangHelper.GetString(this.Id,"Virtual host"),
                                                        gravity:2
                                                    },
                                                    {
                                                        view: "text",
                                                        name: "v_port",
                                                        label: LangHelper.GetString(this.Id,"Virtual port"),
                                                        labelPosition: "top",
                                                        tooltip:LangHelper.GetString(this.Id,"Virtual port")
                                                    }
                                                ]
                                            },
                                            {
                                                cols:[
                                                    {},
                                                    {
                                                        view:"button",
                                                        label:LangHelper.GetString(this.Id,"Cancel"),
                                                        click:function ()
                                                        {
                                                            _this.Views.CreateTaskContext.hide();
                                                        }
                                                    },
                                                    {
                                                        view:"button",
                                                        type:"form",
                                                        label:LangHelper.GetString(this.Id,"Save"),
                                                        click:function ()
                                                        {
                                                            _this.SaveTask();
                                                        }
                                                    }
                                                ]
                                            }
                                        ]
                                    }
                                ]
                            }
                        },
                        {view:"resizer"},
                        {
                            header:LangHelper.GetString(this.Id,"Extra options"),
                            //collapsed:true,
                            gravity:3,
                            body:{
                                view:"tabview",
                                id:this.Id + "CreateTaskContextFormTabs",
                                cells:[
                                    {
                                        header: LangHelper.GetString(this.Id,"Recursion"),
                                        id: this.Id + "CreateTaskContextFormTabsRecursive",
                                        body: {
                                            id: this.Id + "CreateTaskContextFormTabsRecursiveForm",
                                            view: "form",
                                            borderless: true,
                                            margin: 0,
                                            padding: 8,
                                            elements: [
                                                {
                                                    cols:[
                                                        {
                                                            rows:[
                                                                {
                                                                    view: "checkbox",
                                                                    name: "recursive",
                                                                    labelRight: LangHelper.GetString(this.Id,"Recursive download"),
                                                                    tooltip:LangHelper.GetString(this.Id,"Enable recursive download"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "checkbox",
                                                                    name: "level_enabled",
                                                                    labelRight: LangHelper.GetString(this.Id,"Recursion depth"),
                                                                    tooltip:LangHelper.GetString(this.Id,"Recursion depth (inf and 0 - infinity)"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "text",
                                                                    name: "level",
                                                                    labelWidth:0,
                                                                    tooltip:LangHelper.GetString(this.Id,"Recursion depth (inf and 0 - infinity)")
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            rows:[
                                                                {
                                                                    view: "checkbox",
                                                                    name: "page_requisites",
                                                                    labelRight: LangHelper.GetString(this.Id,"Download requisites"),
                                                                    tooltip:LangHelper.GetString(this.Id,"Download all the images  necessary to display the HTML page and so on"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "checkbox",
                                                                    name: "convert_links",
                                                                    labelRight: LangHelper.GetString(this.Id,"Convert references"),
                                                                    tooltip:LangHelper.GetString(this.Id,"Make links in downloaded HTML or CSS point to local files"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "checkbox",
                                                                    name: "backup_converted",
                                                                    labelRight: LangHelper.GetString(this.Id,"Backup"),
                                                                    tooltip:LangHelper.GetString(this.Id,"Before converting file X, back up as X.orig"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "checkbox",
                                                                    name: "mirror",
                                                                    labelRight: LangHelper.GetString(this.Id,"Create site mirror"),
                                                                    tooltip:LangHelper.GetString(this.Id,"Shortcut for -N -r -l inf --no-remove-listing"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "checkbox",
                                                                    name: "no_parent",
                                                                    labelRight: LangHelper.GetString(this.Id,"Don't ascend"),
                                                                    tooltip:LangHelper.GetString(this.Id,"Don't ascend to the parent directory"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {}
                                            ]
                                        }
                                    },
                                    {
                                        header:LangHelper.GetString(this.Id,"Input&nbsp;data"),
                                        id:this.Id + "CreateTaskContextFormTabsInput",
                                        body:{
                                            id:this.Id + "CreateTaskContextFormTabsInputForm",
                                            view: "form",
                                            borderless: true,
                                            margin: 0,
                                            padding: 8,
                                            elements:[
                                                {
                                                    view: "checkbox",
                                                    name: "continue",
                                                    labelRight:LangHelper.GetString(this.Id,"Continue downloading"),
                                                    tooltip:LangHelper.GetString(this.Id,"Resume getting a partially-downloaded file"),
                                                    value:0,
                                                    labelWidth:0
                                                },
                                                {
                                                    view: "checkbox",
                                                    name: "force_html",
                                                    labelRight: LangHelper.GetString(this.Id,"Force HTML"),
                                                    tooltip:LangHelper.GetString(this.Id,"Treat input file as HTML"),
                                                    value:0,
                                                    labelWidth:0
                                                },
                                                {
                                                    view: "checkbox",
                                                    name: "base_enabled",
                                                    labelRight: LangHelper.GetString(this.Id,"URL prefix"),
                                                    tooltip:LangHelper.GetString(this.Id,"Resolves HTML input-file links relative to URL"),
                                                    value:0,
                                                    labelWidth:0
                                                },
                                                {
                                                    view: "text",
                                                    name: "base",
                                                    labelWidth:0,
                                                    tooltip:LangHelper.GetString(this.Id,"Resolves HTML input-file links relative to URL")
                                                },
                                                {
                                                    view: "text",
                                                    name: "add_params",
                                                    labelPosition: "top",
                                                    label:LangHelper.GetString(this.Id,"Extra options"),
                                                    tooltip:LangHelper.GetString(this.Id,"Extra options")
                                                },
                                                {}
                                            ]
                                        }
                                    },
                                    {
                                        header:LangHelper.GetString(this.Id,"Downloading"),
                                        id:this.Id + "CreateTaskContextFormTabsDownload",
                                        body:{
                                            id:this.Id + "CreateTaskContextFormTabsDownloadForm",
                                            view: "form",
                                            borderless: true,
                                            margin: 0,
                                            padding: 8,
                                            elements:[
                                                {
                                                    cols:[
                                                        {
                                                            rows:[
                                                                {
                                                                    view: "checkbox",
                                                                    name: "wait_enabled",
                                                                    labelRight: LangHelper.GetString(this.Id,"Pause"),
                                                                    tooltip:LangHelper.GetString(this.Id,"Wait between retrievals in seconds"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "text",
                                                                    name: "wait",
                                                                    labelWidth:0,
                                                                    tooltip:LangHelper.GetString(this.Id,"Wait between retrievals in seconds")
                                                                },
                                                                {
                                                                    view: "checkbox",
                                                                    name: "random_wait",
                                                                    labelRight: LangHelper.GetString(this.Id,"Random wait"),
                                                                    tooltip:LangHelper.GetString(this.Id,"Wait from 0.5*WAIT...1.5*WAIT secs between retrievals"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "checkbox",
                                                                    name: "limit_rate_enabled",
                                                                    labelRight: LangHelper.GetString(this.Id,"Speed (bps)"),
                                                                    tooltip:LangHelper.GetString(this.Id,"Limit download rate"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "text",
                                                                    name: "limit_rate",
                                                                    labelWidth:0,
                                                                    tooltip:LangHelper.GetString(this.Id,"Limit download rate")
                                                                },
                                                                {
                                                                    view: "checkbox",
                                                                    name: "server_response",
                                                                    labelRight: LangHelper.GetString(this.Id,"Server response"),
                                                                    tooltip:LangHelper.GetString(this.Id,"Print server response"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "checkbox",
                                                                    name: "no_clobber",
                                                                    labelRight: LangHelper.GetString(this.Id,"Skip downloads"),
                                                                    tooltip:LangHelper.GetString(this.Id,"Skip downloads that would download to existing files (overwriting them)"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            rows:[
                                                                {
                                                                    view: "checkbox",
                                                                    name: "tries_enabled",
                                                                    labelRight: LangHelper.GetString(this.Id,"Tries"),
                                                                    tooltip:LangHelper.GetString(this.Id,"Set number of retries (0 unlimits)"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "text",
                                                                    name: "tries",
                                                                    labelWidth:0,
                                                                    tooltip:LangHelper.GetString(this.Id,"Set number of retries (0 unlimits)")
                                                                },
                                                                {
                                                                    view: "checkbox",
                                                                    name: "retry_connrefused",
                                                                    labelRight: LangHelper.GetString(this.Id,"Retry even if refused"),
                                                                    tooltip:LangHelper.GetString(this.Id,"Retry even if connection is refused"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "checkbox",
                                                                    name: "timeout_enabled",
                                                                    labelRight: LangHelper.GetString(this.Id,"Time-out"),
                                                                    tooltip:LangHelper.GetString(this.Id,"Set all timeout values in seconds"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "text",
                                                                    name: "timeout",
                                                                    labelWidth:0,
                                                                    tooltip:LangHelper.GetString(this.Id,"Set all timeout values in seconds")
                                                                },
                                                                {
                                                                    view: "checkbox",
                                                                    name: "timestamping",
                                                                    labelRight: LangHelper.GetString(this.Id,"Don't re-retrieve"),
                                                                    tooltip:LangHelper.GetString(this.Id,"Don't re-retrieve files unless newer than local"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {}
                                            ]
                                        }
                                    },
                                    {
                                        header:LangHelper.GetString(this.Id,"Directories"),
                                        id:this.Id + "CreateTaskContextFormTabsDirectories",
                                        body:{
                                            id:this.Id + "CreateTaskContextFormTabsDirectoriesForm",
                                            view: "form",
                                            borderless: true,
                                            margin: 0,
                                            padding: 8,
                                            elements:[
                                                {
                                                    view: "checkbox",
                                                    name: "no_directories",
                                                    labelRight: LangHelper.GetString(this.Id,"Don't create directories"),
                                                    tooltip:LangHelper.GetString(this.Id,"Don't create directories"),
                                                    value:0,
                                                    labelWidth:0
                                                },
                                                {
                                                    view: "checkbox",
                                                    name: "force_directories",
                                                    labelRight: LangHelper.GetString(this.Id,"Force creation of directories"),
                                                    tooltip:LangHelper.GetString(this.Id,"Force creation of directories"),
                                                    value:0,
                                                    labelWidth:0
                                                },
                                                {
                                                    view: "checkbox",
                                                    name: "no_host_directories",
                                                    labelRight: LangHelper.GetString(this.Id,"Don't create host directories"),
                                                    tooltip:LangHelper.GetString(this.Id,"Don't create host directories"),
                                                    value:0,
                                                    labelWidth:0
                                                },
                                                {
                                                    view: "checkbox",
                                                    name: "cut_dirs_enabled",
                                                    labelRight: LangHelper.GetString(this.Id,"Ignore"),
                                                    tooltip:LangHelper.GetString(this.Id,"Ignore number remote directory components"),
                                                    value:0,
                                                    labelWidth:0
                                                },
                                                {
                                                    view: "text",
                                                    name: "cut_dirs",
                                                    labelWidth:0,
                                                    tooltip:LangHelper.GetString(this.Id,"Ignore number remote directory components")
                                                },
                                                {}
                                            ]
                                        }
                                    },
                                    {
                                        header: "HTTP",
                                        id: this.Id + "CreateTaskContextFormTabsHttp",
                                        body: {
                                            id:this.Id + "CreateTaskContextFormTabsHttpForm",
                                            view: "form",
                                            borderless: true,
                                            margin: 0,
                                            padding: 8,
                                            elements:[
                                                {
                                                    cols:[
                                                        {
                                                            rows:[
                                                                {
                                                                    view: "checkbox",
                                                                    name: "http_user_enabled",
                                                                    labelRight: LangHelper.GetString(this.Id,"Http user"),
                                                                    tooltip:LangHelper.GetString(this.Id,"Set http user"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "text",
                                                                    name: "http_user",
                                                                    labelWidth:0,
                                                                    tooltip:LangHelper.GetString(this.Id,"Set http user")
                                                                },
                                                                {
                                                                    view: "checkbox",
                                                                    name: "proxy_user_enabled",
                                                                    labelRight: LangHelper.GetString(this.Id,"Proxy user"),
                                                                    tooltip:LangHelper.GetString(this.Id,"Set proxy username"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "text",
                                                                    name: "proxy_user",
                                                                    labelWidth:0,
                                                                    tooltip:LangHelper.GetString(this.Id,"Set proxy username")
                                                                },
                                                                {
                                                                    view: "checkbox",
                                                                    name: "post_data_enabled",
                                                                    labelRight: LangHelper.GetString(this.Id,"POST data"),
                                                                    tooltip:LangHelper.GetString(this.Id,"Use the POST method. Send STRING as the data"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "text",
                                                                    name: "post_data",
                                                                    labelWidth:0,
                                                                    tooltip:LangHelper.GetString(this.Id,"Use the POST method. Send STRING as the data")
                                                                },
                                                                {
                                                                    view: "checkbox",
                                                                    name: "no_cookies",
                                                                    labelRight: LangHelper.GetString(this.Id,"Don't use cookies"),
                                                                    tooltip:LangHelper.GetString(this.Id,"Don't use cookies"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "checkbox",
                                                                    name: "no_http_keep_alive",
                                                                    labelRight: LangHelper.GetString(this.Id,"Do not use keep alive"),
                                                                    tooltip:LangHelper.GetString(this.Id,"Disable HTTP keep-alive (persistent connections)"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "checkbox",
                                                                    name: "no_cache",
                                                                    labelRight: LangHelper.GetString(this.Id,"Do not use cache"),
                                                                    tooltip:LangHelper.GetString(this.Id,"Disallow server-cached data"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {}
                                                            ]
                                                        },
                                                        {
                                                            rows:[
                                                                {
                                                                    view: "checkbox",
                                                                    name: "http_password_enabled",
                                                                    labelRight: LangHelper.GetString(this.Id,"Http password"),
                                                                    tooltip:LangHelper.GetString(this.Id,"Set http password"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "text",
                                                                    name: "http_password",
                                                                    labelWidth:0,
                                                                    tooltip:LangHelper.GetString(this.Id,"Set http password")
                                                                },
                                                                {
                                                                    view: "checkbox",
                                                                    name: "proxy_password_enabled",
                                                                    labelRight: LangHelper.GetString(this.Id,"Proxy password"),
                                                                    tooltip:LangHelper.GetString(this.Id,"Set proxy password"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "text",
                                                                    name: "proxy_password",
                                                                    labelWidth:0,
                                                                    tooltip:LangHelper.GetString(this.Id,"Set proxy password")
                                                                },
                                                                {
                                                                    view: "checkbox",
                                                                    name: "referer_enabled",
                                                                    labelRight: LangHelper.GetString(this.Id,"Referer header"),
                                                                    tooltip:LangHelper.GetString(this.Id,"Include 'Referer: URL' header in HTTP request"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "text",
                                                                    name: "referer",
                                                                    labelWidth:0,
                                                                    tooltip:LangHelper.GetString(this.Id,"Include 'Referer: URL' header in HTTP request")
                                                                },
                                                                {
                                                                    view: "checkbox",
                                                                    name: "user_agent_enabled",
                                                                    labelRight: LangHelper.GetString(this.Id,"Agent"),
                                                                    tooltip:LangHelper.GetString(this.Id,"Identify as Agent instead of Wget/version"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "text",
                                                                    name: "user_agent",
                                                                    labelWidth:0,
                                                                    tooltip:LangHelper.GetString(this.Id,"Identify as Agent instead of Wget/version")
                                                                },
                                                                {}
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    },
                                    {
                                        header: "FTP",
                                        id: this.Id + "CreateTaskContextFormTabsFtp",
                                        body: {
                                            id: this.Id + "CreateTaskContextFormTabsFtpForm",
                                            view: "form",
                                            borderless: true,
                                            margin: 0,
                                            padding: 8,
                                            elements: [
                                                {
                                                    view: "checkbox",
                                                    name: "no_remove_listing",
                                                    labelRight: LangHelper.GetString(this.Id,"Don't remove '.listing' files"),
                                                    tooltip:LangHelper.GetString(this.Id,"Don't remove '.listing' files"),
                                                    value:0,
                                                    labelWidth:0
                                                },
                                                {
                                                    view: "checkbox",
                                                    name: "retr_symlinks",
                                                    labelRight: LangHelper.GetString(this.Id,"Upload files by reference"),
                                                    tooltip:LangHelper.GetString(this.Id,"When recursing, get linked-to files (not dir)"),
                                                    value:0,
                                                    labelWidth:0
                                                },
                                                {
                                                    view: "checkbox",
                                                    name: "no_glob",
                                                    labelRight: LangHelper.GetString(this.Id,"Turn off masks"),
                                                    tooltip:LangHelper.GetString(this.Id,"Turn off FTP file name globbing"),
                                                    value:0,
                                                    labelWidth:0
                                                },
                                                {
                                                    view: "checkbox",
                                                    name: "no_passive_ftp",
                                                    labelRight: LangHelper.GetString(this.Id,"Disable passive mode"),
                                                    tooltip:LangHelper.GetString(this.Id,"Disable the passive transfer mode"),
                                                    value:0,
                                                    labelWidth:0
                                                },
                                                {}
                                            ]
                                        }
                                    }
                                ]
                            }
                        }
                    ]
                }
            ]
        }
    };

    this.TaskDeleteContext = webix.ui({
        view:"popup",
        width:300,
        height:180,
        body:{
            view:"form",
            borderless:true,
            elements:[
                {
                    template:LangHelper.GetString("All","Are you sure you want to delete the task?")
                },
                {
                    cols:[
                        {},
                        {
                            view:"button",
                            label:LangHelper.GetString(this.Id,"Cancel"),
                            click:function () {
                                _this.TaskDeleteContext.hide();
                            }
                        },
                        {
                            view:"button",
                            type:"form",
                            label:LangHelper.GetString(this.Id,"Delete"),
                            click:function () {
                                _this.TaskDeleteContext.hide();

                                var selected = _this.GetTasksTableSelectedData();

                                if(selected)
                                    _this.DeleteTask(selected.id);
                            }
                        }
                    ]
                }
            ]
        }
    });

    this.SiteFolderClearContext = webix.ui({
        view:"popup",
        width:300,
        height:180,
        body:{
            view:"form",
            borderless:true,
            elements:[
                {
                    template:LangHelper.GetString(this.Id,"Are you sure you want to empty the folder?")
                },
                {
                    cols:[
                        {},
                        {
                            view:"button",
                            label:LangHelper.GetString(this.Id,"Cancel"),
                            click:function ()
                            {
                                _this.SiteFolderClearContext.hide();
                            }
                        },
                        {
                            view:"button",
                            type:"form",
                            label:LangHelper.GetString(this.Id,"Clear"),
                            click:function ()
                            {
                                _this.SiteFolderClearContext.hide();

                                _this.ClearSiteFolder();
                            }
                        }
                    ]
                }
            ]
        }
    });

    this.SiteFolderInfoContext = webix.ui({
        view:"popup",
        width:400,
        body:{
            view:"form",
            id: this.Id + "SiteFolderInfoContextForm",
            borderless:true,
            elements:[
                {
                    cols:[
                        {
                            view:"label",
                            label:LangHelper.GetString(this.Id,"Folder")+":"
                        },
                        {
                            view:"label",
                            name:"path",
                            label:""
                        }
                    ]
                },
                {
                    cols:[
                        {
                            view:"label",
                            label:LangHelper.GetString(this.Id,"Size")+":"

                        },
                        {
                            view:"label",
                            name:"size",
                            label:"0"
                        }
                    ]
                },
                {
                    cols:[
                        {
                            view:"label",
                            label:LangHelper.GetString(this.Id,"Number of files")+":"

                        },
                        {
                            view:"label",
                            name:"files_count",
                            label:"0"

                        }
                    ]
                },
                {
                    cols:[
                        {
                            view:"label",
                            label:LangHelper.GetString(this.Id,"Number of folders")+":"
                        },
                        {
                            view:"label",
                            name:"folders_count",
                            label:"0"

                        }
                    ]
                },
                {
                    cols:[
                        {},
                        {
                            view:"button",
                            label:"Ok",
                            click:function ()
                            {
                                _this.SiteFolderInfoContext.hide();
                            }
                        }
                    ]
                }
            ]
        }
    });

    this.SpoofingContextFormConfig = {
        view: "popup",
        id: this.Id + "SpoofingContext",
        width:400,
        body: {
            view:"form",
            id: this.Id + "SpoofingContextForm",
            borderless:true,
            elements:[
                {
                    view: "text",
                    name: "file_name",
                    label: LangHelper.GetString(this.Id,"File"),
                    labelPosition: "top",
                    tooltip:LangHelper.GetString(this.Id,"Path to file"),
                    placeholder:LangHelper.GetString(this.Id,"index.html by default")
                },
                {
                    view: "richselect",
                    name: "template",
                    labelPosition: "top",
                    required:true,
                    labelWidth:120,
                    label:LangHelper.GetString(this.Id,"Template"),
                    tooltip:LangHelper.GetString(this.Id,"Template"),
                    options: Helper.DictToArr(WgetSpoofingTemplatesDic),
                    value:WgetSpoofingTemplatesDic.FacebookCredentialsCollect,
                    validate: function(Val)
                    {
                        return Val;
                    },
                    invalidMessage: LangHelper.GetString(this.Id,"You must select a template"),
                },
                {
                    cols:[
                        {},
                        {
                            view:"button",
                            label:LangHelper.GetString(this.Id,"Cancel"),
                            click:function ()
                            {
                                _this.Views.SpoofingContext.hide();
                            }
                        },
                        {
                            view:"button",
                            type:"form",
                            label:LangHelper.GetString(this.Id,"Spoof"),
                            click:function ()
                            {
                                _this.SpoofSite();
                            }
                        }
                    ]
                }
            ]
        }
    };

    this.ViewConfig = [
        {
            cols:[
                SormSideBarView({id: this.Id}),
                {
                    type: "space",
                    rows: [
                        {
                            type: "clear",
                            borderless: true,
                            rows: [
                                PageHeaderTemplate({root_title: LangHelper.GetString("AppView","Lawful interception"), title: LangHelper.GetString("AppView","Cloning a website")}),
                                {
                                    view:"accordion",
                                    multi:"mixed",
                                    cols:[
                                        {
                                            header:LangHelper.GetString(this.Id,"Sites"),
                                            body:{
                                                rows:[
                                                    {
                                                        view: "toolbar",
                                                        height: 32,
                                                        padding: 0,
                                                        margin: 0,
                                                        elements: [
                                                            {
                                                                view: "button",
                                                                id:this.Id + "AddTaskBtn",
                                                                type: "icon",
                                                                icon: "plus",
                                                                align: "right",
                                                                width: 32,
                                                                css:"toolbar_round_btn",
                                                                tooltip:LangHelper.GetString(this.Id,"Add task to site download"),
                                                                click: function (id, e, node)
                                                                {
                                                                    _this.AddTaskToTasksTable();
                                                                }
                                                            },
                                                            {
                                                                view: "button",
                                                                id:this.Id + "AddSubTaskBtn",
                                                                type: "icon",
                                                                icon: "plus-square",
                                                                align: "right",
                                                                width: 32,
                                                                css:"toolbar_round_btn",
                                                                tooltip:LangHelper.GetString(this.Id,"Add a subtask to site download"),
                                                                click: function (id, e, node)
                                                                {
                                                                    _this.AddSubTaskToTasksTable();
                                                                }
                                                            },
                                                            {
                                                                width:16
                                                            },
                                                            {
                                                                view: "button",
                                                                id:this.Id + "PlayBtn",
                                                                type: "icon",
                                                                icon: "play-circle",
                                                                width: 32,
                                                                css:"toolbar_round_btn",
                                                                tooltip:LangHelper.GetString(this.Id,"Start scanning"),
                                                                click: function (id, e, node)
                                                                {
                                                                    _this.RunTask();
                                                                }
                                                            },
                                                            {
                                                                view: "button",
                                                                id:this.Id + "StopBtn",
                                                                type: "icon",
                                                                icon: "stop-circle",
                                                                width: 32,
                                                                css:"toolbar_round_btn",
                                                                tooltip:LangHelper.GetString(this.Id,"Stop scanning"),
                                                                click: function (id, e, node)
                                                                {
                                                                    _this.StopTask();
                                                                }
                                                            },
                                                            {
                                                                width:16
                                                            },
                                                            {
                                                                view: "button",
                                                                id:this.Id + "SiteFolderPropertiesBtn",
                                                                type: "icon",
                                                                icon: "folder",
                                                                width: 32,
                                                                css:"toolbar_round_btn",
                                                                tooltip:LangHelper.GetString(this.Id,"Data folder properties"),
                                                                click: function (id, e, node)
                                                                {
                                                                    var node = this.getNode();
                                                                    _this.SiteFolderInfoContext.show(node, {pos:"bottom"});
                                                                    _this.GetSiteFolderInfo();
                                                                }
                                                            },
                                                            {
                                                                view: "button",
                                                                id:this.Id + "SiteExportBtn",
                                                                type: "icon",
                                                                icon: "download",
                                                                width: 32,
                                                                css:"toolbar_round_btn",
                                                                tooltip:LangHelper.GetString(this.Id,"Download zip-archive"),
                                                                click: function (id, e, node)
                                                                {
                                                                    _this.DownloadSiteZip();
                                                                }
                                                            },
                                                            {
                                                                view: "button",
                                                                id:this.Id + "ClearSiteFolderBtn",
                                                                type: "icon",
                                                                icon: "eraser",
                                                                width: 32,
                                                                css:"toolbar_round_btn",
                                                                tooltip:LangHelper.GetString(this.Id,"Clear Folder"),
                                                                click: function (id, e, node)
                                                                {
                                                                    var node = this.getNode();
                                                                    _this.SiteFolderClearContext.show(node, {pos:"bottom"});
                                                                }
                                                            },
                                                            {
                                                                width:16
                                                            },
                                                            {
                                                                view: "button",
                                                                id:this.Id + "VhostEnableBtn",
                                                                type: "icon",
                                                                icon: "toggle-on",
                                                                width: 32,
                                                                css:"toolbar_round_btn",
                                                                tooltip:LangHelper.GetString(this.Id,"Enable virtual host"),
                                                                click: function (id, e, node)
                                                                {
                                                                    _this.EnableVirtualHost();
                                                                }
                                                            },
                                                            {
                                                                view: "button",
                                                                id:this.Id + "VhostDisableBtn",
                                                                type: "icon",
                                                                icon: "toggle-off",
                                                                width: 32,
                                                                css:"toolbar_round_btn",
                                                                tooltip:LangHelper.GetString(this.Id,"Disable virtual host"),
                                                                click: function (id, e, node)
                                                                {
                                                                    _this.DisableVirtualHost();
                                                                }
                                                            },
                                                            {
                                                                width:16
                                                            },
                                                            {
                                                                view: "button",
                                                                id:this.Id + "SiteSpoofingBtn",
                                                                type: "icon",
                                                                icon: "chain-broken",
                                                                width: 32,
                                                                css:"toolbar_round_btn",
                                                                tooltip:LangHelper.GetString(this.Id,"Site spoofing"),
                                                                click: function (id, e, node)
                                                                {
                                                                    var node = this.getNode();
                                                                    _this.Views.SpoofingContextForm.clear();
                                                                    _this.Views.SpoofingContextForm.clearValidation();
                                                                    _this.Views.SpoofingContext.show(node, {pos:"bottom"});
                                                                }
                                                            },
                                                            {},
                                                            {
                                                                view: "button",
                                                                id:this.Id+"TasksRefreshBtn",
                                                                type: "icon",
                                                                icon: "refresh",
                                                                css: "toolbar_round_btn",
                                                                width: 32,
                                                                tooltip: LangHelper.GetString("All","Refresh"),
                                                                click: function (id, e, node)
                                                                {
                                                                    _this.RefreshTasksTable(0);
                                                                }
                                                            }
                                                        ]
                                                    },
                                                    BlockHeaderTemplate({title:LangHelper.GetString(this.Id,"Tasks for downloading sites"), icon:"clone"}),
                                                    {
                                                        view: "treetable",
                                                        id: this.Id + "TasksTable",
                                                        select: "row",
                                                        editable: true,
                                                        editaction:"custom",
                                                        resizeColumn: true,
                                                        tooltip:true,
                                                        form:this.Id + "CreateTaskContextForm",
                                                        on: {
                                                            onItemClick: function (id,e,node)
                                                            {
                                                                var rowData = this.getItem(id);

                                                                if(id.column === 'run')
                                                                {
                                                                    var data = _this.Views.TasksTable.getItem(id);

                                                                    if(!data.wget_id)
                                                                    {
                                                                        return;
                                                                    }

                                                                    if(data.status != WgetTaskStatusEnum.progress && data.download)
                                                                    {
                                                                        return;
                                                                    }

                                                                    if(data.status == WgetTaskStatusEnum.progress)
                                                                    {
                                                                        setTimeout(function ()
                                                                        {
                                                                            _this.StopTask();
                                                                        }, 10);
                                                                    }
                                                                    else
                                                                    {
                                                                        setTimeout(function ()
                                                                        {
                                                                            _this.RunTask();
                                                                        }, 10);
                                                                    }
                                                                }
                                                                
                                                                else if (id.column === 'edit')
                                                                {
                                                                    _this.ShowTasksEditForm(rowData, this.getItemNode(id), "right");
                                                                }
                                                                
                                                                else if(id.column === 'trash')
                                                                {
                                                                    var __this = this;

                                                                    var data = _this.Views.TasksTable.getItem(id);

                                                                    if(!data.wget_id)
                                                                    {
                                                                        _this.DeleteTask(id);
                                                                        return;
                                                                    }

                                                                    setTimeout(function () {
                                                                        var node = __this.getItemNode(id);
                                                                        _this.TaskDeleteContext.show(node, {pos:"bottom"});
                                                                    }, 10);
                                                                }
                                                            },
                                                            onBeforeFilter:function()
                                                            {
                                                                return false;
                                                            },
                                                            onAfterFilter:function()
                                                            {
                                                                _this.RefreshTasksTable();
                                                            },
                                                            onBeforeSort:function()
                                                            {
                                                                _this.RefreshTasksTable();
                                                                return false;
                                                            },
                                                            onAfterSelect:function ()
                                                            {
                                                                _this.OnTasksTableRowSelect();
                                                            },
                                                            onAfterUnSelect:function ()
                                                            {
                                                                _this.OnTasksTableRowUnSelect();
                                                            }
                                                        },
                                                        columns: [
                                                            {
                                                                id: "run",
                                                                header: "",
                                                                width: 32,
                                                                template: function (obj, common)
                                                                {
                                                                    if(obj.status == WgetTaskStatusEnum.progress && obj.wget_id)
                                                                    {
                                                                        return CellStopBtnTemplate();
                                                                    }

                                                                    if(obj.wget_id)
                                                                    {
                                                                        return CellPlayBtnTemplate();
                                                                    }

                                                                    return "";
                                                                },
                                                                headermenu: false
                                                            },
                                                            {
                                                                id: "edit",
                                                                header: "",
                                                                width: 32,
                                                                template: CellEditBtnTemplate,
                                                                headermenu: false
                                                            },
                                                            {
                                                                id: "title",
                                                                header: [LangHelper.GetString(this.Id,"Task"), {content: "textFilter"}],
                                                                template:function(obj, common, value, config)
                                                                {
                                                                    return common.treetable(obj, common, value, config) + (obj.title?obj.title:LangHelper.GetString(this.Id,"Task")+" "+obj.wget_id);
                                                                },
                                                                fillspace: true,
                                                                sort: "string"
                                                            },
                                                            {
                                                                id: "date",
                                                                header: [LangHelper.GetString(this.Id,"Date"), {content: "dateRangeFilter"}],
                                                                //format: webix.Date.dateToStr("%d.%m.%Y"),
                                                                template:function(obj, common){
                                                                    if(obj.date)
                                                                    {
                                                                        var format =  webix.Date.dateToStr("%d.%m.%Y %H:%i");
                                                                        return format(obj.date);
                                                                    }

                                                                    return "";
                                                                },
                                                                fillspace: true,
                                                                sort: "date"
                                                            },
                                                            {
                                                                id: "user_id",
                                                                header: [LangHelper.GetString(this.Id,"User"), {
                                                                    content: "multiSelectFilter",
                                                                    options: App.DicsManager.GetDicAsArray(DicsEnum.UsersDic),
                                                                    inputConfig: {suggest: {fitMaster: false}}
                                                                }],
                                                                fillspace: true,
                                                                template: function (obj)
                                                                {
                                                                    if(App.DicsManager.GetDic("UsersDic")[obj.user_id])
                                                                        return App.DicsManager.GetDic("UsersDic")[obj.user_id].value;
                                                                    else
                                                                        return "";
                                                                },
                                                                sort: "string"
                                                            },
                                                            {
                                                                id: "status",
                                                                header: [LangHelper.GetString(this.Id,"Status"), {
                                                                    content: "richSelectFilter",
                                                                    options: Helper.DictToArr(WgetTaskStatusesDic),
                                                                    inputConfig: {suggest: {fitMaster: false}}
                                                                }],
                                                                template: function (obj, common)
                                                                {
                                                                    var statusStr = "";

                                                                    if(WgetTaskStatusesDic[obj.status])
                                                                    {
                                                                        if(obj.status == WgetTaskStatusEnum.progress && obj.wget_id)
                                                                        {
                                                                            statusStr += '<div class="spinner green"><div class="bounce1"></div><div class="bounce2"></div> <div class="bounce3"></div> </div>';
                                                                        }

                                                                        statusStr += "<span class=\"status status_" + WgetTaskStatusColorsEnum[obj.status] + "\">" + WgetTaskStatusesDic[obj.status].value + "</span>";
                                                                    }

                                                                    return statusStr;
                                                                },
                                                                fillspace: true,
                                                                sort: "string"
                                                            },
                                                            {
                                                                id: "vhost",
                                                                header: [LangHelper.GetString(this.Id,"Virt. host"), {
                                                                    content: "richSelectFilter",
                                                                    options: Helper.DictToArr(WgetTaskVhostStatusesDic),
                                                                    inputConfig: {suggest: {fitMaster: false}}
                                                                }],
                                                                template: CellOnOffTemplate,
                                                                width: 72,
                                                                sort: "string"
                                                            },
                                                            {
                                                                id: "trash",
                                                                header: "",
                                                                width: 32,
                                                                template: CellTrashBtnTemplate,
                                                                headermenu: false
                                                            }
                                                        ],
                                                        data:[]
                                                    },
                                                    {
                                                        view: "toolbar",
                                                        padding: 0,
                                                        margin: 0,
                                                        height:32,
                                                        elements: [
                                                            {
                                                                view: "pager",
                                                                gravity:3,
                                                                id: this.Id + "TasksTable" + "Paging",
                                                                template: "{common.first()} {common.prev()} {common.pages()} {common.next()} {common.last()}",
                                                                master:false,
                                                                on: {
                                                                    onAfterPageChange:function(Page)
                                                                    {
                                                                        _this.RefreshTasksTable(Page);
                                                                    }
                                                                }
                                                            },
                                                            {
                                                                view:"richselect",
                                                                id: this.Id + "TasksTable" + "Paging" + "Size",
                                                                label:LangHelper.GetString(this.Id,"On page"),
                                                                labelWidth:120,
                                                                minWidth:220,
                                                                value:100,
                                                                options:PagingSizeOptions,
                                                                on: {
                                                                    onChange:function()
                                                                    {
                                                                        _this.RefreshTasksTable();
                                                                    }
                                                                }
                                                            }
                                                        ]
                                                    }
                                                ]
                                            }
                                        },
                                        {
                                            view: "resizer"
                                        },
                                        {
                                            header:LangHelper.GetString(this.Id,"Statistics"),
                                            body:{
                                                rows:[
                                                    {
                                                        id:this.Id + "ReportsPanel",
                                                        rows:[
                                                            {
                                                                view: "toolbar",
                                                                height: 32,
                                                                padding: 0,
                                                                margin: 0,
                                                                elements: [
                                                                    {
                                                                        view:"label",
                                                                        id:this.Id+"TaskDataInfoLabel"
                                                                    }
                                                                ]
                                                            },
                                                            {
                                                                view: "tabbar",
                                                                id: this.Id + "ReportsPanelTabs",
                                                                value: this.Id + "ReportsPanelTabs" + "LogTab",
                                                                multiview: true,
                                                                height:webix.skin.$active.tabbarSmallHeight,
                                                                borderless:false,
                                                                options: [
                                                                    {id:this.Id + "ReportsPanelTabs" + "LogTab", value:LangHelper.GetString(this.Id,"Statistics log")},
                                                                    {id:this.Id + "ReportsPanelTabs" + "FilesTab", value:LangHelper.GetString(this.Id,"Files statistics")},
                                                                    {id:this.Id + "ReportsPanelTabs" + "SpoofingTab", value:LangHelper.GetString(this.Id,"Spoofing data")}
                                                                ]
                                                            },
                                                            {
                                                                cells:[
                                                                    {
                                                                        id:this.Id + "ReportsPanelTabs" + "LogTab",
                                                                        rows:[
                                                                            {
                                                                                view: "toolbar",
                                                                                height: 32,
                                                                                padding: 0,
                                                                                margin: 0,
                                                                                elements: [
                                                                                    {
                                                                                        view: "button",
                                                                                        id:this.Id+"TaskDataDownloadLogBtn",
                                                                                        type: "icon",
                                                                                        icon: "download",
                                                                                        css: "toolbar_round_btn",
                                                                                        width: 32,
                                                                                        tooltip: LangHelper.GetString(this.Id,"Download the log file"),
                                                                                        click: function (id, e, node)
                                                                                        {
                                                                                            _this.DownloadLog();
                                                                                        }
                                                                                    },
                                                                                    {},
                                                                                    {
                                                                                        view: "button",
                                                                                        id:this.Id+"TaskDataRefreshLogBtn",
                                                                                        type: "icon",
                                                                                        icon: "refresh",
                                                                                        css: "toolbar_round_btn",
                                                                                        width: 32,
                                                                                        tooltip: LangHelper.GetString(this.Id,"Refresh"),
                                                                                        click: function (id, e, node)
                                                                                        {
                                                                                            _this.RefreshTaskLogs();
                                                                                        }
                                                                                    }
                                                                                ]
                                                                            },
                                                                            {
                                                                                view:"codemirror-editor",
                                                                                id:_this.Id + "ReportsPanelTabs" + "Log",
                                                                                value:""
                                                                            }
                                                                        ]
                                                                    },
                                                                    {
                                                                        id:this.Id + "ReportsPanelTabs" + "FilesTab",
                                                                        rows:[
                                                                            {
                                                                                view: "toolbar",
                                                                                height: 32,
                                                                                padding: 0,
                                                                                margin: 0,
                                                                                elements: [
                                                                                    {
                                                                                        view: "button",
                                                                                        id:this.Id+"TaskDataExportFilesBtn",
                                                                                        type: "icon",
                                                                                        icon: "file-excel",
                                                                                        css: "toolbar_round_btn",
                                                                                        width: 32,
                                                                                        tooltip: LangHelper.GetString(this.Id,"Export to Excel"),
                                                                                        click: function (id, e, node)
                                                                                        {
                                                                                            _this.ExportTaskDataToExcel();
                                                                                        }
                                                                                    },
                                                                                    {},
                                                                                    {
                                                                                        view: "button",
                                                                                        id:this.Id+"TaskDataRefreshFilesBtn",
                                                                                        type: "icon",
                                                                                        icon: "refresh",
                                                                                        css: "toolbar_round_btn",
                                                                                        width: 32,
                                                                                        tooltip: LangHelper.GetString(this.Id,"Refresh"),
                                                                                        click: function (id, e, node)
                                                                                        {
                                                                                            _this.RefreshTaskData();
                                                                                        }
                                                                                    }
                                                                                ]
                                                                            },
                                                                            {
                                                                                id:this.Id + "ReportsPanelTabs" + "FilesTable",
                                                                                view: "datatable",
                                                                                select: "row",
                                                                                resizeColumn:true,
                                                                                tooltip:true,
                                                                                scroll:"xy",
                                                                                dragColumn:true,
                                                                                headermenu: {
                                                                                    width: webix.skin.$active.tableHeaderMenuWidth,
                                                                                    autoheight: false,
                                                                                    scroll: true
                                                                                },
                                                                                columns: [
                                                                                    {
                                                                                        id: "url",
                                                                                        header: ["Url", {content: "textFilter"}],
                                                                                        fillspace: true,
                                                                                        sort:"string"
                                                                                    },
                                                                                    {
                                                                                        id: "date",
                                                                                        header: [LangHelper.GetString(this.Id,"Date"), {content: "dateRangeFilter"}],
                                                                                        template:function(obj, common)
                                                                                        {
                                                                                            if(obj.date)
                                                                                            {
                                                                                                var format =  webix.Date.dateToStr("%d.%m.%Y %H:%i");
                                                                                                return format(obj.date);
                                                                                            }

                                                                                            return "";
                                                                                        },
                                                                                        fillspace: true,
                                                                                        sort: "date"
                                                                                    },
                                                                                    {
                                                                                        id: "local_path",
                                                                                        header: [LangHelper.GetString(this.Id,"Path"), {content: "textFilter"}],
                                                                                        fillspace: true,
                                                                                        sort:"string"
                                                                                    },
                                                                                    {
                                                                                        id: "bytes_loaded",
                                                                                        header: [LangHelper.GetString(this.Id,"Bytes loaded"), {content: "textFilter"}],
                                                                                        fillspace: true,
                                                                                        sort:"string"
                                                                                    },
                                                                                    {
                                                                                        id: "bytes_total",
                                                                                        header: [LangHelper.GetString(this.Id,"Total bytes"), {content: "textFilter"}],
                                                                                        fillspace: true,
                                                                                        sort:"string"
                                                                                    },
                                                                                    {
                                                                                        id: "rate",
                                                                                        header: [LangHelper.GetString(this.Id,"Percent"), ""],
                                                                                        template: function (obj, common)
                                                                                        {
                                                                                            var _obj = {total:obj.bytes_total, done:obj.bytes_loaded};

                                                                                            if(_obj.done > _obj.total)
                                                                                                _obj.total = _obj.done;

                                                                                            return CellProgressTemplate(_obj, LangHelper.GetString(this.Id,"Total (bytes)"), LangHelper.GetString(this.Id,"Uploaded (bytes)"));
                                                                                        },
                                                                                        fillspace: true,
                                                                                        sort:"string"
                                                                                    },
                                                                                    {
                                                                                        id: "http_response",
                                                                                        header: [LangHelper.GetString(this.Id,"Http status"), {content: "textFilter"}],
                                                                                        fillspace: true,
                                                                                        sort:"string"
                                                                                    },
                                                                                    {
                                                                                        id: "status",
                                                                                        header: [LangHelper.GetString(this.Id,"Status"), {
                                                                                            content: "richSelectFilter",
                                                                                            options: Helper.DictToArr(WgetTaskDataStatusesDic),
                                                                                            inputConfig: {suggest: {fitMaster: false}}
                                                                                        }],
                                                                                        template: function (obj, common)
                                                                                        {
                                                                                            var statusStr = "";

                                                                                            if(obj.status == WgetTaskDataStatusEnum.progress)
                                                                                            {
                                                                                                var selectedData = _this.GetTasksTableSelectedData();
                                                                                                if(selectedData && selectedData.status != WgetTaskStatusEnum.progress)
                                                                                                {
                                                                                                    obj.status = WgetTaskDataStatusEnum.not_saved;
                                                                                                }
                                                                                            }

                                                                                            if(WgetTaskDataStatusesDic[obj.status])
                                                                                            {
                                                                                                if(obj.status == WgetTaskDataStatusEnum.progress)
                                                                                                {
                                                                                                    statusStr += '<div class="spinner"><div class="bounce1"></div><div class="bounce2"></div> <div class="bounce3"></div> </div>';
                                                                                                }

                                                                                                statusStr += "<span class=\"status status_" + WgetTaskDataStatusColorsEnum[obj.status] + "\">" + WgetTaskDataStatusesDic[obj.status].value + "</span>";
                                                                                            }

                                                                                            return statusStr;
                                                                                        },
                                                                                        fillspace: true,
                                                                                        sort: "string"
                                                                                    },
                                                                                    {
                                                                                        header: {content: "headerMenu"},
                                                                                        headermenu: false,
                                                                                        width: 32
                                                                                    }
                                                                                ],
                                                                                on: {
                                                                                    onBeforeFilter:function()
                                                                                    {
                                                                                        return false;
                                                                                    },
                                                                                    onAfterFilter:function()
                                                                                    {
                                                                                        _this.RefreshTaskData();
                                                                                    },
                                                                                    onBeforeSort:function()
                                                                                    {
                                                                                        _this.RefreshTaskData();

                                                                                        return false;
                                                                                    },
                                                                                    onAfterColumnHide:function()
                                                                                    {
                                                                                        _this.SaveTableState(this);
                                                                                    },
                                                                                    onAfterColumnShow:function()
                                                                                    {
                                                                                        _this.SaveTableState(this);
                                                                                    },
                                                                                    onAfterColumnDrop:function()
                                                                                    {
                                                                                        _this.SaveTableState(this);
                                                                                    }

                                                                                },
                                                                                data: []
                                                                            },
                                                                            {
                                                                                view: "toolbar",
                                                                                padding: 0,
                                                                                margin: 0,
                                                                                height:32,
                                                                                elements: [
                                                                                    {
                                                                                        view: "pager",
                                                                                        gravity:3,
                                                                                        id: this.Id + "ReportsPanelTabs" + "FilesTable" + "Paging",
                                                                                        template: "{common.first()} {common.prev()} {common.pages()} {common.next()} {common.last()}",
                                                                                        master:false,
                                                                                        on: {
                                                                                            onAfterPageChange:function(Page)
                                                                                            {
                                                                                                _this.RefreshTaskData(Page);
                                                                                            }
                                                                                        }
                                                                                    },
                                                                                    {
                                                                                        view:"richselect",
                                                                                        id: this.Id + "ReportsPanelTabs" + "FilesTable" + "Paging" + "Size",
                                                                                        label:LangHelper.GetString(this.Id,"On page"),
                                                                                        labelWidth:120,
                                                                                        minWidth:220,
                                                                                        value:100,
                                                                                        options:PagingSizeOptions,
                                                                                        on: {
                                                                                            onChange:function()
                                                                                            {
                                                                                                _this.RefreshTaskData();
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                ]
                                                                            }
                                                                        ]
                                                                    },
                                                                    {
                                                                        id:this.Id + "ReportsPanelTabs" + "SpoofingTab",
                                                                        rows:[
                                                                            {
                                                                                view: "toolbar",
                                                                                height: 32,
                                                                                padding: 0,
                                                                                margin: 0,
                                                                                elements: [
                                                                                    {
                                                                                        view: "button",
                                                                                        id:this.Id+"SpoofingDataDownloadBtn",
                                                                                        type: "icon",
                                                                                        icon: "download",
                                                                                        css: "toolbar_round_btn",
                                                                                        width: 32,
                                                                                        tooltip: LangHelper.GetString(this.Id,"Download the log file"),
                                                                                        click: function (id, e, node)
                                                                                        {
                                                                                            _this.DownloadSpoofingData();
                                                                                        }
                                                                                    },
                                                                                    {},
                                                                                    {
                                                                                        view: "button",
                                                                                        id:this.Id+"SpoofingDataRefreshBtn",
                                                                                        type: "icon",
                                                                                        icon: "refresh",
                                                                                        css: "toolbar_round_btn",
                                                                                        width: 32,
                                                                                        tooltip: LangHelper.GetString(this.Id,"Refresh"),
                                                                                        click: function (id, e, node)
                                                                                        {
                                                                                            _this.RefreshSpoofingData();
                                                                                        }
                                                                                    }
                                                                                ]
                                                                            },
                                                                            {
                                                                                view:"codemirror-editor",
                                                                                id:_this.Id + "ReportsPanelTabs" + "Spoofing",
                                                                                value:""
                                                                            }
                                                                        ]
                                                                    }
                                                                ]
                                                            }
                                                        ]
                                                    }
                                                ]
                                            }
                                        },
                                        {
                                            gravity:0
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    ];

    this.Views = {
        AddTaskBtn:null,
        AddSubTaskBtn:null,

        PlayBtn:null,
        StopBtn:null,

        VhostEnableBtn:null,
        VhostDisableBtn:null,

        SiteExportBtn:null,
        ClearSiteFolderBtn:null,
        SiteFolderPropertiesBtn:null,

        TasksTable:null,
        TasksTablePaging:null,
        TasksTablePagingSize:null,

        CreateTaskContext:null,
        CreateTaskContextForms:{
            CreateTaskContextForm:null,
            CreateTaskContextFormTabsRecursiveForm:null,
            CreateTaskContextFormTabsInputForm:null,
            CreateTaskContextFormTabsDownloadForm:null,
            CreateTaskContextFormTabsDirectoriesForm:null,
            CreateTaskContextFormTabsHttpForm:null,
            CreateTaskContextFormTabsFtpForm:null
        },

        SiteFolderInfoContextForm:null,

        SiteSpoofingBtn:null,
        SpoofingContext:null,
        SpoofingContextForm:null,

        ReportsPanel:null,

        TaskDataInfoLabel:null,

        ReportsPanelTabs:null,

        ReportsPanelTabsLog:null,

        ReportsPanelTabsFilesTable:null,
        ReportsPanelTabsFilesTablePaging:null,
        ReportsPanelTabsFilesTablePagingSize:null,

        ReportsPanelTabsSpoofing:null
    };

    this.Subscriptions = {};

    this.TasksTableRowsSelectedCount = new Rx.BehaviorSubject(0);

    this.MonitorTimer = null;

    this.TaskDataMonitorTimer = null;
};

TrafficManagementSiteCloneViewController.prototype.Destroy = function ()
{
    var _this = this;

    clearTimeout(this.MonitorTimer);
    clearTimeout(this.TaskDataMonitorTimer);

    this.SaveAllTablesState();

    Object.keys(this.Subscriptions).forEach(function (key)
    {
        _this.Subscriptions[key].unsubscribe();
    });

    this.ViewModel.Destroy();
    delete this.ViewModel;

    this.Health.Destroy();
    delete this.Health;
};

TrafficManagementSiteCloneViewController.prototype.ViewDidLoad = function (Params)
{
    var _this = this;

    webix.ready(function () {
        $$(_this.Id + "LeftSideBar").select(_this.Id);
    });

    this.BindAll();

    this.RestoreAllTablesState();

    this.CheckHealth();
};

TrafficManagementSiteCloneViewController.prototype.BindAll = function (Params)
{
    var _this = this;

    this.Views.AddTaskBtn = $$(this.Id + "AddTaskBtn");
    this.Views.AddSubTaskBtn = $$(this.Id + "AddSubTaskBtn");

    this.Views.PlayBtn = $$(this.Id + "PlayBtn");
    this.Views.StopBtn = $$(this.Id + "StopBtn");

    this.Views.VhostEnableBtn = $$(this.Id + "VhostEnableBtn");
    this.Views.VhostDisableBtn = $$(this.Id + "VhostDisableBtn");

    this.Views.SiteExportBtn = $$(this.Id + "SiteExportBtn");
    this.Views.ClearSiteFolderBtn = $$(this.Id + "ClearSiteFolderBtn");
    this.Views.SiteFolderPropertiesBtn = $$(this.Id + "SiteFolderPropertiesBtn");

    this.Views.TasksTable = $$(this.Id + "TasksTable");
    this.Views.TasksTablePaging = $$(this.Id + "TasksTable" + "Paging");
    this.Views.TasksTablePagingSize = $$(this.Id + "TasksTable" + "Paging" + "Size");

    this.Views.CreateTaskContext = webix.ui(this.CreateTaskContextFormConfig);
    this.Views.CreateTaskContextForms.CreateTaskContextForm = $$(this.Id+"CreateTaskContextForm");
    this.Views.CreateTaskContextForms.CreateTaskContextFormTabsRecursiveForm = $$(this.Id+"CreateTaskContextFormTabsRecursiveForm");
    this.Views.CreateTaskContextForms.CreateTaskContextFormTabsInputForm = $$(this.Id+"CreateTaskContextFormTabsInputForm");
    this.Views.CreateTaskContextForms.CreateTaskContextFormTabsDownloadForm = $$(this.Id+"CreateTaskContextFormTabsDownloadForm");
    this.Views.CreateTaskContextForms.CreateTaskContextFormTabsDirectoriesForm = $$(this.Id+"CreateTaskContextFormTabsDirectoriesForm");
    this.Views.CreateTaskContextForms.CreateTaskContextFormTabsHttpForm = $$(this.Id+"CreateTaskContextFormTabsHttpForm");
    this.Views.CreateTaskContextForms.CreateTaskContextFormTabsFtpForm = $$(this.Id+"CreateTaskContextFormTabsFtpForm");

    this.Views.SiteFolderInfoContextForm = $$(this.Id+"SiteFolderInfoContextForm");

    this.Views.SiteSpoofingBtn = $$(this.Id+"SiteSpoofingBtn");
    this.Views.SpoofingContext = webix.ui(this.SpoofingContextFormConfig);
    this.Views.SpoofingContextForm = $$(this.Id+"SpoofingContextForm");

    this.Views.ReportsPanel = $$(this.Id+"ReportsPanel");

    this.Views.ReportsPanelTabs = $$(this.Id+"ReportsPanelTabs");

    this.Views.ReportsPanelTabsLog = $$(this.Id+"ReportsPanelTabsLog");

    this.Views.ReportsPanelTabsFilesTable = $$(this.Id+"ReportsPanelTabsFilesTable");
    this.Views.ReportsPanelTabsFilesTablePaging = $$(this.Id+"ReportsPanelTabsFilesTablePaging");
    this.Views.ReportsPanelTabsFilesTablePagingSize = $$(this.Id+"ReportsPanelTabsFilesTablePagingSize");

    this.Views.ReportsPanelTabsSpoofing = $$(this.Id+"ReportsPanelTabsSpoofing");

    this.Views.TaskDataInfoLabel = $$(this.Id + "TaskDataInfoLabel");

    this.Views.ReportsPanelTabs.attachEvent("onChange", function(New, Old)
    {
        _this.RefreshCurrentStatTab();
    });

    this.Subscriptions.SignalDicUpdatedSubscription = App.DicsManager.Signal[DicsEnum.UsersDic].subscribe(function (Updated)
    {
        if (Updated)
        {
            _this.RefreshTasksTable(0);
            //_this.AddTaskToTasksTable();
        }
    });

    this.Subscriptions.SignalTasksUpdatedSubscription = this.ViewModel.SignalTasksUpdated.subscribe(function (Updated)
    {
        if (Updated)
        {
            _this.UpdateTasksTable();

            _this.MonitorTasksTable();
        }
    });

    this.Subscriptions.TasksTableRowsSelectedCountSubscription = this.TasksTableRowsSelectedCount.subscribe(function (Value)
    {
        _this.TasksTableRowsSelectedCountSubscriptionHandler(Value);
    });

    this.Subscriptions.SignalTaskUpdatedSubscription = this.ViewModel.SignalTaskUpdated.subscribe(function (Task)
    {
        if(Task)
        {
            var selectedData = _this.GetTasksTableSelectedData();

            var rows = _this.Views.TasksTable.find(function(obj){
                return obj.wget_id == Task.wget_id;
            });

            if(rows && rows.length == 1)
            {
                _this.Views.TasksTable.updateItem(rows[0].id,  Task);

                if(selectedData && selectedData.wget_id == Task.wget_id && Task.status != 0)
                {
                    _this.OnTasksTableRowSelect();
                }
            }

        }
    });

    this.Subscriptions.SignalTaskLogsUpdatedSubscription = this.ViewModel.SignalTaskLogsUpdated.subscribe(function (Task)
    {
        if(Task)
        {
            _this.SetTaskLogsSelectedTask();
        }
    });

    this.Subscriptions.SignalTaskDataUpdatedSubscription = this.ViewModel.SignalTaskDataUpdated.subscribe(function (TaskId)
    {
        if (TaskId)
        {
            _this.UpdateTaskData(TaskId);
        }
    });

    this.Subscriptions.SignalTaskSpoofingDataUpdatedSubscription = this.ViewModel.SignalTaskSpoofingDataUpdated.subscribe(function (Task)
    {
        if(Task)
        {
            _this.SetSpoofingDataSelectedTask();
        }
    });

    this.Subscriptions.SignalHealthUpdatedSubscription = this.Health.HealthUpdated.subscribe(function (Updated)
    {
        if (Updated)
        {
            _this.HealthResult();
        }
    });
};

TrafficManagementSiteCloneViewController.prototype.AddTaskToTasksTable = function ()
{
    var _this = this;

    var model = new WgetTaskModel();

    _this.Views.TasksTable.add(model,0);

    _this.Views.TasksTable.select(model.id, false);
    _this.Views.TasksTable.showItem(model.id);

    _this.ShowTasksEditForm(model, _this.Views.TasksTable.getItemNode(model.id), "right");
};

TrafficManagementSiteCloneViewController.prototype.AddSubTaskToTasksTable = function ()
{
    var _this = this;

    var selectedModel = _this.GetTasksTableSelectedData();

    if(!selectedModel || !selectedModel.wget_id)
        return;

    var model = new WgetTaskModel({parent_id:selectedModel.wget_id});

    _this.Views.TasksTable.add(model,0,selectedModel.id);
    _this.Views.TasksTable.open(selectedModel.id);

    _this.Views.TasksTable.select(model.id, false);
    _this.Views.TasksTable.showItem(model.id);

    _this.ShowTasksEditForm(model, _this.Views.TasksTable.getItemNode(model.id), "right");
};

TrafficManagementSiteCloneViewController.prototype.UpdateTasksTable = function (Params)
{
    var _this = this;

    var dic = App.DicsManager.GetDicAsArray(DicsEnum.UsersDic);

    this.Views.TasksTable.getColumnConfig("user_id").header[1].options = App.DicsManager.GetDicAsArray(DicsEnum.UsersDic);

    this.Views.TasksTable.clearAll();
    this.Views.TasksTable.parse(_this.ViewModel.GetTasks());

    this.Views.TasksTablePagingSize.setValue(this.ViewModel.TasksFilter.total.size);

    this.Views.TasksTablePaging.define(this.ViewModel.TasksFilter.total);
    this.Views.TasksTablePaging.refresh();
};

TrafficManagementSiteCloneViewController.prototype.RefreshTasksTable = function (Page)
{
    var _this = this;

    var state = this.Views.TasksTable.getState();

    var size = this.Views.TasksTablePagingSize.getValue();

    this.ViewModel.RefershTasks({page:Page, filter:state.filter, size:size, sort:state.sort ? state.sort:null, callback:function () {}})
};

TrafficManagementSiteCloneViewController.prototype.OnTasksTableRowSelect = function ()
{
    var data = this.GetTasksTableSelectedData();

    this.TasksTableRowsSelectedCount.next(data?(Array.isArray(data)?data.length:1):0);
};

TrafficManagementSiteCloneViewController.prototype.OnTasksTableRowUnSelect = function ()
{
    var data = this.GetTasksTableSelectedData();
    this.TasksTableRowsSelectedCount.next(data?(Array.isArray(data)?data.length:1):0);
};

TrafficManagementSiteCloneViewController.prototype.ShowTasksEditForm = function (Data, Node, Pos)
{
    var _this = this;

    this.ClearForms();

    if(Data && Data.form_data)
    {
        var formParams = new WgetTaskFormModel(JSON.parse(Data.form_data));

        if(formParams)
        {
            this.Views.CreateTaskContextForms.CreateTaskContextForm.setValues(new WgetTaskFormModel(formParams, true, false));
            this.Views.CreateTaskContextForms.CreateTaskContextFormTabsRecursiveForm.setValues(new WgetTaskFormCmdRecursiveModel(formParams.cmd));
            this.Views.CreateTaskContextForms.CreateTaskContextFormTabsInputForm.setValues(new WgetTaskFormCmdInputModel(formParams.cmd));
            this.Views.CreateTaskContextForms.CreateTaskContextFormTabsDownloadForm.setValues(new WgetTaskFormCmdDownloadModel(formParams.cmd));
            this.Views.CreateTaskContextForms.CreateTaskContextFormTabsDirectoriesForm.setValues(new WgetTaskFormCmdDirectoriesModel(formParams.cmd));
            this.Views.CreateTaskContextForms.CreateTaskContextFormTabsHttpForm.setValues(new WgetTaskFormCmdHttpModel(formParams.cmd));
            this.Views.CreateTaskContextForms.CreateTaskContextFormTabsFtpForm.setValues(new WgetTaskFormCmdFtpModel(formParams.cmd));
        }
    }

    this.Views.CreateTaskContext.show(Node, {pos:Pos});
};

TrafficManagementSiteCloneViewController.prototype.DeleteTask = function (RowId)
{
    var _this = this;

    var taskData = this.Views.TasksTable.getItem(RowId);
    taskData.valid = true;

    if(taskData.wget_id > 0)
    {
        this.ViewModel.DeleteTask({task:taskData, callback:function (R)
        {
            if(R.success)
            {
                _this.Views.TasksTable.remove(RowId);

                webix.message(LangHelper.GetString(_this.Id,"Deleted"));
            }
            else
            {
                taskData.valid = false;
                _this.Views.TasksTable.validate(RowId);
                if(R.error)
                    webix.message({text:R.error.msg,type:"error"});
            }
        }});
    }
    else
    {
        this.Views.TasksTable.remove(RowId);
    }
};

TrafficManagementSiteCloneViewController.prototype.GetTasksTableSelectedData = function ()
{
    return this.Views.TasksTable.getSelectedItem();
};

TrafficManagementSiteCloneViewController.prototype.SaveTask = function ()
{
    var _this = this;

    var taskData = this.GetTasksTableSelectedData();

    if(this.ValidateForms())
    {
        taskData.valid = true;

        this.Views.CreateTaskContext.hide();

        var params = {};

        Object.keys(this.Views.CreateTaskContextForms).forEach(function (Key)
        {
            webix.extend(params, _this.Views.CreateTaskContextForms[Key].getValues());
        });

        params.cmd = params;

        var formData = new WgetTaskFormModel(params);

        this.ViewModel.SaveTask({task:taskData, task_form:formData, callback:function (R)
        {
            if(R.success && R.data && R.data.task)
            {
                _this.Views.TasksTable.updateItem(taskData.id,  new WgetTaskModel(R.data.task));

                _this.MonitorTasksTable();

                webix.message(LangHelper.GetString(_this.Id,"Task saved"));
            }
            else
            {
                taskData.valid = false;
                _this.Views.TasksTable.validate(taskData.id);

                if(R.error)
                    webix.message({text:R.error.msg,type:"error"});
            }

            _this.OnTasksTableRowSelect();
        }});

        this.ClearForms();
    }
    else
    {
        taskData.valid = false;
    }

    this.Views.TasksTable.validate(taskData.id);
};

TrafficManagementSiteCloneViewController.prototype.ValidateForms = function ()
{
    var _this = this;

    var valid = true;

    Object.keys(this.Views.CreateTaskContextForms).every(function (Key)
    {
        valid = _this.Views.CreateTaskContextForms[Key].validate();

        return valid;
    });

    return valid;
};

TrafficManagementSiteCloneViewController.prototype.ClearForms = function ()
{
    var _this = this;

    Object.keys(this.Views.CreateTaskContextForms).forEach(function (Key)
    {
        _this.Views.CreateTaskContextForms[Key].clear();
        _this.Views.CreateTaskContextForms[Key].clearValidation();
    });
};

TrafficManagementSiteCloneViewController.prototype.ParamsToTaskFormModel = function(Params)
{
    var model = new WgetTaskFormModel(Params);

    return model;
};

TrafficManagementSiteCloneViewController.prototype.MonitorTasksTable = function()
{
    var _this = this;

    clearTimeout(this.MonitorTimer);

    var tasks = this.Views.TasksTable.serialize();

    var tasksIdsToMonitor = [];

    var addToMonitor = function (Tasks)
    {
        Tasks.forEach(function (Task)
        {
            if(Task.wget_id && Task.status == WgetTaskStatusEnum.progress)
            {
                tasksIdsToMonitor.push(Task.wget_id)
            }

            if(Task.data && Task.data.length)
            {
                addToMonitor(Task.data);
            }
        });
    };

    addToMonitor(tasks);

    if(tasksIdsToMonitor.length)
    {
        this.ViewModel.RefershTasks({wgets_ids:tasksIdsToMonitor, callback:function ()
        {
            _this.MonitorTimer = setTimeout(function ()
            {
                _this.MonitorTasksTable();
            }, 10000);
        }});
    }
};

TrafficManagementSiteCloneViewController.prototype.RunTask = function ()
{
    var _this = this;

    var taskData = this.GetTasksTableSelectedData();

    taskData.valid = true;

    this.ViewModel.RunTask({task:taskData, callback:function (R)
    {
        if(R.success && R.data && R.data.task && R.data.task.success)
        {
            _this.Views.TasksTable.updateItem(taskData.id,  new WgetTaskModel(R.data.task.data));

            _this.MonitorTasksTable();

            if(R.data.task.data.status == WgetTaskStatusEnum.progress)
            {
                webix.message(LangHelper.GetString(_this.Id,"Scan started"));
            }
            else
            {
                webix.message({text:LangHelper.GetString(this.Id,"Scan not running"), type:"debug"});
            }
        }
        else
        {
            taskData.valid = false;

            _this.Views.TasksTable.validate(taskData.id);

            if(R.error)
                webix.message({text:R.error.msg,type:"error"});
        }

        _this.OnTasksTableRowSelect();
    }});
};

TrafficManagementSiteCloneViewController.prototype.StopTask = function ()
{
    var _this = this;

    var taskData = this.GetTasksTableSelectedData();

    taskData.valid = true;

    this.ViewModel.StopTask({task:taskData, callback:function (R)
    {
        if(R.success)
        {
            if(R.data && R.data.task)
            {
                _this.Views.TasksTable.updateItem(taskData.id,  new WgetTaskModel(R.data.task));

                webix.message(LangHelper.GetString(_this.Id,"Scan stopped"));
            }
            else
            {
                webix.message(LangHelper.GetString(_this.Id,"Scan not running"));
            }

        }
        else
        {
            taskData.valid = false;

            _this.Views.TasksTable.validate(taskData.id);

            if(R.error)
                webix.message({text:R.error.msg,type:"error"});
        }

        _this.OnTasksTableRowSelect();
    }});
};

TrafficManagementSiteCloneViewController.prototype.RefreshCurrentStatTab = function()
{
    var current = this.Views.ReportsPanelTabs.getValue();

    if(current == this.Id + "ReportsPanelTabs" + "LogTab")
    {
        this.RefreshTaskLogs();
    }

    if(current == this.Id + "ReportsPanelTabs" + "FilesTab")
    {
        this.RefreshTaskData();
    }

    if(current == this.Id + "ReportsPanelTabs" + "SpoofingTab")
    {
        this.RefreshSpoofingData();
    }
};

TrafficManagementSiteCloneViewController.prototype.RefreshTaskLogs = function()
{
    var taskData = this.GetTasksTableSelectedData();

    if(taskData)
    {
        this.ViewModel.RefreshTaskLogs(taskData);
    }
};

TrafficManagementSiteCloneViewController.prototype.RefreshTaskData = function(Page)
{
    var taskData = this.GetTasksTableSelectedData();

    if(taskData)
    {
        var state = this.Views.ReportsPanelTabsFilesTable.getState();

        var size = this.Views.ReportsPanelTabsFilesTablePagingSize.getValue();

        this.ViewModel.RefreshTaskData(taskData, {page:Page, filter:state.filter, size:size, sort:state.sort ? state.sort:null, callback:function ()
        {

        }});
    }
};

TrafficManagementSiteCloneViewController.prototype.SetTaskLogsSelectedTask = function()
{
    var _this = this;

    var taskData = this.GetTasksTableSelectedData();

    if(taskData)
    {
        var logsModel = this.ViewModel.GetTaskLogs(taskData);

        this.Views.ReportsPanelTabsLog.setValue(logsModel.content);
    }
};

TrafficManagementSiteCloneViewController.prototype.UpdateTaskData = function (TaskId)
{
    var _this = this;

    var data = _this.ViewModel.GetTaskData(TaskId);

    var table, paging, size;

    table = this.Views.ReportsPanelTabsFilesTable;
    paging = this.Views.ReportsPanelTabsFilesTablePaging;
    size = this.Views.ReportsPanelTabsFilesTablePagingSize;


    table.clearAll();
    table.parse(data.data);

    size.setValue(this.ViewModel.TaskDataFilter[TaskId].total.size);

    paging.define(this.ViewModel.TaskDataFilter[TaskId].total);
    paging.refresh();
};

TrafficManagementSiteCloneViewController.prototype.SaveTableState = function(Table)
{
    var tableState = Table.getState();
    var tableId = Table.config.id;

    var tableColunsToSave = {
        hidden:tableState.hidden,
        ids:tableState.ids,
        order:tableState.order
    };

    webix.storage.local.put(tableId+"Columns", tableColunsToSave);
};

TrafficManagementSiteCloneViewController.prototype.RestoreTableState = function(Table)
{

    var tableState = Table.getState();
    var tableId = Table.config.id;

    var columnsRestoredState = webix.storage.local.get(tableId+"Columns");

    if(columnsRestoredState)
    {
        tableState.hidden = columnsRestoredState.hidden;
        tableState.ids = columnsRestoredState.ids;
        tableState.order = columnsRestoredState.order;

        Table.setState(tableState);
    }
};

TrafficManagementSiteCloneViewController.prototype.SaveAllTablesState = function()
{
    this.SaveTableState(this.Views.ReportsPanelTabsFilesTable);
};

TrafficManagementSiteCloneViewController.prototype.RestoreAllTablesState = function()
{
    this.RestoreTableState(this.Views.ReportsPanelTabsFilesTable);
};

TrafficManagementSiteCloneViewController.prototype.ExportTaskDataToExcel = function ()
{
    var selectedData = this.GetTasksTableSelectedData();

    if(selectedData && selectedData.wget_id)
    {
        var title = LangHelper.GetString(this.Id,"File statistics")+" - " + selectedData.title;

        webix.toExcel(this.Views.ReportsPanelTabsFilesTable, {
            filename: LangHelper.GetString("AppView","Title") + " " + title,
            name: LangHelper.GetString("AppView","Title") + " " + title,
            filterHTML:true
        });
    }
};

TrafficManagementSiteCloneViewController.prototype.DownloadLog = function ()
{
    var selectedData = this.GetTasksTableSelectedData();

    if(selectedData && selectedData.wget_id)
    {
        this.ViewModel.DownloadLog(selectedData);
    }
};

TrafficManagementSiteCloneViewController.prototype.DownloadSiteZip = function ()
{
    var _this = this;

    var selectedData = this.GetTasksTableSelectedData();

    if(selectedData && selectedData.wget_id)
    {
        selectedData.download = 1;

        this.TasksTableRowsSelectedCountSubscriptionHandler(1, true);

        this.ViewModel.DownloadSiteZip({task:selectedData, callback:function (R)
        {
            selectedData.download = 0;
            _this.TasksTableRowsSelectedCountSubscriptionHandler(1, true);
        }});
    }
};

TrafficManagementSiteCloneViewController.prototype.TasksTableRowsSelectedCountSubscriptionHandler = function (Value, NoRefresh)
{
    var _this = this;

    var _disableAll = function ()
    {
        _this.Views.ReportsPanel.disable();
        _this.Views.AddSubTaskBtn.disable();
        _this.Views.PlayBtn.disable();
        _this.Views.StopBtn.disable();
        _this.Views.VhostEnableBtn.disable();
        _this.Views.VhostDisableBtn.disable();
        _this.Views.SiteExportBtn.disable();
        _this.Views.ClearSiteFolderBtn.disable();
        _this.Views.SiteFolderPropertiesBtn.disable();
        _this.Views.SiteSpoofingBtn.disable();
    };

    if(Value != 1)
    {
        _disableAll();
        return;
    }

    var selectedData = _this.GetTasksTableSelectedData();

    if(!selectedData)
    {
        _disableAll();
        return;
    }


    if(selectedData.status || selectedData.data_count)
    {
        _this.Views.ReportsPanel.enable();

        if(!NoRefresh)
            _this.RefreshCurrentStatTab();

        this.SetTaskDataInfoLabel()
    }
    else
    {
        _this.Views.ReportsPanel.disable("");
    }

    _this.Views.AddSubTaskBtn.enable();
    _this.Views.SiteFolderPropertiesBtn.enable();
    _this.Views.SiteSpoofingBtn.enable();

    switch (parseInt(selectedData.status))
    {
        case WgetTaskStatusEnum.new:
        case WgetTaskStatusEnum.stoped:
        case WgetTaskStatusEnum.error:

            if(selectedData.wget_id)
            {
                !selectedData.download ? _this.Views.PlayBtn.enable() : _this.Views.PlayBtn.disable();
                !selectedData.download ? _this.Views.SiteExportBtn.enable() : _this.Views.SiteExportBtn.disable();
                !selectedData.download ? _this.Views.ClearSiteFolderBtn.enable() : _this.Views.ClearSiteFolderBtn.disable();
            }
            else
            {
                _this.Views.PlayBtn.disable();
                _this.Views.SiteExportBtn.disable();
                _this.Views.ClearSiteFolderBtn.disable();
            }


            _this.Views.StopBtn.disable();

            break;

        case WgetTaskStatusEnum.progress:
            _this.Views.PlayBtn.disable();
            _this.Views.StopBtn.enable();
            _this.Views.SiteExportBtn.disable();
            _this.Views.ClearSiteFolderBtn.disable();
            break;
        default:
            break;
    }

    switch (parseInt(selectedData.vhost))
    {
        case WgetTaskVhostStatusEnum.enabled:
            _this.Views.VhostEnableBtn.disable();
            _this.Views.VhostDisableBtn.enable();
            break;

        case WgetTaskVhostStatusEnum.disabled:
            if(selectedData.wget_id)
            {
                _this.Views.VhostEnableBtn.enable();
                _this.Views.VhostDisableBtn.disable();
            }

            break;

        default:
            break;
    }
};

TrafficManagementSiteCloneViewController.prototype.ClearSiteFolder = function ()
{
    var _this = this;

    var selectedData = this.GetTasksTableSelectedData();

    selectedData.valid = true;

    if(selectedData && selectedData.wget_id)
    {
        this.ViewModel.ClearSiteFolder({task:selectedData, callback:function (R)
        {
            if(R.success)
            {
                if(R.data)
                {
                    _this.Views.TasksTable.updateItem(selectedData.id,  new WgetTaskModel(R.data));
                }

                webix.message(LangHelper.GetString(_this.Id,"Folder is cleared"))
            }
            else
            {
                webix.message({type:"error", text:R.error.msg});
            }

            _this.OnTasksTableRowSelect();
        }});
    }
};

TrafficManagementSiteCloneViewController.prototype.GetSiteFolderInfo = function ()
{
    var _this = this;

    var selectedData = this.GetTasksTableSelectedData();

    if(selectedData && selectedData.wget_id)
    {
        this.ViewModel.GetSiteFolderInfo({task:selectedData, callback:function (R)
        {
            if(R.success)
            {
                _this.Views.SiteFolderInfoContextForm.setValues(R.data);
            }
            else
            {
                webix.message({type:"error", text:R.error.msg});
            }
        }});
    }
};

TrafficManagementSiteCloneViewController.prototype.SetTaskDataInfoLabel = function()
{
    var taskData = this.GetTasksTableSelectedData();

    if(!taskData)
        return;

    var format =  webix.Date.dateToStr("%d.%m.%Y %H:%i");
    var date =  format(taskData.date);

    var report_count = 0;
    var maxStatus = 0;

    var status = "";

    if(WgetTaskStatusesDic[taskData.status])
        status = "<span class=\"status status_" + WgetTaskStatusColorsEnum[taskData.status] + "\">" + WgetTaskStatusesDic[taskData.status].value + "</span>";

    var title = taskData.title?taskData.title:LangHelper.GetString(this.Id,"Task") + " "+taskData.task_id;

    var text = title + " "+LangHelper.GetString(this.Id,"from")+" " + date + ". "+LangHelper.GetString(this.Id,"Status")+": " + status + ".";

    this.Views.TaskDataInfoLabel.setValue(text);
};

TrafficManagementSiteCloneViewController.prototype.EnableVirtualHost = function()
{
    var _this = this;

    var taskData = this.GetTasksTableSelectedData();

    taskData.valid = true;

    this.ViewModel.EnableVirtualHost({task:taskData, callback:function (R)
    {
        if(R.success && R.data)
        {
            _this.Views.TasksTable.updateItem(taskData.id,  new WgetTaskModel(R.data));

            _this.MonitorTasksTable();

            if(R.data.vhost == WgetTaskVhostStatusEnum.enabled)
            {
                webix.message(LangHelper.GetString(_this.Id,"Virtual host enabled"));
            }
            else
            {
                webix.message({text:LangHelper.GetString(this.Id,"Virtual host not enabled"), type:"debug"});
            }
        }
        else
        {
            taskData.valid = false;

            _this.Views.TasksTable.validate(taskData.id);

            if(R.error)
                webix.message({text:R.error.msg,type:"error"});
        }

        _this.OnTasksTableRowSelect();
    }});
};

TrafficManagementSiteCloneViewController.prototype.DisableVirtualHost = function()
{
    var _this = this;

    var taskData = this.GetTasksTableSelectedData();

    taskData.valid = true;

    this.ViewModel.DisableVirtualHost({task:taskData, callback:function (R)
    {
        if(R.success)
        {
            if(R.data)
            {
                _this.Views.TasksTable.updateItem(taskData.id,  new WgetTaskModel(R.data));

                webix.message(LangHelper.GetString(_this.Id,"Virtual host disabled"));
            }
            else
            {
                webix.message(LangHelper.GetString(_this.Id,"Virtual host not disabled"));
            }

        }
        else
        {
            taskData.valid = false;

            _this.Views.TasksTable.validate(taskData.id);

            if(R.error)
                webix.message({text:R.error.msg,type:"error"});
        }

        _this.OnTasksTableRowSelect();
    }});
};

TrafficManagementSiteCloneViewController.prototype.CheckHealth = function()
{
    this.Health.Refresh({wget: 1});
};

TrafficManagementSiteCloneViewController.prototype.HealthResult = function()
{
    var health = this.Health.GetHealth();

    if(health.wget)
    {
        if(!health.wget.success)
            webix.message({text:LangHelper.GetString("AppView","Cloning a website") + "<br/><br/>" + health.wget.error.msg + "<br/><br/>" + LangHelper.GetString("Health","Verify services health"), type:"error", expire: 300000});
    }
};

TrafficManagementSiteCloneViewController.prototype.SpoofSite = function()
{
    var _this = this;

    var task = this.GetTasksTableSelectedData();

    if(!task)
        return;

    if(this.Views.SpoofingContextForm.validate())
    {
        this.Views.SpoofingContext.hide();

        var form = this.Views.SpoofingContextForm.getValues();

        this.ViewModel.SpoofSite({task:task, form:form, callback:function (R)
        {
            if(R.success)
            {
                webix.message(LangHelper.GetString(_this.Id,"Site is spoofed"));
            }
            else
            {
                if(R.error)
                    webix.message({text:R.error.msg,type:"error"});
            }
        }});
    }
};

TrafficManagementSiteCloneViewController.prototype.RefreshSpoofingData = function()
{
    var taskData = this.GetTasksTableSelectedData();

    if(taskData)
    {
        this.ViewModel.RefreshSpoofingData(taskData);
    }
};

TrafficManagementSiteCloneViewController.prototype.SetSpoofingDataSelectedTask = function()
{
    var _this = this;

    var taskData = this.GetTasksTableSelectedData();

    if(taskData)
    {
        var logsModel = this.ViewModel.GetSpoofingData(taskData);

        this.Views.ReportsPanelTabsSpoofing.setValue(logsModel.content);
    }
};

TrafficManagementSiteCloneViewController.prototype.DownloadSpoofingData = function()
{
    var selectedData = this.GetTasksTableSelectedData();

    if(selectedData && selectedData.wget_id)
    {
        this.ViewModel.DownloadSpoofingData(selectedData);
    }
};
function NetworkAuditViewController()
{
    var _this = this;

    this.Id = "NetworkAudit";

    this.ViewModel = new NetworkAuditManager();

    this.Health = new SormHealthManager();

    this.TaskDeleteContext = webix.ui({
        view:"popup",
        width:300,
        height:180,
        body:{
            view:"form",
            borderless:true,
            elements:[
                {
                    template:LangHelper.GetString(this.Id,"Are you sure you want to delete the profile?")
                },
                {
                    cols:[
                        {},
                        {
                            view:"button",
                            label:LangHelper.GetString(this.Id,"Cancel"),
                            click:function () {
                                _this.TaskDeleteContext.hide();
                            }
                        },
                        {
                            view:"button",
                            type:"form",
                            label:LangHelper.GetString(this.Id,"Delete"),
                            click:function () {
                                _this.TaskDeleteContext.hide();

                                var selected = _this.GetTasksTableSelectedData();

                                if(selected)
                                    _this.DeleteTask(selected.id);
                            }
                        }
                    ]
                }
            ]
        }
    });

    this.CreateTaskContextFormConfig = {
        view: "popup",
        id: this.Id + "CreateTaskContext",
        width: 960,
        maxHeight: 600,
        modal:true,
        body:{
            rows:[
                BlockHeaderTemplate({title:LangHelper.GetString(this.Id,"Node scan profile"), icon:"file"}),
                {
                    view: "accordion",
                    multi: "mixed",
                    cols: [
                        {
                            header:LangHelper.GetString(this.Id,"Main settings"),
                            gravity:2,
                            body:{
                                rows:[
                                    {
                                        id:this.Id + "CreateTaskContextForm",
                                        view: "form",
                                        borderless: true,
                                        margin: 0,
                                        padding: 8,
                                        elements: [
                                            BlockHeaderTemplate({title:LangHelper.GetString(this.Id,"Node scan profile"), icon:"file"}),
                                            {
                                                view: "text",
                                                name: "title",
                                                labelWidth:120,
                                                label: LangHelper.GetString(this.Id,"Description"),
                                                tooltip:LangHelper.GetString(this.Id,"Task title / description")
                                            },
                                            {
                                                view: "textarea",
                                                name: "target",
                                                labelWidth:120,
                                                label: LangHelper.GetString(this.Id,"Nodes"),
                                                tooltip: LangHelper.GetString(this.Id,"Host names, IP-addresses, networks...")
                                            },
                                            {
                                                view: "richselect",
                                                name: "template",
                                                labelWidth:120,
                                                label:LangHelper.GetString(this.Id,"Template"),
                                                tooltip:LangHelper.GetString(this.Id,"Apply template"),
                                                options: Helper.DictToArr(NetworkAuditTemplatesDic),
                                                value:"0",
                                                on:{
                                                    onChange:function(N, O)
                                                    {
                                                        _this.ApplyContextFormTemplate(N, O);
                                                    }
                                                }
                                            },
                                            {
                                                cols:[
                                                    {},
                                                    {
                                                        view:"button",
                                                        label:LangHelper.GetString(this.Id,"Cancel"),
                                                        click:function ()
                                                        {
                                                            _this.Views.CreateTaskContext.hide();
                                                        }
                                                    },
                                                    {
                                                        view:"button",
                                                        type:"form",
                                                        label:LangHelper.GetString(this.Id,"Save"),
                                                        click:function ()
                                                        {
                                                            _this.SaveTask();
                                                        }
                                                    }
                                                ]
                                            }
                                        ]
                                    }
                                ]
                            }
                        },
                        {view:"resizer"},
                        {
                            header:LangHelper.GetString(this.Id,"Extra options"),
                            gravity:3,
                            body:{
                                view:"tabview",
                                id:this.Id + "CreateTaskContextFormTabs",
                                cells:[
                                    {
                                        header: LangHelper.GetString(this.Id,"Hosts"),
                                        id: this.Id + "CreateTaskContextFormTabsHostDiscovery",
                                        body:{
                                            id: this.Id + "CreateTaskContextFormTabsHostDiscoveryForm",
                                            view: "form",
                                            borderless: true,
                                            margin: 0,
                                            padding: 8,
                                            elements:[
                                                {
                                                    rows:[
                                                        {
                                                            cols:[
                                                                {
                                                                    rows:[
                                                                        {
                                                                            view: "checkbox",
                                                                            name: "sL",
                                                                            labelRight: LangHelper.GetString(this.Id,"List scan"),
                                                                            tooltip:LangHelper.GetString(this.Id,"Simply list targets to scan"),
                                                                            value:0,
                                                                            labelWidth:0
                                                                        },
                                                                        {
                                                                            view: "checkbox",
                                                                            name: "sn",
                                                                            labelRight: LangHelper.GetString(this.Id,"Ping scan"),
                                                                            tooltip:LangHelper.GetString(this.Id,"Disable port scan"),
                                                                            value:0,
                                                                            labelWidth:0
                                                                        },
                                                                        {
                                                                            view: "checkbox",
                                                                            name: "Pn",
                                                                            labelRight: LangHelper.GetString(this.Id,"Treat all hosts as online"),
                                                                            tooltip:LangHelper.GetString(this.Id,"Skip host discovery"),
                                                                            value:0,
                                                                            labelWidth:0
                                                                        },
                                                                        {
                                                                            view: "checkbox",
                                                                            name: "n",
                                                                            labelRight: LangHelper.GetString(this.Id,"No DNS resolution"),
                                                                            tooltip:LangHelper.GetString(this.Id,"Never do reverse DNS resolution on the active IP addresses it finds"),
                                                                            value:0,
                                                                            labelWidth:0
                                                                        },
                                                                        {
                                                                            view: "checkbox",
                                                                            name: "system_dns",
                                                                            labelRight: LangHelper.GetString(this.Id,"OS's DNS"),
                                                                            tooltip:LangHelper.GetString(this.Id,"Use OS's DNS resolver"),
                                                                            value:0,
                                                                            labelWidth:0
                                                                        },
                                                                        {
                                                                            view: "checkbox",
                                                                            name: "PS_enabled",
                                                                            labelRight: LangHelper.GetString(this.Id,"TCP SYN Ping"),
                                                                            tooltip:LangHelper.GetString(this.Id,"This option sends an empty TCP packet with the SYN flag set"),
                                                                            value:0,
                                                                            labelWidth:0
                                                                        },
                                                                        {
                                                                            view: "text",
                                                                            name: "PS",
                                                                            labelWidth:0,
                                                                            tooltip:LangHelper.GetString(this.Id,"Ports list: 22-25,80,113,1050,35000")
                                                                        },
                                                                        {
                                                                            view: "checkbox",
                                                                            name: "PA_enabled",
                                                                            labelRight: LangHelper.GetString(this.Id,"TCP ACK Ping"),
                                                                            tooltip:LangHelper.GetString(this.Id,"This option sends an empty TCP packet with the ACK  flag set"),
                                                                            value:0,
                                                                            labelWidth:0
                                                                        },
                                                                        {
                                                                            view: "text",
                                                                            name: "PA",
                                                                            labelWidth:0,
                                                                            tooltip:LangHelper.GetString(this.Id,"Ports list: 22-25,80,113,1050,35000")
                                                                        },
                                                                        {
                                                                            view: "checkbox",
                                                                            name: "PU_enabled",
                                                                            labelRight: LangHelper.GetString(this.Id,"UDP Ping"),
                                                                            tooltip:LangHelper.GetString(this.Id,"Sends a UDP packet to the given ports"),
                                                                            value:0,
                                                                            labelWidth:0
                                                                        },
                                                                        {
                                                                            view: "text",
                                                                            name: "PU",
                                                                            labelWidth:0,
                                                                            tooltip:LangHelper.GetString(this.Id,"Ports list: 22-25,80,113,1050,35000")
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    rows:[
                                                                        {
                                                                            view: "checkbox",
                                                                            name: "PE",
                                                                            labelRight: LangHelper.GetString(this.Id,"ICMP echo"),
                                                                            tooltip:LangHelper.GetString(this.Id,"ICMP echo"),
                                                                            value:0,
                                                                            labelWidth:0
                                                                        },
                                                                        {
                                                                            view: "checkbox",
                                                                            name: "PP",
                                                                            labelRight: LangHelper.GetString(this.Id,"Timestamp request"),
                                                                            tooltip:LangHelper.GetString(this.Id,"Timestamp request"),
                                                                            value:0,
                                                                            labelWidth:0
                                                                        },
                                                                        {
                                                                            view: "checkbox",
                                                                            name: "PM",
                                                                            labelRight: LangHelper.GetString(this.Id,"Netmask request"),
                                                                            tooltip:LangHelper.GetString(this.Id,"Netmask request"),
                                                                            value:0,
                                                                            labelWidth:0
                                                                        },
                                                                        {
                                                                            view: "checkbox",
                                                                            name: "R",
                                                                            labelRight: LangHelper.GetString(this.Id,"DNS resolution for all targets"),
                                                                            tooltip:LangHelper.GetString(this.Id,"Always do reverse DNS resolution on the target IP addresses"),
                                                                            value:0,
                                                                            labelWidth:0
                                                                        },
                                                                        {
                                                                            view: "checkbox",
                                                                            name: "traceroute",
                                                                            labelRight: LangHelper.GetString(this.Id,"Host path"),
                                                                            tooltip:LangHelper.GetString(this.Id,"Trace hop path to each host"),
                                                                            value:0,
                                                                            labelWidth:0
                                                                        },
                                                                        {
                                                                            view: "checkbox",
                                                                            name: "PO_enabled",
                                                                            labelRight: LangHelper.GetString(this.Id,"IP Protocol ping"),
                                                                            tooltip:LangHelper.GetString(this.Id,"Sends IP packets with the specified protocol number set in their IP header"),
                                                                            value:0,
                                                                            labelWidth:0
                                                                        },
                                                                        {
                                                                            view: "text",
                                                                            name: "PO",
                                                                            labelWidth:0,
                                                                            tooltip:LangHelper.GetString(this.Id,"Protocols list")
                                                                        },
                                                                        {
                                                                            view: "checkbox",
                                                                            name: "dns_servers_enabled",
                                                                            labelRight: LangHelper.GetString(this.Id,"DNS servers"),
                                                                            tooltip:LangHelper.GetString(this.Id,"Specify custom DNS servers"),
                                                                            value:0,
                                                                            labelWidth:0
                                                                        },
                                                                        {
                                                                            view: "text",
                                                                            name: "dns_servers",
                                                                            labelWidth:0,
                                                                            tooltip:LangHelper.GetString(this.Id,"<serv1[,serv2],...>")
                                                                        },
                                                                        {
                                                                            view: "checkbox",
                                                                            name: "PY_enabled",
                                                                            labelRight: LangHelper.GetString(this.Id,"SCTP INIT Ping"),
                                                                            tooltip:LangHelper.GetString(this.Id,"This option sends an SCTP packet containing a minimal INIT chunk"),
                                                                            value:0,
                                                                            labelWidth:0
                                                                        },
                                                                        {
                                                                            view: "text",
                                                                            name: "PY",
                                                                            labelWidth:0,
                                                                            tooltip:LangHelper.GetString(this.Id,"Ports list: 22-25,80,113,1050,35000")
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    },
                                    {
                                        header: LangHelper.GetString(this.Id,"Ports"),
                                        id: this.Id + "CreateTaskContextFormTabsScanTechniques",
                                        body:{
                                            id: this.Id + "CreateTaskContextFormTabsScanTechniquesForm",
                                            view: "form",
                                            borderless: true,
                                            margin: 0,
                                            padding: 8,
                                            elements:[
                                                {
                                                    cols:[
                                                        {
                                                            rows:[
                                                                {
                                                                    view: "checkbox",
                                                                    name: "sS",
                                                                    labelRight: LangHelper.GetString(this.Id,"TCP SYN scan"),
                                                                    tooltip:LangHelper.GetString(this.Id,"TCP SYN scan"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "checkbox",
                                                                    name: "sT",
                                                                    labelRight: LangHelper.GetString(this.Id,"TCP connect scan"),
                                                                    tooltip:LangHelper.GetString(this.Id,"TCP connect scan is the default TCP scan type when SYN scan is not an option"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "checkbox",
                                                                    name: "sU",
                                                                    labelRight: LangHelper.GetString(this.Id,"UDP scans"),
                                                                    tooltip:LangHelper.GetString(this.Id,"UDP scans"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "checkbox",
                                                                    name: "sY",
                                                                    labelRight: LangHelper.GetString(this.Id,"SCTP INIT scan"),
                                                                    tooltip:LangHelper.GetString(this.Id,"SCTP is a relatively new alternative to the TCP and UDP protocols"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "checkbox",
                                                                    name: "sN",
                                                                    labelRight: LangHelper.GetString(this.Id,"TCP Null scan"),
                                                                    tooltip:LangHelper.GetString(this.Id,"TCP Null scan"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "checkbox",
                                                                    name: "sF",
                                                                    labelRight: LangHelper.GetString(this.Id,"FIN scan"),
                                                                    tooltip:LangHelper.GetString(this.Id,"FIN scan"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "checkbox",
                                                                    name: "sX",
                                                                    labelRight: LangHelper.GetString(this.Id,"Xmas scan"),
                                                                    tooltip:LangHelper.GetString(this.Id,"Xmas scan"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "checkbox",
                                                                    name: "sZ",
                                                                    labelRight: LangHelper.GetString(this.Id,"SCTP COOKIE ECHO scan"),
                                                                    tooltip:LangHelper.GetString(this.Id,"SCTP COOKIE ECHO scan"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "checkbox",
                                                                    name: "sO",
                                                                    labelRight: LangHelper.GetString(this.Id,"IP protocol scan"),
                                                                    tooltip:LangHelper.GetString(this.Id,"IP protocol scan"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {}
                                                            ]
                                                        },
                                                        {
                                                            rows:[
                                                                {
                                                                    view: "checkbox",
                                                                    name: "sI_enabled",
                                                                    labelRight: LangHelper.GetString(this.Id,"Idle scan"),
                                                                    tooltip:LangHelper.GetString(this.Id,"This advanced scan method allows for a truly blind TCP port scan of the target (meaning no packets are sent to the target from your real IP address)"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "text",
                                                                    name: "sI",
                                                                    labelWidth:0,
                                                                    tooltip:LangHelper.GetString(this.Id,"<zombie host>[:<probeport>]")
                                                                },
                                                                {
                                                                    view: "checkbox",
                                                                    name: "b_enabled",
                                                                    labelRight: LangHelper.GetString(this.Id,"FTP bounce scan"),
                                                                    tooltip:LangHelper.GetString(this.Id,"FTP bounce scan"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "text",
                                                                    name: "b",
                                                                    labelWidth:0,
                                                                    tooltip:LangHelper.GetString(this.Id,"<FTP relay host>")
                                                                },
                                                                {
                                                                    view: "checkbox",
                                                                    name: "scanflags_enabled",
                                                                    labelRight: LangHelper.GetString(this.Id,"Custom TCP scan"),
                                                                    tooltip:LangHelper.GetString(this.Id,"Custom TCP scan"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "text",
                                                                    name: "scanflags",
                                                                    labelWidth:0,
                                                                    tooltip:LangHelper.GetString(this.Id,"TCP flags")
                                                                },
                                                                {}
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    },
                                    {
                                        header: LangHelper.GetString(this.Id,"Order"),
                                        id: this.Id + "CreateTaskContextFormTabsPortSpecification",
                                        body:{
                                            id: this.Id + "CreateTaskContextFormTabsPortSpecificationForm",
                                            view: "form",
                                            borderless: true,
                                            margin: 0,
                                            padding: 8,
                                            elements:[
                                                {
                                                    cols:[
                                                        {
                                                            rows:[
                                                                {
                                                                    view: "checkbox",
                                                                    name: "F",
                                                                    labelRight: LangHelper.GetString(this.Id,"Fast scan"),
                                                                    tooltip:LangHelper.GetString(this.Id,"Fast (limited port) scan"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "checkbox",
                                                                    name: "r",
                                                                    labelRight: LangHelper.GetString(this.Id,"No randomize ports"),
                                                                    tooltip:LangHelper.GetString(this.Id,"Don't randomize ports"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {}
                                                            ]
                                                        },
                                                        {
                                                            rows:[
                                                                {
                                                                    view: "checkbox",
                                                                    name: "p_enabled",
                                                                    labelRight: LangHelper.GetString(this.Id,"Only specified ports"),
                                                                    tooltip:LangHelper.GetString(this.Id,"Only scan specified ports"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "text",
                                                                    name: "p",
                                                                    labelWidth:0,
                                                                    tooltip:LangHelper.GetString(this.Id,"Ports list: 22-25,80,113,1050,35000")
                                                                },
                                                                {
                                                                    view: "checkbox",
                                                                    name: "top_ports_enabled",
                                                                    labelRight: LangHelper.GetString(this.Id,"Most common ports"),
                                                                    tooltip:LangHelper.GetString(this.Id,"Scan most common ports"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "text",
                                                                    name: "top_ports",
                                                                    labelWidth:0,
                                                                    tooltip:LangHelper.GetString(this.Id,"<n> of ports")
                                                                },
                                                                {
                                                                    view: "checkbox",
                                                                    name: "port_ratio_enabled",
                                                                    labelRight: LangHelper.GetString(this.Id,"Scans ports with a ratio"),
                                                                    tooltip:LangHelper.GetString(this.Id,"Scans all ports with a ratio greater than the one given"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "text",
                                                                    name: "port_ratio",
                                                                    labelWidth:0,
                                                                    tooltip:LangHelper.GetString(this.Id,"<ratio> must be between 0.0 and 1.0")
                                                                },
                                                                {}
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    },
                                    {
                                        header: LangHelper.GetString(this.Id,"Service"),
                                        id: this.Id + "CreateTaskContextFormTabsServiceVersion",
                                        body:{
                                            id: this.Id + "CreateTaskContextFormTabsServiceVersionForm",
                                            view: "form",
                                            borderless: true,
                                            margin: 0,
                                            padding: 8,
                                            elements:[
                                                {
                                                    cols:[
                                                        {
                                                            rows:[
                                                                {
                                                                    view: "checkbox",
                                                                    name: "sV",
                                                                    labelRight: LangHelper.GetString(this.Id,"Service determine"),
                                                                    tooltip:LangHelper.GetString(this.Id,"Probe open ports to determine service/version info"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "checkbox",
                                                                    name: "version_light",
                                                                    labelRight: LangHelper.GetString(this.Id,"Likely probes"),
                                                                    tooltip:LangHelper.GetString(this.Id,"Limit to most likely probes (intensity 2)"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "checkbox",
                                                                    name: "version_all",
                                                                    labelRight: LangHelper.GetString(this.Id,"Every probe"),
                                                                    tooltip:LangHelper.GetString(this.Id,"Try every single probe (intensity 9)"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "checkbox",
                                                                    name: "version_trace",
                                                                    labelRight: LangHelper.GetString(this.Id,"Detailed info"),
                                                                    tooltip:LangHelper.GetString(this.Id,"Show detailed version scan activity (for debugging)"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "checkbox",
                                                                    name: "version_intensity_enabled",
                                                                    labelRight: LangHelper.GetString(this.Id,"Intensity"),
                                                                    tooltip:LangHelper.GetString(this.Id,"Intensity"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "text",
                                                                    name: "version_intensity",
                                                                    labelWidth:0,
                                                                    tooltip:LangHelper.GetString(this.Id,"<level>: Set from 0 (light) to 9 (try all probes)")
                                                                },
                                                                {}
                                                            ]
                                                        },
                                                        {
                                                            rows:[
                                                                {}
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    },
                                    {
                                        header: LangHelper.GetString(this.Id,"OS"),
                                        id: this.Id + "CreateTaskContextFormTabsOs",
                                        body:{
                                            id: this.Id + "CreateTaskContextFormTabsOsForm",
                                            view: "form",
                                            borderless: true,
                                            margin: 0,
                                            padding: 8,
                                            elements:[
                                                {
                                                    cols:[
                                                        {
                                                            rows:[
                                                                {
                                                                    view: "checkbox",
                                                                    name: "O",
                                                                    labelRight: LangHelper.GetString(this.Id,"OS detection"),
                                                                    tooltip:LangHelper.GetString(this.Id,"Enable OS detection"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "checkbox",
                                                                    name: "osscan_limit",
                                                                    labelRight: LangHelper.GetString(this.Id,"Promising targets"),
                                                                    tooltip:LangHelper.GetString(this.Id,"Limit OS detection to promising targets"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "checkbox",
                                                                    name: "osscan_guess",
                                                                    labelRight: LangHelper.GetString(this.Id,"Guess OS"),
                                                                    tooltip:LangHelper.GetString(this.Id,"Guess OS more aggressively"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {}
                                                            ]
                                                        },
                                                        {}
                                                    ]
                                                }
                                            ]
                                        }
                                    },
                                    {
                                        header: LangHelper.GetString(this.Id,"Firewall"),
                                        id: this.Id + "CreateTaskContextFormTabsFirewall",
                                        body:{
                                            id: this.Id + "CreateTaskContextFormTabsFirewallForm",
                                            view: "form",
                                            borderless: true,
                                            margin: 0,
                                            padding: 8,
                                            elements:[
                                                {
                                                    cols:[
                                                        {
                                                            rows:[
                                                                {
                                                                    view: "checkbox",
                                                                    name: "f",
                                                                    labelRight: LangHelper.GetString(this.Id,"Fragment packets"),
                                                                    tooltip:LangHelper.GetString(this.Id,"Fragment packets"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "checkbox",
                                                                    name: "mtu_enabled",
                                                                    labelRight: LangHelper.GetString(this.Id,"MTU of fragment"),
                                                                    tooltip:LangHelper.GetString(this.Id,"Fragment packets with given MTU"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "text",
                                                                    name: "mtu",
                                                                    labelWidth:0,
                                                                    tooltip:LangHelper.GetString(this.Id,"MTU value")
                                                                },
                                                                {
                                                                    view: "checkbox",
                                                                    name: "D_enabled",
                                                                    labelRight: LangHelper.GetString(this.Id,"Cloak a scan"),
                                                                    tooltip:LangHelper.GetString(this.Id,"Cloak a scan with decoys"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "text",
                                                                    name: "D",
                                                                    labelWidth:0,
                                                                    tooltip:LangHelper.GetString(this.Id,"<decoy1,decoy2[,ME],...>")
                                                                },
                                                                {
                                                                    view: "checkbox",
                                                                    name: "S_enabled",
                                                                    labelRight: LangHelper.GetString(this.Id,"Spoof address"),
                                                                    tooltip:LangHelper.GetString(this.Id,"Spoof source address"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "text",
                                                                    name: "S",
                                                                    labelWidth:0,
                                                                    tooltip:LangHelper.GetString(this.Id,"<IP_Address>")
                                                                },
                                                                {
                                                                    view: "checkbox",
                                                                    name: "e_enabled",
                                                                    labelRight: LangHelper.GetString(this.Id,"Use specified interface"),
                                                                    tooltip:LangHelper.GetString(this.Id,"Use specified interface"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "text",
                                                                    name: "e",
                                                                    labelWidth:0,
                                                                    tooltip:LangHelper.GetString(this.Id,"<iface>")
                                                                },
                                                                {
                                                                    view: "checkbox",
                                                                    name: "g_enabled",
                                                                    labelRight: LangHelper.GetString(this.Id,"Use port"),
                                                                    tooltip:LangHelper.GetString(this.Id,"Use given port number"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "text",
                                                                    name: "g",
                                                                    labelWidth:0,
                                                                    tooltip:LangHelper.GetString(this.Id,"<portnum>")
                                                                },
                                                                {}
                                                            ]
                                                        },
                                                        {
                                                            rows:[
                                                                {
                                                                    view: "checkbox",
                                                                    name: "badsum",
                                                                    labelRight: LangHelper.GetString(this.Id,"Bogus checksum"),
                                                                    tooltip:LangHelper.GetString(this.Id,"Send packets with a bogus TCP/UDP/SCTP checksum"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "checkbox",
                                                                    name: "data_length_enabled",
                                                                    labelRight: LangHelper.GetString(this.Id,"Random data"),
                                                                    tooltip:LangHelper.GetString(this.Id,"Append random data to sent packets"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "text",
                                                                    name: "data_length",
                                                                    labelWidth:0,
                                                                    tooltip:LangHelper.GetString(this.Id,"<num>")
                                                                },
                                                                {
                                                                    view: "checkbox",
                                                                    name: "ip_options_enabled",
                                                                    labelRight: LangHelper.GetString(this.Id,"Ip options"),
                                                                    tooltip:LangHelper.GetString(this.Id,"Send packets with specified ip options"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "text",
                                                                    name: "ip_options",
                                                                    labelWidth:0,
                                                                    tooltip:LangHelper.GetString(this.Id,"<options>")
                                                                },
                                                                {
                                                                    view: "checkbox",
                                                                    name: "ttl_enabled",
                                                                    labelRight: LangHelper.GetString(this.Id,"IP life time"),
                                                                    tooltip:LangHelper.GetString(this.Id,"Set IP time-to-live field"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "text",
                                                                    name: "ttl",
                                                                    labelWidth:0,
                                                                    tooltip:LangHelper.GetString(this.Id,"Life time value")
                                                                },
                                                                {
                                                                    view: "checkbox",
                                                                    name: "spoof_mac_enabled",
                                                                    labelRight: LangHelper.GetString(this.Id,"MAC address"),
                                                                    tooltip:LangHelper.GetString(this.Id,"Spoof your MAC address"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "text",
                                                                    name: "spoof_mac",
                                                                    labelWidth:0,
                                                                    tooltip:LangHelper.GetString(this.Id,"<mac address/prefix/vendor name>")
                                                                },
                                                                {}
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    },
                                    {
                                        header: LangHelper.GetString(this.Id,"Misc"),
                                        id: this.Id + "CreateTaskContextFormTabsMisc",
                                        body: {
                                            id: this.Id + "CreateTaskContextFormTabsMiscForm",
                                            view: "form",
                                            borderless: true,
                                            margin: 0,
                                            padding: 8,
                                            elements: [
                                                {
                                                    cols:[
                                                        {
                                                            rows:[
                                                                {
                                                                    view: "checkbox",
                                                                    name: "6",
                                                                    labelRight: LangHelper.GetString(this.Id,"Enable IPv6 scanning"),
                                                                    tooltip:LangHelper.GetString(this.Id,"Enable IPv6 scanning"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "checkbox",
                                                                    name: "A",
                                                                    labelRight: LangHelper.GetString(this.Id,"Enable all"),
                                                                    tooltip:LangHelper.GetString(this.Id,"Enable OS detection, version detection, script scanning, and traceroute"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "checkbox",
                                                                    name: "any_enabled",
                                                                    labelRight: LangHelper.GetString(this.Id,"Any params"),
                                                                    tooltip:LangHelper.GetString(this.Id,"Any params"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "text",
                                                                    name: "any",
                                                                    labelWidth:0,
                                                                    tooltip:LangHelper.GetString(this.Id,"Any params")
                                                                },
                                                                {}
                                                            ]
                                                        },
                                                        {
                                                            rows:[
                                                                {}
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    },
                                    {
                                        header: LangHelper.GetString(this.Id,"Time"),
                                        id: this.Id + "CreateTaskContextFormTabsTiming",
                                        body: {
                                            id: this.Id + "CreateTaskContextFormTabsTimingForm",
                                            view: "form",
                                            borderless: true,
                                            margin: 0,
                                            padding: 8,
                                            elements: [
                                                {
                                                    cols:[
                                                        {
                                                            rows:[
                                                                {
                                                                    view: "checkbox",
                                                                    name: "T_enabled",
                                                                    labelRight: LangHelper.GetString(this.Id,"Timing template"),
                                                                    tooltip:LangHelper.GetString(this.Id,"<0-5>: Set timing template (higher is faster)"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "richselect",
                                                                    name: "T",
                                                                    labelWidth:0,
                                                                    value:"0",
                                                                    tooltip:LangHelper.GetString(this.Id,"<0-5>: Set timing template (higher is faster)"),
                                                                    options: Helper.DictToArr(NetworkAuditTimingDic)
                                                                },
                                                                {
                                                                    view: "checkbox",
                                                                    name: "hostgroup_enabled",
                                                                    labelRight: LangHelper.GetString(this.Id,"Group sizes"),
                                                                    tooltip:LangHelper.GetString(this.Id,"Parallel host scan group sizes"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    cols:[
                                                                        {
                                                                            view: "text",
                                                                            name: "min_hostgroup",
                                                                            placeholder:"min",
                                                                            labelWidth:0,
                                                                            tooltip:LangHelper.GetString(this.Id,"<hosts number>")
                                                                        },
                                                                        {
                                                                            view: "text",
                                                                            name: "max_hostgroup",
                                                                            placeholder:"max",
                                                                            labelWidth:0,
                                                                            tooltip:LangHelper.GetString(this.Id,"<hosts number>")
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    view: "checkbox",
                                                                    name: "parallelism_enabled",
                                                                    labelRight: LangHelper.GetString(this.Id,"Probe parallelization"),
                                                                    tooltip:LangHelper.GetString(this.Id,"Probe parallelization"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    cols:[
                                                                        {
                                                                            view: "text",
                                                                            name: "min_parallelism",
                                                                            placeholder:"min",
                                                                            labelWidth:0,
                                                                            tooltip:LangHelper.GetString(this.Id,"<hosts number>")
                                                                        },
                                                                        {
                                                                            view: "text",
                                                                            name: "max_parallelism",
                                                                            placeholder:"max",
                                                                            labelWidth:0,
                                                                            tooltip:LangHelper.GetString(this.Id,"<hosts number>")
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    view: "checkbox",
                                                                    name: "rtt_timeout_enabled",
                                                                    labelRight: LangHelper.GetString(this.Id,"Waiting time"),
                                                                    tooltip:LangHelper.GetString(this.Id,"Specifies probe round trip time"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    cols:[
                                                                        {
                                                                            view: "text",
                                                                            name: "min_rtt_timeout",
                                                                            placeholder:"min",
                                                                            labelWidth:0,
                                                                            tooltip:LangHelper.GetString(this.Id,"<hosts number>")
                                                                        },
                                                                        {
                                                                            view: "text",
                                                                            name: "max_rtt_timeout",
                                                                            placeholder:"max",
                                                                            labelWidth:0,
                                                                            tooltip:LangHelper.GetString(this.Id,"<hosts number>")
                                                                        },
                                                                        {
                                                                            view: "text",
                                                                            name: "initial_rtt_timeout",
                                                                            placeholder:"initial",
                                                                            labelWidth:0,
                                                                            tooltip:LangHelper.GetString(this.Id,"<hosts number>")
                                                                        }
                                                                    ]
                                                                },
                                                                {}
                                                            ]
                                                        },
                                                        {
                                                            rows:[
                                                                {
                                                                    view: "checkbox",
                                                                    name: "max_retries_enabled",
                                                                    labelRight: LangHelper.GetString(this.Id,"Retries number"),
                                                                    tooltip:LangHelper.GetString(this.Id,"Caps number of port scan probe retransmissions"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "text",
                                                                    name: "max_retries",
                                                                    labelWidth:0,
                                                                    tooltip:LangHelper.GetString(this.Id,"<tries>")
                                                                },
                                                                {
                                                                    view: "checkbox",
                                                                    name: "host_timeout_enabled",
                                                                    labelRight: LangHelper.GetString(this.Id,"Host time-out"),
                                                                    tooltip:LangHelper.GetString(this.Id,"Give up on target after this long"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    view: "text",
                                                                    name: "host_timeout",
                                                                    labelWidth:0,
                                                                    tooltip:LangHelper.GetString(this.Id,"<time>")
                                                                },
                                                                {
                                                                    view: "checkbox",
                                                                    name: "scan_delay_enabled",
                                                                    labelRight: LangHelper.GetString(this.Id,"Scan delay"),
                                                                    tooltip:LangHelper.GetString(this.Id,"Adjust delay between probes"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    cols:[
                                                                        {
                                                                            view: "text",
                                                                            name: "scan_delay",
                                                                            placeholder:"min",
                                                                            labelWidth:0,
                                                                            tooltip:LangHelper.GetString(this.Id,"<time>")
                                                                        },
                                                                        {
                                                                            view: "text",
                                                                            name: "max_scan_delay",
                                                                            placeholder:"max",
                                                                            labelWidth:0,
                                                                            tooltip:LangHelper.GetString(this.Id,"<time>")
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    view: "checkbox",
                                                                    name: "rate_enabled",
                                                                    labelRight: LangHelper.GetString(this.Id,"Rate"),
                                                                    tooltip:LangHelper.GetString(this.Id,"Send packets no slower than <number> per second"),
                                                                    value:0,
                                                                    labelWidth:0
                                                                },
                                                                {
                                                                    cols:[
                                                                        {
                                                                            view: "text",
                                                                            name: "min_rate",
                                                                            placeholder:"min",
                                                                            labelWidth:0,
                                                                            tooltip:LangHelper.GetString(this.Id,"<number>")
                                                                        },
                                                                        {
                                                                            view: "text",
                                                                            name: "max_rate",
                                                                            placeholder:"max",
                                                                            labelWidth:0,
                                                                            tooltip:LangHelper.GetString(this.Id,"<number>")
                                                                        }
                                                                    ]
                                                                },
                                                                {}
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    }
                                ]
                            }
                        }
                    ]
                }
            ]
        }
    };

    this.ViewConfig = [
        {
            cols:[
                SormSideBarView({id: this.Id}),
                {
                    type: "space",
                    rows: [
                        {
                            type: "clear",
                            borderless: true,
                            rows: [
                                PageHeaderTemplate({root_title: LangHelper.GetString("AppView","Lawful interception"), title: LangHelper.GetString("AppView","Network audit")}),
                                {
                                    view:"accordion",
                                    multi:"mixed",
                                    cols:[
                                        {
                                            header:LangHelper.GetString(this.Id,"Profiles"),
                                            body:{
                                                rows:[
                                                    {
                                                        view: "toolbar",
                                                        height: 32,
                                                        padding: 0,
                                                        margin: 0,
                                                        elements: [
                                                            {
                                                                view: "button",
                                                                id:this.Id + "AddTaskBtn",
                                                                type: "icon",
                                                                icon: "plus",
                                                                align: "right",
                                                                width: 32,
                                                                css:"toolbar_round_btn",
                                                                tooltip:LangHelper.GetString(this.Id,"Add profile"),
                                                                click: function (id, e, node)
                                                                {
                                                                    _this.AddTaskToTasksTable();
                                                                }
                                                            },
                                                            {
                                                                view: "button",
                                                                id:this.Id + "AddSubTaskBtn",
                                                                type: "icon",
                                                                icon: "plus-square",
                                                                align: "right",
                                                                width: 32,
                                                                css:"toolbar_round_btn",
                                                                tooltip:LangHelper.GetString(this.Id,"Add subprofile"),
                                                                click: function (id, e, node)
                                                                {
                                                                    _this.AddSubTaskToTasksTable();
                                                                }
                                                            },
                                                            {
                                                                width:16
                                                            },
                                                            {
                                                                view: "button",
                                                                id:this.Id + "PlayBtn",
                                                                type: "icon",
                                                                icon: "play-circle",
                                                                width: 32,
                                                                css:"toolbar_round_btn",
                                                                tooltip:LangHelper.GetString(this.Id,"Start scanning"),
                                                                click: function (id, e, node)
                                                                {
                                                                    _this.RunTask();
                                                                }
                                                            },
                                                            {
                                                                view: "button",
                                                                id:this.Id + "StopBtn",
                                                                type: "icon",
                                                                icon: "stop-circle",
                                                                width: 32,
                                                                css:"toolbar_round_btn",
                                                                tooltip:LangHelper.GetString(this.Id,"Stop scanning"),
                                                                click: function (id, e, node)
                                                                {
                                                                    _this.StopTask();
                                                                }
                                                            },
                                                            {},
                                                            {
                                                                view: "button",
                                                                id:this.Id+"TasksRefreshBtn",
                                                                type: "icon",
                                                                icon: "refresh",
                                                                css: "toolbar_round_btn",
                                                                width: 32,
                                                                tooltip: LangHelper.GetString("All","Refresh"),
                                                                click: function (id, e, node)
                                                                {
                                                                    _this.RefreshTasksTable(0);
                                                                }
                                                            }
                                                        ]
                                                    },
                                                    BlockHeaderTemplate({title:LangHelper.GetString(this.Id,"List of nodes scan profiles"), icon:"file"}),
                                                    {
                                                        view: "treetable",
                                                        id: this.Id + "TasksTable",
                                                        select: "row",
                                                        editable: true,
                                                        editaction:"custom",
                                                        resizeColumn: true,
                                                        tooltip:true,
                                                        form:this.Id + "CreateTaskContextForm",
                                                        on: {
                                                            onItemClick: function (id,e,node)
                                                            {
                                                                var rowData = this.getItem(id);

                                                                if(id.column === 'run')
                                                                {
                                                                    var data = _this.Views.TasksTable.getItem(id);

                                                                    if(!data.task_id)
                                                                    {
                                                                        return;
                                                                    }

                                                                    if(data.status == NetworkAuditTaskStatusEnum.progress)
                                                                    {
                                                                        setTimeout(function ()
                                                                        {
                                                                            _this.StopTask();
                                                                        }, 10);
                                                                    }
                                                                    else
                                                                    {
                                                                        setTimeout(function ()
                                                                        {
                                                                            _this.RunTask();
                                                                        }, 10);
                                                                    }
                                                                }

                                                                else if (id.column === 'edit')
                                                                {
                                                                    _this.ShowTasksEditForm(rowData, this.getItemNode(id), "right");
                                                                }

                                                                else if(id.column === 'trash')
                                                                {
                                                                    var __this = this;

                                                                    var data = _this.Views.TasksTable.getItem(id);

                                                                    if(!data.task_id)
                                                                    {
                                                                        _this.DeleteTask(id);
                                                                        return;
                                                                    }

                                                                    setTimeout(function () {
                                                                        var node = __this.getItemNode(id);
                                                                        _this.TaskDeleteContext.show(node, {pos:"bottom"});
                                                                    }, 10);
                                                                }
                                                            },
                                                            onBeforeFilter:function()
                                                            {
                                                                return false;
                                                            },
                                                            onAfterFilter:function()
                                                            {
                                                                _this.RefreshTasksTable();
                                                            },
                                                            onBeforeSort:function()
                                                            {
                                                                _this.RefreshTasksTable();
                                                                return false;
                                                            },
                                                            onAfterSelect:function ()
                                                            {
                                                                _this.OnTasksTableRowSelect();
                                                            },
                                                            onAfterUnSelect:function ()
                                                            {
                                                                _this.OnTasksTableRowUnSelect();
                                                            }
                                                        },
                                                        columns: [
                                                            {
                                                                id: "run",
                                                                header: "",
                                                                width: 32,
                                                                template: function (obj, common)
                                                                {
                                                                    if(obj.status == NetworkAuditTaskStatusEnum.progress && obj.task_id)
                                                                    {
                                                                        return CellStopBtnTemplate();
                                                                    }

                                                                    if(obj.task_id)
                                                                    {
                                                                        return CellPlayBtnTemplate();
                                                                    }

                                                                    return "";
                                                                },
                                                                headermenu: false
                                                            },
                                                            {
                                                                id: "edit",
                                                                header: "",
                                                                width: 32,
                                                                template: CellEditBtnTemplate,
                                                                headermenu: false
                                                            },
                                                            {
                                                                id: "title",
                                                                header: [LangHelper.GetString(this.Id,"Profile"), {content: "textFilter"}],
                                                                template:function(obj, common, value, config)
                                                                {
                                                                    return common.treetable(obj, common, value, config) + (obj.title?obj.title:LangHelper.GetString(_this.Id,"Profile")+" "+obj.task_id);
                                                                },
                                                                fillspace: true,
                                                                sort: "string"
                                                            },
                                                            {
                                                                id: "date",
                                                                header: [LangHelper.GetString(this.Id,"Date"), {content: "dateRangeFilter"}],
                                                                //format: webix.Date.dateToStr("%d.%m.%Y"),
                                                                template:function(obj, common){
                                                                    if(obj.date)
                                                                    {
                                                                        var format =  webix.Date.dateToStr("%d.%m.%Y %H:%i");
                                                                        return format(obj.date);
                                                                    }

                                                                    return "";
                                                                },
                                                                fillspace: true,
                                                                sort: "date"
                                                            },
                                                            {
                                                                id: "user_id",
                                                                header: [LangHelper.GetString(this.Id,"User"), {
                                                                    content: "multiSelectFilter",
                                                                    options: App.DicsManager.GetDicAsArray(DicsEnum.UsersDic),
                                                                    inputConfig: {suggest: {fitMaster: false}}
                                                                }],
                                                                fillspace: true,
                                                                template: function (obj)
                                                                {
                                                                    if(App.DicsManager.GetDic("UsersDic")[obj.user_id])
                                                                        return App.DicsManager.GetDic("UsersDic")[obj.user_id].value;
                                                                    else
                                                                        return "";
                                                                },
                                                                sort: "string"
                                                            },
                                                            {
                                                                id: "status",
                                                                header: [LangHelper.GetString(this.Id,"Status"), {
                                                                    content: "richSelectFilter",
                                                                    options: Helper.DictToArr(NetworkAuditStatusesDic),
                                                                    inputConfig: {suggest: {fitMaster: false}}
                                                                }],
                                                                template: function (obj, common)
                                                                {
                                                                    var statusStr = "";

                                                                    if(NetworkAuditStatusesDic[obj.status])
                                                                    {
                                                                        if(obj.status == NetworkAuditTaskStatusEnum.progress && obj.task_id)
                                                                        {
                                                                            statusStr += '<div class="spinner green"><div class="bounce1"></div><div class="bounce2"></div> <div class="bounce3"></div> </div>';
                                                                        }

                                                                        statusStr += "<span class=\"status status_" + (NetworkAuditTaskStatusColorsEnum[obj.status]) + "\">" + NetworkAuditStatusesDic[obj.status].value + "</span>";
                                                                    }

                                                                    return statusStr;
                                                                },
                                                                fillspace: true,
                                                                sort: "string"
                                                            },
                                                            {
                                                                id: "trash",
                                                                header: "",
                                                                width: 32,
                                                                template: CellTrashBtnTemplate,
                                                                headermenu: false
                                                            }
                                                        ],
                                                        data:[]
                                                    },
                                                    {
                                                        view: "toolbar",
                                                        padding: 0,
                                                        margin: 0,
                                                        height:32,
                                                        elements: [
                                                            {
                                                                view: "pager",
                                                                gravity:3,
                                                                id: this.Id + "TasksTable" + "Paging",
                                                                template: "{common.first()} {common.prev()} {common.pages()} {common.next()} {common.last()}",
                                                                master:false,
                                                                on: {
                                                                    onAfterPageChange:function(Page)
                                                                    {
                                                                        _this.RefreshTasksTable(Page);
                                                                    }
                                                                }
                                                            },
                                                            {
                                                                view:"richselect",
                                                                id: this.Id + "TasksTable" + "Paging" + "Size",
                                                                label:LangHelper.GetString("All", "On page"),
                                                                labelWidth:120,
                                                                minWidth:220,
                                                                value:100,
                                                                options:PagingSizeOptions,
                                                                on: {
                                                                    onChange:function()
                                                                    {
                                                                        _this.RefreshTasksTable();
                                                                    }
                                                                }
                                                            }
                                                        ]
                                                    }
                                                ]
                                            }
                                        },
                                        {view:"resizer"},
                                        {
                                            header:LangHelper.GetString(this.Id,"Scan results"),
                                            body:{
                                                id:this.Id + "ReportsPanel",
                                                rows:[
                                                    {
                                                        view: "toolbar",
                                                        height: 32,
                                                        padding: 0,
                                                        margin: 0,
                                                        elements: [
                                                            {
                                                                view:"label",
                                                                id:this.Id+"TaskDataInfoLabel"
                                                            }
                                                        ]
                                                    },
                                                    {
                                                        view: "tabbar",
                                                        id: this.Id + "ReportsPanelTabs",
                                                        value: this.Id + "ReportsPanelTabs" + "LogTab",
                                                        multiview: true,
                                                        height:webix.skin.$active.tabbarSmallHeight,
                                                        borderless:false,
                                                        options: [
                                                            {id:this.Id + "ReportsPanelTabs" + "LogTab", value:LangHelper.GetString(this.Id,"Log")},
                                                            {id:this.Id + "ReportsPanelTabs" + "XmlTab", value:LangHelper.GetString(this.Id,"Report")}
                                                        ]
                                                    },
                                                    {
                                                        cells:[
                                                            {
                                                                id: this.Id + "ReportsPanelTabs" + "LogTab",
                                                                rows: [
                                                                    {
                                                                        view: "toolbar",
                                                                        height: 32,
                                                                        padding: 0,
                                                                        margin: 0,
                                                                        elements: [
                                                                            {},
                                                                            {
                                                                                view: "button",
                                                                                id:this.Id+"TaskLogsRefreshBtn",
                                                                                type: "icon",
                                                                                icon: "refresh",
                                                                                css: "toolbar_round_btn",
                                                                                width: 32,
                                                                                tooltip: LangHelper.GetString("All","Refresh"),
                                                                                click: function (id, e, node)
                                                                                {
                                                                                    _this.RefreshCurrentReportTab();
                                                                                }
                                                                            }
                                                                        ]
                                                                    },
                                                                    {
                                                                        view:"codemirror-editor",
                                                                        id:this.Id + "ReportsPanelLog",
                                                                        value:""
                                                                    }
                                                                ]
                                                            },
                                                            {
                                                                id: this.Id + "ReportsPanelTabs" + "XmlTab",
                                                                rows:[
                                                                    {
                                                                        view: "toolbar",
                                                                        height: 32,
                                                                        padding: 0,
                                                                        margin: 0,
                                                                        elements: [
                                                                            // {
                                                                            //     view: "button",
                                                                            //     id:this.Id+"TaskReportPdfBtn",
                                                                            //     type: "icon",
                                                                            //     icon: "file-pdf",
                                                                            //     css: "toolbar_round_btn",
                                                                            //     width: 32,
                                                                            //     tooltip: LangHelper.GetString("All","Export to PDF"),
                                                                            //     click: function (id, e, node)
                                                                            //     {
                                                                            //         _this.ExportTaskReportToPdf();
                                                                            //     }
                                                                            // },
                                                                            {},
                                                                            {
                                                                                view: "button",
                                                                                id:this.Id+"TaskReportRefreshBtn",
                                                                                type: "icon",
                                                                                icon: "refresh",
                                                                                css: "toolbar_round_btn",
                                                                                width: 32,
                                                                                tooltip: LangHelper.GetString("All","Refresh"),
                                                                                click: function (id, e, node)
                                                                                {
                                                                                    _this.RefreshCurrentReportTab();
                                                                                }
                                                                            }
                                                                        ]
                                                                    },
                                                                    {
                                                                        id: this.Id + "ReportsPanelXml",
                                                                        view:"iframe"
                                                                    }
                                                                ]
                                                            }
                                                        ]
                                                    }
                                                ]
                                            }
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    ];

    this.Views = {
        AddTaskBtn: null,
        AddSubTaskBtn: null,

        PlayBtn: null,
        StopBtn: null,

        TasksTable:null,
        TasksTablePaging:null,
        TasksTablePagingSize:null,

        CreateTaskContext:null,
        CreateTaskContextForms:{
            CreateTaskContextForm:null,
            CreateTaskContextFormTabsHostDiscoveryForm:null,
            CreateTaskContextFormTabsScanTechniquesForm:null,
            CreateTaskContextFormTabsPortSpecificationForm:null,
            CreateTaskContextFormTabsServiceVersionForm:null,
            CreateTaskContextFormTabsOsForm:null,
            CreateTaskContextFormTabsFirewallForm:null,
            CreateTaskContextFormTabsMiscForm:null,
            CreateTaskContextFormTabsTimingForm:null
        },

        ReportsPanel:null,
        ReportsPanelTabs:null,
        TaskDataInfoLabel:null,
        ReportsPanelLog:null,
        ReportsPanelXml:null
    };

    this.Subscriptions = {};

    this.TasksTableRowsSelectedCount = new Rx.BehaviorSubject(0);

    this.MonitorTimer = null;
};

NetworkAuditViewController.prototype.Destroy = function ()
{
    var _this = this;

    Object.keys(this.Subscriptions).forEach(function (key)
    {
        _this.Subscriptions[key].unsubscribe();
    });

    this.ViewModel.Destroy();
    delete this.ViewModel;

    this.Health.Destroy();
    delete this.Health;
};

NetworkAuditViewController.prototype.ViewDidLoad = function (Params)
{
    var _this = this;

    webix.ready(function () {
        $$(_this.Id + "LeftSideBar").select(_this.Id);
    });

    this.BindAll();

    this.CheckHealth();
};

NetworkAuditViewController.prototype.BindAll = function (Params)
{
    var _this = this;

    this.Views.AddTaskBtn = $$(this.Id + "AddTaskBtn");
    this.Views.AddSubTaskBtn = $$(this.Id + "AddSubTaskBtn");

    this.Views.PlayBtn = $$(this.Id + "PlayBtn");
    this.Views.StopBtn = $$(this.Id + "StopBtn");

    this.Views.TasksTable = $$(this.Id + "TasksTable");
    this.Views.TasksTablePaging = $$(this.Id + "TasksTable" + "Paging");
    this.Views.TasksTablePagingSize = $$(this.Id + "TasksTable" + "Paging" + "Size");

    this.Views.CreateTaskContext = webix.ui(this.CreateTaskContextFormConfig);
    this.Views.CreateTaskContextForms.CreateTaskContextForm = $$(this.Id+"CreateTaskContextForm");
    this.Views.CreateTaskContextForms.CreateTaskContextFormTabsHostDiscoveryForm = $$(this.Id+"CreateTaskContextFormTabsHostDiscoveryForm");
    this.Views.CreateTaskContextForms.CreateTaskContextFormTabsScanTechniquesForm = $$(this.Id+"CreateTaskContextFormTabsScanTechniquesForm");
    this.Views.CreateTaskContextForms.CreateTaskContextFormTabsPortSpecificationForm = $$(this.Id+"CreateTaskContextFormTabsPortSpecificationForm");
    this.Views.CreateTaskContextForms.CreateTaskContextFormTabsServiceVersionForm = $$(this.Id+"CreateTaskContextFormTabsServiceVersionForm");
    this.Views.CreateTaskContextForms.CreateTaskContextFormTabsOsForm = $$(this.Id+"CreateTaskContextFormTabsOsForm");
    this.Views.CreateTaskContextForms.CreateTaskContextFormTabsFirewallForm = $$(this.Id+"CreateTaskContextFormTabsFirewallForm");
    this.Views.CreateTaskContextForms.CreateTaskContextFormTabsMiscForm = $$(this.Id+"CreateTaskContextFormTabsMiscForm");
    this.Views.CreateTaskContextForms.CreateTaskContextFormTabsTimingForm = $$(this.Id+"CreateTaskContextFormTabsTimingForm");

    this.Views.ReportsPanel = $$(this.Id+"ReportsPanel");
    this.Views.ReportsPanelTabs = $$(this.Id+"ReportsPanelTabs");

    this.Views.ReportsPanelLog = $$(this.Id+"ReportsPanelLog");
    this.Views.ReportsPanelXml = $$(this.Id+"ReportsPanelXml");

    this.Views.TaskDataInfoLabel = $$(this.Id + "TaskDataInfoLabel");

    this.Views.ReportsPanelTabs.attachEvent("onChange", function(New, Old)
    {
        _this.RefreshCurrentReportTab();
    });

    this.Subscriptions.SignalDicUpdatedSubscription = App.DicsManager.Signal[DicsEnum.UsersDic].subscribe(function (Updated)
    {
        if (Updated)
        {
            _this.RefreshTasksTable(0);
        }
    });

    this.Subscriptions.SignalTasksUpdatedSubscription = this.ViewModel.SignalTasksUpdated.subscribe(function (Updated)
    {
        if (Updated)
        {
            _this.UpdateTasksTable();

            _this.MonitorTasksTable();
        }
    });

    this.Subscriptions.TasksTableRowsSelectedCountSubscription = this.TasksTableRowsSelectedCount.subscribe(function (Value)
    {
        _this.TasksTableRowsSelectedCountSubscriptionHandler(Value);
    });

    this.Subscriptions.SignalTaskUpdatedSubscription = this.ViewModel.SignalTaskUpdated.subscribe(function (Task)
    {
        if(Task)
        {
            var selectedData = _this.GetTasksTableSelectedData();

            var rows = _this.Views.TasksTable.find(function(obj)
            {
                return obj.task_id == Task.task_id;
            });

            if(rows && rows.length == 1)
            {
                _this.Views.TasksTable.updateItem(rows[0].id,  Task);

                if(selectedData && selectedData.task_id == Task.task_id && Task.status != 0)
                {
                    _this.OnTasksTableRowSelect();
                }
            }

        }
    });

    this.Subscriptions.SignalTaskLogsUpdatedSubscription = this.ViewModel.SignalTaskLogsUpdated.subscribe(function (Task)
    {
        if(Task)
        {
            _this.SetTaskLogsSelectedTask();
        }
    });

    this.Subscriptions.SignalTaskXmlUpdatedSubscription = this.ViewModel.SignalTaskXmlUpdated.subscribe(function (Task)
    {
        if(Task)
        {
            _this.SetTaskXmlSelectedTask();
        }
    });

    this.Subscriptions.SignalHealthUpdatedSubscription = this.Health.HealthUpdated.subscribe(function (Updated)
    {
        if (Updated)
        {
            _this.HealthResult();
        }
    });
};

NetworkAuditViewController.prototype.UpdateTasksTable = function (Params)
{
    var _this = this;

    var dic = App.DicsManager.GetDicAsArray(DicsEnum.UsersDic);

    this.Views.TasksTable.getColumnConfig("user_id").header[1].options = App.DicsManager.GetDicAsArray(DicsEnum.UsersDic);

    this.Views.TasksTable.clearAll();
    this.Views.TasksTable.parse(_this.ViewModel.GetTasks());

    this.Views.TasksTablePagingSize.setValue(this.ViewModel.TasksFilter.total.size);

    this.Views.TasksTablePaging.define(this.ViewModel.TasksFilter.total);
    this.Views.TasksTablePaging.refresh();
};

NetworkAuditViewController.prototype.RefreshTasksTable = function (Page)
{
    var _this = this;

    var state = this.Views.TasksTable.getState();

    var size = this.Views.TasksTablePagingSize.getValue();

    this.ViewModel.RefershTasks({page:Page, filter:state.filter, size:size, sort:state.sort ? state.sort:null, callback:function () {}})
};

NetworkAuditViewController.prototype.AddTaskToTasksTable = function ()
{
    var _this = this;

    var model = new NetworkAuditTaskModel();
    model.form_data = JSON.stringify(new NetworkAuditTaskFormModel());

    _this.Views.TasksTable.add(model,0);

    _this.Views.TasksTable.select(model.id, false);
    _this.Views.TasksTable.showItem(model.id);

    _this.ShowTasksEditForm(model, _this.Views.TasksTable.getItemNode(model.id), "right");
};

NetworkAuditViewController.prototype.AddSubTaskToTasksTable = function ()
{
    var _this = this;

    var selectedModel = _this.GetTasksTableSelectedData();

    if(!selectedModel || !selectedModel.task_id)
        return;

    var model = new NetworkAuditTaskModel({parent_id:selectedModel.task_id});

    _this.Views.TasksTable.add(model,0,selectedModel.id);
    _this.Views.TasksTable.open(selectedModel.id);

    _this.Views.TasksTable.select(model.id, false);
    _this.Views.TasksTable.showItem(model.id);

    _this.ShowTasksEditForm(model, _this.Views.TasksTable.getItemNode(model.id), "right");
};

NetworkAuditViewController.prototype.ClearForms = function ()
{
    var _this = this;

    Object.keys(this.Views.CreateTaskContextForms).forEach(function (Key)
    {
        _this.Views.CreateTaskContextForms[Key].clear();
        _this.Views.CreateTaskContextForms[Key].clearValidation();
    });
};

NetworkAuditViewController.prototype.SetTasksEditFormValues = function (FormData)
{
    if(FormData)
    {
        this.Views.CreateTaskContextForms.CreateTaskContextForm.setValues(new NetworkAuditTaskFormModel(FormData, true, false));
        this.Views.CreateTaskContextForms.CreateTaskContextFormTabsHostDiscoveryForm.setValues(new NetworkAuditTaskFormCmdHostDiscoveryModel(FormData.cmd));
        this.Views.CreateTaskContextForms.CreateTaskContextFormTabsScanTechniquesForm.setValues(new NetworkAuditTaskFormCmdScanTechniquesModel(FormData.cmd));
        this.Views.CreateTaskContextForms.CreateTaskContextFormTabsPortSpecificationForm.setValues(new NetworkAuditTaskFormCmdPortSpecificationModel(FormData.cmd));
        this.Views.CreateTaskContextForms.CreateTaskContextFormTabsServiceVersionForm.setValues(new NetworkAuditTaskFormCmdServiceVersionModel(FormData.cmd));
        this.Views.CreateTaskContextForms.CreateTaskContextFormTabsOsForm.setValues(new NetworkAuditTaskFormCmdOsModel(FormData.cmd));
        this.Views.CreateTaskContextForms.CreateTaskContextFormTabsFirewallForm.setValues(new NetworkAuditTaskFormCmdFirewallModel(FormData.cmd));
        this.Views.CreateTaskContextForms.CreateTaskContextFormTabsMiscForm.setValues(new NetworkAuditTaskFormMiscModel(FormData.cmd));
        this.Views.CreateTaskContextForms.CreateTaskContextFormTabsTimingForm.setValues(new NetworkAuditTaskFormTimingModel(FormData.cmd));
    }
};

NetworkAuditViewController.prototype.ShowTasksEditForm = function (Data, Node, Pos)
{
    this.ClearForms();

    if(Data && Data.form_data)
    {
        var formParams = new NetworkAuditTaskFormModel(JSON.parse(Data.form_data));

        this.SetTasksEditFormValues(formParams);
    }

    this.Views.CreateTaskContext.show(Node, {pos:Pos});
};

NetworkAuditViewController.prototype.GetTasksTableSelectedData = function ()
{
    return this.Views.TasksTable.getSelectedItem();
};

NetworkAuditViewController.prototype.OnTasksTableRowSelect = function ()
{
    var data = this.GetTasksTableSelectedData();

    this.TasksTableRowsSelectedCount.next(data?(Array.isArray(data)?data.length:1):0);
};

NetworkAuditViewController.prototype.OnTasksTableRowUnSelect = function ()
{
    var data = this.GetTasksTableSelectedData();
    this.TasksTableRowsSelectedCount.next(data?(Array.isArray(data)?data.length:1):0);
};

NetworkAuditViewController.prototype.ValidateForms = function ()
{
    var _this = this;

    var valid = true;

    Object.keys(this.Views.CreateTaskContextForms).every(function (Key)
    {
        valid = _this.Views.CreateTaskContextForms[Key].validate();

        return valid;
    });

    return valid;
};

NetworkAuditViewController.prototype.SaveTask = function ()
{
    var _this = this;

    var taskData = this.GetTasksTableSelectedData();

    if(this.ValidateForms())
    {
        taskData.valid = true;

        this.Views.CreateTaskContext.hide();

        var params = {};

        Object.keys(this.Views.CreateTaskContextForms).forEach(function (Key)
        {
            webix.extend(params, _this.Views.CreateTaskContextForms[Key].getValues());
        });

        params.cmd = params;

        var formData = new NetworkAuditTaskFormModel(params);

        this.ViewModel.SaveTask({task:taskData, task_form:formData, callback:function (R)
        {
            if(R.success && R.data && R.data.task)
            {
                _this.Views.TasksTable.updateItem(taskData.id,  new NetworkAuditTaskModel(R.data.task));

                _this.MonitorTasksTable();

                webix.message(LangHelper.GetString(_this.Id,"Profile saved"));
            }
            else
            {
                taskData.valid = false;

                _this.Views.TasksTable.validate(taskData.id);

                if(R.error)
                    webix.message({text:R.error.msg,type:"error"});
            }

            _this.OnTasksTableRowSelect();
        }});

        this.ClearForms();
    }
    else
    {
        taskData.valid = false;
    }

    this.Views.TasksTable.validate(taskData.id);
};

NetworkAuditViewController.prototype.DeleteTask = function (RowId)
{
    var _this = this;

    var taskData = this.Views.TasksTable.getItem(RowId);
    taskData.valid = true;

    if(taskData.task_id > 0)
    {
        this.ViewModel.DeleteTask({task:taskData, callback:function (R)
        {
            if(R.success)
            {
                _this.Views.TasksTable.remove(RowId);

                webix.message(LangHelper.GetString("All","Deleted"));
            }
            else
            {
                taskData.valid = false;
                _this.Views.TasksTable.validate(RowId);
                if(R.error)
                    webix.message({text:R.error.msg,type:"error"});
            }
        }});
    }
    else
    {
        this.Views.TasksTable.remove(RowId);
    }
};

NetworkAuditViewController.prototype.TasksTableRowsSelectedCountSubscriptionHandler = function (Value, NoRefresh)
{
    var _this = this;

    var _disableAll = function ()
    {
        _this.Views.ReportsPanel.disable();
        _this.Views.AddSubTaskBtn.disable();
        _this.Views.PlayBtn.disable();
        _this.Views.StopBtn.disable();
    };

    if(Value != 1)
    {
        _disableAll();
        return;
    }

    var selectedData = _this.GetTasksTableSelectedData();

    if(!selectedData)
    {
        _disableAll();
        return;
    }

    if(selectedData.status)
    {
        _this.Views.ReportsPanel.enable();

        this.SetTaskDataInfoLabel();

        if(!NoRefresh)
            this.RefreshCurrentReportTab();
    }
    else
    {
        _this.Views.ReportsPanel.disable();
    }

    _this.Views.AddSubTaskBtn.enable();

    switch (parseInt(selectedData.status))
    {
        case NetworkAuditTaskStatusEnum.new:
        case NetworkAuditTaskStatusEnum.stoped:
        case NetworkAuditTaskStatusEnum.error:

            if(selectedData.task_id)
            {
                !selectedData.download ? _this.Views.PlayBtn.enable() : _this.Views.PlayBtn.disable();
            }
            else
            {
                _this.Views.PlayBtn.disable();
            }

            _this.Views.StopBtn.disable();

            break;

        case NetworkAuditTaskStatusEnum.progress:
            _this.Views.PlayBtn.disable();
            _this.Views.StopBtn.enable();
            break;
        default:
            break;
    }
};

NetworkAuditViewController.prototype.RunTask = function ()
{
    var _this = this;

    var taskData = this.GetTasksTableSelectedData();

    taskData.valid = true;

    this.ViewModel.RunTask({task:taskData, callback:function (R)
    {
        if(R.success && R.data && R.data.task && R.data.task.success)
        {
            _this.Views.TasksTable.updateItem(taskData.id,  new NetworkAuditTaskModel(R.data.task.data));

            _this.MonitorTasksTable();

            if(R.data.task.data.status == NetworkAuditTaskStatusEnum.progress)
            {
                webix.message(LangHelper.GetString(_this.Id,"Scan started"));
            }
            else
            {
                webix.message({text:LangHelper.GetString(_this.Id,"Scan not running"), type:"debug"});
            }
        }
        else
        {
            taskData.valid = false;

            _this.Views.TasksTable.validate(taskData.id);

            if(R.error)
                webix.message({text:R.error.msg,type:"error"});
        }

        _this.OnTasksTableRowSelect();
    }});
};

NetworkAuditViewController.prototype.StopTask = function ()
{
    var _this = this;

    var taskData = this.GetTasksTableSelectedData();

    taskData.valid = true;

    this.ViewModel.StopTask({task:taskData, callback:function (R)
    {
        if(R.success)
        {
            if(R.data && R.data.task)
            {
                _this.Views.TasksTable.updateItem(taskData.id,  new NetworkAuditTaskModel(R.data.task));

                webix.message(LangHelper.GetString(_this.Id,"Scan stopped"));
            }
            else
            {
                webix.message(LangHelper.GetString(_this.Id,"Scan not running"));
            }

        }
        else
        {
            taskData.valid = false;

            _this.Views.TasksTable.validate(taskData.id);

            if(R.error)
                webix.message({text:R.error.msg,type:"error"});
        }

        _this.OnTasksTableRowSelect();
    }});
};

NetworkAuditViewController.prototype.MonitorTasksTable = function()
{
    var _this = this;

    clearTimeout(this.MonitorTimer);

    var tasks = this.Views.TasksTable.serialize();

    var tasksIdsToMonitor = [];

    var addToMonitor = function (Tasks)
    {
        Tasks.forEach(function (Task)
        {
            if(Task.task_id && Task.status == NetworkAuditTaskStatusEnum.progress)
            {
                tasksIdsToMonitor.push(Task.task_id)
            }

            if(Task.data && Task.data.length)
            {
                addToMonitor(Task.data);
            }
        });
    };

    addToMonitor(tasks);

    if(tasksIdsToMonitor.length)
    {
        this.ViewModel.RefershTasks({tasks_ids:tasksIdsToMonitor, callback:function ()
        {
            _this.MonitorTimer = setTimeout(function ()
            {
                _this.MonitorTasksTable();
            }, 10000);
        }});
    }
};

NetworkAuditViewController.prototype.SetTaskDataInfoLabel = function()
{
    var taskData = this.GetTasksTableSelectedData();

    if(!taskData)
        return;

    var format =  webix.Date.dateToStr("%d.%m.%Y %H:%i");
    var date =  format(taskData.date);

    var status = "";

    if(NetworkAuditStatusesDic[taskData.status])
        status = "<span class=\"status status_" + (NetworkAuditTaskStatusColorsEnum[taskData.status]) + "\">" + NetworkAuditStatusesDic[taskData.status].value + "</span>";

    var title = taskData.title?taskData.title:LangHelper.GetString(this.Id,"Profile") + " "+taskData.task_id;

    var text = title + " "+LangHelper.GetString(this.Id,"from")+" " + date + ". "+LangHelper.GetString(this.Id,"Status")+": " + status + ".";

    this.Views.TaskDataInfoLabel.setValue(text);
};

NetworkAuditViewController.prototype.RefreshTaskLogs = function()
{
    var taskData = this.GetTasksTableSelectedData();

    if(taskData)
    {
        this.ViewModel.RefreshTaskLogs(taskData);
    }
};

NetworkAuditViewController.prototype.RefreshTaskXml = function()
{
    var taskData = this.GetTasksTableSelectedData();

    if(taskData)
    {
        this.ViewModel.RefreshTaskXml(taskData);
    }
};

NetworkAuditViewController.prototype.SetTaskLogsSelectedTask = function()
{
    var _this = this;

    var taskData = this.GetTasksTableSelectedData();

    if(taskData)
    {
        var logsModel = this.ViewModel.GetTaskLogs(taskData);

        this.Views.ReportsPanelLog.setValue(logsModel.content);
    }
};

NetworkAuditViewController.prototype.SetTaskXmlSelectedTask = function()
{
    var _this = this;

    var taskData = this.GetTasksTableSelectedData();

    if(taskData)
    {
        var xmlModel = this.ViewModel.GetTaskXml(taskData);

        var iframe = this.Views.ReportsPanelXml.getIframe();

        if(iframe && iframe.contentDocument)
        {

            //iframe.src = 'data:text/xml,' + encodeURI(xmlModel.content);
            var idoc= iframe.contentDocument || iframe.contentWindow.document; // IE compat
            idoc.open();

            idoc.write(xmlModel.content);

            idoc.write('<link rel="stylesheet" href="https://svn.nmap.org/nmap/docs/nmap.xsl" type="text/xsl" charset="utf-8">');

            idoc.close();
        }
    }
};

NetworkAuditViewController.prototype.RefreshCurrentReportTab = function()
{
    var current = this.Views.ReportsPanelTabs.getValue();

    if(current == this.Id + "ReportsPanelTabs" + "LogTab")
    {
        this.RefreshTaskLogs();
    }

    if(current == this.Id + "ReportsPanelTabs" + "XmlTab")
    {
        this.RefreshTaskXml();
    }
};

NetworkAuditViewController.prototype.ExportTaskReportToPdf = function()
{
    var taskData = this.GetTasksTableSelectedData();

    if(taskData && taskData.task_id)
    {
        this.ViewModel.DownloadTaskPdfReport(taskData);
    }
};

NetworkAuditViewController.prototype.ApplyContextFormTemplate = function(NewId, OldId)
{

    if(NewId == "" || OldId == "")
        return;

    var mainFormParams = this.Views.CreateTaskContextForms.CreateTaskContextForm.getValues();

    this.ClearForms();

    var formParams = new NetworkAuditTaskFormModel();
    formParams.template = NewId;
    formParams.title = mainFormParams.title;
    formParams.target = mainFormParams.target;


    switch (NewId)
    {
        case "0":
            break;
        case "1":
            formParams.cmd.T_enabled = 1;
            formParams.cmd.T = 4;
            formParams.cmd.A = 1;
            break;
        case "2":
            formParams.cmd.sS = 1;
            formParams.cmd.sU = 1;
            formParams.cmd.T_enabled = 1;
            formParams.cmd.T = 4;
            formParams.cmd.A = 1;
            break;
        case "3":
            formParams.cmd.p_enabled = 1;
            formParams.cmd.p = "1-65535";
            formParams.cmd.T_enabled = 1;
            formParams.cmd.T = 4;
            formParams.cmd.A = 1;
            break;
        case "4":
            formParams.cmd.Pn = 1;
            formParams.cmd.T_enabled = 1;
            formParams.cmd.T = 4;
            formParams.cmd.A = 1;
            break;
        case "5":
            formParams.cmd.sn = 1;
            break;
        case "6":
            formParams.cmd.F = 1;
            formParams.cmd.T_enabled = 1;
            formParams.cmd.T = 4;
            break;
        case "7":
            formParams.cmd.sV = 1;
            formParams.cmd.F = 1;
            formParams.cmd.O = 1;
            formParams.cmd.version_light = 1;
            formParams.cmd.T_enabled = 1;
            formParams.cmd.T = 4;
            break;
        case "8":
            formParams.cmd.sn = 1;
            formParams.cmd.traceroute = 1;
            break;
        case "9":
            formParams.cmd.sS = 1;
            formParams.cmd.sU = 1;
            formParams.cmd.T_enabled = 1;
            formParams.cmd.T = 4;
            formParams.cmd.A = 1;
            formParams.cmd.PE = 1;
            formParams.cmd.PP = 1;
            formParams.cmd.PS_enabled = 1;
            formParams.cmd.PS = "80,443";
            formParams.cmd.PA_enabled = 1;
            formParams.cmd.PA = "3389";
            formParams.cmd.PU_enabled = 1;
            formParams.cmd.PU = "40125";
            formParams.cmd.PY_enabled = 1;
            formParams.cmd.g_enabled = 1;
            formParams.cmd.g = "53";
            break;
        default:
    }

    this.SetTasksEditFormValues(formParams);
};

NetworkAuditViewController.prototype.CheckHealth = function()
{
    //this.Health.Refresh({nmap: 1, fop: 1});
    this.Health.Refresh({nmap: 1});
};

NetworkAuditViewController.prototype.HealthResult = function()
{
    var health = this.Health.GetHealth();

    if(health.nmap)
    {
        if(!health.nmap.success)
        {
            webix.message({text:LangHelper.GetString("AppView","Network audit") + "<br/><br/>" + health.nmap.error.msg + "<br/><br/>" + LangHelper.GetString("Health","Verify services health"), type:"error", expire: 300000});
            return
        }
    }

    // if(health.fop)
    // {
    //     if(!health.fop.success)
    //         webix.message({text:LangHelper.GetString("AppView","Network audit") + "<br/><br/>" + health.fop.error.msg + "<br/><br/>" + LangHelper.GetString("Health","Verify services health"), type:"error", expire: 300000});
    // }
};
function TrafficDecodeWebViewController(Params)
{

    var _this = this;

    this.Id = "TrafficDecode";
    this.id = (Params && Params.id ? Params.id : "") + this.Id + "Web";

    this.ViewModel = new TrafficDecodeWebManager();

    this.PagingSizeOptions = [
        {id:10, value:"10"},
        {id:50, value:"50"},
        {id:100, value:"100"},
        {id:200, value:"200"},
        {id:500, value:"500"},
        {id:1000, value:"1 000"},
        {id:10000, value:"10 000"}
    ];

    this.PcapsDic = [];

    this.RowInfoContext = webix.ui({
        view:"popup",
        width:640,
        height:640,
        body:{
            borderless: true,
            margin: 0,
            padding: 0,
            rows:[
                BlockHeaderTemplate({title:LangHelper.GetString(this.Id, "Http info"), icon:"globe", borderless: true}),
                {
                    id: this.id + "RowInfoProperty",
                    view: "property",
                    css: "dpiui2-webix-property",
                    borderless: true,
                    // tooltip: true,
                    editable: false,
                    scroll: "y",
                    nameWidth: 128,
                    tooltip: function(Params)
                    {
                      return Params.value;
                    },
                    elements: [
                        {
                            label: LangHelper.GetString(this.Id, "Http"),
                            type: "label"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "User agent"),
                            type: "text",
                            id: "user_agent",
                            tooltip: function(Obj)
                            {
                                return Obj.user_agent;
                            }
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Host"),
                            type: "text",
                            id: "host"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Url"),
                            type: "text",
                            id: "url"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Content type"),
                            type: "text",
                            id: "content_type"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Content encoding"),
                            type: "text",
                            id: "content_encoding"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Request method"),
                            type: "text",
                            id: "request_method"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Response code"),
                            type: "text",
                            id: "response_code"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Chunk size"),
                            type: "text",
                            id: "chunk_size"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Tcp"),
                            type: "label"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Src port"),
                            type: "text",
                            id: "srcport"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Dst port"),
                            type: "text",
                            id: "dstport"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Tcp time"),
                            type: "text",
                            id: "tcp_time"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Ip"),
                            type: "label"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Ip proto"),
                            type: "text",
                            id: "ip_proto"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Ip version"),
                            type: "text",
                            id: "ip_version"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Src ip"),
                            type: "text",
                            id: "ip_src"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Dst ip"),
                            type: "text",
                            id: "ip_dst"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Eth"),
                            type: "label"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Eth type"),
                            type: "text",
                            id: "eth_type"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Src eth"),
                            type: "text",
                            id: "eth_src"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Dst eth"),
                            type: "text",
                            id: "eth_dst"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "File"),
                            type: "label"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "File Id"),
                            type: "text",
                            id: "pcap_id"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "File name"),
                            type: "text",
                            id: "filename"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Response file"),
                            type: "text",
                            id: "available_file"
                        }
                        // {
                        //     height: 256
                        // }
                    ]
                },
                {
                    height:16
                },
                {
                    cols:[
                        {},
                        {
                        },
                        {
                            view:"button",
                            type:"form",
                            label:LangHelper.GetString(this.Id, "Close"),
                            click:function () {
                                _this.RowInfoContext.hide();
                            }
                        }
                    ]
                }
            ]
        }
    });

    this.ViewConfig = [
        {
            id: this.id,
            rows: [
                BlockHeaderTemplate({title:LangHelper.GetString(this.Id, "Decoded web items"), icon:"globe"}),
                {
                    view: "toolbar",
                    height:webix.skin.$name == "sormui_vulkan" ? webix.skin.$active.tabbarSmallHeight : webix.skin.material.tabbarHeight,
                    padding: 0,
                    margin: 0,
                    elements: [
                        {
                            view: "tabbar",
                            id: this.id + "PanelTabs",
                            value: this.id + "PanelTabsRequests",
                            // multiview: true,
                            options: [
                                {
                                    id: this.id + "PanelTabsRequests",
                                    value: LangHelper.GetString(this.Id , "Requests")
                                },
                                {
                                    id: this.id + "PanelTabsImages",
                                    value: LangHelper.GetString(this.Id , "Images")
                                }
                            ],
                            on: {
                                onChange: function(Id)
                                {
                                    $$(Id).show();

                                    setTimeout(function(){
                                        _this.Refresh(0)
                                    },10);
                                }
                            }
                        },
                        {

                        }
                    ]
                },
                {
                    view: "multiview",
                    cells:[
                        {
                            id: this.id + "PanelTabsRequests",
                            rows: [

                                {
                                    view: "datatable",
                                    id: this.id + "RequestsTable",
                                    select: "row",
                                    resizeColumn: true,
                                    tooltip:true,
                                    on: {
                                        onItemClick: function (id,e,node)
                                        {
                                            var rowData = this.getItem(id);
                                            if (id.column === 'edit')
                                            {

                                            }
                                            else if(id.column === 'trash')
                                            {

                                            }
                                        },
                                        onBeforeFilter:function()
                                        {
                                            return false;
                                        },
                                        onAfterFilter:function()
                                        {
                                            _this.Refresh();
                                        },
                                        onBeforeSort:function()
                                        {
                                            _this.Refresh();
                                            return false;
                                        }
                                    },
                                    columns: [
                                        {
                                            id: "req_date",
                                            header: [LangHelper.GetString(this.Id, "Date"), {content: "dateRangeFilter"}],
                                            //format: webix.Date.dateToStr("%d.%m.%Y"),
                                            template:function(obj, common){
                                                if(obj.req_date)
                                                {
                                                    var format =  webix.Date.dateToStr("%d.%m.%Y %H:%i:%s");
                                                    return format(obj.req_date);
                                                }

                                                return "";
                                            },
                                            width: 160,
                                            sort: "date"
                                        },
                                        {
                                            id: "url",
                                            header: [LangHelper.GetString(this.Id, "Url"), {content: "textFilter"}],
                                            template:function(obj, common, value, config)
                                            {
                                                var result = "";
                                                var content = obj.url.replace("http://","");
                                                if(obj.available_file)
                                                {
                                                    return "<span class='clickable-span underlined-span'>" + content + "</span>"
                                                }
                                                else
                                                    return content;
                                            },
                                            fillspace: 2,
                                            sort: "string"
                                        },
                                        {
                                            id: "chunk_size",
                                            header: [TooltipTemplate(LangHelper.GetString(this.Id, "File size (bytes)")), {content: "textFilter"}],
                                            width: 64,
                                            sort: "number"
                                        },
                                        {
                                            id: "request_method",
                                            header: [
                                                LangHelper.GetString(this.Id, "Method"),
                                                {
                                                content: "multiSelectFilter",
                                                options: Helper.DictToArr(HttpRequestMethodsDic),
                                                inputConfig: {suggest: {fitMaster: false}}
                                            }],
                                            width: 64,
                                            sort: "string"
                                        },
                                        {
                                            id: "info",
                                            header: "",
                                            width: 32,
                                            template: CellQuestionBtnTemplate
                                        }
                                    ],
                                    onClick: {
                                        "fa-question-circle": function(ev, id)
                                        {
                                            var item = this.getItem(id);
                                            var node = this.getItemNode(id);

                                            _this.ShowRowInfoPopup(item, node);

                                            return false;
                                        },
                                        "clickable-span": function(ev, id)
                                        {
                                            var item = this.getItem(id);

                                            _this.OpenRowFile(item);

                                            return false;
                                        }
                                    },
                                    data:[],
                                    rules:{
                                        "valid":function (value)
                                        {
                                            return value;
                                        }
                                    }
                                },
                                {
                                    view: "toolbar",
                                    padding: 0,
                                    margin: 0,
                                    height:32,
                                    elements: [
                                        {
                                            view: "pager",
                                            gravity:3,
                                            id: this.id + "RequestsPaging",
                                            template: "{common.first()} {common.prev()} {common.pages()} {common.next()} {common.last()}",
                                            master:false,
                                            on: {
                                                onAfterPageChange:function(Page)
                                                {
                                                    _this.Refresh(Page);
                                                }
                                            }
                                        },
                                        {
                                            view:"richselect",
                                            id: this.id + "RequestsPagingSize",
                                            label:LangHelper.GetString(this.Id, "On the page"),
                                            labelWidth:120,
                                            minWidth:220,
                                            value:100,
                                            options:this.PagingSizeOptions,//this.options,
                                            on: {
                                                onChange:function()
                                                {
                                                    _this.Refresh();
                                                }
                                            }
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            id: this.id + "PanelTabsImages",
                            rows: [
                                {
                                    view:"search",
                                    id: this.id + "ImagesFullTextSearch",
                                    placeholder:LangHelper.GetString("All","Search"),
                                    on: {
                                        onTimedKeyPress: function(code, e)
                                        {
                                            var __this = this;
                                            setTimeout(function(){
                                                _this.Refresh(0);
                                            },10);
                                        }
                                    },
                                    keyPressTimeout:500
                                    // width: 300
                                },
                                {
                                    view:"dataview",
                                    id:this.id + "DataView",
                                    css: "image-data-view",
                                    xCount:4,
                                    type: {
                                        // width: "auto",
                                        height: 200,
                                        css: "webix_dataview_item-decoded-image"
                                    },
                                    tooltip: function(Obj)
                                    {
                                      return Obj && Obj.url ? Obj.url : null;
                                    },
                                    template:function(Obj){
                                        var url = "/api/decoded_files/" + Obj.pcap_id + "/" + Obj.available_file;
                                        var result = "";

                                        // result += "<div style='width: 100%'><img src='" + url + "' height=120/></div>";
                                        result += "<div class='image-contain-wrapper photoshop-background'><div class='image-contain' style='background: url(" + url + ");'></div></div>";

                                        result += "<span>" + Obj.host + "</span>"

                                        return result;
                                    },
                                    // },
                                    data:[],
                                    on: {
                                        onItemClick: function (id, e, node) {
                                            var rowData = this.getItem(id);

                                            _this.OpenRowFile(rowData);
                                        }
                                    }
                                },
                                {
                                    view: "toolbar",
                                    padding: 0,
                                    margin: 0,
                                    height:32,
                                    elements: [
                                        {
                                            view: "pager",
                                            gravity:3,
                                            id: this.id + "DataViewPaging",
                                            template: "{common.first()} {common.prev()} {common.pages()} {common.next()} {common.last()}",
                                            master:false,
                                            on: {
                                                onAfterPageChange:function(Page)
                                                {
                                                    _this.Refresh(Page);
                                                }
                                            }
                                        },
                                        {
                                            view:"richselect",
                                            id: this.id + "DataViewPagingSize",
                                            label:LangHelper.GetString(this.Id, "On the page"),
                                            labelWidth:120,
                                            minWidth:220,
                                            value:100,
                                            options:this.PagingSizeOptions,//this.options,
                                            on: {
                                                onChange:function()
                                                {
                                                    _this.Refresh();
                                                }
                                            }
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    ];

    this.View = null;

    this.Views = {
        PanelTabs: null,
        RequestsPaging: null,
        RequestsPagingSize: null,
        PanelTabsRequests: null,
        PanelTabsImages: null,
        RequestsTable: null,
        RowInfoProperty: null,
        DataView: null,
        DataViewPaging: null,
        DataViewPagingSize: null,
        ImagesFullTextSearch: null
    };

    this.Subscriptions = {};

    return this;
}

TrafficDecodeWebViewController.prototype.Destroy = function ()
{
    var _this = this;

    Object.keys(this.Subscriptions).forEach(function (key)
    {
        _this.Subscriptions[key].unsubscribe();
    });
};

TrafficDecodeWebViewController.prototype.ViewDidLoad = function (Params)
{
    var _this = this;

    this.BindAll();
};

TrafficDecodeWebViewController.prototype.BindAll = function (Params)
{
    var _this = this;

    Object.keys(this.Views).forEach(function(Key){
        _this.Views[Key] = $$(_this.id + Key);
    });

    this.Subscriptions.SignalRowsUpdatedSubscription = this.ViewModel.SignalRowsUpdated.subscribe(function(Val){
        if(Val)
        {
            _this.UpdateRequestsTable();
        }
    });

    this.Subscriptions.SignalImageUpdatedSubscription = this.ViewModel.SignalImagesUpdated.subscribe(function(Val){
        if(Val)
        {
            _this.UpdateImagesDataView();
        }
    });
};

TrafficDecodeWebViewController.prototype.SetPcapIds = function(Pcaps)
{
    this.Views.RequestsTable.clearAll();
    var arr = [];
    if(Array.isArray(Pcaps))
    {
        arr = Pcaps;
    }
    else if(Pcaps){
        arr = [Pcaps];
    }
    else{
        arr = [];
    }

    this.PcapsDic = arr;
    var ids = [];
    arr.forEach(function(Val){
        ids.push(Val.pcap_id);
    });
    this.PcapIds = ids.join(",");
};

TrafficDecodeWebViewController.prototype.Refresh = function(Page, Params)
{
    var _this = this;

    var method = "RefreshRows";
    var size = {};
    var state = null, filter = {};

    if(_this.Views.PanelTabs.getValue() == this.id + "PanelTabsRequests")
    {
        size = this.Views.RequestsPagingSize.getValue();
        state = this.Views.RequestsTable.getState();
        filter = state.filter;
    }
    else{
        size = this.Views.DataViewPagingSize.getValue();
        state = {};
        filter = {full_text_search: _this.Views.ImagesFullTextSearch.getValue()};
        method = "RefreshImages";
    }

    if(this.PcapIds)
    {
        filter = webix.extend(filter, {pcap_ids: this.PcapIds});
        this.ViewModel[method]({page:Page, filter:filter, size: size, sort: state && state.sort ? state.sort:null, callback:function ()
        {

        }});
    }
};

TrafficDecodeWebViewController.prototype.UpdateRequestsTable = function (Params)
{
    var _this = this;

    this.Views.RequestsTable.clearAll();
    this.Views.RequestsTable.parse(_this.ViewModel.GetRows());

    this.Views.RequestsPagingSize.setValue(this.ViewModel.RowsFilter.Total.size);

    this.Views.RequestsPaging.define(this.ViewModel.RowsFilter.Total);
    this.Views.RequestsPaging.refresh();
};

TrafficDecodeWebViewController.prototype.ShowRowInfoPopup = function(Item, Node)
{
    var _this = this;

    // _this.Views.RowInfoTable.clearAll();
    var item = new TrafficDecodeWebModel(Item);
    var pcap = this.PcapsDic.find(function(Val){return item.pcap_id == Val.pcap_id});

    item = webix.extend(item, {filename: pcap && pcap.file_name ? pcap.file_name : ""}, true);
    this.Views.RowInfoProperty.setValues(item);
    this.Views.RowInfoProperty.resize();
    this.RowInfoContext.show(Node, {pos: "left"});
};

TrafficDecodeWebViewController.prototype.OpenRowFile = function(Item)
{
    var _this = this;
    var item = new TrafficDecodeWebModel(Item);

    window.open("/api/decoded_files/"+ item.pcap_id + "/" + item.available_file , '_blank',"width=640,height=480")
};


TrafficDecodeWebViewController.prototype.UpdateImagesDataView = function (Params)
{
    var _this = this;

    this.Views.DataView.clearAll();
    this.Views.DataView.parse(_this.ViewModel.GetImages());

    this.Views.DataViewPagingSize.setValue(this.ViewModel.ImagesFilter.Total.size);

    this.Views.DataViewPaging.define(this.ViewModel.ImagesFilter.Total);
    this.Views.DataViewPaging.refresh();
};

function TrafficDecodeDnsViewController(Params)
{

    var _this = this;

    this.Id = "TrafficDecode";
    this.id = (Params && Params.id ? Params.id : "") + this.Id + "Dns";

    this.ViewModel = new TrafficDecodeDnsManager();

    this.PagingSizeOptions = [
        {id:10, value:"10"},
        {id:50, value:"50"},
        {id:100, value:"100"},
        {id:200, value:"200"},
        {id:500, value:"500"},
        {id:1000, value:"1 000"},
        {id:10000, value:"10 000"}
    ];

    this.PcapsDic = [];

    this.RowInfoContext = webix.ui({
        view:"popup",
        width:640,
        height:640,
        body:{
            borderless: true,
            margin: 0,
            padding: 0,
            rows:[
                BlockHeaderTemplate({title:LangHelper.GetString(this.Id, "DNS info"), icon:"file-certificate", borderless: true}),
                {
                    id: this.id + "RowInfoTable",
                    view: "datatable",
                    borderless: true,
                    editable: false,
                    tooltip: true,
                    height: 240,
                    scroll: "y",
                    columns: [
                        {
                            id: "id",
                            header: LangHelper.GetString(this.Id, "Key"),
                            fillspace: 1
                        },
                        {
                            id: "value",
                            header: LangHelper.GetString(this.Id, "Value"),
                            fillspace: 2
                        }
                    ]
                },
                BlockHeaderTemplate({title:LangHelper.GetString(this.Id, "Request info"), icon:"table", borderless: true}),
                {
                    id: this.id + "RowInfoProperty",
                    view: "property",
                    css: "dpiui2-webix-property",
                    borderless: true,
                    // tooltip: true,
                    editable: false,
                    scroll: "y",
                    nameWidth: 128,
                    tooltip: function(Params)
                    {
                        return Params.value;
                    },
                    elements: [
                        {
                            label: LangHelper.GetString(this.Id, "Request info"),
                            type: "label"
                        },
                        {
                            label: LangHelper.GetString(this.Id , "Request date"),
                            type: "text",
                            id: "req_date"
                        },
                        {
                            label: LangHelper.GetString(this.Id ,"Response time"),
                            type: "text",
                            id: "res_time"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Udp"),
                            type: "label"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Src port"),
                            type: "text",
                            id: "srcport"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Dst port"),
                            type: "text",
                            id: "dstport"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Ip"),
                            type: "label"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Ip proto"),
                            type: "text",
                            id: "ip_proto"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Ip version"),
                            type: "text",
                            id: "ip_version"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Src ip"),
                            type: "text",
                            id: "ip_src"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Dst ip"),
                            type: "text",
                            id: "ip_dst"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Eth"),
                            type: "label"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Eth type"),
                            type: "text",
                            id: "eth_type"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Src eth"),
                            type: "text",
                            id: "eth_src"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Dst eth"),
                            type: "text",
                            id: "eth_dst"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "File"),
                            type: "label"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Row Id"),
                            type: "text",
                            id: "row_id"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "File Id"),
                            type: "text",
                            id: "pcap_id"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "File name"),
                            type: "text",
                            id: "filename"
                        }
                    ]
                },
                {
                    height:16
                },
                {
                    cols:[
                        {},
                        {
                        },
                        {
                            view:"button",
                            type:"form",
                            label:LangHelper.GetString(this.Id, "Close"),
                            click:function () {
                                _this.RowInfoContext.hide();
                            }
                        }
                    ]
                }
            ]
        }
    });

    this.ViewConfig = [
        {
            id: this.id,
            rows: [
                BlockHeaderTemplate({title:LangHelper.GetString(this.Id, "Decoded DNS items"), icon:"file-certificate"}),
                {
                    rows: [
                        {
                            view: "datatable",
                            id: this.id + "DnsTable",
                            select: "row",
                            resizeColumn: true,
                            tooltip:true,
                            on: {
                                onItemClick: function (id,e,node)
                                {
                                    var rowData = this.getItem(id);
                                    if (id.column === 'edit')
                                    {

                                    }
                                    else if(id.column === 'trash')
                                    {

                                    }
                                },
                                onBeforeFilter:function()
                                {
                                    return false;
                                },
                                onAfterFilter:function()
                                {
                                    _this.Refresh();
                                },
                                onBeforeSort:function()
                                {
                                    _this.Refresh();
                                    return false;
                                }
                            },
                            columns: [
                                {
                                    id: "req_date",
                                    header: [LangHelper.GetString(this.Id, "Date"), {content: "dateRangeFilter"}],
                                    //format: webix.Date.dateToStr("%d.%m.%Y"),
                                    template:function(obj, common){
                                        if(obj.req_date)
                                        {
                                            var format =  webix.Date.dateToStr("%d.%m.%Y %H:%i:%s");
                                            return format(obj.req_date);
                                        }

                                        return "";
                                    },
                                    width: 160,
                                    sort: "date"
                                },
                                {
                                    id: "full_text_search",
                                    header: [LangHelper.GetString(this.Id, "Host"), {content: "textFilter"}],
                                    template:function(obj, common, value, config)
                                    {
                                        var res = obj && obj.host ? obj.host : "";
                                        var splited = res.split(",");
                                        return splited && splited.length > 0 ? splited[0] : "";
                                    },
                                    fillspace: 2,
                                    sort: "string"
                                },
                                {
                                    id: "info",
                                    header: "",
                                    width: 32,
                                    template: CellQuestionBtnTemplate
                                }
                            ],
                            onClick: {
                                "fa-question-circle": function(ev, id)
                                {
                                    var item = this.getItem(id);
                                    var node = this.getItemNode(id);

                                    _this.ShowRowInfoPopup(item, node);

                                    return false;
                                }
                            },
                            data:[],
                            rules:{
                                "valid":function (value)
                                {
                                    return value;
                                }
                            }
                        },
                        {
                            view: "toolbar",
                            padding: 0,
                            margin: 0,
                            height:32,
                            elements: [
                                {
                                    view: "pager",
                                    gravity:3,
                                    id: this.id + "DnsPaging",
                                    template: "{common.first()} {common.prev()} {common.pages()} {common.next()} {common.last()}",
                                    master:false,
                                    on: {
                                        onAfterPageChange:function(Page)
                                        {
                                            _this.Refresh(Page);
                                        }
                                    }
                                },
                                {
                                    view:"richselect",
                                    id: this.id + "DnsPagingSize",
                                    label:LangHelper.GetString(this.Id, "On the page"),
                                    labelWidth:120,
                                    minWidth:220,
                                    value:100,
                                    options:this.PagingSizeOptions,//this.options,
                                    on: {
                                        onChange:function()
                                        {
                                            _this.Refresh();
                                        }
                                    }
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    ];

    this.View = null;

    this.Views = {
        DnsTable: null,
        DnsPaging: null,
        DnsPagingSize: null,
        RowInfoProperty: null,
        RowInfoTable: null
    };

    this.Subscriptions = {};

    return this;
}

TrafficDecodeDnsViewController.prototype.Destroy = function ()
{
    var _this = this;

    Object.keys(this.Subscriptions).forEach(function (key)
    {
        _this.Subscriptions[key].unsubscribe();
    });
};

TrafficDecodeDnsViewController.prototype.ViewDidLoad = function (Params)
{
    var _this = this;

    this.BindAll();
};

TrafficDecodeDnsViewController.prototype.BindAll = function (Params)
{
    var _this = this;

    Object.keys(this.Views).forEach(function(Key){
        _this.Views[Key] = $$(_this.id + Key);
    });

    this.Subscriptions.SignalRowsUpdatedSubscription = this.ViewModel.SignalRowsUpdated.subscribe(function(Val){
        if(Val)
        {
            _this.UpdateRequestsTable();
        }
    });
};

TrafficDecodeDnsViewController.prototype.SetPcapIds = function(Pcaps)
{
    this.Views.DnsTable.clearAll();
    var arr = [];
    if(Array.isArray(Pcaps))
    {
        arr = Pcaps;
    }
    else if(Pcaps){
        arr = [Pcaps];
    }
    else{
        arr = [];
    }

    this.PcapsDic = arr;
    var ids = [];
    arr.forEach(function(Val){
        ids.push(Val.pcap_id);
    });
    this.PcapIds = ids.join(",");
};

TrafficDecodeDnsViewController.prototype.Refresh = function(Page, Params)
{
    var _this = this;

    var size = this.Views.DnsPagingSize.getValue();
    var state = this.Views.DnsTable.getState();
    var filter = state && state.filter ? state.filter : {};

    if(this.PcapIds)
    {
        filter = webix.extend(filter, {pcap_ids: this.PcapIds});
        this.ViewModel.RefreshRows({page:Page, filter:filter, size: size, sort: state && state.sort ? state.sort:null, callback:function ()
            {

            }});
    }
};

TrafficDecodeDnsViewController.prototype.UpdateRequestsTable = function (Params)
{
    var _this = this;

    this.Views.DnsTable.clearAll();
    this.Views.DnsTable.parse(_this.ViewModel.GetRows());

    this.Views.DnsPagingSize.setValue(this.ViewModel.RowsFilter.Total.size);

    this.Views.DnsPaging.define(this.ViewModel.RowsFilter.Total);
    this.Views.DnsPaging.refresh();
};

TrafficDecodeDnsViewController.prototype.ShowRowInfoPopup = function(Item, Node)
{
    var _this = this;

    _this.Views.RowInfoTable.clearAll();
    var item = new TrafficDecodeDnsModel(Item);
    var pcap = this.PcapsDic.find(function(Val){return item.pcap_id == Val.pcap_id});
    var data = [];


    item = webix.extend(item, {filename: pcap && pcap.file_name ? pcap.file_name : ""}, true);

    var data = this.GetRowInfoTableData(item.host, "Host", []);
    data = this.GetRowInfoTableData(item.address, "Address", data);
    data = this.GetRowInfoTableData(item.cname, "CName", data);
    data = this.GetRowInfoTableData(item.srv_name, "Server name", data);
    data = this.GetRowInfoTableData(item.domain_name, "Domain name", data);

    _this.Views.RowInfoTable.parse(data);
    this.Views.RowInfoProperty.setValues(item);
    this.Views.RowInfoProperty.resize();
    this.RowInfoContext.show(Node, {pos: "left"});
};

TrafficDecodeDnsViewController.prototype.GetRowInfoTableData = function(Prop, Label, Result)
{
  var _this = this;

  var strings = Prop.split(",");
  var arr = {};
  strings.forEach(function(Val, i){
      if(Val)
      {
          arr[Val] = Val;
      }
  });

  Object.keys(arr).forEach(function(Val, i){
      Result.push({
          id: LangHelper.GetString(_this.Id, Label) + (Object.keys(arr).length > 1 ? (" " + (i + 1)) : ""),
          value: Val
      });
  });

  return Result;
};

function TrafficDecodeVoipViewController(Params)
{

    var _this = this;

    this.Id = "TrafficDecode";
    this.id = (Params && Params.id ? Params.id : "") + this.Id + "Voip";

    this.ViewModel = new TrafficDecodeVoipManager();

    this.PagingSizeOptions = [
        {id:10, value:"10"},
        {id:50, value:"50"},
        {id:100, value:"100"},
        {id:200, value:"200"},
        {id:500, value:"500"},
        {id:1000, value:"1 000"},
        {id:10000, value:"10 000"}
    ];

    this.PcapsDic = [];

    this.RowLogInfoContext = webix.ui({
        view:"popup",
        width:640,
        height:400,
        body:{
            borderless: true,
            margin: 0,
            padding: 0,
            rows:[
                BlockHeaderTemplate({title:LangHelper.GetString(this.Id, "Requests logs"), icon:"stack-overflow", borderless: true}),
                {
                    view:"codemirror-editor",
                    id:this.id + "RowLogInfoContextEditor",
                    value:""
                },
                {
                    height:16
                },
                {
                    cols:[
                        {},
                        {
                        },
                        {
                            view:"button",
                            type:"form",
                            label:LangHelper.GetString(this.Id, "Close"),
                            click:function () {
                                _this.RowLogInfoContext.hide();
                            }
                        }
                    ]
                }
            ]
        }
    });

    this.RowInfoContext = webix.ui({
        view:"popup",
        width:640,
        height:640,
        body:{
            borderless: true,
            margin: 0,
            padding: 0,
            rows:[
                BlockHeaderTemplate({title:LangHelper.GetString(this.Id, "Voip info"), icon:"voicemail", borderless: true}),
                {
                    id: this.id + "RowInfoProperty",
                    view: "property",
                    css: "dpiui2-webix-property",
                    borderless: true,
                    // tooltip: true,
                    editable: false,
                    scroll: "y",
                    nameWidth: 256,
                    tooltip: function(Params)
                    {
                        return Params.value;
                    },
                    elements: [
                        {
                            label: LangHelper.GetString(this.Id, "TCP"),
                            type: "label"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Src port"),
                            type: "text",
                            id: "srcport"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Dst port"),
                            type: "text",
                            id: "dstport"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Ip"),
                            type: "label"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Ip proto"),
                            type: "text",
                            id: "ip_proto"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Ip version"),
                            type: "text",
                            id: "ip_version"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Src ip"),
                            type: "text",
                            id: "ip_src"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Dst ip"),
                            type: "text",
                            id: "ip_dst"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Eth"),
                            type: "label"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Eth type"),
                            type: "text",
                            id: "eth_type"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Src eth"),
                            type: "text",
                            id: "eth_src"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Dst eth"),
                            type: "text",
                            id: "eth_dst"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Sip info"),
                            type: "label"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Duration"),
                            type: "text",
                            id: "duration"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Caller"),
                            type: "text",
                            id: "sip_from"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Called"),
                            type: "text",
                            id: "sip_to"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Call ID"),
                            type: "text",
                            id: "sip_call_id"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Rtp info"),
                            type: "label"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Ssrc from"),
                            type: "text",
                            id: "ssrc_from"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Ssrc to"),
                            type: "text",
                            id: "ssrc_to"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Record") + " 1",
                            type: "text",
                            id: "file_1"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Record") + " 2",
                            type: "text",
                            id: "file_2"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Combined record"),
                            type: "text",
                            id: "file_combined"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "File"),
                            type: "label"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "File Id"),
                            type: "text",
                            id: "pcap_id"
                        }
                    ]
                },
                {
                    height:16
                },
                {
                    cols:[
                        {},
                        {
                        },
                        {
                            view:"button",
                            type:"form",
                            label:LangHelper.GetString(this.Id, "Close"),
                            click:function () {
                                _this.RowInfoContext.hide();
                            }
                        }
                    ]
                }
            ]
        }
    });

    this.RowAudioContext = webix.ui({
        view: "popup",
        width: 320,
        height: 360,
        modal: true,
        body:{
            borderless: true,
            margin: 0,
            padding: 0,
            rows:[
                BlockHeaderTemplate({title:LangHelper.GetString(this.Id, "Records"), icon:"cassette-tape", borderless: true}),
                {
                    id: this.id + "RowAudioContextTemplate",
                    // height: 360 - 88,
                    template: function(Obj)
                    {
                        var rows = [];
                        if(Obj.file_1)
                        {
                            rows.push({
                                label: LangHelper.GetString(_this.Id, "Record") + " 1. " + Obj.file_1,
                                url: "/api/decoded_files/" + Obj.pcap_id + "/" + Obj.file_1
                            });
                        }

                        if(Obj.file_2)
                        {
                            rows.push({
                                label: LangHelper.GetString(_this.Id, "Record") + " 2. " + Obj.file_2,
                                url: "/api/decoded_files/" + Obj.pcap_id + "/" + Obj.file_2
                            });
                        }

                        if(Obj.file_combined)
                        {
                            rows.push({
                                label: LangHelper.GetString(_this.Id, "Combined record") + ".",
                                url: "/api/decoded_files/" + Obj.pcap_id + "/" + Obj.file_combined
                            });
                        }

                        var res = "<div>";

                        rows.forEach(function(Val){
                            var url = Val.url + "?type=Voip";
                            res += "<div class='pcap-decode-voip-record'><span>" + Val.label + "</span>";
                            res += "<audio id='" + webix.uid() + "' controls>" +
                                "<source src='" + url + "'>" +
                                "<a href='" + url + "'>" + LangHelper.GetString(_this.Id, "Download record") + "</a>" +
                                "</audio></div>"
                        });

                        return res + "</div>";
                    }
                },
                {
                    height:16
                },
                {
                    cols:[
                        {},
                        {
                        },
                        {
                            view:"button",
                            label:LangHelper.GetString(this.Id, "Close"),
                            click:function () {
                                _this.StopAudios();
                                _this.RowAudioContext.hide();
                            }
                        }
                    ]
                }
            ]
        }
    });

    this.ViewConfig = [
        {
            id: this.id,
            rows: [
                BlockHeaderTemplate({title:LangHelper.GetString(this.Id, "Decoded Voip items"), icon:"voicemail"}),
                {
                    rows: [
                        {
                            view: "datatable",
                            id: this.id + "Table",
                            select: "row",
                            resizeColumn: true,
                            tooltip:true,
                            on: {
                                onItemClick: function (id,e,node)
                                {
                                    var rowData = this.getItem(id);
                                    if (id.column === 'edit')
                                    {

                                    }
                                    else if(id.column === 'trash')
                                    {

                                    }
                                },
                                onBeforeFilter:function()
                                {
                                    return false;
                                },
                                onAfterFilter:function()
                                {
                                    _this.Refresh();
                                },
                                onBeforeSort:function()
                                {
                                    _this.Refresh();
                                    return false;
                                }
                            },
                            columns: [
                                {
                                    id: "audio",
                                    header: "",
                                    width: 32,
                                    template: function(Obj) {
                                        var res = "";
                                        if (Obj.file_1 || Obj.file_2 || Obj.file_combined) {
                                            res = CellCassetteBtnTemplate();
                                        }
                                        return res;
                                    }
                                },
                                {
                                    id: "req_date",
                                    header: [LangHelper.GetString(this.Id, "Date"), {content: "dateRangeFilter"}],
                                    //format: webix.Date.dateToStr("%d.%m.%Y"),
                                    template:function(obj, common){
                                        if(obj.req_date)
                                        {
                                            var format =  webix.Date.dateToStr("%d.%m.%Y %H:%i:%s");
                                            return format(obj.req_date);
                                        }

                                        return "";
                                    },
                                    width: 160,
                                    sort: "date"
                                },
                                {
                                    id: "duration",
                                    header: [LangHelper.GetString(this.Id, "Duration"), {content: "textFilter"}],
                                    sort: "string",
                                    width: 80,
                                    template: function(Obj)
                                    {
                                        return new Date((Obj && Obj.duration ? Obj.duration : 0)*1000).toISOString().substr(11,8);
                                    }
                                },
                                {
                                    id: "sip_from",
                                    header: [LangHelper.GetString(this.Id, "Caller"), {content: "textFilter"}],
                                    fillspace: 1,
                                    sort: "string",
                                    template: function(Obj)
                                    {
                                        return Helper.ReplaceArrows(Obj.sip_from);
                                    }
                                },
                                {
                                    id: "sip_to",
                                    header: [LangHelper.GetString(this.Id, "Called"), {content: "textFilter"}],
                                    fillspace: 1,
                                    sort: "string",
                                    template: function(Obj)
                                    {
                                        return Helper.ReplaceArrows(Obj.sip_to);
                                    }
                                },
                                {
                                    id: "log",
                                    header: "",
                                    width: 32,
                                    template: CellLogsBtnTemplate
                                },
                                {
                                    id: "info",
                                    header: "",
                                    width: 32,
                                    template: CellQuestionBtnTemplate
                                }
                            ],
                            onClick: {
                                "fa-question-circle": function(ev, id)
                                {
                                    var item = this.getItem(id);
                                    var node = this.getItemNode(id);

                                    _this.ShowRowInfoPopup(item, node);

                                    return false;
                                },
                                "fa-stack-overflow": function(ev, id)
                                {
                                    var item = this.getItem(id);
                                    var node = this.getItemNode(id);

                                    _this.ShowRowLogInfoPopup(item, node);

                                    return false;
                                },
                                "fa-cassette-tape": function(ev, id)
                                {
                                    var item = this.getItem(id);
                                    var node = this.getItemNode(id);

                                    _this.ShowRowAudioPopup(item, node);

                                    return false;
                                }
                            },
                            data:[],
                            rules:{
                                "valid":function (value)
                                {
                                    return value;
                                }
                            }
                        },
                        {
                            view: "toolbar",
                            padding: 0,
                            margin: 0,
                            height:32,
                            elements: [
                                {
                                    view: "pager",
                                    gravity:3,
                                    id: this.id + "Paging",
                                    template: "{common.first()} {common.prev()} {common.pages()} {common.next()} {common.last()}",
                                    master:false,
                                    on: {
                                        onAfterPageChange:function(Page)
                                        {
                                            _this.Refresh(Page);
                                        }
                                    }
                                },
                                {
                                    view:"richselect",
                                    id: this.id + "PagingSize",
                                    label:LangHelper.GetString(this.Id, "On the page"),
                                    labelWidth:120,
                                    minWidth:220,
                                    value:100,
                                    options:this.PagingSizeOptions,//this.options,
                                    on: {
                                        onChange:function()
                                        {
                                            _this.Refresh();
                                        }
                                    }
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    ];

    this.View = null;

    this.Views = {
        Table: null,
        Paging: null,
        PagingSize: null,
        RowInfoProperty: null,
        RowInfoTable: null,
        RowLogInfoContextEditor: null,
        RowAudioContextTemplate: null
    };

    this.Subscriptions = {};

    return this;
}

TrafficDecodeVoipViewController.prototype.Destroy = function ()
{
    var _this = this;

    Object.keys(this.Subscriptions).forEach(function (key)
    {
        _this.Subscriptions[key].unsubscribe();
    });
};

TrafficDecodeVoipViewController.prototype.ViewDidLoad = function (Params)
{
    var _this = this;
    this.BindAll();
};

TrafficDecodeVoipViewController.prototype.BindAll = function (Params)
{
    var _this = this;

    Object.keys(this.Views).forEach(function(Key){
        _this.Views[Key] = $$(_this.id + Key);
    });

    this.Subscriptions.SignalRowsUpdatedSubscription = this.ViewModel.SignalRowsUpdated.subscribe(function(Val){
        if(Val)
        {
            _this.UpdateRequestsTable();
        }
    });
};

TrafficDecodeVoipViewController.prototype.Refresh = function(Page, Params)
{
    var _this = this;

    var size = this.Views.PagingSize.getValue();
    var state = this.Views.Table.getState();
    var filter = state && state.filter ? state.filter : {};

    if(this.PcapIds)
    {
        filter = webix.extend(filter, {pcap_ids: this.PcapIds});
        this.ViewModel.RefreshRows({page:Page, filter:filter, size: size, sort: state && state.sort ? state.sort:null, callback:function ()
            {

            }});
    }
};

TrafficDecodeVoipViewController.prototype.SetPcapIds = function(Pcaps)
{
    var arr = [];
    if(Array.isArray(Pcaps))
    {
        arr = Pcaps;
    }
    else if(Pcaps){
        arr = [Pcaps];
    }
    else{
        arr = [];
    }

    this.PcapsDic = arr;
    var ids = [];
    arr.forEach(function(Val){
        ids.push(Val.pcap_id);
    });
    this.PcapIds = ids.join(",");
};

TrafficDecodeVoipViewController.prototype.UpdateRequestsTable = function (Params)
{
    var _this = this;

    this.Views.Table.clearAll();
    this.Views.Table.parse(_this.ViewModel.GetRows());

    this.Views.PagingSize.setValue(this.ViewModel.RowsFilter.Total.size);

    this.Views.Paging.define(this.ViewModel.RowsFilter.Total);
    this.Views.Paging.refresh();
};

TrafficDecodeVoipViewController.prototype.ShowRowInfoPopup = function(Item, Node)
{
    var _this = this;

    var item = new TrafficDecodeVoipModel(Item);
    var pcap = this.PcapsDic.find(function(Val){return item.pcap_id == Val.pcap_id});
    var data = [];


    item = webix.extend(item, {filename: pcap && pcap.file_name ? pcap.file_name : ""}, true);

    item.sip_from = Helper.ReplaceArrows(item.sip_from);
    item.sip_to = Helper.ReplaceArrows(item.sip_to);
    item.duration = new Date((item.duration ? item.duration : 0)*1000).toISOString().substr(11,8);
    this.Views.RowInfoProperty.setValues(item);
    this.Views.RowInfoProperty.resize();
    this.RowInfoContext.show(Node, {pos: "left"});
};

TrafficDecodeVoipViewController.prototype.ShowRowLogInfoPopup = function(Item, Node)
{
    var _this = this;

    var item = new TrafficDecodeVoipModel(Item);

    this.RowLogInfoContext.show(Node, {pos: "left"});
    this.Views.RowLogInfoContextEditor.setValue(item.msg_headers);
};

TrafficDecodeVoipViewController.prototype.ShowRowAudioPopup = function(Item, Node)
{
    var _this = this;

    var item = new TrafficDecodeVoipModel(Item);

    this.Views.RowAudioContextTemplate.setValues(item);

    this.RowAudioContext.show(Node, {pos: "left"});

    setTimeout(function(){
        var audios = document.getElementsByTagName('audio');

        for(var i = 0; i < audios.length; i++)
        {
            audios[i].addEventListener('play', function(event){
                var id = event.target && event.target.id ? event.target.id : null;
                if(id)
                {
                    _this.StopAudios(id);
                }
            });
        }
    }, 10);
};

TrafficDecodeVoipViewController.prototype.StopAudios = function(Id)
{
  var _this = this;

  var audios = document.getElementsByTagName('audio');

  for(var i = 0; i < audios.length; i++)
  {
      var stop = true;
      if(Id && audios[i].id == Id)
      {
          stop = false;
      }
      if(stop)
      {
          audios[i].pause();
      }
  }
};
function TrafficDecodeMailViewController(Params)
{

    var _this = this;

    this.Id = "TrafficDecode";
    this.id = (Params && Params.id ? Params.id : "") + this.Id + "Mail";

    this.ViewModel = new TrafficDecodeMailManager();

    this.PagingSizeOptions = [
        {id:10, value:"10"},
        {id:50, value:"50"},
        {id:100, value:"100"},
        {id:200, value:"200"},
        {id:500, value:"500"},
        {id:1000, value:"1 000"},
        {id:10000, value:"10 000"}
    ];

    this.PcapsDic = [];

    this.RowInfoContext = webix.ui({
        view:"popup",
        width:640,
        height:640,
        body:{
            borderless: true,
            margin: 0,
            padding: 0,
            rows:[
                BlockHeaderTemplate({title:LangHelper.GetString(this.Id, "Mail info"), icon:"envelope", borderless: true}),
                {
                    id: this.id + "RowInfoProperty",
                    view: "property",
                    css: "dpiui2-webix-property",
                    borderless: true,
                    // tooltip: true,
                    editable: false,
                    scroll: "y",
                    nameWidth: 256,
                    tooltip: function(Params)
                    {
                        return Params.value;
                    },
                    elements: [
                        {
                            label: LangHelper.GetString(this.Id, "TCP"),
                            type: "label"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Src port"),
                            type: "text",
                            id: "src_port"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Dst port"),
                            type: "text",
                            id: "dst_port"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Tcp stream"),
                            type: "text",
                            id: "tcp_stream"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Ip"),
                            type: "label"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Ip proto"),
                            type: "text",
                            id: "ip_proto"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Ip version"),
                            type: "text",
                            id: "ip_version"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Src ip"),
                            type: "text",
                            id: "ip_src"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Dst ip"),
                            type: "text",
                            id: "ip_dst"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Eth"),
                            type: "label"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Eth type"),
                            type: "text",
                            id: "eth_type"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Src eth"),
                            type: "text",
                            id: "eth_src"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Dst eth"),
                            type: "text",
                            id: "eth_dst"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "IMF info"),
                            type: "label"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Sender"),
                            type: "text",
                            id: "imf_from"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Receiver"),
                            type: "text",
                            id: "imf_to"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Subject"),
                            type: "text",
                            id: "imf_subject"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Message ID"),
                            type: "text",
                            id: "imf_message_id"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "User agent"),
                            type: "text",
                            id: "imf_user_agent"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Mime version"),
                            type: "text",
                            id: "imf_mime_version"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Content type"),
                            type: "text",
                            id: "imf_content_type"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Language"),
                            type: "text",
                            id: "imf_lang"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "MIME info"),
                            type: "label"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Multipart type"),
                            type: "text",
                            id: "mime_multipart_type"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Multipart content type"),
                            type: "text",
                            id: "mime_multipart_content_type"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Multipart content encoding"),
                            type: "text",
                            id: "mime_multipart_content_encoding"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Multipart content disposition"),
                            type: "text",
                            id: "mime_multipart_content_disposition"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "File"),
                            type: "label"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Row Id"),
                            type: "text",
                            id: "row_id"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "File Id"),
                            type: "text",
                            id: "pcap_id"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "File name"),
                            type: "text",
                            id: "available_file"
                        }
                    ]
                },
                {height: 16},
                {
                    cols:[
                        {},
                        {
                        },
                        {
                            view:"button",
                            type:"form",
                            label:LangHelper.GetString(this.Id, "Close"),
                            click:function () {
                                _this.RowInfoContext.hide();
                            }
                        }
                    ]
                }
            ]
        }
    });

    this.EnvelopContext = webix.ui({
        view: "popup",
        width: 840,
        height: 840,
        modal: true,
        body:{
            borderless: true,
            margin: 0,
            padding: 0,
            rows:[
                BlockHeaderTemplate({title:LangHelper.GetString(this.Id, "Mail info"), icon:"envelope", borderless: true}),
                {
                    id: this.id + "EnvelopeInfo",
                    borderless: true,
                    template: function(Obj)
                    {
                        var tmpl = "<div>";

                        tmpl += "<div class='dpiui2-envelope-row'><div class='dpiui2-envelope-header'>" + LangHelper.GetString(_this.Id, "Sender") + ":</div> " + Obj.imf_from + "</div>";
                        tmpl += "<div class='dpiui2-envelope-row'><div class='dpiui2-envelope-header'>" + LangHelper.GetString(_this.Id, "Receiver") + ":</div> " + Obj.imf_to + "</div>";
                        tmpl += "<div class='dpiui2-envelope-row'><div class='dpiui2-envelope-header'>" + LangHelper.GetString(_this.Id, "Subject") + ":</div> " + Obj.imf_subject + "</div>";

                        var attachments = JSON.parse(Obj.imf_attachments);



                        tmpl += "<div class='dpiui2-envelope-message'><pre>" + Obj.imf_message + "</pre></div>";


                        if(attachments && attachments.length > 0)
                        {
                            tmpl += "<div class='dpiui2-envelope-attachments'>";
                            attachments.forEach(function(Att){
                                tmpl += "<div attr-elem='" + JSON.stringify(Att)+ "' class='dpiui2-envelope-attachment'><span class='webix_icon fa-download'></span>" + Att.Filename + "</div>"
                            });
                            tmpl += "</div>";

                                //
                        }

                        tmpl += "</div>";
                        return tmpl;
                    },
                    onClick:{
                        'dpiui2-envelope-attachment': function(ev, id)
                        {
                            var data = ev.target.getAttribute('attr-elem');

                            if(!data && ev.target.tagName.toLowerCase() == "span")
                            {
                                var parent = ev.target.parentElement || ev.target.parentNode;

                                if(parent)
                                {
                                    data = parent.getAttribute('attr-elem');
                                }

                            }
                            try{
                                if(data)
                                {
                                    _this.DownloadAttachment(JSON.parse(data));
                                }
                            }
                            catch(e)
                            {}

                            return false;
                        }
                    }
                },
                {height: 16},
                {
                    cols:[
                        {},
                        {},
                        {
                            view:"button",
                            type:"form",
                            label:LangHelper.GetString(this.Id, "Close"),
                            click:function () {
                                _this.EnvelopContext.hide();
                            }
                        }
                    ]
                }
            ]
        }
    });

    this.ViewConfig = [
        {
            id: this.id,
            rows: [
                BlockHeaderTemplate({title:LangHelper.GetString(this.Id, "Decoded Mail items"), icon:"envelope"}),
                {
                    rows: [
                        {
                            view: "datatable",
                            id: this.id + "MailTable",
                            select: "row",
                            resizeColumn: true,
                            tooltip:true,
                            on: {
                                onItemClick: function (id,e,node)
                                {
                                    var rowData = this.getItem(id);
                                    if (id.column === 'edit')
                                    {

                                    }
                                    else if(id.column === 'trash')
                                    {

                                    }
                                },
                                onBeforeFilter:function()
                                {
                                    return false;
                                },
                                onAfterFilter:function()
                                {
                                    _this.Refresh();
                                },
                                onBeforeSort:function()
                                {
                                    _this.Refresh();
                                    return false;
                                }
                            },
                            columns: [
                                {
                                    id: "req_date",
                                    header: [LangHelper.GetString(this.Id, "Date"), {content: "dateRangeFilter"}],
                                    //format: webix.Date.dateToStr("%d.%m.%Y"),
                                    template:function(obj, common){
                                        if(obj.req_date)
                                        {
                                            var format =  webix.Date.dateToStr("%d.%m.%Y %H:%i:%s");
                                            return format(obj.req_date);
                                        }

                                        return "";
                                    },
                                    width: 160,
                                    sort: "date"
                                },
                                {
                                    id: "imf_from",
                                    header: [LangHelper.GetString(this.Id, "Sender"), {content: "textFilter"}],
                                    fillspace: 1,
                                    sort: "string",
                                    template:function(Obj)
                                    {
                                        return Helper.ReplaceArrows(Obj.imf_from);
                                    }
                                },
                                {
                                    id: "imf_to",
                                    header: [LangHelper.GetString(this.Id, "Receiver"), {content: "textFilter"}],
                                    fillspace: 1,
                                    sort: "string",
                                    template:function(Obj)
                                    {
                                        return Helper.ReplaceArrows(Obj.imf_to);
                                    }
                                },
                                {
                                    id: "imf_subject",
                                    header: [LangHelper.GetString(this.Id, "Subject"), {content: "textFilter"}],
                                    fillspace: 1,
                                    sort: "string"
                                },
                                {
                                    id: "envelope",
                                    header: "",
                                    width: 32,
                                    template: CellEnvelopeBtnTemplate
                                },
                                {
                                    id: "available_file",
                                    header: "",
                                    width: 32,
                                    template: function(Obj)
                                    {
                                        if(Obj.available_file)
                                        {
                                            // return "<a href='/api/decoded_files/mails/" + Obj.row_id + "'>" + CellDownloadBtnTemplate() + "</a>";
                                            return CellDownloadBtnTemplate();
                                        }

                                        return "";
                                    }
                                },
                                {
                                    id: "info",
                                    header: "",
                                    width: 32,
                                    template: CellQuestionBtnTemplate
                                }
                            ],
                            onClick: {
                                "fa-question-circle": function(ev, id)
                                {
                                    var item = this.getItem(id);
                                    var node = this.getItemNode(id);

                                    _this.ShowRowInfoPopup(item, node);

                                    return false;
                                },
                                "fa-download": function(ev, id)
                                {
                                    var item = this.getItem(id);
                                    //
                                    _this.DownloadEmailFile(item);

                                    return false;
                                },
                                "fa-envelope": function(ev, id)
                                {
                                    var item = this.getItem(id);
                                    var node = this.getItemNode(id);

                                    _this.ShowEnvelopeInfoPopup(item, node);

                                    return false;
                                }
                            },
                            data:[],
                            rules:{
                                "valid":function (value)
                                {
                                    return value;
                                }
                            }
                        },
                        {
                            view: "toolbar",
                            padding: 0,
                            margin: 0,
                            height:32,
                            elements: [
                                {
                                    view: "pager",
                                    gravity:3,
                                    id: this.id + "MailPaging",
                                    template: "{common.first()} {common.prev()} {common.pages()} {common.next()} {common.last()}",
                                    master:false,
                                    on: {
                                        onAfterPageChange:function(Page)
                                        {
                                            _this.Refresh(Page);
                                        }
                                    }
                                },
                                {
                                    view:"richselect",
                                    id: this.id + "MailPagingSize",
                                    label:LangHelper.GetString(this.Id, "On the page"),
                                    labelWidth:120,
                                    minWidth:220,
                                    value:100,
                                    options:this.PagingSizeOptions,//this.options,
                                    on: {
                                        onChange:function()
                                        {
                                            _this.Refresh();
                                        }
                                    }
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    ];

    this.View = null;

    this.Views = {
        MailTable: null,
        MailPaging: null,
        MailPagingSize: null,
        RowInfoProperty: null,
        RowInfoTable: null,
        EnvelopeInfo: null
    };

    this.Subscriptions = {};

    return this;
}

TrafficDecodeMailViewController.prototype.Destroy = function ()
{
    var _this = this;

    Object.keys(this.Subscriptions).forEach(function (key)
    {
        _this.Subscriptions[key].unsubscribe();
    });
};

TrafficDecodeMailViewController.prototype.ViewDidLoad = function (Params)
{
    var _this = this;

    this.BindAll();
};

TrafficDecodeMailViewController.prototype.BindAll = function (Params)
{
    var _this = this;

    Object.keys(this.Views).forEach(function(Key){
        _this.Views[Key] = $$(_this.id + Key);
    });

    this.Subscriptions.SignalRowsUpdatedSubscription = this.ViewModel.SignalRowsUpdated.subscribe(function(Val){
        if(Val)
        {
            _this.UpdateRequestsTable();
        }
    });
};

TrafficDecodeMailViewController.prototype.SetPcapIds = function(Pcaps)
{
    this.Views.MailTable.clearAll();
    var arr = [];
    if(Array.isArray(Pcaps))
    {
        arr = Pcaps;
    }
    else if(Pcaps){
        arr = [Pcaps];
    }
    else{
        arr = [];
    }

    this.PcapsDic = arr;
    var ids = [];
    arr.forEach(function(Val){
        ids.push(Val.pcap_id);
    });
    this.PcapIds = ids.join(",");
};

TrafficDecodeMailViewController.prototype.Refresh = function(Page, Params)
{
    var _this = this;

    var size = this.Views.MailPagingSize.getValue();
    var state = this.Views.MailTable.getState();
    var filter = state && state.filter ? state.filter : {};

    if(this.PcapIds)
    {
        filter = webix.extend(filter, {pcap_ids: this.PcapIds});
        this.ViewModel.RefreshRows({page:Page, filter:filter, size: size, sort: state && state.sort ? state.sort:null, callback:function ()
            {

            }});
    }
};

TrafficDecodeMailViewController.prototype.UpdateRequestsTable = function (Params)
{
    var _this = this;

    this.Views.MailTable.clearAll();
    this.Views.MailTable.parse(_this.ViewModel.GetRows());

    this.Views.MailPagingSize.setValue(this.ViewModel.RowsFilter.Total.size);

    this.Views.MailPaging.define(this.ViewModel.RowsFilter.Total);
    this.Views.MailPaging.refresh();
};

TrafficDecodeMailViewController.prototype.ShowRowInfoPopup = function(Item, Node)
{
    var _this = this;

    var item = new TrafficDecodeMailModel(Item);
    var pcap = this.PcapsDic.find(function(Val){return item.pcap_id == Val.pcap_id});
    var data = [];


    item = webix.extend(item, {filename: pcap && pcap.file_name ? pcap.file_name : ""}, true);

    item.imf_from = Helper.ReplaceArrows(item.imf_from);
    item.imf_to = Helper.ReplaceArrows(item.imf_to);

    this.Views.RowInfoProperty.setValues(item);
    this.Views.RowInfoProperty.resize();
    this.RowInfoContext.show(Node, {pos: "left"});
};

TrafficDecodeMailViewController.prototype.DownloadEmailFile = function(Item)
{
    var _this = this;
    var item = new TrafficDecodeMailModel(Item);

    App.ServerManager.DownloadFileFromLocalUrl("/decoded_files/"+ item.pcap_id + "/" + item.available_file + "?type=Mail");
};

TrafficDecodeMailViewController.prototype.ShowEnvelopeInfoPopup = function(Item, Node)
{
    var _this = this;

    var item = new TrafficDecodeMailModel(Item);

    item.imf_from = Helper.ReplaceArrows(item.imf_from);
    item.imf_to = Helper.ReplaceArrows(item.imf_to);

    this.Views.EnvelopeInfo.setValues(item);
    this.Views.EnvelopeInfo.resize();
    this.EnvelopContext.show(Node, {pos: "left"});
};

TrafficDecodeMailViewController.prototype.DownloadAttachment = function(Att)
{
    var _this = this;

    _this.ViewModel.DownloadAttachment(Att);
};
function TrafficDecodeFtpViewController(Params)
{

    var _this = this;

    this.Id = "TrafficDecode";
    this.id = (Params && Params.id ? Params.id : "") + this.Id + "Ftp";

    this.ViewModel = new TrafficDecodeFtpManager();

    this.PagingSizeOptions = [
        {id:10, value:"10"},
        {id:50, value:"50"},
        {id:100, value:"100"},
        {id:200, value:"200"},
        {id:500, value:"500"},
        {id:1000, value:"1 000"},
        {id:10000, value:"10 000"}
    ];

    this.PcapsDic = [];

    this.RowInfoContext = webix.ui({
        view:"popup",
        width:640,
        height:640,
        body:{
            borderless: true,
            margin: 0,
            padding: 0,
            rows:[
                BlockHeaderTemplate({title:LangHelper.GetString(this.Id, "Ftp info"), icon:"file-export", borderless: true}),
                {
                    id: this.id + "RowInfoProperty",
                    view: "property",
                    css: "dpiui2-webix-property",
                    borderless: true,
                    // tooltip: true,
                    editable: false,
                    scroll: "y",
                    nameWidth: 256,
                    tooltip: function(Params)
                    {
                        return Params.value;
                    },
                    elements: [
                        {
                            label: LangHelper.GetString(this.Id, "TCP"),
                            type: "label"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Src port"),
                            type: "text",
                            id: "srcport"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Dst port"),
                            type: "text",
                            id: "dstport"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Ip"),
                            type: "label"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Ip proto"),
                            type: "text",
                            id: "ip_proto"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Ip version"),
                            type: "text",
                            id: "ip_version"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Src ip"),
                            type: "text",
                            id: "ip_src"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Dst ip"),
                            type: "text",
                            id: "ip_dst"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Eth"),
                            type: "label"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Eth type"),
                            type: "text",
                            id: "eth_type"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Src eth"),
                            type: "text",
                            id: "eth_src"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Dst eth"),
                            type: "text",
                            id: "eth_dst"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Ftp info"),
                            type: "label"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "File name"),
                            type: "text",
                            id: "file_name"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Ftp directory"),
                            type: "text",
                            id: "ftp_directory"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "File size (bytes)"),
                            type: "text",
                            id: "file_size"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Direction"),
                            type: "text",
                            id: "direction"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "File"),
                            type: "label"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "File Id"),
                            type: "text",
                            id: "pcap_id"
                        },
                        {
                            label: LangHelper.GetString(this.Id, "Response file"),
                            type: "text",
                            id: "available_file"
                        }
                    ]
                },
                {height: 16},
                {
                    cols:[
                        {},
                        {
                        },
                        {
                            view:"button",
                            type:"form",
                            label:LangHelper.GetString(this.Id, "Close"),
                            click:function () {
                                _this.RowInfoContext.hide();
                            }
                        }
                    ]
                }
            ]
        }
    });

    this.ViewConfig = [
        {
            id: this.id,
            rows: [
                BlockHeaderTemplate({title:LangHelper.GetString(this.Id, "Decoded Ftp items"), icon:"file-export"}),
                {
                    rows: [
                        {
                            view: "datatable",
                            id: this.id + "Table",
                            select: "row",
                            resizeColumn: true,
                            tooltip:true,
                            on: {
                                onItemClick: function (id,e,node)
                                {
                                    var rowData = this.getItem(id);
                                    if (id.column === 'edit')
                                    {

                                    }
                                    else if(id.column === 'trash')
                                    {

                                    }
                                },
                                onBeforeFilter:function()
                                {
                                    return false;
                                },
                                onAfterFilter:function()
                                {
                                    _this.Refresh();
                                },
                                onBeforeSort:function()
                                {
                                    _this.Refresh();
                                    return false;
                                }
                            },
                            columns: [
                                {
                                    id: "req_date",
                                    header: [LangHelper.GetString(this.Id, "Date"), {content: "dateRangeFilter"}],
                                    //format: webix.Date.dateToStr("%d.%m.%Y"),
                                    template:function(obj, common){
                                        if(obj.req_date)
                                        {
                                            var format =  webix.Date.dateToStr("%d.%m.%Y %H:%i:%s");
                                            return format(obj.req_date);
                                        }

                                        return "";
                                    },
                                    width: 144,
                                    sort: "date"
                                },
                                {
                                    id: "file_name",
                                    header: [LangHelper.GetString(this.Id, "File"), {content: "textFilter"}],
                                    template:function(obj, common, value, config)
                                    {
                                        if(obj.available_file)
                                        {
                                            return "<span class='clickable-span underlined-span'>" + obj.file_name + "</span>"
                                        }
                                        else
                                            return obj.file_name;
                                    },
                                    fillspace: 1,
                                    sort: "string"
                                },
                                {
                                    id: "direction",
                                    header: [
                                        LangHelper.GetString(this.Id, "Direction"),
                                        {
                                            content: "richSelectFilter",
                                            options: Helper.DictToArr(TrafficDecodeFtpDirectionsDic),
                                            inputConfig: {suggest: {fitMaster: false}}
                                        }],
                                    width: 108,
                                    sort: "string",
                                    template: function(Obj)
                                    {
                                        var res = TrafficDecodeFtpDirectionsDic.down.value;

                                        if(Obj && Obj.direction)
                                        {
                                            var d = TrafficDecodeFtpDirectionsDic[Obj.direction];

                                            if(d && d.value)
                                            {
                                                res = d.value;
                                            }
                                        }

                                        return res;
                                    }
                                },
                                {
                                    id: "file_size",
                                    header: [TooltipTemplate(LangHelper.GetString(this.Id, "File size (bytes)")), {content: "textFilter"}],
                                    width: 80,
                                    sort: "string"
                                },
                                {
                                    id: "ip_src",
                                    header: [LangHelper.GetString(this.Id, "Client"), {content: "textFilter"}],
                                    width: 104,
                                    sort: "string"
                                },
                                {
                                    id: "ip_dst",
                                    header: [LangHelper.GetString(this.Id, "Server"), {content: "textFilter"}],
                                    width: 104,
                                    sort: "string"
                                },
                                {
                                    id: "info",
                                    header: "",
                                    width: 32,
                                    template: CellQuestionBtnTemplate
                                }
                            ],
                            onClick: {
                                "fa-question-circle": function(ev, id)
                                {
                                    var item = this.getItem(id);
                                    var node = this.getItemNode(id);

                                    _this.ShowRowInfoPopup(item, node);

                                    return false;
                                },
                                "clickable-span": function(ev, id)
                                {
                                    var item = this.getItem(id);

                                    _this.OpenRowFile(item);

                                    return false;
                                }
                            },
                            data:[],
                            rules:{
                                "valid":function (value)
                                {
                                    return value;
                                }
                            }
                        },
                        {
                            view: "toolbar",
                            padding: 0,
                            margin: 0,
                            height:32,
                            elements: [
                                {
                                    view: "pager",
                                    gravity:3,
                                    id: this.id + "Paging",
                                    template: "{common.first()} {common.prev()} {common.pages()} {common.next()} {common.last()}",
                                    master:false,
                                    on: {
                                        onAfterPageChange:function(Page)
                                        {
                                            _this.Refresh(Page);
                                        }
                                    }
                                },
                                {
                                    view:"richselect",
                                    id: this.id + "PagingSize",
                                    label:LangHelper.GetString(this.Id, "On the page"),
                                    labelWidth:120,
                                    minWidth:220,
                                    value:100,
                                    options:this.PagingSizeOptions,//this.options,
                                    on: {
                                        onChange:function()
                                        {
                                            _this.Refresh();
                                        }
                                    }
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    ];

    this.View = null;

    this.Views = {
        Table: null,
        Paging: null,
        PagingSize: null,
        RowInfoProperty: null,
        RowInfoTable: null
    };

    this.Subscriptions = {};

    return this;
}

TrafficDecodeFtpViewController.prototype.Destroy = function ()
{
    var _this = this;

    Object.keys(this.Subscriptions).forEach(function (key)
    {
        _this.Subscriptions[key].unsubscribe();
    });
};

TrafficDecodeFtpViewController.prototype.ViewDidLoad = function (Params)
{
    var _this = this;
    this.BindAll();
};

TrafficDecodeFtpViewController.prototype.BindAll = function (Params)
{
    var _this = this;

    Object.keys(this.Views).forEach(function(Key){
        _this.Views[Key] = $$(_this.id + Key);
    });

    this.Subscriptions.SignalRowsUpdatedSubscription = this.ViewModel.SignalRowsUpdated.subscribe(function(Val){
        if(Val)
        {
            _this.UpdateRequestsTable();
        }
    });
};

TrafficDecodeFtpViewController.prototype.Refresh = function(Page, Params)
{
    var _this = this;

    var size = this.Views.PagingSize.getValue();
    var state = this.Views.Table.getState();
    var filter = state && state.filter ? state.filter : {};

    if(this.PcapIds)
    {
        filter = webix.extend(filter, {pcap_ids: this.PcapIds});
        this.ViewModel.RefreshRows({page:Page, filter:filter, size: size, sort: state && state.sort ? state.sort:null, callback:function ()
            {

            }});
    }
};

TrafficDecodeFtpViewController.prototype.SetPcapIds = function(Pcaps)
{
    var arr = [];
    if(Array.isArray(Pcaps))
    {
        arr = Pcaps;
    }
    else if(Pcaps){
        arr = [Pcaps];
    }
    else{
        arr = [];
    }

    this.PcapsDic = arr;
    var ids = [];
    arr.forEach(function(Val){
        ids.push(Val.pcap_id);
    });
    this.PcapIds = ids.join(",");
};

TrafficDecodeFtpViewController.prototype.UpdateRequestsTable = function (Params)
{
    var _this = this;

    this.Views.Table.clearAll();
    this.Views.Table.parse(_this.ViewModel.GetRows());

    this.Views.PagingSize.setValue(this.ViewModel.RowsFilter.Total.size);

    this.Views.Paging.define(this.ViewModel.RowsFilter.Total);
    this.Views.Paging.refresh();
};

TrafficDecodeFtpViewController.prototype.ShowRowInfoPopup = function(Item, Node)
{
    var _this = this;

    var item = new TrafficDecodeFtpModel(Item);
    var pcap = this.PcapsDic.find(function(Val){return item.pcap_id == Val.pcap_id});
    var data = [];


    item = webix.extend(item, {filename: pcap && pcap.file_name ? pcap.file_name : ""}, true);

    item.direction = TrafficDecodeFtpDirectionsDic[item.direction] ? TrafficDecodeFtpDirectionsDic[item.direction].value : TrafficDecodeFtpDirectionsDic.down.value;
    this.Views.RowInfoProperty.setValues(item);
    this.Views.RowInfoProperty.resize();
    this.RowInfoContext.show(Node, {pos: "left"});
};
TrafficDecodeFtpViewController.prototype.OpenRowFile = function(Item)
{
    var _this = this;
    var item = new TrafficDecodeFtpModel(Item);

    window.open("/api/decoded_files/"+ item.pcap_id + "/" + item.available_file + "?type=Ftp" , '_blank',"width=640,height=480")
};
function TrafficDecodeViewController(Params)
{

    var _this = this;

    this.Id = "TrafficDecode";

    this.ViewModel = new TrafficDecodeManager();

    // this.WebItemsViewController = new TrafficDecodeWebViewController(Params);
    // this.FtpItemsViewController = new TrafficDecodeFtpViewController(Params);
    // this.MailItemsViewController = new TrafficDecodeMailViewController(Params);
    // this.VoipItemsViewController = new TrafficDecodeVoipViewController(Params);

    this.Reports = {
        ReportsPanelTabsWeb: {
            id: this.Id + "ReportsPanelTabsWeb",
            title: LangHelper.GetString(_this.Id,"Web"),
            icon: 'fa-globe',
            viewController: new TrafficDecodeWebViewController(Params)
        },
        ReportsPanelTabsDns: {
            id: this.Id + "ReportsPanelTabsDns",
            title: LangHelper.GetString(_this.Id,"Dns"),
            icon: 'fa-file-certificate',
            viewController: new TrafficDecodeDnsViewController(Params)
        },
        ReportsPanelTabsMail: {
            id: this.Id + "ReportsPanelTabsMail",
            title: LangHelper.GetString(_this.Id,"Mail"),
            icon: 'fa-envelope',
            viewController: new TrafficDecodeMailViewController(Params)
        },
        ReportsPanelTabsVoip: {
            id: this.Id + "ReportsPanelTabsVoip",
            title: LangHelper.GetString(_this.Id,"Voip"),
            icon: 'fa-voicemail',
            viewController: new TrafficDecodeVoipViewController(Params)
        },
        ReportsPanelTabsFtp: {
            id: this.Id + "ReportsPanelTabsFtp",
            title: LangHelper.GetString(_this.Id,"Ftp"),
            icon: 'fa-file-export',
            viewController: new TrafficDecodeFtpViewController(Params)
        }
    };

    this.CreateTaskContext = null;
    this.CreateTaskContextFormConfig = {
        view:"popup",
        id:this.Id + "CreateTaskContext",
        width:480,
        height:280,
        modal:true,
        body:{
            borderless: true,
            margin: 0,
            padding: 0,
            rows:[
                {
                    id:this.Id + "CreateTaskContextForm",
                    view: "form",
                    margin: 0,
                    padding: 0,
                    borderless: true,
                    elements: [
                        BlockHeaderTemplate({title:LangHelper.GetString(this.Id, "Traffic decode"), icon:"tasks"}),
                        {
                            view: "text",
                            name: "title",
                            label: LangHelper.GetString(this.Id, "Name"),
                            labelPosition: "left",
                            labelWidth:120,
                            invalidMessage: LangHelper.GetString("Dics",  "Field is required"),
                            validate: function(Val)
                            {
                                return !!Val;
                            }
                        },
                        {
                            view:"textarea",
                            name:"description",
                            label:LangHelper.GetString(this.Id, "Description"),
                            labelPosition: "left",
                            labelWidth:120,
                            invalidMessage: LangHelper.GetString("Dics",  "Field is required"),
                            validate: function(Val)
                            {
                                return !!Val;
                            }
                        }
                    ]
                },
                {
                    view: "form",
                    margin: 0,
                    padding: 0,
                    borderless:true,
                    elements:[
                        {height: 16},
                        {
                            cols:[
                                {},
                                {
                                    view:"button",
                                    label:LangHelper.GetString(this.Id, "Cancel"),
                                    click:function ()
                                    {
                                        _this.CreateTaskContext.hide();
                                        _this.ClearTaskForm();
                                    }
                                },
                                {
                                    view:"button",
                                    type:"form",
                                    label:LangHelper.GetString(this.Id, "Save"),
                                    click:function ()
                                    {
                                        _this.SaveTask();
                                    }
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    };

    this.TaskDeleteContext = webix.ui({
        view:"popup",
        width:300,
        height:180,
        body:{
            view:"form",
            borderless: true,
            margin: 0,
            padding: 0,
            elements:[
                {
                    template:LangHelper.GetString(this.Id, "Are you sure you want to delete the task?")
                },
                {
                    height: 16
                },
                {
                    cols:[
                        {},
                        {
                            view:"button",
                            label:LangHelper.GetString(this.Id, "Cancel"),
                            click:function () {
                                _this.TaskDeleteContext.hide();
                            }
                        },
                        {
                            view:"button",
                            type:"form",
                            label:LangHelper.GetString(this.Id, "Delete"),
                            click:function () {
                                _this.TaskDeleteContext.hide();

                                var selected = _this.GetTasksTableSelectedData();

                                if(selected)
                                    _this.DeleteTask(selected.id);
                            }
                        }
                    ]
                }
            ]
        }
    });

    this.CreateFileContext = null;
    this.CreateFileContextFormConfig = {
        view:"popup",
        id:this.Id + "CreateFileContext",
        width:480,
        height:360,
        modal:true,
        body:{
            rows:[
                {
                    id:this.Id + "CreateFileContextForm",
                    view: "form",
                    margin: 0,
                    padding: 0,
                    borderless: true,
                    elements: [
                        BlockHeaderTemplate({title:LangHelper.GetString(this.Id, "Pcap-file"), icon:"file"}),
                        {
                            view: "uploader",
                            value: LangHelper.GetString(_this.Id,"Click to download or drag a file"),
                            id: this.Id+"CreateFileContextFormUploader",
                            accept: ".pcap, .cap, .pcapng",
                            css:"upload_area",
                            autosend:false,
                            height:64
                        },
                        {
                            id: this.Id + "CreateFileContextFormDetailsWrapper",
                            rows: [
                                {
                                    cols: [
                                        {
                                            view: "text",
                                            name: "title",
                                            label: LangHelper.GetString(this.Id, "Name"),
                                            labelPosition: "left",
                                            labelWidth:120,
                                            invalidMessage: LangHelper.GetString("Dics",  "Field is required"),
                                            validate: function(Val)
                                            {
                                                return !!Val;
                                            }
                                        }
                                    ]
                                },
                                {
                                    cols: [
                                        {
                                            view:"textarea",
                                            name:"description",
                                            label:LangHelper.GetString(this.Id, "Description"),
                                            labelPosition: "left",
                                            labelWidth:120,
                                            invalidMessage: LangHelper.GetString("Dics",  "Field is required"),
                                            validate: function(Val)
                                            {
                                                return !!Val;
                                            }
                                        }
                                    ]
                                },
                                {
                                    cols: [
                                        {
                                            view: "multiselect",
                                            name: "decode_types",
                                            label: LangHelper.GetString(this.Id, "Decode types"),
                                            labelPosition: "left",
                                            options: Helper.DictToArr(TrafficDecodeTypesDic),
                                            labelWidth:120,
                                            invalidMessage: LangHelper.GetString("Dics",  "Field is required"),
                                            validate: function(Val)
                                            {
                                                return !!Val;
                                            }
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    view: "form",
                    margin: 0,
                    padding: 0,
                    borderless:true,
                    elements:[
                        {height: 16},
                        {
                            cols:[
                                {},
                                {
                                    view:"button",
                                    label:LangHelper.GetString(this.Id, "Cancel"),
                                    click:function ()
                                    {
                                        _this.CreateFileContext.hide();
                                        _this.ClearFileForm();
                                    }
                                },
                                {
                                    view:"button",
                                    type:"form",
                                    label:LangHelper.GetString(this.Id, "Save"),
                                    click:function ()
                                    {
                                        setTimeout(function(){
                                            _this.SaveFile();
                                        }, 10);
                                    }
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    };

    this.FileDeleteContext = webix.ui({
        view:"popup",
        width:300,
        height:180,
        body:{
            view:"form",
            borderless: true,
            margin: 0,
            padding: 0,
            elements:[
                {
                    template:LangHelper.GetString(this.Id, "Are you sure you want to delete the file?")
                },
                {height: 16},
                {
                    cols:[
                        {},
                        {
                            view:"button",
                            label:LangHelper.GetString(this.Id, "Cancel"),
                            click:function () {
                                _this.FileDeleteContext.hide();
                            }
                        },
                        {
                            view:"button",
                            type:"form",
                            label:LangHelper.GetString(this.Id, "Delete"),
                            click:function () {
                                _this.FileDeleteContext.hide();

                                var selected = _this.GetFilesTableSelectedData();

                                if(selected)
                                    _this.DeleteFile(selected.id);
                            }
                        }
                    ]
                }
            ]
        }
    });

    this.FileResetQueueContext = webix.ui({
        view:"popup",
        width:300,
        height:180,
        modal: true,
        body:{
            view:"form",
            borderless: true,
            margin: 0,
            padding: 0,
            elements:[
                {
                    template:LangHelper.GetString(this.Id, "Are you sure you want to reset decode queue for selected pcaps?")
                },
                {height: 16},
                {
                    cols:[
                        {},
                        {
                            view:"button",
                            label:LangHelper.GetString(this.Id, "Cancel"),
                            click:function () {
                                _this.FileResetQueueContext.hide();
                            }
                        },
                        {
                            view:"button",
                            type:"form",
                            width: 120,
                            label:LangHelper.GetString(this.Id, "Reset_"),
                            click:function () {
                                _this.FileResetQueueContext.hide();

                                _this.ResetQueueForSelectedPcaps();
                            }
                        }
                    ]
                }
            ]
        }
    });

    this.OnDecodeTypesChangedContext = webix.ui({
        view:"popup",
        width:480,
        height:180,
        modal: true,
        left: (window.innerWidth / 2 ) - 240,
        top: (window.innerHeight / 2) - 90,
        body:{
            view:"form",
            borderless:true,
            elements:[
                {
                    borderless:true,
                    template:LangHelper.GetString(_this.Id,"File saved") + "!"
                },
                {
                    borderless:true,
                    template:LangHelper.GetString(_this.Id,"Do you want to reset decode queue for this pcap?")
                },
                {
                    cols:[
                        {},
                        {
                            view:"button",
                            label:LangHelper.GetString(this.Id,"Cancel"),
                            click:function () {
                                _this.OnDecodeTypesChangedContext.hide();
                            }
                        },
                        {
                            view:"button",
                            type:"form",
                            label:LangHelper.GetString(this.Id,"Reset_"),
                            click:function () {
                                _this.OnDecodeTypesChangedContext.hide();

                                _this.ResetQueueForSelectedPcaps();
                            }
                        }
                    ]
                }
            ]
        }
    });

    this.PagingSizeOptions = [
        {id:10, value:"10"},
        {id:50, value:"50"},
        {id:100, value:"100"},
        {id:200, value:"200"},
        {id:500, value:"500"},
        {id:1000, value:"1 000"},
        {id:10000, value:"10 000"}
    ];

    this.ViewConfig = [
        {
            cols:[
                SormSideBarView({id: this.Id}),
                {
                    type: "space",
                    rows: [
                        {
                            type: "clear",
                            borderless: true,
                            rows: [
                                PageHeaderTemplate({root_title: LangHelper.GetString("AppView", "Lawful interception"), title: LangHelper.GetString("AppView", "Traffic decode")}),
                                {
                                    view:"accordion",
                                    multi:"mixed",
                                    cols:[
                                        {
                                            gravity:1,
                                            header:LangHelper.GetString(this.Id, "Tasks"),
                                            body:{
                                                rows: [
                                                    {
                                                        view: "toolbar",
                                                        height: 32,
                                                        padding: 0,
                                                        margin: 0,
                                                        elements: [
                                                            {
                                                                view: "button",
                                                                id:this.Id + "AddTaskBtn",
                                                                type: "icon",
                                                                icon: "plus",
                                                                align: "right",
                                                                width: 32,
                                                                css:"toolbar_round_btn",
                                                                tooltip:LangHelper.GetString(this.Id, "Add task"),
                                                                click: function (id, e, node)
                                                                {
                                                                    _this.AddTaskToTasksTable();
                                                                }
                                                            },
                                                            {},
                                                            {
                                                                view: "button",
                                                                id:this.Id+"TasksRefreshBtn",
                                                                type: "icon",
                                                                icon: "refresh",
                                                                css: "toolbar_round_btn",
                                                                width: 32,
                                                                tooltip: LangHelper.GetString(this.Id, "Refresh"),
                                                                click: function (id, e, node)
                                                                {
                                                                    _this.RefreshTasksTable(0,1);
                                                                }
                                                            }
                                                        ]
                                                    },
                                                    BlockHeaderTemplate({title:LangHelper.GetString(this.Id, "Tasks"), icon:"tasks"}),
                                                    {
                                                        view: "datatable",
                                                        id: this.Id + "TasksTable",
                                                        select: "row",
                                                        editable: true,
                                                        editaction:"custom",
                                                        resizeColumn: true,
                                                        tooltip:true,
                                                        form:this.Id + "CreateTaskContextForm",
                                                        on: {
                                                            onItemClick: function (id,e,node)
                                                            {
                                                                var rowData = this.getItem(id);
                                                                if (id.column === 'edit')
                                                                {
                                                                    _this.ShowTasksEditForm(rowData, this.getItemNode(id), "right");
                                                                }
                                                                else if(id.column === 'trash')
                                                                {
                                                                    var __this = this;

                                                                    var data = _this.Views.TasksTable.getItem(id);

                                                                    if(!data.task_id)
                                                                    {
                                                                        _this.DeleteTask(id);
                                                                        return;
                                                                    }

                                                                    setTimeout(function () {
                                                                        var node = __this.getItemNode(id);
                                                                        _this.TaskDeleteContext.show(node, {pos:"bottom"});
                                                                    }, 10);
                                                                }
                                                            },
                                                            onBeforeFilter:function()
                                                            {
                                                                return false;
                                                            },
                                                            onAfterFilter:function()
                                                            {
                                                                _this.RefreshTasksTable();
                                                            },
                                                            onBeforeSort:function()
                                                            {
                                                                _this.RefreshTasksTable();
                                                                return false;
                                                            },
                                                            onAfterSelect:function ()
                                                            {
                                                                _this.OnTasksTableRowSelect();
                                                            },
                                                            onAfterUnSelect:function ()
                                                            {
                                                                _this.OnTasksTableRowUnSelect();
                                                            }
                                                        },
                                                        columns: [
                                                            {
                                                                id: "edit",
                                                                header: "",
                                                                width: 32,
                                                                template: CellEditBtnTemplate,
                                                                headermenu: false
                                                            },
                                                            {
                                                                id: "task_id",
                                                                header: [LangHelper.GetString(this.Id, "ID"), {content: "textFilter"}],
                                                                width: 64,
                                                                sort: "number"
                                                            },
                                                            {
                                                                id: "title",
                                                                header: [LangHelper.GetString(this.Id, "Task"), {content: "textFilter"}],
                                                                template:function(obj, common, value, config)
                                                                {
                                                                    return obj.title?obj.title:LangHelper.GetString(_this.Id, "Task")+" "+obj.task_id;
                                                                },
                                                                fillspace: 2,
                                                                sort: "string"
                                                            },
                                                            {
                                                                id: "user_id",
                                                                header: [LangHelper.GetString(this.Id, "User"), {
                                                                    content: "multiSelectFilter",
                                                                    options: App.DicsManager.GetDicAsArray(DicsEnum.UsersDic),
                                                                    inputConfig: {suggest: {fitMaster: false}}
                                                                }],
                                                                fillspace: true,
                                                                template: function (obj)
                                                                {
                                                                    if(App.DicsManager.GetDic("UsersDic")[obj.user_id])
                                                                        return App.DicsManager.GetDic("UsersDic")[obj.user_id].value;
                                                                    else
                                                                        return "";
                                                                },
                                                                sort: "string"
                                                            },
                                                            {
                                                                id: "trash",
                                                                header: "",
                                                                width: 32,
                                                                template: CellTrashBtnTemplate,
                                                                headermenu: false
                                                            }
                                                        ],
                                                        data:[],
                                                        rules:{
                                                            "valid":function (value)
                                                            {
                                                                return value;
                                                            }
                                                        }
                                                    },
                                                    {
                                                        view: "toolbar",
                                                        padding: 0,
                                                        margin: 0,
                                                        height:32,
                                                        elements: [
                                                            {
                                                                view: "pager",
                                                                gravity:3,
                                                                id: this.Id + "TasksTable" + "Paging",
                                                                template: "{common.first()} {common.prev()} {common.pages()} {common.next()} {common.last()}",
                                                                master:false,
                                                                on: {
                                                                    onAfterPageChange:function(Page)
                                                                    {
                                                                        _this.RefreshTasksTable(Page);
                                                                    }
                                                                }
                                                            },
                                                            {
                                                                view:"richselect",
                                                                id: this.Id + "TasksTable" + "Paging" + "Size",
                                                                label:LangHelper.GetString(this.Id, "On the page"),
                                                                labelWidth:120,
                                                                minWidth:220,
                                                                value:100,
                                                                options:this.PagingSizeOptions,
                                                                on: {
                                                                    onChange:function()
                                                                    {
                                                                        _this.RefreshTasksTable();
                                                                    }
                                                                }
                                                            }
                                                        ]
                                                    }
                                                ]
                                            }
                                        },
                                        {view: "resizer"},
                                        {
                                            gravity: 2,
                                            header: LangHelper.GetString(this.Id, "Files"),
                                            id: this.Id + "FilesWrapper",
                                            body: {
                                                rows:[
                                                    {
                                                        view: "toolbar",
                                                        height: 32,
                                                        padding: 0,
                                                        margin: 0,
                                                        elements: [
                                                            {
                                                                view: "button",
                                                                id:this.Id + "AddFileBtn",
                                                                type: "icon",
                                                                icon: "plus",
                                                                align: "right",
                                                                width: 32,
                                                                css:"toolbar_round_btn",
                                                                tooltip:LangHelper.GetString(this.Id, "Add file"),
                                                                click: function (id, e, node)
                                                                {
                                                                    _this.AddFileToFilesTable();
                                                                }
                                                            },
                                                            {width: 16},
                                                            {
                                                                view: "button",
                                                                id:this.Id + "ResetQueueBtn",
                                                                type: "icon",
                                                                icon: "bolt",
                                                                align: "right",
                                                                width: 32,
                                                                css: "toolbar_round_btn",
                                                                tooltip: LangHelper.GetString(this.Id, "Reset decode queue for selected files"),
                                                                click: function(id, e, node)
                                                                {
                                                                    var _node = this.getNode(id);
                                                                    setTimeout(function () {
                                                                        _this.FileResetQueueContext.show(_node, {pos:"bottom"});
                                                                    }, 10);
                                                                }
                                                            },
                                                            {},
                                                            {
                                                                view: "button",
                                                                id:this.Id+"FilesRefreshBtn",
                                                                type: "icon",
                                                                icon: "refresh",
                                                                css: "toolbar_round_btn",
                                                                width: 32,
                                                                tooltip: LangHelper.GetString(this.Id, "Refresh"),
                                                                click: function (id, e, node)
                                                                {
                                                                    _this.RefreshFiles(0,1);
                                                                }
                                                            }
                                                        ]
                                                    },
                                                    BlockHeaderTemplate({title:LangHelper.GetString(this.Id, "Files"), icon:"file"}),
                                                    {
                                                        view: "datatable",
                                                        id: this.Id + "FilesTable",
                                                        select: "row",
                                                        editable: true,
                                                        editaction:"custom",
                                                        resizeColumn: true,
                                                        tooltip:true,
                                                        form:this.Id + "CreateFileContextForm",
                                                        on: {
                                                            onItemClick: function (id,e,node)
                                                            {
                                                                var rowData = this.getItem(id);

                                                                if (id.column === 'edit')
                                                                {
                                                                    _this.ShowFileEditForm(rowData, this.getItemNode(id), "right");
                                                                }
                                                                else if(id.column === 'trash')
                                                                {
                                                                    var __this = this;

                                                                    var data = _this.Views.FilesTable.getItem(id);

                                                                    if(!data.pcap_id)
                                                                    {
                                                                        _this.DeleteFile(id);
                                                                        return;
                                                                    }

                                                                    setTimeout(function () {
                                                                        var node = __this.getItemNode(id);
                                                                        _this.FileDeleteContext.show(node, {pos:"bottom"});
                                                                    }, 10);
                                                                }
                                                                else if(id.column === 'download')
                                                                {
                                                                    var __this = this;

                                                                    this.select(id); //reset if multiple rows selected

                                                                    _this.DownloadPcapFile(rowData);
                                                                }
                                                            },
                                                            onBeforeFilter:function()
                                                            {
                                                                return false;
                                                            },
                                                            onAfterFilter:function()
                                                            {
                                                                _this.RefreshFiles();
                                                            },
                                                            onBeforeSort:function()
                                                            {
                                                                _this.RefreshFiles();
                                                                return false;
                                                            },
                                                            onAfterSelect:function ()
                                                            {
                                                                _this.OnFilesTableRowSelect();
                                                            },
                                                            onAfterUnSelect:function ()
                                                            {
                                                                _this.OnFilesTableRowUnSelect();
                                                            }
                                                        },
                                                        columns: [
                                                            {
                                                                id: "edit",
                                                                header: "",
                                                                width: 32,
                                                                template: CellEditBtnTemplate,
                                                                headermenu: false
                                                            },
                                                            {
                                                                id: "download",
                                                                header: "",
                                                                width: 32,
                                                                template: CellDownloadBtnTemplate,
                                                                headermenu: false
                                                            },
                                                            {
                                                                id: "pcap_id",
                                                                header: [LangHelper.GetString(this.Id, "ID"), {content: "textFilter"}],
                                                                width: 64,
                                                                sort: "number"
                                                            },
                                                            {
                                                                id: "title",
                                                                header: [LangHelper.GetString(this.Id, "Name"), {content: "textFilter"}],
                                                                template:function(obj, common, value, config)
                                                                {
                                                                    return obj.title?obj.title:(LangHelper.GetString(_this.Id, "File")+" "+obj.task_id);
                                                                },
                                                                fillspace: 2,
                                                                sort: "string"
                                                            },
                                                            {
                                                                id: "status",
                                                                header: [LangHelper.GetString(this.Id, LangHelper.GetString(this.Id, "Status")), {
                                                                    content: "richSelectFilter",
                                                                    options: Helper.DictToArr(SormTrafficDecodeFilesStatuses),
                                                                    inputConfig: {suggest: {fitMaster: false}}
                                                                }],
                                                                tooltip: function(obj)
                                                                {
                                                                  if(obj.status !== SormTrafficDecodeFilesStatuses.error.id)
                                                                  {
                                                                      var val = SormTrafficDecodeFilesStatuses[obj.status];
                                                                      var statusStr = "";

                                                                      if(val)
                                                                      {
                                                                          statusStr = "<span class=\"traffic_decode_state traffic_decode_state_" + val.id + "\">" + '<span class="fa fa-square"></span> ' + val.value + "</span>";
                                                                      }

                                                                      return statusStr;
                                                                  }
                                                                  else{
                                                                      return obj.error_description;
                                                                  }
                                                                },
                                                                template: function (obj, common)
                                                                {
                                                                    var val = SormTrafficDecodeFilesStatuses[obj.status];
                                                                    var statusStr = "";

                                                                    if(val)
                                                                    {
                                                                        if(val.id == SormTrafficDecodeFilesStatuses.decoding.id
                                                                            || val.id == SormTrafficDecodeFilesStatuses.preparing.id
                                                                            || val.id == SormTrafficDecodeFilesStatuses.downloading.id
                                                                        )
                                                                        {
                                                                            statusStr += '<div class="spinner"><div class="bounce1"></div><div class="bounce2"></div> <div class="bounce3"></div> </div>';
                                                                        }
                                                                        else{
                                                                            statusStr += "<span class=\"traffic_decode_state traffic_decode_state_" + val.id + "\"><span class=\"fa fa-square\"></span>";
                                                                        }
                                                                        statusStr +=  " " + val.value;
                                                                    }

                                                                    return statusStr;
                                                                },
                                                                fillspace: true,
                                                                sort: "string"
                                                            },
                                                            {
                                                                id: "decode_start_at",
                                                                header: [LangHelper.GetString(this.Id, "Decode started"), {content: "dateRangeFilter"}],
                                                                //format: webix.Date.dateToStr("%d.%m.%Y"),
                                                                template:function(obj, common){
                                                                    if(obj.decode_start_at)
                                                                    {
                                                                        var format =  webix.Date.dateToStr("%d.%m.%Y %H:%i");
                                                                        return format(obj.decode_start_at);
                                                                    }

                                                                    return "";
                                                                },
                                                                fillspace: 2,
                                                                sort: "date"
                                                            },
                                                            {
                                                                id: "decode_finish_at",
                                                                header: [LangHelper.GetString(this.Id, "Decode finished"), {content: "dateRangeFilter"}],
                                                                //format: webix.Date.dateToStr("%d.%m.%Y"),
                                                                template:function(obj, common){
                                                                    if(obj.decode_finish_at)
                                                                    {
                                                                        var format =  webix.Date.dateToStr("%d.%m.%Y %H:%i");
                                                                        return format(obj.decode_finish_at);
                                                                    }

                                                                    return "";
                                                                },
                                                                fillspace: 2,
                                                                sort: "date"
                                                            },
                                                            {
                                                                id: "trash",
                                                                header: "",
                                                                width: 32,
                                                                template: CellTrashBtnTemplate,
                                                                headermenu: false
                                                            }
                                                        ],
                                                        data:[],
                                                        rules:{
                                                            "valid":function (value)
                                                            {
                                                                return value;
                                                            }
                                                        }
                                                    },
                                                    {
                                                        view: "toolbar",
                                                        padding: 0,
                                                        margin: 0,
                                                        height:32,
                                                        elements: [
                                                            {
                                                                view: "pager",
                                                                gravity:3,
                                                                id: this.Id + "FilesTable" + "Paging",
                                                                template: "{common.first()} {common.prev()} {common.pages()} {common.next()} {common.last()}",
                                                                master:false,
                                                                on: {
                                                                    onAfterPageChange:function(Page)
                                                                    {
                                                                        _this.RefreshFiles(Page);
                                                                    }
                                                                }
                                                            },
                                                            {
                                                                view:"richselect",
                                                                id: this.Id + "FilesTable" + "Paging" + "Size",
                                                                label:LangHelper.GetString(this.Id, "On the page"),
                                                                labelWidth:120,
                                                                minWidth:220,
                                                                value:100,
                                                                options:this.PagingSizeOptions,
                                                                on: {
                                                                    onChange:function()
                                                                    {
                                                                        _this.RefreshFiles();
                                                                    }
                                                                }
                                                            }
                                                        ]
                                                    }
                                                ]
                                            }
                                        },
                                        {
                                            view: "resizer"
                                        },
                                        {
                                            gravity:3,
                                            header:LangHelper.GetString(this.Id, "Results"),
                                            body:{
                                                id:this.Id + "ReportsPanel",
                                                rows:[
                                                    {
                                                        view: "toolbar",
                                                        height:webix.skin.$name == "sormui_vulkan" ? webix.skin.$active.tabbarSmallHeight : webix.skin.material.tabbarHeight,
                                                        padding: 0,
                                                        margin: 0,
                                                        elements: [
                                                            {
                                                                view: "tabbar",
                                                                id: this.Id + "ReportsPanelTabs",
                                                                // tabMoreWidth: 0,
                                                                value: this.Reports.ReportsPanelTabsWeb.id,
                                                                // multiview: true,
                                                                options: function(){
                                                                    var arr = [];

                                                                    Object.values(_this.Reports).forEach(function(Val){
                                                                        arr.push({
                                                                            id: Val.id,
                                                                            value: _this.TabbarOptionTemplate(Val)
                                                                        });
                                                                    });

                                                                    return arr;
                                                                }() ,
                                                                on: {
                                                                    onChange: function(Id)
                                                                    {
                                                                        $$(Id).show();

                                                                        setTimeout(function(){
                                                                            _this.RefreshReport(0)
                                                                        },10);
                                                                    }
                                                                }
                                                            },
                                                            {
                                                                view: "button",
                                                                id:this.Id+"RefreshReportData",
                                                                type: "icon",
                                                                icon: "refresh",
                                                                css: "toolbar_round_btn",
                                                                width: 32,
                                                                tooltip: LangHelper.GetString(this.Id, "Refresh"),
                                                                click: function (id, e, node)
                                                                {
                                                                    _this.RefreshReport(0);
                                                                }
                                                            }
                                                        ]
                                                    },
                                                    {
                                                        view: "multiview",
                                                        cells:function(){
                                                            var arr = [];
                                                            Object.values(_this.Reports).forEach(function(Val){
                                                               arr.push({
                                                                   id: Val.id,
                                                                   rows: Val.viewController.ViewConfig
                                                               });
                                                            });

                                                            return arr;
                                                        }()
                                                    }
                                                ]
                                            }
                                        },
                                        {
                                            gravity:0
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    ];

    this.Views = {
        AddTaskBtn:null,

        TasksTable:null,
        TasksTablePaging:null,
        TasksTablePagingSize:null,
        CreateTaskContextForm:null,
        AddFileBtn: null,
        FilesRefreshBtn: null,
        FilesTable: null,
        FilesTablePaging: null,
        FilesTablePagingSize: null,
        FilesWrapper: null,
        CreateFileContextForm: null,
        CreateFileContextFormUploader: null,
        CreateFileContextFormDetailsWrapper: null,
        ReportsPanel: null,
        ReportsPanelTabs: null,
        ResetQueueBtn: null
    };

    this.Subscriptions = {};

    this.Connections = {};

    this.TasksTableRowsSelectedCount = new Rx.BehaviorSubject(0);
    this.FilesTableRowsSelectedCount = new Rx.BehaviorSubject(0);

    this.MonitorTimer = null;

    this.TaskDataMonitorTimer = null;

    this.FileToSave = null;

    return this;
}

TrafficDecodeViewController.prototype.Destroy = function ()
{
    var _this = this;

    // clearTimeout(this.MonitorTimer);
    // clearTimeout(this.TaskDataMonitorTimer);

    // this.SaveAllTablesState();

    Object.keys(this.Subscriptions).forEach(function (key)
    {
        _this.Subscriptions[key].unsubscribe();
    });

    Object.keys(this.Reports).forEach(function(Key){
        _this.Reports[Key].viewController.Destroy();
    });

    App.WebSocketManager.Disconnect(this.Id);

    this.ViewModel.Destroy();
    delete this.ViewModel;
};

TrafficDecodeViewController.prototype.ViewDidLoad = function (Params)
{
    var _this = this;

    webix.ready(function () {
        $$(_this.Id + "LeftSideBar").select(_this.Id);
    });

    Object.keys(this.Reports).forEach(function(Key){
        _this.Reports[Key].viewController.ViewDidLoad();
    });

    this.BindAll();

    this.RestoreAllTablesState();
};

TrafficDecodeViewController.prototype.BindAll = function (Params)
{
    var _this = this;

    this.Views.AddTaskBtn = $$(this.Id + "AddTaskBtn");
    this.Views.AddSubTaskBtn = $$(this.Id + "AddSubTaskBtn");
    //
    this.Views.TasksTable = $$(this.Id + "TasksTable");
    this.Views.TasksTablePaging = $$(this.Id + "TasksTable" + "Paging");
    this.Views.TasksTablePagingSize = $$(this.Id + "TasksTable" + "Paging" + "Size");

    this.Views.ReportsPanel = $$(this.Id + "ReportsPanel");
    this.Views.ReportsPanelTabs = $$(this.Id + "ReportsPanelTabs");
    this.CreateTaskContext = webix.ui(this.CreateTaskContextFormConfig);
    this.Views.CreateTaskContextForm = $$(this.Id+"CreateTaskContextForm");


    this.Views.AddFileBtn = $$(this.Id + "AddFileBtn");
    this.Views.FilesRefreshBtn = $$(this.Id + "FilesRefreshBtn");
    //
    this.Views.FilesTable = $$(this.Id + "FilesTable");
    this.Views.FilesTablePaging = $$(this.Id + "FilesTablePaging");
    this.Views.FilesTablePagingSize = $$(this.Id + "FilesTablePagingSize");
    this.Views.FilesWrapper = $$(this.Id + "FilesWrapper");

    this.CreateFileContext = webix.ui(this.CreateFileContextFormConfig);
    this.Views.CreateFileContextForm = $$(this.Id+"CreateFileContextForm");
    this.Views.CreateFileContextFormUploader = $$(this.Id+"CreateFileContextFormUploader");
    this.Views.CreateFileContextFormDetailsWrapper = $$(this.Id+"CreateFileContextFormDetailsWrapper");

    this.Views.ResetQueueBtn = $$(this.Id+"ResetQueueBtn");

    this.Views.CreateFileContextFormUploader.attachEvent("onAfterFileAdd", function(FileObj)
    {
        if(FileObj && (FileObj.type == "pcap" || FileObj.type == "cap" || FileObj.type == "pcapng" ))
        {
            _this.FileToSave = FileObj;
            _this.Views.CreateFileContextFormDetailsWrapper.enable();

            _this.Views.CreateFileContextForm.setValues(new TrafficDecodeFileFormModel({
                title: FileObj.name,
                file_name: FileObj.name,
                description: FileObj.name
            }));
        }
        else{
            _this.Views.CreateFileContextFormDetailsWrapper.disable();
        }


    });

    this.Subscriptions.SignalDicUpdatedSubscription = App.DicsManager.Signal[DicsEnum.UsersDic].subscribe(function (Updated)
    {
        if (Updated)
        {
            _this.RefreshTasksTable(0,1);
        }
    });

    this.Subscriptions.SignalTasksUpdatedSubscription = this.ViewModel.SignalTasksUpdated.subscribe(function (Updated)
    {
        if (Updated)
        {
            _this.UpdateTasksTable();
        }
    });

    this.Subscriptions.SignalFilesUpdatedSubscription = this.ViewModel.SignalFilesUpdated.subscribe(function (Updated)
    {
        if (Updated)
        {
            _this.UpdateFilesTable();
        }
    });

    this.Subscriptions.TasksTableRowsSelectedCountSubscription = this.TasksTableRowsSelectedCount.subscribe(function(Val){
       _this.OnTasksTableSelectionChanged();

    });

    this.Subscriptions.FilesTableRowsSelectedCountSubscription = this.FilesTableRowsSelectedCount.subscribe(function(Val){
        if(Val)
        {
            _this.Views.ReportsPanel.enable();

            var selected = _this.GetFilesTableSelectedData();
            var enable = true;
            if(Array.isArray(selected))
            {
                selected.forEach(function(Sel){
                    if(!Sel.pcap_id || Sel.status == SormTrafficDecodeFilesStatuses.new.id)
                    {
                        enable = false;
                    }
                })
            }
            else if(!selected.pcap_id || selected.status == SormTrafficDecodeFilesStatuses.new.id)
            {
                enable = false;
            }

            if(enable)
            {
                _this.Views.ResetQueueBtn.enable();
            }
        }
        else{
            _this.Views.ReportsPanel.disable();
            _this.Views.ResetQueueBtn.disable();
        }
        _this.RefreshReport(0, true);
    });

    Object.keys(this.Reports).forEach(function(Key){
        _this.Subscriptions["Badge" + Key + "Subscription"] = _this.Reports[Key].viewController.ViewModel.SignalRowsUpdated.subscribe(function(Val){
            if(Val)
            {
                var index = _this.Views.ReportsPanelTabs.config.options.findIndex(function(Obj){return Obj.id == _this.Reports[Key].id});
                if(index > -1 && _this.Views.ReportsPanelTabs.config.options[index])
                {
                    _this.Views.ReportsPanelTabs.config.options[index].value = _this.TabbarOptionTemplate(_this.Reports[Key]);
                    _this.Views.ReportsPanelTabs.refresh();
                }
            }
        });
    });

    App.WebSocketManager.Connect(this.Id);

    App.WebSocketManager.Connections[this.Id].on("pcap_decode_status", function(data){
        var item = JSON.parse(data);
        _this.Views.FilesTable.eachRow(function(Row){
            var rowItem = _this.Views.FilesTable.getItem(Row);
            if(rowItem.pcap_id == item.pcap_id)
            {
                this.updateItem(Row, item);
                _this.OnFilesTableRowSelect();
                _this.RefreshReport(0, true);
            }
        });
    });

    this.CheckHardwareAvailability();
};

TrafficDecodeViewController.prototype.RefreshTasksTable = function(Page, Sync)
{
    var _this = this;

    var state = this.Views.TasksTable.getState();

    var size = this.Views.TasksTablePagingSize.getValue();

    this.ViewModel.RefershTasks({page:Page, sync:Sync, filter:state.filter, size: size, sort:state.sort ? state.sort:null, callback:function ()
        {

        }});
};

TrafficDecodeViewController.prototype.RestoreAllTablesState = function()
{
    // this.RestoreTableState(this.Views.ConnectionsMailTabTable);
    // this.RestoreTableState(this.Views.ConnectionsFtpTabTable);
    // this.RestoreTableState(this.Views.ConnectionsHttpTabTable);
    // this.RestoreTableState(this.Views.ConnectionsImTabTable);
    // this.RestoreTableState(this.Views.ConnectionsPstnTabTable);
    // this.RestoreTableState(this.Views.ConnectionsRfTabTable);
    // this.RestoreTableState(this.Views.ConnectionsTermTabTable);
    // this.RestoreTableState(this.Views.ConnectionsVoipTabTable);
    // this.RestoreTableState(this.Views.ConnectionsAaaTabTable);
};

TrafficDecodeViewController.prototype.RestoreTableState = function(Table)
{

    var tableState = Table.getState();
    var config = Table.config;
    var tableId = Table.config.id;
    if(config.version)
        tableId += "_" + config.version;

    var columnsRestoredState = webix.storage.local.get(tableId+"Columns");

    if(columnsRestoredState)
    {
        tableState.hidden = columnsRestoredState.hidden;
        tableState.ids = columnsRestoredState.ids;
        tableState.order = columnsRestoredState.order;

        Table.setState(tableState);
    }
};

TrafficDecodeViewController.prototype.UpdateTasksTable = function (Params)
{
    var _this = this;

    this.Views.TasksTable.getColumnConfig("user_id").header[1].options = App.DicsManager.GetDicAsArray(DicsEnum.UsersDic);

    this.Views.TasksTable.clearAll();
    this.Views.TasksTable.parse(_this.ViewModel.GetTasks());

    this.Views.TasksTablePagingSize.setValue(this.ViewModel.TasksFilter.Total.size);

    this.Views.TasksTablePaging.define(this.ViewModel.TasksFilter.Total);
    this.Views.TasksTablePaging.refresh();
};

TrafficDecodeViewController.prototype.AddTaskToTasksTable = function()
{
    var _this = this;

    var model = new TrafficDecodeTaskModel();

    _this.Views.TasksTable.add(model,0);

    _this.Views.TasksTable.select(model.id, false);
    _this.Views.TasksTable.showItem(model.id);

    _this.ShowTasksEditForm(model, _this.Views.TasksTable.getItemNode(model.id), "right");
};

TrafficDecodeViewController.prototype.OnTasksTableRowSelect = function ()
{
    var data = this.GetTasksTableSelectedData();

    this.TasksTableRowsSelectedCount.next(data?(Array.isArray(data)?data.length:1):0);
};

TrafficDecodeViewController.prototype.OnTasksTableRowUnSelect = function ()
{
    var data = this.GetTasksTableSelectedData();
    this.TasksTableRowsSelectedCount.next(data?(Array.isArray(data)?data.length:1):0);
};

TrafficDecodeViewController.prototype.GetTasksTableSelectedData = function ()
{
    return this.Views.TasksTable.getSelectedItem();
};

TrafficDecodeViewController.prototype.ShowTasksEditForm = function (Data, Node, Pos)
{
    this.ClearTaskForm();

    if(Data)
    {
        this.Views.CreateTaskContextForm.setValues(new TrafficDecodeTaskFormModel(Data));
    }

    this.CreateTaskContext.show(Node, {pos:Pos});
};

TrafficDecodeViewController.prototype.ClearTaskForm = function ()
{
    var _this = this;

    this.Views.CreateTaskContextForm.elements.title.setValue("");
    this.Views.CreateTaskContextForm.elements.description.setValue("");
    this.Views.CreateTaskContextForm.clearValidation();
};

TrafficDecodeViewController.prototype.SaveTask = function ()
{
    var _this = this;

    var taskData = this.GetTasksTableSelectedData();

    if(this.Views.CreateTaskContextForm.validate())
    {
        taskData.valid = true;

        this.CreateTaskContext.hide();

        var formData = new TrafficDecodeTaskModel(new TrafficDecodeTaskFormModel(this.Views.CreateTaskContextForm.getValues()));

        this.ViewModel.SaveTask({task:taskData, task_form:formData, callback:function (R)
            {
                if(R.success && R.data && R.data.task)
                {
                    _this.Views.TasksTable.updateItem(taskData.id,  new TrafficDecodeTaskModel(R.data.task));

                    webix.message(LangHelper.GetString(_this.Id, "Task saved"));
                }
                else
                {
                    taskData.valid = false;
                    _this.Views.TasksTable.validate(taskData.id);

                    if(R.error)
                        webix.message({text:R.error.msg,type:"error"});
                }

                _this.OnTasksTableRowSelect();
            }});

        this.ClearTaskForm();
    }
    else
    {
        taskData.valid = false;
    }

    this.Views.TasksTable.validate(taskData.id);
};

TrafficDecodeViewController.prototype.DeleteTask = function (RowId)
{
    var _this = this;

    var taskData = this.Views.TasksTable.getItem(RowId);
    taskData.valid = true;

    if(taskData.task_id > 0)
    {
        this.ViewModel.DeleteTask({task:taskData, callback:function (R)
            {
                if(R.success)
                {
                    _this.Views.TasksTable.remove(RowId);
                    _this.Views.FilesTable.clearAll();
                    webix.message(LangHelper.GetString(_this.Id, "Task deleted"));
                }
                else
                {
                    taskData.valid = false;
                    _this.Views.TasksTable.validate(RowId);
                    if(R.error)
                        webix.message({text:R.error.msg,type:"error"});
                }
            }});
    }
    else
    {
        this.Views.TasksTable.remove(RowId);
    }
};

TrafficDecodeViewController.prototype.RefreshFiles = function(Page, Sync)
{
    var _this = this;

    var state = this.Views.FilesTable.getState();

    var size = this.Views.FilesTablePagingSize.getValue();

    var filter = state.filter;
    var tasks = this.GetTasksTableSelectedData();
    var task_id = "";
    if(tasks)
    {
        if(Array.isArray(tasks))
        {
            var arr = [];
            tasks.forEach(function(Task){
                arr.push(Task.task_id);
            });
            task_id = arr.join(",");
        }
        else{
            task_id += tasks.task_id;
        }
    }

    if(task_id && task_id != "0")
    {
        filter = webix.extend(filter, {task_id: task_id});
        this.ViewModel.RefreshFiles({page:Page, sync:Sync, filter:filter, size: size, sort:state.sort ? state.sort:null, callback:function ()
        {

        }});
    }



};

TrafficDecodeViewController.prototype.UpdateFilesTable = function (Params)
{
    var _this = this;

    this.Views.FilesTable.clearAll();
    this.Views.FilesTable.parse(_this.ViewModel.GetFiles());

    this.Views.FilesTablePagingSize.setValue(this.ViewModel.FilesFilter.Total.size);

    this.Views.FilesTablePaging.define(this.ViewModel.FilesFilter.Total);
    this.Views.FilesTablePaging.refresh();
};

TrafficDecodeViewController.prototype.AddFileToFilesTable = function()
{
    var _this = this;

    var task = this.GetTasksTableSelectedData();
    var model = new TrafficDecodeFileModel({task_id: task.task_id});

    _this.Views.FilesTable.add(model,0);

    _this.Views.FilesTable.select(model.id, false);
    _this.Views.FilesTable.showItem(model.id);

    _this.ShowFileEditForm(model, _this.Views.FilesTable.getItemNode(model.id), "right");
};

TrafficDecodeViewController.prototype.ShowFileEditForm = function (Data, Node, Pos)
{
    this.ClearFileForm();

    if(Data)
    {
        var model = new TrafficDecodeFileModel(Data);
        this.Views.CreateFileContextForm.setValues(new TrafficDecodeFileFormModel(model));

        if(model.pcap_id > 0)
        {
            this.Views.CreateFileContextFormDetailsWrapper.enable();
            this.Views.CreateFileContextFormUploader.disable();
        }
        else{
            this.Views.CreateFileContextFormDetailsWrapper.disable();
            this.Views.CreateFileContextFormUploader.enable();
        }

    }

    this.CreateFileContext.show(Node, {pos:Pos});
};

TrafficDecodeViewController.prototype.ClearFileForm = function ()
{
    var _this = this;

    this.Views.CreateFileContextFormUploader.setValue(null);
    this.Views.CreateFileContextForm.elements.title.setValue(null);
    this.Views.CreateFileContextForm.elements.description.setValue(null);
    this.Views.CreateFileContextForm.clearValidation();
    this.Views.CreateFileContextFormDetailsWrapper.disable();

    this.FileToSave = null;
};

TrafficDecodeViewController.prototype.SaveFile = function ()
{
    var _this = this;

    var fileData = this.GetFilesTableSelectedData();
    if(this.Views.CreateFileContextForm.validate())
    {
        fileData.valid = true;

        var saveFile = function(){

            var formData = new TrafficDecodeFileFormModel(_this.Views.CreateFileContextForm.getValues({hidden: true}));

            _this.ViewModel.SaveFile({file:fileData, file_form:formData, callback:function (R)
                {
                    if(R.success && R.data && R.data.file)
                    {
                        if(fileData.pcap_id && fileData.decode_types != formData.decode_types)
                        {
                            _this.OnDecodeTypesChangedContext.show();
                        }
                        _this.Views.FilesTable.updateItem(fileData.id,  new TrafficDecodeFileModel(R.data.file));



                        webix.message(LangHelper.GetString(_this.Id, "File saved"));
                    }
                    else
                    {
                        fileData.valid = false;
                        _this.Views.FilesTable.validate(fileData.id);

                        if(R.error)
                            webix.message({text:R.error.msg,type:"error"});

                        _this.DeleteLocalPcap({file: formData, taskId: fileData.task_id});
                    }

                    _this.OnFilesTableRowSelect();
                }});

            _this.CreateFileContext.hide();
            _this.ClearFileForm();
        };

        if(this.FileToSave)
        {
            this.SaveLocalPcap({callback: saveFile});
        }
        else{
            saveFile();
        }

    }
    else
    {
        fileData.valid = false;
    }

    this.Views.FilesTable.validate(fileData.id);
};

TrafficDecodeViewController.prototype.OnFilesTableRowSelect = function ()
{
    var data = this.GetFilesTableSelectedData();

    this.FilesTableRowsSelectedCount.next(data?(Array.isArray(data)?data.length:1):0);
};

TrafficDecodeViewController.prototype.OnFilesTableRowUnSelect = function ()
{
    var data = this.GetFilesTableSelectedData();
    this.FilesTableRowsSelectedCount.next(data?(Array.isArray(data)?data.length:1):0);
};

TrafficDecodeViewController.prototype.GetFilesTableSelectedData = function ()
{
    return this.Views.FilesTable.getSelectedItem();
};

TrafficDecodeViewController.prototype.DeleteFile = function (RowId)
{
    var _this = this;

    var fileData = this.Views.FilesTable.getItem(RowId);
    fileData.valid = true;

    if(fileData.pcap_id > 0)
    {
        this.ViewModel.DeleteFile({file:fileData, callback:function (R)
            {
                if(R.success)
                {
                    _this.Views.FilesTable.remove(RowId);

                    webix.message(LangHelper.GetString(_this.Id, "File deleted"));
                }
                else
                {
                    fileData.valid = false;
                    _this.Views.FilesTable.validate(RowId);
                    if(R.error)
                        webix.message({text:R.error.msg,type:"error"});
                }
            }});
    }
    else
    {
        this.Views.FilesTable.remove(RowId);
    }
};

TrafficDecodeViewController.prototype.SaveLocalPcap = function(Params)
{
    var _this = this;

    this.ViewModel.GeneratePcapFileUploadId({
        fileName: this.FileToSave.name,
        path: "pcaps/" + this.GetTasksTableSelectedData().task_id,
        callback: function(R){
            if(R.success && R.data)
            {
                var key = R.data.key ? R.data.key : webix.uid();

                App.ServerManager.UploadFile(
                    {
                        key: key,
                        file: _this.FileToSave.file,
                        callback: function(R)
                        {
                            if(R.status != 200)
                            {
                                webix.message({text:LangHelper.GetString(_this.Id, "File uploading error") + "<br/>" +R.status + ": " + R.statusText,type:"error"});
                            }else{
                                webix.message(LangHelper.GetString(_this.Id, "File uploaded"));

                                if(Params && Params.callback && typeof Params.callback === "function")
                                {
                                    Params.callback();
                                }
                            }

                            _this.FileToSave = null;
                        }
                    }
                );
            }
            else{
                if(R.error)
                    webix.message({text:R.error.msg,type:"error"});
            }
        }
    });
};

TrafficDecodeViewController.prototype.DeleteLocalPcap = function(Params)
{
    var _this = this;

    var fileModel = new TrafficDecodeFileFormModel(Params && Params.file ? Params.file : null);
    var taskId = Params && Params.taskId ? Params.taskId : 0;

    if(fileModel.file_name)
    {
        this.ViewModel.DeleteLocalFile({file:fileModel, taskId: taskId, callback:function (R)
            {
                if(R.success)
                {
                    webix.message(LangHelper.GetString(_this.Id, "File deleted"));
                }
                else if(R.error){
                    webix.message({text:R.error.msg,type:"error"});
                }
            }});
    }
};

TrafficDecodeViewController.prototype.RefreshReport = function(Page, AllTabs)
{
  var _this = this;

  var tab = _this.Views.ReportsPanelTabs.getValue();
  tab = tab.replace(_this.Id, "");

  var selected = this.GetFilesTableSelectedData();

  if(this.Reports[tab])
  {
      this.Reports[tab].viewController.SetPcapIds(selected);
      this.Reports[tab].viewController.Refresh(Page);
  }

  if(AllTabs)
  {
      Object.keys(this.Reports).forEach(function(Key){
          if(Key !== tab)
          {
              _this.Reports[Key].viewController.SetPcapIds(selected);
              _this.Reports[Key].viewController.Refresh(Page);
          }
      });
  }
};

TrafficDecodeViewController.prototype.CheckHardwareAvailability = function()
{
  var _this = this;

  this.ViewModel.CheckPcapDecodeHardwreAvailability();
};

TrafficDecodeViewController.prototype.ResetQueueForSelectedPcaps = function()
{
    var _this = this;

    var selected = this.GetFilesTableSelectedData();

    if(selected)
    {
        var f = function(Item)
        {
            var file = new TrafficDecodeFileModel(Item);

            _this.ViewModel.ResetPcapDecodeQueue({
                file: file,
                callback: function(R)
                {
                    if(R.success)
                    {
                        _this.Views.FilesTable.updateItem(file.id, R.data);

                        webix.message(file.title + ":" + LangHelper.GetString(_this.Id, "Decode queue reset"));
                    }
                    else{
                        webix.message({
                            text:file.title + ":" + R.error.msg,
                            type:"error"
                        });
                    }
                }
            });
        }

        if(Array.isArray(selected))
        {
            selected.forEach(function(Val){
                f(Val);
            });
        }
        else{
            f(selected);
        }
    }
};

TrafficDecodeViewController.prototype.OnTasksTableSelectionChanged = function()
{
    var _this = this;

    var selected = this.GetTasksTableSelectedData();
    var disable_files_wrapper = false;
    var refresh = true;
    var disable_add_file = false;
    if(selected)
    {
        var check = function(Obj)
        {
            if(!Obj.task_id)
            {
                disable_files_wrapper = true;
                refresh = false;
                disable_add_file = true;
            }
        }
        if(Array.isArray(selected))
        {
            disable_add_file = true;

            selected.forEach(function(Val){
                check(Val);
            });
        }
        else{
            check(selected);
        }
    }
    else{
        refresh = false;
        disable_files_wrapper = true;
        disable_add_file = true;
    }

    if(disable_add_file)
    {
        _this.Views.AddFileBtn.disable();
    }else{
        _this.Views.AddFileBtn.enable();
    }

    if(disable_files_wrapper)
    {
        _this.Views.FilesWrapper.disable();
    }else{
        _this.Views.FilesWrapper.enable();
    }

    if(refresh)
    {
        _this.RefreshFiles(0);
    }
};

TrafficDecodeViewController.prototype.DownloadPcapFile = function (Data)
{
    var selectedData = Data ? Data : this.GetFilesTableSelectedData();

    if(selectedData)
    {
        this.ViewModel.DownloadPcapFile(selectedData);
    }
};

TrafficDecodeViewController.prototype.TabbarOptionTemplate = function(Report)
{
    var _this = this;
    var count = Report.viewController.ViewModel.RowsFilter.Total.count;
    count = count ? (count > 99 ? "99+" : count) : 0;

    return "<div>" +
        "<span class='webix_icon " + Report.icon + "'></span> "+ Report.title + " (" + count + ")" +
        // "<span class=\"webix_badge pcap_decode_badge\">" + count + "</span>" +
    "</div>";
};
function TrafficDecodeLogsViewController(Params)
{

    var _this = this;

    this.Id = "TrafficDecode";
    this.id = this.Id + "Logs";

    this.ViewModel = new TrafficDecodeLogsManager();

    this.PagingSizeOptions = [
        {id:10, value:"10"},
        {id:50, value:"50"},
        {id:100, value:"100"},
        {id:200, value:"200"},
        {id:500, value:"500"},
        {id:1000, value:"1 000"},
        {id:10000, value:"10 000"}
    ];

    this.RowLogInfoContext = webix.ui({
        view:"popup",
        width:640,
        height:320,
        body:{
            rows:[
                BlockHeaderTemplate({title:LangHelper.GetString(this.Id, "Log data"), icon:"stack-overflow", borderless: true}),
                {
                    view:"codemirror-editor",
                    id:this.id + "RowLogInfoContextEditor",
                    value:""
                },
                {
                    cols:[
                        {},
                        {
                        },
                        {
                            view:"button",
                            type:"form",
                            label:LangHelper.GetString(this.Id, "Close"),
                            click:function () {
                                _this.RowLogInfoContext.hide();
                            }
                        }
                    ]
                }
            ]
        }
    });

    this.RowLogDeleteContext = webix.ui({
        view:"popup",
        width:300,
        height:180,
        body:{
            view:"form",
            borderless:true,
            elements:[
                {
                    template:LangHelper.GetString(this.Id, "Are you sure you want to delete this row?")
                },
                {
                    cols:[
                        {},
                        {
                            view:"button",
                            label:LangHelper.GetString(this.Id, "Cancel"),
                            click:function () {
                                _this.RowLogDeleteContext.hide();
                            }
                        },
                        {
                            view:"button",
                            type:"form",
                            label:LangHelper.GetString(this.Id, "Delete"),
                            click:function () {
                                _this.RowLogDeleteContext.hide();

                                var selected = _this.GetTableSelectedData();
                                if(selected)
                                    _this.DeleteRow(selected.id);
                            }
                        }
                    ]
                }
            ]
        }
    });

    this.ViewConfig = [
        {
            cols: [
                SormSideBarView({id: this.id}),
                {
                    type: "space",
                    rows: [
                        {
                            type: "clear",
                            borderless: true,
                            rows: [
                                PageHeaderTemplate({
                                    root_title: LangHelper.GetString("AppView", "Lawful interception"),
                                    title: LangHelper.GetString("AppView", "Traffic decode logs")
                                }),
                                {
                                    view: "toolbar",
                                    // borderless: true,
                                    height: 32,
                                    padding: 0,
                                    margin: 0,
                                    elements: [
                                        {},
                                        {
                                            view: "button",
                                            id:this.Id+"RefreshBtn",
                                            type: "icon",
                                            icon: "refresh",
                                            css: "toolbar_round_btn",
                                            width: 32,
                                            tooltip: LangHelper.GetString(this.Id, "Refresh"),
                                            click: function (id, e, node)
                                            {
                                                _this.Refresh(0);
                                            }
                                        }
                                    ]
                                },
                                BlockHeaderTemplate({
                                    title:LangHelper.GetString(this.Id, "Traffic decode queue"),
                                    icon:"stack-overflow"
                                }),
                                {
                                    rows: [
                                        {
                                            view: "datatable",
                                            id: this.id + "Table",
                                            select: "row",
                                            resizeColumn: true,
                                            tooltip:true,
                                            on: {
                                                onItemClick: function (id,e,node)
                                                {
                                                    var rowData = this.getItem(id);

                                                    if(id.column === 'trash')
                                                    {
                                                        var __this = this;

                                                        var data = _this.Views.Table.getItem(id);

                                                        setTimeout(function () {
                                                            var node = __this.getItemNode(id);
                                                            _this.RowLogDeleteContext.show(node, {pos: "left"});
                                                        }, 10);
                                                    }
                                                },
                                                onBeforeFilter:function()
                                                {
                                                    return false;
                                                },
                                                onAfterFilter:function()
                                                {
                                                    _this.Refresh();
                                                },
                                                onBeforeSort:function()
                                                {
                                                    _this.Refresh();
                                                    return false;
                                                }
                                            },
                                            columns: [
                                                {
                                                    id: "row_id",
                                                    header:[LangHelper.GetString(_this.Id, "Row ID"),
                                                        {
                                                            content: "textFilter"
                                                        }
                                                    ]
                                                },
                                                {
                                                    id: "update_time",
                                                    header: [LangHelper.GetString(this.Id, "Date"), {content: "dateRangeFilter"}],
                                                    //format: webix.Date.dateToStr("%d.%m.%Y"),
                                                    template:function(obj, common){
                                                        if(obj.update_time)
                                                        {
                                                            var format =  webix.Date.dateToStr("%d.%m.%Y %H:%i:%s");
                                                            return format(obj.update_time);
                                                        }

                                                        return "";
                                                    },
                                                    width: 160,
                                                    sort: "date"
                                                },
                                                {
                                                    id: "item_type",
                                                    header: [
                                                        LangHelper.GetString(this.Id, "Type"),
                                                        {
                                                            content: "richSelectFilter",
                                                            options: Helper.DictToArr(TrafficDecodeLogsItemTypeDic),
                                                            inputConfig: {suggest: {fitMaster: false}}
                                                        }
                                                    ],
                                                    template: function(Item)
                                                    {
                                                        return Item.item_type && TrafficDecodeLogsItemTypeDic[Item.item_type] ?
                                                            TrafficDecodeLogsItemTypeDic[Item.item_type].value :
                                                            (Item.item_type ? Item.item_type : "")
                                                    },
                                                    fillspace: true
                                                },
                                                {
                                                    id: "item_action",
                                                    header: [
                                                        LangHelper.GetString(this.Id, "Action"),
                                                        {
                                                            content: "textFilter"
                                                        }
                                                    ],
                                                    fillspace: true
                                                },
                                                {
                                                    id: "decode_type",
                                                    header: [
                                                        LangHelper.GetString(this.Id, "Decode type"),
                                                        {
                                                            content: "multiSelectFilter",
                                                            options: Helper.DictToArr(TrafficDecodeTypesDic),
                                                            inputConfig: {suggest: {fitMaster: false}}
                                                        }
                                                    ]
                                                },
                                                {
                                                    id: "status",
                                                    header: [
                                                        LangHelper.GetString(this.Id, "Status"),
                                                        {
                                                            content: "multiSelectFilter",
                                                            options: Helper.DictToArr(TrafficDecodeLogStatusesDic),
                                                            inputConfig: {suggest: {fitMaster: false}}
                                                        }
                                                    ],
                                                    template: function(Item)
                                                    {
                                                        return Item.status && TrafficDecodeLogStatusesDic[Item.status] ?
                                                            TrafficDecodeLogStatusesDic[Item.status].value :
                                                            (Item.status ? Item.status : "")
                                                    },
                                                    fillspace: true
                                                },
                                                {
                                                    id: "status_description",
                                                    header: [
                                                        LangHelper.GetString(this.Id, "Description"),
                                                        {
                                                            content: "textFilter"
                                                        }
                                                    ],
                                                    fillspace: true
                                                },
                                                {
                                                    id: "info",
                                                    header: "",
                                                    width: 32,
                                                    template: CellQuestionBtnTemplate
                                                },
                                                {
                                                    id: "trash",
                                                    header: "",
                                                    width: 32,
                                                    template: CellTrashBtnTemplate,
                                                    headermenu: false
                                                }
                                            ],
                                            onClick: {
                                                "fa-question-circle": function(ev, id)
                                                {
                                                    var item = this.getItem(id);
                                                    var node = this.getItemNode(id);

                                                    setTimeout(function(){
                                                        _this.ShowRowLogInfoPopup(item, node);
                                                    }, 10);

                                                    return false;
                                                }
                                            },
                                            data:[],
                                            rules:{
                                                "valid":function (value)
                                                {
                                                    return value;
                                                }
                                            }
                                        },
                                        {
                                            view: "toolbar",
                                            padding: 0,
                                            margin: 0,
                                            height:32,
                                            elements: [
                                                {
                                                    view: "pager",
                                                    gravity:3,
                                                    id: this.id + "Paging",
                                                    template: "{common.first()} {common.prev()} {common.pages()} {common.next()} {common.last()}",
                                                    master:false,
                                                    on: {
                                                        onAfterPageChange:function(Page)
                                                        {
                                                            _this.Refresh(Page);
                                                        }
                                                    }
                                                },
                                                {
                                                    view:"richselect",
                                                    id: this.id + "PagingSize",
                                                    label:LangHelper.GetString(this.Id, "On the page"),
                                                    labelWidth:120,
                                                    minWidth:220,
                                                    value:100,
                                                    options:this.PagingSizeOptions,//this.options,
                                                    on: {
                                                        onChange:function()
                                                        {
                                                            _this.Refresh();
                                                        }
                                                    }
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ],
                }
            ]
        }
    ];

    this.View = null;

    this.Views = {
        Table: null,
        Paging: null,
        PagingSize: null,
        RowLogInfoContextEditor: null
    };

    this.Subscriptions = {};

    return this;
}

TrafficDecodeLogsViewController.prototype.Destroy = function ()
{
    var _this = this;

    Object.keys(this.Subscriptions).forEach(function (key)
    {
        _this.Subscriptions[key].unsubscribe();
    });
};

TrafficDecodeLogsViewController.prototype.ViewDidLoad = function (Params)
{
    var _this = this;

    webix.ready(function () {
        $$(_this.id + "LeftSideBar").select(_this.id);
    });

    this.BindAll();
};

TrafficDecodeLogsViewController.prototype.BindAll = function (Params)
{
    var _this = this;

    Object.keys(this.Views).forEach(function(Key){
        _this.Views[Key] = $$(_this.id + Key);
    });

    this.Subscriptions.SignalRowsUpdatedSubscription = this.ViewModel.SignalRowsUpdated.subscribe(function(Val){
        if(Val)
        {
            _this.UpdateTable();
        }
    });

    this.Refresh();
};


TrafficDecodeLogsViewController.prototype.Refresh = function(Page, Params)
{
    var _this = this;

    var size = this.Views.PagingSize.getValue();
    var state = this.Views.Table.getState();
    var filter = {};

    if(state.filter.start)
    {
        filter.update_time_start= {
            id: "update_time",
            operator: ">=",
            value: state.filter.start
        };
    }

    if(state.filter.end)
    {
        filter.update_time_end = {
            id: "update_time",
            operator: "<=",
            value: state.filter.end
        };
    }

    if(state.filter.row_id)
    {
        filter.row_id = {
            id: "row_id",
            operator: "like",
            value: state.filter.row_id
        }
    }

    if(state.filter.item_type)
    {
        filter.item_type = {
            id: "item_type",
            operator: "=",
            value: state.filter.item_type
        }
    }

    if(state.filter.item_action)
    {
        filter.item_action = {
            id: "item_action",
            operator: "like",
            value: state.filter.item_action
        }
    }

    if(state.filter.status)
    {
        filter.status = {
            id: "status",
            operator: "in",
            value: state.filter.status.split(",")
        }
    }

    if(state.filter.decode_type)
    {
        filter.decode_type = {
            id: "decode_type",
            operator: "in",
            value: state.filter.decode_type.split(",")
        }
    }

    if(state.filter.status_description)
    {
        filter.status_description = {
            id: "status_description",
            operator: "like",
            value: state.filter.status_description
        }
    }
    this.ViewModel.RefreshRows({page:Page, filter:filter, size: size, sort: state && state.sort ? state.sort:null, callback:function ()
        {

        }});
};

TrafficDecodeLogsViewController.prototype.UpdateTable = function (Params)
{
    var _this = this;

    this.Views.Table.clearAll();
    this.Views.Table.parse(_this.ViewModel.GetRows());

    this.Views.PagingSize.setValue(this.ViewModel.RowsFilter.Total.size);

    this.Views.Paging.define(this.ViewModel.RowsFilter.Total);
    this.Views.Paging.refresh();
};

TrafficDecodeLogsViewController.prototype.ShowRowLogInfoPopup = function(Item, Node)
{
    var _this = this;

    var item = new TrafficDecodeLogModel(Item);

    this.RowLogInfoContext.show(Node, {pos: "left"});

    var str = "";
    if(item.data)
    {
        var data = JSON.parse(item.data);

        if(data)
        {
            str = JSON.stringify(data, undefined, 2);
        }
    }

    this.Views.RowLogInfoContextEditor.setValue(str);
};

TrafficDecodeLogsViewController.prototype.DeleteRow = function (RowId)
{
    var _this = this;

    var data = this.Views.Table.getItem(RowId);
    data.valid = true;

    if(data._id > 0)
    {
        this.ViewModel.DeleteRow({row:data, callback:function (R)
            {
                if(R.success)
                {
                    _this.Views.Table.remove(RowId);

                    webix.message(LangHelper.GetString(_this.Id, "Row deleted"));
                }
                else
                {
                    data.valid = false;
                    _this.Views.Table.validate(RowId);
                    if(R.error)
                        webix.message({text:R.error.msg,type:"error"});
                }
            }});
    }
    else
    {
        this.Views.Table.remove(RowId);
    }
};

TrafficDecodeLogsViewController.prototype.GetTableSelectedData = function ()
{
    return this.Views.Table.getSelectedItem();
};
